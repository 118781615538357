<div class="container-fluid pl-5" [ngClass]="{'pr-5':!stixService.guidedUI}">

  <!-- <div class="row ml-2 mr-3" [class]="{'mt-3': !stixService.guidedUI}">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <h3 *ngIf="!stixService.guidedUI" class="component-title mb-3">{{ getComponentDisplayName() }}</h3>
      <app-dynamic-form [questions]="questions" [hasExternalReferences]="hasExternalReferences()"
        [hasGranularMarkings]="hasGranularMarkings()" [hasExtensions]="hasExtensions()"
        [hasObjectMarkingReferences]="hasObjectMarkingReferences()" [hasContents]="hasContents()"
        [isFormValid]="currentComponent?.isFormValid" [currentComponent]="currentComponent"
        [componentMap]="componentMap" [displayName]="getComponentDisplayName()"
        [objectPropertyTypeSelectionInput]="objectPropertyTypeSelectionInput" (componentData)="addComponent($event)"
        [queryParams]="queryParams" [objectSelectionInput]="objectSelectionInput"
        (guidedUIEvent)="guidedUIEventHandler($event)"> -->

  <div class="row ml-2" [ngClass]="{'mt-3 mr-3': !stixService.guidedUI, 'w-100': stixService.guidedUI}">
    <div [ngClass]="{'col-xl-12': !stixService.guidedUI, 'w-100': stixService.guidedUI}">
      <div class="row pl-3 align-items-baseline">
        <h3 class="component-title pl-2" [ngClass]="{'mt-2': !stixService.guidedUI}" style="margin-bottom:0;">{{ getComponentDisplayName() }}</h3>
        <img *ngIf="isDuplicated()" (click)="viewDuplicates($event, currentComponentForDuplicates)" src="./assets/merge-cells.svg" alt="" width="25" height="25" class="ml-3 duplicate-object-icon" title="Duplicated Objects">
        <div class="mx-auto mt-3">{{ requiredMessage }}</div>   
      </div>
      <app-dynamic-form [questions]="questions" [hasExternalReferences]="hasExternalReferences()"
        [hasGranularMarkings]="hasGranularMarkings()" [hasExtensions]="hasExtensions()"
        [hasObjectMarkingReferences]="hasObjectMarkingReferences()" [hasContents]="hasContents()"
        [isFormValid]="currentComponent?.isFormValid" [currentComponent]="currentComponent"
        [componentMap]="componentMap" [displayName]="getComponentDisplayName()"
        [objectPropertyTypeSelectionInput]="objectPropertyTypeSelectionInput" (componentData)="addComponent($event)" (checkDuplicates)="checkDuplicates($event)"
        [queryParams]="queryParams" [objectSelectionInput]="objectSelectionInput"
        (guidedUIEvent)="guidedUIEventHandler($event)">
      </app-dynamic-form>

    </div>
    <div class="col-md-12 col-lg-12 col-xl-6 d-none">
      <div class="mt-3 pt-2 json-previewer-title">{{ getComponentDisplayName() }} - STIX Previewer</div>
      <!-- Need to keep pre and span on the same line. Pre ingests all whitespace, including enter keys in the code -->
      <!-- <pre class="component-previewer"><span *ngIf="this.stixService.bundle.objects.length > 0">{{ this.stixService.bundle.objects | bundlefilter: componentId | json }}</span>
            </pre> -->
      <pre class="component-previewer"><span *ngIf="this.errorList.length > 0"><span *ngFor="let x of this.errorList">{{ x }}</span></span>
            </pre>
      <!-- <div class="float-right">
                <button type="button" class="btn btn-success component-button">
                    Save
                </button> -->
    </div>
  </div>

  <div class="row w-100" [ngClass]="{'ml-1': stixService.guidedUI}"*ngIf="isIdentity() && storedIdentities && storedIdentities?.arr?.length > 0">
    <div class="w-100" [ngClass]="{'col-xl-12': !stixService.guidedUI}">
      <div [ngClass]="{'pl-2 pt-0': !stixService.guidedUI, 'pt-3': stixService.guidedUI}">
        <h3>Available Identities</h3>

      <!-- <h3>Available Identities</h3> -->

      <!-- <accordion class="mb-3">
        <accordion-group *ngFor="let identity of storedIdentities?.arr; let i = index;"
          heading="{{ identity.name ? identity.name : '<NO NAME>'}} ({{identity.id}})">
          <div class="d-flex justify-content-between" *ngIf="identity.id !== cisaId">
            <div>
              <button type="button" class="btn btn-sm btn-secondary" (click)="editObject(identity);">Edit</button>

              <button type="button" class="btn btn-sm btn-warning btn-revoke"
                (click)="revokeObject(identity);">Revoke</button>
            </div>
            <button type="button" class="btn btn-sm btn-danger btn-remove" title="Remove From Local Storage"
              (click)="removeObject(identity.id);">Remove From Local Storage</button>
          </div>
          <pre class="pt-1 pb-1 mt-3">{{ getJsonDisplayForComponent(identity) | json }}</pre>
        </accordion-group>
      </accordion> -->

        <accordion class="mb-3 identities">
          <accordion-group *ngFor="let identity of storedIdentities?.arr; let i = index;"
            heading="{{ identity.name ? identity.name : '<NO NAME>'}} ({{identity.id}})">
            <div class="d-flex justify-content-between">
              <ng-template [ngIf]="identity.id !== cisaId" [ngIfElse]="elseIsCISA">
                <div>
                  <button type="button" class="btn btn-sm btn-secondary" (click)="editObject(identity);">
                    <fa-icon [icon]="faEdit" class="pr-1"></fa-icon>
                    Edit
                  </button>
                  <button type="button" class="btn btn-sm btn-warning btn-revoke"
                    (click)="revokeObject(identity);">
                    <fa-icon [icon]="faRevoke" class="pr-1"></fa-icon>
                    Revoke
                  </button>
                  <button *ngIf="!compareIdentity(identity.id)" type="button" class="btn btn-sm btn-primary" (click)="setMainIdentity(identity.id)">
                    <fa-icon [icon]="faIdCard"></fa-icon>
                    Set Identity
                  </button>
                  <button *ngIf="compareIdentity(identity.id)" type="button" class="btn btn-sm btn-primary" disabled>
                    <fa-icon [icon]="faIdCard"></fa-icon>
                     Identity Set
                  </button>
                </div>
                <button type="button" class="btn btn-sm btn-danger btn-remove" title="Remove From Local Storage"
                  (click)="removeObject(identity.id);">
                  <fa-icon [icon]="faTrash" class="pr-1"></fa-icon>
                  Remove From Local Storage
                </button>
              </ng-template>
              <ng-template #elseIsCISA>
                <div>
                  <button type="button" class="btn btn-sm btn-success btn-secondary btn-cisa" title="Add To Bundle"
                    (click)="addCISAIdentity(identity);">
                    <fa-icon [icon]="faFileImport" class="pr-1"></fa-icon>
                    Add To Bundle
                  </button>
                  <button *ngIf="!compareIdentity(identity.id)" type="button" class="btn btn-sm btn-primary" (click)="setMainIdentity(identity.id)">
                    <fa-icon [icon]="faIdCard"></fa-icon>
                    Set Identity
                  </button>
                  <button *ngIf="compareIdentity(identity.id)" type="button" class="btn btn-sm btn-primary" disabled>
                    <fa-icon [icon]="faIdCard"></fa-icon>
                     Identity Set
                  </button>
                </div>
              </ng-template>
            </div>
            <pre class="pt-1 pb-1 mt-3 jsonDisplay">{{ getJsonDisplayForComponent(identity) | json }}</pre>
          </accordion-group>
        </accordion>
      </div>
    </div>
  </div>
</div>