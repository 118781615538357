<form (ngSubmit)="addComponent()" [formGroup]="form">
    <div class="row">
        <div class="col-12">
            <div class="row pl-2 pr-2" *ngIf="errorMessage">
                <div class="mx-auto mb-1 warning-message">{{ errorMessage }}</div>     
            </div>
            <div class="row pr-2" [ngClass]="{'pl-2 mb-2': stixService.guidedUI}" *ngIf="showObjectMarkingReference()" style="padding-top: 0;">
                <!-- <hr *ngIf="hasGranularMarkings  || hasExternalReferences || hasContents"
                    class="section-separator"> -->
                <hr class="section-separator" style="margin-top: 8px;">
                <app-string-array [key]="'object_marking_refs'" *ngIf="hasObjectMarkingReferences" (stringArrayOutput)="setObjectMarkingReferences($event)"
                    [class]="topColumnProperties"></app-string-array>
                <app-string-array [key]="'labels'" *ngIf="ifLabels()" (stringArrayOutput)="setLabels($event)"
                    [class]="topColumnProperties"></app-string-array>
                <hr *ngIf="!stixService.guidedUI" class="section-separator" style="margin-bottom: 4px;">
            </div>
            <div class="row pr-2" [ngClass]="{'pl-2': stixService.guidedUI}" *ngIf="!showObjectMarkingReference() && stixService.guidedUI" style="padding-top: 0;">
                <hr class="section-separator" style="margin-top: 8px; margin-bottom: 16px;">
            </div>
            <div class="row">
                <div *ngFor="let question of questions" [ngClass]="{'pl-2 pr-2 w-100 display-flex': stixService.guidedUI, 'p-0': !stixService.guidedUI}" [class]="questionWrapperClass(question)">
                    <button
                        *ngIf="stixService.guidedUI && showFavIconType(question) === 'plus' && !(stixService.newObject && question.key === 'revoked')"
                        type="button" class="btn btn-fa-sm btn-outline-danger d-flex justify-content-center ml-0 mr-2"
                        title="Click to add favorite" (click)="updateFav(question)">
                        <fa-icon [icon]="faHeart" (click)="updateFav(question)" alt="" width="30px" height="20"
                            class="d-inline-block align-top"></fa-icon>
                    </button>
                    <button
                        *ngIf="stixService.guidedUI && showFavIconType(question) === 'minus' && !(stixService.newObject && question.key === 'revoked')"
                        type="button" class="btn btn-fa-sm btn-danger d-flex justify-content-center ml-0 mr-2"
                        title="Click to remove favorite" (click)="updateFav(question)">
                        <img src="./assets/heart-circle-minus-solid.svg" (click)="updateFav(question)" alt=""
                            width="30px" height="20" class="d-inline-block align-top fav-color ml-1">
                    </button>
                    <app-question (change)="isAddEnabled()" (input)="isAddEnabled()" (isAddEnabled)="isAddEnabled()" (checkDuplicates)="checkDuplicatesCallback()" *ngIf="!(stixService.newObject && question.key === 'revoked')" [ngClass]="{'w-100': stixService.guidedUI}" [question]="question" [form]="form" [componentMap]="componentMap" [displayName]="displayName"></app-question>
                </div>
            </div>
            <div class="row pr-2" [ngClass]="{'pl-2': stixService.guidedUI}">
                <hr class="section-separator" style="margin-top: 4px;" *ngIf="!stixService.guidedUI">
                <div [class]="questionWrapperClass(null, true)" style="float:left" *ngIf="hasGranularMarkings">
                    <app-granular-markings class="w-100" [ngClass]="{'mt-2': stixService.guidedUI}"></app-granular-markings>
                </div>
                <div [class]="questionWrapperClass(null, true)" style="float:left" *ngIf="hasExternalReferences">
                    <!-- <hr class="section-separator"> -->
                    <app-external-references class="w-100" [ngClass]="{'mt-2': stixService.guidedUI}"></app-external-references>
                </div>

                <div [class]="questionWrapperClass(null, true)" *ngIf="hasExtensions">
                    <!-- <hr *ngIf="hasGranularMarkings  || hasExternalReferences || hasContents || hasObjectMarkingReferences"
                        class="section-separator"> -->
                    <app-extensions class="w-100" [ngClass]="{'mt-2': stixService.guidedUI}"></app-extensions>
                </div>

                <hr *ngIf="stixService.guidedUI" [class]="questionWrapperClass(null, true)" class="section-separator" style="margin-bottom: 8px;">
                <hr *ngIf="!stixService.guidedUI" class="section-separator" style="margin-bottom: 8px;">
            </div>
        </div>
    </div>
    <div class="row" [ngClass]="{'add-to-bundle': !stixService.guidedUI}">
        <div class="col text-right p-0 pr-2">
            <button [class]="{'move-out-of-view': stixService.guidedUI}"
                type="button" class="btn btn-lg btn-secondary component-button text mr-2" (click)="cancel()" style="width: 175px !important">
                <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
                Cancel
            </button>   
            <!-- <button *ngIf="showClose" type="button" class="btn btn-lg btn-secondary component-button text mr-2"
                (click)="closeWindow()">
                <fa-icon [icon]="faClose" class="pr-1"></fa-icon>
                Close
            </button>        -->
            <button [class]="{'move-out-of-view': stixService.guidedUI}"
                type="button" class="btn btn-lg btn-success component-button text" (click)="addComponent()"
                [disabled]="!addEnabled || !isFormDataChanged()" style="width: 175px !important">
                <fa-icon [icon]="faAddToBundle" class="pr-1"></fa-icon>
                Add to Bundle
            </button>
        </div>
    </div>
</form>