<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ messageObj?.title}}</h4>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal"
      (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body extensions-modal-body">
   <!-- {{ messageObj?.message }} -->
   <span [innerHTML]="messageObj?.message"></span>

    <div class="item-action">
      <button 
        type="button"
        class="btn btn-sm btn-secondary mr-3"
        (click)="close()">
        <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-sm btn-success"
        (click)="confirm()">
        <fa-icon [icon]="faCheck" class="pr-1"></fa-icon>
        Confirm
      </button>
    </div>
  </div>
</div>