<div class="d-flex flex-column justify-content-start align-items-center content-wrapper">
    <div class="d-flex flex-row justify-content-around align-items-end gr-top-width">
        <div class="w-75 pl-5 d-flex align-items-end">
            <div class="mb-3">
                <img class="card-img" src="./assets/sword.svg" class="center" width="80" height="80" />
            </div>
            <span class="title-main-size3">HOW?</span>
        </div>

        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
    </div>

    <div class="horizonal-rule-top"></div>
    <div class="d-flex justify-content-center align-items-start w-80">
        <div class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">Adding Observables</h6>
            <div class="gr-help-section-content">
                <span class="gr-help-section-sub-title">Observable Type:</span> Enter the observable type. 
                <br><br>
                <span class="gr-help-section-sub-title">Observable Value:</span> Add it's value or any required information.
                <br><br>
                <span class="gr-help-section-sub-title">Time:</span> Enter the time zone where the observable was seen, the number of times it was seen, and the first and last times it was observed.
                <br><br>
                <span class="gr-help-section-sub-title">Observable Relationship:</span> Establishes a relationship between an attacker and its target or between an observable and the victim. This field will only be active if a relationship can be established.
                <br><br>
                If <b>attacker</b> is selected then an attacker name must be added. If you don't know who the attacker is at this point, just add unknown.
                <br><br>
                You may also select <b>benign</b> if the observable is not related to either the attacker or the target.
                <!-- First enter the observable type. Next, can add it's value or any required information.
                <br><br>
                <span class="gr-help-section-sub-title">Observable alignment</span> establishes a relationship between an attacker and its target.
                <br><br>
                If <b>attacker</b> is selected than an attacker name must be added. If you don't know who the attacker is at this point, just add unknown -->
            </div>
        </div>
        <div class="mt-5 d-flex flex-column align-items-center gr-outter-wrapper" style="position: relative;">
            <div class="w-100 observable-section">
                <div class="d-flex col-12">
                    <h6 class="mt-3 col-7" style="color: #007bff">Observable Type</h6>
                    <select class="form-control col-4 mt-2 mb-2 gr-input-wrapper-small" type="text" [(ngModel)]="currentObservableType" (change)="changeObservableType(true)">
                        <option disabled selected style="color: #999" value="">Select Observable Type</option>
                        <option *ngFor="let option of observableOptions">{{option}}</option>
                    </select>
                    <div class="col-1">
                        <button class="btn btn-primary btn-circle float-right" (click)="observableTypeDropdown = !observableTypeDropdown" [ngClass]="{'btn-angle-up': observableTypeDropdown}" [disabled]="currentObservableType == ''">
                            <fa-icon *ngIf="!observableTypeDropdown" [icon]="faAngleDown"></fa-icon>
                            <fa-icon *ngIf="observableTypeDropdown" class="angle-up" [icon]="faAngleUp"></fa-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="observableTypeDropdown" class="col-12">
                    <ul>
                        <li *ngFor="let string of currentObservableInfo">{{string}}</li>
                    </ul>
                </div>
            </div>
            <div class="w-100 observable-section">
                <div class="d-flex col-12">
                    <h6 class="mt-3 col-11" style="color: #007bff">Observable Value</h6>
                    <div class="col-1 mb-2">
                        <button class="btn btn-primary btn-circle float-right" (click)="observableValueDropdown = !observableValueDropdown" [ngClass]="{'btn-angle-up': observableValueDropdown}" [disabled]="currentObservableType == ''">
                            <fa-icon *ngIf="!observableValueDropdown" [icon]="faAngleDown"></fa-icon>
                            <fa-icon *ngIf="observableValueDropdown" class="angle-up" [icon]="faAngleUp"></fa-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="observableValueDropdown" class="col-12">
                    <div *ngFor="let field of currentObservableInput" class="col-12">
                        <div *ngIf="field.type === 'text' && field.show === true" class="col-12 mb-2 d-flex">
                            <input type="text" placeholder="{{field.placeholder}}" class="form-control col-12 gr-input-wrapper-small" [(ngModel)]="field.value" (input)="disableAddToCart(false)">
                        </div>

                        <div *ngIf="field.type === 'number' && field.show === true" class="col-12 mb-2 d-flex">
                            <input type="number" placeholder="{{field.placeholder}}" class="form-control col-12 gr-input-wrapper-small" [(ngModel)]="field.value" (input)="disableAddToCart(false)">
                        </div>

                        <div *ngIf="field.type === 'text-modal-add' && field.show === true" class="col-12 mb-2">
                            <div class="w-100 d-flex">
                                <div class="col-12 d-flex pr-0 pl-0">
                                    <input type="text" placeholder="{{field.currentPlaceholder}}" class="form-control float-left mr-1 gr-input-wrapper-small" [disabled]='true' [(ngModel)]="field.value" (input)="disableAddToCart(false)" (change)="disableAddToCart(false)">
                                    <button class="btn btn-primary" style="height: 38px; float: right;" type="button"
                                        [disabled]="field.currentField == ''" (click)="openModal(field)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 mt-2" *ngIf="field.arr.length != 0">
                                <div *ngFor="let item of field.arr; index as i">
                                    {{getModalAddName(field.arr[i])}}
                                    <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    (click)="deleteFieldArr(i, field)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="field.type === 'select' && field.show === true" class="col-12 mb-2 d-flex">
                            <select *ngIf="field.type === 'select'" class="form-control col-12 gr-input-wrapper-small" (change)="selectChange($event)" [(ngModel)]="field.value">
                                <option disabled selected style="color: #999" value="">{{field.placeholder}}</option>
                                <option *ngFor="let option of field.options">{{option}}</option>
                            </select>
                        </div>

                        <div *ngIf="field.type === 'hybrid' && field.show === true" class="col-12 mb-2 d-flex">
                            <select class="form-control col-3 gr-input-wrapper-small" (change)="updateCurrentPlaceholder($event, field.index)" [(ngModel)]="field.currentField">
                                <option disabled selected style="color: #999" value="">{{field.select_placeholder}}</option>
                                <option *ngFor="let option of field.field_options">{{option}}</option>
                            </select>
                            <input type="text" placeholder="{{field.currentPlaceholder}}" class="form-control col-9" [disabled]="field.currentPlaceholder == ''" [(ngModel)]="field.value" (input)="disableAddToCart(false)">
                        </div>

                        <div *ngIf="field.type === 'hybrid-add' && field.show === true" class="col-12 mb-2">
                            <div class="w-100 d-flex">
                                <select class="form-control col-3 gr-input-wrapper-small" (change)="updateCurrentPlaceholder($event, field.index)" [(ngModel)]="field.value">
                                    <option disabled selected style="color: #999" value="">{{field.select_placeholder}}</option>
                                    <option *ngFor="let option of field.field_options">{{option}}</option>
                                </select>
                                <div class="col-9 d-flex pr-0 pl-0">
                                    <input type="text" placeholder="{{field.currentPlaceholder}}" class="form-control float-left mr-1 gr-input-wrapper-small" [(ngModel)]="field.value" (input)="disableAddToCart(false)" (change)="disableAddToCart(false)">
                                    <button class="btn btn-primary" style="height: 38px; float: right;" type="button"
                                        [disabled]="field.currentField == ''" (click)="addFieldArr(field)">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 mt-2" *ngIf="field.arr.length != 0">
                                <div *ngFor="let item of field.arr; index as i">
                                    {{field.arr[i]}}
                                    <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    (click)="deleteFieldArr(i, field)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="field.type === 'hybrid-hybrid' && field.show === true" class="col-12 mb-2 d-flex">
                            <select class="form-control col-3 gr-input-wrapper-small" (change)="updateCurrentPlaceholder($event, field.index)" [(ngModel)]="field.currentField">
                                <option disabled selected style="color: #999" value=''>{{field.select_placeholder}}</option>
                                <option *ngFor="let option of field.field_options">{{option}}</option>
                            </select>
                            <select class="form-control col-4 gr-input-wrapper-small" [disabled]="field.currentPlaceholder == '' || field.currentPlaceholder == 'No Refs Available'" (change)="disableAddToCart(false)" [(ngModel)]="field.value">
                                <option disabled selected style="color: #999" value="">{{field.hybrid_placeholder}}</option>
                                <option *ngFor="let option of field.hybrid_options">{{option}}</option>
                            </select>
                            <input type="text" placeholder="{{field.currentPlaceholder}}" class="form-control col-5" [disabled]="field.currentPlaceholder == '' || field.currentPlaceholder == 'No Refs Available'" (input)='disableAddToCart(false)' [(ngModel)]="field.value">
                        </div>

                        <div *ngIf="field.type === 'hybrid-hybrid-add' && field.show === true" class="col-12 mb-2">
                            <div class="w-100 d-flex">
                                <select class="form-control col-3 gr-input-wrapper-small" (change)="updateCurrentPlaceholder($event, field.index)" [(ngModel)]="field.currentField">
                                    <option disabled selected style="color: #999" value=''>{{field.select_placeholder}}</option>
                                    <option *ngFor="let option of field.field_options">{{option}}</option>
                                </select>
                                <select class="form-control col-4 gr-input-wrapper-small" [disabled]="field.currentPlaceholder == '' || field.currentPlaceholder == 'No Refs Available'" (change)="disableAddToCart(false)" [(ngModel)]="field.value">
                                    <option disabled selected style="color: #999" value="">{{field.hybrid_placeholder}}</option>
                                    <option *ngFor="let option of field.hybrid_options">{{option}}</option>
                                </select>
                                <input type="text" placeholder="{{field.currentPlaceholder}}" class="form-control gr-input-wrapper-small" [disabled]="field.currentPlaceholder == '' || field.currentPlaceholder == 'No Refs Available'" (change)="disableAddToCart(false)" (input)="disableAddToCart(false)" [(ngModel)]="field.value">
                                <button class="btn btn-primary" style="height: 38px; float: right; min-width: 39.133px" type="button"
                                    [disabled]="field.currentField == ''" (click)="addFieldArr(field)">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                            <div class="col-12 mt-2" *ngIf="field.arr.length != 0">
                                <div *ngFor="let item of field.arr; index as i">
                                    {{field.arr[i]}}
                                    <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                                    (click)="deleteFieldArr(i, field)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="field.error != ''" class="col-12 ml-2">
                            <p class="hint" style="color: red">{{field.error}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100 observable-section">
                <div class="d-flex col-12">
                    <h6 class="mt-3 col-11" style="color: #007bff">Observable Time</h6>
                    <div class="col-1 mb-2">
                        <button class="btn btn-primary btn-circle float-right" (click)="observableTimeDropdown = !observableTimeDropdown" [ngClass]="{'btn-angle-up': observableTimeDropdown}"  [disabled]="!showObservableTime">
                            <fa-icon *ngIf="!observableTimeDropdown" [icon]="faAngleDown"></fa-icon>
                            <fa-icon *ngIf="observableTimeDropdown" class="angle-up" [icon]="faAngleUp"></fa-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="observableTimeDropdown" class="col-12 mb-2">
                    <!-- <div class=" d-flex mb-1 col-12" style="margin-left: .05rem !important;">    
                        <div class="col-6">
                            <select class="w-100 form-control gr-input-wrapper-small" type="text" [(ngModel)]="timeZone" (change)="disableAddToCart(false)">
                                <option selected style="color: #999" value="">Select Time Zone</option>
                                <option *ngFor="let tz of timeZoneOptions" value="{{tz}}">{{guidedService.showTimezoneAndOffset(tz)}}</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-12" style="margin-left: .05rem !important;">
                        <div class="d-flex col-12 mb-2">
                            <div style="position: relative; width: 67%;">
                                <input style="margin-right: 1%;" class=" gr-input-wrapper-small form-control  " placeholder="First Observed" [max]="last_seen" [owlDateTimeTrigger]="dtPicker2" [owlDateTime]="dtPicker2" [(ngModel)]="firstObserved" (ngModelChange)="checkObserved('first')" (input)="disableAddToCart(true)">
                                <button class="clear-button">
                                    <p class="clear-button-text" *ngIf="firstObserved !== ''" (click)="clearObserved('first')">X</p>
                                </button>
                            </div>
                            <owl-date-time #dtPicker2 [startAt]="lastDateStart" (afterPickerClosed)="disableAddToCart(true)"></owl-date-time>
                            <select style="width: 32%;" class=" gr-input-wrapper-small form-control " type="text" [(ngModel)]="firstObservedTimeZone" (change)="disableAddToCart(false)">
                                <option selected style="color: #999" value="">Select Time Zone</option>
                                <option *ngFor="let tz of timeZoneOptions" value="{{tz}}">{{guidedService.showTimezoneAndOffset(tz)}}</option>
                            </select>
                        </div>
                        <div class="d-flex col-12 mb-2">
                            <div style="position: relative; width: 67%;">
                                <input style="margin-right: 1%;" class="form-control gr-input-wrapper-small " placeholder="Last Observed" [min]="first_seen" [owlDateTimeTrigger]="dtPicker3" [owlDateTime]="dtPicker3" [(ngModel)]="lastObserved" (ngModelChange)="checkObserved('last')" (input)="disableAddToCart(true)">
                                <button class="clear-button">
                                    <p class="clear-button-text" *ngIf="lastObserved !== ''" (click)="clearObserved('last')">X</p>
                                </button>
                            </div>
                            <owl-date-time [startAt]="firstDateStart" #dtPicker3 (afterPickerClosed)="disableAddToCart(true)"></owl-date-time>
                            <select style="width: 32%;" class=" gr-input-wrapper-small form-control" type="text" [(ngModel)]="lastObservedTimeZone" (change)="disableAddToCart(false)">
                                <option selected style="color: #999" value="">Select Time Zone</option>
                                <option *ngFor="let tz of timeZoneOptions" value="{{tz}}">{{guidedService.showTimezoneAndOffset(tz)}}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <input type="number" min="0" placeholder="Number Observed" class="form-control gr-input-wrapper-small" [(ngModel)]="numberObserved" (input)="disableAddToCart(false)">
                        </div>
                    </div>

                </div>
            </div>
            <div class="w-100 observable-section">
                <div class="d-flex col-12">
                    <h6 class="mt-3 col-11" style="color: #007bff">Observable Relationship</h6>
                    <div class="col-1 mb-2">
                        <button class="btn btn-primary btn-circle float-right" (click)="observableAlignmentDropdown = !observableAlignmentDropdown" [ngClass]="{'btn-angle-up': observableAlignmentDropdown}"  [disabled]="!showObservableAlignment">
                            <fa-icon *ngIf="!observableAlignmentDropdown" [icon]="faAngleDown"></fa-icon>
                            <fa-icon *ngIf="observableAlignmentDropdown" class="angle-up" [icon]="faAngleUp"></fa-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="observableAlignmentDropdown" class="col-12">
                    <p class="ml-4">Describe the relationship of this observable; is it relevant to the target or attacker:</p>
                    <ul>
                        <li><b>Target</b> = the observable was used to target the victim of the event.</li>
                        <li><b>Attacker</b> = the observable was used by a threat actor to target the victim.</li>
                        <li><b>Unknown</b> = the observable was used related to the event but cannot be related to the attacker or victim at this time.</li>
                        <li><b>Benign</b> = the observable is not related to either the attacker or the target.</li>
                    </ul>
                </div>
                <div class="col-12 d-flex" *ngIf="showObservableAlignment">
                    <div class="col-6">
                        <select class="form-control col-12 mt-2 mb-2 gr-input-wrapper-small" type="text" (change)="disableAddToCart(false)" [(ngModel)]="currentObservableAlignment">
                            <option disabled selected style="color: #999" value="">Select Observable Alignment</option>
                            <option>Target</option>
                            <option>Attacker</option>
                            <option>Unknown</option>
                            <option>Benign</option>
                        </select>
                    </div>
                    <div class="col-6">
                        <input type="text" *ngIf="currentObservableAlignment != 'Attacker' && currentObservableAlignment != 'Target'" placeholder="{{alignmentPlaceholder[currentObservableAlignment]}}" [disabled]="true" class="form-control col-12 mt-2 gr-input-wrapper-small" >
                        <select *ngIf="currentObservableAlignment == 'Target'" class="form-control col-12 mt-2 mb-2 gr-input-wrapper-small" type="text" [(ngModel)]="currentTarget" (change)="disableAddToCart(false)">
                            <option disabled selected style="color: #999" value="">Select Target Name</option>
                            <option *ngFor="let target of listOfTargets" [value]="target.id">{{target.name}}</option>
                        </select>
                        <select *ngIf="currentObservableAlignment == 'Attacker'" class="form-control col-12 mt-2 mb-2 gr-input-wrapper-small" type="text" [(ngModel)]="currentAttacker" (change)="disableAddToCart(false)">
                            <option disabled selected style="color: #999" value="">Select Attacker Name</option>
                            <option *ngFor="let attacker of listOfAttackers" [value]="attacker.id">{{attacker.name}}</option>
                        </select>
                        <!-- <input type="text" *ngIf="currentObservableAlignment == 'Attacker'" placeholder="{{alignmentPlaceholder[currentObservableAlignment]}}" class="form-control col-12 mt-2 gr-input-wrapper-small" [(ngModel)]="currentAttacker" (input)="disableAddToCart(false)"> -->
                    </div>
                </div>
            </div>
            <app-guided-cart class="w-100" #guidedCart [currentComponent]="component" [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [editModeEvent]="editModeEmitter" [syncCartEvent]="syncCartEmitter" [getEditItemEvent]="getEditItemEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart>
        </div>
    </div>
</div>

<!-- <app-add-component #appAddComponent style="display: none" [objectSelectionInput]="objectSelection"></app-add-component> -->