export const STIXObjectTypes = [
    'attack-pattern', 
    'campaign', 
    'course-of-action',
    'extension-definition',
    'grouping', 
    'identity', 
    'incident', 
    'indicator', 
    'infrastructure', 
    'intrusion-set',
    'language-content',
    'location', 
    'malware', 
    'malware-analysis', 
    'marking-definition',
    'note', 
    'observed-data', 
    'opinion', 
    'report', 
    'threat-actor', 
    'tool',
    'vulnerability',
    'relationship',
    'sighting',
    'artifact',
    'autonomous-system',
    'directory',
    'domain-name',
    'email-addr',
    'email-message',
    'file',
    'ipv4-addr',
    'ipv6-addr',
    'mac-addr',
    'mutex',
    'network-traffic',
    'process',
    'software',
    'url',
    'user-account',
    'windows-registry-key',
    'x509-certificate'
]
/** Meta Objects not added */

export interface SelectedObject {
    id: number,
    version: string;
}