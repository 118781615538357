//global contents

import { Artifact } from "./artifact";
import { AttackPattern } from "./attack-pattern";
import { Autonomous } from "./autonomous";
import { Campaign } from "./campaign";
import { Coa } from "./coa";
import { Directory } from "./directory";
import { DomainName } from "./domain-name";
import { EmailAddress } from "./email-address";
import { EmailMessage } from "./email-message";
import { File } from "./file";
import { Grouping } from "./grouping";
import { Identity } from "./identity";
import { Incident } from "./incident";
import { Indicator } from "./indicator";
import { Infrastructure } from "./infrastructure";
import { IntrusionSet } from "./intrusion-set";
import { Ipv4Addr } from "./ipv4";
import { Ipv6Addr } from "./ipv6";
import { LanguageContent } from "./language-content";
import { ExtensionDefinition } from "./extension-definition";
import { Location } from "./location";
import { MacAddress } from "./mac-address";
import { Malware } from "./malware";
import { MalwareAnalysis } from "./malware-analysis";
import { MarkingDefinition } from "./marking-definition";
import { Mutex } from "./mutex";
import { NetworkTraffic } from "./network-traffic";
import { Note } from "./note";
import { ObservedData } from "./observed-data";
import { Opinion } from "./opinion";
import { Process } from "./process";
import { Relationship } from "./relationship";
import { Report } from "./report";
import { Sighting } from "./sighting";
import { Software } from "./software";
import { ThreatActor } from "./threat-actor";
import { Tool } from "./tool";
import { Url } from "./url";
import { UserAccount } from "./user-account";
import { Vulnerability } from "./vulnerability";
import { WindowsRegistryKey } from "./windows-registry-key";
import { XCert } from "./x-cert";
import { StixService } from "../stix-service.service";

let modelMap = new Map<string, Object>();
// modelMap.set("artifact", new Artifact());
// modelMap.set("attack-pattern", new AttackPattern());
// modelMap.set("autonomous-system", new Autonomous());
// modelMap.set("campaign", new Campaign());
// modelMap.set("course-of-action", new Coa());
// modelMap.set("directory", new Directory());
// modelMap.set("domain-name", new DomainName());
// modelMap.set("email-addr", new EmailAddress());
// modelMap.set("email-message", new EmailMessage());
// modelMap.set("file", new File());
// modelMap.set("grouping", new Grouping());
// modelMap.set("identity", new Identity());
// modelMap.set("incident", new Incident());
// modelMap.set("indicator", new Indicator());
// modelMap.set("infrastructure", new Infrastructure());
// modelMap.set("intrusion-set", new IntrusionSet());
// modelMap.set("ipv4-addr", new Ipv4Addr());
// modelMap.set("ipv6-addr", new Ipv6Addr());
// modelMap.set("location", new Location());
// modelMap.set("mac-addr", new MacAddress());
// modelMap.set("malware-analysis", new MalwareAnalysis());
// modelMap.set("malware", new Malware());
// modelMap.set("malware-analysis", new MalwareAnalysis());
// modelMap.set("marking-definition", new MarkingDefinition());
// modelMap.set("mutex", new Mutex());
// modelMap.set("network-traffic", new NetworkTraffic());
// modelMap.set("note", new Note());
// modelMap.set("observed-data", new ObservedData());
// modelMap.set("opinion", new Opinion());
// modelMap.set("process", new Process());
// modelMap.set("relationship", new Relationship());
// modelMap.set("report", new Report());
// modelMap.set("sighting", new Sighting());
// modelMap.set("software", new Software());
// modelMap.set("threat-actor", new ThreatActor());
// modelMap.set("tool", new Tool());
// modelMap.set("url", new Url());
// modelMap.set("user-account", new UserAccount());
// modelMap.set("vulnerability", new Vulnerability());
// modelMap.set("windows-registry-key", new WindowsRegistryKey());
// modelMap.set("x509-certificate", new XCert());
// modelMap.set("report", new Report());
// modelMap.set("language-content", new LanguageContent());
// modelMap.set("extension-definition", new ExtensionDefinition());

export const COMPONENT_MAP = modelMap;
