<div class="pl-3 pb-3 d-flex justify-content-between">
    <div>
        <button class="btn btn-sm btn-outline-primary mr-2" [ngClass]="{'btn-primary': activeTable == 'profile', 'btn-outline-primary': activeTable != 'profile'}" [disabled]="activeTable == 'profile'" (click)="changeActiveTable('profile')">
            <fa-icon [icon]="faCloud" class="pr-1"></fa-icon>
            My Profile
        </button>
        <button class="btn btn-sm" [ngClass]="{'btn-primary': activeTable == 'local', 'btn-outline-primary': activeTable != 'local'}" [disabled]="activeTable == 'local'" (click)="changeActiveTable('local')">
            <fa-icon [icon]="faHdd" class="pr-1"></fa-icon>
            Local
        </button>
    </div>
    <form class="form-inline">
        <select class="form-control" (change)="setSearchType($event)">
            <option value="name" selected>Name</option>
            <option value="report">Report #</option>
        </select>
        <input type="search" class="form-control mr-1 ml-1" style="width:250px;" placeholder="Search by name or report #..." [(ngModel)]="searchText" name="searchText" (ngModelChange)="clearSearch()"/>
        <button class="btn btn-md btn-primary" (click)="this.setSearchPage(); search()">
            <fa-icon [icon]="faSearch"></fa-icon>
        </button>
    </form>
</div>
<div *ngIf="!isLoading && isSaving && nameCheck && newBundle.name != ''" class="col-12 text-center">
    <p class="text-danger">**Name must be unique**</p>
</div>
<div *ngIf="!isLoading && activeTable == 'profile' && stixService.imxServerConnectionError != ''" class="col-12 text-center">
    <p class="text-danger">**{{stixService.imxServerConnectionError}}**</p>
</div>
<table *ngIf="(activeTable == 'local' || (activeTable == 'profile' && stixService.imxServerConnectionError == ''))" class="table col-12 mb-0">
    <thead style="border-bottom: 2px solid lightgray;" >
        <tr>
            <th scope="col" class="text-center" style="width: 15%; min-width: 150px;">
                <span  style="cursor:pointer;" (click)="sortTable('name')">
                    Name
                </span>
                <fa-icon *ngIf="sortKey === 'name-desc'" [icon]="faAngleDown">
                </fa-icon>
                <fa-icon *ngIf="sortKey === 'name-asc'" [icon]="faAngleUp">
                </fa-icon>
            </th>
            <th scope="col" class="text-center nowrap" style="width: 7.5%; min-width: 80px;">
                <span  style="cursor:pointer;" (click)="sortTable('reportNo')">
                    Report #
                </span>
                <fa-icon *ngIf="sortKey === 'reportNo-desc'" [icon]="faAngleDown">
                </fa-icon>
                <fa-icon *ngIf="sortKey === 'reportNo-asc'" [icon]="faAngleUp">
                </fa-icon>
            </th>
            <th scope="col" *ngIf="mode == 'basic'" class="text-center" style="width: 7.5%; min-width: 80px;">
                <span>
                    TLP Color
                </span>
            </th>
            <th scope="col" class="text-center" style="width: 20%; min-width: 200px;">
                <span style="cursor:pointer;" (click)="sortTable('created')">
                    Created
                </span>
                <fa-icon *ngIf="sortKey === 'created-desc'" [icon]="faAngleDown">
                </fa-icon>
                <fa-icon *ngIf="sortKey === 'created-asc'" [icon]="faAngleUp">
                </fa-icon>
            </th>
            <th scope="col" class="text-center" style="width: 20%; min-width: 200px;">
                <span style="cursor:pointer;" (click)="sortTable('modified')">
                    Modified
                </span>
                <fa-icon *ngIf="sortKey === 'modified-desc'" [icon]="faAngleDown">
                </fa-icon>
                <fa-icon *ngIf="sortKey === 'modified-asc'" [icon]="faAngleUp">
                </fa-icon>
            </th>
            <th scope="col" *ngIf="mode == 'basic'" class="text-center" style="width: 10%; min-width: 100px;">
                <span>
                    Publication Status
                </span>
            </th>
            <th scope="col" class="text-center nowrap" (click)="sortTable('internal_status')" style="width: 10%; min-width: 100px;">
                <span style="cursor:pointer;">
                    Internal Status
                </span>
                <fa-icon *ngIf="sortKey === 'internal_status-desc'" [icon]="faAngleDown">
                </fa-icon>
                <fa-icon *ngIf="sortKey === 'internal_status-asc'" [icon]="faAngleUp">
                </fa-icon>
            </th>
            <th scope="col" class="text-center" style="width: 10%; min-width: 100px;">
                <span>
                    Actions
                </span>
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="isLoading">
            <tr class="expanded-panel">
                <td colspan="8" class="text-center">
                    <span class="spinner-grow spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
                </td>
            </tr>
        </ng-container>
        <!-- <ng-container *ngIf="!isLoading && mode == 'saving' && !isSaving">
            <tr class="expanded-panel">
                <td colspan="6" class="text-center col-12">
                    <button class="btn btn-sm btn-success" (click)="activateSave('show')">
                        <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
                        New Bundle
                    </button>
                </td>
            </tr>
        </ng-container> -->
        <ng-container *ngIf="!isLoading && isSaving">
            <tr class="expanded-panel">
                <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                    class="row-name text-center">
                    <input class="form-control name" (input)="newBundleNameChange($event)" [(ngModel)]="newBundle.name">
                </td>
                <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                    class="row-name text-center">
                    {{ newBundle.reportNo }}
                </td>
                <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                    class="row-name text-center">
                    {{ newBundle.tlp }}
                </td>
                <td style="cursor:pointer; color:#007bff; font-weight: normal; overflow-wrap: break-word;"
                    class="row-name text-center">
                    {{ newBundle.created }}
                </td>
                <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                    class="row-name text-center">
                    {{ newBundle.modified }}
                </td>
                <!-- <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                    class="row-name text-center">
                    {{ obj._source.publicationServer }}<br>
                    {{ obj._source.publicationRoot }}<br>
                    {{ obj._source.publicationStatus  }}
                </td> -->
                <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                    class="row-name text-center">
                    <select class="form-control"
                        (change)="changeNewBundle($event)" [ngModel]="newBundle.internal_status" height="38px">
                        <option value=''>TBD</option>
                        <option value='In Progress'>In Progress</option>
                        <option value='Blocked'>Blocked</option>
                        <option value='Completed'>Completed</option>
                    </select>
                </td>
                <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                    class="row-name text-center">

                    <!-- Button For Save Modal -->
                    <button *ngIf="mode == 'saving'" type="button" class="btn btn-fa btn-success"
                        (click)="saveBundle(newBundle, true)"
                        title="Click to save new bundle" [disabled]="nameCheck">
                        <fa-icon [icon]="faSave"></fa-icon>
                    </button>
                    <button *ngIf="mode == 'saving'" type="button" class="btn btn-fa btn-secondary ml-1"
                        (click)="activateSave('hide')"
                        title="Click to cancel saving new bundle">
                        <fa-icon [icon]="faBan"></fa-icon>
                    </button>
                </td>
            </tr>
        </ng-container>

        <!-- Active table is Profile and not searching -->
        <ng-container *ngIf="!isSearching && (activeTable == 'profile' && stixService.serverSavedBundles.length != 0 && !isLoading)">
            <ng-container *ngFor="let obj of stixService.serverSavedBundles; let i = index;" >
                <tr [ngClass]="{'selected-bundle': obj._source.name == currentSelectedBundleName}">
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <div class="d-inline-flex align-items-center" *ngIf="!editingName || (editingName && editingIndex !== i)">
                            <span>{{ obj._source.name }}</span>
                            <fa-icon [icon]="faEdit" (click)="editBundleName($event, i, obj._source.name)" class="bundle-edit ml-2" style="color: #dee2e6;"></fa-icon>
                        </div>
                        <span class="d-inline-flex align-items-center" *ngIf="editingIndex === i && editingName">
                            <input class="form-control name mr-2" (click)="$event.stopPropagation()" style="width: 200px;" type="text" [(ngModel)]="editingNameText" />
                            <button class="mr-1 btn btn-success" (click)="renameBundle($event, obj, renameErrorModal);" title="Rename bundle">
                                <fa-icon [icon]="faSave"></fa-icon>
                            </button>
                            <button  class="btn btn-danger" (click)="cancelBundleNameEdit($event);" title="Cancel bundle rename">
                                <span style="font-weight: bold;">X</span>
                            </button>
                        </span>
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        {{ obj._source.reportNo }}
                    </td>
                    <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="changeShowSavedBundles(i)">
                        {{ obj._source.tlp }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal; overflow-wrap: break-word;"
                        class="row-name text-center nowrap" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        {{ obj._source.created }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center nowrap" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        {{ obj._source.modified }}
                    </td>
                    <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="changeShowSavedBundles(i)">
                        {{ obj._source.publicationServer }}<br>
                        {{ obj._source.publicationRoot }}<br>
                        {{ obj._source.publicationStatus  }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center">
                        <select class="form-control"
                            (change)="internalStatusChange($event, i)" [ngModel]="obj._source.internal_status" height="38px">
                            <option value='TBD'>TBD</option>
                            <option value='In Progress'>In Progress</option>
                            <option value='Blocked'>Blocked</option>
                            <option value='Completed'>Completed</option>
                        </select>
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center action-buttons">

                        <!-- Button For Save Modal -->
                        <!-- <button *ngIf="mode == 'saving'" type="button" class="btn btn-fa btn-success mr-1"
                            (click)="saveBundle(obj, false)" [disabled]="isSaving"
                            title="Click to overwrite">
                            <fa-icon [icon]="faSave"></fa-icon>
                        </button> -->

                        <button *ngIf="mode == 'saving' && bundleRowNumber !== i" type="button" class="btn btn-fa btn-primary mr-1"
                            title="Click to preview the contents of the bundle"
                            (click)="changeShowSavedBundles(i)">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </button>
   
                        <button *ngIf="mode == 'saving' && bundleRowNumber == i" type="button" class="btn btn-fa btn-danger mr-1"
                        title="Click to close the contents of the bundle"
                        (click)="changeShowSavedBundles(i)">
                            X
                        </button>
                    
                        <!-- <button *ngIf="mode == 'saving'" type="button" class="btn btn-fa btn-success mr-1"
                            title="Click to rename the bundle"
                            (click)="editBundleName(i)">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button> -->

                        <!-- Actions for My Profile Page -->
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-primary"
                            (click)="editBundle(obj)"
                            title="Click to load and edit the bundle">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-danger ml-1"
                            (click)="deleteBundle(obj)"
                            title="Click to delete the bundle">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-success ml-1"
                            (click)="transferBundle(obj)"
                            title="Click to transfer bundle to Local">
                            <fa-icon [icon]="faArrowCircleRight"></fa-icon>
                        </button>
                    </td>
                </tr>
                <tr class="expanded-panel" *ngIf="bundleRowNumber == i">
                    <td colspan="8">
                        
                        <pre class="preview-json-window save-json">{{ savedBundleResults | json }}</pre>
                    </td>
                </tr>
            </ng-container>
        </ng-container>

        <!-- Active table is Local and not searching -->
        <ng-container *ngIf="!isSearching && (activeTable == 'local' && localPagination.length != 0 && !isLoading)">
            <ng-container *ngFor="let obj of localPagination; let i = index;" >
                <tr  [ngClass]="{'selected-bundle': obj.name == currentSelectedBundleName}">
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <div class="d-inline-flex align-items-center" *ngIf="!editingName || (editingName && editingIndex !== i)">
                            <span>{{ obj.name }}</span>
                            <fa-icon [icon]="faEdit" (click)="editBundleName($event, i, obj.name)" class="bundle-edit ml-2" style="color: #dee2e6;"></fa-icon>
                        </div>
                        <span class="d-inline-flex align-items-center" *ngIf="editingIndex === i && editingName">
                            <input class="form-control name mr-2" (click)="$event.stopPropagation()" style="width: 200px;" type="text" [(ngModel)]="editingNameText" />
                            <button class="mr-1 btn btn-success" (click)="renameBundle($event, obj, renameErrorModal);" title="Rename bundle">
                                <fa-icon [icon]="faSave"></fa-icon>
                            </button>
                            <button  class="btn btn-danger" (click)="cancelBundleNameEdit($event);" title="Cancel bundle rename">
                                <span style="font-weight: bold;">X</span>
                            </button>
                        </span>
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.reportNo }}
                    </td>
                    <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.tlp }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal; overflow-wrap: break-word;"
                        class="row-name text-center nowrap" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.created }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center nowrap" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.modified }}
                    </td>
                    <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.publicationServer }}<br>
                        {{ obj.publicationRoot }}<br>
                        {{ obj.publicationStatus  }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        <select class="form-control"
                            (change)="internalStatusChange($event, i)" [ngModel]="obj.internal_status" height="38px">
                            <option value='TBD'>TBD</option>
                            <option value='In Progress'>In Progress</option>
                            <option value='Blocked'>Blocked</option>
                            <option value='Completed'>Completed</option>
                        </select>
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center action-buttons">

                        <!-- Button For Save Modal -->
                        <!-- <button *ngIf="mode == 'saving'" type="button" class="btn btn-fa btn-success mr-1"
                            (click)="saveBundle(obj, false)" [disabled]="isSaving"
                            title="Click to load and edit the bundle">
                            <fa-icon [icon]="faSave"></fa-icon>
                        </button> -->

                        <button *ngIf="mode == 'saving' && bundleRowNumber !== i" type="button" class="btn btn-fa btn-primary mr-1"
                            title="Click to preview the contents of the bundle"
                            (click)="changeShowSavedBundles(i)">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </button>

                        <button *ngIf="mode == 'saving' && bundleRowNumber == i" type="button" class="btn btn-fa btn-danger mr-1"
                            title="Click to close the contents of the bundle"
                            (click)="changeShowSavedBundles(i)">
                            X
                        </button>

                        <!-- Actions for My Profile Page -->
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-primary"
                            (click)="editBundle(obj)"
                            title="Click to load and edit the bundle">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-danger ml-1"
                            (click)="deleteBundle(obj)"
                            title="Click to delete the bundle">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-success ml-1"
                            (click)="transferBundle(obj)"
                            title="Click to transfer the bundle to your profile">
                            <fa-icon [icon]="faArrowCircleRight"></fa-icon>
                        </button>
                    </td>
                </tr>
                <tr class="expanded-panel" *ngIf="bundleRowNumber == i">
                    <td colspan="8">
                        
                        <pre class="preview-json-window save-json">{{ savedBundleResults | json }}</pre>
                    </td>
                </tr>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="!isLoading && ((activeTable == 'local' && localPagination.length == 0) || (activeTable == 'profile' && stixService.serverSavedBundles.length == 0))">
            <tr class="expanded-panel">
                <td colspan="8" class="text-center">
                    <span style="font-weight: bold; text-align: left; white-space: nowrap;">No Bundles saved for user in target location</span>
                </td>
            </tr>
        </ng-container>

        <!-- Active table is Profile and is searching -->
        <ng-container *ngIf="isSearching && (activeTable == 'profile' && searchResultsProfile.length != 0 && !isLoading)">
            <ng-container *ngFor="let obj of searchResultsProfile; let i = index;" >
                <tr  [ngClass]="{'selected-bundle': obj._source.name == currentSelectedBundleName}">
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <div class="d-inline-flex align-items-center" *ngIf="!editingName || (editingName && editingIndex !== i)">
                            <span>{{ obj._source.name }}</span>
                            <fa-icon [icon]="faEdit" (click)="editBundleName($event, i, obj._source.name)" class="bundle-edit ml-2" style="color: #dee2e6;"></fa-icon>
                        </div>
                        <span class="d-inline-flex align-items-center" *ngIf="editingIndex === i && editingName">
                            <input class="form-control name mr-2" (click)="$event.stopPropagation()" style="width: 200px;" type="text" [(ngModel)]="editingNameText" />
                            <button class="mr-1 btn btn-success" (click)="renameBundle($event, obj, renameErrorModal);" title="Rename bundle">
                                <fa-icon [icon]="faSave"></fa-icon>
                            </button>
                            <button  class="btn btn-danger" (click)="cancelBundleNameEdit($event);" title="Cancel bundle rename">
                                <span style="font-weight: bold;">X</span>
                            </button>
                        </span>
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        {{ obj._source.reportNo }}
                    </td>
                    <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="changeShowSavedBundles(i)">
                        {{ obj._source.tlp }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal; overflow-wrap: break-word;"
                        class="row-name text-center nowrap" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        {{ obj._source.created }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center nowrap" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        {{ obj._source.modified }}
                    </td>
                    <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="changeShowSavedBundles(i)">
                        {{ obj._source.publicationServer }}<br>
                        {{ obj._source.publicationRoot }}<br>
                        {{ obj._source.publicationStatus  }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center">
                        <select class="form-control"
                            (change)="internalStatusChange($event, i)" [ngModel]="obj._source.internal_status" height="38px">
                            <option value='TBD'>TBD</option>
                            <option value='In Progress'>In Progress</option>
                            <option value='Blocked'>Blocked</option>
                            <option value='Completed'>Completed</option>
                        </select>
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center action-buttons">

                        <!-- Button For Save Modal -->
                        <!-- <button *ngIf="mode == 'saving'" type="button" class="btn btn-fa btn-success mr-1"
                            (click)="saveBundle(obj, false)" [disabled]="isSaving"
                            title="Click to overwrite">
                            <fa-icon [icon]="faSave"></fa-icon>
                        </button> -->

                        <button *ngIf="mode == 'saving' && bundleRowNumber !== i" type="button" class="btn btn-fa btn-primary mr-1"
                            title="Click to preview the contents of the bundle"
                            (click)="changeShowSavedBundles(i)">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </button>

                        <button *ngIf="mode == 'saving' && bundleRowNumber == i" type="button" class="btn btn-fa btn-danger mr-1"
                            title="Click to close the contents of the bundle"
                            (click)="changeShowSavedBundles(i)">
                            X
                        </button>

                        <!-- Actions for My Profile Page -->
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-primary"
                            (click)="editBundle(obj)"
                            title="Click to load and edit the bundle">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-danger ml-1"
                            (click)="deleteBundle(obj)"
                            title="Click to delete the bundle">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-success ml-1"
                            (click)="transferBundle(obj)"
                            title="Click to transfer bundle to Local">
                            <fa-icon [icon]="faArrowCircleRight"></fa-icon>
                        </button>
                    </td>
                </tr>
                <tr class="expanded-panel" *ngIf="bundleRowNumber == i">
                    <td colspan="8">
                        
                        <pre class="preview-json-window save-json">{{ savedBundleResults | json }}</pre>
                    </td>
                </tr>
            </ng-container>
        </ng-container>

        <!-- Active table is Local and is Searching -->
        <ng-container *ngIf="isSearching && (activeTable == 'local' && searchResultsLocal.length != 0 && !isLoading)">
            <ng-container *ngFor="let obj of searchResultsLocal; let i = index;" >
                <tr  [ngClass]="{'selected-bundle': obj.name == currentSelectedBundleName}">
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <div class="d-inline-flex align-items-center" *ngIf="!editingName || (editingName && editingIndex !== i)">
                            <span>{{ obj.name }}</span>
                            <fa-icon [icon]="faEdit" (click)="editBundleName($event, i, obj.name)" class="bundle-edit ml-2" style="color: #dee2e6;"></fa-icon>
                        </div>
                        <span class="d-inline-flex align-items-center" *ngIf="editingIndex === i && editingName">
                            <input class="form-control name mr-2" (click)="$event.stopPropagation()" style="width: 200px;" type="text" [(ngModel)]="editingNameText" />
                            <button class="mr-1 btn btn-success" (click)="renameBundle($event, obj, renameErrorModal);" title="Rename bundle">
                                <fa-icon [icon]="faSave"></fa-icon>
                            </button>
                            <button  class="btn btn-danger" (click)="cancelBundleNameEdit($event);" title="Cancel bundle rename">
                                <span style="font-weight: bold;">X</span>
                            </button>
                        </span>
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.reportNo }}
                    </td>
                    <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.tlp }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal; overflow-wrap: break-word;"
                        class="row-name text-center nowrap" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.created }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center nowrap" (click)="mode == 'saving' ? selectCurrentBundle(obj) : changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.modified }}
                    </td>
                    <td *ngIf="mode == 'basic'" style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center" (click)="changeShowSavedBundles(i)">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        {{ obj.publicationServer }}<br>
                        {{ obj.publicationRoot }}<br>
                        {{ obj.publicationStatus  }}
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center">
                        <!-- <fa-icon *ngIf="getTaxiiServer(obj.taxiiServer) != 'Default' && getTaxiiServer(obj.taxiiServer) != 'AIS'"[icon]="faStop" class="color-red"></fa-icon> -->
                        <select class="form-control"
                            (change)="internalStatusChange($event, i)" [ngModel]="obj.internal_status" height="38px">
                            <option value='TBD'>TBD</option>
                            <option value='In Progress'>In Progress</option>
                            <option value='Blocked'>Blocked</option>
                            <option value='Completed'>Completed</option>
                        </select>
                    </td>
                    <td style="cursor:pointer; color:#007bff; font-weight: normal;"
                        class="row-name text-center action-buttons">

                        <!-- Button For Save Modal -->
                        <!-- <button *ngIf="mode == 'saving'" type="button" class="btn btn-fa btn-success mr-1"
                            (click)="saveBundle(obj, false)" [disabled]="isSaving"
                            title="Click to load and edit the bundle">
                            <fa-icon [icon]="faSave"></fa-icon>
                        </button> -->

                        <button *ngIf="mode == 'saving' && bundleRowNumber !== i" type="button" class="btn btn-fa btn-primary mr-1"
                            title="Click to preview the contents of the bundle"
                            (click)="changeShowSavedBundles(i)">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </button>

                        <button *ngIf="mode == 'saving' && bundleRowNumber == i" type="button" class="btn btn-fa btn-danger mr-1"
                            title="Click to close the contents of the bundle"
                            (click)="changeShowSavedBundles(i)">
                            X
                        </button>

                        <!-- Actions for My Profile Page -->
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-primary"
                            (click)="editBundle(obj)"
                            title="Click to load and edit the bundle">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-danger ml-1"
                            (click)="deleteBundle(obj)"
                            title="Click to delete the bundle">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                        <button *ngIf="mode == 'basic'" type="button" class="btn btn-fa btn-success ml-1"
                            (click)="transferBundle(obj)"
                            title="Click to transfer the bundle to your profile">
                            <fa-icon [icon]="faArrowCircleRight"></fa-icon>
                        </button>
                    </td>
                </tr>
                <tr class="expanded-panel" *ngIf="bundleRowNumber == i">
                    <td colspan="8">
                        
                        <pre class="preview-json-window save-json">{{ savedBundleResults | json }}</pre>
                    </td>
                </tr>
            </ng-container>
        </ng-container>

        <!-- If no search result and is searching -->
        <ng-container *ngIf="isSearching && (((activeTable == 'local' && searchResultsLocal.length == 0) || (activeTable == 'profile' && searchResultsProfile.length == 0)))">
            <tr class="expanded-panel">
                <td colspan="8" class="text-center">
                    <span style="font-weight: bold; text-align: left; white-space: nowrap;">No Bundles saved for user in target location</span>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

<div *ngIf="mode == 'saving'" class="footer d-flex pb-2 pt-2 pr-3 pl-3 align-items-center justify-content-between save-bar">
    <span class="nowrap">
        Bundle Name:    
    </span>
    <input type="text" class="form-control mr-1 ml-2" placeholder="Enter name of the bundle..." [(ngModel)]="currentSelectedBundleName"  (ngModelChange)="trimBundleName()" name="saveText"/>
    <button style="height: 38px;" class="btn btn-sm btn-success ml-1" (click)="searchBundleName(errorModal)" [disabled]="isLoading || isFindingBundle || !currentSelectedBundleName">
        <span *ngIf="!isFindingBundle">
            <fa-icon [icon]="faSave" class="mr-1"></fa-icon>
            Save
        </span>

        <span *ngIf="isFindingBundle" class="spinner-grow spinner-grow-sm" role="status"
        aria-hidden="true"></span>
    </button>
</div>

<div class="footer mt-1 mb-1 d-flex justify-content-center" *ngIf="!isSearching && (serverTotalPages !== 0 && activeTable == 'profile' && stixService.imxServerConnectionError == '' )" [ngClass]="{'footer-no-bottom': serverTotalPages == 1}">
    <div class="d-inline-flex ml-3">
        <div *ngIf="serverTotalPages !== 1 d-flex"  class="span-footer float-left">
            <span >Page: {{currentPage}}/{{serverTotalPages}}</span>
        </div>
        <div class="buttons-footer">
            <button *ngIf="currentPage > 1" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('left')">
                <fa-icon [icon]="faArrowLeft"></fa-icon>
            </button>
            <button *ngIf="currentPage < serverTotalPages" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('right')">
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
    </div>
</div>
<div class="footer ml-4 mt-1 mb-1 d-flex justify-content-center" *ngIf="!isSearching && (localTotalPages !== 0 && activeTable == 'local')" [ngClass]="{'footer-no-bottom': localTotalPages == 1}">
    <div *ngIf="localTotalPages !== 1"  class="span-footer float-left">
        <span >Page: {{currentPage}}/{{localTotalPages}}</span>
    </div>
    <div class="buttons-footer">
        <button *ngIf="currentPage > 1" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('left')">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
        </button>
        <button *ngIf="currentPage < localTotalPages" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('right')">
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
    </div>
</div>

<div class="footer mt-1 mb-1 d-flex justify-content-center" *ngIf="isSearching && (totalProfileSearchBundles !== 0 && activeTable == 'profile' && stixService.imxServerConnectionError == '' )" [ngClass]="{'footer-no-bottom': totalProfileSearchBundles == 1}">
    <div *ngIf="totalProfileSearchBundles !== 1"  class="span-footer float-left">
        <span >Page: {{currentSearchPage}}/{{totalProfileSearchBundles}}</span>
    </div>
    <div class="buttons-footer">
        <button *ngIf="currentSearchPage > 1" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('left')">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
        </button>
        <button *ngIf="currentSearchPage < totalProfileSearchBundles" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('right')">
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
    </div>
</div>
<div class="footer ml-4 mt-1 mb-1 d-flex justify-content-center" *ngIf="isSearching && (totalLocalSearchBundles !== 0 && activeTable == 'local')" [ngClass]="{'footer-no-bottom': localTotalPages == 1}">
    <div *ngIf="totalLocalSearchBundles !== 1"  class="span-footer float-left">
        <span >Page: {{currentSearchPage}}/{{totalLocalSearchBundles}}</span>
    </div>
    <div class="buttons-footer">
        <button *ngIf="currentSearchPage > 1" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('left')">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
        </button>
        <button *ngIf="currentSearchPage < totalLocalSearchBundles" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('right')">
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
    </div>
</div>

<ng-template #renameErrorModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="error-modal-title">
            A bundle with the same name already exists
        </h4>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Cancel"
            (click)="continueOverwrite(false)">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Close
        </button>
    </div>
</ng-template>

<ng-template #errorModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="error-modal-title">
            Overwrite bundle?
        </h4>
    </div>

    <div class="modal-body pb-0">
        <p>A bundle with the same name has been found.</p>
        <p>Confirm that you would like to overwrite the bundle, or cancel.</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-success" aria-label="Confirm"
            (click)="continueOverwrite(true)">
            <fa-icon [icon]="faCheck" class="pr-1"></fa-icon>
            Confirm
        </button>
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Cancel"
            (click)="continueOverwrite(false)">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
    </div>
</ng-template>