export const IdContributingProperties = [
  { 'artifact': ['hashes', 'payload_bin'] },
  { 'autonomous-system': ['number'] },
  { 'directory': ['path'] },
  { 'domain-name': ['value'] },
  { 'email-addr': ['value'] },
  { 'email-message': ['body', 'from_ref', 'subject'] },
  { 'file': ['extensions', 'hashes', 'name', 'parent_directory_ref'] },
  { 'ipv4-addr': ['value'] },
  { 'ipv6-addr': ['value'] },
  { 'mac-addr': ['value'] },
  { 'mutex': ['name'] },
  { 'network-traffic': ['dst_port', 'dst_ref', 'protocols', 'src_port', 'src_ref', 'start'] },
  { 'software': ['cpe', 'name', 'swid', 'vendor', 'version'] },
  { 'url': ['value'] },
  { 'user-account': ['account_login', 'account_type', 'user_id'] },
  { 'windows-registry-key': ['key', 'values'] },
  { 'x509-certificate': ['hashes', 'serial_number'] },
]