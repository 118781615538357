import { Component, OnInit, Inject } from '@angular/core';
import { Extension } from "../../models/extension";
import { StixService } from '../../stix-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ArchiveExtension } from './../predefined/archive-ext';
import { PDFExtension } from './../predefined/pdf-ext';
import { RasterImageExtension } from './../predefined/raster-image-ext';
import { NTFSExtension } from './../predefined/ntfs-ext';
import { WindowsPEBinaryExtension } from './../predefined/windows-pebinary-ext';
import { HTTPRequestExtension } from './../predefined/http-request-ext';
import { ICMPExtension } from './../predefined/icmp-ext';
import { faTrash, faEdit, faPlus, faBan, faWrench } from "@fortawesome/free-solid-svg-icons";
import { SocketExtension } from './../predefined/socket-ext';
import { TCPExtension } from './../predefined/tcp-ext';
import { WindowsProcessExtension } from './../predefined/windows-process-ext';
import { WindowsServiceExtension } from './../predefined/windows-service-ext';
import { UnixAccountExtension } from './../predefined/unix-account-ext';
import { X509V3Extensions } from './../predefined/x509_v3_extensions';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ExtensionsData {
  selectors: [],
  lang: '',
  marking_ref: ''
}

@Component({
  selector: 'app-extensions-dialog',
  templateUrl: './extensions-dialog.component.html',
  styleUrls: ['./extensions-dialog.component.css']
})
export class ExtensionsDialogComponent implements OnInit {
  faTrash   = faTrash;
  faEdit    = faEdit;
  faPlus    = faPlus;
  faBan     = faBan;
  faWrench = faWrench;

  currentExtension: any = new Extension('', '', new Map());
  isAddingExtensions: boolean = false;
  isEditingExtension: boolean = false;
  newExtensions: Extension[] = [];
  errorMessage: string = '';
  selectedExtension: any;
  page: string;
  pageSize: number;
  validationMessage: string = ''; //Error message for the modal window
  predefined: boolean = true;
  toplevel: boolean = false;
  type_options: string[] = [];
  toplevel_props: string[] = [];
  headingTracker: number = 0;
  headings: string[] = []; // DEPRECATED
  property: string = '';
  value: string = '';
  currentArrString = {};
  subObject = {};
  editedSubObject;
  subObjectType: string;
  validationError = new Map();
  objectRegex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
  predefinedRegex = new RegExp(/^{"([-_a-z0-9]*)":{(.*)}}$/);

  customProp = new Map();
  nameError = '';

  hashes = new Map();
  hashOptions = [
    { name: 1, value: 'MD5' },
    { name: 2, value: 'SHA-1' },
    { name: 3, value: 'SHA-256' },
    { name: 3, value: 'SHA-512' },
    { name: 4, value: 'SHA3-256' },
    { name: 5, value: 'SHA3-512' },
    { name: 6, value: 'SSDEEP' },
    { name: 7, value: 'TLSH' }
  ];
  regExIntegerMatch = new RegExp('^cb|dwX|dwY|dwXSize|dwYSize|dwXCountChars|dwYCountChars$');
  regExStringMatch = new RegExp('^lpDesktop|lpTitle|dwFillAttribute|dwFlags|wShowWindow|hStdInput|hStdOutput|hStdError$');
  customStringPropKeys: string[] = [];
  customArrPropKeys: string[] = [];
  modalRef: any;
  modalError = new Map();
  tooltip: string;
  componentData: any;

  constructor(public modalService: NgbModal, public stixService: StixService,
    public dialogRef:
      MatDialogRef<ExtensionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
    ExtensionsData) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onX(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    // let result = this.addButton();

    // if(result.valid === false){
    //   this.errorMessage = result.errorMessage;
    //   return;
    // }

    console.log(this.currentExtension);
    this.dialogRef.close(this.currentExtension);
  }

  validID(): boolean {
    if (this.currentExtension.extension_id != '') {      
      const idRegex = new RegExp('^extension-definition--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$');
      if (!idRegex.test(this.currentExtension.extension_id)) {
        this.errorMessage = "Must begin with 'extension-definition--' and followed by a UUID (i.e. extension-definition--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)";
        return false;
      } 
      if (JSON.stringify(this.stixService.extensions).includes(this.currentExtension.extension_id)) {
        this.errorMessage = 'WARNING: ID already in use. Please use a different Extension';
        return false;
      }
    }
    this.errorMessage = '';
    return true;
  }

  hasProperties(obj: any): boolean {
    if (obj == undefined || !obj.extension_types)
      return false;
    return JSON.stringify(obj.extension_types).includes('property-extension');
  }

  getTypes(): void {
    this.validID();
    this.currentExtension.extension_type = '';
    if (this.currentExtension.extension_id.includes('extension-definition--')) {
      this.predefined = false;
      let obj = this.stixService.getObjectFromID(this.currentExtension.extension_id);

      if (obj === undefined) {
        this.type_options = ['Property Extension'];
      } else {
        this.type_options = JSON.parse(JSON.stringify(obj.extension_types));
        for (let i = 0; i < this.type_options.length; i++) {
          let t = '';
          switch (this.type_options[i]) {
            // New Objects are handled when creating Extension Definition
            case 'new-sdo':
            case 'new-sro':
            case 'new-sco':
              break;
            case 'property-extension': t = 'Property Extension'; break;
            case 'toplevel-property-extension': t = 'Toplevel Property Extension'; this.toplevel_props = obj.extension_properties; break;
            default: t = this.type_options[i];
          }
          this.type_options[i] = t;
        }
        this.type_options = this.type_options.filter((element) => {
          return element !== '';
        });
      }
    }
    else
      this.predefined = true;
  }

}
