import { v4 as uuid } from "uuid";
import { Observable, of } from "rxjs";
import { FormModel } from "../dynamic-form-component/form-model";
import { QuestionBase } from "../dynamic-form-component/question-base";
import { DatetimeQuestion } from "../dynamic-form-component/question-types/question-datepicker";
import { DropdownQuestion } from "../dynamic-form-component/question-types/question-dropdown";
import { TextboxQuestion } from "../dynamic-form-component/question-types/question-textbox";
import { IdentityQuestion } from "../dynamic-form-component/question-types/question-identity"
import { StringArrayQuestion } from "../dynamic-form-component/question-types/question-string-array";
import { RefArrayQuestion } from "../dynamic-form-component/question-types/question-ref-array";
import { OpenVocabArrayQuestion } from "../dynamic-form-component/question-types/question-ov-array";
import { KillChainQuestion } from "../dynamic-form-component/question-types/question-kill-chain";
import { PhaseNameQuestion } from "../dynamic-form-component/question-types/question-phase-name";
import { ExternalReference } from "./external-reference";
import { GranularMarking } from "./granular-marking";
import { Extension } from "./extension";
import { StixService } from "../stix-service.service";
import { LANGUAGES } from "./languages";
import { Content } from "./content";
import { Window } from "./window";
//import { X509V3Extension } from "./x509-v3-extension";

export class Malware extends FormModel {


    type?: string;
    id?: string;
    spec_version?: string;
    created_by_ref?: string;
    name?: string;
    description?: string;
    malware_types?: string[]
    is_family?: boolean;
    aliases?: string[];
    labels?: string[];
    confidence?: number;
    lang?: string;
    external_references?: ExternalReference[];
    object_marking_refs?: string[];
    granular_markings?: GranularMarking[];
    extensions?: Extension[];
    created?: string;
    modified?: string;
    revoked?: boolean;
    first_seen?: string;
    last_seen?: string;
    operating_system_refs?: string[];
    architecture_execution_envs?: string[];
    implementation_languages?: string[];
    capabilities?: string[];
    sample_refs?: string[];
    kill_chain_phases?: any[];
    kill_chain_name?: string;
    phase_name?: string;
    loaded?: boolean = false;

    constructor(
        public stixService: StixService,
        type?: string | '',
        id?: string | '',
        spec_version?: string | '',
        created_by_ref?: string | '',
        name?: string | '',
        description?: string | '',
        malware_types?: string[] | [],
        is_family?: boolean,
        aliases?: string[] | [],
        labels?: string[] | [],
        confidence?: number,
        lang?: string | '',
        external_references?: ExternalReference[] | [],
        object_marking_refs?: string[] | [],
        granular_markings?: GranularMarking[] | [],
        extensions?: Extension[] | [],
        created?: string | '',
        modified?: string | '',
        revoked?: boolean,
        first_seen?: string | '',
        last_seen?: string | '',
        operating_system_refs?: string[] | [],
        architecture_execution_envs?: string[] | [],
        implementation_languages?: string[] | [],
        capabilities?: string[] | [],
        sample_refs?: string[] | [],
        kill_chain_phases?: any[] | [],
        kill_chain_name?: string | '',
        phase_name?: string | '',
    ) {
        super();
        this.type = type;
        this.id = id;
        this.spec_version = spec_version;
        this.created_by_ref = created_by_ref;
        this.name = name;
        this.description = description;
        this.malware_types = malware_types;
        this.is_family = is_family;
        this.aliases = aliases;
        this.labels = labels;
        this.confidence = confidence;
        this.lang = lang;
        this.external_references = external_references;
        this.object_marking_refs = object_marking_refs;
        this.granular_markings = granular_markings;
        this.extensions = extensions;
        this.created = created;
        this.modified = modified;
        this.revoked = revoked;
        this.first_seen = first_seen;
        this.last_seen = last_seen;
        this.operating_system_refs = operating_system_refs;
        this.architecture_execution_envs = architecture_execution_envs;
        this.implementation_languages = implementation_languages;
        this.capabilities = capabilities;
        this.sample_refs = sample_refs;
        this.kill_chain_name = kill_chain_name;
        this.phase_name = phase_name;
        this.kill_chain_phases = [];
    }

    getWindows(): Window[] {
        return [];
    }
    /*
    getX509V3Extensions(): X509V3Extension[] {
        return [];
    }
    */
    getContents(): Content[] {
        return [];
    }

    getExternalReferences(): ExternalReference[] {
        return this.external_references || [];
    }

    getGranularMarkings(): GranularMarking[] {
        return this.granular_markings || [];
    }
    getExtensions(): Extension[] {
        return this.extensions || [];
    }

    getQuestions(): QuestionBase<any>[] {
        let questions: QuestionBase<any>[] = [
            new TextboxQuestion({
                key: 'type',
                label: 'Type',
                value: 'malware',
                required: true,
                order: 1,
                type: 'text',
                readonly: true,
                columnWidth: 'col-2 type'
            }),
            new TextboxQuestion({
                key: 'id',
                label: 'ID',
                value: `malware--${uuid()}`,
                validatorFn: (componentData: any) => {
                    const id = componentData.id;
                    const idRegex = new RegExp('^malware--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$')
                    if (!idRegex.test(id))
                        return {
                            valid: false,
                            errorMessage: "Must begin with 'malware--' and followed by a UUID (i.e. opinion--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true,
                    };
                },
                required: true,
                order: 2,
                type: 'text',
                readonly: true,
                columnWidth: 'col-4 id'
            }),
            new TextboxQuestion({
                key: 'spec_version',
                label: 'Spec Ver.',
                value: '2.1',
                readonly: true,
                columnWidth: 'col-1 spec-version',
                required: true,
                order: 3
            }),

            new DatetimeQuestion({
                key: 'created',
                label: 'Created*',
                columnWidth: 'w-20 created',
                required: true,
                order: 4
            }),

            new DatetimeQuestion({
                key: 'modified',
                label: 'Modified*',
                validatorFn: (componentData: any) => {
                    // Check whether Modified Date is equal or after to Created Date                    
                    var createdDateTime = Date.parse(componentData['created']);
                    var modifiedDateTime = Date.parse(componentData['modified']);

                    if (modifiedDateTime && modifiedDateTime < createdDateTime
                        && !this.stixService.newObject) {
                        return {
                            valid: false,
                            errorMessage: "Modified date must be after created date."
                        };
                    }

                    return {
                        valid: true
                    };

                },
                columnWidth: 'w-20 modified',
                required: true,
                order: 5
            }),

            new TextboxQuestion({
                key: 'name',
                label: 'Name',
                columnWidth: 'col-3 name',
                required: false,
                order: 6
            }),

            new TextboxQuestion({
                key: 'description',
                label: 'Description',
                order: 7,
                required: false,
                columnWidth: 'col-12'
            }),
            new TextboxQuestion({
                key: 'confidence',
                label: 'Confidence',
                validatorFn: (componentData: any) => {
                    const confidence = componentData.confidence;
                    const confidenceRegex = new RegExp('^(?:100|[1-9]?[0-9])$')
                    if (confidence) {
                        if (!confidenceRegex.test(confidence))
                            return {
                                valid: false,
                                errorMessage: "Confidence value must be an integer in the range of 0-100"
                            };
                    };
                    return {
                        valid: true,
                    };
                },
                columnWidth: 'col-2 confidence',
                order: 8,
                required: false,
                type: 'number',
            }),
            new IdentityQuestion({
                key: 'created_by_ref',
                label: 'Created By Ref',
                validatorFn: (componentData: any) => {
                    const created_by_ref = componentData.created_by_ref;
                    const created_by_refRegex = new RegExp('identity--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}')
                    if (created_by_ref != '') {
                        if (!created_by_refRegex.test(created_by_ref))
                            return {
                                valid: false,
                                errorMessage: "Must begin with 'identity--' and followed by a UUID (i.e. identity--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                            };
                    }
                    return {
                        valid: true,
                    };
                },
                relString: this.created_by_ref,
                order: 11,
                required: false,
                columnWidth: 'col-4 created-by-ref',
            }),
            new DropdownQuestion({
                key: 'lang',
                label: 'Language',
                options: LANGUAGES,
                order: 10,
                required: false,
                columnWidth: 'col-3 language',
            }),
            new DropdownQuestion({
                key: 'revoked',
                label: 'Revoked',
                options: [
                    { key: 'true', value: 'True' },
                    { key: 'false', value: 'False' },
                ],
                columnWidth: 'col-6',
                order: 9
            }),
            new DatetimeQuestion({
                key: 'first_seen',
                label: 'First Seen',
                columnWidth: 'col-6',
                required: false,
                order: 12
            }),
            new DatetimeQuestion({
                key: 'last_seen',
                label: 'Last Seen',
                validatorFn: (componentData: any) => {
                    // Check whether Last Seen Date is equal or after to First Seen Date
                    var validfromDateTime = Date.parse(componentData['first_seen']);
                    var validuntilDateTime = Date.parse(componentData['last_seen']);

                    if (validuntilDateTime && validuntilDateTime < validfromDateTime) {
                        return {
                            valid: false,
                            errorMessage: "Valid Until date must be after Valid From date."
                        };
                    }

                    return {
                        valid: true
                    };

                },
                columnWidth: 'col-6',
                required: false,
                order: 13
            }),

            new DropdownQuestion({
                key: 'is_family',
                label: 'Is Family*',
                options: [
                    { key: 'true', value: 'True' },
                    { key: 'false', value: 'False' },
                ],
                columnWidth: 'col-6',
                required: true,
                order: 14
            }),
            new PhaseNameQuestion({
                key: 'phase_name',
                label: 'Phase Name',
                columnWidth: 'col-6',
                order: 25,
                relString: this.kill_chain_name,
            }),
            new KillChainQuestion({
                key: 'kill_chain_name',
                label: 'Kill Chain Name',
                validatorFn: (componentData: any) => {
                    let phase_name = componentData.phase_name;
                    let kill_chain_name = componentData.kill_chain_name;

                    if (this.stixService.kill_chainTrack){
                        questions.find((i) => i.key == "kill_chain_name").value = '';
                        this.stixService.kill_chainTrack = false;
                    }
                    if (kill_chain_name === undefined || kill_chain_name === '') {
                        questions.find((i) => i.key == "phase_name").relString = '';
                        questions.find((i) => i.key == "phase_name").arrOptions = [];
                    } else {
                        questions.find((i) => i.key == "phase_name").relString = kill_chain_name;
                        questions.find((i) => i.key == "phase_name").arrOptions = [
                            'reconnaissance',
                            'weaponization',
                            'delivery',
                            'exploitation',
                            'installation',
                            'command-and-control',
                        ];
                    }
                    return {
                        valid: true
                    };
                },
                columnWidth: 'col-6',
                order: 24,
                relString: this.phase_name,
            }),

            new OpenVocabArrayQuestion({
                key: 'malware_types',
                label: 'Malware Types',
                options: [
                    { key: 'adware', value: 'adware' },
                    { key: 'backdoor', value: 'backdoor' },
                    { key: 'bot', value: 'bot' },
                    { key: 'bootkit', value: 'bootkit' },
                    { key: 'ddos', value: 'ddos' },
                    { key: 'downloader', value: 'downloader' },
                    { key: 'dropper', value: 'dropper' },
                    { key: 'exploit-kit', value: 'exploit-kit' },
                    { key: 'keylogger', value: 'keylogger' },
                    { key: 'ransomware', value: 'ransomware' },
                    { key: 'remote-access-trojan', value: 'remote-access-trojan' },
                    { key: 'resource-exploitation', value: 'resource-exploitation' },
                    { key: 'rogue-security-software', value: 'rogue-security-software' },
                    { key: 'rootkit', value: 'rootkit' },
                    { key: 'screen-capture', value: 'screen-capture' },
                    { key: 'spyware', value: 'spyware' },
                    { key: 'trojan', value: 'trojan' },
                    { key: 'unknown', value: 'unknown' },
                    { key: 'virus', value: 'virus' },
                    { key: 'webshell', value: 'webshell' },
                    { key: 'wiper', value: 'wiper' },
                    { key: 'worm', value: 'worm' },
                ],
                columnWidth: 'col-6',
                order: 16
            }),


            new OpenVocabArrayQuestion({
                key: 'architecture_execution_envs',
                label: 'Architecture Execution Envs',
                multiple: true,
                options: [
                    { key: 'alpha', value: 'alpha' },
                    { key: 'arm', value: 'arm' },
                    { key: 'ia-64', value: 'ia-64' },
                    { key: 'mips', value: 'mips' },
                    { key: 'powerpc', value: 'powerpc' },
                    { key: 'sparc', value: 'sparc' },
                    { key: 'x86', value: 'x86' },
                    { key: 'x86-64', value: 'x86-64' },
                ],
                columnWidth: 'col-6',
                order: 17,
                marginRight: true
            }),
            new OpenVocabArrayQuestion({
                key: 'implementation_languages',
                label: 'Implementation Languages',
                multiple: true,
                options: [
                    { key: 'applescript', value: 'Applescript' },
                    { key: 'bash', value: 'Bash' },
                    { key: 'c', value: 'C' },
                    { key: 'c++', value: 'C++' },
                    { key: 'c#', value: 'C#' },
                    { key: 'go', value: 'GO' },
                    { key: 'java', value: 'Java' },
                    { key: 'javascript', value: 'Javascript' },
                    { key: 'lua', value: 'LUA' },
                    { key: 'objective-c', value: 'Objective-C' },
                    { key: 'perl', value: 'Perl' },
                    { key: 'php', value: 'PHP' },
                    { key: 'powershell', value: 'Powershell' },
                    { key: 'python', value: 'Python' },
                    { key: 'ruby', value: 'Ruby' },
                    { key: 'scala', value: 'Scala' },
                    { key: 'swift', value: 'Swift' },
                    { key: 'typescript', value: 'Typescript' },
                    { key: 'visual-basic', value: 'Visual Basic' },
                    { key: 'x86-32', value: 'x86-32' },
                    { key: 'x86-64', value: 'x86-64' },

                ],
                columnWidth: 'col-6',
                order: 18,
            }),
            new OpenVocabArrayQuestion({
                key: 'capabilities',
                label: 'Capabilities',
                multiple: true,
                options: [
                    { key: 'accesses-remote-machines', value: 'accesses-remote-machines' },
                    { key: 'anti-debugging', value: 'anti-debugging' },
                    { key: 'anti-disassembly', value: 'anti-disassembly' },
                    { key: 'anti-emulation', value: 'anti-emulation' },
                    { key: 'anti-memory-forensics', value: 'anti-memory-forensics' },
                    { key: 'anti-sandbox', value: 'anti-sandbox' },
                    { key: 'anti-vm', value: 'anti-vm' },
                    { key: 'captures-input-peripherals', value: 'captures-input-peripherals' },
                    { key: 'captures-output-peripherals', value: 'captures-output-peripherals' },
                    { key: 'captures-system-state-data', value: 'captures-system-state-data' },
                    { key: 'cleans-traces-of-infection', value: 'cleans-traces-of-infection' },
                    { key: 'commits-fraud', value: 'commits-fraud' },
                    { key: 'communicates-with-c2', value: 'communicates-with-c2' },
                    { key: 'compromises-data-availability', value: 'compromises-data-availability' },
                    { key: 'compromises-data-integrity', value: 'compromises-data-integrity' },
                    { key: 'compromises-system-availability', value: 'compromises-system-availability' },
                    { key: 'controls-local-machine', value: 'controls-local-machine' },
                    { key: 'degrades-security-software', value: 'degrades-security-software' },
                    { key: 'degrades-system-updates', value: 'degrades-system-updates' },
                    { key: 'determines-c2-server', value: 'determines-c2-server' },
                    { key: 'emails-spam', value: 'emails-spam' },
                    { key: 'escalates-privileges', value: 'escalates-privileges' },
                    { key: 'evades-av', value: 'evades-av' },
                    { key: 'exfiltrates-data', value: 'exfiltrates-data' },
                    { key: 'fingerprints-host', value: 'fingerprints-host' },
                    { key: 'hides-artifacts', value: 'hides-artifacts' },
                    { key: 'hides-executing-code', value: 'hides-executing-code' },
                    { key: 'infects-files', value: 'infects-files' },
                    { key: 'infects-remote-machines', value: 'infects-remote-machines' },
                    { key: 'installs-other-components', value: 'installs-other-components' },
                    { key: 'persists-after-system-reboot', value: 'persists-after-system-reboot' },
                    { key: 'prevents-artifact-access', value: 'prevents-artifact-access' },
                    { key: 'prevents-artifact-deletion', value: 'prevents-artifact-deletion' },
                    { key: 'probes-network-environment', value: 'probes-network-environment' },
                    { key: 'self-modifies', value: 'self-modifies' },
                    { key: 'steals-authentication-credentials', value: 'steals-authentication-credentials' },
                    { key: 'violates-system-operational-integrity', value: 'violates-system-operational-integrity' },
                ],
                columnWidth: 'col-6',
                order: 19,
                marginRight: true
            }),
            // new StringArrayQuestion({
            //     key: 'labels',
            //     label: 'Labels',
            //     value: new Array(),
            //     columnWidth: 'col-6',
            //     order: 20
            // }),
            new StringArrayQuestion({
                key: 'aliases',
                label: 'Aliases',
                value: new Array(),
                columnWidth: 'col-6',
                order: 21
            }),
            new StringArrayQuestion({
                key: 'operating_system_refs',
                label: 'Operating System Refs',
                validatorFn: (componentData: any) => {
                    const operating_system_refs = componentData.operating_system_refs;
                    const operating_system_refsRegex = new RegExp('software--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}')
                    if (operating_system_refs != '' && !operating_system_refsRegex.test(operating_system_refs))
                        return {
                            valid: false,
                            errorMessage: "Must be the identifier for a SCO software object (i.e. software--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true,
                    };
                },
                value: new Array(),
                columnWidth: 'col-6',
                order: 22,
                marginRight: true
            }),
            new RefArrayQuestion({
                key: 'sample_refs',
                label: 'Sample Refs',
                validatorFn: (componentData: any) => {
                    const sample_refs = componentData.sample_refs;
                    const sample_refsRegex = new RegExp('^(file|artifact)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$');
                    if (sample_refs != '' && !sample_refsRegex.test(sample_refs))
                        return {
                            valid: false,
                            errorMessage: "Must be the identifier for a 'file' or 'artifact' object (i.e. file--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true,
                    };
                },
                value: new Array(),
                columnWidth: 'col-6',
                order: 23,
                allowedRefsMap: ["file", "artifact"],
                allowedRefs: ["file", "artifact"]
            })
        ];

        return questions.sort((a, b) => a.order - b.order);
    }

    hasX509V3Extensions(): boolean {
        return false;
    }

    hasWindows(): boolean {
        return false;
    }

    hasContents(): boolean {
        return false;
    }

    hasExternalReferences(): boolean {
        return true;
    }

    hasGranularMarkings(): boolean {
        return true;
    }

    hasExtensions(): boolean {
        return true;
    }

    hasObjectMarkingReferences(): boolean {
        return true;
    }

    populateFromJSON(componentData: any, stixService: StixService): void {
        this.type = componentData.type;
        this.id = componentData.id;
        this.created_by_ref = componentData.created_by_ref;
        this.spec_version = componentData.spec_version;
        this.name = componentData.name;
        this.description = componentData.description;
        this.confidence = parseInt(componentData.confidence) || undefined;
        this.created = componentData.created;
        this.modified = componentData.modified;
        this.revoked = JSON.parse(componentData.revoked[0] || '""');
        this.lang = componentData.lang[0];
        this.external_references = componentData.external_references;
        this.labels = stixService.stringArrays.get("labels") || [];
        this.object_marking_refs = componentData.object_marking_refs;
        this.granular_markings = componentData.granular_markings;
        this.extensions = componentData.extensions;
        this.malware_types = stixService.stringArrays.get("malware_types") || [];
        this.is_family = JSON.parse(componentData.is_family[0] || '""');
        this.aliases = stixService.stringArrays.get("aliases") || [];
        this.kill_chain_phases = [];

        if ((stixService.stringArrays.get('killChain') || []).length > 0) {
            this.kill_chain_phases = [];
            for (let x in stixService.stringArrays.get('killChain')) {
                let obj = {}
                let tuple = stixService.stringArrays.get('killChain')[x].split(': ');
                obj["kill_chain_name"] = tuple[1];
                obj["phase_name"] = tuple[0];
                this.kill_chain_phases.push(obj);
            }
        }
        delete this.phase_name;
        delete this.kill_chain_name;

        this.first_seen = componentData.first_seen;
        this.last_seen = componentData.last_seen;
        this.operating_system_refs = stixService.stringArrays.get("operating_system_refs") || [];
        this.architecture_execution_envs = stixService.stringArrays.get("architecture_execution_envs") || [];
        this.implementation_languages = stixService.stringArrays.get("implementation_languages") || [];
        this.capabilities = stixService.stringArrays.get("capabilities") || [];
        this.sample_refs = stixService.stringArrays.get("sample_refs") || [];
    }
    /*
    setX509V3Extensions(newX509V3Extensions: X509V3Extension[]): void {
        // N/a
    }
*/
    setWindows(newWindows: Window[]): void {
        // N/a
    }

    setContents(newContents: Content[]): void {
        // N/a
    }



    setExternalReferences(newExternalReferences: ExternalReference[]): void {
        this.external_references = newExternalReferences;
    }

    setGranularMarkings(newGranularMarkings: GranularMarking[]): void {
        this.granular_markings = newGranularMarkings;
    }

    setExtensions(newExtensions: Extension[]): void {
        this.extensions = newExtensions;
    }
    setObjectMarkingRefs(newObjectMarkingRefs: string[]): void {
        this.object_marking_refs = newObjectMarkingRefs;
    }



}


