<div id="cust-obj">
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">{{currType}} (Custom {{capitalizedCustomObjType}})</h2>
    </div>

    <div class="modal-body extensions-modal-body" [class]="stixService.guidedUI?'':'mx-5'">
        <div class="col-md-12 p-0">
            <h4 class="mx-2">Common Properties</h4>
            <div class="row mx-1 mb-3">
                <div class="px-1" [class]="questionWrapperClass('type')">
                    <div [class]="stixService.guidedUI?'input-group mb-2':''">
                        <span [class]="stixService.guidedUI?'input-group-text':''">
                            Type*
                        </span>
                        <input class="form-control" [(ngModel)]="this.currentObject['type']" type="text"
                            [readonly]="this.stixService.customObjReadOnly">
                    </div>
                    <div *ngIf="stixService.guidedUI" class="object-property-name-divider mt-1"></div>
                </div>
                <div class="px-1" [class]="questionWrapperClass('id')">
                    <div [class]="stixService.guidedUI?'input-group mb-2':''">
                        <span [class]="stixService.guidedUI?'input-group-text':''">
                            ID
                        </span>
                        <input class="form-control" [(ngModel)]="this.currentObject['id']" type="text" readonly>
                    </div>
                </div>
                <div class="px-1" [class]="questionWrapperClass('specVersion')">
                    <div [class]="stixService.guidedUI?'input-group':''">
                        <span [class]="stixService.guidedUI?'input-group-text':''">
                            Spec Version
                        </span>
                        <input class="form-control" [(ngModel)]="this.currentObject['spec_version']" type="text"
                            readonly>
                    </div>
                </div>


                <div class="mb-2" *ngIf="this.currentObject['type'] != '' && this.modalError.get('type')">
                    <span class="warning-message">
                        {{this.modalError.get('type')}}
                    </span>
                </div>
            </div>

            <div *ngIf="customObjType != 'new-sco'">
                <div class="row mx-1 mb-3">
                    <div class="px-1" [class]="questionWrapperClass('created')">
                        <div [class]="stixService.guidedUI?'input-group mb-2':''">
                            <span [class]="stixService.guidedUI?'input-group-text':''">
                                Created*
                            </span>
                            <input class="form-control" [(ngModel)]="this.currentObject['created']" type="text"
                                readonly>
                        </div>
                    </div>
                    <div class="px-1" [class]="questionWrapperClass('modified')">
                        <div [class]="stixService.guidedUI?'input-group mb-2':''">
                            <span [class]="stixService.guidedUI?'input-group-text':''">
                                Modified*
                            </span>
                            <input class="form-control" [(ngModel)]="this.currentObject['modified']" type="text"
                                readonly>
                        </div>
                    </div>
                    <div class="px-1" [class]="questionWrapperClass('confidence')">
                        <div [class]="stixService.guidedUI?'input-group mb-2':''">
                            <span [class]="stixService.guidedUI?'input-group-text':''">
                                <span>Confidence</span>
                                <fa-icon (click)="openTooltipModal(confidenceTooltip)" class="pl-1"
                                    [icon]="faInfoCircle">
                                </fa-icon>
                            </span>
                            <input class="form-control" [(ngModel)]="this.currentObject['confidence']" type="number"
                                [readonly]="this.stixService.customObjReadOnly">
                        </div>
                    </div>
                    <div class="px-1" [class]="questionWrapperClass('language')">
                        <div [class]="stixService.guidedUI?'input-group':''">
                            <span [class]="stixService.guidedUI?'input-group-text':''">
                                Language
                            </span>
                            <input *ngIf="this.stixService.customObjReadOnly" class="form-control"
                                [(ngModel)]="this.currentObject['lang']" type="text" readonly>
                            <select *ngIf="!this.stixService.customObjReadOnly" class="form-control dropdown"
                                [(ngModel)]="this.currentObject['lang']">
                                <option value="" selected></option>
                                <option *ngFor="let lang of languages" [value]="lang.key">{{lang.value}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row mx-1 my-0">
                    <!-- Labels String Array -->
                    <div class="px-1" [class]="questionWrapperClass('labels')" *ngIf="stixService.guidedUI">
                        <div class="input-group">
                            <span class="input-group-text">
                                Labels
                            </span> 
                            <input class="form-control" [(ngModel)]="this.modalProps['modal_labels']" type="text"
                                [readonly]="this.stixService.customObjReadOnly">
                            <button *ngIf="!this.stixService.customObjReadOnly"
                                class="btn btn-fa-sm btn-primary ml-1" type="button"
                                (click)="addString(this.modalProps['modal_labels'], 'modal_labels')"
                                [disabled]="this.modalProps['modal_labels'] == undefined || this.modalProps['modal_labels'] == '' || this.stixService.customObjReadOnly">
                                <fa-icon [icon]="faPlus"></fa-icon>
                            </button>      
                        </div>
                    </div>
                    <div class="px-1" [class]="questionWrapperClass('labels')" *ngIf="!stixService.guidedUI">
                        <div>
                            <span>
                                Labels
                            </span> 
                            <div class="input-group">
                            <input class="form-control" [(ngModel)]="this.modalProps['modal_labels']" type="text"
                                [readonly]="this.stixService.customObjReadOnly">
                            <button *ngIf="!this.stixService.customObjReadOnly"
                                class="btn btn-fa-sm btn-primary ml-1" type="button"
                                (click)="addString(this.modalProps['modal_labels'], 'modal_labels')"
                                [disabled]="this.modalProps['modal_labels'] == undefined || this.modalProps['modal_labels'] == '' || this.stixService.customObjReadOnly">
                                <fa-icon [icon]="faPlus"></fa-icon>
                            </button>   
                            </div>
                        </div>
                    </div>
                    <div class="row mx-1 mb-3" *ngIf="stixService.guidedUI">
                        <span class="pl-3 ml-3">
                            <div class="form-group mt-2">
                                <li *ngFor="let x of this.currentObject.get('modal_labels')" tabindex="0">
                                    {{x}}
                                    <button *ngIf="!this.stixService.customObjReadOnly"
                                        class="btn btn-fa-xsm btn-danger trash-can ml-1" type="button"
                                        (click)="deleteString(x, 'modal_labels')"
                                        style="height:40px;width:auto;background-color:rgb(210, 0, 0)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                </li>
                            </div>
                        </span>
                    </div>
                    <div class="px-1" [class]="questionWrapperClass('createdByRef')">
                        <div [class]="stixService.guidedUI?'input-group mb-2':''">
                            <span [class]="stixService.guidedUI?'input-group-text':''">
                                Created By Ref
                            </span>
                            <input *ngIf="this.stixService.customObjReadOnly" class="form-control" type="text"
                                [(ngModel)]="this.currentObject['created_by_ref']" readonly>
                            <select *ngIf="!this.stixService.customObjReadOnly" type="text" class="form-control"
                                [(ngModel)]="this.currentObject['created_by_ref']">
                                <option value=""></option>
                                <option *ngFor="let id of ids" [value]="id.id">{{id.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="px-1" [class]="questionWrapperClass('revoked')">
                        <div [class]="stixService.guidedUI?'input-group mb-2':''">
                            <span [class]="stixService.guidedUI?'input-group-text':''">
                                Revoked
                            </span>
                            <input *ngIf="this.stixService.customObjReadOnly" class="form-control" type="text"
                                [(ngModel)]="this.currentObject['revoked']" readonly>
                            <select *ngIf="!this.stixService.customObjReadOnly" class="form-control dropdown"
                                [(ngModel)]="this.currentObject['revoked']">
                                <option value="" selected></option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row mx-1 mb-3" *ngIf="!stixService.guidedUI">
                    <span class="pl-3 ml-3">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.currentObject.get('modal_labels')" tabindex="0">
                                {{x}}
                                <button *ngIf="!this.stixService.customObjReadOnly"
                                    class="btn btn-fa-xsm btn-danger trash-can ml-1" type="button"
                                    (click)="deleteString(x, 'modal_labels')"
                                    style="height:40px;width:auto;background-color:rgb(210, 0, 0)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
            </div>

            <div *ngIf="customObjType == 'new-sco'">
                <div class="row mx-1 mb-3">
                    <div>
                        <div>
                            <span>
                                Defanged
                            </span>
                            <input *ngIf="this.stixService.customObjReadOnly" class="form-control" type="text"
                                [(ngModel)]="this.currentObject['defanged']" readonly>
                            <select *ngIf="!this.stixService.customObjReadOnly" class="form-control dropdown"
                                [(ngModel)]="this.currentObject['defanged']">
                                <option value="" selected></option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 p-0 mt-3" [class]="questionWrapperClass('createCustomProperties')">
            <ng-container
                *ngIf="!this.stixService.customObjReadOnly">
                <h4 class="mx-2">Create Custom Property</h4>
                <div class="row mx-1 my-0">
                    <div class="px-1" [class]="questionWrapperClass('propertyName')">
                        <div [class]="stixService.guidedUI?'input-group mb-2':''">
                            <span [class]="stixService.guidedUI?'input-group-text':''">
                                Property Name
                            </span>
                            <input class="form-control" [(ngModel)]="customProp['name']" type="text">
                        </div>
                        <div class="mb-2" *ngIf="this.currentObject['name'] != '' && this.modalError.get('customName')">
                            <span class="warning-message">
                                {{this.modalError.get('customName')}}
                            </span>
                        </div>
                    </div>
                    <div class="px-1" [class]="questionWrapperClass('propertyType')" *ngIf="stixService.guidedUI">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                Property Type
                            </span>
                            <select type="text" class="form-control" [(ngModel)]="customProp['type']">
                                <option value=""></option>
                                <option value="text">Text</option>
                                <option value="boolean">Boolean</option>
                                <option value="integer">Integer</option>
                                <option value="datetime">Datetime</option>
                                <option value="array">Array</option>
                                <option value="dict">Dictionary</option>
                            </select>
                            <div>
                                <button class="btn btn-fa-sm btn-primary ml-1" type="button"
                                    (click)="createProperty(collection)" [disabled]="customProp['type'] == '' || customProp['name'] == ''
                                    || ((customProp['type'] == 'text' && customProp['text'] == '') 
                                        || (customProp['type'] == 'boolean' && customProp['boolean'] == '')
                                        || (customProp['type'] == 'integer' && customProp['integer'] == '')
                                        || (customProp['type'] == 'datetime' && customProp['datetime'] == ''))">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="px-1" [class]="questionWrapperClass('propertyType')" *ngIf="!stixService.guidedUI">
                        <div>
                            <span>
                                Property Type
                            </span>
                            <div class="input-group">
                                <select type="text" class="form-control" [(ngModel)]="customProp['type']">
                                    <option value=""></option>
                                    <option value="text">Text</option>
                                    <option value="boolean">Boolean</option>
                                    <option value="integer">Integer</option>
                                    <option value="datetime">Datetime</option>
                                    <option value="array">Array</option>
                                    <option value="dict">Dictionary</option>
                                </select>
                                <div>
                                    <button class="btn btn-fa-sm btn-primary ml-1" type="button"
                                        (click)="createProperty(collection)" [disabled]="customProp['type'] == '' || customProp['name'] == ''
                                        || ((customProp['type'] == 'text' && customProp['text'] == '') 
                                            || (customProp['type'] == 'boolean' && customProp['boolean'] == '')
                                            || (customProp['type'] == 'integer' && customProp['integer'] == '')
                                            || (customProp['type'] == 'datetime' && customProp['datetime'] == ''))">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container [ngSwitch]="this.customProp['type']">
                    <!-- Mappings are used for each type (text vs boolean vs etc) so that state is 
                        saved if they switch to a previous type before submitting -->
                    <div *ngSwitchCase="'text'" class="row my-0 p-0">
                        <div class="input-group mb-2" [class]="questionWrapperClass('propertyValueText')">
                            <span [class]="stixService.guidedUI?'input-group-text ml-4':'mx-2'">
                                Property Value
                            </span>
                            <textarea class="form-control mr-4" [(ngModel)]="customProp['text']" type="text"></textarea>
                        </div>
                    </div>
                    <div *ngSwitchCase="'boolean'" class="row my-0 p-0">
                        <div class="input-group mb-2" [class]="questionWrapperClass('propertyValueBoolean')">
                            <span [class]="stixService.guidedUI?'input-group-text ml-4':'mx-2'">
                                Property Value
                            </span>
                            <select class="form-control dropdown mr-4" [(ngModel)]="customProp['boolean']">
                                <option value="" selected></option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>
                    </div>
                    <div *ngSwitchCase="'integer'" class="row my-0 p-0">
                        <div class="input-group mb-2 pr-0" [class]="questionWrapperClass('propertyValueInteger')">
                            <span [class]="stixService.guidedUI?'input-group-text ml-4':'mx-2'">
                                Property Value
                            </span>
                            <input class="form-control mr-4" [(ngModel)]="customProp['integer']" type="number">
                        </div>
                    </div>
                    <div *ngSwitchCase="'datetime'" class="row my-0 p-0">
                        <div class="input-group mb-2" [class]="questionWrapperClass('propertyValueTime')">
                            <span [class]="stixService.guidedUI?'input-group-text ml-4':'mx-2'">
                                Property Value
                            </span>
                            <input class="form-control mr-4" [(ngModel)]="customProp['datetime']"
                                [owlDateTimeTrigger]="dtPickerCustom" [owlDateTime]="dtPickerCustom">
                            <owl-date-time #dtPickerCustom></owl-date-time>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            </div>

            <!-- Messed up logic first time - this is only present when there is a custom property -->
            <div class="col-md-12 p-0" [class]="questionWrapperClass('showCustomProperties')">
            <ng-container
                *ngIf="!((!this.customStringPropKeys || this.customStringPropKeys.length == 0) && (!this.customArrPropKeys || this.customArrPropKeys.length == 0))">
                <span class="tab"></span>
                <h4 class="mx-2">Your Custom Properties</h4>
                <hr>
                <div class="row">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.customStringPropKeys" tabindex="0">
                                {{x}}: {{this.currentObject[x]}}
                                <button *ngIf="!this.stixService.customObjReadOnly"
                                    class="btn btn-fa-xsm btn-danger trash-can ml-1" type="button"
                                    (click)="deleteProperty(x)"
                                    style="height:40px;width:auto;background-color:rgb(210, 0, 0)">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
                <div *ngIf="this.customArrPropKeys && this.customArrPropKeys.length > 0" class="col-12 p-0">
                    <div class="form-group mt-2 bump">
                        <accordion>
                            <accordion-group *ngFor="let x of this.customArrPropKeys" [heading]="x"
                                [panelClass]="'accordion-panel-class'">
                                <div *ngIf="!this.stixService.customObjReadOnly" class="row">
                                    <div class="col-md-2">
                                        <button type="button" class="btn btn-fa btn-primary mr-2"
                                            (click)="this.editCollection(x, collection);">
                                            <fa-icon [icon]="faEdit"></fa-icon>
                                        </button>
                                        <button type="button" class="btn btn-fa btn-warning"
                                            (click)="deleteProperty(x);">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="row ml-1">
                                    <pre>{{ this.currentObject[x] | json }}</pre>
                                </div>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </ng-container>
            </div>
        </div>
        <!-- <div class="col-md-5 pr-5">
                <div class="row pl-4">
                    <app-granular-markings class="w-100"></app-granular-markings>
                </div>
                <div class="row pl-4" *ngIf="customObjType != 'SCO'">
                    <hr class="section-separator">
                    <app-external-references class="w-100"></app-external-references>
                </div>
                <div class="row pl-4">
                    <hr class="section-separator">
                    <app-string-array [key]="'object_marking_refs'" class="w-100"></app-string-array>
                </div>
                <div class="row pl-4">
                    <hr class="section-separator">
                    <app-extensions class="w-100"></app-extensions>
                </div>
            </div> -->
    </div>

    <div class="modal-footer mb-4">
        <button type="button" class="btn btn-md btn-secondary" (click)="cancel()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-md btn-success mr-3" [disabled]="!isCustomObjAddEnabled()" type="button"
            (click)="addToBundle()">
            <fa-icon [icon]="faFileImport" class="pr-1"></fa-icon>
            Add to Bundle
        </button>
    </div>
</div>

<ng-template #collection let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">Configure {{this.customProp['name']}}</h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body extensions-modal-body" id="cust-obj-collection">
        <div class="col-md-12">
            <span class="tab"></span>

            <!-- String Array -->
            <ng-container *ngIf="this.customProp['type'] == 'array'">
                <div class="row bump">
                    <div class="p-0 col-12">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <b>Value</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['value']" type="text">
                            <div>
                                <button class="btn btn-fa-sm btn-primary add-button ml-1 mr-2" type="button"
                                    (click)="addSubModalString(this.customProp['value'], 'customArray')"
                                    [disabled]="this.customProp['value'] == ''">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.customProp.get('customArray')" tabindex="0">
                                {{x}}
                                <button class="btn btn-fa-xsm btn-danger trash-can ml-1" type="button"
                                    (click)="deleteSubModalString(x, 'customArray')">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
            </ng-container>

            <!-- String Dict -->
            <ng-container *ngIf="this.customProp['type'] == 'dict'">
                <div class="row">
                    <div class="p-0 col-12">
                        <div class="input-group mb-2 col-12">
                            <span class="input-group-text">
                                <b>Key</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['key']" type="text">
                        </div>
                        <div class="input-group mb-2 col-12">
                            <span class="input-group-text">
                                <b>Value</b>
                            </span>
                            <input class="form-control" [(ngModel)]="this.customProp['value']" type="text">
                            <div>
                                <button class="btn btn-fa-sm btn-primary ml-1" type="button"
                                    (click)="addSubModalDictString(this.customProp['key'], this.customProp['value'], 'customDict')"
                                    [disabled]="this.customProp['key'] == '' || this.customProp['value'] == ''">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <span class="tab">
                        <div class="form-group mt-2">
                            <li *ngFor="let x of this.customProp.get('customDict').keys()">
                                {{x}}: {{this.customProp.get('customDict').get(x)}}
                                <button class="btn btn-fa-xsm btn-danger trash-can ml-1" type="button"
                                    (click)="deleteSubModalDictString(x, 'customDict')">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </div>
                    </span>
                </div>
            </ng-container>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-md btn-secondary" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-md btn-success mr-3" type="button" (click)="createCollection()"
            [disabled]="this.customProp.get('customArray').length == 0 && this.customProp.get('customDict').size == 0">
            <fa-icon [icon]="faPlusCircle" class="pr-1"></fa-icon>
            Create Property
        </button>
    </div>
</ng-template>

<ng-template #confidenceTooltip let-modal>
    <div class="tooltip-wrapper">
        <h2>Confidence Scales</h2>
        <div>The confidence property reflects the object creator's trust in the accuracy of their data.
            The confidence value MUST be a number in the range of 0-100.</div>
        <div class="tooltip-content">
            <table>
                <tr>
                    <th>Low/Med/High</th>
                    <th>Range of Values</th>
                </tr>
                <tr>
                    <td>Low</td>
                    <td>1-29</td>
                </tr>
                <tr>
                    <td>Med</td>
                    <td>30-69</td>
                </tr>
                <tr>
                    <td>High</td>
                    <td>70-100</td>
                </tr>
            </table>
        </div>
    </div>
</ng-template>