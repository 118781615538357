<div class="body admin">
  <ul class="nav nav-tabs">
    <li class="nav-item" (click)="setTab('apiRoots')">
      <a class="nav-link" [ngClass]="{'active': activeTab === 'apiRoots'}">API Roots</a>
    </li>
    <li class="nav-item" (click)="setTab('collections')">
      <a class="nav-link active" [ngClass]="{'active': activeTab === 'collections'}">Collections</a>
    </li>
    <li class="nav-item" (click)="setTab('roles')">
      <a class="nav-link" [ngClass]="{'active': activeTab === 'roles'}">Groups</a>
    </li>
    <li class="nav-item" (click)="setTab('users')">
      <a class="nav-link" [ngClass]="{'active': activeTab === 'users'}">Users</a>
    </li>
    <li class="nav-item" (click)="setTab('kibana-dashboard')">
      <a class="nav-link" [ngClass]="{'active': activeTab === 'kibana-dashboard'}" target="_blank" href={{kibanaDashboardURL}}>Kibana Dashboard</a>
    </li>
  </ul>

  <div *ngIf="activeTab === 'collections' && !currentCollection">
    <div class="action-bar mt-3">
      <div class="api-root-menu d-flex align-items-center ml-5">
        <label for="APIRoots" style="width: 150px; font-weight: normal; margin-top: 10px;">API Roots</label>
        <select class="form-control" id="APIRoots" name="APIRoots" (change)="onAPIRootChanged($event)">
          <option value=''>Select an API Root</option>
          <option value='--unassigned--'>{{unassignedCollection}}</option>
          <option *ngFor="let apiRoot of apiRoots; let i = index" [value]="apiRoot?.fragment"
            title="{{apiRoot?.apiRoot?.description}}">{{ apiRoot?.apiRoot?.title }}</option>
        </select>
      </div>

      <button type="button" class="btn btn-sm btn-primary" style="margin-top:5.5px;" (click)="addCollection()"
        title="Click to create a new collection." [disabled]="!currentApiRoot">
        <fa-icon [icon]="faPlus" class="pr-1"></fa-icon>
        Add Collection
      </button>
    </div>

    <div *ngIf="collectionsShown?.length > 0" class="table-wrapper" style="padding-right: 70px">
      <table class="table">
        <thead>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('title', 'collections')">
              Title
              <fa-icon *ngIf="sortKey === 'title-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'title-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('id', 'collections')">
              ID
              <fa-icon *ngIf="sortKey === 'id-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'id-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('can_read', 'collections')">
              Can Read
              <fa-icon *ngIf="sortKey === 'can_read-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'can_read-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('can_write', 'collections')">
              Can Write
              <fa-icon *ngIf="sortKey === 'can_write-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'can_write-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('alias', 'collections')">
              Alias
              <fa-icon *ngIf="sortKey === 'alias-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'alias-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">Description</th>
          <th scope="col">Media Types</th>
          <th scope="col">Actions</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let collection of collectionsShown">
            <tr>
              <th style="font-weight: normal; width:14%;">
                {{ collection?.title }}
              </th>
              <th style="font-weight: normal; width:14%;">
                {{ collection?.id }}
              </th>
              <th style="font-weight: normal; width:10%;">
                {{ collection?.can_read }}
              </th>
              <th style="font-weight: normal; width:10%;">
                {{ collection?.can_write }}
              </th>
              <th style="font-weight: normal; width:14%;">
                {{ collection?.alias }}
              </th>
              <th style="font-weight: normal; width:14%;">
                {{ collection?.description }}
              </th>
              <th style="font-weight: normal; width:14%;">
                <div *ngFor="let media of collection?.media_types">
                  {{ media }}
                </div>
              </th>
              <th style="font-weight: normal; width:22%;">
                <div class="item-action-icons">
                  <button type="button" class="btn btn-fa btn-warning mr-1" (click)="editCollection(collection)"
                    title="Click to edit.">
                    <fa-icon [icon]="faList"></fa-icon>
                  </button>
                  <button (click)="removeObject('collection', collection)" class="btn btn-fa btn-danger mr-1"
                    title="Click to remove.">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="activeTab === 'users' && !currentUser">
    <div class="action-bar">
      <div></div>
      <button type="button" class="btn btn-sm btn-primary mr-1 add-button" (click)="addUser()"
        title="Click to create a new user.">
        <fa-icon [icon]="faPlus" class="pr-1"></fa-icon>
        Add User
      </button>
    </div>

    <div class="table-wrapper">
      <table class="table" style="width: 100%">
        <thead>
          <th scope="col" style="width: 25%">
            <span style="cursor:pointer;" (click)="sort('user', 'users')">
              User Name
              <fa-icon *ngIf="sortKey === 'user-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'user-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col" style="width: 50%">
            <span style="cursor:pointer;" (click)="sort('roles', 'users')">
              Groups
              <fa-icon *ngIf="sortKey === 'roles-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'roles-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">Actions</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of users">
            <tr>
              <th style="font-weight: normal; width:14%;">
                {{ user?.user }}
              </th>
              <th style="font-weight: normal; width:14%;">
                <div class="item-role-wrapper">
                  <div *ngFor="let role of user.roles" class="item-role">
                    {{ role }}
                  </div>
                </div>
              </th>
              <th style="font-weight: normal; width:14%;">
                <div class="item-action-icons">
                  <button type="button" class="btn btn-fa btn-warning mr-1" (click)="editUser(user)"
                    title="Click to edit.">
                    <fa-icon [icon]="faList"></fa-icon>
                  </button>
                  <button (click)="removeObject('user', user)" class="btn btn-fa btn-danger mr-1"
                    title="Click to remove.">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="activeTab === 'apiRoots' && !currentApiRoot">
    <div class="action-bar">
      <div></div>
      <button type="button" class="btn btn-sm btn-primary mr-1 add-button" (click)="addApiRoot()"
        title="Click to create a new API Root.">
        <fa-icon [icon]="faPlus" class="pr-1"></fa-icon>
        Add API Root
      </button>
    </div>

    <div class="table-wrapper">
      <table class="table">
        <thead>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('fragment', 'api-roots')">
              URL
              <fa-icon *ngIf="sortKey === 'fragment-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'fragment-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('title', 'api-roots')">
              Title
              <fa-icon *ngIf="sortKey === 'title-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'title-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('max_content_length', 'api-roots')">
              Max Content Length
              <fa-icon *ngIf="sortKey === 'max_content_length-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'max_content_length-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col">
            <span style="cursor:pointer;" (click)="sort('versions', 'api-roots')">
              Versions
              <fa-icon *ngIf="sortKey === 'versions-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'versions-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col" style="width: 50%">Collections</th>
          <th scope="col">Actions</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let apiRoot of apiRoots">
            <tr>
              <th style="font-weight: normal; width:12%;">
                {{ apiRoot?.fragment }}
              </th>
              <th style="font-weight: normal; width:12%;">
                {{ apiRoot?.apiRoot?.title }}
              </th>
              <th style="font-weight: normal; width:12%;">
                {{ apiRoot?.apiRoot?.max_content_length }}
              </th>
              <th style="font-weight: normal; width:12%;">
                <div *ngFor="let ver of apiRoot?.apiRoot?.versions">
                  {{ ver }}
                </div>
              </th>
              <th style="font-weight: normal; width:28%;">
                <div *ngIf="apiRoot?.rws?.length === 1" class="p-2">
                  <div *ngIf="apiRoot?.rws[0]?.cId">
                    {{ getCollectionInAPIRoot(apiRoot?.fragment, apiRoot?.rws[0]?.cId)?.title }}
                  </div>
                  <div class="d-flex">
                    <div>Readers:</div>
                    <div *ngFor="let r of apiRoot?.rws[0]?.readers" class="d-flex flex-column pl-2">
                      {{ r }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <div>Writers:</div>
                    <div *ngFor="let w of apiRoot?.rws[0]?.writers" class="d-flex flex-column pl-2">
                      {{ w }}
                    </div>
                  </div>
                </div>

                <ngb-carousel *ngIf="apiRoot?.rws?.length > 1" [interval]=9999999 [animation]=false>
                  <ng-template ngbSlide *ngFor="let rw of apiRoot?.rws">
                    <div class="d-flex justify-content-center align-items-center">
                      <div>
                        <div *ngIf="rw?.cId">
                          {{ getCollectionInAPIRoot(apiRoot?.fragment, rw?.cId)?.title }}
                        </div>
                        <div class="d-flex">
                          <div>Readers:</div>
                          <div *ngFor="let r of rw?.readers" class="d-flex flex-column pl-2">
                            {{ r }}
                          </div>
                        </div>
                        <div class="d-flex">
                          <div>Writers:</div>
                          <div *ngFor="let w of rw?.writers" class="d-flex flex-column pl-2">
                            {{ w }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-carousel>
              </th>
              <th style="font-weight: normal; width:22%; min-width: 120px;">
                <div class="item-action-icons">
                  <button type="button" class="btn btn-fa btn-warning mr-1" (click)="editApiRoot(apiRoot)"
                    title="Click to edit.">
                    <fa-icon [icon]="faList"></fa-icon>
                  </button>
                  <button (click)="removeObject('api-root', apiRoot)" class="btn btn-fa btn-danger mr-1"
                    title="Click to remove.">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="activeTab === 'roles'">
    <div class="action-bar mt-3">
      <div class="d-flex">
        <div class="api-root-menu d-flex align-items-center ml-5">
          <label for="Role" style="width: 150px; font-weight: normal; margin-top: 10px;">Groups</label>
          <select class="form-control" id="Role" name="Role" [(ngModel)]="currentRole" (change)="onRoleChange($event)">
            <option value='null' disabled selected>Select a Group</option>
            <option *ngFor="let role of roles; let i = index" [value]="role" [selected]="currentRole === role"
              title="{{role}}">{{ role }}</option>
          </select>
        </div>

        <button *ngIf="currentRole" class="btn btn-fa btn-danger ml-3" type="button" title="Remove this role."
          (click)="removeRole()">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
      <!-- <button type="button" class="btn btn-sm btn-primary"
        style="margin-top:5.5px;"
        (click)="addCollection()"
        title="Click to create a new collection."
        [disabled]="!currentApiRoot">
        Add Role
      </button> -->

      <!-- <button *ngIf="currentRole" class="btn btn-fa btn-danger"
          type="button"
          title="Remove this role."
          (click)="removeRole()">
          <fa-icon [icon]="faTrash"></fa-icon>
      </button> -->

      <!-- <button (click)="removeObject('role-collection', roleDetail)"
        class="btn btn-fa btn-danger mr-1"
        title="Click to remove.">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button> -->

      <div class="dropdown">
        <button class="btn btn-sm btn-primary" type="button" data-toggle="dropdown" aria-expanded="false"
          title="Click to create a new group or add collection.">
          <fa-icon [icon]="faPlusCircle" class="pr-1"></fa-icon>
          New
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" (click)="newRole()">New Group</a>
          <a *ngIf="currentRole" class="dropdown-item" (click)="addCollectionToRole()">New Collection</a>
        </div>
      </div>
    </div>

    <div class="table-wrapper" *ngIf="currentRole">
      <table class="table">
        <thead>
          <th scope="col" style="width:14%">
            <span style="cursor:pointer;" (click)="sort('api_root', 'groups')">
              API Root
              <fa-icon *ngIf="sortKey === 'api_root-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'api_root-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col" style="width:18%">
            <span style="cursor:pointer;" (click)="sort('collection_id', 'groups')">
              Collection
              <fa-icon *ngIf="sortKey === 'collection_id-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'collection_id-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col" style="width:12%">
            <span style="cursor:pointer;" (click)="sort('can_read', 'groups')">
              Can Read
              <fa-icon *ngIf="sortKey === 'can_read-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'can_read-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col" style="width:12%">
            <span style="cursor:pointer;" (click)="sort('can_write', 'groups')">
              Can Write
              <fa-icon *ngIf="sortKey === 'can_write-des'" [icon]="faAngleDown"></fa-icon>
              <fa-icon *ngIf="sortKey === 'can_write-asc'" [icon]="faAngleUp"></fa-icon>
            </span>
          </th>
          <th scope="col" style="width:6%">Actions</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let roleDetail of roleDetails?.privileges">
            <tr>
              <th style="font-weight: normal; width:14%;">
                {{ getApiRootDetail(roleDetail?.api_root)?.apiRoot?.title }}
              </th>
              <th style="font-weight: normal; width:18%;">
                {{ getCollectionById(roleDetail?.collection_id)?.title }}
              </th>
              <th style="font-weight: normal; width:12%;">
                <!-- {{ roleDetail?.can_read }} -->
                {{ getCollectionPerRolePermission(roleDetail, 'read') }}
              </th>
              <th style="font-weight: normal; width:12%;">
                <!-- {{ roleDetail?.can_write }} -->
                {{ getCollectionPerRolePermission(roleDetail, 'write') }}
              </th>
              <th style="font-weight: normal; width:6%;">
                <div class="item-action-icons">
                  <button type="button" class="btn btn-fa btn-warning mr-1" (click)="editRole(currentRole, roleDetail)"
                    title="Click to edit.">
                    <fa-icon [icon]="faList"></fa-icon>
                  </button>
                  <button (click)="removeObject('role-collection', roleDetail)" class="btn btn-fa btn-danger mr-1"
                    title="Click to remove.">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </th>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="activeTab === 'kibana-dashboard'">
    <!-- <iframe src="https://imx-beta.va.th.ten" height="600" width="800"></iframe> -->
    <div class="kibana-message">
      Please view dashboard on new tab.
    </div>
  </div>

  <app-admin-collection *ngIf="currentCollection && activeTab === 'collections'" [collectionInput]="currentCollection"
    [apiRootInput]="currentApiRoot" [collectionsForAllApiRoots]="collections"
    [apiRoots]="apiRoots"
    (back)="backToMain($event)"></app-admin-collection>

  <app-admin-user *ngIf="currentUser && activeTab === 'users'" [userInput]="currentUser"
    (back)="backToMain($event)"></app-admin-user>

  <app-admin-api-root *ngIf="currentApiRoot && activeTab === 'apiRoots'" [apiRootInput]="currentApiRoot"
    [apiRootCollectionsInput]="getCollectionInAPIRoot(currentApiRoot.fragment)"
    [collectionsForAllApiRoots]="collections" (back)="backToMain($event)"></app-admin-api-root>

  <div *ngIf="errorMessages?.length > 0" class="row error-message">
    <div class="col">
      <div class="d-flex justify-content-center flex-column">
        <div *ngFor="let err of errorMessages;" class="alert alert-danger" role="alert">
          {{ err }}
        </div>
      </div>
    </div>
  </div>

</div>