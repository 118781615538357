import { Component, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { GuidedService } from 'src/app/guided.service';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { GuidedCartComponent } from '../guided-cart/guided-cart.component';

@Component({
  selector: 'app-how-event',
  templateUrl: './how-event.component.html',
  styleUrls: ['./how-event.component.css']
})
export class HowEventComponent implements OnInit {
  @ViewChild("guidedCart", { static: false }) guidedCart: GuidedCartComponent;

  //With Cart
  @Output() disableCartEmitter = new EventEmitter<boolean>();
  @Output() newItemEmitter = new EventEmitter<any>();
  @Output() editModeEmitter = new EventEmitter<boolean>();
  @Output() syncCartEmitter = new EventEmitter<any>();
  @Output() getEditItemEmitter = new EventEmitter<any>();

  //With Parent
  @Output() showAssistance = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();

  component: string = 'how-event';

  faQuestionCircle = faQuestionCircle;

  details = '';
  name = '';
  goal = '';

  editMode: boolean = false;
  cartIds: number[] = [];

  constructor(
    public guidedService: GuidedService,
  ) { }

  ngOnInit(): void {
    const editCartTimeout = setTimeout( () => {
      if(this.guidedService.editCartObject.component && this.guidedService.editCartObject.component === this.component){
        this.getEditItemEmitter.emit(this.guidedService.editCartObject);
      }
    }, 100);
  }

  addCartItem(cancel){
    if(cancel){
      this.reset();
      this.checkCart();
      return;
    }

    let eventObject = {
      type: 'event',
      name: this.name
    }

    if(this.details){
      eventObject['description'] = this.details.trim();
    }

    if(this.goal){
      eventObject['goal'] = this.goal;
    }

    eventObject['cartId'] = this.cartIds[0] ? this.cartIds[0] : null;

    let emitObj = {
      mode: this.component,
      push: [
        eventObject
      ]
    }

    this.newItemEmitter.emit(emitObj);
    this.disableCartEmitter.emit(true);
    this.reset();
  }

  checkCart(){
    if(this.name !== ''){
      this.disableCartEmitter.emit(false);
      return;
    }

    this.disableCartEmitter.emit(true);
  }

  editCartItem(event){
    this.reset();
    this.editMode = true;

    this.cartIds = [ event.cartId ];

    this.name = event.name;

    if(event.description){
      this.details = event.description;
    }

    if(event.goal){
      this.goal = event.goal;
    }

    this.checkCart();
  }

  reset(){
    this.name = '';
    this.details = '';
    this.goal = '';

    this.editMode = false;
    this.cartIds = [];
  }

  showAssistanceTrigger(){
    this.showAssistance.emit();
  }

  addComponents() {
    if(this.guidedCart.cartLength === 0){
      this.reset();
      return;
    }
    let types = [];
    let data = [];

    for(let type in this.guidedCart.cart){
      let objects = this.guidedCart.cart[type];

      for(let obj of objects){
        types.push(obj.type);

        if(obj.display_name) delete obj.display_name;
        if(obj.cartId) delete obj.cartId;
        if(obj.linkId) delete obj.linkId;
        if(obj.type) delete obj.type;

        data.push(obj);
      }
    }

    this.guidedService.addEventMethod(types, data);
    this.reset();
  }

  redirectCart(event){
    if(event.component !== this.component){
      this.changePage.emit(event);
    } else {
      this.getEditItemEmitter.emit(event);
    }
  }

  syncCart(event){
    if(event === this.component){
      this.syncCartEmitter.emit();
    }
  }
}
