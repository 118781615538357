import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class FluentdService {
  private old_page: string = 'n/a';

  constructor(
    private keycloak: KeycloakService) { }


  public logEvent(event, resp) {
    if (environment.keycloak === true && environment.fluentd === true) {
      this.keycloak.loadUserProfile().then(user => {
        let form = new FormData();

        let log = this.constructLog(event, resp, user.username);
        form.set('json', JSON.stringify(log));

        let url = `${environment.fluentdConfig.protocol}://${environment.fluentdConfig.url}/imx.`;
        switch (environment.environment) {
          case "local": {
            url += "local";
            break;
          }
          case "dev":
          case "ten-dev": {
            url += "dev";
            break;
          }
          case "beta": {
            url += "beta";
            break;
          }
          case "guided": {
            url += "guided";
            break;
          }
          case "staging": {
            url += "staging";
            break;
          }
          case "prod": {
            url += "prod";
            break;
          }
          default: {
            console.warn(`FLUENTD ERROR - Cannot match environment to logging profile: ${environment.environment}`);
          }
        }

        let req = new XMLHttpRequest();

        req.open('POST', url, true);
        req.send(form);
      });
    } else {
      console.warn('Keycloak is disabled, so logging is also disabled');
    }
  }

  private constructLog(event, resp, username) {
    let log = {
      user: username,
      event: event,
      resp: {},
      log_level: ''
    }

    if (event.action === 'page') {
      event.old_page = this.old_page;
      this.old_page = event.new_page;
    }

    for (let field in resp) {
      if (field === "status") {
        switch (resp[field]) {
          case 'pending':
          case 'completed':
          case 200:
            log.log_level = 'SUCCESS';
            break;
          case 0:
          case 401:
          case 403:
          case 404:
            log.log_level = 'ERROR';
            break;
          default:
            log.log_level = 'UNKNOWN';
        }

        if (isNaN(resp[field])) {
          log.resp[field] = resp[field];
        }
        else {
          log.resp[`http_${field}`] = resp[field];
        }
      }
      else {
        if (event.action !== 'query') {
          log.resp[field] = resp[field];
        }
      }
    }

    return log;
  }
}
