export const PROTOCOL = [
    { key: 'af', value:'af'},
    { key: 'ap', value:'ap'},
    { key: 'av', value:'av'},
    { key: 'bb', value:'bb'},
    { key: 'c3', value:'c3'},
    { key: 'cm', value:'cm'},
    { key: 'dc', value:'dc'},
    { key: 'ea', value:'ea'},
    { key: 'ep', value:'ep'},
    { key: 'gf', value:'gf'},
    { key: 'gw', value:'gw'},
    { key: 'hg', value:'hg'},
    { key: 'im', value:'im'},
    { key: 'ip', value:'ip'},
    { key: 'ni', value:'ni'},
    { key: 'ns', value:'ns'},
    { key: 'ph', value:'ph'},
    { key: 'pk', value:'pk'},
    { key: 'sd', value:'sd'},
    { key: 'ws', value:'ws'},
    {key: 'a14', value:'a14'},
    {key: 'a15', value:'a15'},
    {key: 'aap', value:'aap'},
    {key: 'aas', value:'aas'},
    {key: 'aci', value:'aci'},
    {key: 'acp', value:'acp'},
    {key: 'ads', value:'ads'},
    {key: 'afs', value:'afs'},
    {key: 'amc', value:'amc'},
    {key: 'amp', value:'amp'},
    {key: 'ams', value:'ams'},
    {key: 'amt', value:'amt'},
    {key: 'aol', value:'aol'},
    {key: 'arn', value:'arn'},
    {key: 'arp', value:'arp'},
    {key: 'asa', value:'asa'},
    {key: 'asi', value:'asi'},
    {key: 'asr', value:'asr'},
    {key: 'ats', value:'ats'},
    {key: 'b2n', value:'b2n'},
    {key: 'bcs', value:'bcs'},
    {key: 'bdp', value:'bdp'},
    {key: 'bgp', value:'bgp'},
    {key: 'bip', value:'bip'},
    {key: 'bre', value:'bre'},
    {key: 'bri', value:'bri'},
    {key: 'brp', value:'brp'},
    {key: 'btp', value:'btp'},
    {key: 'bxp', value:'bxp'},
    {key: 'bzr', value:'bzr'},
    {key: 'cal', value:'cal'},
    {key: 'cap', value:'cap'},
    {key: 'car', value:'car'},
    {key: 'cas', value:'cas'},
    {key: 'cbp', value:'cbp'},
    {key: 'cbt', value:'cbt'},
    {key: 'ccp', value:'ccp'},
    {key: 'cdc', value:'cdc'},
    {key: 'cdn', value:'cdn'},
    {key: 'cds', value:'cds'},
    {key: 'cfs', value:'cfs'},
    {key: 'cfw', value:'cfw'},
    {key: 'cis', value:'cis'},
    {key: 'clp', value:'clp'},
    {key: 'cma', value:'cma'},
    {key: 'con', value:'con'},
    {key: 'cps', value:'cps'},
    {key: 'crs', value:'crs'},
    {key: 'cso', value:'cso'},
    {key: 'ctf', value:'ctf'},
    {key: 'ctp', value:'ctp'},
    {key: 'cvc', value:'cvc'},
    {key: 'cvd', value:'cvd'},
    {key: 'cyc', value:'cyc'},
    {key: 'dbm', value:'dbm'},
    {key: 'dca', value:'dca'},
    {key: 'dcp', value:'dcp'},
    {key: 'dcs', value:'dcs'},
    {key: 'ddt', value:'ddt'},
    {key: 'dfn', value:'dfn'},
    {key: 'dgp', value:'dgp'},
    {key: 'dhe', value:'dhe'},
    {key: 'dka', value:'dka'},
    {key: 'dls', value:'dls'},
    {key: 'dmp', value:'dmp'},
    {key: 'dmt', value:'dmt'},
    {key: 'dna', value:'dna'},
    {key: 'dnp', value:'dnp'},
    {key: 'dnx', value:'dnx'},
    {key: 'dop', value:'dop'},
    {key: 'dpm', value:'dpm'},
    {key: 'dpp', value:'dpp'},
    {key: 'drp', value:'drp'},
    {key: 'dsc', value:'dsc'},
    {key: 'dsd', value:'dsd'},
    {key: 'dsf', value:'dsf'},
    {key: 'dsp', value:'dsp'},
    {key: 'dtk', value:'dtk'},
    {key: 'dtp', value:'dtp'},
    {key: 'dts', value:'dts'},
    {key: 'dwf', value:'dwf'},
    {key: 'dwr', value:'dwr'},
    {key: 'ea1', value:'ea1'},
    {key: 'eba', value:'eba'},
    {key: 'ecp', value:'ecp'},
    {key: 'eeg', value:'eeg'},
    {key: 'efr', value:'efr'},
    {key: 'efs', value:'efs'},
    {key: 'egp', value:'egp'},
    {key: 'egs', value:'egs'},
    {key: 'ehs', value:'ehs'},
    {key: 'eis', value:'eis'},
    {key: 'eli', value:'eli'},
    {key: 'els', value:'els'},
    {key: 'ems', value:'ems'},
    {key: 'enl', value:'enl'},
    {key: 'epc', value:'epc'},
    {key: 'epp', value:'epp'},
    {key: 'esp', value:'esp'},
    {key: 'est', value:'est'},
    {key: 'etp', value:'etp'},
    {key: 'ets', value:'ets'},
    {key: 'evm', value:'evm'},
    {key: 'evp', value:'evp'},
    {key: 'exb', value:'exb'},
    {key: 'fcp', value:'fcp'},
    {key: 'fhc', value:'fhc'},
    {key: 'fis', value:'fis'},
    {key: 'fix', value:'fix'},
    {key: 'fly', value:'fly'},
    {key: 'fmp', value:'fmp'},
    {key: 'fse', value:'fse'},
    {key: 'fsr', value:'fsr'},
    {key: 'ftp', value:'ftp'},
    {key: 'fxp', value:'fxp'},
    {key: 'g5m', value:'g5m'},
    {key: 'gap', value:'gap'},
    {key: 'ggp', value:'ggp'},
    {key: 'ggz', value:'ggz'},
    {key: 'git', value:'git'},
    {key: 'gld', value:'gld'},
    {key: 'gsi', value:'gsi'},
    {key: 'gue', value:'gue'},
    {key: 'hao', value:'hao'},
    {key: 'hap', value:'hap'},
    {key: 'hid', value:'hid'},
    {key: 'hiq', value:'hiq'},
    {key: 'hkp', value:'hkp'},
    {key: 'hl7', value:'hl7'},
    {key: 'hmp', value:'hmp'},
    {key: 'hnm', value:'hnm'},
    {key: 'hue', value:'hue'},
    {key: 'iax', value:'iax'},
    {key: 'ibp', value:'ibp'},
    {key: 'ica', value:'ica'},
    {key: 'ici', value:'ici'},
    {key: 'icp', value:'icp'},
    {key: 'ics', value:'ics'},
    {key: 'idp', value:'idp'},
    {key: 'igp', value:'igp'},
    {key: 'ild', value:'ild'},
    {key: 'ill', value:'ill'},
    {key: 'iop', value:'iop'},
    {key: 'iot', value:'iot'},
    {key: 'ipp', value:'ipp'},
    {key: 'ipx', value:'ipx'},
    {key: 'iqp', value:'iqp'},
    {key: 'irc', value:'irc'},
    {key: 'irp', value:'irp'},
    {key: 'iua', value:'iua'},
    {key: 'izm', value:'izm'},
    {key: 'jcp', value:'jcp'},
    {key: 'jms', value:'jms'},
    {key: 'jps', value:'jps'},
    {key: 'kdm', value:'kdm'},
    {key: 'kis', value:'kis'},
    {key: 'ktp', value:'ktp'},
    {key: 'l2f', value:'l2f'},
    {key: 'lam', value:'lam'},
    {key: 'lbf', value:'lbf'},
    {key: 'lbm', value:'lbm'},
    {key: 'ldp', value:'ldp'},
    {key: 'lmp', value:'lmp'},
    {key: 'lms', value:'lms'},
    {key: 'lrp', value:'lrp'},
    {key: 'ls3', value:'ls3'},
    {key: 'ltp', value:'ltp'},
    {key: 'lxi', value:'lxi'},
    {key: 'mao', value:'mao'},
    {key: 'mas', value:'mas'},
    {key: 'max', value:'max'},
    {key: 'mcp', value:'mcp'},
    {key: 'mdm', value:'mdm'},
    {key: 'mib', value:'mib'},
    {key: 'mle', value:'mle'},
    {key: 'mmm', value:'mmm'},
    {key: 'mon', value:'mon'},
    {key: 'mpm', value:'mpm'},
    {key: 'mpp', value:'mpp'},
    {key: 'mrm', value:'mrm'},
    {key: 'msp', value:'msp'},
    {key: 'mtn', value:'mtn'},
    {key: 'mtp', value:'mtp'},
    {key: 'mux', value:'mux'},
    {key: 'mxi', value:'mxi'},
    {key: 'mxs', value:'mxs'},
    {key: 'nas', value:'nas'},
    {key: 'nbd', value:'nbd'},
    {key: 'ncl', value:'ncl'},
    {key: 'ncp', value:'ncp'},
    {key: 'ndn', value:'ndn'},
    {key: 'nfa', value:'nfa'},
    {key: 'nfs', value:'nfs'},
    {key: 'nim', value:'nim'},
    {key: 'nip', value:'nip'},
    {key: 'nkd', value:'nkd'},
    {key: 'nms', value:'nms'},
    {key: 'nnp', value:'nnp'},
    {key: 'npp', value:'npp'},
    {key: 'nqs', value:'nqs'},
    {key: 'nsp', value:'nsp'},
    {key: 'nss', value:'nss'},
    {key: 'nst', value:'nst'},
    {key: 'ntp', value:'ntp'},
    {key: 'ntx', value:'ntx'},
    {key: 'nut', value:'nut'},
    {key: 'nvc', value:'nvc'},
    {key: 'nvd', value:'nvd'},
    {key: 'oak', value:'oak'},
    {key: 'obf', value:'obf'},
    {key: 'oca', value:'oca'},
    {key: 'oce', value:'oce'},
    {key: 'ola', value:'ola'},
    {key: 'oms', value:'oms'},
    {key: 'osp', value:'osp'},
    {key: 'otp', value:'otp'},
    {key: 'ott', value:'ott'},
    {key: 'otv', value:'otv'},
    {key: 'pcp', value:'pcp'},
    {key: 'pdb', value:'pdb'},
    {key: 'pdp', value:'pdp'},
    {key: 'pds', value:'pds'},
    {key: 'pip', value:'pip'},
    {key: 'piu', value:'piu'},
    {key: 'pmd', value:'pmd'},
    {key: 'pns', value:'pns'},
    {key: 'ppp', value:'ppp'},
    {key: 'prm', value:'prm'},
    {key: 'prp', value:'prp'},
    {key: 'pss', value:'pss'},
    {key: 'ptp', value:'ptp'},
    {key: 'pup', value:'pup'},
    {key: 'qcp', value:'qcp'},
    {key: 'qfp', value:'qfp'},
    {key: 'qft', value:'qft'},
    {key: 'qrh', value:'qrh'},
    {key: 'qtp', value:'qtp'},
    {key: 'qvr', value:'qvr'},
    {key: 'rap', value:'rap'},
    {key: 'rbr', value:'rbr'},
    {key: 'rce', value:'rce'},
    {key: 'rcp', value:'rcp'},
    {key: 'rda', value:'rda'},
    {key: 'rdp', value:'rdp'},
    {key: 'rds', value:'rds'},
    {key: 'res', value:'res'},
    {key: 'rfa', value:'rfa'},
    {key: 'rfb', value:'rfb'},
    {key: 'rfe', value:'rfe'},
    {key: 'rgb', value:'rgb'},
    {key: 'rid', value:'rid'},
    {key: 'rip', value:'rip'},
    {key: 'ris', value:'ris'},
    {key: 'rje', value:'rje'},
    {key: 'rlm', value:'rlm'},
    {key: 'rlp', value:'rlp'},
    {key: 'rmc', value:'rmc'},
    {key: 'rmt', value:'rmt'},
    {key: 'rna', value:'rna'},
    {key: 'rnm', value:'rnm'},
    {key: 'rpi', value:'rpi'},
    {key: 'rql', value:'rql'},
    {key: 'rrh', value:'rrh'},
    {key: 'rrp', value:'rrp'},
    {key: 'rvd', value:'rvd'},
    {key: 'rwp', value:'rwp'},
    {key: 'rxe', value:'rxe'},
    {key: 'sac', value:'sac'},
    {key: 'sbl', value:'sbl'},
    {key: 'scp', value:'scp'},
    {key: 'sdo', value:'sdo'},
    {key: 'sdr', value:'sdr'},
    {key: 'sds', value:'sds'},
    {key: 'sdt', value:'sdt'},
    {key: 'see', value:'see'},
    {key: 'sep', value:'sep'},
    {key: 'set', value:'set'},
    {key: 'sip', value:'sip'},
    {key: 'slp', value:'slp'},
    {key: 'smb', value:'smb'},
    {key: 'smi', value:'smi'},
    {key: 'sms', value:'sms'},
    {key: 'sos', value:'sos'},
    {key: 'sox', value:'sox'},
    {key: 'spc', value:'spc'},
    {key: 'spg', value:'spg'},
    {key: 'sqp', value:'sqp'},
    {key: 'src', value:'src'},
    {key: 'ssh', value:'ssh'},
    {key: 'ssp', value:'ssp'},
    {key: 'sst', value:'sst'},
    {key: 'stp', value:'stp'},
    {key: 'stt', value:'stt'},
    {key: 'stx', value:'stx'},
    {key: 'sua', value:'sua'},
    {key: 'sum', value:'sum'},
    {key: 'svn', value:'svn'},
    {key: 'swx', value:'swx'},
    {key: 'tcp', value:'tcp'},
    {key: 'tcu', value:'tcu'},
    {key: 'ted', value:'ted'},
    {key: 'tgp', value:'tgp'},
    {key: 'tig', value:'tig'},
    {key: 'tl1', value:'tl1'},
    {key: 'tmi', value:'tmi'},
    {key: 'tnp', value:'tnp'},
    {key: 'trp', value:'trp'},
    {key: 'tsa', value:'tsa'},
    {key: 'tsb', value:'tsb'},
    {key: 'tsp', value:'tsp'},
    {key: 'ttc', value:'ttc'},
    {key: 'tvm', value:'tvm'},
    {key: 'ucl', value:'ucl'},
    {key: 'udp', value:'udp'},
    {key: 'uec', value:'uec'},
    {key: 'uis', value:'uis'},
    {key: 'ulp', value:'ulp'},
    {key: 'uma', value:'uma'},
    {key: 'ums', value:'ums'},
    {key: 'ups', value:'ups'},
    {key: 'urd', value:'urd'},
    {key: 'urm', value:'urm'},
    {key: 'vad', value:'vad'},
    {key: 'vat', value:'vat'},
    {key: 'vce', value:'vce'},
    {key: 'vfo', value:'vfo'},
    {key: 'vhd', value:'vhd'},
    {key: 'vid', value:'vid'},
    {key: 'vop', value:'vop'},
    {key: 'vos', value:'vos'},
    {key: 'vpa', value:'vpa'},
    {key: 'vpp', value:'vpp'},
    {key: 'vqp', value:'vqp'},
    {key: 'vrt', value:'vrt'},
    {key: 'vtp', value:'vtp'},
    {key: 'wdp', value:'wdp'},
    {key: 'wfc', value:'wfc'},
    {key: 'wfm', value:'wfm'},
    {key: 'who', value:'who'},
    {key: 'www', value:'www'},
    {key: 'x11', value:'x11'},
    {key: 'xds', value:'xds'},
    {key: 'xfr', value:'xfr'},
    {key: 'xic', value:'xic'},
    {key: 'xmp', value:'xmp'},
    {key: 'xpl', value:'xpl'},
    {key: 'xrl', value:'xrl'},
    {key: 'zep', value:'zep'},
    {key: 'zmp', value:'zmp'},
    {key:'6a44',value:'6a44'},
    {key:'9pfs',value:'9pfs'},
    {key:'aamp',value:'aamp'},
    {key:'abbs',value:'abbs'},
    {key:'acap',value:'acap'},
    {key:'acas',value:'acas'},
    {key:'acis',value:'acis'},
    {key:'acme',value:'acme'},
    {key:'adap',value:'adap'},
    {key:'adcp',value:'adcp'},
    {key:'adws',value:'adws'},
    {key:'aero',value:'aero'},
    {key:'agma',value:'agma'},
    {key:'ahsp',value:'ahsp'},
    {key:'airs',value:'airs'},
    {key:'amcs',value:'amcs'},
    {key:'amqp',value:'amqp'},
    {key:'anet',value:'anet'},
    {key:'aocp',value:'aocp'},
    {key:'aodv',value:'aodv'},
    {key:'aplx',value:'aplx'},
    {key:'arcp',value:'arcp'},
    {key:'ardt',value:'ardt'},
    {key:'aria',value:'aria'},
    {key:'arns',value:'arns'},
    {key:'asam',value:'asam'},
    {key:'asia',value:'asia'},
    {key:'asmp',value:'asmp'},
    {key:'at-3',value:'at-3'},
    {key:'at-5',value:'at-5'},
    {key:'at-7',value:'at-7'},
    {key:'at-8',value:'at-8'},
    {key:'atmp',value:'atmp'},
    {key:'atul',value:'atul'},
    {key:'aura',value:'aura'},
    {key:'aurp',value:'aurp'},
    {key:'auth',value:'auth'},
    {key:'avtp',value:'avtp'},
    {key:'base',value:'base'},
    {key:'bccp',value:'bccp'},
    {key:'bctp',value:'bctp'},
    {key:'bdsk',value:'bdsk'},
    {key:'beep',value:'beep'},
    {key:'bess',value:'bess'},
    {key:'bftp',value:'bftp'},
    {key:'bgmp',value:'bgmp'},
    {key:'biff',value:'biff'},
    {key:'blp1',value:'blp1'},
    {key:'blp2',value:'blp2'},
    {key:'blp3',value:'blp3'},
    {key:'blp4',value:'blp4'},
    {key:'blp5',value:'blp5'},
    {key:'bmap',value:'bmap'},
    {key:'bmpp',value:'bmpp'},
    {key:'bnet',value:'bnet'},
    {key:'boks',value:'boks'},
    {key:'bolt',value:'bolt'},
    {key:'boxp',value:'boxp'},
    {key:'bpcd',value:'bpcd'},
    {key:'bpmd',value:'bpmd'},
    {key:'bprd',value:'bprd'},
    {key:'brcd',value:'brcd'},
    {key:'burp',value:'burp'},
    {key:'ca-1',value:'ca-1'},
    {key:'ca-2',value:'ca-2'},
    {key:'camp',value:'camp'},
    {key:'casp',value:'casp'},
    {key:'cba8',value:'cba8'},
    {key:'cbus',value:'cbus'},
    {key:'ccnx',value:'ccnx'},
    {key:'cctv',value:'cctv'},
    {key:'cdid',value:'cdid'},
    {key:'ceph',value:'ceph'},
    {key:'cftp',value:'cftp'},
    {key:'cgms',value:'cgms'},
    {key:'chmd',value:'chmd'},
    {key:'ciao',value:'ciao'},
    {key:'cifs',value:'cifs'},
    {key:'cl/1',value:'cl/1'},
    {key:'cl-1',value:'cl-1'},
    {key:'cmot',value:'cmot'},
    {key:'cnap',value:'cnap'},
    {key:'cncp',value:'cncp'},
    {key:'cnrp',value:'cnrp'},
    {key:'coap',value:'coap'},
    {key:'conf',value:'conf'},
    {key:'cops',value:'cops'},
    {key:'copy',value:'copy'},
    {key:'cora',value:'cora'},
    {key:'cpsp',value:'cpsp'},
    {key:'crip',value:'crip'},
    {key:'cscp',value:'cscp'},
    {key:'csdm',value:'csdm'},
    {key:'cslg',value:'cslg'},
    {key:'csms',value:'csms'},
    {key:'cssc',value:'cssc'},
    {key:'cssp',value:'cssp'},
    {key:'csvr',value:'csvr'},
    {key:'ctcd',value:'ctcd'},
    {key:'ctdb',value:'ctdb'},
    {key:'ctdp',value:'ctdp'},
    {key:'ctsd',value:'ctsd'},
    {key:'cwmp',value:'cwmp'},
    {key:'cxtp',value:'cxtp'},
    {key:'cxws',value:'cxws'},
    {key:'cytv',value:'cytv'},
    {key:'daap',value:'daap'},
    {key:'dacp',value:'dacp'},
    {key:'dali',value:'dali'},
    {key:'dasp',value:'dasp'},
    {key:'dawn',value:'dawn'},
    {key:'dbdb',value:'dbdb'},
    {key:'dcap',value:'dcap'},
    {key:'dccm',value:'dccm'},
    {key:'dctp',value:'dctp'},
    {key:'dddp',value:'dddp'},
    {key:'ddgn',value:'ddgn'},
    {key:'deos',value:'deos'},
    {key:'dict',value:'dict'},
    {key:'difi',value:'difi'},
    {key:'dlep',value:'dlep'},
    {key:'dlip',value:'dlip'},
    {key:'dnap',value:'dnap'},
    {key:'dnox',value:'dnox'},
    {key:'doom',value:'doom'},
    {key:'down',value:'down'},
    {key:'dpap',value:'dpap'},
    {key:'dpcp',value:'dpcp'},
    {key:'dpsi',value:'dpsi'},
    {key:'drip',value:'drip'},
    {key:'dsdn',value:'dsdn'},
    {key:'dtn1',value:'dtn1'},
    {key:'dtpt',value:'dtpt'},
    {key:'easl',value:'easl'},
    {key:'ebms',value:'ebms'},
    {key:'echo',value:'echo'},
    {key:'ecmp',value:'ecmp'},
    {key:'ecms',value:'ecms'},
    {key:'ecnp',value:'ecnp'},
    {key:'edcp',value:'edcp'},
    {key:'edix',value:'edix'},
    {key:'efcp',value:'efcp'},
    {key:'eftp',value:'eftp'},
    {key:'ehtp',value:'ehtp'},
    {key:'eisp',value:'eisp'},
    {key:'elad',value:'elad'},
    {key:'elan',value:'elan'},
    {key:'elcn',value:'elcn'},
    {key:'emce',value:'emce'},
    {key:'enfs',value:'enfs'},
    {key:'enpc',value:'enpc'},
    {key:'enpp',value:'enpp'},
    {key:'enrp',value:'enrp'},
    {key:'entp',value:'entp'},
    {key:'eoss',value:'eoss'},
    {key:'epmd',value:'epmd'},
    {key:'eppc',value:'eppc'},
    {key:'erpc',value:'erpc'},
    {key:'esip',value:'esip'},
    {key:'essp',value:'essp'},
    {key:'evtp',value:'evtp'},
    {key:'ewnn',value:'ewnn'},
    {key:'exce',value:'exce'},
    {key:'excw',value:'excw'},
    {key:'exec',value:'exec'},
    {key:'exp1',value:'exp1'},
    {key:'exp2',value:'exp2'},
    {key:'fcis',value:'fcis'},
    {key:'fhsp',value:'fhsp'},
    {key:'find',value:'find'},
    {key:'fish',value:'fish'},
    {key:'fisk',value:'fisk'},
    {key:'fjcp',value:'fjcp'},
    {key:'fmtp',value:'fmtp'},
    {key:'fmwp',value:'fmwp'},
    {key:'frcs',value:'frcs'},
    {key:'frog',value:'frog'},
    {key:'ftps',value:'ftps'},
    {key:'gacp',value:'gacp'},
    {key:'gaia',value:'gaia'},
    {key:'gcsp',value:'gcsp'},
    {key:'gdoi',value:'gdoi'},
    {key:'giop',value:'giop'},
    {key:'gist',value:'gist'},
    {key:'glbp',value:'glbp'},
    {key:'gntp',value:'gntp'},
    {key:'gpfs',value:'gpfs'},
    {key:'gpnp',value:'gpnp'},
    {key:'gpsd',value:'gpsd'},
    {key:'grcp',value:'grcp'},
    {key:'grid',value:'grid'},
    {key:'gris',value:'gris'},
    {key:'gsms',value:'gsms'},
    {key:'guid',value:'guid'},
    {key:'gvcp',value:'gvcp'},
    {key:'gwha',value:'gwha'},
    {key:'h323',value:'h323'},
    {key:'harp',value:'harp'},
    {key:'hawk',value:'hawk'},
    {key:'hbci',value:'hbci'},
    {key:'hctl',value:'hctl'},
    {key:'hdap',value:'hdap'},
    {key:'help',value:'help'},
    {key:'hems',value:'hems'},
    {key:'hfcs',value:'hfcs'},
    {key:'hicp',value:'hicp'},
    {key:'hinp',value:'hinp'},
    {key:'hinz',value:'hinz'},
    {key:'hmcp',value:'hmcp'},
    {key:'hnmp',value:'hnmp'},
    {key:'hola',value:'hola'},
    {key:'hsrp',value:'hsrp'},
    {key:'htcp',value:'htcp'},
    {key:'htsp',value:'htsp'},
    {key:'http',value:'http'},
    {key:'iad1',value:'iad1'},
    {key:'iad2',value:'iad2'},
    {key:'iad3',value:'iad3'},
    {key:'iadt',value:'iadt'},
    {key:'iapp',value:'iapp'},
    {key:'iasd',value:'iasd'},
    {key:'ibar',value:'ibar'},
    {key:'ibiz',value:'ibiz'},
    {key:'ibus',value:'ibus'},
    {key:'ican',value:'ican'},
    {key:'icap',value:'icap'},
    {key:'icmp',value:'icmp'},
    {key:'icms',value:'icms'},
    {key:'icpp',value:'icpp'},
    {key:'idac',value:'idac'},
    {key:'idcp',value:'idcp'},
    {key:'idfp',value:'idfp'},
    {key:'idps',value:'idps'},
    {key:'idrs',value:'idrs'},
    {key:'idtp',value:'idtp'},
    {key:'idxp',value:'idxp'},
    {key:'iffl',value:'iffl'},
    {key:'ifsp',value:'ifsp'},
    {key:'igcp',value:'igcp'},
    {key:'igmp',value:'igmp'},
    {key:'igrs',value:'igrs'},
    {key:'iims',value:'iims'},
    {key:'iiop',value:'iiop'},
    {key:'ilss',value:'ilss'},
    {key:'imap',value:'imap'},
    {key:'imip',value:'imip'},
    {key:'imsp',value:'imsp'},
    {key:'imyx',value:'imyx'},
    {key:'incp',value:'incp'},
    {key:'indi',value:'indi'},
    {key:'indy',value:'indy'},
    {key:'intv',value:'intv'},
    {key:'iota',value:'iota'},
    {key:'ipcd',value:'ipcd'},
    {key:'ipcu',value:'ipcu'},
    {key:'ipdd',value:'ipdd'},
    {key:'ip-e',value:'ip-e'},
    {key:'ippc',value:'ippc'},
    {key:'ipps',value:'ipps'},
    {key:'iqrm',value:'iqrm'},
    {key:'ique',value:'ique'},
    {key:'ircu',value:'ircu'},
    {key:'irmc',value:'irmc'},
    {key:'irtp',value:'irtp'},
    {key:'isdc',value:'isdc'},
    {key:'isdd',value:'isdd'},
    {key:'isis',value:'isis'},
    {key:'islc',value:'islc'},
    {key:'ismc',value:'ismc'},
    {key:'isns',value:'isns'},
    {key:'issd',value:'issd'},
    {key:'item',value:'item'},
    {key:'ivef',value:'ivef'},
    {key:'ivsd',value:'ivsd'},
    {key:'iwec',value:'iwec'},
    {key:'iwg1',value:'iwg1'},
    {key:'j-ac',value:'j-ac'},
    {key:'jaus',value:'jaus'},
    {key:'jcan',value:'jcan'},
    {key:'jini',value:'jini'},
    {key:'jmap',value:'jmap'},
    {key:'jtag',value:'jtag'},
    {key:'juka',value:'juka'},
    {key:'jute',value:'jute'},
    {key:'jwpc',value:'jwpc'},
    {key:'kali',value:'kali'},
    {key:'kana',value:'kana'},
    {key:'kftp',value:'kftp'},
    {key:'kink',value:'kink'},
    {key:'klio',value:'klio'},
    {key:'kmip',value:'kmip'},
    {key:'kpdp',value:'kpdp'},
    {key:'kwtc',value:'kwtc'},
    {key:'kyma',value:'kyma'},
    {key:'l2tp',value:'l2tp'},
    {key:'larp',value:'larp'},
    {key:'ldap',value:'ldap'},
    {key:'ldss',value:'ldss'},
    {key:'ldxp',value:'ldxp'},
    {key:'leaf',value:'leaf'},
    {key:'lhtp',value:'lhtp'},
    {key:'link',value:'link'},
    {key:'linx',value:'linx'},
    {key:'llrp',value:'llrp'},
    {key:'lmcs',value:'lmcs'},
    {key:'lmdp',value:'lmdp'},
    {key:'lm-x',value:'lm-x'},
    {key:'lpcp',value:'lpcp'},
    {key:'lpdg',value:'lpdg'},
    {key:'lsdp',value:'lsdp'},
    {key:'lstp',value:'lstp'},
    {key:'lupa',value:'lupa'},
    {key:'m2ap',value:'m2ap'},
    {key:'m2pa',value:'m2pa'},
    {key:'m2ua',value:'m2ua'},
    {key:'m3ap',value:'m3ap'},
    {key:'m3da',value:'m3da'},
    {key:'m3ua',value:'m3ua'},
    {key:'mail',value:'mail'},
    {key:'mapx',value:'mapx'},
    {key:'masc',value:'masc'},
    {key:'mbap',value:'mbap'},
    {key:'mbus',value:'mbus'},
    {key:'mctp',value:'mctp'},
    {key:'mdns',value:'mdns'},
    {key:'mdqs',value:'mdqs'},
    {key:'mdtp',value:'mdtp'},
    {key:'mftp',value:'mftp'},
    {key:'mice',value:'mice'},
    {key:'mira',value:'mira'},
    {key:'mist',value:'mist'},
    {key:'mles',value:'mles'},
    {key:'mlsn',value:'mlsn'},
    {key:'mmcc',value:'mmcc'},
    {key:'monp',value:'monp'},
    {key:'mptn',value:'mptn'},
    {key:'mqtt',value:'mqtt'},
    {key:'mrip',value:'mrip'},
    {key:'msdp',value:'msdp'},
    {key:'msmq',value:'msmq'},
    {key:'msnp',value:'msnp'},
    {key:'msrp',value:'msrp'},
    {key:'msss',value:'msss'},
    {key:'mtqp',value:'mtqp'},
    {key:'mttp',value:'mttp'},
    {key:'murx',value:'murx'},
    {key:'muse',value:'muse'},
    {key:'mxit',value:'mxit'},
    {key:'mzap',value:'mzap'},
    {key:'naap',value:'naap'},
    {key:'naio',value:'naio'},
    {key:'name',value:'name'},
    {key:'namp',value:'namp'},
    {key:'nani',value:'nani'},
    {key:'nbdb',value:'nbdb'},
    {key:'nced',value:'nced'},
    {key:'ncld',value:'ncld'},
    {key:'ncpu',value:'ncpu'},
    {key:'ndmp',value:'ndmp'},
    {key:'ndnp',value:'ndnp'},
    {key:'ndsp',value:'ndsp'},
    {key:'ndtp',value:'ndtp'},
    {key:'necp',value:'necp'},
    {key:'nerv',value:'nerv'},
    {key:'news',value:'news'},
    {key:'nhci',value:'nhci'},
    {key:'nirp',value:'nirp'},
    {key:'nmap',value:'nmap'},
    {key:'nmmp',value:'nmmp'},
    {key:'nmsd',value:'nmsd'},
    {key:'nmsp',value:'nmsp'},
    {key:'nnsp',value:'nnsp'},
    {key:'nntp',value:'nntp'},
    {key:'norp',value:'norp'},
    {key:'npmp',value:'npmp'},
    {key:'npsp',value:'npsp'},
    {key:'nrpe',value:'nrpe'},
    {key:'nsrp',value:'nsrp'},
    {key:'nsws',value:'nsws'},
    {key:'nufw',value:'nufw'},
    {key:'nvme',value:'nvme'},
    {key:'obex',value:'obex'},
    {key:'odmr',value:'odmr'},
    {key:'odsi',value:'odsi'},
    {key:'ofsd',value:'ofsd'},
    {key:'ohsc',value:'ohsc'},
    {key:'olsr',value:'olsr'},
    {key:'olsv',value:'olsv'},
    {key:'omhs',value:'omhs'},
    {key:'ospf',value:'ospf'},
    {key:'osvr',value:'osvr'},
    {key:'otlp',value:'otlp'},
    {key:'otmp',value:'otmp'},
    {key:'ottp',value:'ottp'},
    {key:'owms',value:'owms'},
    {key:'p2pq',value:'p2pq'},
    {key:'pads',value:'pads'},
    {key:'pana',value:'pana'},
    {key:'pcep',value:'pcep'},
    {key:'pdap',value:'pdap'},
    {key:'pdps',value:'pdps'},
    {key:'pdtp',value:'pdtp'},
    {key:'pfcp',value:'pfcp'},
    {key:'pftp',value:'pftp'},
    {key:'pgps',value:'pgps'},
    {key:'pirp',value:'pirp'},
    {key:'plex',value:'plex'},
    {key:'pmas',value:'pmas'},
    {key:'pmcd',value:'pmcd'},
    {key:'pmcp',value:'pmcp'},
    {key:'pmcs',value:'pmcs'},
    {key:'pnbs',value:'pnbs'},
    {key:'poch',value:'poch'},
    {key:'pop2',value:'pop2'},
    {key:'pop3',value:'pop3'},
    {key:'pptp',value:'pptp'},
    {key:'pqsp',value:'pqsp'},
    {key:'prat',value:'prat'},
    {key:'pres',value:'pres'},
    {key:'prgp',value:'prgp'},
    {key:'psap',value:'psap'},
    {key:'psia',value:'psia'},
    {key:'psrt',value:'psrt'},
    {key:'pssc',value:'pssc'},
    {key:'pump',value:'pump'},
    {key:'pvsw',value:'pvsw'},
    {key:'qbdb',value:'qbdb'},
    {key:'qbox',value:'qbox'},
    {key:'qmqp',value:'qmqp'},
    {key:'qmtp',value:'qmtp'},
    {key:'qotd',value:'qotd'},
    {key:'qttp',value:'qttp'},
    {key:'quad',value:'quad'},
    {key:'racf',value:'racf'},
    {key:'radg',value:'radg'},
    {key:'rads',value:'rads'},
    {key:'ramp',value:'ramp'},
    {key:'raop',value:'raop'},
    {key:'rapi',value:'rapi'},
    {key:'rarp',value:'rarp'},
    {key:'ratl',value:'ratl'},
    {key:'ratp',value:'ratp'},
    {key:'rcst',value:'rcst'},
    {key:'rcts',value:'rcts'},
    {key:'rdgs',value:'rdgs'},
    {key:'rds2',value:'rds2'},
    {key:'retp',value:'retp'},
    {key:'rets',value:'rets'},
    {key:'rfbc',value:'rfbc'},
    {key:'rfid',value:'rfid'},
    {key:'rfio',value:'rfio'},
    {key:'rfmp',value:'rfmp'},
    {key:'rgtp',value:'rgtp'},
    {key:'rise',value:'rise'},
    {key:'rmpp',value:'rmpp'},
    {key:'rndc',value:'rndc'},
    {key:'rnrp',value:'rnrp'},
    {key:'roce',value:'roce'},
    {key:'rome',value:'rome'},
    {key:'rprt',value:'rprt'},
    {key:'rrac',value:'rrac'},
    {key:'rrdp',value:'rrdp'},
    {key:'rsap',value:'rsap'},
    {key:'rscd',value:'rscd'},
    {key:'rscs',value:'rscs'},
    {key:'rsip',value:'rsip'},
    {key:'rsms',value:'rsms'},
    {key:'rsom',value:'rsom'},
    {key:'rsvd',value:'rsvd'},
    {key:'rtip',value:'rtip'},
    {key:'rtsp',value:'rtsp'},
    {key:'s102',value:'s102'},
    {key:'s3db',value:'s3db'},
    {key:'saft',value:'saft'},
    {key:'sais',value:'sais'},
    {key:'samd',value:'samd'},
    {key:'sasg',value:'sasg'},
    {key:'sasp',value:'sasp'},
    {key:'scol',value:'scol'},
    {key:'scup',value:'scup'},
    {key:'sddp',value:'sddp'},
    {key:'send',value:'send'},
    {key:'sflm',value:'sflm'},
    {key:'sftp',value:'sftp'},
    {key:'sftu',value:'sftu'},
    {key:'sgcp',value:'sgcp'},
    {key:'sgmp',value:'sgmp'},
    {key:'ship',value:'ship'},
    {key:'siam',value:'siam'},
    {key:'silc',value:'silc'},
    {key:'sips',value:'sips'},
    {key:'smag',value:'smag'},
    {key:'smap',value:'smap'},
    {key:'smip',value:'smip'},
    {key:'smpp',value:'smpp'},
    {key:'smsd',value:'smsd'},
    {key:'smsp',value:'smsp'},
    {key:'smtp',value:'smtp'},
    {key:'smux',value:'smux'},
    {key:'snac',value:'snac'},
    {key:'snap',value:'snap'},
    {key:'sncp',value:'sncp'},
    {key:'snif',value:'snif'},
    {key:'snmp',value:'snmp'},
    {key:'snpp',value:'snpp'},
    {key:'snss',value:'snss'},
    {key:'soap',value:'soap'},
    {key:'soda',value:'soda'},
    {key:'sops',value:'sops'},
    {key:'spdm',value:'spdm'},
    {key:'spdp',value:'spdp'},
    {key:'spdy',value:'spdy'},
    {key:'spmp',value:'spmp'},
    {key:'spsc',value:'spsc'},
    {key:'spss',value:'spss'},
    {key:'sptx',value:'sptx'},
    {key:'sqdr',value:'sqdr'},
    {key:'srcp',value:'srcp'},
    {key:'srdp',value:'srdp'},
    {key:'srmp',value:'srmp'},
    {key:'ssad',value:'ssad'},
    {key:'ssdp',value:'ssdp'},
    {key:'sslp',value:'sslp'},
    {key:'ssmc',value:'ssmc'},
    {key:'ssmd',value:'ssmd'},
    {key:'ssql',value:'ssql'},
    {key:'stmf',value:'stmf'},
    {key:'stss',value:'stss'},
    {key:'stun',value:'stun'},
    {key:'stvp',value:'stvp'},
    {key:'sugp',value:'sugp'},
    {key:'surf',value:'surf'},
    {key:'swyp',value:'swyp'},
    {key:'sxmp',value:'sxmp'},
    {key:'sztp',value:'sztp'},
    {key:'talk',value:'talk'},
    {key:'tams',value:'tams'},
    {key:'tarp',value:'tarp'},
    {key:'tell',value:'tell'},
    {key:'tftp',value:'tftp'},
    {key:'thrp',value:'thrp'},
    {key:'tidp',value:'tidp'},
    {key:'time',value:'time'},
    {key:'tinc',value:'tinc'},
    {key:'tip2',value:'tip2'},
    {key:'tipc',value:'tipc'},
    {key:'toad',value:'toad'},
    {key:'topx',value:'topx'},
    {key:'tpdu',value:'tpdu'},
    {key:'tpip',value:'tpip'},
    {key:'tpmd',value:'tpmd'},
    {key:'tram',value:'tram'},
    {key:'trap',value:'trap'},
    {key:'trel',value:'trel'},
    {key:'trim',value:'trim'},
    {key:'trip',value:'trip'},
    {key:'tsaf',value:'tsaf'},
    {key:'tsb2',value:'tsb2'},
    {key:'turn',value:'turn'},
    {key:'tvpm',value:'tvpm'},
    {key:'twds',value:'twds'},
    {key:'tyba',value:'tyba'},
    {key:'uaac',value:'uaac'},
    {key:'uacs',value:'uacs'},
    {key:'ubxd',value:'ubxd'},
    {key:'uddi',value:'uddi'},
    {key:'ufmp',value:'ufmp'},
    {key:'umsp',value:'umsp'},
    {key:'unet',value:'unet'},
    {key:'unot',value:'unot'},
    {key:'uorb',value:'uorb'},
    {key:'upnp',value:'upnp'},
    {key:'uswi',value:'uswi'},
    {key:'utcd',value:'utcd'},
    {key:'uucp',value:'uucp'},
    {key:'v5ua',value:'v5ua'},
    {key:'vatp',value:'vatp'},
    {key:'vcmp',value:'vcmp'},
    {key:'vcrp',value:'vcrp'},
    {key:'vdab',value:'vdab'},
    {key:'vfbp',value:'vfbp'},
    {key:'visa',value:'visa'},
    {key:'visd',value:'visd'},
    {key:'vmtp',value:'vmtp'},
    {key:'vnas',value:'vnas'},
    {key:'vnyx',value:'vnyx'},
    {key:'vp2p',value:'vp2p'},
    {key:'vpac',value:'vpac'},
    {key:'vpad',value:'vpad'},
    {key:'vpjp',value:'vpjp'},
    {key:'vpnz',value:'vpnz'},
    {key:'vpvc',value:'vpvc'},
    {key:'vpvd',value:'vpvd'},
    {key:'vroa',value:'vroa'},
    {key:'vrpn',value:'vrpn'},
    {key:'vrtp',value:'vrtp'},
    {key:'vscp',value:'vscp'},
    {key:'wacp',value:'wacp'},
    {key:'wafs',value:'wafs'},
    {key:'wcpp',value:'wcpp'},
    {key:'wimd',value:'wimd'},
    {key:'wins',value:'wins'},
    {key:'wlbs',value:'wlbs'},
    {key:'wlcp',value:'wlcp'},
    {key:'wnn6',value:'wnn6'},
    {key:'wpgs',value:'wpgs'},
    {key:'x510',value:'x510'},
    {key:'xbox',value:'xbox'},
    {key:'xdas',value:'xdas'},
    {key:'xdtp',value:'xdtp'},
    {key:'xfer',value:'xfer'},
    {key:'xiip',value:'xiip'},
    {key:'xmcp',value:'xmcp'},
    {key:'xmsg',value:'xmsg'},
    {key:'xnds',value:'xnds'},
    {key:'xnet',value:'xnet'},
    {key:'xnmp',value:'xnmp'},
    {key:'xoms',value:'xoms'},
    {key:'x-on',value:'x-on'},
    {key:'xpra',value:'xpra'},
    {key:'xrpl',value:'xrpl'},
    {key:'xtrm',value:'xtrm'},
    {key:'yawn',value:'yawn'},
    {key:'2ping',value:'2ping'},
    {key:'3exmp',value:'3exmp'},
    {key:'3link',value:'3link'},
    {key:'3l-l1',value:'3l-l1'},
    {key:'4talk',value:'4talk'},
    {key:'a1-bs',value:'a1-bs'},
    {key:'about',value:'about'},
    {key:'accel',value:'accel'},
    {key:'acnet',value:'acnet'},
    {key:'acplt',value:'acplt'},
    {key:'acter',value:'acter'},
    {key:'adisk',value:'adisk'},
    {key:'adrep',value:'adrep'},
    {key:'ads-c',value:'ads-c'},
    {key:'ads-s',value:'ads-s'},
    {key:'aesop',value:'aesop'},
    {key:'afrog',value:'afrog'},
    {key:'agcat',value:'agcat'},
    {key:'agslb',value:'agslb'},
    {key:'aises',value:'aises'},
    {key:'alarm',value:'alarm'},
    {key:'alfin',value:'alfin'},
    {key:'alias',value:'alias'},
    {key:'alpes',value:'alpes'},
    {key:'altcp',value:'altcp'},
    {key:'amqps',value:'amqps'},
    {key:'aol-1',value:'aol-1'},
    {key:'aol-2',value:'aol-2'},
    {key:'aol-3',value:'aol-3'},
    {key:'apdap',value:'apdap'},
    {key:'apocd',value:'apocd'},
    {key:'arcpd',value:'arcpd'},
    {key:'argus',value:'argus'},
    {key:'asdis',value:'asdis'},
    {key:'aseba',value:'aseba'},
    {key:'asmps',value:'asmps'},
    {key:'async',value:'async'},
    {key:'atnet',value:'atnet'},
    {key:'audit',value:'audit'},
    {key:'auris',value:'auris'},
    {key:'avian',value:'avian'},
    {key:'ayiya',value:'ayiya'},
    {key:'azeti',value:'azeti'},
    {key:'aztec',value:'aztec'},
    {key:'babel',value:'babel'},
    {key:'bbars',value:'bbars'},
    {key:'beorl',value:'beorl'},
    {key:'bh611',value:'bh611'},
    {key:'bhfhs',value:'bhfhs'},
    {key:'bhmds',value:'bhmds'},
    {key:'binkp',value:'binkp'},
    {key:'blaze',value:'blaze'},
    {key:'bmdss',value:'bmdss'},
    {key:'bones',value:'bones'},
    {key:'bootp',value:'bootp'},
    {key:'bousg',value:'bousg'},
    {key:'bpdbm',value:'bpdbm'},
    {key:'brain',value:'brain'},
    {key:'breas',value:'breas'},
    {key:'bruce',value:'bruce'},
    {key:'bv-ds',value:'bv-ds'},
    {key:'bv-is',value:'bv-is'},
    {key:'bytex',value:'bytex'},
    {key:'camac',value:'camac'},
    {key:'candp',value:'candp'},
    {key:'cawas',value:'cawas'},
    {key:'ccmad',value:'ccmad'},
    {key:'cddbp',value:'cddbp'},
    {key:'cft-0',value:'cft-0'},
    {key:'cft-1',value:'cft-1'},
    {key:'cft-2',value:'cft-2'},
    {key:'cft-3',value:'cft-3'},
    {key:'cft-4',value:'cft-4'},
    {key:'cft-5',value:'cft-5'},
    {key:'cft-6',value:'cft-6'},
    {key:'cft-7',value:'cft-7'},
    {key:'chaos',value:'chaos'},
    {key:'cheat',value:'cheat'},
    {key:'chess',value:'chess'},
    {key:'chfts',value:'chfts'},
    {key:'chili',value:'chili'},
    {key:'clock',value:'clock'},
    {key:'cnhrp',value:'cnhrp'},
    {key:'coaps',value:'coaps'},
    {key:'cosir',value:'cosir'},
    {key:'covia',value:'covia'},
    {key:'cpdlc',value:'cpdlc'},
    {key:'cppdp',value:'cppdp'},
    {key:'csms2',value:'csms2'},
    {key:'csrpc',value:'csrpc'},
    {key:'cvmon',value:'cvmon'},
    {key:'cvsup',value:'cvsup'},
    {key:'dbase',value:'dbase'},
    {key:'dbref',value:'dbref'},
    {key:'decap',value:'decap'},
    {key:'dicom',value:'dicom'},
    {key:'dixie',value:'dixie'},
    {key:'dmidi',value:'dmidi'},
    {key:'dnsix',value:'dnsix'},
    {key:'domiq',value:'domiq'},
    {key:'drwcs',value:'drwcs'},
    {key:'dsatp',value:'dsatp'},
    {key:'dsfgw',value:'dsfgw'},
    {key:'d-s-n',value:'d-s-n'},
    {key:'eapsp',value:'eapsp'},
    {key:'ebreg',value:'ebreg'},
    {key:'ecomm',value:'ecomm'},
    {key:'edge1',value:'edge1'},
    {key:'eenet',value:'eenet'},
    {key:'eheap',value:'eheap'},
    {key:'elcsd',value:'elcsd'},
    {key:'eldim',value:'eldim'},
    {key:'emcon',value:'emcon'},
    {key:'e-mdu',value:'e-mdu'},
    {key:'emwin',value:'emwin'},
    {key:'e-net',value:'e-net'},
    {key:'epmap',value:'epmap'},
    {key:'etb4j',value:'etb4j'},
    {key:'etftp',value:'etftp'},
    {key:'ewall',value:'ewall'},
    {key:'ewdgs',value:'ewdgs'},
    {key:'e-woa',value:'e-woa'},
    {key:'eyetv',value:'eyetv'},
    {key:'famdc',value:'famdc'},
    {key:'femis',value:'femis'},
    {key:'ff-sm',value:'ff-sm'},
    {key:'fjork',value:'fjork'},
    {key:'flcrs',value:'flcrs'},
    {key:'fmsas',value:'fmsas'},
    {key:'fodms',value:'fodms'},
    {key:'found',value:'found'},
    {key:'fpitp',value:'fpitp'},
    {key:'ftsrv',value:'ftsrv'},
    {key:'g2tag',value:'g2tag'},
    {key:'genie',value:'genie'},
    {key:'ghvpn',value:'ghvpn'},
    {key:'ginad',value:'ginad'},
    {key:'globe',value:'globe'},
    {key:'glrpc',value:'glrpc'},
    {key:'golem',value:'golem'},
    {key:'gotit',value:'gotit'},
    {key:'grasp',value:'grasp'},
    {key:'grcmp',value:'grcmp'},
    {key:'gribi',value:'gribi'},
    {key:'growl',value:'growl'},
    {key:'grubd',value:'grubd'},
    {key:'gtaua',value:'gtaua'},
    {key:'gv-pf',value:'gv-pf'},
    {key:'gv-us',value:'gv-us'},
    {key:'helix',value:'helix'},
    {key:'hello',value:'hello'},
    {key:'hivep',value:'hivep'},
    {key:'howdy',value:'howdy'},
    {key:'hpiod',value:'hpiod'},
    {key:'hpssd',value:'hpssd'},
    {key:'https',value:'https'},
    {key:'httpx',value:'httpx'},
    {key:'husky',value:'husky'},
    {key:'hydap',value:'hydap'},
    {key:'hydra',value:'hydra'},
    {key:'ichat',value:'ichat'},
    {key:'iclid',value:'iclid'},
    {key:'icmpd',value:'icmpd'},
    {key:'iconp',value:'iconp'},
    {key:'icpps',value:'icpps'},
    {key:'icpv2',value:'icpv2'},
    {key:'idata',value:'idata'},
    {key:'idcws',value:'idcws'},
    {key:'ident',value:'ident'},
    {key:'igrid',value:'igrid'},
    {key:'ilynx',value:'ilynx'},
    {key:'imap2',value:'imap2'},
    {key:'imap3',value:'imap3'},
    {key:'imaps',value:'imaps'},
    {key:'imidi',value:'imidi'},
    {key:'imink',value:'imink'},
    {key:'imprs',value:'imprs'},
    {key:'inedo',value:'inedo'},
    {key:'insis',value:'insis'},
    {key:'ipass',value:'ipass'},
    {key:'ipcd3',value:'ipcd3'},
    {key:'ip-dc',value:'ip-dc'},
    {key:'ip-ee',value:'ip-ee'},
    {key:'ipfix',value:'ipfix'},
    {key:'ip-hc',value:'ip-hc'},
    {key:'ipsma',value:'ipsma'},
    {key:'ip-wb',value:'ip-wb'},
    {key:'iRAPP',value:'iRAPP'},
    {key:'irdmi',value:'irdmi'},
    {key:'irisa',value:'irisa'},
    {key:'is99c',value:'is99c'},
    {key:'is99s',value:'is99s'},
    {key:'iscsi',value:'iscsi'},
    {key:'itach',value:'itach'},
    {key:'italk',value:'italk'},
    {key:'itose',value:'itose'},
    {key:'itsrc',value:'itsrc'},
    {key:'iwork',value:'iwork'},
    {key:'izira',value:'izira'},
    {key:'joost',value:'joost'},
    {key:'jstel',value:'jstel'},
    {key:'jt400',value:'jt400'},
    {key:'kazaa',value:'kazaa'},
    {key:'kdnet',value:'kdnet'},
    {key:'kiosk',value:'kiosk'},
    {key:'kitim',value:'kitim'},
    {key:'kiwin',value:'kiwin'},
    {key:'lan2p',value:'lan2p'},
    {key:'lapse',value:'lapse'},
    {key:'ldaps',value:'ldaps'},
    {key:'leoip',value:'leoip'},
    {key:'licor',value:'licor'},
    {key:'light',value:'light'},
    {key:'llmnr',value:'llmnr'},
    {key:'lobby',value:'lobby'},
    {key:'login',value:'login'},
    {key:'lutap',value:'lutap'},
    {key:'lutcp',value:'lutcp'},
    {key:'lv-jc',value:'lv-jc'},
    {key:'mailq',value:'mailq'},
    {key:'manet',value:'manet'},
    {key:'mc3ss',value:'mc3ss'},
    {key:'mcftp',value:'mcftp'},
    {key:'mcntp',value:'mcntp'},
    {key:'mcrcp',value:'mcrcp'},
    {key:'meta5',value:'meta5'},
    {key:'meter',value:'meter'},
    {key:'mihcs',value:'mihcs'},
    {key:'mihes',value:'mihes'},
    {key:'mihis',value:'mihis'},
    {key:'mikey',value:'mikey'},
    {key:'mimer',value:'mimer'},
    {key:'mmcal',value:'mmcal'},
    {key:'mmpft',value:'mmpft'},
    {key:'molly',value:'molly'},
    {key:'ms-do',value:'ms-do'},
    {key:'msfrs',value:'msfrs'},
    {key:'msims',value:'msims'},
    {key:'ms-la',value:'ms-la'},
    {key:'msrps',value:'msrps'},
    {key:'msync',value:'msync'},
    {key:'mumps',value:'mumps'},
    {key:'munin',value:'munin'},
    {key:'m-wnn',value:'m-wnn'},
    {key:'mysql',value:'mysql'},
    {key:'nacnl',value:'nacnl'},
    {key:'ncu-1',value:'ncu-1'},
    {key:'ncu-2',value:'ncu-2'},
    {key:'ncxcp',value:'ncxcp'},
    {key:'ndmps',value:'ndmps'},
    {key:'neo4j',value:'neo4j'},
    {key:'neod1',value:'neod1'},
    {key:'neod2',value:'neod2'},
    {key:'netcp',value:'netcp'},
    {key:'neted',value:'neted'},
    {key:'neteh',value:'neteh'},
    {key:'netgw',value:'netgw'},
    {key:'netiq',value:'netiq'},
    {key:'netml',value:'netml'},
    {key:'nfapi',value:'nfapi'},
    {key:'nfile',value:'nfile'},
    {key:'nimsh',value:'nimsh'},
    {key:'ninaf',value:'ninaf'},
    {key:'ni-rt',value:'ni-rt'},
    {key:'nntps',value:'nntps'},
    {key:'nodel',value:'nodel'},
    {key:'nomad',value:'nomad'},
    {key:'nomdb',value:'nomdb'},
    {key:'nppmp',value:'nppmp'},
    {key:'nq-ap',value:'nq-ap'},
    {key:'nsrmp',value:'nsrmp'},
    {key:'nsstp',value:'nsstp'},
    {key:'ntalk',value:'ntalk'},
    {key:'ntske',value:'ntske'},
    {key:'nusdp',value:'nusdp'},
    {key:'nusrp',value:'nusrp'},
    {key:'nuxsl',value:'nuxsl'},
    {key:'nxlmd',value:'nxlmd'},
    {key:'obrpd',value:'obrpd'},
    {key:'ocaws',value:'ocaws'},
    {key:'oc-lm',value:'oc-lm'},
    {key:'odisk',value:'odisk'},
    {key:'odnsp',value:'odnsp'},
    {key:'oidsr',value:'oidsr'},
    {key:'omsdk',value:'omsdk'},
    {key:'onmux',value:'onmux'},
    {key:'orion',value:'orion'},
    {key:'osaut',value:'osaut'},
    {key:'oscit',value:'oscit'},
    {key:'osdcp',value:'osdcp'},
    {key:'ovbus',value:'ovbus'},
    {key:'ovobs',value:'ovobs'},
    {key:'ovsdb',value:'ovsdb'},
    {key:'ovwdb',value:'ovwdb'},
    {key:'pacom',value:'pacom'},
    {key:'pcast',value:'pcast'},
    {key:'pcoip',value:'pcoip'},
    {key:'pdnet',value:'pdnet'},
    {key:'perfd',value:'perfd'},
    {key:'pipes',value:'pipes'},
    {key:'plato',value:'plato'},
    {key:'pochi',value:'pochi'},
    {key:'pop3s',value:'pop3s'},
    {key:'ppsms',value:'ppsms'},
    {key:'press',value:'press'},
    {key:'prsvp',value:'prsvp'},
    {key:'pwdis',value:'pwdis'},
    {key:'q3ade',value:'q3ade'},
    {key:'qencp',value:'qencp'},
    {key:'qmtps',value:'qmtps'},
    {key:'qotps',value:'qotps'},
    {key:'qsoft',value:'qsoft'},
    {key:'quake',value:'quake'},
    {key:'qubes',value:'qubes'},
    {key:'quinn',value:'quinn'},
    {key:'quosa',value:'quosa'},
    {key:'quote',value:'quote'},
    {key:'qwave',value:'qwave'},
    {key:'radio',value:'radio'},
    {key:'radix',value:'radix'},
    {key:'razor',value:'razor'},
    {key:'rdlap',value:'rdlap'},
    {key:'re101',value:'re101'},
    {key:'rebol',value:'rebol'},
    {key:'redis',value:'redis'},
    {key:'rfile',value:'rfile'},
    {key:'rimsl',value:'rimsl'},
    {key:'ripng',value:'ripng'},
    {key:'rmlnk',value:'rmlnk'},
    {key:'rnmap',value:'rnmap'},
    {key:'robix',value:'robix'},
    {key:'rootd',value:'rootd'},
    {key:'rsf-1',value:'rsf-1'},
    {key:'rsmtp',value:'rsmtp'},
    {key:'rsync',value:'rsync'},
    {key:'rtsps',value:'rtsps'},
    {key:'rushd',value:'rushd'},
    {key:'rxapi',value:'rxapi'},
    {key:'rxmon',value:'rxmon'},
    {key:'saism',value:'saism'},
    {key:'sapms',value:'sapms'},
    {key:'sapv1',value:'sapv1'},
    {key:'saris',value:'saris'},
    {key:'sbcap',value:'sbcap'},
    {key:'s-bfd',value:'s-bfd'},
    {key:'sbook',value:'sbook'},
    {key:'scone',value:'scone'},
    {key:'scscp',value:'scscp'},
    {key:'sdmmp',value:'sdmmp'},
    {key:'senip',value:'senip'},
    {key:'sf-lm',value:'sf-lm'},
    {key:'sflow',value:'sflow'},
    {key:'sgcip',value:'sgcip'},
    {key:'sg-lm',value:'sg-lm'},
    {key:'sgsap',value:'sgsap'},
    {key:'shell',value:'shell'},
    {key:'shilp',value:'shilp'},
    {key:'sicct',value:'sicct'},
    {key:'sieve',value:'sieve'},
    {key:'simco',value:'simco'},
    {key:'simon',value:'simon'},
    {key:'sixid',value:'sixid'},
    {key:'skype',value:'skype'},
    {key:'slmap',value:'slmap'},
    {key:'slpda',value:'slpda'},
    {key:'slscc',value:'slscc'},
    {key:'slush',value:'slush'},
    {key:'smaho',value:'smaho'},
    {key:'smile',value:'smile'},
    {key:'smpte',value:'smpte'},
    {key:'smsqp',value:'smsqp'},
    {key:'smwan',value:'smwan'},
    {key:'snapd',value:'snapd'},
    {key:'snapp',value:'snapp'},
    {key:'snare',value:'snare'},
    {key:'s-net',value:'s-net'},
    {key:'socks',value:'socks'},
    {key:'solve',value:'solve'},
    {key:'sonar',value:'sonar'},
    {key:'sonus',value:'sonus'},
    {key:'spice',value:'spice'},
    {key:'spike',value:'spike'},
    {key:'spock',value:'spock'},
    {key:'spocp',value:'spocp'},
    {key:'spres',value:'spres'},
    {key:'sruth',value:'sruth'},
    {key:'ss7ns',value:'ss7ns'},
    {key:'sscan',value:'sscan'},
    {key:'ssdtp',value:'ssdtp'},
    {key:'ssmpp',value:'ssmpp'},
    {key:'ssrip',value:'ssrip'},
    {key:'stars',value:'stars'},
    {key:'stotp',value:'stotp'},
    {key:'stuns',value:'stuns'},
    {key:'suucp',value:'suucp'},
    {key:'svdrp',value:'svdrp'},
    {key:'svnet',value:'svnet'},
    {key:'swa-1',value:'swa-1'},
    {key:'swa-2',value:'swa-2'},
    {key:'swa-3',value:'swa-3'},
    {key:'swa-4',value:'swa-4'},
    {key:'swarm',value:'swarm'},
    {key:'swdtp',value:'swdtp'},
    {key:'swrmi',value:'swrmi'},
    {key:'tango',value:'tango'},
    {key:'tbrpf',value:'tbrpf'},
    {key:'tcode',value:'tcode'},
    {key:'tempo',value:'tempo'},
    {key:'texai',value:'texai'},
    {key:'texar',value:'texar'},
    {key:'tftps',value:'tftps'},
    {key:'thing',value:'thing'},
    {key:'thrtx',value:'thrtx'},
    {key:'tibsd',value:'tibsd'},
    {key:'tigv2',value:'tigv2'},
    {key:'timed',value:'timed'},
    {key:'track',value:'track'},
    {key:'tripe',value:'tripe'},
    {key:'troff',value:'troff'},
    {key:'tryst',value:'tryst'},
    {key:'tsilb',value:'tsilb'},
    {key:'turns',value:'turns'},
    {key:'tvbus',value:'tvbus'},
    {key:'twcss',value:'twcss'},
    {key:'twrpc',value:'twrpc'},
    {key:'tzrpc',value:'tzrpc'},
    {key:'uadtc',value:'uadtc'},
    {key:'uarps',value:'uarps'},
    {key:'unify',value:'unify'},
    {key:'unite',value:'unite'},
    {key:'updog',value:'updog'},
    {key:'users',value:'users'},
    {key:'us-gv',value:'us-gv'},
    {key:'utest',value:'utest'},
    {key:'utime',value:'utime'},
    {key:'uwsgi',value:'uwsgi'},
    {key:'vchat',value:'vchat'},
    {key:'vemmi',value:'vemmi'},
    {key:'venus',value:'venus'},
    {key:'vhusb',value:'vhusb'},
    {key:'visel',value:'visel'},
    {key:'vmnet',value:'vmnet'},
    {key:'vmrdp',value:'vmrdp'},
    {key:'vmsvc',value:'vmsvc'},
    {key:'vnetd',value:'vnetd'},
    {key:'vrace',value:'vrace'},
    {key:'vslmp',value:'vslmp'},
    {key:'vstat',value:'vstat'},
    {key:'vtsas',value:'vtsas'},
    {key:'vxlan',value:'vxlan'},
    {key:'weave',value:'weave'},
    {key:'webex',value:'webex'},
    {key:'websm',value:'websm'},
    {key:'wello',value:'wello'},
    {key:'whamb',value:'whamb'},
    {key:'wicop',value:'wicop'},
    {key:'willy',value:'willy'},
    {key:'windb',value:'windb'},
    {key:'winfs',value:'winfs'},
    {key:'winrm',value:'winrm'},
    {key:'wired',value:'wired'},
    {key:'witap',value:'witap'},
    {key:'wkars',value:'wkars'},
    {key:'wsman',value:'wsman'},
    {key:'wsmlb',value:'wsmlb'},
    {key:'xaapi',value:'xaapi'},
    {key:'xdmcp',value:'xdmcp'},
    {key:'xgrid',value:'xgrid'},
    {key:'xmapi',value:'xmapi'},
    {key:'xmms2',value:'xmms2'},
    {key:'xmpv7',value:'xmpv7'},
    {key:'xqosd',value:'xqosd'},
    {key:'xribs',value:'xribs'},
    {key:'xsync',value:'xsync'},
    {key:'xtgui',value:'xtgui'},
    {key:'xtrms',value:'xtrms'},
    {key:'xvttp',value:'xvttp'},
    {key:'zicom',value:'zicom'},
    {key:'zserv',value:'zserv'},
    {key:'3ds-lm',value:'3ds-lm'},
    {key:'914c/g',value:'914c/g'},
    {key:'914c-g',value:'914c-g'},
    {key:'a13-an',value:'a13-an'},
    {key:'a1-msc',value:'a1-msc'},
    {key:'aal-lm',value:'aal-lm'},
    {key:'abarsd',value:'abarsd'},
    {key:'abb-hw',value:'abb-hw'},
    {key:'acd-pm',value:'acd-pm'},
    {key:'actnet',value:'actnet'},
    {key:'admind',value:'admind'},
    {key:'aegate',value:'aegate'},
    {key:'aequus',value:'aequus'},
    {key:'aftmux',value:'aftmux'},
    {key:'agentx',value:'agentx'},
    {key:'aibkup',value:'aibkup'},
    {key:'aic-np',value:'aic-np'},
    {key:'ailith',value:'ailith'},
    {key:'amanda',value:'amanda'},
    {key:'aminet',value:'aminet'},
    {key:'ampify',value:'ampify'},
    {key:'and-lm',value:'and-lm'},
    {key:'anet-b',value:'anet-b'},
    {key:'anet-h',value:'anet-h'},
    {key:'anet-l',value:'anet-l'},
    {key:'anet-m',value:'anet-m'},
    {key:'an-pcp',value:'an-pcp'},
    {key:'apani1',value:'apani1'},
    {key:'apani2',value:'apani2'},
    {key:'apani3',value:'apani3'},
    {key:'apani4',value:'apani4'},
    {key:'apani5',value:'apani5'},
    {key:'applix',value:'applix'},
    {key:'applus',value:'applus'},
    {key:'arcnet',value:'arcnet'},
    {key:'ariel1',value:'ariel1'},
    {key:'ariel2',value:'ariel2'},
    {key:'ariel3',value:'ariel3'},
    {key:'aritts',value:'aritts'},
    {key:'armadp',value:'armadp'},
    {key:'asgenf',value:'asgenf'},
    {key:'asihpi',value:'asihpi'},
    {key:'asmgcs',value:'asmgcs'},
    {key:'atc-lm',value:'atc-lm'},
    {key:'atmtcp',value:'atmtcp'},
    {key:'at-nbp',value:'at-nbp'},
    {key:'attune',value:'attune'},
    {key:'at-zis',value:'at-zis'},
    {key:'auditd',value:'auditd'},
    {key:'aurora',value:'aurora'},
    {key:'avdecc',value:'avdecc'},
    {key:'avenue',value:'avenue'},
    {key:'avenyo',value:'avenyo'},
    {key:'bacnet',value:'bacnet'},
    {key:'batman',value:'batman'},
    {key:'beacon',value:'beacon'},
    {key:'beamer',value:'beamer'},
    {key:'bender',value:'bender'},
    {key:'BESApi',value:'BESApi'},
    {key:'bex-xr',value:'bex-xr'},
    {key:'biotic',value:'biotic'},
    {key:'bl-idm',value:'bl-idm'},
    {key:'blocks',value:'blocks'},
    {key:'bmc-ar',value:'bmc-ar'},
    {key:'bmc-ea',value:'bmc-ea'},
    {key:'bootpc',value:'bootpc'},
    {key:'bootps',value:'bootps'},
    {key:'boscap',value:'boscap'},
    {key:'brdptc',value:'brdptc'},
    {key:'brf-gw',value:'brf-gw'},
    {key:'brlp-0',value:'brlp-0'},
    {key:'brlp-1',value:'brlp-1'},
    {key:'brlp-2',value:'brlp-2'},
    {key:'brlp-3',value:'brlp-3'},
    {key:'brutus',value:'brutus'},
    {key:'bsqdea',value:'bsqdea'},
    {key:'busboy',value:'busboy'},
    {key:'buzzer',value:'buzzer'},
    {key:'bveapi',value:'bveapi'},
    {key:'bzflag',value:'bzflag'},
    {key:'caacws',value:'caacws'},
    {key:'caerpc',value:'caerpc'},
    {key:'caevms',value:'caevms'},
    {key:'caicci',value:'caicci'},
    {key:'CAIlic',value:'CAIlic'},
    {key:'caldav',value:'caldav'},
    {key:'candrp',value:'candrp'},
    {key:'capmux',value:'capmux'},
    {key:'cardax',value:'cardax'},
    {key:'ccmail',value:'ccmail'},
    {key:'ccmrmi',value:'ccmrmi'},
    {key:'cdfunc',value:'cdfunc'},
    {key:'cecsvc',value:'cecsvc'},
    {key:'centra',value:'centra'},
    {key:'cimple',value:'cimple'},
    {key:'cim-rs',value:'cim-rs'},
    {key:'citynl',value:'citynl'},
    {key:'clique',value:'clique'},
    {key:'clscts',value:'clscts'},
    {key:'comcam',value:'comcam'},
    {key:'comsat',value:'comsat'},
    {key:'comscm',value:'comscm'},
    {key:'condor',value:'condor'},
    {key:'coviot',value:'coviot'},
    {key:'csoft1',value:'csoft1'},
    {key:'cspuni',value:'cspuni'},
    {key:'ctlptc',value:'ctlptc'},
    {key:'ctxlic',value:'ctxlic'},
    {key:'custix',value:'custix'},
    {key:'cvmmon',value:'cvmmon'},
    {key:'daishi',value:'daishi'},
    {key:'davsrc',value:'davsrc'},
    {key:'db-lsp',value:'db-lsp'},
    {key:'dbstar',value:'dbstar'},
    {key:'d-data',value:'d-data'},
    {key:'ddrepl',value:'ddrepl'},
    {key:'delibo',value:'delibo'},
    {key:'de-noc',value:'de-noc'},
    {key:'device',value:'device'},
    {key:'di-ase',value:'di-ase'},
    {key:'di-drm',value:'di-drm'},
    {key:'di-msg',value:'di-msg'},
    {key:'direct',value:'direct'},
    {key:'dirgis',value:'dirgis'},
    {key:'distcc',value:'distcc'},
    {key:'dj-ice',value:'dj-ice'},
    {key:'dj-ilm',value:'dj-ilm'},
    {key:'dlsrap',value:'dlsrap'},
    {key:'dlsrpn',value:'dlsrpn'},
    {key:'dlswpn',value:'dlswpn'},
    {key:'dns2go',value:'dns2go'},
    {key:'dns-sd',value:'dns-sd'},
    {key:'doc1lm',value:'doc1lm'},
    {key:'docent',value:'docent'},
    {key:'docker',value:'docker'},
    {key:'doglms',value:'doglms'},
    {key:'domain',value:'domain'},
    {key:'dproxy',value:'dproxy'},
    {key:'drmsmc',value:'drmsmc'},
    {key:'dsETOS',value:'dsETOS'},
    {key:'ds-slp',value:'ds-slp'},
    {key:'ds-srv',value:'ds-srv'},
    {key:'d-star',value:'d-star'},
    {key:'dtspcd',value:'dtspcd'},
    {key:'dt-vra',value:'dt-vra'},
    {key:'dvapps',value:'dvapps'},
    {key:'ecwcfg',value:'ecwcfg'},
    {key:'efi-lm',value:'efi-lm'},
    {key:'efi-mg',value:'efi-mg'},
    {key:'egptlm',value:'egptlm'},
    {key:'elanlm',value:'elanlm'},
    {key:'emcads',value:'emcads'},
    {key:'emgmsg',value:'emgmsg'},
    {key:'encore',value:'encore'},
    {key:'entomb',value:'entomb'},
    {key:'epicon',value:'epicon'},
    {key:'epnsdp',value:'epnsdp'},
    {key:'ep-nsp',value:'ep-nsp'},
    {key:'ep-pcp',value:'ep-pcp'},
    {key:'esl-lm',value:'esl-lm'},
    {key:'esp-lm',value:'esp-lm'},
    {key:'estamp',value:'estamp'},
    {key:'ewctsp',value:'ewctsp'},
    {key:'exonet',value:'exonet'},
    {key:'fc-cli',value:'fc-cli'},
    {key:'fcmsys',value:'fcmsys'},
    {key:'fc-ser',value:'fc-ser'},
    {key:'ff-fms',value:'ff-fms'},
    {key:'ff-ice',value:'ff-ice'},
    {key:'fg-fps',value:'fg-fps'},
    {key:'fg-gip',value:'fg-gip'},
    {key:'filemq',value:'filemq'},
    {key:'finger',value:'finger'},
    {key:'fintrx',value:'fintrx'},
    {key:'fjhpjp',value:'fjhpjp'},
    {key:'fjmpcm',value:'fjmpcm'},
    {key:'fjmpss',value:'fjmpss'},
    {key:'flexlm',value:'flexlm'},
    {key:'fprams',value:'fprams'},
    {key:'frc-hp',value:'frc-hp'},
    {key:'frc-lp',value:'frc-lp'},
    {key:'frc-mp',value:'frc-mp'},
    {key:'fronet',value:'fronet'},
    {key:'fs-qos',value:'fs-qos'},
    {key:'ftnmtp',value:'ftnmtp'},
    {key:'ftsync',value:'ftsync'},
    {key:'futrix',value:'futrix'},
    {key:'fv-key',value:'fv-key'},
    {key:'fxuptp',value:'fxuptp'},
    {key:'garcon',value:'garcon'},
    {key:'gdomap',value:'gdomap'},
    {key:'gds_db',value:'gds_db'},
    {key:'gds-db',value:'gds-db'},
    {key:'geneve',value:'geneve'},
    {key:'gerhcs',value:'gerhcs'},
    {key:'giffer',value:'giffer'},
    {key:'glishd',value:'glishd'},
    {key:'gnunet',value:'gnunet'},
    {key:'gopher',value:'gopher'},
    {key:'groove',value:'groove'},
    {key:'gsakmp',value:'gsakmp'},
    {key:'gsiftp',value:'gsiftp'},
    {key:'gsmtap',value:'gsmtap'},
    {key:'g-talk',value:'g-talk'},
    {key:'gw-asv',value:'gw-asv'},
    {key:'gw-log',value:'gw-log'},
    {key:'hassle',value:'hassle'},
    {key:'hermes',value:'hermes'},
    {key:'hexarc',value:'hexarc'},
    {key:'hippad',value:'hippad'},
    {key:'hislip',value:'hislip'},
    {key:'hks-lm',value:'hks-lm'},
    {key:'hpr-db',value:'hpr-db'},
    {key:'hp-sca',value:'hp-sca'},
    {key:'hp-sci',value:'hp-sci'},
    {key:'hp-sco',value:'hp-sco'},
    {key:'hs-off',value:'hs-off'},
    {key:'hsrpv6',value:'hsrpv6'},
    {key:'htrust',value:'htrust'},
    {key:'hybrid',value:'hybrid'},
    {key:'iaudit',value:'iaudit'},
    {key:'icslap',value:'icslap'},
    {key:'idmaps',value:'idmaps'},
    {key:'idsync',value:'idsync'},
    {key:'ies-lm',value:'ies-lm'},
    {key:'igi-lm',value:'igi-lm'},
    {key:'ihouse',value:'ihouse'},
    {key:'impera',value:'impera'},
    {key:'ims-ni',value:'ims-ni'},
    {key:'indura',value:'indura'},
    {key:'inetfs',value:'inetfs'},
    {key:'ip-arc',value:'ip-arc'},
    {key:'ip-blf',value:'ip-blf'},
    {key:'ipcore',value:'ipcore'},
    {key:'ipfixs',value:'ipfixs'},
    {key:'ip-ipx',value:'ip-ipx'},
    {key:'ip-mtu',value:'ip-mtu'},
    {key:'ip-x25',value:'ip-x25'},
    {key:'ircs-u',value:'ircs-u'},
    {key:'irdmi2',value:'irdmi2'},
    {key:'irelay',value:'irelay'},
    {key:'isakmp',value:'isakmp'},
    {key:'iscape',value:'iscape'},
    {key:'ischat',value:'ischat'},
    {key:'ishare',value:'ishare'},
    {key:'isi-gl',value:'isi-gl'},
    {key:'iso-ip',value:'iso-ip'},
    {key:'isparx',value:'isparx'},
    {key:'istorm',value:'istorm'},
    {key:'itm-lm',value:'itm-lm'},
    {key:'jargon',value:'jargon'},
    {key:'jeditx',value:'jeditx'},
    {key:'j-link',value:'j-link'},
    {key:'jmact3',value:'jmact3'},
    {key:'jmact5',value:'jmact5'},
    {key:'jmact6',value:'jmact6'},
    {key:'jmevt2',value:'jmevt2'},
    {key:'joltid',value:'joltid'},
    {key:'ka0wuc',value:'ka0wuc'},
    {key:'ka-kdp',value:'ka-kdp'},
    {key:'kermit',value:'kermit'},
    {key:'klogin',value:'klogin'},
    {key:'krb524',value:'krb524'},
    {key:'kshell',value:'kshell'},
    {key:'labrat',value:'labrat'},
    {key:'lcs-ap',value:'lcs-ap'},
    {key:'leaf-1',value:'leaf-1'},
    {key:'leaf-2',value:'leaf-2'},
    {key:'lm-dta',value:'lm-dta'},
    {key:'lm-mon',value:'lm-mon'},
    {key:'loadav',value:'loadav'},
    {key:'ltctcp',value:'ltctcp'},
    {key:'ltcudp',value:'ltcudp'},
    {key:'lv-ffx',value:'lv-ffx'},
    {key:'lv-not',value:'lv-not'},
    {key:'lyrics',value:'lyrics'},
    {key:'lyskom',value:'lyskom'},
    {key:'macbak',value:'macbak'},
    {key:'macfoh',value:'macfoh'},
    {key:'madcap',value:'madcap'},
    {key:'magpie',value:'magpie'},
    {key:'maitrd',value:'maitrd'},
    {key:'mandos',value:'mandos'},
    {key:'matrix',value:'matrix'},
    {key:'matter',value:'matter'},
    {key:'mbap-s',value:'mbap-s'},
    {key:'mcidas',value:'mcidas'},
    {key:'mecomm',value:'mecomm'},
    {key:'med-ci',value:'med-ci'},
    {key:'mediat',value:'mediat'},
    {key:'mevent',value:'mevent'},
    {key:'minger',value:'minger'},
    {key:'mloadd',value:'mloadd'},
    {key:'mmaeds',value:'mmaeds'},
    {key:'mmcals',value:'mmcals'},
    {key:'moncon',value:'moncon'},
    {key:'mondex',value:'mondex'},
    {key:'mother',value:'mother'},
    {key:'mountd',value:'mountd'},
    {key:'mpnjsc',value:'mpnjsc'},
    {key:'msdfsr',value:'msdfsr'},
    {key:'msdts1',value:'msdts1'},
    {key:'msgsys',value:'msgsys'},
    {key:'mshnet',value:'mshnet'},
    {key:'mshvlm',value:'mshvlm'},
    {key:'msiccp',value:'msiccp'},
    {key:'ms-ilm',value:'ms-ilm'},
    {key:'ms-mcc',value:'ms-mcc'},
    {key:'msp-os',value:'msp-os'},
    {key:'mtrace',value:'mtrace'},
    {key:'mumble',value:'mumble'},
    {key:'murray',value:'murray'},
    {key:'mvx-lm',value:'mvx-lm'},
    {key:'mxomss',value:'mxomss'},
    {key:'myrtle',value:'myrtle'},
    {key:'mysqlx',value:'mysqlx'},
    {key:'mysync',value:'mysync'},
    {key:'n1-fwp',value:'n1-fwp'},
    {key:'nasmon',value:'nasmon'},
    {key:'nbt-pc',value:'nbt-pc'},
    {key:'nbx-au',value:'nbx-au'},
    {key:'nbx-cc',value:'nbx-cc'},
    {key:'neckar',value:'neckar'},
    {key:'nessus',value:'nessus'},
    {key:'netarx',value:'netarx'},
    {key:'netblt',value:'netblt'},
    {key:'netmon',value:'netmon'},
    {key:'netmpi',value:'netmpi'},
    {key:'netrcs',value:'netrcs'},
    {key:'netrek',value:'netrek'},
    {key:'netrjs',value:'netrjs'},
    {key:'newoak',value:'newoak'},
    {key:'nexgen',value:'nexgen'},
    {key:'ni-ftp',value:'ni-ftp'},
    {key:'nimaux',value:'nimaux'},
    {key:'nimgtw',value:'nimgtw'},
    {key:'nimhub',value:'nimhub'},
    {key:'nimreg',value:'nimreg'},
    {key:'nlogin',value:'nlogin'},
    {key:'nls-tl',value:'nls-tl'},
    {key:'noteit',value:'noteit'},
    {key:'noteza',value:'noteza'},
    {key:'notify',value:'notify'},
    {key:'nsw-fe',value:'nsw-fe'},
    {key:'nta-ds',value:'nta-ds'},
    {key:'nta-us',value:'nta-us'},
    {key:'nuauth',value:'nuauth'},
    {key:'nvcnet',value:'nvcnet'},
    {key:'nvmsgd',value:'nvmsgd'},
    {key:'nvp-ii',value:'nvp-ii'},
    {key:'nxedit',value:'nxedit'},
    {key:'ocasec',value:'ocasec'},
    {key:'olhost',value:'olhost'},
    {key:'omserv',value:'omserv'},
    {key:'onesaf',value:'onesaf'},
    {key:'ontime',value:'ontime'},
    {key:'opencm',value:'opencm'},
    {key:'opencu',value:'opencu'},
    {key:'openit',value:'openit'},
    {key:'opennl',value:'opennl'},
    {key:'opsmgr',value:'opsmgr'},
    {key:'oracle',value:'oracle'},
    {key:'ora-lm',value:'ora-lm'},
    {key:'orasrv',value:'orasrv'},
    {key:'orbixd',value:'orbixd'},
    {key:'osb-sd',value:'osb-sd'},
    {key:'ovhpas',value:'ovhpas'},
    {key:'p25cai',value:'p25cai'},
    {key:'p6ssmc',value:'p6ssmc'},
    {key:'parity',value:'parity'},
    {key:'passgo',value:'passgo'},
    {key:'patrol',value:'patrol'},
    {key:'pcmail',value:'pcmail'},
    {key:'pdrncs',value:'pdrncs'},
    {key:'pduncs',value:'pduncs'},
    {key:'pehelp',value:'pehelp'},
    {key:'peport',value:'peport'},
    {key:'perrla',value:'perrla'},
    {key:'pharos',value:'pharos'},
    {key:'pichat',value:'pichat'},
    {key:'pictua',value:'pictua'},
    {key:'pjlink',value:'pjlink'},
    {key:'pmdmgr',value:'pmdmgr'},
    {key:'prizma',value:'prizma'},
    {key:'prm-nm',value:'prm-nm'},
    {key:'prm-sm',value:'prm-sm'},
    {key:'pro-ed',value:'pro-ed'},
    {key:'prolog',value:'prolog'},
    {key:'proofd',value:'proofd'},
    {key:'proxim',value:'proxim'},
    {key:'ps-ams',value:'ps-ams'},
    {key:'pscupd',value:'pscupd'},
    {key:'psmond',value:'psmond'},
    {key:'pt-tls',value:'pt-tls'},
    {key:'puparp',value:'puparp'},
    {key:'puppet',value:'puppet'},
    {key:'pushns',value:'pushns'},
    {key:'puzzle',value:'puzzle'},
    {key:'pwdgen',value:'pwdgen'},
    {key:'pwgpsi',value:'pwgpsi'},
    {key:'pyrrho',value:'pyrrho'},
    {key:'qptlmd',value:'qptlmd'},
    {key:'quaddb',value:'quaddb'},
    {key:'quotad',value:'quotad'},
    {key:'radius',value:'radius'},
    {key:'radpdf',value:'radpdf'},
    {key:'radsec',value:'radsec'},
    {key:'rakket',value:'rakket'},
    {key:'rap-ip',value:'rap-ip'},
    {key:'rasadv',value:'rasadv'},
    {key:'ravehd',value:'ravehd'},
    {key:'rds-ib',value:'rds-ib'},
    {key:'rds-ip',value:'rds-ip'},
    {key:'recipe',value:'recipe'},
    {key:'reftek',value:'reftek'},
    {key:'relief',value:'relief'},
    {key:'remcap',value:'remcap'},
    {key:'remctl',value:'remctl'},
    {key:'remote',value:'remote'},
    {key:'repcmd',value:'repcmd'},
    {key:'repsvc',value:'repsvc'},
    {key:'rescap',value:'rescap'},
    {key:'rexecj',value:'rexecj'},
    {key:'rfx-lm',value:'rfx-lm'},
    {key:'ris-cm',value:'ris-cm'},
    {key:'rmiaux',value:'rmiaux'},
    {key:'roboer',value:'roboer'},
    {key:'roketz',value:'roketz'},
    {key:'router',value:'router'},
    {key:'rrifmm',value:'rrifmm'},
    {key:'rrilwm',value:'rrilwm'},
    {key:'rrimwm',value:'rrimwm'},
    {key:'rrirtr',value:'rrirtr'},
    {key:'rrisat',value:'rrisat'},
    {key:'rs-rmi',value:'rs-rmi'},
    {key:'rtnt-1',value:'rtnt-1'},
    {key:'rtnt-2',value:'rtnt-2'},
    {key:'rwhois',value:'rwhois'},
    {key:'sabams',value:'sabams'},
    {key:'sacred',value:'sacred'},
    {key:'sah-lm',value:'sah-lm'},
    {key:'saiscm',value:'saiscm'},
    {key:'saiseh',value:'saiseh'},
    {key:'sanity',value:'sanity'},
    {key:'savant',value:'savant'},
    {key:'schdca',value:'schdca'},
    {key:'schick',value:'schick'},
    {key:'schims',value:'schims'},
    {key:'schlog',value:'schlog'},
    {key:'schmpp',value:'schmpp'},
    {key:'schoms',value:'schoms'},
    {key:'schsap',value:'schsap'},
    {key:'schvpp',value:'schvpp'},
    {key:'scinet',value:'scinet'},
    {key:'scte30',value:'scte30'},
    {key:'sdfunc',value:'sdfunc'},
    {key:'sdhelp',value:'sdhelp'},
    {key:'search',value:'search'},
    {key:'seraph',value:'seraph'},
    {key:'sfmsso',value:'sfmsso'},
    {key:'sftsrv',value:'sftsrv'},
    {key:'sgi-lk',value:'sgi-lk'},
    {key:'shofar',value:'shofar'},
    {key:'signal',value:'signal'},
    {key:'siipat',value:'siipat'},
    {key:'silkp1',value:'silkp1'},
    {key:'silkp2',value:'silkp2'},
    {key:'silkp3',value:'silkp3'},
    {key:'silkp4',value:'silkp4'},
    {key:'simmon',value:'simmon'},
    {key:'sipuri',value:'sipuri'},
    {key:'skronk',value:'skronk'},
    {key:'smpppd',value:'smpppd'},
    {key:'smsync',value:'smsync'},
    {key:'sna-cs',value:'sna-cs'},
    {key:'snagas',value:'snagas'},
    {key:'socp-c',value:'socp-c'},
    {key:'socp-t',value:'socp-t'},
    {key:'softcm',value:'softcm'},
    {key:'softpc',value:'softpc'},
    {key:'sparql',value:'sparql'},
    {key:'spidap',value:'spidap'},
    {key:'spugna',value:'spugna'},
    {key:'sqlsrv',value:'sqlsrv'},
    {key:'sshell',value:'sshell'},
    {key:'ss-idi',value:'ss-idi'},
    {key:'sstp-1',value:'sstp-1'},
    {key:'sstp-2',value:'sstp-2'},
    {key:'stanza',value:'stanza'},
    {key:'stdptc',value:'stdptc'},
    {key:'submit',value:'submit'},
    {key:'suitjd',value:'suitjd'},
    {key:'sun-dr',value:'sun-dr'},
    {key:'sun-lm',value:'sun-lm'},
    {key:'sunrpc',value:'sunrpc'},
    {key:'supdup',value:'supdup'},
    {key:'supple',value:'supple'},
    {key:'svrloc',value:'svrloc'},
    {key:'sxqdea',value:'sxqdea'},
    {key:'sxuptp',value:'sxuptp'},
    {key:'syslog',value:'syslog'},
    {key:'sysopt',value:'sysopt'},
    {key:'sysorb',value:'sysorb'},
    {key:'sysrqd',value:'sysrqd'},
    {key:'systat',value:'systat'},
    {key:'t2-brm',value:'t2-brm'},
    {key:'t2-drm',value:'t2-drm'},
    {key:'tabula',value:'tabula'},
    {key:'tacacs',value:'tacacs'},
    {key:'tag-pm',value:'tag-pm'},
    {key:'talnet',value:'talnet'},
    {key:'tcpmux',value:'tcpmux'},
    {key:'tdmoip',value:'tdmoip'},
    {key:'tekpls',value:'tekpls'},
    {key:'telnet',value:'telnet'},
    {key:'teredo',value:'teredo'},
    {key:'ticf-1',value:'ticf-1'},
    {key:'ticf-2',value:'ticf-2'},
    {key:'tiepie',value:'tiepie'},
    {key:'tl1-lv',value:'tl1-lv'},
    {key:'tlisrv',value:'tlisrv'},
    {key:'tnETOS',value:'tnETOS'},
    {key:'tnmpv2',value:'tnmpv2'},
    {key:'tolfab',value:'tolfab'},
    {key:'tomboy',value:'tomboy'},
    {key:'tp-tcp',value:'tp-tcp'},
    {key:'tqdata',value:'tqdata'},
    {key:'tragic',value:'tragic'},
    {key:'truecm',value:'truecm'},
    {key:'tsbiis',value:'tsbiis'},
    {key:'tunage',value:'tunage'},
    {key:'tunnel',value:'tunnel'},
    {key:'twsdss',value:'twsdss'},
    {key:'uaiact',value:'uaiact'},
    {key:'u-dbap',value:'u-dbap'},
    {key:'udrive',value:'udrive'},
    {key:'udt_os',value:'udt_os'},
    {key:'udt-os',value:'udt-os'},
    {key:'ulpnet',value:'ulpnet'},
    {key:'ultrex',value:'ultrex'},
    {key:'unglue',value:'unglue'},
    {key:'unieng',value:'unieng'},
    {key:'unisql',value:'unisql'},
    {key:'uohost',value:'uohost'},
    {key:'us-cli',value:'us-cli'},
    {key:'us-srv',value:'us-srv'},
    {key:'utmpcd',value:'utmpcd'},
    {key:'utmpsd',value:'utmpsd'},
    {key:'utsftp',value:'utsftp'},
    {key:'vaprtm',value:'vaprtm'},
    {key:'vatata',value:'vatata'},
    {key:'vcscmd',value:'vcscmd'},
    {key:'vettcp',value:'vettcp'},
    {key:'vidigo',value:'vidigo'},
    {key:'vipera',value:'vipera'},
    {key:'vmodem',value:'vmodem'},
    {key:'vnsstr',value:'vnsstr'},
    {key:'vns-tp',value:'vns-tp'},
    {key:'volley',value:'volley'},
    {key:'vopied',value:'vopied'},
    {key:'vsinet',value:'vsinet'},
    {key:'vsixml',value:'vsixml'},
    {key:'vt-ssl',value:'vt-ssl'},
    {key:'vxi-11',value:'vxi-11'},
    {key:'warmux',value:'warmux'},
    {key:'wb-mon',value:'wb-mon'},
    {key:'wd-2go',value:'wd-2go'},
    {key:'webdav',value:'webdav'},
    {key:'websnp',value:'websnp'},
    {key:'webtie',value:'webtie'},
    {key:'web-xi',value:'web-xi'},
    {key:'wedraw',value:'wedraw'},
    {key:'whoami',value:'whoami'},
    {key:'wifile',value:'wifile'},
    {key:'wifree',value:'wifree'},
    {key:'wimsic',value:'wimsic'},
    {key:'winddx',value:'winddx'},
    {key:'windlm',value:'windlm'},
    {key:'winpcs',value:'winpcs'},
    {key:'wizard',value:'wizard'},
    {key:'wpages',value:'wpages'},
    {key:'wsdapi',value:'wsdapi'},
    {key:'wsmans',value:'wsmans'},
    {key:'wspipe',value:'wspipe'},
    {key:'wsynch',value:'wsynch'},
    {key:'wusage',value:'wusage'},
    {key:'wysdma',value:'wysdma'},
    {key:'wysdmc',value:'wysdmc'},
    {key:'x500ms',value:'x500ms'},
    {key:'xadmin',value:'xadmin'},
    {key:'xap-ha',value:'xap-ha'},
    {key:'xdsxdm',value:'xdsxdm'},
    {key:'xns-ch',value:'xns-ch'},
    {key:'xoraya',value:'xoraya'},
    {key:'xpanel',value:'xpanel'},
    {key:'xpilot',value:'xpilot'},
    {key:'xprtld',value:'xprtld'},
    {key:'xsmsvc',value:'xsmsvc'},
    {key:'yakumo',value:'yakumo'},
    {key:'z39.50',value:'z39.50'},
    {key:'z39-50',value:'z39-50'},
    {key:'zannet',value:'zannet'},
    {key:'zarkov',value:'zarkov'},
    {key:'zented',value:'zented'},
    {key:'zeromq',value:'zeromq'},
    {key:'zoomcp',value:'zoomcp'},
    {key:'z-wave',value:'z-wave'},
    {key:'3d-nfsd',value:'3d-nfsd'},
    {key:'abatjss',value:'abatjss'},
    {key:'abr-api',value:'abr-api'},
    {key:'acmsoda',value:'acmsoda'},
    {key:'acptsys',value:'acptsys'},
    {key:'ac-tech',value:'ac-tech'},
    {key:'ada-cip',value:'ada-cip'},
    {key:'adnodes',value:'adnodes'},
    {key:'aed-512',value:'aed-512'},
    {key:'ag-swim',value:'ag-swim'},
    {key:'aiagent',value:'aiagent'},
    {key:'airdrop',value:'airdrop'},
    {key:'airmate',value:'airmate'},
    {key:'airplay',value:'airplay'},
    {key:'airport',value:'airport'},
    {key:'airshot',value:'airshot'},
    {key:'airsync',value:'airsync'},
    {key:'alchemy',value:'alchemy'},
    {key:'alljoyn',value:'alljoyn'},
    {key:'aloe-pp',value:'aloe-pp'},
    {key:'altbsdp',value:'altbsdp'},
    {key:'amberon',value:'amberon'},
    {key:'ams-htm',value:'ams-htm'},
    {key:'amx-rms',value:'amx-rms'},
    {key:'ansysli',value:'ansysli'},
    {key:'antrmai',value:'antrmai'},
    {key:'apcupsd',value:'apcupsd'},
    {key:'appletv',value:'appletv'},
    {key:'apri-lm',value:'apri-lm'},
    {key:'aquamon',value:'aquamon'},
    {key:'arkivio',value:'arkivio'},
    {key:'asterix',value:'asterix'},
    {key:'at-echo',value:'at-echo'},
    {key:'atlinks',value:'atlinks'},
    {key:'at-rtmp',value:'at-rtmp'},
    {key:'autonoc',value:'autonoc'},
    {key:'autopac',value:'autopac'},
    {key:'avatars',value:'avatars'},
    {key:'avi-nms',value:'avi-nms'},
    {key:'aws-brf',value:'aws-brf'},
    {key:'aws-wsp',value:'aws-wsp'},
    {key:'axon-lm',value:'axon-lm'},
    {key:'bbn-mmc',value:'bbn-mmc'},
    {key:'bbn-mmx',value:'bbn-mmx'},
    {key:'bcslogc',value:'bcslogc'},
    {key:'beeyond',value:'beeyond'},
    {key:'berknet',value:'berknet'},
    {key:'bfagent',value:'bfagent'},
    {key:'bfd-lag',value:'bfd-lag'},
    {key:'bf-game',value:'bf-game'},
    {key:'bgs-nsi',value:'bgs-nsi'},
    {key:'bhevent',value:'bhevent'},
    {key:'bhoetty',value:'bhoetty'},
    {key:'biap-mp',value:'biap-mp'},
    {key:'biimenu',value:'biimenu'},
    {key:'bilobit',value:'bilobit'},
    {key:'bim-pem',value:'bim-pem'},
    {key:'bis-web',value:'bis-web'},
    {key:'bitflit',value:'bitflit'},
    {key:'blizwow',value:'blizwow'},
    {key:'bmc-gms',value:'bmc-gms'},
    {key:'bmc-grx',value:'bmc-grx'},
    {key:'bngsync',value:'bngsync'},
    {key:'boe-cms',value:'boe-cms'},
    {key:'boe-was',value:'boe-was'},
    {key:'bounzza',value:'bounzza'},
    {key:'brvread',value:'brvread'},
    {key:'btrieve',value:'btrieve'},
    {key:'bts-x73',value:'bts-x73'},
    {key:'busycal',value:'busycal'},
    {key:'caci-lm',value:'caci-lm'},
    {key:'cadis-1',value:'cadis-1'},
    {key:'cadis-2',value:'cadis-2'},
    {key:'cadlock',value:'cadlock'},
    {key:'ca-idms',value:'ca-idms'},
    {key:'caldavs',value:'caldavs'},
    {key:'caller9',value:'caller9'},
    {key:'caltalk',value:'caltalk'},
    {key:'can-dch',value:'can-dch'},
    {key:'canditv',value:'canditv'},
    {key:'can-nds',value:'can-nds'},
    {key:'caps-lm',value:'caps-lm'},
    {key:'cardbox',value:'cardbox'},
    {key:'carddav',value:'carddav'},
    {key:'caspssl',value:'caspssl'},
    {key:'cautcpd',value:'cautcpd'},
    {key:'ccmcomm',value:'ccmcomm'},
    {key:'ccowcmr',value:'ccowcmr'},
    {key:'cerebra',value:'cerebra'},
    {key:'cesdinv',value:'cesdinv'},
    {key:'cfdptkt',value:'cfdptkt'},
    {key:'chargen',value:'chargen'},
    {key:'chip-lm',value:'chip-lm'},
    {key:'chipper',value:'chipper'},
    {key:'chshell',value:'chshell'},
    {key:'cichlid',value:'cichlid'},
    {key:'cimplex',value:'cimplex'},
    {key:'cimtrak',value:'cimtrak'},
    {key:'citadel',value:'citadel'},
    {key:'classic',value:'classic'},
    {key:'clutild',value:'clutild'},
    {key:'cmadmin',value:'cmadmin'},
    {key:'cmtp-av',value:'cmtp-av'},
    {key:'codasrv',value:'codasrv'},
    {key:'cognima',value:'cognima'},
    {key:'connect',value:'connect'},
    {key:'convert',value:'convert'},
    {key:'copycat',value:'copycat'},
    {key:'core-rd',value:'core-rd'},
    {key:'corerjd',value:'corerjd'},
    {key:'couchdb',value:'couchdb'},
    {key:'courier',value:'courier'},
    {key:'cpscomm',value:'cpscomm'},
    {key:'cs-live',value:'cs-live'},
    {key:'csoauth',value:'csoauth'},
    {key:'ct2nmcs',value:'ct2nmcs'},
    {key:'cuelink',value:'cuelink'},
    {key:'cumulus',value:'cumulus'},
    {key:'cuseeme',value:'cuseeme'},
    {key:'cyaserv',value:'cyaserv'},
    {key:'cypress',value:'cypress'},
    {key:'davsrcs',value:'davsrcs'},
    {key:'daytime',value:'daytime'},
    {key:'dbabble',value:'dbabble'},
    {key:'dbaudio',value:'dbaudio'},
    {key:'ddm-dfm',value:'ddm-dfm'},
    {key:'ddm-rdb',value:'ddm-rdb'},
    {key:'ddm-ssl',value:'ddm-ssl'},
    {key:'ddns-v3',value:'ddns-v3'},
    {key:'dec_dlm',value:'dec_dlm'},
    {key:'decauth',value:'decauth'},
    {key:'decbsrv',value:'decbsrv'},
    {key:'dec-dlm',value:'dec-dlm'},
    {key:'dectalk',value:'dectalk'},
    {key:'de-spot',value:'de-spot'},
    {key:'device2',value:'device2'},
    {key:'d-fence',value:'d-fence'},
    {key:'digiman',value:'digiman'},
    {key:'discard',value:'discard'},
    {key:'display',value:'display'},
    {key:'ditrios',value:'ditrios'},
    {key:'dlpx-sp',value:'dlpx-sp'},
    {key:'dls-mon',value:'dls-mon'},
    {key:'dna-cml',value:'dna-cml'},
    {key:'dnp-sec',value:'dnp-sec'},
    {key:'dns-llq',value:'dns-llq'},
    {key:'docstor',value:'docstor'},
    {key:'dof-eps',value:'dof-eps'},
    {key:'dossier',value:'dossier'},
    {key:'dp-bura',value:'dp-bura'},
    {key:'dpm-acm',value:'dpm-acm'},
    {key:'dpserve',value:'dpserve'},
    {key:'drizzle',value:'drizzle'},
    {key:'drmsfsd',value:'drmsfsd'},
    {key:'ds-clnt',value:'ds-clnt'},
    {key:'dserver',value:'dserver'},
    {key:'dsgsync',value:'dsgsync'},
    {key:'ds-mail',value:'ds-mail'},
    {key:'dsmipv6',value:'dsmipv6'},
    {key:'dsp3270',value:'dsp3270'},
    {key:'dssiapi',value:'dssiapi'},
    {key:'ds-srvr',value:'ds-srvr'},
    {key:'ds-user',value:'ds-user'},
    {key:'dtp-dia',value:'dtp-dia'},
    {key:'dtp-net',value:'dtp-net'},
    {key:'dvr-esm',value:'dvr-esm'},
    {key:'dyna-lm',value:'dyna-lm'},
    {key:'dynamid',value:'dynamid'},
    {key:'eb-sync',value:'eb-sync'},
    {key:'echonet',value:'echonet'},
    {key:'ecmport',value:'ecmport'},
    {key:'ecsqdmn',value:'ecsqdmn'},
    {key:'edbsrvr',value:'edbsrvr'},
    {key:'e-dpnet',value:'e-dpnet'},
    {key:'edtools',value:'edtools'},
    {key:'efb-aci',value:'efb-aci'},
    {key:'egistix',value:'egistix'},
    {key:'ehs-ssl',value:'ehs-ssl'},
    {key:'eisport',value:'eisport'},
    {key:'ellpack',value:'ellpack'},
    {key:'elxmgmt',value:'elxmgmt'},
    {key:'embrace',value:'embrace'},
    {key:'epl-slp',value:'epl-slp'},
    {key:'epncdp2',value:'epncdp2'},
    {key:'escp-ip',value:'escp-ip'},
    {key:'es-elmd',value:'es-elmd'},
    {key:'espeech',value:'espeech'},
    {key:'essbase',value:'essbase'},
    {key:'etebac5',value:'etebac5'},
    {key:'evb-elm',value:'evb-elm'},
    {key:'ewelink',value:'ewelink'},
    {key:'ew-mgmt',value:'ew-mgmt'},
    {key:'example',value:'example'},
    {key:'excerpt',value:'excerpt'},
    {key:'eye2eye',value:'eye2eye'},
    {key:'eyelink',value:'eyelink'},
    {key:'eyetvsn',value:'eyetvsn'},
    {key:'ezproxy',value:'ezproxy'},
    {key:'ezrelay',value:'ezrelay'},
    {key:'farenet',value:'farenet'},
    {key:'fatpipe',value:'fatpipe'},
    {key:'fatserv',value:'fatserv'},
    {key:'faximum',value:'faximum'},
    {key:'faxstfx',value:'faxstfx'},
    {key:'fconfig',value:'fconfig'},
    {key:'fcp-udp',value:'fcp-udp'},
    {key:'finisar',value:'finisar'},
    {key:'firefox',value:'firefox'},
    {key:'fjmpjps',value:'fjmpjps'},
    {key:'flex-lm',value:'flex-lm'},
    {key:'fln-spx',value:'fln-spx'},
    {key:'fl-purr',value:'fl-purr'},
    {key:'fpo-fns',value:'fpo-fns'},
    {key:'freeciv',value:'freeciv'},
    {key:'fs-mgmt',value:'fs-mgmt'},
    {key:'ftranhc',value:'ftranhc'},
    {key:'ft-role',value:'ft-role'},
    {key:'fv-cert',value:'fv-cert'},
    {key:'fv-time',value:'fv-time'},
    {key:'galileo',value:'galileo'},
    {key:'gat-lmd',value:'gat-lmd'},
    {key:'gbjd816',value:'gbjd816'},
    {key:'gbs-smp',value:'gbs-smp'},
    {key:'gbs-stp',value:'gbs-stp'},
    {key:'gcm-app',value:'gcm-app'},
    {key:'gearman',value:'gearman'},
    {key:'geneous',value:'geneous'},
    {key:'genstat',value:'genstat'},
    {key:'ggf-ncp',value:'ggf-ncp'},
    {key:'glogger',value:'glogger'},
    {key:'gppitnp',value:'gppitnp'},
    {key:'griffin',value:'griffin'},
    {key:'gsidcap',value:'gsidcap'},
    {key:'guibase',value:'guibase'},
    {key:'gxtelmd',value:'gxtelmd'},
    {key:'hacl-gs',value:'hacl-gs'},
    {key:'hacl-hb',value:'hacl-hb'},
    {key:'hacl-qs',value:'hacl-qs'},
    {key:'hart-ip',value:'hart-ip'},
    {key:'hdl-srv',value:'hdl-srv'},
    {key:'healthd',value:'healthd'},
    {key:'heliosd',value:'heliosd'},
    {key:'here-lm',value:'here-lm'},
    {key:'hlibmgr',value:'hlibmgr'},
    {key:'hmmp-op',value:'hmmp-op'},
    {key:'homekit',value:'homekit'},
    {key:'honyaku',value:'honyaku'},
    {key:'hotwayd',value:'hotwayd'},
    {key:'houston',value:'houston'},
    {key:'hp-clic',value:'hp-clic'},
    {key:'hpdevms',value:'hpdevms'},
    {key:'hp-hcip',value:'hp-hcip'},
    {key:'hpocbus',value:'hpocbus'},
    {key:'hpr-rep',value:'hpr-rep'},
    {key:'hpvroom',value:'hpvroom'},
    {key:'hrd-ncs',value:'hrd-ncs'},
    {key:'hs-port',value:'hs-port'},
    {key:'huesync',value:'huesync'},
    {key:'hylafax',value:'hylafax'},
    {key:'hyper-g',value:'hyper-g'},
    {key:'hyperip',value:'hyperip'},
    {key:'ias-reg',value:'ias-reg'},
    {key:'iba-cfg',value:'iba-cfg'},
    {key:'ibm3494',value:'ibm3494'},
    {key:'ibm-app',value:'ibm-app'},
    {key:'ibm-db2',value:'ibm-db2'},
    {key:'ibm-mgr',value:'ibm-mgr'},
    {key:'ibm-pps',value:'ibm-pps'},
    {key:'ibm-res',value:'ibm-res'},
    {key:'ibm-ssd',value:'ibm-ssd'},
    {key:'icad-el',value:'icad-el'},
    {key:'icg-swp',value:'icg-swp'},
    {key:'ideesrv',value:'ideesrv'},
    {key:'iec-104',value:'iec-104'},
    {key:'iee-qfx',value:'iee-qfx'},
    {key:'ifolder',value:'ifolder'},
    {key:'imgames',value:'imgames'},
    {key:'imgsync',value:'imgsync'},
    {key:'imsldoc',value:'imsldoc'},
    {key:'infoman',value:'infoman'},
    {key:'infotos',value:'infotos'},
    {key:'innosys',value:'innosys'},
    {key:'inspect',value:'inspect'},
    {key:'ip-arpa',value:'ip-arpa'},
    {key:'ipdr-sp',value:'ipdr-sp'},
    {key:'ip-fddi',value:'ip-fddi'},
    {key:'ip-ieee',value:'ip-ieee'},
    {key:'ip-qsig',value:'ip-qsig'},
    {key:'ip-slip',value:'ip-slip'},
    {key:'irc-iot',value:'irc-iot'},
    {key:'irtrans',value:'irtrans'},
    {key:'isi-irp',value:'isi-irp'},
    {key:'isis-am',value:'isis-am'},
    {key:'iso-ill',value:'iso-ill'},
    {key:'isomair',value:'isomair'},
    {key:'iso-tp0',value:'iso-tp0'},
    {key:'iso-tp4',value:'iso-tp4'},
    {key:'ispipes',value:'ispipes'},
    {key:'ispmmgr',value:'ispmmgr'},
    {key:'ispq-vc',value:'ispq-vc'},
    {key:'isticky',value:'isticky'},
    {key:'i-zipqd',value:'i-zipqd'},
    {key:'jamlink',value:'jamlink'},
    {key:'jaxflow',value:'jaxflow'},
    {key:'jbroker',value:'jbroker'},
    {key:'jesmsjc',value:'jesmsjc'},
    {key:'jetform',value:'jetform'},
    {key:'jibe-eb',value:'jibe-eb'},
    {key:'j-lan-p',value:'j-lan-p'},
    {key:'journee',value:'journee'},
    {key:'jukebox',value:'jukebox'},
    {key:'ka-sddp',value:'ka-sddp'},
    {key:'k-block',value:'k-block'},
    {key:'keysrvr',value:'keysrvr'},
    {key:'kpasswd',value:'kpasswd'},
    {key:'kpn-icw',value:'kpn-icw'},
    {key:'ktelnet',value:'ktelnet'},
    {key:'kz-migr',value:'kz-migr'},
    {key:'l3-hawk',value:'l3-hawk'},
    {key:'laes-bf',value:'laes-bf'},
    {key:'laplink',value:'laplink'},
    {key:'lexicon',value:'lexicon'},
    {key:'liaison',value:'liaison'},
    {key:'libelle',value:'libelle'},
    {key:'library',value:'library'},
    {key:'lipsinc',value:'lipsinc'},
    {key:'livelan',value:'livelan'},
    {key:'llm-csv',value:'llm-csv'},
    {key:'lnvmaps',value:'lnvmaps'},
    {key:'loc-srv',value:'loc-srv'},
    {key:'lofr-lm',value:'lofr-lm'},
    {key:'lontalk',value:'lontalk'},
    {key:'lumiere',value:'lumiere'},
    {key:'lux-dtp',value:'lux-dtp'},
    {key:'lv-auth',value:'lv-auth'},
    {key:'lv-pici',value:'lv-pici'},
    {key:'M30S1PT',value:'M30S1PT'},
    {key:'maestro',value:'maestro'},
    {key:'magbind',value:'magbind'},
    {key:'magicom',value:'magicom'},
    {key:'mailbox',value:'mailbox'},
    {key:'martalk',value:'martalk'},
    {key:'matterc',value:'matterc'},
    {key:'matterd',value:'matterd'},
    {key:'mavlink',value:'mavlink'},
    {key:'MaxumSP',value:'MaxumSP'},
    {key:'mcagent',value:'mcagent'},
    {key:'mc-comm',value:'mc-comm'},
    {key:'mctfeed',value:'mctfeed'},
    {key:'med-ltp',value:'med-ltp'},
    {key:'med-ovw',value:'med-ovw'},
    {key:'mesamis',value:'mesamis'},
    {key:'meshcop',value:'meshcop'},
    {key:'metasys',value:'metasys'},
    {key:'mfcobol',value:'mfcobol'},
    {key:'mfe-nsp',value:'mfe-nsp'},
    {key:'minipay',value:'minipay'},
    {key:'minirem',value:'minirem'},
    {key:'mirrtex',value:'mirrtex'},
    {key:'mit-dov',value:'mit-dov'},
    {key:'mmacomm',value:'mmacomm'},
    {key:'monetra',value:'monetra'},
    {key:'mongodb',value:'mongodb'},
    {key:'monitor',value:'monitor'},
    {key:'MOS-aux',value:'MOS-aux'},
    {key:'mpfoncl',value:'mpfoncl'},
    {key:'mpfwsas',value:'mpfwsas'},
    {key:'mpls-pm',value:'mpls-pm'},
    {key:'mpm-snd',value:'mpm-snd'},
    {key:'mpshrsv',value:'mpshrsv'},
    {key:'msft-gc',value:'msft-gc'},
    {key:'msgclnt',value:'msgclnt'},
    {key:'msg-icp',value:'msg-icp'},
    {key:'msgsrvr',value:'msgsrvr'},
    {key:'msl_lmd',value:'msl_lmd'},
    {key:'msl-lmd',value:'msl-lmd'},
    {key:'ms-rome',value:'ms-rome'},
    {key:'mupdate',value:'mupdate'},
    {key:'mvel-lm',value:'mvel-lm'},
    {key:'myblast',value:'myblast'},
    {key:'nbt-wol',value:'nbt-wol'},
    {key:'nbx-dir',value:'nbx-dir'},
    {key:'nbx-ser',value:'nbx-ser'},
    {key:'ncpm-ft',value:'ncpm-ft'},
    {key:'ncpm-pm',value:'ncpm-pm'},
    {key:'ncr_ccl',value:'ncr_ccl'},
    {key:'ncr-ccl',value:'ncr-ccl'},
    {key:'ndl-aas',value:'ndl-aas'},
    {key:'ndl-als',value:'ndl-als'},
    {key:'ndl-aps',value:'ndl-aps'},
    {key:'nds_sso',value:'nds_sso'},
    {key:'ndsauth',value:'ndsauth'},
    {key:'nds-sso',value:'nds-sso'},
    {key:'netaspi',value:'netaspi'},
    {key:'netblox',value:'netblox'},
    {key:'netchat',value:'netchat'},
    {key:'netclip',value:'netclip'},
    {key:'netnews',value:'netnews'},
    {key:'netperf',value:'netperf'},
    {key:'netplan',value:'netplan'},
    {key:'netrisk',value:'netrisk'},
    {key:'nettest',value:'nettest'},
    {key:'netwall',value:'netwall'},
    {key:'nextcap',value:'nextcap'},
    {key:'nfsrdma',value:'nfsrdma'},
    {key:'ng-umds',value:'ng-umds'},
    {key:'nicname',value:'nicname'},
    {key:'ni-mail',value:'ni-mail'},
    {key:'nim-wan',value:'nim-wan'},
    {key:'niprobe',value:'niprobe'},
    {key:'noadmin',value:'noadmin'},
    {key:'noagent',value:'noagent'},
    {key:'nsc-ccs',value:'nsc-ccs'},
    {key:'nsesrvr',value:'nsesrvr'},
    {key:'nsiiops',value:'nsiiops'},
    {key:'nucleus',value:'nucleus'},
    {key:'objcall',value:'objcall'},
    {key:'ocs_amu',value:'ocs_amu'},
    {key:'ocs_cmu',value:'ocs_cmu'},
    {key:'ocs-amu',value:'ocs-amu'},
    {key:'ocs-cmu',value:'ocs-cmu'},
    {key:'octopus',value:'octopus'},
    {key:'ohimsrv',value:'ohimsrv'},
    {key:'oi-2000',value:'oi-2000'},
    {key:'oma-ilp',value:'oma-ilp'},
    {key:'oma-mlp',value:'oma-mlp'},
    {key:'oma-rlp',value:'oma-rlp'},
    {key:'oma-ulp',value:'oma-ulp'},
    {key:'omnisky',value:'omnisky'},
    {key:'openvpn',value:'openvpn'},
    {key:'ora-oap',value:'ora-oap'},
    {key:'orbiter',value:'orbiter'},
    {key:'osm-oev',value:'osm-oev'},
    {key:'osu-nms',value:'osu-nms'},
    {key:'otpatch',value:'otpatch'},
    {key:'outlaws',value:'outlaws'},
    {key:'ovready',value:'ovready'},
    {key:'ovtopmd',value:'ovtopmd'},
    {key:'owhttpd',value:'owhttpd'},
    {key:'p2pchat',value:'p2pchat'},
    {key:'pacmand',value:'pacmand'},
    {key:'pammrpc',value:'pammrpc'},
    {key:'panasas',value:'panasas'},
    {key:'panoply',value:'panoply'},
    {key:'pawserv',value:'pawserv'},
    {key:'pcc-mfp',value:'pcc-mfp'},
    {key:'pcihreq',value:'pcihreq'},
    {key:'pcs-pcw',value:'pcs-pcw'},
    {key:'pdap-np',value:'pdap-np'},
    {key:'pda-sys',value:'pda-sys'},
    {key:'pdefmns',value:'pdefmns'},
    {key:'pegasus',value:'pegasus'},
    {key:'pe-mike',value:'pe-mike'},
    {key:'peocoll',value:'peocoll'},
    {key:'peoctlr',value:'peoctlr'},
    {key:'persona',value:'persona'},
    {key:'phrelay',value:'phrelay'},
    {key:'piccolo',value:'piccolo'},
    {key:'picknfs',value:'picknfs'},
    {key:'picodbc',value:'picodbc'},
    {key:'piesync',value:'piesync'},
    {key:'pinghgl',value:'pinghgl'},
    {key:'pipedal',value:'pipedal'},
    {key:'pit-vpn',value:'pit-vpn'},
    {key:'pkagent',value:'pkagent'},
    {key:'pkixrep',value:'pkixrep'},
    {key:'pmdfmgt',value:'pmdfmgt'},
    {key:'pokeeye',value:'pokeeye'},
    {key:'pov-ray',value:'pov-ray'},
    {key:'prelude',value:'prelude'},
    {key:'printer',value:'printer'},
    {key:'privoxy',value:'privoxy'},
    {key:'profile',value:'profile'},
    {key:'prolink',value:'prolink'},
    {key:'pscribe',value:'pscribe'},
    {key:'pserver',value:'pserver'},
    {key:'psi-ptt',value:'psi-ptt'},
    {key:'psqlmws',value:'psqlmws'},
    {key:'ptp-req',value:'ptp-req'},
    {key:'pwgwims',value:'pwgwims'},
    {key:'pxc-pin',value:'pxc-pin'},
    {key:'q55-pcc',value:'q55-pcc'},
    {key:'qbikgdp',value:'qbikgdp'},
    {key:'qmvideo',value:'qmvideo'},
    {key:'qsm-gui',value:'qsm-gui'},
    {key:'raadmin',value:'raadmin'},
    {key:'radmind',value:'radmind'},
    {key:'raid-ac',value:'raid-ac'},
    {key:'raid-am',value:'raid-am'},
    {key:'raid-cc',value:'raid-cc'},
    {key:'raid-cd',value:'raid-cd'},
    {key:'raid-cs',value:'raid-cs'},
    {key:'raid-sf',value:'raid-sf'},
    {key:'rancher',value:'rancher'},
    {key:'rbt-smc',value:'rbt-smc'},
    {key:'rdm-tfs',value:'rdm-tfs'},
    {key:'rdrmshc',value:'rdrmshc'},
    {key:'repscmd',value:'repscmd'},
    {key:'resorcs',value:'resorcs'},
    {key:'res-sap',value:'res-sap'},
    {key:'rib-slm',value:'rib-slm'},
    {key:'rich-cp',value:'rich-cp'},
    {key:'rimf-ps',value:'rimf-ps'},
    {key:'rmopagt',value:'rmopagt'},
    {key:'roambot',value:'roambot'},
    {key:'roboeda',value:'roboeda'},
    {key:'rocrail',value:'rocrail'},
    {key:'rothaga',value:'rothaga'},
    {key:'rpas-c2',value:'rpas-c2'},
    {key:'rpasswd',value:'rpasswd'},
    {key:'rradict',value:'rradict'},
    {key:'rr-disc',value:'rr-disc'},
    {key:'rsh-spx',value:'rsh-spx'},
    {key:'rs-pias',value:'rs-pias'},
    {key:'rtelnet',value:'rtelnet'},
    {key:'rtsserv',value:'rtsserv'},
    {key:'rym-rrc',value:'rym-rrc'},
    {key:'sabarsd',value:'sabarsd'},
    {key:'sandvox',value:'sandvox'},
    {key:'sat-mon',value:'sat-mon'},
    {key:'sbackup',value:'sbackup'},
    {key:'scaleft',value:'scaleft'},
    {key:'scanner',value:'scanner'},
    {key:'scenccs',value:'scenccs'},
    {key:'scenidm',value:'scenidm'},
    {key:'sc-golf',value:'sc-golf'},
    {key:'schsrmp',value:'schsrmp'},
    {key:'sco-aip',value:'sco-aip'},
    {key:'scohelp',value:'scohelp'},
    {key:'scservp',value:'scservp'},
    {key:'scte104',value:'scte104'},
    {key:'sd-data',value:'sd-data'},
    {key:'sd-elmd',value:'sd-elmd'},
    {key:'sdl-ets',value:'sdl-ets'},
    {key:'sdnskmp',value:'sdnskmp'},
    {key:'sdo-ssh',value:'sdo-ssh'},
    {key:'sdo-tls',value:'sdo-tls'},
    {key:'sdproxy',value:'sdproxy'},
    {key:'sdsc-lm',value:'sdsc-lm'},
    {key:'sdt-lmd',value:'sdt-lmd'},
    {key:'seaodbc',value:'seaodbc'},
    {key:'seaview',value:'seaview'},
    {key:'seeCard',value:'seeCard'},
    {key:'seispoc',value:'seispoc'},
    {key:'sent-lm',value:'sent-lm'},
    {key:'sesi-lm',value:'sesi-lm'},
    {key:'shifter',value:'shifter'},
    {key:'shipsgm',value:'shipsgm'},
    {key:'simctlp',value:'simctlp'},
    {key:'sis-emt',value:'sis-emt'},
    {key:'sixchat',value:'sixchat'},
    {key:'sixtrak',value:'sixtrak'},
    {key:'slimcli',value:'slimcli'},
    {key:'slm-api',value:'slm-api'},
    {key:'sma-spw',value:'sma-spw'},
    {key:'smc-jmx',value:'smc-jmx'},
    {key:'sm-disc',value:'sm-disc'},
    {key:'smimeca',value:'smimeca'},
    {key:'snmpssh',value:'snmpssh'},
    {key:'snmptls',value:'snmptls'},
    {key:'socalia',value:'socalia'},
    {key:'socorfs',value:'socorfs'},
    {key:'spramsd',value:'spramsd'},
    {key:'spss-lm',value:'spss-lm'},
    {key:'spx-hmp',value:'spx-hmp'},
    {key:'sql*net',value:'sql*net'},
    {key:'sqlexec',value:'sqlexec'},
    {key:'sql-net',value:'sql-net'},
    {key:'sqlserv',value:'sqlserv'},
    {key:'srssend',value:'srssend'},
    {key:'ssm-cvs',value:'ssm-cvs'},
    {key:'ssm-els',value:'ssm-els'},
    {key:'ss-sign',value:'ss-sign'},
    {key:'starbot',value:'starbot'},
    {key:'stat-cc',value:'stat-cc'},
    {key:'statsrv',value:'statsrv'},
    {key:'statusd',value:'statusd'},
    {key:'stgxfws',value:'stgxfws'},
    {key:'storman',value:'storman'},
    {key:'stun-p1',value:'stun-p1'},
    {key:'stun-p2',value:'stun-p2'},
    {key:'stun-p3',value:'stun-p3'},
    {key:'sun-rpc',value:'sun-rpc'},
    {key:'surebox',value:'surebox'},
    {key:'svcloud',value:'svcloud'},
    {key:'swcards',value:'swcards'},
    {key:'swispol',value:'swispol'},
    {key:'symplex',value:'symplex'},
    {key:'synapse',value:'synapse'},
    {key:'synergy',value:'synergy'},
    {key:'tacnews',value:'tacnews'},
    {key:'tal-pod',value:'tal-pod'},
    {key:'tambora',value:'tambora'},
    {key:'tbricks',value:'tbricks'},
    {key:'tcp-aco',value:'tcp-aco'},
    {key:'teedtap',value:'teedtap'},
    {key:'telnets',value:'telnets'},
    {key:'tenfold',value:'tenfold'},
    {key:'tera-mp',value:'tera-mp'},
    {key:'test-ok',value:'test-ok'},
    {key:'tile-ml',value:'tile-ml'},
    {key:'timelot',value:'timelot'},
    {key:'tl1-raw',value:'tl1-raw'},
    {key:'tl1-ssh',value:'tl1-ssh'},
    {key:'tmosms0',value:'tmosms0'},
    {key:'tmosms1',value:'tmosms1'},
    {key:'tnos-dp',value:'tnos-dp'},
    {key:'tnos-sp',value:'tnos-sp'},
    {key:'tns-adv',value:'tns-adv'},
    {key:'tns-cml',value:'tns-cml'},
    {key:'todogwa',value:'todogwa'},
    {key:'topflow',value:'topflow'},
    {key:'tpcsrvr',value:'tpcsrvr'},
    {key:'trdp-md',value:'trdp-md'},
    {key:'trdp-pd',value:'trdp-pd'},
    {key:'trisoap',value:'trisoap'},
    {key:'trunk-1',value:'trunk-1'},
    {key:'trunk-2',value:'trunk-2'},
    {key:'tscchat',value:'tscchat'},
    {key:'tsepisp',value:'tsepisp'},
    {key:'tserver',value:'tserver'},
    {key:'tsrmagt',value:'tsrmagt'},
    {key:'tsspmap',value:'tsspmap'},
    {key:'ttat3lb',value:'ttat3lb'},
    {key:'ttc-ssl',value:'ttc-ssl'},
    {key:'ttyinfo',value:'ttyinfo'},
    {key:'tunatic',value:'tunatic'},
    {key:'ubroker',value:'ubroker'},
    {key:'ucs-isc',value:'ucs-isc'},
    {key:'ultimad',value:'ultimad'},
    {key:'undo-lm',value:'undo-lm'},
    {key:'unicall',value:'unicall'},
    {key:'uniport',value:'uniport'},
    {key:'upgrade',value:'upgrade'},
    {key:'uuidgen',value:'uuidgen'},
    {key:'vcs-app',value:'vcs-app'},
    {key:'vdmmesh',value:'vdmmesh'},
    {key:'vdmplay',value:'vdmplay'},
    {key:'ventoso',value:'ventoso'},
    {key:'via-ftp',value:'via-ftp'},
    {key:'vieo-fe',value:'vieo-fe'},
    {key:'vlsi-lm',value:'vlsi-lm'},
    {key:'vmpwscs',value:'vmpwscs'},
    {key:'vmsvc-2',value:'vmsvc-2'},
    {key:'voalte2',value:'voalte2'},
    {key:'voalte3',value:'voalte3'},
    {key:'vpm-udp',value:'vpm-udp'},
    {key:'vrmg-ip',value:'vrmg-ip'},
    {key:'vspread',value:'vspread'},
    {key:'vts-rpc',value:'vts-rpc'},
    {key:'vulture',value:'vulture'},
    {key:'wap-wsp',value:'wap-wsp'},
    {key:'weandsf',value:'weandsf'},
    {key:'webdata',value:'webdata'},
    {key:'webdavs',value:'webdavs'},
    {key:'webpush',value:'webpush'},
    {key:'webster',value:'webster'},
    {key:'webyast',value:'webyast'},
    {key:'we-jell',value:'we-jell'},
    {key:'whisker',value:'whisker'},
    {key:'whois++',value:'whois++'},
    {key:'whoispp',value:'whoispp'},
    {key:'wibukey',value:'wibukey'},
    {key:'wiegand',value:'wiegand'},
    {key:'winddlb',value:'winddlb'},
    {key:'winport',value:'winport'},
    {key:'wnn6-ds',value:'wnn6-ds'},
    {key:'wrspice',value:'wrspice'},
    {key:'wsicopy',value:'wsicopy'},
    {key:'wtc-rex',value:'wtc-rex'},
    {key:'wtc-wpr',value:'wtc-wpr'},
    {key:'wwdcpic',value:'wwdcpic'},
    {key:'www-dev',value:'www-dev'},
    {key:'wxbrief',value:'wxbrief'},
    {key:'x9-icue',value:'x9-icue'},
    {key:'xential',value:'xential'},
    {key:'xingcsm',value:'xingcsm'},
    {key:'xmquery',value:'xmquery'},
    {key:'xnm-ssl',value:'xnm-ssl'},
    {key:'xns-idp',value:'xns-idp'},
    {key:'xo-wave',value:'xo-wave'},
    {key:'xtlserv',value:'xtlserv'},
    {key:'xtreamx',value:'xtreamx'},
    {key:'yo-main',value:'yo-main'},
    {key:'zion-lm',value:'zion-lm'},
    {key:'zsecure',value:'zsecure'},
    {key:'1ci-smcs',value:'1ci-smcs'},
    {key:'3Com-nsd',value:'3Com-nsd'},
    {key:'5afe-dir',value:'5afe-dir'},
    {key:'abatemgr',value:'abatemgr'},
    {key:'abb-escp',value:'abb-escp'},
    {key:'acc-raid',value:'acc-raid'},
    {key:'acp-port',value:'acp-port'},
    {key:'acr-nema',value:'acr-nema'},
    {key:'adobe-vc',value:'adobe-vc'},
    {key:'a-d-sync',value:'a-d-sync'},
    {key:'aeroflex',value:'aeroflex'},
    {key:'aes-x170',value:'aes-x170'},
    {key:'afesc-mc',value:'afesc-mc'},
    {key:'afs3-bos',value:'afs3-bos'},
    {key:'agpolicy',value:'agpolicy'},
    {key:'aicc-cmi',value:'aicc-cmi'},
    {key:'aipn-reg',value:'aipn-reg'},
    {key:'aker-cdp',value:'aker-cdp'},
    {key:'allpeers',value:'allpeers'},
    {key:'aloe-gwp',value:'aloe-gwp'},
    {key:'altalink',value:'altalink'},
    {key:'amba-cam',value:'amba-cam'},
    {key:'ambit-lm',value:'ambit-lm'},
    {key:'amdsched',value:'amdsched'},
    {key:'ampl-lic',value:'ampl-lic'},
    {key:'amx-icsp',value:'amx-icsp'},
    {key:'animolmd',value:'animolmd'},
    {key:'ansys-lm',value:'ansys-lm'},
    {key:'ansyslmd',value:'ansyslmd'},
    {key:'apc-2160',value:'apc-2160'},
    {key:'apc-2161',value:'apc-2161'},
    {key:'apc-2260',value:'apc-2260'},
    {key:'apc-3052',value:'apc-3052'},
    {key:'apc-3506',value:'apc-3506'},
    {key:'apc-5454',value:'apc-5454'},
    {key:'apc-5455',value:'apc-5455'},
    {key:'apc-5456',value:'apc-5456'},
    {key:'apc-6547',value:'apc-6547'},
    {key:'apc-6548',value:'apc-6548'},
    {key:'apc-6549',value:'apc-6549'},
    {key:'apc-7845',value:'apc-7845'},
    {key:'apc-7846',value:'apc-7846'},
    {key:'apc-9950',value:'apc-9950'},
    {key:'apc-9951',value:'apc-9951'},
    {key:'apc-9952',value:'apc-9952'},
    {key:'apm-link',value:'apm-link'},
    {key:'appleqtc',value:'appleqtc'},
    {key:'appss-lm',value:'appss-lm'},
    {key:'arca-api',value:'arca-api'},
    {key:'arcisdms',value:'arcisdms'},
    {key:'ardusmul',value:'ardusmul'},
    {key:'ardusuni',value:'ardusuni'},
    {key:'argis-ds',value:'argis-ds'},
    {key:'argis-te',value:'argis-te'},
    {key:'asam-cmp',value:'asam-cmp'},
    {key:'asap-tcp',value:'asap-tcp'},
    {key:'asap-udp',value:'asap-udp'},
    {key:'asci-val',value:'asci-val'},
    {key:'asc-slmd',value:'asc-slmd'},
    {key:'as-debug',value:'as-debug'},
    {key:'asf-rmcp',value:'asf-rmcp'},
    {key:'asmp-mon',value:'asmp-mon'},
    {key:'aspeclmd',value:'aspeclmd'},
    {key:'atm-uhas',value:'atm-uhas'},
    {key:'authentx',value:'authentx'},
    {key:'axis-nvr',value:'axis-nvr'},
    {key:'azeti-bd',value:'azeti-bd'},
    {key:'badm_pub',value:'badm_pub'},
    {key:'badm-pub',value:'badm-pub'},
    {key:'bdir_pub',value:'bdir_pub'},
    {key:'bdir-pub',value:'bdir-pub'},
    {key:'bears-01',value:'bears-01'},
    {key:'bears-02',value:'bears-02'},
    {key:'beatpack',value:'beatpack'},
    {key:'bfd-echo',value:'bfd-echo'},
    {key:'bflckmgr',value:'bflckmgr'},
    {key:'bhoedap4',value:'bhoedap4'},
    {key:'bingbang',value:'bingbang'},
    {key:'bis-sync',value:'bis-sync'},
    {key:'bitspeer',value:'bitspeer'},
    {key:'blockade',value:'blockade'},
    {key:'bnetfile',value:'bnetfile'},
    {key:'bnetgame',value:'bnetgame'},
    {key:'bookworm',value:'bookworm'},
    {key:'bosswave',value:'bosswave'},
    {key:'bq-cromo',value:'bq-cromo'},
    {key:'business',value:'business'},
    {key:'bv-agent',value:'bv-agent'},
    {key:'bvtsonar',value:'bvtsonar'},
    {key:'cadlock2',value:'cadlock2'},
    {key:'cadsi-lm',value:'cadsi-lm'},
    {key:'cadsisvr',value:'cadsisvr'},
    {key:'caiccipc',value:'caiccipc'},
    {key:'calltrax',value:'calltrax'},
    {key:'carddavs',value:'carddavs'},
    {key:'cardsend',value:'cardsend'},
    {key:'carousel',value:'carousel'},
    {key:'cas-mapi',value:'cas-mapi'},
    {key:'catalyst',value:'catalyst'},
    {key:'cbserver',value:'cbserver'},
    {key:'ccag-pib',value:'ccag-pib'},
    {key:'ccm-port',value:'ccm-port'},
    {key:'ccss-qmm',value:'ccss-qmm'},
    {key:'ccss-qsm',value:'ccss-qsm'},
    {key:'cdbroker',value:'cdbroker'},
    {key:'cefd-vmp',value:'cefd-vmp'},
    {key:'celatalk',value:'celatalk'},
    {key:'cera-bcm',value:'cera-bcm'},
    {key:'cfengine',value:'cfengine'},
    {key:'cgn-stat',value:'cgn-stat'},
    {key:'checksum',value:'checksum'},
    {key:'circle-x',value:'circle-x'},
    {key:'cirrossp',value:'cirrossp'},
    {key:'clvm-cfg',value:'clvm-cfg'},
    {key:'cmc-port',value:'cmc-port'},
    {key:'cmip-man',value:'cmip-man'},
    {key:'cmtp-mgt',value:'cmtp-mgt'},
    {key:'coauthor',value:'coauthor'},
    {key:'cogitate',value:'cogitate'},
    {key:'collaber',value:'collaber'},
    {key:'collabio',value:'collabio'},
    {key:'colubris',value:'colubris'},
    {key:'commerce',value:'commerce'},
    {key:'commfort',value:'commfort'},
    {key:'concomp1',value:'concomp1'},
    {key:'conecube',value:'conecube'},
    {key:'connendp',value:'connendp'},
    {key:'connlcli',value:'connlcli'},
    {key:'converge',value:'converge'},
    {key:'cops-tls',value:'cops-tls'},
    {key:'corbaloc',value:'corbaloc'},
    {key:'cpq-wbem',value:'cpq-wbem'},
    {key:'crmsbits',value:'crmsbits'},
    {key:'csdmbase',value:'csdmbase'},
    {key:'csi-lfap',value:'csi-lfap'},
    {key:'csi-mmws',value:'csi-mmws'},
    {key:'csi-sgwp',value:'csi-sgwp'},
    {key:'csnet-ns',value:'csnet-ns'},
    {key:'csnotify',value:'csnotify'},
    {key:'cspmulti',value:'cspmulti'},
    {key:'cst-port',value:'cst-port'},
    {key:'ctxs-vpp',value:'ctxs-vpp'},
    {key:'cylink-c',value:'cylink-c'},
    {key:'cytel-lm',value:'cytel-lm'},
    {key:'danf-ak2',value:'danf-ak2'},
    {key:'datalens',value:'datalens'},
    {key:'datusorb',value:'datusorb'},
    {key:'dbbrowse',value:'dbbrowse'},
    {key:'dccp-udp',value:'dccp-udp'},
    {key:'dcn-meas',value:'dcn-meas'},
    {key:'dei-icda',value:'dei-icda'},
    {key:'descent3',value:'descent3'},
    {key:'deskview',value:'deskview'},
    {key:'devbasic',value:'devbasic'},
    {key:'dey-sapi',value:'dey-sapi'},
    {key:'dfserver',value:'dfserver'},
    {key:'dgi-serv',value:'dgi-serv'},
    {key:'dhanda-g',value:'dhanda-g'},
    {key:'diagmond',value:'diagmond'},
    {key:'diameter',value:'diameter'},
    {key:'dic-aida',value:'dic-aida'},
    {key:'dif-port',value:'dif-port'},
    {key:'digivote',value:'digivote'},
    {key:'disclose',value:'disclose'},
    {key:'distinct',value:'distinct'},
    {key:'dl_agent',value:'dl_agent'},
    {key:'dl-agent',value:'dl-agent'},
    {key:'dmf-mail',value:'dmf-mail'},
    {key:'dnc-port',value:'dnc-port'},
    {key:'docker-s',value:'docker-s'},
    {key:'domain-s',value:'domain-s'},
    {key:'dropcopy',value:'dropcopy'},
    {key:'ds-admin',value:'ds-admin'},
    {key:'dsl-sync',value:'dsl-sync'},
    {key:'duckrace',value:'duckrace'},
    {key:'dvt-data',value:'dvt-data'},
    {key:'dxadmind',value:'dxadmind'},
    {key:'dxspider',value:'dxspider'},
    {key:'dxtgsync',value:'dxtgsync'},
    {key:'dyn-site',value:'dyn-site'},
    {key:'dzdaemon',value:'dzdaemon'},
    {key:'eb-amuzi',value:'eb-amuzi'},
    {key:'ebinsite',value:'ebinsite'},
    {key:'eforward',value:'eforward'},
    {key:'ehf-mail',value:'ehf-mail'},
    {key:'ehome-ms',value:'ehome-ms'},
    {key:'embl-ndt',value:'embl-ndt'},
    {key:'emcrmird',value:'emcrmird'},
    {key:'emperion',value:'emperion'},
    {key:'enl-name',value:'enl-name'},
    {key:'eor-game',value:'eor-game'},
    {key:'esbroker',value:'esbroker'},
    {key:'escvpnet',value:'escvpnet'},
    {key:'esimport',value:'esimport'},
    {key:'esmagent',value:'esmagent'},
    {key:'esri_sde',value:'esri_sde'},
    {key:'esri-sde',value:'esri-sde'},
    {key:'esro-gen',value:'esro-gen'},
    {key:'ethercat',value:'ethercat'},
    {key:'ethoscan',value:'ethoscan'},
    {key:'excerpts',value:'excerpts'},
    {key:'extensis',value:'extensis'},
    {key:'facelink',value:'facelink'},
    {key:'facespan',value:'facespan'},
    {key:'fagordnc',value:'fagordnc'},
    {key:'fairview',value:'fairview'},
    {key:'fastlynx',value:'fastlynx'},
    {key:'fdtracks',value:'fdtracks'},
    {key:'ffserver',value:'ffserver'},
    {key:'filecast',value:'filecast'},
    {key:'finle-lm',value:'finle-lm'},
    {key:'firetask',value:'firetask'},
    {key:'fisa-svc',value:'fisa-svc'},
    {key:'fjdmimgr',value:'fjdmimgr'},
    {key:'fj-hdnet',value:'fj-hdnet'},
    {key:'fjinvmgr',value:'fjinvmgr'},
    {key:'fjsvmpor',value:'fjsvmpor'},
    {key:'flashmsg',value:'flashmsg'},
    {key:'florence',value:'florence'},
    {key:'fmpro-v6',value:'fmpro-v6'},
    {key:'fmsascon',value:'fmsascon'},
    {key:'fotogcad',value:'fotogcad'},
    {key:'frc-hicp',value:'frc-hicp'},
    {key:'freehand',value:'freehand'},
    {key:'fryeserv',value:'fryeserv'},
    {key:'fs-agent',value:'fs-agent'},
    {key:'fsc-port',value:'fsc-port'},
    {key:'ftpcroco',value:'ftpcroco'},
    {key:'ftp-data',value:'ftp-data'},
    {key:'fuscript',value:'fuscript'},
    {key:'gadugadu',value:'gadugadu'},
    {key:'galaxy4d',value:'galaxy4d'},
    {key:'gamegen1',value:'gamegen1'},
    {key:'gcp-rphy',value:'gcp-rphy'},
    {key:'gdp-port',value:'gdp-port'},
    {key:'genie-lm',value:'genie-lm'},
    {key:'geniuslm',value:'geniuslm'},
    {key:'giop-ssl',value:'giop-ssl'},
    {key:'glasspad',value:'glasspad'},
    {key:'go-login',value:'go-login'},
    {key:'goorstop',value:'goorstop'},
    {key:'gprs-sig',value:'gprs-sig'},
    {key:'gradecam',value:'gradecam'},
    {key:'graphics',value:'graphics'},
    {key:'grf-port',value:'grf-port'},
    {key:'grid-alt',value:'grid-alt'},
    {key:'gss-http',value:'gss-http'},
    {key:'gte-samp',value:'gte-samp'},
    {key:'gt-proxy',value:'gt-proxy'},
    {key:'gtp-user',value:'gtp-user'},
    {key:'hacl-cfg',value:'hacl-cfg'},
    {key:'hes-clip',value:'hes-clip'},
    {key:'hivestor',value:'hivestor'},
    {key:'hlserver',value:'hlserver'},
    {key:'hmmp-ind',value:'hmmp-ind'},
    {key:'homeauto',value:'homeauto'},
    {key:'host2-ns',value:'host2-ns'},
    {key:'hostname',value:'hostname'},
    {key:'hpppssvr',value:'hpppssvr'},
    {key:'hp-pxpib',value:'hp-pxpib'},
    {key:'hpssmgmt',value:'hpssmgmt'},
    {key:'hpstgmgr',value:'hpstgmgr'},
    {key:'hri-port',value:'hri-port'},
    {key:'http-alt',value:'http-alt'},
    {key:'htuilsrv',value:'htuilsrv'},
    {key:'iadt-tls',value:'iadt-tls'},
    {key:'iafdbase',value:'iafdbase'},
    {key:'ias-auth',value:'ias-auth'},
    {key:'ibm-cics',value:'ibm-cics'},
    {key:'ibm-dt-2',value:'ibm-dt-2'},
    {key:'iclpv-dm',value:'iclpv-dm'},
    {key:'iclpv-pm',value:'iclpv-pm'},
    {key:'iclpv-sc',value:'iclpv-sc'},
    {key:'iconquer',value:'iconquer'},
    {key:'identify',value:'identify'},
    {key:'idig_mux',value:'idig_mux'},
    {key:'idig-mux',value:'idig-mux'},
    {key:'idmgratm',value:'idmgratm'},
    {key:'idotdist',value:'idotdist'},
    {key:'ieee-mih',value:'ieee-mih'},
    {key:'ieee-mms',value:'ieee-mms'},
    {key:'ii-admin',value:'ii-admin'},
    {key:'iiw-port',value:'iiw-port'},
    {key:'imdocsvc',value:'imdocsvc'},
    {key:'imqstomp',value:'imqstomp'},
    {key:'imtc-map',value:'imtc-map'},
    {key:'imtc-mcs',value:'imtc-mcs'},
    {key:'indx-dds',value:'indx-dds'},
    {key:'infboard',value:'infboard'},
    {key:'infoexch',value:'infoexch'},
    {key:'informer',value:'informer'},
    {key:'infoseek',value:'infoseek'},
    {key:'infowave',value:'infowave'},
    {key:'inspider',value:'inspider'},
    {key:'integral',value:'integral'},
    {key:'interact',value:'interact'},
    {key:'intersan',value:'intersan'},
    {key:'intrinsa',value:'intrinsa'},
    {key:'invision',value:'invision'},
    {key:'ip-cmprs',value:'ip-cmprs'},
    {key:'ip-dvmrp',value:'ip-dvmrp'},
    {key:'ipr-dglt',value:'ipr-dglt'},
    {key:'iqobject',value:'iqobject'},
    {key:'iqserver',value:'iqserver'},
    {key:'irc-serv',value:'irc-serv'},
    {key:'iris-lwz',value:'iris-lwz'},
    {key:'iris-xpc',value:'iris-xpc'},
    {key:'iroid-sd',value:'iroid-sd'},
    {key:'ironmail',value:'ironmail'},
    {key:'iso-tp0s',value:'iso-tp0s'},
    {key:'iso-tsap',value:'iso-tsap'},
    {key:'isysg-lm',value:'isysg-lm'},
    {key:'itm-mccs',value:'itm-mccs'},
    {key:'iwserver',value:'iwserver'},
    {key:'jaleosnd',value:'jaleosnd'},
    {key:'jdp-disc',value:'jdp-disc'},
    {key:'jlicelmd',value:'jlicelmd'},
    {key:'jmb-cds1',value:'jmb-cds1'},
    {key:'jmb-cds2',value:'jmb-cds2'},
    {key:'jpegmpeg',value:'jpegmpeg'},
    {key:'jprinter',value:'jprinter'},
    {key:'jvclient',value:'jvclient'},
    {key:'jvserver',value:'jvserver'},
    {key:'jwclient',value:'jwclient'},
    {key:'jwpc-bin',value:'jwpc-bin'},
    {key:'jwserver',value:'jwserver'},
    {key:'kerberos',value:'kerberos'},
    {key:'kfserver',value:'kfserver'},
    {key:'knet-cmp',value:'knet-cmp'},
    {key:'kv-agent',value:'kv-agent'},
    {key:'l2c-data',value:'l2c-data'},
    {key:'l2c-disc',value:'l2c-disc'},
    {key:'l3-exprt',value:'l3-exprt'},
    {key:'l3-hbmon',value:'l3-hbmon'},
    {key:'la-maint',value:'la-maint'},
    {key:'lbc-sync',value:'lbc-sync'},
    {key:'lds-dump',value:'lds-dump'},
    {key:'legent-1',value:'legent-1'},
    {key:'legent-2',value:'legent-2'},
    {key:'linkname',value:'linkname'},
    {key:'linktest',value:'linktest'},
    {key:'lionhead',value:'lionhead'},
    {key:'lipsinc1',value:'lipsinc1'},
    {key:'llm-pass',value:'llm-pass'},
    {key:'lnvalarm',value:'lnvalarm'},
    {key:'loaprobe',value:'loaprobe'},
    {key:'lockstep',value:'lockstep'},
    {key:'logcabin',value:'logcabin'},
    {key:'lonworks',value:'lonworks'},
    {key:'lpar2rrd',value:'lpar2rrd'},
    {key:'ls3bcast',value:'ls3bcast'},
    {key:'lsp-ping',value:'lsp-ping'},
    {key:'lumimgrd',value:'lumimgrd'},
    {key:'mailprox',value:'mailprox'},
    {key:'matahari',value:'matahari'},
    {key:'mbg-ctrl',value:'mbg-ctrl'},
    {key:'mbserver',value:'mbserver'},
    {key:'mcns-sec',value:'mcns-sec'},
    {key:'mconnect',value:'mconnect'},
    {key:'mcp-port',value:'mcp-port'},
    {key:'mcreport',value:'mcreport'},
    {key:'mediabox',value:'mediabox'},
    {key:'mediatap',value:'mediatap'},
    {key:'med-supp',value:'med-supp'},
    {key:'memcache',value:'memcache'},
    {key:'metagram',value:'metagram'},
    {key:'metasage',value:'metasage'},
    {key:'mfserver',value:'mfserver'},
    {key:'microsan',value:'microsan'},
    {key:'mieleacs',value:'mieleacs'},
    {key:'minilock',value:'minilock'},
    {key:'mini-sql',value:'mini-sql'},
    {key:'minivend',value:'minivend'},
    {key:'mipv6tls',value:'mipv6tls'},
    {key:'miva-mqs',value:'miva-mqs'},
    {key:'ml-svnet',value:'ml-svnet'},
    {key:'mngsuite',value:'mngsuite'},
    {key:'mns-mail',value:'mns-mail'},
    {key:'moodring',value:'moodring'},
    {key:'mosaixcc',value:'mosaixcc'},
    {key:'MOS-soap',value:'MOS-soap'},
    {key:'moy-corp',value:'moy-corp'},
    {key:'mp3sushi',value:'mp3sushi'},
    {key:'mphlpdmc',value:'mphlpdmc'},
    {key:'mpidcagt',value:'mpidcagt'},
    {key:'mpidcmgr',value:'mpidcmgr'},
    {key:'mpls-udp',value:'mpls-udp'},
    {key:'mpnjsocl',value:'mpnjsocl'},
    {key:'mpnjsomb',value:'mpnjsomb'},
    {key:'mpnjsomg',value:'mpnjsomg'},
    {key:'mpnjsosv',value:'mpnjsosv'},
    {key:'mps-raft',value:'mps-raft'},
    {key:'msg-auth',value:'msg-auth'},
    {key:'ms-olap1',value:'ms-olap1'},
    {key:'ms-olap2',value:'ms-olap2'},
    {key:'ms-olap3',value:'ms-olap3'},
    {key:'ms-olap4',value:'ms-olap4'},
    {key:'ms-sql-m',value:'ms-sql-m'},
    {key:'ms-sql-s',value:'ms-sql-s'},
    {key:'must-p2p',value:'must-p2p'},
    {key:'mxim-ice',value:'mxim-ice'},
    {key:'mysql-im',value:'mysql-im'},
    {key:'n1-rmgmt',value:'n1-rmgmt'},
    {key:'nacagent',value:'nacagent'},
    {key:'navbuddy',value:'navbuddy'},
    {key:'nav-data',value:'nav-data'},
    {key:'nav-port',value:'nav-port'},
    {key:'nburn_id',value:'nburn_id'},
    {key:'nburn-id',value:'nburn-id'},
    {key:'ncconfig',value:'ncconfig'},
    {key:'ncdirect',value:'ncdirect'},
    {key:'ncpm-hip',value:'ncpm-hip'},
    {key:'ncube-lm',value:'ncube-lm'},
    {key:'neoiface',value:'neoiface'},
    {key:'neon24x7',value:'neon24x7'},
    {key:'netadmin',value:'netadmin'},
    {key:'netagent',value:'netagent'},
    {key:'netangel',value:'netangel'},
    {key:'netcomm1',value:'netcomm1'},
    {key:'netcomm2',value:'netcomm2'},
    {key:'netiq-mc',value:'netiq-mc'},
    {key:'netmagic',value:'netmagic'},
    {key:'netmount',value:'netmount'},
    {key:'neto-dcs',value:'neto-dcs'},
    {key:'netop-rc',value:'netop-rc'},
    {key:'netready',value:'netready'},
    {key:'netrjs-1',value:'netrjs-1'},
    {key:'netrjs-2',value:'netrjs-2'},
    {key:'netrjs-3',value:'netrjs-3'},
    {key:'netrjs-4',value:'netrjs-4'},
    {key:'new-rwho',value:'new-rwho'},
    {key:'nextstep',value:'nextstep'},
    {key:'nfoldman',value:'nfoldman'},
    {key:'nhserver',value:'nhserver'},
    {key:'nicelink',value:'nicelink'},
    {key:'nimbusdb',value:'nimbusdb'},
    {key:'nitrogen',value:'nitrogen'},
    {key:'nlg-data',value:'nlg-data'},
    {key:'nmc-disc',value:'nmc-disc'},
    {key:'noaaport',value:'noaaport'},
    {key:'notateit',value:'notateit'},
    {key:'novation',value:'novation'},
    {key:'npmp-gui',value:'npmp-gui'},
    {key:'nsc-posa',value:'nsc-posa'},
    {key:'ntlx-ent',value:'ntlx-ent'},
    {key:'nuts_dem',value:'nuts_dem'},
    {key:'nuts-dem',value:'nuts-dem'},
    {key:'nv-video',value:'nv-video'},
    {key:'ocbinder',value:'ocbinder'},
    {key:'ocserver',value:'ocserver'},
    {key:'odi-port',value:'odi-port'},
    {key:'oidocsvc',value:'oidocsvc'},
    {key:'oirtgsvc',value:'oirtgsvc'},
    {key:'onep-tls',value:'onep-tls'},
    {key:'onscreen',value:'onscreen'},
    {key:'openbase',value:'openbase'},
    {key:'opencore',value:'opencore'},
    {key:'openflow',value:'openflow'},
    {key:'openhpid',value:'openhpid'},
    {key:'openmail',value:'openmail'},
    {key:'openmath',value:'openmath'},
    {key:'openpath',value:'openpath'},
    {key:'openport',value:'openport'},
    {key:'opentrac',value:'opentrac'},
    {key:'opi-sock',value:'opi-sock'},
    {key:'op-probe',value:'op-probe'},
    {key:'oprofile',value:'oprofile'},
    {key:'oveadmgr',value:'oveadmgr'},
    {key:'ovladmgr',value:'ovladmgr'},
    {key:'owserver',value:'owserver'},
    {key:'p2pgroup',value:'p2pgroup'},
    {key:'padl2sim',value:'padl2sim'},
    {key:'pafec-lm',value:'pafec-lm'},
    {key:'palace-1',value:'palace-1'},
    {key:'palace-2',value:'palace-2'},
    {key:'palace-3',value:'palace-3'},
    {key:'palace-4',value:'palace-4'},
    {key:'palace-5',value:'palace-5'},
    {key:'palace-6',value:'palace-6'},
    {key:'pammratc',value:'pammratc'},
    {key:'paragent',value:'paragent'},
    {key:'parallel',value:'parallel'},
    {key:'parliant',value:'parliant'},
    {key:'pciarray',value:'pciarray'},
    {key:'pctrader',value:'pctrader'},
    {key:'pda-data',value:'pda-data'},
    {key:'pd-admin',value:'pd-admin'},
    {key:'pda-gate',value:'pda-gate'},
    {key:'peerwire',value:'peerwire'},
    {key:'pegboard',value:'pegboard'},
    {key:'perimlan',value:'perimlan'},
    {key:'photuris',value:'photuris'},
    {key:'pim-port',value:'pim-port'},
    {key:'piranha1',value:'piranha1'},
    {key:'piranha2',value:'piranha2'},
    {key:'pkix-cmc',value:'pkix-cmc'},
    {key:'plato-lm',value:'plato-lm'},
    {key:'plethora',value:'plethora'},
    {key:'plgproxy',value:'plgproxy'},
    {key:'pluribus',value:'pluribus'},
    {key:'pmwebapi',value:'pmwebapi'},
    {key:'pnbscada',value:'pnbscada'},
    {key:'pnet-enc',value:'pnet-enc'},
    {key:'polestar',value:'polestar'},
    {key:'pon-ictp',value:'pon-ictp'},
    {key:'pqsflows',value:'pqsflows'},
    {key:'pra_elmd',value:'pra_elmd'},
    {key:'pra-elmd',value:'pra-elmd'},
    {key:'presence',value:'presence'},
    {key:'prex-tcp',value:'prex-tcp'},
    {key:'proaxess',value:'proaxess'},
    {key:'prospero',value:'prospero'},
    {key:'protonet',value:'protonet'},
    {key:'pscl-mgt',value:'pscl-mgt'},
    {key:'pslicser',value:'pslicser'},
    {key:'ptp-init',value:'ptp-init'},
    {key:'pvaccess',value:'pvaccess'},
    {key:'pxc-ntfy',value:'pxc-ntfy'},
    {key:'pxc-roid',value:'pxc-roid'},
    {key:'pxc-splr',value:'pxc-splr'},
    {key:'pxc-spvr',value:'pxc-spvr'},
    {key:'qip-msgd',value:'qip-msgd'},
    {key:'qnts-orb',value:'qnts-orb'},
    {key:'quailnet',value:'quailnet'},
    {key:'queueadm',value:'queueadm'},
    {key:'radio-bc',value:'radio-bc'},
    {key:'radioepg',value:'radioepg'},
    {key:'radio-sm',value:'radio-sm'},
    {key:'radiotag',value:'radiotag'},
    {key:'radiovis',value:'radiovis'},
    {key:'raventbs',value:'raventbs'},
    {key:'raventdm',value:'raventdm'},
    {key:'rbakcup1',value:'rbakcup1'},
    {key:'rbakcup2',value:'rbakcup2'},
    {key:'rbsystem',value:'rbsystem'},
    {key:'rcc-host',value:'rcc-host'},
    {key:'rcip-itu',value:'rcip-itu'},
    {key:'RDCenter',value:'RDCenter'},
    {key:'reachout',value:'reachout'},
    {key:'recvr-rc',value:'recvr-rc'},
    {key:'rellpack',value:'rellpack'},
    {key:'remotefs',value:'remotefs'},
    {key:'repliweb',value:'repliweb'},
    {key:'rets-ssl',value:'rets-ssl'},
    {key:'rfid-rp1',value:'rfid-rp1'},
    {key:'rhp-iibp',value:'rhp-iibp'},
    {key:'rkb-oscs',value:'rkb-oscs'},
    {key:'rlm-disc',value:'rlm-disc'},
    {key:'rlzdbase',value:'rlzdbase'},
    {key:'rmonitor',value:'rmonitor'},
    {key:'roku-rcp',value:'roku-rcp'},
    {key:'roverlog',value:'roverlog'},
    {key:'rpki-rtr',value:'rpki-rtr'},
    {key:'rt-event',value:'rt-event'},
    {key:'rt-sound',value:'rt-sound'},
    {key:'rtsp-alt',value:'rtsp-alt'},
    {key:'rubygems',value:'rubygems'},
    {key:'rxxmiele',value:'rxxmiele'},
    {key:'sagxtsds',value:'sagxtsds'},
    {key:'santools',value:'santools'},
    {key:'saratoga',value:'saratoga'},
    {key:'sasggprs',value:'sasggprs'},
    {key:'sceanics',value:'sceanics'},
    {key:'scoremgr',value:'scoremgr'},
    {key:'scpi-raw',value:'scpi-raw'},
    {key:'scrabble',value:'scrabble'},
    {key:'sdbproxy',value:'sdbproxy'},
    {key:'sdclient',value:'sdclient'},
    {key:'sdserver',value:'sdserver'},
    {key:'sdtvwcam',value:'sdtvwcam'},
    {key:'semantix',value:'semantix'},
    {key:'sentinel',value:'sentinel'},
    {key:'sepvsync',value:'sepvsync'},
    {key:'servexec',value:'servexec'},
    {key:'services',value:'services'},
    {key:'servserv',value:'servserv'},
    {key:'servstat',value:'servstat'},
    {key:'sftp-ssh',value:'sftp-ssh'},
    {key:'sge-exec',value:'sge-exec'},
    {key:'sgi-soap',value:'sgi-soap'},
    {key:'shaperai',value:'shaperai'},
    {key:'shareapp',value:'shareapp'},
    {key:'sift-uft',value:'sift-uft'},
    {key:'simba-cs',value:'simba-cs'},
    {key:'simp-all',value:'simp-all'},
    {key:'simslink',value:'simslink'},
    {key:'slimhttp',value:'slimhttp'},
    {key:'smaclmgr',value:'smaclmgr'},
    {key:'smakynet',value:'smakynet'},
    {key:'smart-lm',value:'smart-lm'},
    {key:'smartsdp',value:'smartsdp'},
    {key:'smc-http',value:'smc-http'},
    {key:'sm-pas-1',value:'sm-pas-1'},
    {key:'sm-pas-2',value:'sm-pas-2'},
    {key:'sm-pas-3',value:'sm-pas-3'},
    {key:'sm-pas-4',value:'sm-pas-4'},
    {key:'sm-pas-5',value:'sm-pas-5'},
    {key:'sms-chat',value:'sms-chat'},
    {key:'sms-xfer',value:'sms-xfer'},
    {key:'snif-cln',value:'snif-cln'},
    {key:'snif-srv',value:'snif-srv'},
    {key:'snmpdtls',value:'snmpdtls'},
    {key:'snmptrap',value:'snmptrap'},
    {key:'souschef',value:'souschef'},
    {key:'spamtrap',value:'spamtrap'},
    {key:'spearcat',value:'spearcat'},
    {key:'spearway',value:'spearway'},
    {key:'sphinxql',value:'sphinxql'},
    {key:'spramsca',value:'spramsca'},
    {key:'ssh-mgmt',value:'ssh-mgmt'},
    {key:'ssowatch',value:'ssowatch'},
    {key:'starfish',value:'starfish'},
    {key:'startron',value:'startron'},
    {key:'ste-smsc',value:'ste-smsc'},
    {key:'storview',value:'storview'},
    {key:'streletz',value:'streletz'},
    {key:'sttunnel',value:'sttunnel'},
    {key:'suitcase',value:'suitcase'},
    {key:'supermon',value:'supermon'},
    {key:'sur-meas',value:'sur-meas'},
    {key:'svbackup',value:'svbackup'},
    {key:'swdtp-sv',value:'swdtp-sv'},
    {key:'swiftnet',value:'swiftnet'},
    {key:'swismgr1',value:'swismgr1'},
    {key:'swismgr2',value:'swismgr2'},
    {key:'swistrap',value:'swistrap'},
    {key:'switcher',value:'switcher'},
    {key:'sw-orion',value:'sw-orion'},
    {key:'swr-port',value:'swr-port'},
    {key:'swxadmin',value:'swxadmin'},
    {key:'swx-gate',value:'swx-gate'},
    {key:'syam-smc',value:'syam-smc'},
    {key:'sychrond',value:'sychrond'},
    {key:'sync-em7',value:'sync-em7'},
    {key:'syncqdea',value:'syncqdea'},
    {key:'synctest',value:'synctest'},
    {key:'taiclock',value:'taiclock'},
    {key:'tapestry',value:'tapestry'},
    {key:'tapeware',value:'tapeware'},
    {key:'taserver',value:'taserver'},
    {key:'tasp-net',value:'tasp-net'},
    {key:'tcc-http',value:'tcc-http'},
    {key:'tdaccess',value:'tdaccess'},
    {key:'teamlist',value:'teamlist'},
    {key:'teleport',value:'teleport'},
    {key:'telindus',value:'telindus'},
    {key:'tenir-rc',value:'tenir-rc'},
    {key:'tentacle',value:'tentacle'},
    {key:'terabase',value:'terabase'},
    {key:'tetrinet',value:'tetrinet'},
    {key:'theta-lm',value:'theta-lm'},
    {key:'thingkit',value:'thingkit'},
    {key:'thinwire',value:'thinwire'},
    {key:'timbuktu',value:'timbuktu'},
    {key:'timezone',value:'timezone'},
    {key:'tivo-hme',value:'tivo-hme'},
    {key:'tksocket',value:'tksocket'},
    {key:'tnos-dps',value:'tnos-dps'},
    {key:'tn-tl-r1',value:'tn-tl-r1'},
    {key:'tn-tl-r2',value:'tn-tl-r2'},
    {key:'tn-tl-w1',value:'tn-tl-w1'},
    {key:'tn-tl-w2',value:'tn-tl-w2'},
    {key:'tolteces',value:'tolteces'},
    {key:'tonidods',value:'tonidods'},
    {key:'transact',value:'transact'},
    {key:'trim-ice',value:'trim-ice'},
    {key:'tripwire',value:'tripwire'},
    {key:'trivnet1',value:'trivnet1'},
    {key:'trivnet2',value:'trivnet2'},
    {key:'trueconf',value:'trueconf'},
    {key:'tsdos390',value:'tsdos390'},
    {key:'ttc-etap',value:'ttc-etap'},
    {key:'ucontrol',value:'ucontrol'},
    {key:'uddi-inq',value:'uddi-inq'},
    {key:'uddi-pub',value:'uddi-pub'},
    {key:'uddi-sec',value:'uddi-sec'},
    {key:'uddi-sub',value:'uddi-sub'},
    {key:'udpradio',value:'udpradio'},
    {key:'ultrabac',value:'ultrabac'},
    {key:'umm-port',value:'umm-port'},
    {key:'urbisnet',value:'urbisnet'},
    {key:'v2g-secc',value:'v2g-secc'},
    {key:'vedabase',value:'vedabase'},
    {key:'venus-se',value:'venus-se'},
    {key:'veracity',value:'veracity'},
    {key:'veronica',value:'veronica'},
    {key:'versiera',value:'versiera'},
    {key:'vfmobile',value:'vfmobile'},
    {key:'videotex',value:'videotex'},
    {key:'volterio',value:'volterio'},
    {key:'vpa-disc',value:'vpa-disc'},
    {key:'vpps-qua',value:'vpps-qua'},
    {key:'vpps-via',value:'vpps-via'},
    {key:'vrmg-p2p',value:'vrmg-p2p'},
    {key:'vrts-tdd',value:'vrts-tdd'},
    {key:'vsaiport',value:'vsaiport'},
    {key:'vsiadmin',value:'vsiadmin'},
    {key:'vvr-data',value:'vvr-data'},
    {key:'wap-push',value:'wap-push'},
    {key:'wap-vcal',value:'wap-vcal'},
    {key:'warppipe',value:'warppipe'},
    {key:'watchdoc',value:'watchdoc'},
    {key:'watilapp',value:'watilapp'},
    {key:'wbem-rmi',value:'wbem-rmi'},
    {key:'wb-expak',value:'wb-expak'},
    {key:'wcbackup',value:'wcbackup'},
    {key:'web2host',value:'web2host'},
    {key:'weblogin',value:'weblogin'},
    {key:'webphone',value:'webphone'},
    {key:'wherehoo',value:'wherehoo'},
    {key:'whistler',value:'whistler'},
    {key:'whosells',value:'whosells'},
    {key:'windream',value:'windream'},
    {key:'winqedit',value:'winqedit'},
    {key:'wip-port',value:'wip-port'},
    {key:'wkgrpsvr',value:'wkgrpsvr'},
    {key:'wlanauth',value:'wlanauth'},
    {key:'workflow',value:'workflow'},
    {key:'world-lm',value:'world-lm'},
    {key:'wormhole',value:'wormhole'},
    {key:'wpl-disc',value:'wpl-disc'},
    {key:'writesrv',value:'writesrv'},
    {key:'wsdapi-s',value:'wsdapi-s'},
    {key:'wtc-qels',value:'wtc-qels'},
    {key:'wwiotalk',value:'wwiotalk'},
    {key:'www-http',value:'www-http'},
    {key:'x2e-disc',value:'x2e-disc'},
    {key:'xcompute',value:'xcompute'},
    {key:'xingmpeg',value:'xingmpeg'},
    {key:'xns-auth',value:'xns-auth'},
    {key:'xns-mail',value:'xns-mail'},
    {key:'xns-time',value:'xns-time'},
    {key:'x-plane9',value:'x-plane9'},
    {key:'xss-port',value:'xss-port'},
    {key:'xtshapro',value:'xtshapro'},
    {key:'xul-http',value:'xul-http'},
    {key:'zen-pawn',value:'zen-pawn'},
    {key:'zre-disc',value:'zre-disc'},
    {key:'z-wave-s',value:'z-wave-s'},
    {key:'1password',value:'1password'},
    {key:'3com-amp3',value:'3com-amp3'},
    {key:'3gpp-cbsp',value:'3gpp-cbsp'},
    {key:'3gpp-monp',value:'3gpp-monp'},
    {key:'3gpp-w1ap',value:'3gpp-w1ap'},
    {key:'3par-evts',value:'3par-evts'},
    {key:'3par-mgmt',value:'3par-mgmt'},
    {key:'5afe-disc',value:'5afe-disc'},
    {key:'a16-an-an',value:'a16-an-an'},
    {key:'a17-an-an',value:'a17-an-an'},
    {key:'aairnet-1',value:'aairnet-1'},
    {key:'aairnet-2',value:'aairnet-2'},
    {key:'accelenet',value:'accelenet'},
    {key:'accessone',value:'accessone'},
    {key:'accu-lmgr',value:'accu-lmgr'},
    {key:'accuracer',value:'accuracer'},
    {key:'ace-proxy',value:'ace-proxy'},
    {key:'acp-proto',value:'acp-proto'},
    {key:'adapt-sna',value:'adapt-sna'},
    {key:'advant-lm',value:'advant-lm'},
    {key:'affiliate',value:'affiliate'},
    {key:'agentview',value:'agentview'},
    {key:'agps-port',value:'agps-port'},
    {key:'aipn-auth',value:'aipn-auth'},
    {key:'alesquery',value:'alesquery'},
    {key:'alpha-sms',value:'alpha-sms'},
    {key:'altova-lm',value:'altova-lm'},
    {key:'ampr-info',value:'ampr-info'},
    {key:'ampr-rcmd',value:'ampr-rcmd'},
    {key:'anyremote',value:'anyremote'},
    {key:'apc-necmp',value:'apc-necmp'},
    {key:'apex-edge',value:'apex-edge'},
    {key:'apex-mesh',value:'apex-mesh'},
    {key:'apollo-cc',value:'apollo-cc'},
    {key:'applerdbg',value:'applerdbg'},
    {key:'aprigo-cs',value:'aprigo-cs'},
    {key:'apwi-disc',value:'apwi-disc'},
    {key:'arepa-cas',value:'arepa-cas'},
    {key:'ars-vista',value:'ars-vista'},
    {key:'asap-sctp',value:'asap-sctp'},
    {key:'asoki-sma',value:'asoki-sma'},
    {key:'assyst-dr',value:'assyst-dr'},
    {key:'astergate',value:'astergate'},
    {key:'astnotify',value:'astnotify'},
    {key:'astralite',value:'astralite'},
    {key:'astrolink',value:'astrolink'},
    {key:'atex_elmd',value:'atex_elmd'},
    {key:'atex-elmd',value:'atex-elmd'},
    {key:'attero-ad',value:'attero-ad'},
    {key:'autobuild',value:'autobuild'},
    {key:'autocueds',value:'autocueds'},
    {key:'avesterra',value:'avesterra'},
    {key:'aviva-sna',value:'aviva-sna'},
    {key:'awacs-ice',value:'awacs-ice'},
    {key:'awg-proxy',value:'awg-proxy'},
    {key:'axio-disc',value:'axio-disc'},
    {key:'babyphone',value:'babyphone'},
    {key:'bacula-fd',value:'bacula-fd'},
    {key:'bacula-sd',value:'bacula-sd'},
    {key:'badm_priv',value:'badm_priv'},
    {key:'badm-priv',value:'badm-priv'},
    {key:'bdir_priv',value:'bdir_priv'},
    {key:'bdir-priv',value:'bdir-priv'},
    {key:'behnke-cc',value:'behnke-cc'},
    {key:'bf-master',value:'bf-master'},
    {key:'bioserver',value:'bioserver'},
    {key:'blackbook',value:'blackbook'},
    {key:'blackjack',value:'blackjack'},
    {key:'bluelance',value:'bluelance'},
    {key:'boardplus',value:'boardplus'},
    {key:'boomerang',value:'boomerang'},
    {key:'bpcp-poll',value:'bpcp-poll'},
    {key:'bpcp-trap',value:'bpcp-trap'},
    {key:'bspne-pcc',value:'bspne-pcc'},
    {key:'btprjctrl',value:'btprjctrl'},
    {key:'bvcontrol',value:'bvcontrol'},
    {key:'bv-smcsrv',value:'bv-smcsrv'},
    {key:'caaclang2',value:'caaclang2'},
    {key:'catchpole',value:'catchpole'},
    {key:'cctv-port',value:'cctv-port'},
    {key:'cddbp-alt',value:'cddbp-alt'},
    {key:'cesdcdman',value:'cesdcdman'},
    {key:'cesdcdtrn',value:'cesdcdtrn'},
    {key:'cfg-cloud',value:'cfg-cloud'},
    {key:'cisco-avp',value:'cisco-avp'},
    {key:'ciscocsdb',value:'ciscocsdb'},
    {key:'cisco-fna',value:'cisco-fna'},
    {key:'cisco-sys',value:'cisco-sys'},
    {key:'cisco-tdp',value:'cisco-tdp'},
    {key:'cisco-tna',value:'cisco-tna'},
    {key:'citrixima',value:'citrixima'},
    {key:'citrixupp',value:'citrixupp'},
    {key:'clearcase',value:'clearcase'},
    {key:'clearvisn',value:'clearvisn'},
    {key:'clipboard',value:'clipboard'},
    {key:'clusterxl',value:'clusterxl'},
    {key:'cmmdriver',value:'cmmdriver'},
    {key:'cockroach',value:'cockroach'},
    {key:'codaauth2',value:'codaauth2'},
    {key:'codemeter',value:'codemeter'},
    {key:'cogsys-lm',value:'cogsys-lm'},
    {key:'coherence',value:'coherence'},
    {key:'comcam-io',value:'comcam-io'},
    {key:'conductor',value:'conductor'},
    {key:'confluent',value:'confluent'},
    {key:'connected',value:'connected'},
    {key:'continuus',value:'continuus'},
    {key:'coord-svr',value:'coord-svr'},
    {key:'copy-disc',value:'copy-disc'},
    {key:'cora-disc',value:'cora-disc'},
    {key:'corelccam',value:'corelccam'},
    {key:'cosmocall',value:'cosmocall'},
    {key:'cp-spxdpy',value:'cp-spxdpy'},
    {key:'cp-spxsvr',value:'cp-spxsvr'},
    {key:'crinis-hb',value:'crinis-hb'},
    {key:'csc_proxy',value:'csc_proxy'},
    {key:'csccredir',value:'csccredir'},
    {key:'csc-proxy',value:'csc-proxy'},
    {key:'csedaemon',value:'csedaemon'},
    {key:'ctp-state',value:'ctp-state'},
    {key:'cvc_hostd',value:'cvc_hostd'},
    {key:'cvc-hostd',value:'cvc-hostd'},
    {key:'cw-dpitap',value:'cw-dpitap'},
    {key:'cybercash',value:'cybercash'},
    {key:'cyborgnet',value:'cyborgnet'},
    {key:'cycleserv',value:'cycleserv'},
    {key:'d2dconfig',value:'d2dconfig'},
    {key:'d3winosfi',value:'d3winosfi'},
    {key:'dab-sti-c',value:'dab-sti-c'},
    {key:'dai-shell',value:'dai-shell'},
    {key:'daqstream',value:'daqstream'},
    {key:'data-port',value:'data-port'},
    {key:'datex-asn',value:'datex-asn'},
    {key:'dccp-ping',value:'dccp-ping'},
    {key:'dcutility',value:'dcutility'},
    {key:'ddi-tcp-1',value:'ddi-tcp-1'},
    {key:'ddi-tcp-2',value:'ddi-tcp-2'},
    {key:'ddi-tcp-3',value:'ddi-tcp-3'},
    {key:'ddi-tcp-4',value:'ddi-tcp-4'},
    {key:'ddi-tcp-5',value:'ddi-tcp-5'},
    {key:'ddi-tcp-6',value:'ddi-tcp-6'},
    {key:'ddi-tcp-7',value:'ddi-tcp-7'},
    {key:'ddi-udp-1',value:'ddi-udp-1'},
    {key:'ddi-udp-2',value:'ddi-udp-2'},
    {key:'ddi-udp-3',value:'ddi-udp-3'},
    {key:'ddi-udp-4',value:'ddi-udp-4'},
    {key:'ddi-udp-5',value:'ddi-udp-5'},
    {key:'ddi-udp-6',value:'ddi-udp-6'},
    {key:'ddi-udp-7',value:'ddi-udp-7'},
    {key:'dec-notes',value:'dec-notes'},
    {key:'delos-dms',value:'delos-dms'},
    {key:'delta-mcp',value:'delta-mcp'},
    {key:'de-server',value:'de-server'},
    {key:'deskshare',value:'deskshare'},
    {key:'devonsync',value:'devonsync'},
    {key:'diameters',value:'diameters'},
    {key:'dicom-tls',value:'dicom-tls'},
    {key:'dictation',value:'dictation'},
    {key:'directnet',value:'directnet'},
    {key:'DMExpress',value:'DMExpress'},
    {key:'doip-data',value:'doip-data'},
    {key:'doip-disc',value:'doip-disc'},
    {key:'dots-data',value:'dots-data'},
    {key:'downtools',value:'downtools'},
    {key:'dpi-proxy',value:'dpi-proxy'},
    {key:'dpkeyserv',value:'dpkeyserv'},
    {key:'dpm-agent',value:'dpm-agent'},
    {key:'dragonfly',value:'dragonfly'},
    {key:'dsmcc-ccp',value:'dsmcc-ccp'},
    {key:'dsx-agent',value:'dsx-agent'},
    {key:'dwnmshttp',value:'dwnmshttp'},
    {key:'dynamic3d',value:'dynamic3d'},
    {key:'earphoria',value:'earphoria'},
    {key:'e-builder',value:'e-builder'},
    {key:'ecmp-data',value:'ecmp-data'},
    {key:'editbench',value:'editbench'},
    {key:'eicon-slp',value:'eicon-slp'},
    {key:'eicon-x25',value:'eicon-x25'},
    {key:'elatelink',value:'elatelink'},
    {key:'em7-secom',value:'em7-secom'},
    {key:'empowerid',value:'empowerid'},
    {key:'emsd-port',value:'emsd-port'},
    {key:'emwavemsg',value:'emwavemsg'},
    {key:'enrp-sctp',value:'enrp-sctp'},
    {key:'entextlow',value:'entextlow'},
    {key:'entextmed',value:'entextmed'},
    {key:'entextxid',value:'entextxid'},
    {key:'eportcomm',value:'eportcomm'},
    {key:'ergolight',value:'ergolight'},
    {key:'erp-scale',value:'erp-scale'},
    {key:'esinstall',value:'esinstall'},
    {key:'esp-encap',value:'esp-encap'},
    {key:'essweb-gw',value:'essweb-gw'},
    {key:'est-coaps',value:'est-coaps'},
    {key:'evs-notif',value:'evs-notif'},
    {key:'evtp-data',value:'evtp-data'},
    {key:'ewcappsrv',value:'ewcappsrv'},
    {key:'exoconfig',value:'exoconfig'},
    {key:'ezmeeting',value:'ezmeeting'},
    {key:'ezproxy-2',value:'ezproxy-2'},
    {key:'f5-iquery',value:'f5-iquery'},
    {key:'fazzt-ptp',value:'fazzt-ptp'},
    {key:'fcip-port',value:'fcip-port'},
    {key:'fcis-disc',value:'fcis-disc'},
    {key:'fdt-rcatp',value:'fdt-rcatp'},
    {key:'feldwesen',value:'feldwesen'},
    {key:'ff-annunc',value:'ff-annunc'},
    {key:'findviatv',value:'findviatv'},
    {key:'fio-cmgmt',value:'fio-cmgmt'},
    {key:'firepower',value:'firepower'},
    {key:'fjdocdist',value:'fjdocdist'},
    {key:'fjswapsnp',value:'fjswapsnp'},
    {key:'flightdmp',value:'flightdmp'},
    {key:'flr_agent',value:'flr_agent'},
    {key:'flr-agent',value:'flr-agent'},
    {key:'foliocorp',value:'foliocorp'},
    {key:'fsportmap',value:'fsportmap'},
    {key:'fs-rh-srv',value:'fs-rh-srv'},
    {key:'fs-server',value:'fs-server'},
    {key:'ftp-agent',value:'ftp-agent'},
    {key:'ftps-data',value:'ftps-data'},
    {key:'ftrapid-1',value:'ftrapid-1'},
    {key:'ftrapid-2',value:'ftrapid-2'},
    {key:'funkproxy',value:'funkproxy'},
    {key:'gamelobby',value:'gamelobby'},
    {key:'garagepad',value:'garagepad'},
    {key:'gc-config',value:'gc-config'},
    {key:'gcmonitor',value:'gcmonitor'},
    {key:'gdbremote',value:'gdbremote'},
    {key:'gemini-lm',value:'gemini-lm'},
    {key:'geognosis',value:'geognosis'},
    {key:'geolocate',value:'geolocate'},
    {key:'glrdrvmon',value:'glrdrvmon'},
    {key:'gnmi-gnoi',value:'gnmi-gnoi'},
    {key:'gopro-web',value:'gopro-web'},
    {key:'gprs-cube',value:'gprs-cube'},
    {key:'gprs-data',value:'gprs-data'},
    {key:'groupwise',value:'groupwise'},
    {key:'gsmp-ancp',value:'gsmp-ancp'},
    {key:'gtegsc-lm',value:'gtegsc-lm'},
    {key:'gtrack-ne',value:'gtrack-ne'},
    {key:'h2gf-w-2m',value:'h2gf-w-2m'},
    {key:'hacl-poll',value:'hacl-poll'},
    {key:'hacl-test',value:'hacl-test'},
    {key:'hbbtv-ait',value:'hbbtv-ait'},
    {key:'hb-engine',value:'hb-engine'},
    {key:'heartbeat',value:'heartbeat'},
    {key:'heathview',value:'heathview'},
    {key:'hecmtl-db',value:'hecmtl-db'},
    {key:'heythings',value:'heythings'},
    {key:'hillrserv',value:'hillrserv'},
    {key:'hip-nat-t',value:'hip-nat-t'},
    {key:'hotu-chat',value:'hotu-chat'},
    {key:'hpbladems',value:'hpbladems'},
    {key:'hp-server',value:'hp-server'},
    {key:'hp-status',value:'hp-status'},
    {key:'hpstgmgr2',value:'hpstgmgr2'},
    {key:'hpvirtgrp',value:'hpvirtgrp'},
    {key:'hpvmmdata',value:'hpvmmdata'},
    {key:'hsl-storm',value:'hsl-storm'},
    {key:'http-mgmt',value:'http-mgmt'},
    {key:'http-wmap',value:'http-wmap'},
    {key:'htvncconf',value:'htvncconf'},
    {key:'hughes-ap',value:'hughes-ap'},
    {key:'iadt-disc',value:'iadt-disc'},
    {key:'iafserver',value:'iafserver'},
    {key:'iclpv-nlc',value:'iclpv-nlc'},
    {key:'iclpv-nls',value:'iclpv-nls'},
    {key:'iclpv-sas',value:'iclpv-sas'},
    {key:'iclpv-wsm',value:'iclpv-wsm'},
    {key:'ideaquest',value:'ideaquest'},
    {key:'iden-ralp',value:'iden-ralp'},
    {key:'ifcp-port',value:'ifcp-port'},
    {key:'ife_icorp',value:'ife_icorp'},
    {key:'ife-icorp',value:'ife-icorp'},
    {key:'ii-drills',value:'ii-drills'},
    {key:'ii-konane',value:'ii-konane'},
    {key:'imagepump',value:'imagepump'},
    {key:'imagescal',value:'imagescal'},
    {key:'imqstomps',value:'imqstomps'},
    {key:'imqtunnel',value:'imqtunnel'},
    {key:'index-net',value:'index-net'},
    {key:'influence',value:'influence'},
    {key:'infocrypt',value:'infocrypt'},
    {key:'infomover',value:'infomover'},
    {key:'instantia',value:'instantia'},
    {key:'intel_rci',value:'intel_rci'},
    {key:'intel-rci',value:'intel-rci'},
    {key:'intelsync',value:'intelsync'},
    {key:'interbase',value:'interbase'},
    {key:'interwise',value:'interwise'},
    {key:'intrastar',value:'intrastar'},
    {key:'invokator',value:'invokator'},
    {key:'ipcserver',value:'ipcserver'},
    {key:'ipdcesgbs',value:'ipdcesgbs'},
    {key:'ipfltbcst',value:'ipfltbcst'},
    {key:'ipsendmsg',value:'ipsendmsg'},
    {key:'ipspeaker',value:'ipspeaker'},
    {key:'irdg-post',value:'irdg-post'},
    {key:'iris-beep',value:'iris-beep'},
    {key:'iris-xpcs',value:'iris-xpcs'},
    {key:'irobotmcs',value:'irobotmcs'},
    {key:'ironstorm',value:'ironstorm'},
    {key:'isis-ambc',value:'isis-ambc'},
    {key:'ismserver',value:'ismserver'},
    {key:'isnetserv',value:'isnetserv'},
    {key:'isode-dua',value:'isode-dua'},
    {key:'isoft-p2p',value:'isoft-p2p'},
    {key:'isrp-port',value:'isrp-port'},
    {key:'ita-agent',value:'ita-agent'},
    {key:'itap-ddtp',value:'itap-ddtp'},
    {key:'ito-e-gui',value:'ito-e-gui'},
    {key:'ivocalize',value:'ivocalize'},
    {key:'ivs-video',value:'ivs-video'},
    {key:'jaxer-web',value:'jaxer-web'},
    {key:'jdmn-port',value:'jdmn-port'},
    {key:'jerand-lm',value:'jerand-lm'},
    {key:'jetstream',value:'jetstream'},
    {key:'jt400-ssl',value:'jt400-ssl'},
    {key:'jukejoint',value:'jukejoint'},
    {key:'kar2ouche',value:'kar2ouche'},
    {key:'keyserver',value:'keyserver'},
    {key:'keyshadow',value:'keyshadow'},
    {key:'kftp-data',value:'kftp-data'},
    {key:'kofax-svr',value:'kofax-svr'},
    {key:'kryptolan',value:'kryptolan'},
    {key:'ksysguard',value:'ksysguard'},
    {key:'kv-server',value:'kv-server'},
    {key:'l3-ranger',value:'l3-ranger'},
    {key:'l3t-at-an',value:'l3t-at-an'},
    {key:'labyrinth',value:'labyrinth'},
    {key:'landmarks',value:'landmarks'},
    {key:'lanner-lm',value:'lanner-lm'},
    {key:'lanschool',value:'lanschool'},
    {key:'lanserver',value:'lanserver'},
    {key:'lansource',value:'lansource'},
    {key:'lazy-ptop',value:'lazy-ptop'},
    {key:'ldgateway',value:'ldgateway'},
    {key:'libratone',value:'libratone'},
    {key:'lisp-data',value:'lisp-data'},
    {key:'livestats',value:'livestats'},
    {key:'ljk-login',value:'ljk-login'},
    {key:'lnvpoller',value:'lnvpoller'},
    {key:'lnvstatus',value:'lnvstatus'},
    {key:'locus-con',value:'locus-con'},
    {key:'locus-map',value:'locus-map'},
    {key:'logicnode',value:'logicnode'},
    {key:'lonbridge',value:'lonbridge'},
    {key:'lonworks2',value:'lonworks2'},
    {key:'lorica-in',value:'lorica-in'},
    {key:'lotusmtap',value:'lotusmtap'},
    {key:'lotusnote',value:'lotusnote'},
    {key:'lsys-oamp',value:'lsys-oamp'},
    {key:'lumis-lca',value:'lumis-lca'},
    {key:'m3da-disc',value:'m3da-disc'},
    {key:'macfoh-db',value:'macfoh-db'},
    {key:'macminder',value:'macminder'},
    {key:'macon-tcp',value:'macon-tcp'},
    {key:'macon-udp',value:'macon-udp'},
    {key:'madge-ltd',value:'madge-ltd'},
    {key:'magicdice',value:'magicdice'},
    {key:'marcam-lm',value:'marcam-lm'},
    {key:'mbl-battd',value:'mbl-battd'},
    {key:'mc-client',value:'mc-client'},
    {key:'mcer-port',value:'mcer-port'},
    {key:'mc-gt-srv',value:'mc-gt-srv'},
    {key:'mck-ivpip',value:'mck-ivpip'},
    {key:'mdap-port',value:'mdap-port'},
    {key:'medevolve',value:'medevolve'},
    {key:'merit-inp',value:'merit-inp'},
    {key:'metaagent',value:'metaagent'},
    {key:'meta-corp',value:'meta-corp'},
    {key:'metalbend',value:'metalbend'},
    {key:'metastorm',value:'metastorm'},
    {key:'mgxswitch',value:'mgxswitch'},
    {key:'micom-pfs',value:'micom-pfs'},
    {key:'mieleprof',value:'mieleprof'},
    {key:'mindprint',value:'mindprint'},
    {key:'mi-raysat',value:'mi-raysat'},
    {key:'moneysync',value:'moneysync'},
    {key:'monkeycom',value:'monkeycom'},
    {key:'MOS-lower',value:'MOS-lower'},
    {key:'MOS-upper',value:'MOS-upper'},
    {key:'movaz-ssc',value:'movaz-ssc'},
    {key:'mpm-flags',value:'mpm-flags'},
    {key:'mpsserver',value:'mpsserver'},
    {key:'mqe-agent',value:'mqe-agent'},
    {key:'ms-smlbiz',value:'ms-smlbiz'},
    {key:'mtportmon',value:'mtportmon'},
    {key:'mtrgtrans',value:'mtrgtrans'},
    {key:'mtsserver',value:'mtsserver'},
    {key:'multiplex',value:'multiplex'},
    {key:'mxim-art2',value:'mxim-art2'},
    {key:'mxxrlogin',value:'mxxrlogin'},
    {key:'n2nremote',value:'n2nremote'},
    {key:'n2receive',value:'n2receive'},
    {key:'na-er-tip',value:'na-er-tip'},
    {key:'namemunge',value:'namemunge'},
    {key:'nati-dstp',value:'nati-dstp'},
    {key:'natuslink',value:'natuslink'},
    {key:'neoriders',value:'neoriders'},
    {key:'net8-cman',value:'net8-cman'},
    {key:'netcelera',value:'netcelera'},
    {key:'netcheque',value:'netcheque'},
    {key:'neteh-ext',value:'neteh-ext'},
    {key:'netmap_lm',value:'netmap_lm'},
    {key:'netmap-lm',value:'netmap-lm'},
    {key:'netsc-dev',value:'netsc-dev'},
    {key:'netscript',value:'netscript'},
    {key:'netuitive',value:'netuitive'},
    {key:'newgenpay',value:'newgenpay'},
    {key:'newlixreg',value:'newlixreg'},
    {key:'nexentamv',value:'nexentamv'},
    {key:'nifty-hmi',value:'nifty-hmi'},
    {key:'ni-sysapi',value:'ni-sysapi'},
    {key:'nmea-0183',value:'nmea-0183'},
    {key:'nms-dpnss',value:'nms-dpnss'},
    {key:'nmsigport',value:'nmsigport'},
    {key:'nmsserver',value:'nmsserver'},
    {key:'noteshare',value:'noteshare'},
    {key:'npdbgmngr',value:'npdbgmngr'},
    {key:'npmp-trap',value:'npmp-trap'},
    {key:'nrcabq-lm',value:'nrcabq-lm'},
    {key:'ns-server',value:'ns-server'},
    {key:'ntlx-arch',value:'ntlx-arch'},
    {key:'nvme-disc',value:'nvme-disc'},
    {key:'oa-system',value:'oa-system'},
    {key:'objective',value:'objective'},
    {key:'od-master',value:'od-master'},
    {key:'oem-agent',value:'oem-agent'},
    {key:'oma-ilp-s',value:'oma-ilp-s'},
    {key:'oma-mlp-s',value:'oma-mlp-s'},
    {key:'oma-rlp-s',value:'oma-rlp-s'},
    {key:'omasgport',value:'omasgport'},
    {key:'omni-live',value:'omni-live'},
    {key:'omnistate',value:'omnistate'},
    {key:'omviagent',value:'omviagent'},
    {key:'onpsocket',value:'onpsocket'},
    {key:'opcon-xps',value:'opcon-xps'},
    {key:'opcua-tcp',value:'opcua-tcp'},
    {key:'opcua-tls',value:'opcua-tls'},
    {key:'opcua-udp',value:'opcua-udp'},
    {key:'openmailg',value:'openmailg'},
    {key:'openqueue',value:'openqueue'},
    {key:'opentable',value:'opentable'},
    {key:'opnet-smp',value:'opnet-smp'},
    {key:'opsec-cvp',value:'opsec-cvp'},
    {key:'opsec-ela',value:'opsec-ela'},
    {key:'opsec-lea',value:'opsec-lea'},
    {key:'opsec-omi',value:'opsec-omi'},
    {key:'opsec-sam',value:'opsec-sam'},
    {key:'opsec-uaa',value:'opsec-uaa'},
    {key:'opsec-ufp',value:'opsec-ufp'},
    {key:'opswagent',value:'opswagent'},
    {key:'optilogic',value:'optilogic'},
    {key:'os-licman',value:'os-licman'},
    {key:'ospf-lite',value:'ospf-lite'},
    {key:'p4runtime',value:'p4runtime'},
    {key:'partimage',value:'partimage'},
    {key:'payrouter',value:'payrouter'},
    {key:'perf-port',value:'perf-port'},
    {key:'periscope',value:'periscope'},
    {key:'personnel',value:'personnel'},
    {key:'pgbouncer',value:'pgbouncer'},
    {key:'phonebook',value:'phonebook'},
    {key:'photoshow',value:'photoshow'},
    {key:'ping-pong',value:'ping-pong'},
    {key:'pmcdproxy',value:'pmcdproxy'},
    {key:'pm-cmdsvr',value:'pm-cmdsvr'},
    {key:'pnet-conn',value:'pnet-conn'},
    {key:'pnrp-port',value:'pnrp-port'},
    {key:'ppcontrol',value:'ppcontrol'},
    {key:'printopia',value:'printopia'},
    {key:'print-srv',value:'print-srv'},
    {key:'privilege',value:'privilege'},
    {key:'prm-nm-np',value:'prm-nm-np'},
    {key:'prm-sm-np',value:'prm-sm-np'},
    {key:'prnstatus',value:'prnstatus'},
    {key:'procos-lm',value:'procos-lm'},
    {key:'proremote',value:'proremote'},
    {key:'proshare1',value:'proshare1'},
    {key:'proshare2',value:'proshare2'},
    {key:'psbserver',value:'psbserver'},
    {key:'pslserver',value:'pslserver'},
    {key:'pspserver',value:'pspserver'},
    {key:'psrserver',value:'psrserver'},
    {key:'PS-Server',value:'PS-Server'},
    {key:'ptk-alink',value:'ptk-alink'},
    {key:'ptp-event',value:'ptp-event'},
    {key:'pumpkindb',value:'pumpkindb'},
    {key:'puprouter',value:'puprouter'},
    {key:'purenoise',value:'purenoise'},
    {key:'purityrpc',value:'purityrpc'},
    {key:'pvsw-inet',value:'pvsw-inet'},
    {key:'pvuniwien',value:'pvuniwien'},
    {key:'pvxpluscs',value:'pvxpluscs'},
    {key:'pvxplusio',value:'pvxplusio'},
    {key:'pwgippfax',value:'pwgippfax'},
    {key:'pwrsevent',value:'pwrsevent'},
    {key:'pxc-epmap',value:'pxc-epmap'},
    {key:'qip-audup',value:'qip-audup'},
    {key:'qip-login',value:'qip-login'},
    {key:'qip-qdhcp',value:'qip-qdhcp'},
    {key:'qnxnetman',value:'qnxnetman'},
    {key:'qolyester',value:'qolyester'},
    {key:'qo-secure',value:'qo-secure'},
    {key:'qsm-proxy',value:'qsm-proxy'},
    {key:'radioport',value:'radioport'},
    {key:'radiustls',value:'radiustls'},
    {key:'railduino',value:'railduino'},
    {key:'rapidbase',value:'rapidbase'},
    {key:'rapido-ip',value:'rapido-ip'},
    {key:'ratio-adp',value:'ratio-adp'},
    {key:'raven-rdp',value:'raven-rdp'},
    {key:'raven-rmp',value:'raven-rmp'},
    {key:'raxa-mgmt',value:'raxa-mgmt'},
    {key:'rblcheckd',value:'rblcheckd'},
    {key:'rbr-debug',value:'rbr-debug'},
    {key:'registrar',value:'registrar'},
    {key:'remote-as',value:'remote-as'},
    {key:'reversion',value:'reversion'},
    {key:'ridgeway1',value:'ridgeway1'},
    {key:'ridgeway2',value:'ridgeway2'},
    {key:'rlm-admin',value:'rlm-admin'},
    {key:'rms-agent',value:'rms-agent'},
    {key:'rmtserver',value:'rmtserver'},
    {key:'robcad-lm',value:'robcad-lm'},
    {key:'robustirc',value:'robustirc'},
    {key:'rsc-robot',value:'rsc-robot'},
    {key:'rs-status',value:'rs-status'},
    {key:'rt-helper',value:'rt-helper'},
    {key:'rtmp-port',value:'rtmp-port'},
    {key:'rtsclient',value:'rtsclient'},
    {key:'rt-viewer',value:'rt-viewer'},
    {key:'sandpolis',value:'sandpolis'},
    {key:'sane-port',value:'sane-port'},
    {key:'sat-expak',value:'sat-expak'},
    {key:'sbi-agent',value:'sbi-agent'},
    {key:'scintilla',value:'scintilla'},
    {key:'sco-dtmgr',value:'sco-dtmgr'},
    {key:'scotty-ft',value:'scotty-ft'},
    {key:'scup-disc',value:'scup-disc'},
    {key:'scx-proxy',value:'scx-proxy'},
    {key:'sds-admin',value:'sds-admin'},
    {key:'sdsharing',value:'sdsharing'},
    {key:'secure-ts',value:'secure-ts'},
    {key:'semaphore',value:'semaphore'},
    {key:'semi-grpc',value:'semi-grpc'},
    {key:'senomix01',value:'senomix01'},
    {key:'senomix02',value:'senomix02'},
    {key:'senomix03',value:'senomix03'},
    {key:'senomix04',value:'senomix04'},
    {key:'senomix05',value:'senomix05'},
    {key:'senomix06',value:'senomix06'},
    {key:'senomix07',value:'senomix07'},
    {key:'senomix08',value:'senomix08'},
    {key:'serendipd',value:'serendipd'},
    {key:'servereye',value:'servereye'},
    {key:'servermgr',value:'servermgr'},
    {key:'sessionfs',value:'sessionfs'},
    {key:'sge_execd',value:'sge_execd'},
    {key:'sge-execd',value:'sge-execd'},
    {key:'shivahose',value:'shivahose'},
    {key:'shockwave',value:'shockwave'},
    {key:'shoutcast',value:'shoutcast'},
    {key:'sicct-sdp',value:'sicct-sdp'},
    {key:'siebel-ns',value:'siebel-ns'},
    {key:'sightline',value:'sightline'},
    {key:'silkmeter',value:'silkmeter'},
    {key:'sitaradir',value:'sitaradir'},
    {key:'sitewatch',value:'sitewatch'},
    {key:'sixnetudr',value:'sixnetudr'},
    {key:'skytelnet',value:'skytelnet'},
    {key:'slingshot',value:'slingshot'},
    {key:'smallchat',value:'smallchat'},
    {key:'smbdirect',value:'smbdirect'},
    {key:'smc-admin',value:'smc-admin'},
    {key:'smcluster',value:'smcluster'},
    {key:'snif-fifo',value:'snif-fifo'},
    {key:'sns-admin',value:'sns-admin'},
    {key:'sns-agent',value:'sns-agent'},
    {key:'sns-query',value:'sns-query'},
    {key:'sns-quote',value:'sns-quote'},
    {key:'soap-beep',value:'soap-beep'},
    {key:'soap-http',value:'soap-http'},
    {key:'softaudit',value:'softaudit'},
    {key:'solid-mux',value:'solid-mux'},
    {key:'sonardata',value:'sonardata'},
    {key:'soniqsync',value:'soniqsync'},
    {key:'sophia-lm',value:'sophia-lm'},
    {key:'spectardb',value:'spectardb'},
    {key:'sphinxapi',value:'sphinxapi'},
    {key:'splitlock',value:'splitlock'},
    {key:'ssc-agent',value:'ssc-agent'},
    {key:'ssd-audio',value:'ssd-audio'},
    {key:'ssm-cssps',value:'ssm-cssps'},
    {key:'sstsys-lm',value:'sstsys-lm'},
    {key:'stm_pproc',value:'stm_pproc'},
    {key:'stm-pproc',value:'stm-pproc'},
    {key:'storageos',value:'storageos'},
    {key:'strateges',value:'strateges'},
    {key:'strexec-d',value:'strexec-d'},
    {key:'strexec-s',value:'strexec-s'},
    {key:'stun-port',value:'stun-port'},
    {key:'su-mit-tg',value:'su-mit-tg'},
    {key:'supercell',value:'supercell'},
    {key:'swift-rvf',value:'swift-rvf'},
    {key:'swordfish',value:'swordfish'},
    {key:'syscomlan',value:'syscomlan'},
    {key:'tacacs-ds',value:'tacacs-ds'},
    {key:'tag-ups-1',value:'tag-ups-1'},
    {key:'tarantool',value:'tarantool'},
    {key:'taurus-wh',value:'taurus-wh'},
    {key:'tdp-suite',value:'tdp-suite'},
    {key:'tf-redeye',value:'tf-redeye'},
    {key:'tick-port',value:'tick-port'},
    {key:'timeflies',value:'timeflies'},
    {key:'timezones',value:'timezones'},
    {key:'tnp1-port',value:'tnp1-port'},
    {key:'tn-timing',value:'tn-timing'},
    {key:'tn-tl-fd1',value:'tn-tl-fd1'},
    {key:'tn-tl-fd2',value:'tn-tl-fd2'},
    {key:'transmitr',value:'transmitr'},
    {key:'trap-port',value:'trap-port'},
    {key:'traversal',value:'traversal'},
    {key:'tributary',value:'tributary'},
    {key:'truckstar',value:'truckstar'},
    {key:'tt4inarow',value:'tt4inarow'},
    {key:'tunalyzer',value:'tunalyzer'},
    {key:'twinlevel',value:'twinlevel'},
    {key:'twosnakes',value:'twosnakes'},
    {key:'ulistproc',value:'ulistproc'},
    {key:'unikeypro',value:'unikeypro'},
    {key:'unisys-lm',value:'unisys-lm'},
    {key:'univision',value:'univision'},
    {key:'unizensus',value:'unizensus'},
    {key:'upnotifyp',value:'upnotifyp'},
    {key:'urld-port',value:'urld-port'},
    {key:'uucp-path',value:'uucp-path'},
    {key:'vaultbase',value:'vaultbase'},
    {key:'verismart',value:'verismart'},
    {key:'versatalk',value:'versatalk'},
    {key:'versa-tek',value:'versa-tek'},
    {key:'vestasdlp',value:'vestasdlp'},
    {key:'vfbp-disc',value:'vfbp-disc'},
    {key:'vids-avtp',value:'vids-avtp'},
    {key:'virtualdj',value:'virtualdj'},
    {key:'visitview',value:'visitview'},
    {key:'vista-4gl',value:'vista-4gl'},
    {key:'v-one-spp',value:'v-one-spp'},
    {key:'voxidahmp',value:'voxidahmp'},
    {key:'vpsipport',value:'vpsipport'},
    {key:'vsi-omega',value:'vsi-omega'},
    {key:'vs-server',value:'vs-server'},
    {key:'vtu-comms',value:'vtu-comms'},
    {key:'vxlan-gpe',value:'vxlan-gpe'},
    {key:'wanscaler',value:'wanscaler'},
    {key:'wap-vcard',value:'wap-vcard'},
    {key:'wap-wsp-s',value:'wap-wsp-s'},
    {key:'warehouse',value:'warehouse'},
    {key:'wbem-http',value:'wbem-http'},
    {key:'webaccess',value:'webaccess'},
    {key:'webissync',value:'webissync'},
    {key:'webmail-2',value:'webmail-2'},
    {key:'winshadow',value:'winshadow'},
    {key:'wkstn-mon',value:'wkstn-mon'},
    {key:'wmlserver',value:'wmlserver'},
    {key:'workgroup',value:'workgroup'},
    {key:'wta-wsp-s',value:'wta-wsp-s'},
    {key:'xecp-node',value:'xecp-node'},
    {key:'xgate-rmi',value:'xgate-rmi'},
    {key:'xiostatus',value:'xiostatus'},
    {key:'xkotodrcp',value:'xkotodrcp'},
    {key:'xmiserver',value:'xmiserver'},
    {key:'xmpp-bosh',value:'xmpp-bosh'},
    {key:'xybrid-rt',value:'xybrid-rt'},
    {key:'xycstatus',value:'xycstatus'},
    {key:'zephyr-hm',value:'zephyr-hm'},
    {key:'zigbee-ip',value:'zigbee-ip'},
    {key:'zymed-zpp',value:'zymed-zpp'},    
    {key:'3comfaxrpc',value:'3comfaxrpc'},
    {key:'3comnetman',value:'3comnetman'},
    {key:'3com-tsmux',value:'3com-tsmux'},
    {key:'3par-rcopy',value:'3par-rcopy'},
    {key:'a3-sdunode',value:'a3-sdunode'},
    {key:'a4-sdunode',value:'a4-sdunode'},
    {key:'abbaccuray',value:'abbaccuray'},
    {key:'abr-secure',value:'abr-secure'},
    {key:'ac-cluster',value:'ac-cluster'},
    {key:'accord-mgc',value:'accord-mgc'},
    {key:'ace-client',value:'ace-client'},
    {key:'ace-server',value:'ace-server'},
    {key:'acp-policy',value:'acp-policy'},
    {key:'acrobatsrv',value:'acrobatsrv'},
    {key:'acs-ctl-ds',value:'acs-ctl-ds'},
    {key:'acs-ctl-gw',value:'acs-ctl-gw'},
    {key:'active-net',value:'active-net'},
    {key:'activeraid',value:'activeraid'},
    {key:'activesync',value:'activesync'},
    {key:'adaptecmgr',value:'adaptecmgr'},
    {key:'admins-lms',value:'admins-lms'},
    {key:'aecoretech',value:'aecoretech'},
    {key:'aequus-alt',value:'aequus-alt'},
    {key:'afpovertcp',value:'afpovertcp'},
    {key:'agriserver',value:'agriserver'},
    {key:'aic-oncrpc',value:'aic-oncrpc'},
    {key:'aironetddp',value:'aironetddp'},
    {key:'airpreview',value:'airpreview'},
    {key:'airsharing',value:'airsharing'},
    {key:'allstorcns',value:'allstorcns'},
    {key:'amiganetfs',value:'amiganetfs'},
    {key:'amiphd-p2p',value:'amiphd-p2p'},
    {key:'ampr-inter',value:'ampr-inter'},
    {key:'amx-axbnet',value:'amx-axbnet'},
    {key:'anquetsync',value:'anquetsync'},
    {key:'ansanotify',value:'ansanotify'},
    {key:'ansatrader',value:'ansatrader'},
    {key:'apollo-gms',value:'apollo-gms'},
    {key:'appiq-mgmt',value:'appiq-mgmt'},
    {key:'apple-midi',value:'apple-midi'},
    {key:'apple-sasl',value:'apple-sasl'},
    {key:'appworxsrv',value:'appworxsrv'},
    {key:'ardus-cntl',value:'ardus-cntl'},
    {key:'ardus-trns',value:'ardus-trns'},
    {key:'arepa-raft',value:'arepa-raft'},
    {key:'ariascribe',value:'ariascribe'},
    {key:'ars-master',value:'ars-master'},
    {key:'aruba-papi',value:'aruba-papi'},
    {key:'ascomalarm',value:'ascomalarm'},
    {key:'assoc-disc',value:'assoc-disc'},
    {key:'athand-mmp',value:'athand-mmp'},
    {key:'att-mt-sms',value:'att-mt-sms'},
    {key:'autocuelog',value:'autocuelog'},
    {key:'autocuesmi',value:'autocuesmi'},
    {key:'autotunnel',value:'autotunnel'},
    {key:'avanti_cdp',value:'avanti_cdp'},
    {key:'avanti-cdp',value:'avanti-cdp'},
    {key:'axis-video',value:'axis-video'},
    {key:'axysbridge',value:'axysbridge'},
    {key:'b2-license',value:'b2-license'},
    {key:'b2-runtime',value:'b2-runtime'},
    {key:'babel-dtls',value:'babel-dtls'},
    {key:'backburner',value:'backburner'},
    {key:'backupedge',value:'backupedge'},
    {key:'bacula-dir',value:'bacula-dir'},
    {key:'banyan-net',value:'banyan-net'},
    {key:'banyan-rpc',value:'banyan-rpc'},
    {key:'banyan-vip',value:'banyan-vip'},
    {key:'bcs-broker',value:'bcs-broker'},
    {key:'bittorrent',value:'bittorrent'},
    {key:'blackboard',value:'blackboard'},
    {key:'bmc-onekey',value:'bmc-onekey'},
    {key:'board-roar',value:'board-roar'},
    {key:'board-voip',value:'board-voip'},
    {key:'boks_clntd',value:'boks_clntd'},
    {key:'boks_servc',value:'boks_servc'},
    {key:'boks_servm',value:'boks_servm'},
    {key:'boks-clntd',value:'boks-clntd'},
    {key:'boks-servc',value:'boks-servc'},
    {key:'boks-servm',value:'boks-servm'},
    {key:'bootclient',value:'bootclient'},
    {key:'bootserver',value:'bootserver'},
    {key:'boxraysrvr',value:'boxraysrvr'},
    {key:'br-channel',value:'br-channel'},
    {key:'brightcore',value:'brightcore'},
    {key:'br-sat-mon',value:'br-sat-mon'},
    {key:'buddy-draw',value:'buddy-draw'},
    {key:'c1222-acse',value:'c1222-acse'},
    {key:'cabsm-comm',value:'cabsm-comm'},
    {key:'cadabra-lm',value:'cadabra-lm'},
    {key:'cadview-3d',value:'cadview-3d'},
    {key:'can-ferret',value:'can-ferret'},
    {key:'canon-capt',value:'canon-capt'},
    {key:'canon-chmp',value:'canon-chmp'},
    {key:'canon-mfnp',value:'canon-mfnp'},
    {key:'CardWeb-IO',value:'CardWeb-IO'},
    {key:'CardWeb-RT',value:'CardWeb-RT'},
    {key:'casrmagent',value:'casrmagent'},
    {key:'ccu-comm-1',value:'ccu-comm-1'},
    {key:'ccu-comm-2',value:'ccu-comm-2'},
    {key:'ccu-comm-3',value:'ccu-comm-3'},
    {key:'cdl-server',value:'cdl-server'},
    {key:'cedros_fds',value:'cedros_fds'},
    {key:'cedros-fds',value:'cedros-fds'},
    {key:'centerline',value:'centerline'},
    {key:'cgn-config',value:'cgn-config'},
    {key:'charsetmgr',value:'charsetmgr'},
    {key:'checkoutdb',value:'checkoutdb'},
    {key:'cichild-lm',value:'cichild-lm'},
    {key:'cisco-aqos',value:'cisco-aqos'},
    {key:'cisco-redu',value:'cisco-redu'},
    {key:'cisco-sccp',value:'cisco-sccp'},
    {key:'cisco-snat',value:'cisco-snat'},
    {key:'cisco-wafs',value:'cisco-wafs'},
    {key:'cis-secure',value:'cis-secure'},
    {key:'citrixuppg',value:'citrixuppg'},
    {key:'citysearch',value:'citysearch'},
    {key:'cloanto-lm',value:'cloanto-lm'},
    {key:'cloudcheck',value:'cloudcheck'},
    {key:'cmip-agent',value:'cmip-agent'},
    {key:'codasrv-se',value:'codasrv-se'},
    {key:'codima-rtp',value:'codima-rtp'},
    {key:'collection',value:'collection'},
    {key:'compaq-evm',value:'compaq-evm'},
    {key:'compaq-scp',value:'compaq-scp'},
    {key:'compaq-wcp',value:'compaq-wcp'},
    {key:'conference',value:'conference'},
    {key:'connection',value:'connection'},
    {key:'conspiracy',value:'conspiracy'},
    {key:'cookie-jar',value:'cookie-jar'},
    {key:'corba-iiop',value:'corba-iiop'},
    {key:'corelvideo',value:'corelvideo'},
    {key:'corroboree',value:'corroboree'},
    {key:'cp-cluster',value:'cp-cluster'},
    {key:'cp-spxrpts',value:'cp-spxrpts'},
    {key:'cpudpencap',value:'cpudpencap'},
    {key:'cqg-netlan',value:'cqg-netlan'},
    {key:'crtech-nlm',value:'crtech-nlm'},
    {key:'cslistener',value:'cslistener'},
    {key:'csoft-prev',value:'csoft-prev'},
    {key:'cspclmulti',value:'cspclmulti'},
    {key:'csregagent',value:'csregagent'},
    {key:'csvr-proxy',value:'csvr-proxy'},
    {key:'ctt-broker',value:'ctt-broker'},
    {key:'ctx-bridge',value:'ctx-bridge'},
    {key:'cvspserver',value:'cvspserver'},
    {key:'cw-codetap',value:'cw-codetap'},
    {key:'cw-oncetap',value:'cw-oncetap'},
    {key:'cycleserv2',value:'cycleserv2'},
    {key:'darcorp-lm',value:'darcorp-lm'},
    {key:'datacaptor',value:'datacaptor'},
    {key:'dbreporter',value:'dbreporter'},
    {key:'dcs-config',value:'dcs-config'},
    {key:'dcsoftware',value:'dcsoftware'},
    {key:'decladebug',value:'decladebug'},
    {key:'devshr-nts',value:'devshr-nts'},
    {key:'dey-keyneg',value:'dey-keyneg'},
    {key:'dfoxserver',value:'dfoxserver'},
    {key:'dgpf-exchg',value:'dgpf-exchg'},
    {key:'dicom-iscl',value:'dicom-iscl'},
    {key:'direcpc-si',value:'direcpc-si'},
    {key:'directplay',value:'directplay'},
    {key:'disconnect',value:'disconnect'},
    {key:'displaysrc',value:'displaysrc'},
    {key:'distinct32',value:'distinct32'},
    {key:'dlms-cosem',value:'dlms-cosem'},
    {key:'dltimesync',value:'dltimesync'},
    {key:'dns-update',value:'dns-update'},
    {key:'doceri-ctl',value:'doceri-ctl'},
    {key:'doc-server',value:'doc-server'},
    {key:'documentum',value:'documentum'},
    {key:'dof-tunnel',value:'dof-tunnel'},
    {key:'domaintime',value:'domaintime'},
    {key:'donnyworld',value:'donnyworld'},
    {key:'dt-mgmtsvc',value:'dt-mgmtsvc'},
    {key:'dtn-bundle',value:'dtn-bundle'},
    {key:'dvbservdsc',value:'dvbservdsc'},
    {key:'dvt-system',value:'dvt-system'},
    {key:'easyengine',value:'easyengine'},
    {key:'edm-stager',value:'edm-stager'},
    {key:'edsservice',value:'edsservice'},
    {key:'ehp-backup',value:'ehp-backup'},
    {key:'eims-admin',value:'eims-admin'},
    {key:'elfiq-repl',value:'elfiq-repl'},
    {key:'elipse-rec',value:'elipse-rec'},
    {key:'emcrmirccd',value:'emcrmirccd'},
    {key:'emfis-cntl',value:'emfis-cntl'},
    {key:'emfis-data',value:'emfis-data'},
    {key:'ent-engine',value:'ent-engine'},
    {key:'entexthigh',value:'entexthigh'},
    {key:'eq3-config',value:'eq3-config'},
    {key:'eq3-update',value:'eq3-update'},
    {key:'esmmanager',value:'esmmanager'},
    {key:'esro-emsdp',value:'esro-emsdp'},
    {key:'eucalyptus',value:'eucalyptus'},
    {key:'eudora-set',value:'eudora-set'},
    {key:'event-port',value:'event-port'},
    {key:'everydayrc',value:'everydayrc'},
    {key:'exapt-lmgr',value:'exapt-lmgr'},
    {key:'exbit-escp',value:'exbit-escp'},
    {key:'exlm-agent',value:'exlm-agent'},
    {key:'expresspay',value:'expresspay'},
    {key:'extensissn',value:'extensissn'},
    {key:'f1-control',value:'f1-control'},
    {key:'facsys-ntp',value:'facsys-ntp'},
    {key:'faircom-db',value:'faircom-db'},
    {key:'febooti-aw',value:'febooti-aw'},
    {key:'ff-lr-port',value:'ff-lr-port'},
    {key:'filenet-cm',value:'filenet-cm'},
    {key:'filenet-pa',value:'filenet-pa'},
    {key:'filenet-re',value:'filenet-re'},
    {key:'filesphere',value:'filesphere'},
    {key:'firemonrcc',value:'firemonrcc'},
    {key:'fiveacross',value:'fiveacross'},
    {key:'fksp-audit',value:'fksp-audit'},
    {key:'flashfiler',value:'flashfiler'},
    {key:'flir-ircam',value:'flir-ircam'},
    {key:'fmpro-fdal',value:'fmpro-fdal'},
    {key:'galileolog',value:'galileolog'},
    {key:'gandalf-lm',value:'gandalf-lm'},
    {key:'gbmt-stars',value:'gbmt-stars'},
    {key:'genrad-mux',value:'genrad-mux'},
    {key:'globmsgsvc',value:'globmsgsvc'},
    {key:'googlecast',value:'googlecast'},
    {key:'gopro-wake',value:'gopro-wake'},
    {key:'gotodevice',value:'gotodevice'},
    {key:'gre-in-udp',value:'gre-in-udp'},
    {key:'groove-dpp',value:'groove-dpp'},
    {key:'gss-xlicen',value:'gss-xlicen'},
    {key:'gucam-http',value:'gucam-http'},
    {key:'guttersnex',value:'guttersnex'},
    {key:'gwen-sonya',value:'gwen-sonya'},
    {key:'h263-video',value:'h263-video'},
    {key:'hacl-local',value:'hacl-local'},
    {key:'hacl-probe',value:'hacl-probe'},
    {key:'ha-cluster',value:'ha-cluster'},
    {key:'hagel-dump',value:'hagel-dump'},
    {key:'haipe-otnk',value:'haipe-otnk'},
    {key:'hcp-wismar',value:'hcp-wismar'},
    {key:'hello-port',value:'hello-port'},
    {key:'houdini-lm',value:'houdini-lm'},
    {key:'hpidsadmin',value:'hpidsadmin'},
    {key:'hpidsagent',value:'hpidsagent'},
    {key:'hpr-bldlnx',value:'hpr-bldlnx'},
    {key:'hpr-bldwin',value:'hpr-bldwin'},
    {key:'hpr-tstlnx',value:'hpr-tstlnx'},
    {key:'hpr-tstwin',value:'hpr-tstwin'},
    {key:'hp-sessmon',value:'hp-sessmon'},
    {key:'hpss-ndapi',value:'hpss-ndapi'},
    {key:'hpvirtctrl',value:'hpvirtctrl'},
    {key:'hpvmmagent',value:'hpvmmagent'},
    {key:'https-wmap',value:'https-wmap'},
    {key:'hybrid-pop',value:'hybrid-pop'},
    {key:'ias-admind',value:'ias-admind'},
    {key:'iascontrol',value:'iascontrol'},
    {key:'ias-paging',value:'ias-paging'},
    {key:'ibm-abtact',value:'ibm-abtact'},
    {key:'ibm-diradm',value:'ibm-diradm'},
    {key:'ibprotocol',value:'ibprotocol'},
    {key:'icabrowser',value:'icabrowser'},
    {key:'iceedcp_rx',value:'iceedcp_rx'},
    {key:'iceedcp_tx',value:'iceedcp_tx'},
    {key:'iceedcp-rx',value:'iceedcp-rx'},
    {key:'iceedcp-tx',value:'iceedcp-tx'},
    {key:'ice-router',value:'ice-router'},
    {key:'icg-bridge',value:'icg-bridge'},
    {key:'icshostsvc',value:'icshostsvc'},
    {key:'igmpv3lite',value:'igmpv3lite'},
    {key:'imagequery',value:'imagequery'},
    {key:'imqbrokerd',value:'imqbrokerd'},
    {key:'imqtunnels',value:'imqtunnels'},
    {key:'inbusiness',value:'inbusiness'},
    {key:'indigo-dvr',value:'indigo-dvr'},
    {key:'infi-async',value:'infi-async'},
    {key:'infobright',value:'infobright'},
    {key:'infolibria',value:'infolibria'},
    {key:'ingreslock',value:'ingreslock'},
    {key:'ingres-net',value:'ingres-net'},
    {key:'initlsmsad',value:'initlsmsad'},
    {key:'innergroup',value:'innergroup'},
    {key:'inovaport1',value:'inovaport1'},
    {key:'inovaport2',value:'inovaport2'},
    {key:'inovaport3',value:'inovaport3'},
    {key:'inovaport4',value:'inovaport4'},
    {key:'inovaport5',value:'inovaport5'},
    {key:'inovaport6',value:'inovaport6'},
    {key:'interworld',value:'interworld'},
    {key:'intraintra',value:'intraintra'},
    {key:'ioc-sea-lm',value:'ioc-sea-lm'},
    {key:'ipdtp-port',value:'ipdtp-port'},
    {key:'ipluminary',value:'ipluminary'},
    {key:'ip-netbios',value:'ip-netbios'},
    {key:'iposplanet',value:'iposplanet'},
    {key:'ipulse-ics',value:'ipulse-ics'},
    {key:'iqnet-port',value:'iqnet-port'},
    {key:'isa100-gci',value:'isa100-gci'},
    {key:'isis-bcast',value:'isis-bcast'},
    {key:'itinternet',value:'itinternet'},
    {key:'itscomm-ns',value:'itscomm-ns'},
    {key:'iwlistener',value:'iwlistener'},
    {key:'iw-mmogame',value:'iw-mmogame'},
    {key:'jacobus-lm',value:'jacobus-lm'},
    {key:'janus-disc',value:'janus-disc'},
    {key:'jboss-iiop',value:'jboss-iiop'},
    {key:'jdatastore',value:'jdatastore'},
    {key:'jediserver',value:'jediserver'},
    {key:'jnx-kcsync',value:'jnx-kcsync'},
    {key:'juxml-port',value:'juxml-port'},
    {key:'kingfisher',value:'kingfisher'},
    {key:'kmscontrol',value:'kmscontrol'},
    {key:'konshus-lm',value:'konshus-lm'},
    {key:'konspire2b',value:'konspire2b'},
    {key:'kvm-via-ip',value:'kvm-via-ip'},
    {key:'kwdb-commn',value:'kwdb-commn'},
    {key:'lavenir-lm',value:'lavenir-lm'},
    {key:'lcm-server',value:'lcm-server'},
    {key:'ldap-admin',value:'ldap-admin'},
    {key:'ldoms-mgmt',value:'ldoms-mgmt'},
    {key:'ldoms-migr',value:'ldoms-migr'},
    {key:'liberty-lm',value:'liberty-lm'},
    {key:'linktest-s',value:'linktest-s'},
    {key:'lkcmserver',value:'lkcmserver'},
    {key:'lm-instmgr',value:'lm-instmgr'},
    {key:'lm-sserver',value:'lm-sserver'},
    {key:'lnvconsole',value:'lnvconsole'},
    {key:'lnvmailmon',value:'lnvmailmon'},
    {key:'locus-disc',value:'locus-disc'},
    {key:'lorica-out',value:'lorica-out'},
    {key:'lrs-paging',value:'lrs-paging'},
    {key:'lsi-bobcat',value:'lsi-bobcat'},
    {key:'lsys-ezcfg',value:'lsys-ezcfg'},
    {key:'lvision-lm',value:'lvision-lm'},
    {key:'magicnotes',value:'magicnotes'},
    {key:'mailbox-lm',value:'mailbox-lm'},
    {key:'marathontp',value:'marathontp'},
    {key:'markem-dcp',value:'markem-dcp'},
    {key:'masqdialer',value:'masqdialer'},
    {key:'mbconsumer',value:'mbconsumer'},
    {key:'mbproducer',value:'mbproducer'},
    {key:'mc2studios',value:'mc2studios'},
    {key:'mc-brk-srv',value:'mc-brk-srv'},
    {key:'md-cg-http',value:'md-cg-http'},
    {key:'med-fsp-rx',value:'med-fsp-rx'},
    {key:'med-fsp-tx',value:'med-fsp-tx'},
    {key:'mediaspace',value:'mediaspace'},
    {key:'mercantile',value:'mercantile'},
    {key:'meregister',value:'meregister'},
    {key:'metricadbc',value:'metricadbc'},
    {key:'mipi-debug',value:'mipi-debug'},
    {key:'mit-ml-dev',value:'mit-ml-dev'},
    {key:'mit-subnet',value:'mit-subnet'},
    {key:'mn-passage',value:'mn-passage'},
    {key:'mobile-p2p',value:'mobile-p2p'},
    {key:'mobilip-mn',value:'mobilip-mn'},
    {key:'modolansrv',value:'modolansrv'},
    {key:'moneyworks',value:'moneyworks'},
    {key:'montage-lm',value:'montage-lm'},
    {key:'moshebeeri',value:'moshebeeri'},
    {key:'movieslate',value:'movieslate'},
    {key:'mpsysrmsvr',value:'mpsysrmsvr'},
    {key:'mqe-broker',value:'mqe-broker'},
    {key:'ms-alerter',value:'ms-alerter'},
    {key:'msfw-array',value:'msfw-array'},
    {key:'msi-cps-rm',value:'msi-cps-rm'},
    {key:'ms-ilm-sts',value:'ms-ilm-sts'},
    {key:'mslingshot',value:'mslingshot'},
    {key:'ms-shuttle',value:'ms-shuttle'},
    {key:'ms-theater',value:'ms-theater'},
    {key:'mstmg-sstp',value:'mstmg-sstp'},
    {key:'multip-msg',value:'multip-msg'},
    {key:'mylex-mapd',value:'mylex-mapd'},
    {key:'mylxamport',value:'mylxamport'},
    {key:'mzca-alert',value:'mzca-alert'},
    {key:'n2h2server',value:'n2h2server'},
    {key:'nameserver',value:'nameserver'},
    {key:'nasmanager',value:'nasmanager'},
    {key:'nati-logos',value:'nati-logos'},
    {key:'navisphere',value:'navisphere'},
    {key:'ndm-server',value:'ndm-server'},
    {key:'ndsconnect',value:'ndsconnect'},
    {key:'nedap-aepu',value:'nedap-aepu'},
    {key:'netbios-ns',value:'netbios-ns'},
    {key:'net-device',value:'net-device'},
    {key:'netiq-ncap',value:'netiq-ncap'},
    {key:'netlabs-lm',value:'netlabs-lm'},
    {key:'netmo-http',value:'netmo-http'},
    {key:'netport-id',value:'netport-id'},
    {key:'netrestore',value:'netrestore'},
    {key:'netrockey6',value:'netrockey6'},
    {key:'netsc-prod',value:'netsc-prod'},
    {key:'netsteward',value:'netsteward'},
    {key:'netsupport',value:'netsupport'},
    {key:'netviewdm1',value:'netviewdm1'},
    {key:'netviewdm2',value:'netviewdm2'},
    {key:'netviewdm3',value:'netviewdm3'},
    {key:'netware-ip',value:'netware-ip'},
    {key:'netx-agent',value:'netx-agent'},
    {key:'nexgen-aux',value:'nexgen-aux'},
    {key:'ng-control',value:'ng-control'},
    {key:'nimspooler',value:'nimspooler'},
    {key:'niobserver',value:'niobserver'},
    {key:'njenet-ssl',value:'njenet-ssl'},
    {key:'nmasoverip',value:'nmasoverip'},
    {key:'nod-client',value:'nod-client'},
    {key:'novell-zen',value:'novell-zen'},
    {key:'nowcontact',value:'nowcontact'},
    {key:'npmp-local',value:'npmp-local'},
    {key:'npqes-test',value:'npqes-test'},
    {key:'nsjtp-ctrl',value:'nsjtp-ctrl'},
    {key:'nsjtp-data',value:'nsjtp-data'},
    {key:'ntlx-video',value:'ntlx-video'},
    {key:'nupaper-ss',value:'nupaper-ss'},
    {key:'nusdp-disc',value:'nusdp-disc'},
    {key:'nuts_bootp',value:'nuts_bootp'},
    {key:'nuts-bootp',value:'nuts-bootp'},
    {key:'nw-license',value:'nw-license'},
    {key:'odette-ftp',value:'odette-ftp'},
    {key:'ogs-client',value:'ogs-client'},
    {key:'ogs-server',value:'ogs-server'},
    {key:'ohmtrigger',value:'ohmtrigger'},
    {key:'omnivision',value:'omnivision'},
    {key:'omscontact',value:'omscontact'},
    {key:'omviserver',value:'omviserver'},
    {key:'onbase-dds',value:'onbase-dds'},
    {key:'onenet-pgn',value:'onenet-pgn'},
    {key:'ontobroker',value:'ontobroker'},
    {key:'opalis-rdv',value:'opalis-rdv'},
    {key:'opendeploy',value:'opendeploy'},
    {key:'openmailns',value:'openmailns'},
    {key:'openwebnet',value:'openwebnet'},
    {key:'oracle-em1',value:'oracle-em1'},
    {key:'oracle-em2',value:'oracle-em2'},
    {key:'oracle-oms',value:'oracle-oms'},
    {key:'oracle-vp1',value:'oracle-vp1'},
    {key:'oracle-vp2',value:'oracle-vp2'},
    {key:'origo-sync',value:'origo-sync'},
    {key:'ortec-disc',value:'ortec-disc'},
    {key:'ovalarmsrv',value:'ovalarmsrv'},
    {key:'ovsam-mgmt',value:'ovsam-mgmt'},
    {key:'owamp-test',value:'owamp-test'},
    {key:'p4p-portal',value:'p4p-portal'},
    {key:'pacerforum',value:'pacerforum'},
    {key:'park-agent',value:'park-agent'},
    {key:'patrol-ism',value:'patrol-ism'},
    {key:'patrolview',value:'patrolview'},
    {key:'pcia-rxp-b',value:'pcia-rxp-b'},
    {key:'pcle-infex',value:'pcle-infex'},
    {key:'pcmail-srv',value:'pcmail-srv'},
    {key:'pcoip-mgmt',value:'pcoip-mgmt'},
    {key:'pcttunnell',value:'pcttunnell'},
    {key:'pgbackrest',value:'pgbackrest'},
    {key:'pgpkey-hkp',value:'pgpkey-hkp'},
    {key:'pharmasoft',value:'pharmasoft'},
    {key:'philips-vc',value:'philips-vc'},
    {key:'phrelaydbg',value:'phrelaydbg'},
    {key:'pjj-player',value:'pjj-player'},
    {key:'pmip6-cntl',value:'pmip6-cntl'},
    {key:'pmip6-data',value:'pmip6-data'},
    {key:'postgresql',value:'postgresql'},
    {key:'powerburst',value:'powerburst'},
    {key:'poweronnud',value:'poweronnud'},
    {key:'ppsuitemsg',value:'ppsuitemsg'},
    {key:'precise-i3',value:'precise-i3'},
    {key:'print-caps',value:'print-caps'},
    {key:'privateark',value:'privateark'},
    {key:'prnrequest',value:'prnrequest'},
    {key:'profilemac',value:'profilemac'},
    {key:'progistics',value:'progistics'},
    {key:'programmar',value:'programmar'},
    {key:'proxima-lm',value:'proxima-lm'},
    {key:'prRegister',value:'prRegister'},
    {key:'psdbserver',value:'psdbserver'},
    {key:'psprserver',value:'psprserver'},
    {key:'pxc-sapxom',value:'pxc-sapxom'},
    {key:'qadmifoper',value:'qadmifoper'},
    {key:'qke-llc-v3',value:'qke-llc-v3'},
    {key:'qsm-remote',value:'qsm-remote'},
    {key:'qsnet-cond',value:'qsnet-cond'},
    {key:'qsnet-nucl',value:'qsnet-nucl'},
    {key:'quantastor',value:'quantastor'},
    {key:'quest-disc',value:'quest-disc'},
    {key:'quicksuite',value:'quicksuite'},
    {key:'qwizcollab',value:'qwizcollab'},
    {key:'radan-http',value:'radan-http'},
    {key:'radec-corp',value:'radec-corp'},
    {key:'radiusdtls',value:'radiusdtls'},
    {key:'rap-listen',value:'rap-listen'},
    {key:'raqmon-pdu',value:'raqmon-pdu'},
    {key:'raw-serial',value:'raw-serial'},
    {key:'rbt-wanopt',value:'rbt-wanopt'},
    {key:'rdc-wh-eos',value:'rdc-wh-eos'},
    {key:'realm-rusd',value:'realm-rusd'},
    {key:'realsecure',value:'realsecure'},
    {key:'recipe-box',value:'recipe-box'},
    {key:'re-mail-ck',value:'re-mail-ck'},
    {key:'remoteburn',value:'remoteburn'},
    {key:'remote-kis',value:'remote-kis'},
    {key:'renderpipe',value:'renderpipe'},
    {key:'resol-vbus',value:'resol-vbus'},
    {key:'retrospect',value:'retrospect'},
    {key:'ricardo-lm',value:'ricardo-lm'},
    {key:'routematch',value:'routematch'},
    {key:'rsqlserver',value:'rsqlserver'},
    {key:'rtcm-sc104',value:'rtcm-sc104'},
    {key:'rt-event-s',value:'rt-event-s'},
    {key:'rtps-dd-mt',value:'rtps-dd-mt'},
    {key:'rtps-dd-ut',value:'rtps-dd-ut'},
    {key:'s1-control',value:'s1-control'},
    {key:'safarimenu',value:'safarimenu'},
    {key:'safetynetp',value:'safetynetp'},
    {key:'santak-ups',value:'santak-ups'},
    {key:'savagesoft',value:'savagesoft'},
    {key:'scanstat-1',value:'scanstat-1'},
    {key:'scoop-sftp',value:'scoop-sftp'},
    {key:'sco-sysmgr',value:'sco-sysmgr'},
    {key:'scp-config',value:'scp-config'},
    {key:'screencast',value:'screencast'},
    {key:'scriptview',value:'scriptview'},
    {key:'sd-request',value:'sd-request'},
    {key:'seagulllms',value:'seagulllms'},
    {key:'serverdocs',value:'serverdocs'},
    {key:'serverwsd2',value:'serverwsd2'},
    {key:'sfs-config',value:'sfs-config'},
    {key:'sgi-arrayd',value:'sgi-arrayd'},
    {key:'sgi-dmfmgr',value:'sgi-dmfmgr'},
    {key:'sgmp-traps',value:'sgmp-traps'},
    {key:'shipsinvit',value:'shipsinvit'},
    {key:'shivasound',value:'shivasound'},
    {key:'shockwave2',value:'shockwave2'},
    {key:'shots-sync',value:'shots-sync'},
    {key:'shrinkwrap',value:'shrinkwrap'},
    {key:'siemensgsm',value:'siemensgsm'},
    {key:'sigma-port',value:'sigma-port'},
    {key:'signet-ctf',value:'signet-ctf'},
    {key:'silhouette',value:'silhouette'},
    {key:'simon-disc',value:'simon-disc'},
    {key:'sironaxray',value:'sironaxray'},
    {key:'sitaramgmt',value:'sitaramgmt'},
    {key:'skynetflow',value:'skynetflow'},
    {key:'slp-notify',value:'slp-notify'},
    {key:'slslavemon',value:'slslavemon'},
    {key:'smpnameres',value:'smpnameres'},
    {key:'sms-rcinfo',value:'sms-rcinfo'},
    {key:'snapenetio',value:'snapenetio'},
    {key:'snip-slave',value:'snip-slave'},
    {key:'soagateway',value:'soagateway'},
    {key:'solera-lpn',value:'solera-lpn'},
    {key:'s-openmail',value:'s-openmail'},
    {key:'sor-update',value:'sor-update'},
    {key:'sossd-disc',value:'sossd-disc'},
    {key:'spcsdlobby',value:'spcsdlobby'},
    {key:'speedtrace',value:'speedtrace'},
    {key:'spincrisis',value:'spincrisis'},
    {key:'splashsync',value:'splashsync'},
    {key:'split-ping',value:'split-ping'},
    {key:'spl-itunes',value:'spl-itunes'},
    {key:'spr-itunes',value:'spr-itunes'},
    {key:'sps-tunnel',value:'sps-tunnel'},
    {key:'spw-dialer',value:'spw-dialer'},
    {key:'ssdispatch',value:'ssdispatch'},
    {key:'ssp-client',value:'ssp-client'},
    {key:'ssslic-mgr',value:'ssslic-mgr'},
    {key:'ssslog-mgr',value:'ssslog-mgr'},
    {key:'starschool',value:'starschool'},
    {key:'stonefalls',value:'stonefalls'},
    {key:'streettalk',value:'streettalk'},
    {key:'submission',value:'submission'},
    {key:'sunfm-port',value:'sunfm-port'},
    {key:'sun-mc-grp',value:'sun-mc-grp'},
    {key:'sun-sr-jms',value:'sun-sr-jms'},
    {key:'sun-sr-jmx',value:'sun-sr-jmx'},
    {key:'SunVTS-RMI',value:'SunVTS-RMI'},
    {key:'surveillus',value:'surveillus'},
    {key:'surveyinst',value:'surveyinst'},
    {key:'svdrp-disc',value:'svdrp-disc'},
    {key:'svnetworks',value:'svnetworks'},
    {key:'swldy-sias',value:'swldy-sias'},
    {key:'swtp-port1',value:'swtp-port1'},
    {key:'swtp-port2',value:'swtp-port2'},
    {key:'syam-agent',value:'syam-agent'},
    {key:'sybase-tds',value:'sybase-tds'},
    {key:'synclavier',value:'synclavier'},
    {key:'syncserver',value:'syncserver'},
    {key:'synel-data',value:'synel-data'},
    {key:'sysinfo-sp',value:'sysinfo-sp'},
    {key:'syslog-tls',value:'syslog-tls'},
    {key:'sysscanner',value:'sysscanner'},
    {key:'t1distproc',value:'t1distproc'},
    {key:'t5-straton',value:'t5-straton'},
    {key:'talon-disc',value:'talon-disc'},
    {key:'tarantella',value:'tarantella'},
    {key:'td-postman',value:'td-postman'},
    {key:'td-replica',value:'td-replica'},
    {key:'td-service',value:'td-service'},
    {key:'tec5-sdctp',value:'tec5-sdctp'},
    {key:'telefinder',value:'telefinder'},
    {key:'telnetcpcd',value:'telnetcpcd'},
    {key:'telops-lmd',value:'telops-lmd'},
    {key:'tera-fsmgr',value:'tera-fsmgr'},
    {key:'terminaldb',value:'terminaldb'},
    {key:'tftp-mcast',value:'tftp-mcast'},
    {key:'tgcconnect',value:'tgcconnect'},
    {key:'tivoli-npm',value:'tivoli-npm'},
    {key:'tivo-music',value:'tivo-music'},
    {key:'tl1-telnet',value:'tl1-telnet'},
    {key:'tmophl7mts',value:'tmophl7mts'},
    {key:'tns-server',value:'tns-server'},
    {key:'touch-able',value:'touch-able'},
    {key:'traceroute',value:'traceroute'},
    {key:'treehopper',value:'treehopper'},
    {key:'trim-event',value:'trim-event'},
    {key:'triomotion',value:'triomotion'},
    {key:'tr-rsrb-p1',value:'tr-rsrb-p1'},
    {key:'tr-rsrb-p2',value:'tr-rsrb-p2'},
    {key:'tr-rsrb-p3',value:'tr-rsrb-p3'},
    {key:'ttcheckers',value:'ttcheckers'},
    {key:'ttntspauto',value:'ttntspauto'},
    {key:'ttp4daemon',value:'ttp4daemon'},
    {key:'tuneranger',value:'tuneranger'},
    {key:'twamp-test',value:'twamp-test'},
    {key:'ubertragen',value:'ubertragen'},
    {key:'udrawgraph',value:'udrawgraph'},
    {key:'unicontrol',value:'unicontrol'},
    {key:'unifyadmin',value:'unifyadmin'},
    {key:'upnotifyps',value:'upnotifyps'},
    {key:'ups-engine',value:'ups-engine'},
    {key:'usp-agt-ws',value:'usp-agt-ws'},
    {key:'usp-ctr-ws',value:'usp-ctr-ws'},
    {key:'vacdsm-app',value:'vacdsm-app'},
    {key:'vacdsm-sws',value:'vacdsm-sws'},
    {key:'valisys-lm',value:'valisys-lm'},
    {key:'va-pacbase',value:'va-pacbase'},
    {key:'varadero-0',value:'varadero-0'},
    {key:'varadero-1',value:'varadero-1'},
    {key:'varadero-2',value:'varadero-2'},
    {key:'ve-decoder',value:'ve-decoder'},
    {key:'ve-encoder',value:'ve-encoder'},
    {key:'vergencecm',value:'vergencecm'},
    {key:'videobeans',value:'videobeans'},
    {key:'vipera-ssl',value:'vipera-ssl'},
    {key:'virprot-lm',value:'virprot-lm'},
    {key:'visweather',value:'visweather'},
    {key:'vmware-fdm',value:'vmware-fdm'},
    {key:'vnwk-prapi',value:'vnwk-prapi'},
    {key:'voxelstorm',value:'voxelstorm'},
    {key:'vrcommerce',value:'vrcommerce'},
    {key:'vsnm-agent',value:'vsnm-agent'},
    {key:'wakeywakey',value:'wakeywakey'},
    {key:'wap-vcal-s',value:'wap-vcal-s'},
    {key:'wbem-https',value:'wbem-https'},
    {key:'wcr-remlib',value:'wcr-remlib'},
    {key:'webemshttp',value:'webemshttp'},
    {key:'webmachine',value:'webmachine'},
    {key:'webobjects',value:'webobjects'},
    {key:'whosockami',value:'whosockami'},
    {key:'wimaxasncp',value:'wimaxasncp'},
    {key:'winpharaoh',value:'winpharaoh'},
    {key:'witapvoice',value:'witapvoice'},
    {key:'wsdl-event',value:'wsdl-event'},
    {key:'wsm-server',value:'wsm-server'},
    {key:'wssauthsvc',value:'wssauthsvc'},
    {key:'wtc-heleos',value:'wtc-heleos'},
    {key:'wv-csp-sms',value:'wv-csp-sms'},
    {key:'x2-control',value:'x2-control'},
    {key:'x-bone-api',value:'x-bone-api'},
    {key:'x-bone-ctl',value:'x-bone-ctl'},
    {key:'xmlblaster',value:'xmlblaster'},
    {key:'xn-control',value:'xn-control'},
    {key:'xsanclient',value:'xsanclient'},
    {key:'xsanserver',value:'xsanserver'},
    {key:'xsansystem',value:'xsansystem'},
    {key:'xserveraid',value:'xserveraid'},
    {key:'xw-control',value:'xw-control'},
    {key:'xyplex-mux',value:'xyplex-mux'},
    {key:'zephyr-clt',value:'zephyr-clt'},
    {key:'zephyr-srv',value:'zephyr-srv'},
    {key:'zieto-sock',value:'zieto-sock'},
    {key:'zigbee-ips',value:'zigbee-ips'},

    {key:'3m-image-lm',value:'3m-image-lm'},
    {key:'4-tieropmgw',value:'4-tieropmgw'},
    {key:'802-11-iapp',value:'802-11-iapp'},
    {key:'a21-an-1xbs',value:'a21-an-1xbs'},
    {key:'a25-fap-fgw',value:'a25-fap-fgw'},
    {key:'a26-fap-fgw',value:'a26-fap-fgw'},
    {key:'a27-ran-ran',value:'a27-ran-ran'},
    {key:'abcsoftware',value:'abcsoftware'},
    {key:'accountedge',value:'accountedge'},
    {key:'acctopus-cc',value:'acctopus-cc'},
    {key:'acctopus-st',value:'acctopus-st'},
    {key:'acl-manager',value:'acl-manager'},
    {key:'acmaint_dbd',value:'acmaint_dbd'},
    {key:'acmaint-dbd',value:'acmaint-dbd'},
    {key:'acp-conduit',value:'acp-conduit'},
    {key:'actifio-c2c',value:'actifio-c2c'},
    {key:'actionitems',value:'actionitems'},
    {key:'addressbook',value:'addressbook'},
    {key:'adpro-setup',value:'adpro-setup'},
    {key:'adtech-test',value:'adtech-test'},
    {key:'afs3-errors',value:'afs3-errors'},
    {key:'afs3-rmtsys',value:'afs3-rmtsys'},
    {key:'afs3-update',value:'afs3-update'},
    {key:'afs3-volser',value:'afs3-volser'},
    {key:'aimpp-hello',value:'aimpp-hello'},
    {key:'alaris-disc',value:'alaris-disc'},
    {key:'alljoyn-mcm',value:'alljoyn-mcm'},
    {key:'alljoyn-stm',value:'alljoyn-stm'},
    {key:'alta-ana-lm',value:'alta-ana-lm'},
    {key:'ami-control',value:'ami-control'},
    {key:'amx-weblinx',value:'amx-weblinx'},
    {key:'ans-console',value:'ans-console'},
    {key:'ansoft-lm-1',value:'ansoft-lm-1'},
    {key:'ansoft-lm-2',value:'ansoft-lm-2'},
    {key:'apertus-ldp',value:'apertus-ldp'},
    {key:'apollo-data',value:'apollo-data'},
    {key:'appelezvous',value:'appelezvous'},
    {key:'apsolab-col',value:'apsolab-col'},
    {key:'apsolab-rpc',value:'apsolab-rpc'},
    {key:'apsolab-tag',value:'apsolab-tag'},
    {key:'apx500api-1',value:'apx500api-1'},
    {key:'apx500api-2',value:'apx500api-2'},
    {key:'ardus-mtrns',value:'ardus-mtrns'},
    {key:'ariliamulti',value:'ariliamulti'},
    {key:'armi-server',value:'armi-server'},
    {key:'aroundsound',value:'aroundsound'},
    {key:'aspentec-lm',value:'aspentec-lm'},
    {key:'asprovatalk',value:'asprovatalk'},
    {key:'assuria-ins',value:'assuria-ins'},
    {key:'assuria-slm',value:'assuria-slm'},
    {key:'atsc-mh-ssc',value:'atsc-mh-ssc'},
    {key:'aurora-cmgr',value:'aurora-cmgr'},
    {key:'autocuetime',value:'autocuetime'},
    {key:'autodesk-lm',value:'autodesk-lm'},
    {key:'avantageb2b',value:'avantageb2b'},
    {key:'avatars-sec',value:'avatars-sec'},
    {key:'axon-tunnel',value:'axon-tunnel'},
    {key:'backroomnet',value:'backroomnet'},
    {key:'bbn-rcc-mon',value:'bbn-rcc-mon'},
    {key:'bctp-server',value:'bctp-server'},
    {key:'beacon-port',value:'beacon-port'},
    {key:'beatsdirect',value:'beatsdirect'},
    {key:'bfd-control',value:'bfd-control'},
    {key:'bintec-capi',value:'bintec-capi'},
    {key:'bintec-tapi',value:'bintec-tapi'},
    {key:'bluevertise',value:'bluevertise'},
    {key:'blwnkl-port',value:'blwnkl-port'},
    {key:'bmc-net-adm',value:'bmc-net-adm'},
    {key:'bmc-net-svc',value:'bmc-net-svc'},
    {key:'bmc-perf-sd',value:'bmc-perf-sd'},
    {key:'bnt-manager',value:'bnt-manager'},
    {key:'boe-filesvr',value:'boe-filesvr'},
    {key:'boe-pagesvr',value:'boe-pagesvr'},
    {key:'boldsoft-lm',value:'boldsoft-lm'},
    {key:'booked-sync',value:'booked-sync'},
    {key:'boosterware',value:'boosterware'},
    {key:'borland-dsj',value:'borland-dsj'},
    {key:'boutfitness',value:'boutfitness'},
    {key:'bpjava-msvc',value:'bpjava-msvc'},
    {key:'brcd-vr-req',value:'brcd-vr-req'},
    {key:'brski-proxy',value:'brski-proxy'},
    {key:'bullant-rap',value:'bullant-rap'},
    {key:'ca-audit-da',value:'ca-audit-da'},
    {key:'ca-audit-ds',value:'ca-audit-ds'},
    {key:'callwaveiam',value:'callwaveiam'},
    {key:'cambertx-lm',value:'cambertx-lm'},
    {key:'canex-watch',value:'canex-watch'},
    {key:'canit_store',value:'canit_store'},
    {key:'canit-store',value:'canit-store'},
    {key:'can-nds-ssl',value:'can-nds-ssl'},
    {key:'canon-bjnp1',value:'canon-bjnp1'},
    {key:'canon-bjnp2',value:'canon-bjnp2'},
    {key:'canon-bjnp3',value:'canon-bjnp3'},
    {key:'canon-bjnp4',value:'canon-bjnp4'},
    {key:'capfast-lmd',value:'capfast-lmd'},
    {key:'capioverlan',value:'capioverlan'},
    {key:'capwap-data',value:'capwap-data'},
    {key:'cart-o-rama',value:'cart-o-rama'},
    {key:'casanswmgmt',value:'casanswmgmt'},
    {key:'castorproxy',value:'castorproxy'},
    {key:'cc-tracking',value:'cc-tracking'},
    {key:'cgi-starapi',value:'cgi-starapi'},
    {key:'chimera-hwm',value:'chimera-hwm'},
    {key:'c-h-it-port',value:'c-h-it-port'},
    {key:'chromagrafx',value:'chromagrafx'},
    {key:'cindycollab',value:'cindycollab'},
    {key:'cinegrfx-lm',value:'cinegrfx-lm'},
    {key:'cisco-ipsla',value:'cisco-ipsla'},
    {key:'citrixadmin',value:'citrixadmin'},
    {key:'citrix-rtmp',value:'citrix-rtmp'},
    {key:'client-ctrl',value:'client-ctrl'},
    {key:'cnrprotocol',value:'cnrprotocol'},
    {key:'commandport',value:'commandport'},
    {key:'commonspace',value:'commonspace'},
    {key:'compressnet',value:'compressnet'},
    {key:'config-port',value:'config-port'},
    {key:'core-rd-tls',value:'core-rd-tls'},
    {key:'cpnotebook2',value:'cpnotebook2'},
    {key:'cruise-enum',value:'cruise-enum'},
    {key:'cryptoadmin',value:'cryptoadmin'},
    {key:'cs-auth-svr',value:'cs-auth-svr'},
    {key:'csd-monitor',value:'csd-monitor'},
    {key:'csoftragent',value:'csoftragent'},
    {key:'cspmlockmgr',value:'cspmlockmgr'},
    {key:'cs-services',value:'cs-services'},
    {key:'ctdhercules',value:'ctdhercules'},
    {key:'cti-redwood',value:'cti-redwood'},
    {key:'cw-powertap',value:'cw-powertap'},
    {key:'cybro-a-bus',value:'cybro-a-bus'},
    {key:'cymtec-port',value:'cymtec-port'},
    {key:'d2000kernel',value:'d2000kernel'},
    {key:'datasurfsrv',value:'datasurfsrv'},
    {key:'dataturbine',value:'dataturbine'},
    {key:'dberegister',value:'dberegister'},
    {key:'db-lsp-disc',value:'db-lsp-disc'},
    {key:'dcsl-backup',value:'dcsl-backup'},
    {key:'dec-mbadmin',value:'dec-mbadmin'},
    {key:'dell-soo-ds',value:'dell-soo-ds'},
    {key:'derby-repli',value:'derby-repli'},
    {key:'desktop-dna',value:'desktop-dna'},
    {key:'device-info',value:'device-info'},
    {key:'dhct-alerts',value:'dhct-alerts'},
    {key:'dhct-status',value:'dhct-status'},
    {key:'dialog-port',value:'dialog-port'},
    {key:'diamondport',value:'diamondport'},
    {key:'dict-lookup',value:'dict-lookup'},
    {key:'digital-vrc',value:'digital-vrc'},
    {key:'direcpc-dll',value:'direcpc-dll'},
    {key:'directplay8',value:'directplay8'},
    {key:'directvdata',value:'directvdata'},
    {key:'directv-web',value:'directv-web'},
    {key:'dist-opencl',value:'dist-opencl'},
    {key:'divelogsync',value:'divelogsync'},
    {key:'dkmessenger',value:'dkmessenger'},
    {key:'dls-monitor',value:'dls-monitor'},
    {key:'dmaf-caster',value:'dmaf-caster'},
    {key:'dmaf-server',value:'dmaf-server'},
    {key:'dmdocbroker',value:'dmdocbroker'},
    {key:'dn6-nlm-aud',value:'dn6-nlm-aud'},
    {key:'dn6-smm-red',value:'dn6-smm-red'},
    {key:'dns-llq-tls',value:'dns-llq-tls'},
    {key:'doceri-view',value:'doceri-view'},
    {key:'dots-signal',value:'dots-signal'},
    {key:'dsom-server',value:'dsom-server'},
    {key:'dsx_monitor',value:'dsx_monitor'},
    {key:'dsx-monitor',value:'dsx-monitor'},
    {key:'dtag-ste-sb',value:'dtag-ste-sb'},
    {key:'dta-systems',value:'dta-systems'},
    {key:'dwmsgserver',value:'dwmsgserver'},
    {key:'dyna-access',value:'dyna-access'},
    {key:'dyniplookup',value:'dyniplookup'},
    {key:'dzoglserver',value:'dzoglserver'},
    {key:'eapol-relay',value:'eapol-relay'},
    {key:'edb-server1',value:'edb-server1'},
    {key:'edb-server2',value:'edb-server2'},
    {key:'edi_service',value:'edi_service'},
    {key:'edm-manager',value:'edm-manager'},
    {key:'efkon-elite',value:'efkon-elite'},
    {key:'ema-sent-lm',value:'ema-sent-lm'},
    {key:'emc-gateway',value:'emc-gateway'},
    {key:'emprise-lls',value:'emprise-lls'},
    {key:'emprise-lsc',value:'emprise-lsc'},
    {key:'emp-server1',value:'emp-server1'},
    {key:'emp-server2',value:'emp-server2'},
    {key:'enlabel-dpl',value:'enlabel-dpl'},
    {key:'entextnetwk',value:'entextnetwk'},
    {key:'entrust-ash',value:'entrust-ash'},
    {key:'entrust-sps',value:'entrust-sps'},
    {key:'entrusttime',value:'entrusttime'},
    {key:'ept-machine',value:'ept-machine'},
    {key:'eristwoguns',value:'eristwoguns'},
    {key:'eserver-pap',value:'eserver-pap'},
    {key:'esnm-zoning',value:'esnm-zoning'},
    {key:'espeasy-p2p',value:'espeasy-p2p'},
    {key:'espeech-rtp',value:'espeech-rtp'},
    {key:'esps-portal',value:'esps-portal'},
    {key:'etc-control',value:'etc-control'},
    {key:'etcd-client',value:'etcd-client'},
    {key:'etcd-server',value:'etcd-server'},
    {key:'eventserver',value:'eventserver'},
    {key:'ev-services',value:'ev-services'},
    {key:'ewalletsync',value:'ewalletsync'},
    {key:'ew-disc-cmd',value:'ew-disc-cmd'},
    {key:'ewinstaller',value:'ewinstaller'},
    {key:'exoline-tcp',value:'exoline-tcp'},
    {key:'exoline-udp',value:'exoline-udp'},
    {key:'ezmeeting-2',value:'ezmeeting-2'},
    {key:'fac-restore',value:'fac-restore'},
    {key:'fazzt-admin',value:'fazzt-admin'},
    {key:'filenet-nch',value:'filenet-nch'},
    {key:'filenet-pch',value:'filenet-pch'},
    {key:'filenet-rmi',value:'filenet-rmi'},
    {key:'filenet-rpc',value:'filenet-rpc'},
    {key:'filenet-tms',value:'filenet-tms'},
    {key:'filex-lport',value:'filex-lport'},
    {key:'firstcall42',value:'firstcall42'},
    {key:'fjicl-tep-a',value:'fjicl-tep-a'},
    {key:'fjicl-tep-b',value:'fjicl-tep-b'},
    {key:'fjicl-tep-c',value:'fjicl-tep-c'},
    {key:'fjsv-gssagt',value:'fjsv-gssagt'},
    {key:'flirtmitmir',value:'flirtmitmir'},
    {key:'flukeserver',value:'flukeserver'},
    {key:'fox-skytale',value:'fox-skytale'},
    {key:'fujitsu-dev',value:'fujitsu-dev'},
    {key:'fujitsu-dtc',value:'fujitsu-dtc'},
    {key:'funk-logger',value:'funk-logger'},
    {key:'gdrive-sync',value:'gdrive-sync'},
    {key:'generalsync',value:'generalsync'},
    {key:'getty-focus',value:'getty-focus'},
    {key:'gforce-ssmp',value:'gforce-ssmp'},
    {key:'giga-pocket',value:'giga-pocket'},
    {key:'grillezvous',value:'grillezvous'},
    {key:'groovesquid',value:'groovesquid'},
    {key:'gs-realtime',value:'gs-realtime'},
    {key:'gtp-control',value:'gtp-control'},
    {key:'h248-binary',value:'h248-binary'},
    {key:'health-trap',value:'health-trap'},
    {key:'hexxorecore',value:'hexxorecore'},
    {key:'hhb-gateway',value:'hhb-gateway'},
    {key:'homeconnect',value:'homeconnect'},
    {key:'hp-hcip-gwy',value:'hp-hcip-gwy'},
    {key:'hp-nnm-data',value:'hp-nnm-data'},
    {key:'hppronetman',value:'hppronetman'},
    {key:'hpr-toollnx',value:'hpr-toollnx'},
    {key:'hpr-toolwin',value:'hpr-toolwin'},
    {key:'hp-san-mgmt',value:'hp-san-mgmt'},
    {key:'hp-webadmin',value:'hp-webadmin'},
    {key:'hp-webqosdb',value:'hp-webqosdb'},
    {key:'hrd-ns-disc',value:'hrd-ns-disc'},
    {key:'hyperstream',value:'hyperstream'},
    {key:'ias-session',value:'ias-session'},
    {key:'iberiagames',value:'iberiagames'},
    {key:'ibm-rsyscon',value:'ibm-rsyscon'},
    {key:'iccrushmore',value:'iccrushmore'},
    {key:'ice-srouter',value:'ice-srouter'},
    {key:'icg-iprelay',value:'icg-iprelay'},
    {key:'ichalkboard',value:'ichalkboard'},
    {key:'icontrolbox',value:'icontrolbox'},
    {key:'iec-104-sec',value:'iec-104-sec'},
    {key:'incognitorv',value:'incognitorv'},
    {key:'index-pc-wb',value:'index-pc-wb'},
    {key:'indigo-vbcp',value:'indigo-vbcp'},
    {key:'indigo-vrmi',value:'indigo-vrmi'},
    {key:'informacast',value:'informacast'},
    {key:'innosys-acl',value:'innosys-acl'},
    {key:'insitu-conf',value:'insitu-conf'},
    {key:'instl_bootc',value:'instl_bootc'},
    {key:'instl_boots',value:'instl_boots'},
    {key:'instl-bootc',value:'instl-bootc'},
    {key:'instl-boots',value:'instl-boots'},
    {key:'intecom-ps1',value:'intecom-ps1'},
    {key:'intecom-ps2',value:'intecom-ps2'},
    {key:'intecourier',value:'intecourier'},
    {key:'integra-sme',value:'integra-sme'},
    {key:'intermapper',value:'intermapper'},
    {key:'interserver',value:'interserver'},
    {key:'invision-ag',value:'invision-ag'},
    {key:'ionixnetmon',value:'ionixnetmon'},
    {key:'ipsec-nat-t',value:'ipsec-nat-t'},
    {key:'ir-hvac-000',value:'ir-hvac-000'},
    {key:'irt-sharing',value:'irt-sharing'},
    {key:'iso-tsap-c2',value:'iso-tsap-c2'},
    {key:'ita-manager',value:'ita-manager'},
    {key:'itis-device',value:'itis-device'},
    {key:'itm-mcell-s',value:'itm-mcell-s'},
    {key:'itm-mcell-u',value:'itm-mcell-u'},
    {key:'itv-control',value:'itv-control'},
    {key:'itwo-server',value:'itwo-server'},
    {key:'ivcollector',value:'ivcollector'},
    {key:'ivecon-port',value:'ivecon-port'},
    {key:'jtag-server',value:'jtag-server'},
    {key:'jvl-mactalk',value:'jvl-mactalk'},
    {key:'jwalkserver',value:'jwalkserver'},
    {key:'kastenxpipe',value:'kastenxpipe'},
    {key:'kca-service',value:'kca-service'},
    {key:'kerberos-iv',value:'kerberos-iv'},
    {key:'l2c-control',value:'l2c-control'},
    {key:'l-acoustics',value:'l-acoustics'},
    {key:'lanrevagent',value:'lanrevagent'},
    {key:'lansurveyor',value:'lansurveyor'},
    {key:'lbc-control',value:'lbc-control'},
    {key:'lbc-measure',value:'lbc-measure'},
    {key:'lds-distrib',value:'lds-distrib'},
    {key:'lecroy-vicp',value:'lecroy-vicp'},
    {key:'llrp-secure',value:'llrp-secure'},
    {key:'lonewolf-lm',value:'lonewolf-lm'},
    {key:'lsys-camera',value:'lsys-camera'},
    {key:'lxi-evntsvc',value:'lxi-evntsvc'},
    {key:'m2mservices',value:'m2mservices'},
    {key:'macfoh-data',value:'macfoh-data'},
    {key:'maincontrol',value:'maincontrol'},
    {key:'mainsoft-lm',value:'mainsoft-lm'},
    {key:'manage-exec',value:'manage-exec'},
    {key:'manyone-xml',value:'manyone-xml'},
    {key:'matrix_vnet',value:'matrix_vnet'},
    {key:'matrix-vnet',value:'matrix-vnet'},
    {key:'maxim-asics',value:'maxim-asics'},
    {key:'mcs-mailsvr',value:'mcs-mailsvr'},
    {key:'mctet-jserv',value:'mctet-jserv'},
    {key:'mdbs_daemon',value:'mdbs_daemon'},
    {key:'mdbs-daemon',value:'mdbs-daemon'},
    {key:'media-agent',value:'media-agent'},
    {key:'mediaboard1',value:'mediaboard1'},
    {key:'med-net-svc',value:'med-net-svc'},
    {key:'megaco-h248',value:'megaco-h248'},
    {key:'mentaclient',value:'mentaclient'},
    {key:'mentaserver',value:'mentaserver'},
    {key:'mercurydock',value:'mercurydock'},
    {key:'mesavistaco',value:'mesavistaco'},
    {key:'messageasap',value:'messageasap'},
    {key:'metaconsole',value:'metaconsole'},
    {key:'metaedit-mu',value:'metaedit-mu'},
    {key:'metaedit-se',value:'metaedit-se'},
    {key:'metaedit-ws',value:'metaedit-ws'},
    {key:'metrics-pas',value:'metrics-pas'},
    {key:'miami-bcast',value:'miami-bcast'},
    {key:'mieleathome',value:'mieleathome'},
    {key:'miles-apart',value:'miles-apart'},
    {key:'mindfilesys',value:'mindfilesys'},
    {key:'minotaur-sa',value:'minotaur-sa'},
    {key:'miroconnect',value:'miroconnect'},
    {key:'miteksys-lm',value:'miteksys-lm'},
    {key:'MobilitySrv',value:'MobilitySrv'},
    {key:'moldflow-lm',value:'moldflow-lm'},
    {key:'mpc-lifenet',value:'mpc-lifenet'},
    {key:'mppolicy-v5',value:'mppolicy-v5'},
    {key:'msft-gc-ssl',value:'msft-gc-ssl'},
    {key:'msolap-ptp2',value:'msolap-ptp2'},
    {key:'ms-sideshow',value:'ms-sideshow'},
    {key:'ms-sna-base',value:'ms-sna-base'},
    {key:'ms-v-worlds',value:'ms-v-worlds'},
    {key:'mtcevrunqss',value:'mtcevrunqss'},
    {key:'musiconline',value:'musiconline'},
    {key:'mysql-proxy',value:'mysql-proxy'},
    {key:'mzca-action',value:'mzca-action'},
    {key:'na-localise',value:'na-localise'},
    {key:'nasunifiler',value:'nasunifiler'},
    {key:'nati-svrloc',value:'nati-svrloc'},
    {key:'nattyserver',value:'nattyserver'},
    {key:'ncbroadcast',value:'ncbroadcast'},
    {key:'ndl-ahp-svc',value:'ndl-ahp-svc'},
    {key:'nesh-broker',value:'nesh-broker'},
    {key:'net2display',value:'net2display'},
    {key:'netapp-sync',value:'netapp-sync'},
    {key:'netbios-dgm',value:'netbios-dgm'},
    {key:'netbios-ssn',value:'netbios-ssn'},
    {key:'netbookmark',value:'netbookmark'},
    {key:'netboot-pxe',value:'netboot-pxe'},
    {key:'netconf-ssh',value:'netconf-ssh'},
    {key:'netconf-tls',value:'netconf-tls'},
    {key:'netiq-endpt',value:'netiq-endpt'},
    {key:'netiq-voipa',value:'netiq-voipa'},
    {key:'netobjects1',value:'netobjects1'},
    {key:'netobjects2',value:'netobjects2'},
    {key:'netopia-vo1',value:'netopia-vo1'},
    {key:'netopia-vo2',value:'netopia-vo2'},
    {key:'netopia-vo3',value:'netopia-vo3'},
    {key:'netopia-vo4',value:'netopia-vo4'},
    {key:'netopia-vo5',value:'netopia-vo5'},
    {key:'netrix-sftm',value:'netrix-sftm'},
    {key:'netspeak-cs',value:'netspeak-cs'},
    {key:'netspeak-is',value:'netspeak-is'},
    {key:'net-steward',value:'net-steward'},
    {key:'netsupport2',value:'netsupport2'},
    {key:'netvu-video',value:'netvu-video'},
    {key:'netware-csp',value:'netware-csp'},
    {key:'networklens',value:'networklens'},
    {key:'netxms-mgmt',value:'netxms-mgmt'},
    {key:'netxms-sync',value:'netxms-sync'},
    {key:'netx-server',value:'netx-server'},
    {key:'ngr-keydist',value:'ngr-keydist'},
    {key:'nm-assessor',value:'nm-assessor'},
    {key:'nmea-onenet',value:'nmea-onenet'},
    {key:'notify_srvr',value:'notify_srvr'},
    {key:'notify-srvr',value:'notify-srvr'},
    {key:'novar-alarm',value:'novar-alarm'},
    {key:'novar-dbase',value:'novar-dbase'},
    {key:'nssagentmgr',value:'nssagentmgr'},
    {key:'nssalertmgr',value:'nssalertmgr'},
    {key:'nss-routing',value:'nss-routing'},
    {key:'ntz-tracker',value:'ntz-tracker'},
    {key:'odabsharing',value:'odabsharing'},
    {key:'odbcpathway',value:'odbcpathway'},
    {key:'odette-ftps',value:'odette-ftps'},
    {key:'odn-castraq',value:'odn-castraq'},
    {key:'ofocus-conf',value:'ofocus-conf'},
    {key:'ofocus-sync',value:'ofocus-sync'},
    {key:'oma-dcdocbs',value:'oma-dcdocbs'},
    {key:'omstopology',value:'omstopology'},
    {key:'onenet-info',value:'onenet-info'},
    {key:'oob-ws-http',value:'oob-ws-http'},
    {key:'openmailpxy',value:'openmailpxy'},
    {key:'opswmanager',value:'opswmanager'},
    {key:'optima-vnet',value:'optima-vnet'},
    {key:'optiwave-lm',value:'optiwave-lm'},
    {key:'optocontrol',value:'optocontrol'},
    {key:'optohost002',value:'optohost002'},
    {key:'optohost003',value:'optohost003'},
    {key:'optohost004',value:'optohost004'},
    {key:'oraclenames',value:'oraclenames'},
    {key:'osm-appsrvr',value:'osm-appsrvr'},
    {key:'paging-port',value:'paging-port'},
    {key:'palcom-disc',value:'palcom-disc'},
    {key:'parabay-p2p',value:'parabay-p2p'},
    {key:'parsec-game',value:'parsec-game'},
    {key:'parsec-peer',value:'parsec-peer'},
    {key:'patrol-coll',value:'patrol-coll'},
    {key:'patrol-snmp',value:'patrol-snmp'},
    {key:'paycash-wbp',value:'paycash-wbp'},
    {key:'pcmk-remote',value:'pcmk-remote'},
    {key:'pconnectmgr',value:'pconnectmgr'},
    {key:'pcsync-http',value:'pcsync-http'},
    {key:'pegasus-ctl',value:'pegasus-ctl'},
    {key:'pentbox-sim',value:'pentbox-sim'},
    {key:'permabit-cs',value:'permabit-cs'},
    {key:'pgpkey-http',value:'pgpkey-http'},
    {key:'pgpkey-ldap',value:'pgpkey-ldap'},
    {key:'phoenix-rpc',value:'phoenix-rpc'},
    {key:'phonex-port',value:'phonex-port'},
    {key:'photoparata',value:'photoparata'},
    {key:'pim-rp-disc',value:'pim-rp-disc'},
    {key:'pipe_server',value:'pipe_server'},
    {key:'pipe-server',value:'pipe-server'},
    {key:'pixelpusher',value:'pixelpusher'},
    {key:'plysrv-http',value:'plysrv-http'},
    {key:'p-net-local',value:'p-net-local'},
    {key:'powerschool',value:'powerschool'},
    {key:'pq-lic-mgmt',value:'pq-lic-mgmt'},
    {key:'prchat-user',value:'prchat-user'},
    {key:'precise-sft',value:'precise-sft'},
    {key:'precise-vip',value:'precise-vip'},
    {key:'primaserver',value:'primaserver'},
    {key:'privatechat',value:'privatechat'},
    {key:'privatewire',value:'privatewire'},
    {key:'proactivate',value:'proactivate'},
    {key:'productinfo',value:'productinfo'},
    {key:'profinet-cm',value:'profinet-cm'},
    {key:'profinet-rt',value:'profinet-rt'},
    {key:'prospero-np',value:'prospero-np'},
    {key:'pstmailsync',value:'pstmailsync'},
    {key:'ptp-general',value:'ptp-general'},
    {key:'pulsonixnls',value:'pulsonixnls'},
    {key:'pxc-splr-ft',value:'pxc-splr-ft'},
    {key:'pxc-spvr-ft',value:'pxc-spvr-ft'},
    {key:'qadmifevent',value:'qadmifevent'},
    {key:'qdb2service',value:'qdb2service'},
    {key:'qpasa-agent',value:'qpasa-agent'},
    {key:'qsnet-trans',value:'qsnet-trans'},
    {key:'quartus-tcl',value:'quartus-tcl'},
    {key:'quest-agent',value:'quest-agent'},
    {key:'questnotify',value:'questnotify'},
    {key:'quest-vista',value:'quest-vista'},
    {key:'radius-acct',value:'radius-acct'},
    {key:'radmin-port',value:'radmin-port'},
    {key:'radware-rpm',value:'radware-rpm'},
    {key:'rapidmq-reg',value:'rapidmq-reg'},
    {key:'rap-service',value:'rap-service'},
    {key:'rdmnet-ctrl',value:'rdmnet-ctrl'},
    {key:'recolive-cc',value:'recolive-cc'},
    {key:'remographlm',value:'remographlm'},
    {key:'remotebuddy',value:'remotebuddy'},
    {key:'responsenet',value:'responsenet'},
    {key:'riousbprint',value:'riousbprint'},
    {key:'rmiregistry',value:'rmiregistry'},
    {key:'rpc2portmap',value:'rpc2portmap'},
    {key:'rsmp-server',value:'rsmp-server'},
    {key:'rsvp_tunnel',value:'rsvp_tunnel'},
    {key:'rsvp-tunnel',value:'rsvp-tunnel'},
    {key:'rtc-pm-port',value:'rtc-pm-port'},
    {key:'rtimeviewer',value:'rtimeviewer'},
    {key:'rtraceroute',value:'rtraceroute'},
    {key:'sabp-signal',value:'sabp-signal'},
    {key:'salient-mux',value:'salient-mux'},
    {key:'sa-msg-port',value:'sa-msg-port'},
    {key:'sanavigator',value:'sanavigator'},
    {key:'saphostctrl',value:'saphostctrl'},
    {key:'scan-change',value:'scan-change'},
    {key:'sccip-media',value:'sccip-media'},
    {key:'sco-inetmgr',value:'sco-inetmgr'},
    {key:'scotty-disc',value:'scotty-disc'},
    {key:'scpi-telnet',value:'scpi-telnet'},
    {key:'sd-capacity',value:'sd-capacity'},
    {key:'sdp-id-port',value:'sdp-id-port'},
    {key:'seagull-ais',value:'seagull-ais'},
    {key:'secure-mqtt',value:'secure-mqtt'},
    {key:'senteo-http',value:'senteo-http'},
    {key:'sentinel-lm',value:'sentinel-lm'},
    {key:'sentinelsrm',value:'sentinelsrm'},
    {key:'server-find',value:'server-find'},
    {key:'servergraph',value:'servergraph'},
    {key:'serverstart',value:'serverstart'},
    {key:'servicetags',value:'servicetags'},
    {key:'sfs-smp-net',value:'sfs-smp-net'},
    {key:'sftdst-port',value:'sftdst-port'},
    {key:'sge_qmaster',value:'sge_qmaster'},
    {key:'sge-qmaster',value:'sge-qmaster'},
    {key:'sgi-esphttp',value:'sgi-esphttp'},
    {key:'sgi-storman',value:'sgi-storman'},
    {key:'shoppersync',value:'shoppersync'},
    {key:'sim-control',value:'sim-control'},
    {key:'simple-push',value:'simple-push'},
    {key:'sitewatch-s',value:'sitewatch-s'},
    {key:'six-degrees',value:'six-degrees'},
    {key:'sixxsconfig',value:'sixxsconfig'},
    {key:'sleep-proxy',value:'sleep-proxy'},
    {key:'slinterbase',value:'slinterbase'},
    {key:'smartenergy',value:'smartenergy'},
    {key:'smartsocket',value:'smartsocket'},
    {key:'smauth-port',value:'smauth-port'},
    {key:'sms-remctrl',value:'sms-remctrl'},
    {key:'snaresecure',value:'snaresecure'},
    {key:'snifferdata',value:'snifferdata'},
    {key:'sns-gateway',value:'sns-gateway'},
    {key:'socketcloud',value:'socketcloud'},
    {key:'sossd-agent',value:'sossd-agent'},
    {key:'sparechange',value:'sparechange'},
    {key:'spectardata',value:'spectardata'},
    {key:'spectraport',value:'spectraport'},
    {key:'split-dns64',value:'split-dns64'},
    {key:'sqlexec-ssl',value:'sqlexec-ssl'},
    {key:'ss-idi-disc',value:'ss-idi-disc'},
    {key:'sso-control',value:'sso-control'},
    {key:'sso-service',value:'sso-service'},
    {key:'stanag-5066',value:'stanag-5066'},
    {key:'startrecapp',value:'startrecapp'},
    {key:'statsci1-lm',value:'statsci1-lm'},
    {key:'statsci2-lm',value:'statsci2-lm'},
    {key:'stickynotes',value:'stickynotes'},
    {key:'strobe-sync',value:'strobe-sync'},
    {key:'stryker-com',value:'stryker-com'},
    {key:'submissions',value:'submissions'},
    {key:'sun-as-jpda',value:'sun-as-jpda'},
    {key:'sunlps-http',value:'sunlps-http'},
    {key:'sun-sr-http',value:'sun-sr-http'},
    {key:'sun-sr-iiop',value:'sun-sr-iiop'},
    {key:'sunwebadmin',value:'sunwebadmin'},
    {key:'svs-omagent',value:'svs-omagent'},
    {key:'synchromesh',value:'synchromesh'},
    {key:'synchronite',value:'synchronite'},
    {key:'syncopation',value:'syncopation'},
    {key:'synksharing',value:'synksharing'},
    {key:'syslog-conn',value:'syslog-conn'},
    {key:'taccounting',value:'taccounting'},
    {key:'taep-as-svc',value:'taep-as-svc'},
    {key:'taligent-lm',value:'taligent-lm'},
    {key:'tcoregagent',value:'tcoregagent'},
    {key:'tcp-id-port',value:'tcp-id-port'},
    {key:'telaconsole',value:'telaconsole'},
    {key:'thermo-calc',value:'thermo-calc'},
    {key:'thor-engine',value:'thor-engine'},
    {key:'tic-tac-toe',value:'tic-tac-toe'},
    {key:'tiepie-disc',value:'tiepie-disc'},
    {key:'tinavigator',value:'tinavigator'},
    {key:'tinymessage',value:'tinymessage'},
    {key:'tivoconnect',value:'tivoconnect'},
    {key:'tivo-device',value:'tivo-device'},
    {key:'tivo-photos',value:'tivo-photos'},
    {key:'tivo-remote',value:'tivo-remote'},
    {key:'tivo-videos',value:'tivo-videos'},
    {key:'tl1-raw-ssl',value:'tl1-raw-ssl'},
    {key:'tl-ipcproxy',value:'tl-ipcproxy'},
    {key:'topflow-ssl',value:'topflow-ssl'},
    {key:'trap-daemon',value:'trap-daemon'},
    {key:'trc-netpoll',value:'trc-netpoll'},
    {key:'triquest-lm',value:'triquest-lm'},
    {key:'trispen-sra',value:'trispen-sra'},
    {key:'tritium-can',value:'tritium-can'},
    {key:'trusted-web',value:'trusted-web'},
    {key:'ttc-etap-ds',value:'ttc-etap-ds'},
    {key:'ttc-etap-ns',value:'ttc-etap-ns'},
    {key:'turbonote-1',value:'turbonote-1'},
    {key:'turbonote-2',value:'turbonote-2'},
    {key:'tw-auth-key',value:'tw-auth-key'},
    {key:'ucentric-ds',value:'ucentric-ds'},
    {key:'udp-sr-port',value:'udp-sr-port'},
    {key:'unidata-ldm',value:'unidata-ldm'},
    {key:'unify-debug',value:'unify-debug'},
    {key:'unisql-java',value:'unisql-java'},
    {key:'unix-status',value:'unix-status'},
    {key:'ups-onlinet',value:'ups-onlinet'},
    {key:'urlbookmark',value:'urlbookmark'},
    {key:'uucp-rlogin',value:'uucp-rlogin'},
    {key:'vat-control',value:'vat-control'},
    {key:'vcom-tunnel',value:'vcom-tunnel'},
    {key:'ve-recorder',value:'ve-recorder'},
    {key:'veritas_pbx',value:'veritas_pbx'},
    {key:'veritas-pbx',value:'veritas-pbx'},
    {key:'veritas-ucl',value:'veritas-ucl'},
    {key:'videte-cipc',value:'videte-cipc'},
    {key:'vinainstall',value:'vinainstall'},
    {key:'virtualtape',value:'virtualtape'},
    {key:'virtualuser',value:'virtualuser'},
    {key:'visinet-gui',value:'visinet-gui'},
    {key:'vision_elmd',value:'vision_elmd'},
    {key:'vision-elmd',value:'vision-elmd'},
    {key:'vrtl-vmf-ds',value:'vrtl-vmf-ds'},
    {key:'vrtl-vmf-sa',value:'vrtl-vmf-sa'},
    {key:'vrxpservman',value:'vrxpservman'},
    {key:'vvr-control',value:'vvr-control'},
    {key:'vxcrnbuport',value:'vxcrnbuport'},
    {key:'waascluster',value:'waascluster'},
    {key:'wag-service',value:'wag-service'},
    {key:'wap-vcard-s',value:'wap-vcard-s'},
    {key:'wap-wsp-wtp',value:'wap-wsp-wtp'},
    {key:'watchdog-nt',value:'watchdog-nt'},
    {key:'watcomdebug',value:'watcomdebug'},
    {key:'webadmstart',value:'webadmstart'},
    {key:'wfremotertm',value:'wfremotertm'},
    {key:'wg-netforce',value:'wg-netforce'},
    {key:'winjaserver',value:'winjaserver'},
    {key:'wmc-log-svc',value:'wmc-log-svc'},
    {key:'workflowdir',value:'workflowdir'},
    {key:'workstation',value:'workstation'},
    {key:'worldscores',value:'worldscores'},
    {key:'wpl-ers-zmq',value:'wpl-ers-zmq'},
    {key:'wsscomfrmwk',value:'wsscomfrmwk'},
    {key:'www-ldap-gw',value:'www-ldap-gw'},
    {key:'xact-backup',value:'xact-backup'},
    {key:'xandros-cms',value:'xandros-cms'},
    {key:'xcap-portal',value:'xcap-portal'},
    {key:'xcodedistcc',value:'xcodedistcc'},
    {key:'xmlrpc-beep',value:'xmlrpc-beep'},
    {key:'xmpp-client',value:'xmpp-client'},
    {key:'xmpp-server',value:'xmpp-server'},
    {key:'xns-courier',value:'xns-courier'},
    {key:'xxnetserver',value:'xxnetserver'},
    {key:'zenginkyo-1',value:'zenginkyo-1'},
    {key:'zenginkyo-2',value:'zenginkyo-2'},
    {key:'3com-njack-1',value:'3com-njack-1'},
    {key:'3com-njack-2',value:'3com-njack-2'},
    {key:'3com-webview',value:'3com-webview'},
    {key:'4-tieropmcli',value:'4-tieropmcli'},
    {key:'ace-svr-prop',value:'ace-svr-prop'},
    {key:'activememory',value:'activememory'},
    {key:'adobe-shadow',value:'adobe-shadow'},
    {key:'agri-gateway',value:'agri-gateway'},
    {key:'ah-esp-encap',value:'ah-esp-encap'},
    {key:'aigairserver',value:'aigairserver'},
    {key:'airprojector',value:'airprojector'},
    {key:'alphatech-lm',value:'alphatech-lm'},
    {key:'altav-remmgt',value:'altav-remmgt'},
    {key:'altav-tunnel',value:'altav-tunnel'},
    {key:'amt-blc-port',value:'amt-blc-port'},
    {key:'amt-cnf-prot',value:'amt-cnf-prot'},
    {key:'amt-esd-prot',value:'amt-esd-prot'},
    {key:'amx-webadmin',value:'amx-webadmin'},
    {key:'animobserver',value:'animobserver'},
    {key:'an-signaling',value:'an-signaling'},
    {key:'anthony-data',value:'anthony-data'},
    {key:'apogeex-port',value:'apogeex-port'},
    {key:'apollo-admin',value:'apollo-admin'},
    {key:'apollo-relay',value:'apollo-relay'},
    {key:'apparenet-as',value:'apparenet-as'},
    {key:'apparenet-ts',value:'apparenet-ts'},
    {key:'apparenet-ui',value:'apparenet-ui'},
    {key:'apple-ausend',value:'apple-ausend'},
    {key:'apple-licman',value:'apple-licman'},
    {key:'apple-mobdev',value:'apple-mobdev'},
    {key:'appleqtcsrvr',value:'appleqtcsrvr'},
    {key:'appletv-pair',value:'appletv-pair'},
    {key:'appserv-http',value:'appserv-http'},
    {key:'apsolab-cols',value:'apsolab-cols'},
    {key:'apsolab-data',value:'apsolab-data'},
    {key:'apsolab-tags',value:'apsolab-tags'},
    {key:'apw-registry',value:'apw-registry'},
    {key:'arbortext-lm',value:'arbortext-lm'},
    {key:'arena-server',value:'arena-server'},
    {key:'armagetronad',value:'armagetronad'},
    {key:'artifact-msg',value:'artifact-msg'},
    {key:'aruba-server',value:'aruba-server'},
    {key:'asa-gateways',value:'asa-gateways'},
    {key:'asap-tcp-tls',value:'asap-tcp-tls'},
    {key:'asctrl-agent',value:'asctrl-agent'},
    {key:'asipregistry',value:'asipregistry'},
    {key:'as-servermap',value:'as-servermap'},
    {key:'astergatefax',value:'astergatefax'},
    {key:'atlassianapp',value:'atlassianapp'},
    {key:'audiojuggler',value:'audiojuggler'},
    {key:'audirvana-ap',value:'audirvana-ap'},
    {key:'autodesk-nlm',value:'autodesk-nlm'},
    {key:'autotrac-acp',value:'autotrac-acp'},
    {key:'availant-mgr',value:'availant-mgr'},
    {key:'avi-nms-disc',value:'avi-nms-disc'},
    {key:'avsecuremgmt',value:'avsecuremgmt'},
    {key:'b3d-convince',value:'b3d-convince'},
    {key:'bcs-lmserver',value:'bcs-lmserver'},
    {key:'behnke-video',value:'behnke-video'},
    {key:'bex-webadmin',value:'bex-webadmin'},
    {key:'bigbangchess',value:'bigbangchess'},
    {key:'bintec-admin',value:'bintec-admin'},
    {key:'biolink-auth',value:'biolink-auth'},
    {key:'bitforestsrv',value:'bitforestsrv'},
    {key:'blueberry-lm',value:'blueberry-lm'},
    {key:'bmc_ctd_ldap',value:'bmc_ctd_ldap'},
    {key:'bmc_patroldb',value:'bmc_patroldb'},
    {key:'bmc-ctd-ldap',value:'bmc-ctd-ldap'},
    {key:'bmc-jmx-port',value:'bmc-jmx-port'},
    {key:'bmc-patroldb',value:'bmc-patroldb'},
    {key:'boe-cachesvr',value:'boe-cachesvr'},
    {key:'boe-eventsrv',value:'boe-eventsrv'},
    {key:'boe-resssvr1',value:'boe-resssvr1'},
    {key:'boe-resssvr2',value:'boe-resssvr2'},
    {key:'boe-resssvr3',value:'boe-resssvr3'},
    {key:'boe-resssvr4',value:'boe-resssvr4'},
    {key:'boinc-client',value:'boinc-client'},
    {key:'boundaryscan',value:'boundaryscan'},
    {key:'bsfserver-zn',value:'bsfserver-zn'},
    {key:'bsquare-voip',value:'bsquare-voip'},
    {key:'btpp2audctr1',value:'btpp2audctr1'},
    {key:'bues_service',value:'bues_service'},
    {key:'bues-service',value:'bues-service'},
    {key:'bullant-srap',value:'bullant-srap'},
    {key:'buschtrommel',value:'buschtrommel'},
    {key:'cableport-ax',value:'cableport-ax'},
    {key:'cab-protocol',value:'cab-protocol'},
    {key:'cacsambroker',value:'cacsambroker'},
    {key:'caids-sensor',value:'caids-sensor'},
    {key:'call-logging',value:'call-logging'},
    {key:'canocentral0',value:'canocentral0'},
    {key:'canocentral1',value:'canocentral1'},
    {key:'cardbox-http',value:'cardbox-http'},
    {key:'caupc-remote',value:'caupc-remote'},
    {key:'cbos-ip-port',value:'cbos-ip-port'},
    {key:'ccs-software',value:'ccs-software'},
    {key:'certificates',value:'certificates'},
    {key:'ciphire-data',value:'ciphire-data'},
    {key:'ciphire-serv',value:'ciphire-serv'},
    {key:'cl-db-attach',value:'cl-db-attach'},
    {key:'cl-db-remote',value:'cl-db-remote'},
    {key:'cleverdetect',value:'cleverdetect'},
    {key:'clever-tcpip',value:'clever-tcpip'},
    {key:'close-combat',value:'close-combat'},
    {key:'cluster-disc',value:'cluster-disc'},
    {key:'cnckadserver',value:'cnckadserver'},
    {key:'cns-srv-port',value:'cns-srv-port'},
    {key:'collaborator',value:'collaborator'},
    {key:'commlinx-avl',value:'commlinx-avl'},
    {key:'comotionback',value:'comotionback'},
    {key:'compaq-https',value:'compaq-https'},
    {key:'conclave-cpp',value:'conclave-cpp'},
    {key:'contamac_icm',value:'contamac_icm'},
    {key:'contamac-icm',value:'contamac-icm'},
    {key:'contclientms',value:'contclientms'},
    {key:'core-rd-dtls',value:'core-rd-dtls'},
    {key:'cpqrpm-agent',value:'cpqrpm-agent'},
    {key:'cqg-netlan-1',value:'cqg-netlan-1'},
    {key:'crestron-cip',value:'crestron-cip'},
    {key:'crestron-ctp',value:'crestron-ctp'},
    {key:'cruise-diags',value:'cruise-diags'},
    {key:'crusecontrol',value:'crusecontrol'},
    {key:'csccfirewall',value:'csccfirewall'},
    {key:'cs-remote-db',value:'cs-remote-db'},
    {key:'ctisystemmsg',value:'ctisystemmsg'},
    {key:'cuelink-disc',value:'cuelink-disc'},
    {key:'cuillamartin',value:'cuillamartin'},
    {key:'cypress-stat',value:'cypress-stat'},
    {key:'d2ddatatrans',value:'d2ddatatrans'},
    {key:'dancepartner',value:'dancepartner'},
    {key:'dandv-tester',value:'dandv-tester'},
    {key:'dashpas-port',value:'dashpas-port'},
    {key:'daylitetouch',value:'daylitetouch'},
    {key:'d-cinema-csp',value:'d-cinema-csp'},
    {key:'d-cinema-rrp',value:'d-cinema-rrp'},
    {key:'dell-eql-asm',value:'dell-eql-asm'},
    {key:'dellpwrappks',value:'dellpwrappks'},
    {key:'dell-rm-port',value:'dell-rm-port'},
    {key:'dell-ssms-ds',value:'dell-ssms-ds'},
    {key:'dhanalakshmi',value:'dhanalakshmi'},
    {key:'directv-soft',value:'directv-soft'},
    {key:'directv-tick',value:'directv-tick'},
    {key:'discp-client',value:'discp-client'},
    {key:'discp-server',value:'discp-server'},
    {key:'dist-upgrade',value:'dist-upgrade'},
    {key:'di-traceware',value:'di-traceware'},
    {key:'dns-push-tls',value:'dns-push-tls'},
    {key:'documentum_s',value:'documentum_s'},
    {key:'documentum-s',value:'documentum-s'},
    {key:'dpserveadmin',value:'dpserveadmin'},
    {key:'dsmcc-config',value:'dsmcc-config'},
    {key:'dsmeter_iatc',value:'dsmeter_iatc'},
    {key:'dsmeter-iatc',value:'dsmeter-iatc'},
    {key:'dtrmtdesktop',value:'dtrmtdesktop'},
    {key:'dtv-chan-req',value:'dtv-chan-req'},
    {key:'dvcprov-port',value:'dvcprov-port'},
    {key:'e1-interface',value:'e1-interface'},
    {key:'ecbyesfsgksc',value:'ecbyesfsgksc'},
    {key:'e-design-net',value:'e-design-net'},
    {key:'e-design-web',value:'e-design-web'},
    {key:'edm-mgr-sync',value:'edm-mgr-sync'},
    {key:'eicon-server',value:'eicon-server'},
    {key:'elm-momentum',value:'elm-momentum'},
    {key:'elpro_tunnel',value:'elpro_tunnel'},
    {key:'elpro-tunnel',value:'elpro-tunnel'},
    {key:'elvin_client',value:'elvin_client'},
    {key:'elvin_server',value:'elvin_server'},
    {key:'elvin-client',value:'elvin-client'},
    {key:'elvin-server',value:'elvin-server'},
    {key:'emb-proj-cmd',value:'emb-proj-cmd'},
    {key:'embrace-dp-c',value:'embrace-dp-c'},
    {key:'embrace-dp-s',value:'embrace-dp-s'},
    {key:'emc-vcas-tcp',value:'emc-vcas-tcp'},
    {key:'emc-vcas-udp',value:'emc-vcas-udp'},
    {key:'entrust-aaas',value:'entrust-aaas'},
    {key:'entrust-aams',value:'entrust-aams'},
    {key:'entrust-kmsh',value:'entrust-kmsh'},
    {key:'exasoftport1',value:'exasoftport1'},
    {key:'ezmessagesrv',value:'ezmessagesrv'},
    {key:'facilityview',value:'facilityview'},
    {key:'faxstfx-port',value:'faxstfx-port'},
    {key:'fcopy-server',value:'fcopy-server'},
    {key:'fcp-cics-gw1',value:'fcp-cics-gw1'},
    {key:'feed-sharing',value:'feed-sharing'},
    {key:'ferrari-foam',value:'ferrari-foam'},
    {key:'fg-sysupdate',value:'fg-sysupdate'},
    {key:'fjappmgrbulk',value:'fjappmgrbulk'},
    {key:'fjippol-cnsl',value:'fjippol-cnsl'},
    {key:'fjitsuappmgr',value:'fjitsuappmgr'},
    {key:'flex-lmadmin',value:'flex-lmadmin'},
    {key:'font-service',value:'font-service'},
    {key:'foresyte-sec',value:'foresyte-sec'},
    {key:'foxtrot-serv',value:'foxtrot-serv'},
    {key:'fujitsu-neat',value:'fujitsu-neat'},
    {key:'funk-dialout',value:'funk-dialout'},
    {key:'funk-license',value:'funk-license'},
    {key:'galaxy7-data',value:'galaxy7-data'},
    {key:'genisar-port',value:'genisar-port'},
    {key:'geognosisman',value:'geognosisman'},
    {key:'global-wlink',value:'global-wlink'},
    {key:'globecast-id',value:'globecast-id'},
    {key:'gnutella-rtr',value:'gnutella-rtr'},
    {key:'gnutella-svc',value:'gnutella-svc'},
    {key:'gre-udp-dtls',value:'gre-udp-dtls'},
    {key:'gridgen-elmd',value:'gridgen-elmd'},
    {key:'gw-call-port',value:'gw-call-port'},
    {key:'h323gatedisc',value:'h323gatedisc'},
    {key:'h323gatestat',value:'h323gatestat'},
    {key:'h323hostcall',value:'h323hostcall'},
    {key:'hacl-monitor',value:'hacl-monitor'},
    {key:'hfcs-manager',value:'hfcs-manager'},
    {key:'hhb-handheld',value:'hhb-handheld'},
    {key:'hiperscan-id',value:'hiperscan-id'},
    {key:'hmip-routing',value:'hmip-routing'},
    {key:'home-sharing',value:'home-sharing'},
    {key:'hp-alarm-mgr',value:'hp-alarm-mgr'},
    {key:'hp-collector',value:'hp-collector'},
    {key:'hpdeviceinfo',value:'hpdeviceinfo'},
    {key:'hpvmmcontrol',value:'hpvmmcontrol'},
    {key:'hub-open-net',value:'hub-open-net'},
    {key:'hypercube-lm',value:'hypercube-lm'},
    {key:'ias-neighbor',value:'ias-neighbor'},
    {key:'iatp-highpri',value:'iatp-highpri'},
    {key:'iba-cfg-disc',value:'iba-cfg-disc'},
    {key:'ibm-dial-out',value:'ibm-dial-out'},
    {key:'ibm-mqseries',value:'ibm-mqseries'},
    {key:'ibridge-data',value:'ibridge-data'},
    {key:'ibridge-mgmt',value:'ibridge-mgmt'},
    {key:'ice-location',value:'ice-location'},
    {key:'icl-twobase1',value:'icl-twobase1'},
    {key:'icl-twobase2',value:'icl-twobase2'},
    {key:'icl-twobase3',value:'icl-twobase3'},
    {key:'icl-twobase4',value:'icl-twobase4'},
    {key:'icl-twobase5',value:'icl-twobase5'},
    {key:'icl-twobase6',value:'icl-twobase6'},
    {key:'icl-twobase7',value:'icl-twobase7'},
    {key:'icl-twobase8',value:'icl-twobase8'},
    {key:'icl-twobase9',value:'icl-twobase9'},
    {key:'ieee-mms-ssl',value:'ieee-mms-ssl'},
    {key:'ifsf-hb-port',value:'ifsf-hb-port'},
    {key:'imoguia-port',value:'imoguia-port'},
    {key:'intel-rci-mp',value:'intel-rci-mp'},
    {key:'interintelli',value:'interintelli'},
    {key:'intrepid-ssl',value:'intrepid-ssl'},
    {key:'io-dist-data',value:'io-dist-data'},
    {key:'ipcs-command',value:'ipcs-command'},
    {key:'ip-provision',value:'ip-provision'},
    {key:'iscsi-target',value:'iscsi-target'},
    {key:'iss-mgmt-ssl',value:'iss-mgmt-ssl'},
    {key:'isynchronize',value:'isynchronize'},
    {key:'itap-publish',value:'itap-publish'},
    {key:'itu-bicc-stc',value:'itu-bicc-stc'},
    {key:'iuhsctpassoc',value:'iuhsctpassoc'},
    {key:'ivs-database',value:'ivs-database'},
    {key:'jaxflow-data',value:'jaxflow-data'},
    {key:'jeol-nsddp-1',value:'jeol-nsddp-1'},
    {key:'jeol-nsddp-2',value:'jeol-nsddp-2'},
    {key:'jeol-nsddp-3',value:'jeol-nsddp-3'},
    {key:'jeol-nsddp-4',value:'jeol-nsddp-4'},
    {key:'jeol-nsdtp-1',value:'jeol-nsdtp-1'},
    {key:'jeol-nsdtp-2',value:'jeol-nsdtp-2'},
    {key:'jeol-nsdtp-3',value:'jeol-nsdtp-3'},
    {key:'jeol-nsdtp-4',value:'jeol-nsdtp-4'},
    {key:'jetcmeserver',value:'jetcmeserver'},
    {key:'jmq-daemon-1',value:'jmq-daemon-1'},
    {key:'jmq-daemon-2',value:'jmq-daemon-2'},
    {key:'kentrox-prot',value:'kentrox-prot'},
    {key:'kerberos-adm',value:'kerberos-adm'},
    {key:'lanmessenger',value:'lanmessenger'},
    {key:'lanrevserver',value:'lanrevserver'},
    {key:'lbc-watchdog',value:'lbc-watchdog'},
    {key:'libelle-disc',value:'libelle-disc'},
    {key:'lisp-control',value:'lisp-control'},
    {key:'listcrt-port',value:'listcrt-port'},
    {key:'listmgr-port',value:'listmgr-port'},
    {key:'lm-perfworks',value:'lm-perfworks'},
    {key:'lontalk-norm',value:'lontalk-norm'},
    {key:'loreji-panel',value:'loreji-panel'},
    {key:'macfoh-admin',value:'macfoh-admin'},
    {key:'macfoh-audio',value:'macfoh-audio'},
    {key:'magiccontrol',value:'magiccontrol'},
    {key:'manyone-http',value:'manyone-http'},
    {key:'matip-type-a',value:'matip-type-a'},
    {key:'matip-type-b',value:'matip-type-b'},
    {key:'mc-appserver',value:'mc-appserver'},
    {key:'mcns-tel-ret',value:'mcns-tel-ret'},
    {key:'mcs-fastmail',value:'mcs-fastmail'},
    {key:'mctet-master',value:'mctet-master'},
    {key:'mercury-disc',value:'mercury-disc'},
    {key:'metatude-mds',value:'metatude-mds'},
    {key:'mgcp-gateway',value:'mgcp-gateway'},
    {key:'microcom-sbp',value:'microcom-sbp'},
    {key:'micromuse-lm',value:'micromuse-lm'},
    {key:'microsoft-ds',value:'microsoft-ds'},
    {key:'mindarray-ca',value:'mindarray-ca'},
    {key:'mnp-exchange',value:'mnp-exchange'},
    {key:'mobrien-chat',value:'mobrien-chat'},
    {key:'mortgageware',value:'mortgageware'},
    {key:'MOS-soap-opt',value:'MOS-soap-opt'},
    {key:'mppolicy-mgr',value:'mppolicy-mgr'},
    {key:'msfw-control',value:'msfw-control'},
    {key:'msfw-replica',value:'msfw-replica'},
    {key:'msfw-storage',value:'msfw-storage'},
    {key:'ms-licensing',value:'ms-licensing'},
    {key:'ms-streaming',value:'ms-streaming'},
    {key:'mtcevrunqman',value:'mtcevrunqman'},
    {key:'mti-tcs-comm',value:'mti-tcs-comm'},
    {key:'musicmachine',value:'musicmachine'},
    {key:'mvs-capacity',value:'mvs-capacity'},
    {key:'myq-termlink',value:'myq-termlink'},
    {key:'nas-metering',value:'nas-metering'},
    {key:'nav-data-cmd',value:'nav-data-cmd'},
    {key:'ncacn-ip-tcp',value:'ncacn-ip-tcp'},
    {key:'ncadg-ip-udp',value:'ncadg-ip-udp'},
    {key:'ncdmirroring',value:'ncdmirroring'},
    {key:'ncsyncserver',value:'ncsyncserver'},
    {key:'nec-raidplus',value:'nec-raidplus'},
    {key:'netbill-auth',value:'netbill-auth'},
    {key:'netbill-cred',value:'netbill-cred'},
    {key:'netbill-prod',value:'netbill-prod'},
    {key:'netconf-beep',value:'netconf-beep'},
    {key:'netdb-export',value:'netdb-export'},
    {key:'netiq-qcheck',value:'netiq-qcheck'},
    {key:'netop-school',value:'netop-school'},
    {key:'netspeak-acd',value:'netspeak-acd'},
    {key:'netspeak-cps',value:'netspeak-cps'},
    {key:'nettgain-nms',value:'nettgain-nms'},
    {key:'networklenss',value:'networklenss'},
    {key:'netxms-agent',value:'netxms-agent'},
    {key:'neveroffline',value:'neveroffline'},
    {key:'newlixconfig',value:'newlixconfig'},
    {key:'newlixengine',value:'newlixengine'},
    {key:'nexus-portal',value:'nexus-portal'},
    {key:'nicetec-mgmt',value:'nicetec-mgmt'},
    {key:'nimbusdbctrl',value:'nimbusdbctrl'},
    {key:'nimrod-agent',value:'nimrod-agent'},
    {key:'nim-vdrshell',value:'nim-vdrshell'},
    {key:'nod-provider',value:'nod-provider'},
    {key:'novar-global',value:'novar-global'},
    {key:'novell-lu6.2',value:'novell-lu6.2'},
    {key:'novell-lu6-2',value:'novell-lu6-2'},
    {key:'npds-tracker',value:'npds-tracker'},
    {key:'nssocketport',value:'nssocketport'},
    {key:'nucleus-sand',value:'nucleus-sand'},
    {key:'oceansoft-lm',value:'oceansoft-lm'},
    {key:'oemcacao-rmi',value:'oemcacao-rmi'},
    {key:'office-tools',value:'office-tools'},
    {key:'omabcastltkm',value:'omabcastltkm'},
    {key:'oma-bcast-sg',value:'oma-bcast-sg'},
    {key:'onehome-help',value:'onehome-help'},
    {key:'oob-ws-https',value:'oob-ws-https'},
    {key:'opalis-robot',value:'opalis-robot'},
    {key:'opennl-voice',value:'opennl-voice'},
    {key:'openstack-id',value:'openstack-id'},
    {key:'opsmessaging',value:'opsmessaging'},
    {key:'orbix-config',value:'orbix-config'},
    {key:'orbplus-iiop',value:'orbplus-iiop'},
    {key:'origo-native',value:'origo-native'},
    {key:'osmosis-aeea',value:'osmosis-aeea'},
    {key:'ovrimosdbman',value:'ovrimosdbman'},
    {key:'ovsessionmgr',value:'ovsessionmgr'},
    {key:'p2pcommunity',value:'p2pcommunity'},
    {key:'p2pevolvenet',value:'p2pevolvenet'},
    {key:'pairandshare',value:'pairandshare'},
    {key:'password-chg',value:'password-chg'},
    {key:'patrol-mq-gm',value:'patrol-mq-gm'},
    {key:'patrol-mq-nm',value:'patrol-mq-nm'},
    {key:'pay-per-view',value:'pay-per-view'},
    {key:'pcsync-https',value:'pcsync-https'},
    {key:'pgpkey-https',value:'pgpkey-https'},
    {key:'pictrography',value:'pictrography'},
    {key:'pkix-3-ca-ra',value:'pkix-3-ca-ra'},
    {key:'playsta2-app',value:'playsta2-app'},
    {key:'playsta2-lob',value:'playsta2-lob'},
    {key:'plysrv-https',value:'plysrv-https'},
    {key:'pmsm-webrctl',value:'pmsm-webrctl'},
    {key:'p-net-remote',value:'p-net-remote'},
    {key:'pn-requester',value:'pn-requester'},
    {key:'podcastproxy',value:'podcastproxy'},
    {key:'policyserver',value:'policyserver'},
    {key:'powergemplus',value:'powergemplus'},
    {key:'ppactivation',value:'ppactivation'},
    {key:'pplayer-ctrl',value:'pplayer-ctrl'},
    {key:'precise-comm',value:'precise-comm'},
    {key:'prism-deploy',value:'prism-deploy'},
    {key:'profinet-rtm',value:'profinet-rtm'},
    {key:'prosharedata',value:'prosharedata'},
    {key:'pt2-discover',value:'pt2-discover'},
    {key:'ptnetprosrv2',value:'ptnetprosrv2'},
    {key:'qb-db-server',value:'qb-db-server'},
    {key:'qsnet-assist',value:'qsnet-assist'},
    {key:'qsnet-workst',value:'qsnet-workst'},
    {key:'rdb-dbs-disp',value:'rdb-dbs-disp'},
    {key:'realplayfavs',value:'realplayfavs'},
    {key:'recordit-itp',value:'recordit-itp'},
    {key:'redwood-chat',value:'redwood-chat'},
    {key:'remotedeploy',value:'remotedeploy'},
    {key:'renkara-sync',value:'renkara-sync'},
    {key:'resource_mgr',value:'resource_mgr'},
    {key:'resource-mgr',value:'resource-mgr'},
    {key:'rjcdb-vcards',value:'rjcdb-vcards'},
    {key:'robot-remote',value:'robot-remote'},
    {key:'rpki-rtr-tls',value:'rpki-rtr-tls'},
    {key:'rsisysaccess',value:'rsisysaccess'},
    {key:'rsvp-encap-1',value:'rsvp-encap-1'},
    {key:'rsvp-encap-2',value:'rsvp-encap-2'},
    {key:'rugameonline',value:'rugameonline'},
    {key:'rvs-isdn-dcp',value:'rvs-isdn-dcp'},
    {key:'sagectlpanel',value:'sagectlpanel'},
    {key:'samsung-disc',value:'samsung-disc'},
    {key:'saphostctrls',value:'saphostctrls'},
    {key:'sauterdongle',value:'sauterdongle'},
    {key:'scc-security',value:'scc-security'},
    {key:'scientia-sdb',value:'scientia-sdb'},
    {key:'scoi2odialog',value:'scoi2odialog'},
    {key:'sco-peer-tta',value:'sco-peer-tta'},
    {key:'search-agent',value:'search-agent'},
    {key:'seclayer-tcp',value:'seclayer-tcp'},
    {key:'seclayer-tls',value:'seclayer-tls'},
    {key:'sec-ntb-clnt',value:'sec-ntb-clnt'},
    {key:'sentinel-ent',value:'sentinel-ent'},
    {key:'service-ctrl',value:'service-ctrl'},
    {key:'servicemeter',value:'servicemeter'},
    {key:'servistaitsm',value:'servistaitsm'},
    {key:'shadowserver',value:'shadowserver'},
    {key:'shadowstream',value:'shadowstream'},
    {key:'sharp-server',value:'sharp-server'},
    {key:'simbaexpress',value:'simbaexpress'},
    {key:'simple-tx-rx',value:'simple-tx-rx'},
    {key:'simusoftpong',value:'simusoftpong'},
    {key:'sitaraserver',value:'sitaraserver'},
    {key:'sleeptracker',value:'sleeptracker'},
    {key:'slim-devices',value:'slim-devices'},
    {key:'slinkysearch',value:'slinkysearch'},
    {key:'smartpackets',value:'smartpackets'},
    {key:'snmpssh-trap',value:'snmpssh-trap'},
    {key:'snmptls-trap',value:'snmptls-trap'},
    {key:'sns-channels',value:'sns-channels'},
    {key:'sns-protocol',value:'sns-protocol'},
    {key:'sntlkeyssrvr',value:'sntlkeyssrvr'},
    {key:'social-alarm',value:'social-alarm'},
    {key:'solera-epmap',value:'solera-epmap'},
    {key:'sonuscallsig',value:'sonuscallsig'},
    {key:'spandataport',value:'spandataport'},
    {key:'spiral-admin',value:'spiral-admin'},
    {key:'splitlock-gw',value:'splitlock-gw'},
    {key:'spytechphone',value:'spytechphone'},
    {key:'srp-feedback',value:'srp-feedback'},
    {key:'ss-sign-disc',value:'ss-sign-disc'},
    {key:'starfield-io',value:'starfield-io'},
    {key:'stat-results',value:'stat-results'},
    {key:'stat-scanner',value:'stat-scanner'},
    {key:'sti-envision',value:'sti-envision'},
    {key:'stingray-rpc',value:'stingray-rpc'},
    {key:'stresstester',value:'stresstester'},
    {key:'submitserver',value:'submitserver'},
    {key:'sun-as-iiops',value:'sun-as-iiops'},
    {key:'suncacao-csa',value:'suncacao-csa'},
    {key:'suncacao-rmi',value:'suncacao-rmi'},
    {key:'sun-sea-port',value:'sun-sea-port'},
    {key:'sun-sr-admin',value:'sun-sr-admin'},
    {key:'sun-sr-https',value:'sun-sr-https'},
    {key:'sun-sr-iiops',value:'sun-sr-iiops'},
    {key:'sunwebadmins',value:'sunwebadmins'},
    {key:'sybasesrvmon',value:'sybasesrvmon'},
    {key:'symantec-sim',value:'symantec-sim'},
    {key:'symb-sb-port',value:'symb-sb-port'},
    {key:'t128-gateway',value:'t128-gateway'},
    {key:'t1distproc60',value:'t1distproc60'},
    {key:'tacticalauth',value:'tacticalauth'},
    {key:'talarian-mqs',value:'talarian-mqs'},
    {key:'talarian-tcp',value:'talarian-tcp'},
    {key:'talarian-udp',value:'talarian-udp'},
    {key:'talikaserver',value:'talikaserver'},
    {key:'talon-engine',value:'talon-engine'},
    {key:'tapinoma-ecs',value:'tapinoma-ecs'},
    {key:'tappi-boxnet',value:'tappi-boxnet'},
    {key:'taskman-port',value:'taskman-port'},
    {key:'tcim-control',value:'tcim-control'},
    {key:'te-faxserver',value:'te-faxserver'},
    {key:'tellumat-nms',value:'tellumat-nms'},
    {key:'tempest-port',value:'tempest-port'},
    {key:'tht-treasure',value:'tht-treasure'},
    {key:'tibet-server',value:'tibet-server'},
    {key:'ticonnectmgr',value:'ticonnectmgr'},
    {key:'tivo-mindrpc',value:'tivo-mindrpc'},
    {key:'tnp-discover',value:'tnp-discover'},
    {key:'touchnetplus',value:'touchnetplus'},
    {key:'touch-remote',value:'touch-remote'},
    {key:'tptx-console',value:'tptx-console'},
    {key:'traingpsdata',value:'traingpsdata'},
    {key:'trident-data',value:'trident-data'},
    {key:'trinity-dist',value:'trinity-dist'},
    {key:'tr-rsrb-port',value:'tr-rsrb-port'},
    {key:'ttg-protocol',value:'ttg-protocol'},
    {key:'tunstall-lwp',value:'tunstall-lwp'},
    {key:'tunstall-pnc',value:'tunstall-pnc'},
    {key:'tve-announce',value:'tve-announce'},
    {key:'user-manager',value:'user-manager'},
    {key:'usp-agt-coap',value:'usp-agt-coap'},
    {key:'usp-agt-http',value:'usp-agt-http'},
    {key:'usp-agt-mqtt',value:'usp-agt-mqtt'},
    {key:'usp-ctr-coap',value:'usp-ctr-coap'},
    {key:'usp-ctr-http',value:'usp-ctr-http'},
    {key:'usp-ctr-mqtt',value:'usp-ctr-mqtt'},
    {key:'vdmmesh-disc',value:'vdmmesh-disc'},
    {key:'veritas-tcp1',value:'veritas-tcp1'},
    {key:'veritas-udp1',value:'veritas-udp1'},
    {key:'veritas-vis1',value:'veritas-vis1'},
    {key:'veritas-vis2',value:'veritas-vis2'},
    {key:'virtual-time',value:'virtual-time'},
    {key:'vocaltec-hos',value:'vocaltec-hos'},
    {key:'vofr-gateway',value:'vofr-gateway'},
    {key:'vrts-at-port',value:'vrts-at-port'},
    {key:'vsat-control',value:'vsat-control'},
    {key:'vseconnector',value:'vseconnector'},
    {key:'vtr-emulator',value:'vtr-emulator'},
    {key:'vx-auth-port',value:'vx-auth-port'},
    {key:'wago-service',value:'wago-service'},
    {key:'walkietalkie',value:'walkietalkie'},
    {key:'warmspotMgmt',value:'warmspotMgmt'},
    {key:'watchdoc-pod',value:'watchdoc-pod'},
    {key:'watchme-7272',value:'watchme-7272'},
    {key:'watershed-lm',value:'watershed-lm'},
    {key:'winshadow-hd',value:'winshadow-hd'},
    {key:'wmereceiving',value:'wmereceiving'},
    {key:'wmereporting',value:'wmereporting'},
    {key:'worldfusion1',value:'worldfusion1'},
    {key:'worldfusion2',value:'worldfusion2'},
    {key:'wpl-ers-http',value:'wpl-ers-http'},
    {key:'writietalkie',value:'writietalkie'},
    {key:'wrs_registry',value:'wrs_registry'},
    {key:'wrs-registry',value:'wrs-registry'},
    {key:'ws-discovery',value:'ws-discovery'},
    {key:'x25-svc-port',value:'x25-svc-port'},
    {key:'xcap-control',value:'xcap-control'},
    {key:'XmlIpcRegSvc',value:'XmlIpcRegSvc'},
    {key:'XSIP-network',value:'XSIP-network'},
    {key:'xss-srv-port',value:'xss-srv-port'},
    {key:'xtimelicence',value:'xtimelicence'},
    {key:'xybrid-cloud',value:'xybrid-cloud'},
    {key:'zabbix-agent',value:'zabbix-agent'},
    {key:'3com-net-mgmt',value:'3com-net-mgmt'},
    {key:'3par-mgmt-ssl',value:'3par-mgmt-ssl'},
    {key:'abacus-remote',value:'abacus-remote'},
    {key:'abcvoice-port',value:'abcvoice-port'},
    {key:'accessbuilder',value:'accessbuilder'},
    {key:'accessnetwork',value:'accessnetwork'},
    {key:'acp-discovery',value:'acp-discovery'},
    {key:'actifioreplic',value:'actifioreplic'},
    {key:'adobeserver-1',value:'adobeserver-1'},
    {key:'adobeserver-2',value:'adobeserver-2'},
    {key:'adobeserver-3',value:'adobeserver-3'},
    {key:'adobeserver-4',value:'adobeserver-4'},
    {key:'adobeserver-5',value:'adobeserver-5'},
    {key:'aes-discovery',value:'aes-discovery'},
    {key:'afs3-callback',value:'afs3-callback'},
    {key:'afs3-kaserver',value:'afs3-kaserver'},
    {key:'afs3-prserver',value:'afs3-prserver'},
    {key:'afs3-vlserver',value:'afs3-vlserver'},
    {key:'agentsease-db',value:'agentsease-db'},
    {key:'airsharingpro',value:'airsharingpro'},
    {key:'aja-ntv4-disc',value:'aja-ntv4-disc'},
    {key:'alarm-clock-c',value:'alarm-clock-c'},
    {key:'alarm-clock-s',value:'alarm-clock-s'},
    {key:'altovacentral',value:'altovacentral'},
    {key:'amicon-fpsu-s',value:'amicon-fpsu-s'},
    {key:'amt-redir-tcp',value:'amt-redir-tcp'},
    {key:'amt-redir-tls',value:'amt-redir-tls'},
    {key:'amt-soap-http',value:'amt-soap-http'},
    {key:'anoto-rendezv',value:'anoto-rendezv'},
    {key:'answersoft-lm',value:'answersoft-lm'},
    {key:'apollo-status',value:'apollo-status'},
    {key:'apparenet-tps',value:'apparenet-tps'},
    {key:'apple-vpns-rp',value:'apple-vpns-rp'},
    {key:'appliance-cfg',value:'appliance-cfg'},
    {key:'applusservice',value:'applusservice'},
    {key:'appman-server',value:'appman-server'},
    {key:'appserv-https',value:'appserv-https'},
    {key:'appswitch-emp',value:'appswitch-emp'},
    {key:'apwi-imserver',value:'apwi-imserver'},
    {key:'apwi-rxserver',value:'apwi-rxserver'},
    {key:'areaguard-neo',value:'areaguard-neo'},
    {key:'aries-kfinder',value:'aries-kfinder'},
    {key:'armcenterhttp',value:'armcenterhttp'},
    {key:'armtechdaemon',value:'armtechdaemon'},
    {key:'array-manager',value:'array-manager'},
    {key:'asap-sctp-tls',value:'asap-sctp-tls'},
    {key:'asip-webadmin',value:'asip-webadmin'},
    {key:'atc-appserver',value:'atc-appserver'},
    {key:'auriga-router',value:'auriga-router'},
    {key:'av-emb-config',value:'av-emb-config'},
    {key:'avinstalldisc',value:'avinstalldisc'},
    {key:'avocent-adsap',value:'avocent-adsap'},
    {key:'avocent-proxy',value:'avocent-proxy'},
    {key:'avt-profile-1',value:'avt-profile-1'},
    {key:'avt-profile-2',value:'avt-profile-2'},
    {key:'bandhelper-rc',value:'bandhelper-rc'},
    {key:'bandhelper-ss',value:'bandhelper-ss'},
    {key:'barracuda-bbs',value:'barracuda-bbs'},
    {key:'barroomcomedy',value:'barroomcomedy'},
    {key:'bcloud-server',value:'bcloud-server'},
    {key:'beacon-port-2',value:'beacon-port-2'},
    {key:'beeyond-media',value:'beeyond-media'},
    {key:'beyond-remote',value:'beyond-remote'},
    {key:'bfd-multi-ctl',value:'bfd-multi-ctl'},
    {key:'blockade-bpsp',value:'blockade-bpsp'},
    {key:'bluectrlproxy',value:'bluectrlproxy'},
    {key:'bmc-data-coll',value:'bmc-data-coll'},
    {key:'bmc-messaging',value:'bmc-messaging'},
    {key:'bmcpatrolrnvu',value:'bmcpatrolrnvu'},
    {key:'bmc-perf-mgrd',value:'bmc-perf-mgrd'},
    {key:'bmc-reporting',value:'bmc-reporting'},
    {key:'b-novative-ls',value:'b-novative-ls'},
    {key:'bridgecontrol',value:'bridgecontrol'},
    {key:'bsfsvr-zn-ssl',value:'bsfsvr-zn-ssl'},
    {key:'btpp2sectrans',value:'btpp2sectrans'},
    {key:'bts-appserver',value:'bts-appserver'},
    {key:'cadkey-licman',value:'cadkey-licman'},
    {key:'cadkey-tablet',value:'cadkey-tablet'},
    {key:'caistoragemgr',value:'caistoragemgr'},
    {key:'ca-web-update',value:'ca-web-update'},
    {key:'cd3o-protocol',value:'cd3o-protocol'},
    {key:'checkmk-agent',value:'checkmk-agent'},
    {key:'childkey-ctrl',value:'childkey-ctrl'},
    {key:'cinegrfx-elmd',value:'cinegrfx-elmd'},
    {key:'cip4discovery',value:'cip4discovery'},
    {key:'cl-db-request',value:'cl-db-request'},
    {key:'cleanerliverc',value:'cleanerliverc'},
    {key:'clever-ctrace',value:'clever-ctrace'},
    {key:'client-wakeup',value:'client-wakeup'},
    {key:'cloanto-net-1',value:'cloanto-net-1'},
    {key:'com-bardac-dw',value:'com-bardac-dw'},
    {key:'commplex-link',value:'commplex-link'},
    {key:'commplex-main',value:'commplex-main'},
    {key:'commtact-http',value:'commtact-http'},
    {key:'concurrent-lm',value:'concurrent-lm'},
    {key:'conductor-mpx',value:'conductor-mpx'},
    {key:'contactserver',value:'contactserver'},
    {key:'contentserver',value:'contentserver'},
    {key:'coolanetaudio',value:'coolanetaudio'},
    {key:'cpdi-pidas-cm',value:'cpdi-pidas-cm'},
    {key:'cpqrpm-server',value:'cpqrpm-server'},
    {key:'cpq-tasksmart',value:'cpq-tasksmart'},
    {key:'crestron-cips',value:'crestron-cips'},
    {key:'crestron-ctps',value:'crestron-ctps'},
    {key:'cruise-config',value:'cruise-config'},
    {key:'cruise-update',value:'cruise-update'},
    {key:'cr-websystems',value:'cr-websystems'},
    {key:'csvr-sslproxy',value:'csvr-sslproxy'},
    {key:'cumulus-admin',value:'cumulus-admin'},
    {key:'d2k-tapestry1',value:'d2k-tapestry1'},
    {key:'d2k-tapestry2',value:'d2k-tapestry2'},
    {key:'dataprotector',value:'dataprotector'},
    {key:'datascaler-db',value:'datascaler-db'},
    {key:'dayliteserver',value:'dayliteserver'},
    {key:'dbcontrol-oms',value:'dbcontrol-oms'},
    {key:'dbisamserver1',value:'dbisamserver1'},
    {key:'dbisamserver2',value:'dbisamserver2'},
    {key:'dbsyncarbiter',value:'dbsyncarbiter'},
    {key:'dec-mbadmin-h',value:'dec-mbadmin-h'},
    {key:'decvms-sysmgt',value:'decvms-sysmgt'},
    {key:'denali-server',value:'denali-server'},
    {key:'deploymentmap',value:'deploymentmap'},
    {key:'dhcp-failover',value:'dhcp-failover'},
    {key:'dhcpv6-client',value:'dhcpv6-client'},
    {key:'dhcpv6-server',value:'dhcpv6-server'},
    {key:'diagnose-proc',value:'diagnose-proc'},
    {key:'dialogic-elmd',value:'dialogic-elmd'},
    {key:'direcpc-video',value:'direcpc-video'},
    {key:'directv-catlg',value:'directv-catlg'},
    {key:'dns-query-tls',value:'dns-query-tls'},
    {key:'doglms-notify',value:'doglms-notify'},
    {key:'dsmcc-session',value:'dsmcc-session'},
    {key:'dtserver-port',value:'dtserver-port'},
    {key:'dx-instrument',value:'dx-instrument'},
    {key:'e3consultants',value:'e3consultants'},
    {key:'ea-dttx-poker',value:'ea-dttx-poker'},
    {key:'easy-soft-mux',value:'easy-soft-mux'},
    {key:'ecolor-imager',value:'ecolor-imager'},
    {key:'ecovisiong6-1',value:'ecovisiong6-1'},
    {key:'edm-mgr-cntrl',value:'edm-mgr-cntrl'},
    {key:'efidiningport',value:'efidiningport'},
    {key:'emcsymapiport',value:'emcsymapiport'},
    {key:'empire-empuma',value:'empire-empuma'},
    {key:'enphase-envoy',value:'enphase-envoy'},
    {key:'enrp-sctp-tls',value:'enrp-sctp-tls'},
    {key:'eportcommdata',value:'eportcommdata'},
    {key:'EtherNet/IP-1',value:'EtherNet/IP-1'},
    {key:'EtherNet/IP-2',value:'EtherNet/IP-2'},
    {key:'EtherNet-IP-1',value:'EtherNet-IP-1'},
    {key:'EtherNet-IP-2',value:'EtherNet-IP-2'},
    {key:'ethernet-ip-s',value:'ethernet-ip-s'},
    {key:'etlservicemgr',value:'etlservicemgr'},
    {key:'f5-globalsite',value:'f5-globalsite'},
    {key:'facsys-router',value:'facsys-router'},
    {key:'fast-rem-serv',value:'fast-rem-serv'},
    {key:'faxcomservice',value:'faxcomservice'},
    {key:'fcopys-server',value:'fcopys-server'},
    {key:'feitianrockey',value:'feitianrockey'},
    {key:'filenet-obrok',value:'filenet-obrok'},
    {key:'filenet-peior',value:'filenet-peior'},
    {key:'first-defense',value:'first-defense'},
    {key:'fjippol-port1',value:'fjippol-port1'},
    {key:'fjippol-port2',value:'fjippol-port2'},
    {key:'fjippol-swrly',value:'fjippol-swrly'},
    {key:'fontagentnode',value:'fontagentnode'},
    {key:'foxtrot-start',value:'foxtrot-start'},
    {key:'frc-hicp-disc',value:'frc-hicp-disc'},
    {key:'fujitsu-dtcns',value:'fujitsu-dtcns'},
    {key:'fujitsu-mmpdc',value:'fujitsu-mmpdc'},
    {key:'fxaengine-net',value:'fxaengine-net'},
    {key:'g2metric-lynx',value:'g2metric-lynx'},
    {key:'galaxy-server',value:'galaxy-server'},
    {key:'gammafetchsvr',value:'gammafetchsvr'},
    {key:'garmin-marine',value:'garmin-marine'},
    {key:'gds-adppiw-db',value:'gds-adppiw-db'},
    {key:'global-dtserv',value:'global-dtserv'},
    {key:'gmrupdateserv',value:'gmrupdateserv'},
    {key:'goahead-fldup',value:'goahead-fldup'},
    {key:'gsigatekeeper',value:'gsigatekeeper'},
    {key:'gtrack-server',value:'gtrack-server'},
    {key:'gxs-data-port',value:'gxs-data-port'},
    {key:'h2250-annex-g',value:'h2250-annex-g'},
    {key:'hde-lcesrvr-1',value:'hde-lcesrvr-1'},
    {key:'hde-lcesrvr-2',value:'hde-lcesrvr-2'},
    {key:'herodotus-net',value:'herodotus-net'},
    {key:'high-criteria',value:'high-criteria'},
    {key:'hncp-udp-port',value:'hncp-udp-port'},
    {key:'honeywell-vid',value:'honeywell-vid'},
    {key:'hpoms-ci-lstn',value:'hpoms-ci-lstn'},
    {key:'hyperwave-isp',value:'hyperwave-isp'},
    {key:'i3-sessionmgr',value:'i3-sessionmgr'},
    {key:'ibm-mqseries2',value:'ibm-mqseries2'},
    {key:'ice-slocation',value:'ice-slocation'},
    {key:'icl-twobase10',value:'icl-twobase10'},
    {key:'icon-discover',value:'icon-discover'},
    {key:'iconstructsrv',value:'iconstructsrv'},
    {key:'ida-discover1',value:'ida-discover1'},
    {key:'ida-discover2',value:'ida-discover2'},
    {key:'ideafarm-door',value:'ideafarm-door'},
    {key:'idware-router',value:'idware-router'},
    {key:'ifor-protocol',value:'ifor-protocol'},
    {key:'igo-incognito',value:'igo-incognito'},
    {key:'imip-channels',value:'imip-channels'},
    {key:'indigo-server',value:'indigo-server'},
    {key:'informatik-lm',value:'informatik-lm'},
    {key:'ininmessaging',value:'ininmessaging'},
    {key:'iniserve-port',value:'iniserve-port'},
    {key:'inova-ontrack',value:'inova-ontrack'},
    {key:'integrius-stp',value:'integrius-stp'},
    {key:'interhdl_elmd',value:'interhdl_elmd'},
    {key:'interhdl-elmd',value:'interhdl-elmd'},
    {key:'int-rcv-cntrl',value:'int-rcv-cntrl'},
    {key:'io-dist-group',value:'io-dist-group'},
    {key:'ipbroadcaster',value:'ipbroadcaster'},
    {key:'ipt-anri-anri',value:'ipt-anri-anri'},
    {key:'iracinghelper',value:'iracinghelper'},
    {key:'ivs-insertion',value:'ivs-insertion'},
    {key:'jamserverport',value:'jamserverport'},
    {key:'jaxer-manager',value:'jaxer-manager'},
    {key:'jdl-dbkitchen',value:'jdl-dbkitchen'},
    {key:'joaJewelSuite',value:'joaJewelSuite'},
    {key:'jomamqmonitor',value:'jomamqmonitor'},
    {key:'jtnetd-server',value:'jtnetd-server'},
    {key:'jtnetd-status',value:'jtnetd-status'},
    {key:'keynoteaccess',value:'keynoteaccess'},
    {key:'kjtsiteserver',value:'kjtsiteserver'},
    {key:'kme-trap-port',value:'kme-trap-port'},
    {key:'ktickets-rest',value:'ktickets-rest'},
    {key:'kti-icad-srvr',value:'kti-icad-srvr'},
    {key:'kyoceranetdev',value:'kyoceranetdev'},
    {key:'l5nas-parchan',value:'l5nas-parchan'},
    {key:'lan900_remote',value:'lan900_remote'},
    {key:'lan900-remote',value:'lan900-remote'},
    {key:'lanschool-mpt',value:'lanschool-mpt'},
    {key:'launchbird-lm',value:'launchbird-lm'},
    {key:'licensedaemon',value:'licensedaemon'},
    {key:'LiebDevMgmt_A',value:'LiebDevMgmt_A'},
    {key:'LiebDevMgmt_C',value:'LiebDevMgmt_C'},
    {key:'LiebDevMgmt-A',value:'LiebDevMgmt-A'},
    {key:'LiebDevMgmt-C',value:'LiebDevMgmt-C'},
    {key:'lispworks-orb',value:'lispworks-orb'},
    {key:'llsurfup-http',value:'llsurfup-http'},
    {key:'lm-webwatcher',value:'lm-webwatcher'},
    {key:'localinfosrvr',value:'localinfosrvr'},
    {key:'lontalk-urgnt',value:'lontalk-urgnt'},
    {key:'lorica-in-sec',value:'lorica-in-sec'},
    {key:'lot105-ds-upd',value:'lot105-ds-upd'},
    {key:'lsi-raid-mgmt',value:'lsi-raid-mgmt'},
    {key:'lsp-self-ping',value:'lsp-self-ping'},
    {key:'ltp-deepspace',value:'ltp-deepspace'},
    {key:'lv-frontpanel',value:'lv-frontpanel'},
    {key:'m4-network-as',value:'m4-network-as'},
    {key:'macfoh-events',value:'macfoh-events'},
    {key:'macfoh-remote',value:'macfoh-remote'},
    {key:'magenta-logic',value:'magenta-logic'},
    {key:'maytagshuffle',value:'maytagshuffle'},
    {key:'mcs-messaging',value:'mcs-messaging'},
    {key:'mctet-gateway',value:'mctet-gateway'},
    {key:'mdnsresponder',value:'mdnsresponder'},
    {key:'mgcs-mfp-port',value:'mgcs-mfp-port'},
    {key:'mgemanagement',value:'mgemanagement'},
    {key:'mib-streaming',value:'mib-streaming'},
    {key:'midnight-tech',value:'midnight-tech'},
    {key:'mielesemiprof',value:'mielesemiprof'},
    {key:'mkm-discovery',value:'mkm-discovery'},
    {key:'mma-discovery',value:'mma-discovery'},
    {key:'mni-prot-rout',value:'mni-prot-rout'},
    {key:'mogeneti-auth',value:'mogeneti-auth'},
    {key:'monetra-admin',value:'monetra-admin'},
    {key:'mosaicsyssvc1',value:'mosaicsyssvc1'},
    {key:'mpl-gprs-port',value:'mpl-gprs-port'},
    {key:'mpls-udp-dtls',value:'mpls-udp-dtls'},
    {key:'msft-dpm-cert',value:'msft-dpm-cert'},
    {key:'ms-sna-server',value:'ms-sna-server'},
    {key:'ms-s-sideshow',value:'ms-s-sideshow'},
    {key:'ms-wbt-server',value:'ms-wbt-server'},
    {key:'mtport-regist',value:'mtport-regist'},
    {key:'mysql-cluster',value:'mysql-cluster'},
    {key:'ncount-issuer',value:'ncount-issuer'},
    {key:'ndm-requester',value:'ndm-requester'},
    {key:'nest-protocol',value:'nest-protocol'},
    {key:'netapp-icdata',value:'netapp-icdata'},
    {key:'netapp-icmgmt',value:'netapp-icmgmt'},
    {key:'netattachsdmp',value:'netattachsdmp'},
    {key:'netbill-trans',value:'netbill-trans'},
    {key:'netinfo-local',value:'netinfo-local'},
    {key:'netmo-default',value:'netmo-default'},
    {key:'netops-broker',value:'netops-broker'},
    {key:'netplay-port1',value:'netplay-port1'},
    {key:'netplay-port2',value:'netplay-port2'},
    {key:'netserialext1',value:'netserialext1'},
    {key:'netserialext2',value:'netserialext2'},
    {key:'netserialext3',value:'netserialext3'},
    {key:'netserialext4',value:'netserialext4'},
    {key:'netview-aix-1',value:'netview-aix-1'},
    {key:'netview-aix-2',value:'netview-aix-2'},
    {key:'netview-aix-3',value:'netview-aix-3'},
    {key:'netview-aix-4',value:'netview-aix-4'},
    {key:'netview-aix-5',value:'netview-aix-5'},
    {key:'netview-aix-6',value:'netview-aix-6'},
    {key:'netview-aix-7',value:'netview-aix-7'},
    {key:'netview-aix-8',value:'netview-aix-8'},
    {key:'netview-aix-9',value:'netview-aix-9'},
    {key:'netwatcher-db',value:'netwatcher-db'},
    {key:'newbay-snc-mc',value:'newbay-snc-mc'},
    {key:'newwavesearch',value:'newwavesearch'},
    {key:'nexstorindltd',value:'nexstorindltd'},
    {key:'nicetec-nmsvc',value:'nicetec-nmsvc'},
    {key:'nilinkanalyst',value:'nilinkanalyst'},
    {key:'nimcontroller',value:'nimcontroller'},
    {key:'nm-game-admin',value:'nm-game-admin'},
    {key:'nms_topo_serv',value:'nms_topo_serv'},
    {key:'nms-topo-serv',value:'nms-topo-serv'},
    {key:'nokia-ann-ch1',value:'nokia-ann-ch1'},
    {key:'nokia-ann-ch2',value:'nokia-ann-ch2'},
    {key:'notateit-disc',value:'notateit-disc'},
    {key:'notezilla-lan',value:'notezilla-lan'},
    {key:'ns-cfg-server',value:'ns-cfg-server'},
    {key:'o2server-port',value:'o2server-port'},
    {key:'objective-dbc',value:'objective-dbc'},
    {key:'objectmanager',value:'objectmanager'},
    {key:'oce-snmp-trap',value:'oce-snmp-trap'},
    {key:'odeumservlink',value:'odeumservlink'},
    {key:'omni-bookmark',value:'omni-bookmark'},
    {key:'omnilink-port',value:'omnilink-port'},
    {key:'omnivisionesx',value:'omnivisionesx'},
    {key:'oms-nonsecure',value:'oms-nonsecure'},
    {key:'opc-job-start',value:'opc-job-start'},
    {key:'opc-job-track',value:'opc-job-track'},
    {key:'opentelemetry',value:'opentelemetry'},
    {key:'opsview-envoy',value:'opsview-envoy'},
    {key:'optika-emedia',value:'optika-emedia'},
    {key:'opus-services',value:'opus-services'},
    {key:'oracle-ms-ens',value:'oracle-ms-ens'},
    {key:'orbix-cfg-ssl',value:'orbix-cfg-ssl'},
    {key:'orbix-locator',value:'orbix-locator'},
    {key:'orbix-loc-ssl',value:'orbix-loc-ssl'},
    {key:'ordinox-dbase',value:'ordinox-dbase'},
    {key:'orion-rmi-reg',value:'orion-rmi-reg'},
    {key:'ov-nnm-websrv',value:'ov-nnm-websrv'},
    {key:'ovsam-d-agent',value:'ovsam-d-agent'},
    {key:'owamp-control',value:'owamp-control'},
    {key:'pace-licensed',value:'pace-licensed'},
    {key:'parentcontrol',value:'parentcontrol'},
    {key:'parsec-master',value:'parsec-master'},
    {key:'passgo-tivoli',value:'passgo-tivoli'},
    {key:'pcp-multicast',value:'pcp-multicast'},
    {key:'pcptcpservice',value:'pcptcpservice'},
    {key:'pcs-sf-ui-man',value:'pcs-sf-ui-man'},
    {key:'pearldoc-xact',value:'pearldoc-xact'},
    {key:'peerbook-port',value:'peerbook-port'},
    {key:'personal-link',value:'personal-link'},
    {key:'pgpkey-mailto',value:'pgpkey-mailto'},
    {key:'pktcable-cops',value:'pktcable-cops'},
    {key:'pkt-krb-ipsec',value:'pkt-krb-ipsec'},
    {key:'plbserve-port',value:'plbserve-port'},
    {key:'plcy-net-svcs',value:'plcy-net-svcs'},
    {key:'pnaconsult-lm',value:'pnaconsult-lm'},
    {key:'pn-requester2',value:'pn-requester2'},
    {key:'portgate-auth',value:'portgate-auth'},
    {key:'powereasy-erp',value:'powereasy-erp'},
    {key:'powereasy-pos',value:'powereasy-pos'},
    {key:'powerexchange',value:'powerexchange'},
    {key:'powerguardian',value:'powerguardian'},
    {key:'powwow-client',value:'powwow-client'},
    {key:'powwow-server',value:'powwow-server'},
    {key:'pptconference',value:'pptconference'},
    {key:'prchat-server',value:'prchat-server'},
    {key:'printer_agent',value:'printer_agent'},
    {key:'printer-agent',value:'printer-agent'},
    {key:'proactivesrvr',value:'proactivesrvr'},
    {key:'propel-msgsys',value:'propel-msgsys'},
    {key:'proshareaudio',value:'proshareaudio'},
    {key:'proshare-mc-1',value:'proshare-mc-1'},
    {key:'proshare-mc-2',value:'proshare-mc-2'},
    {key:'prosharevideo',value:'prosharevideo'},
    {key:'proxy-gateway',value:'proxy-gateway'},
    {key:'qftest-lookup',value:'qftest-lookup'},
    {key:'quasar-server',value:'quasar-server'},
    {key:'quickbooksrds',value:'quickbooksrds'},
    {key:'radclientport',value:'radclientport'},
    {key:'radware-rpm-s',value:'radware-rpm-s'},
    {key:'rbr-discovery',value:'rbr-discovery'},
    {key:'rdmnet-device',value:'rdmnet-device'},
    {key:'re-conn-proto',value:'re-conn-proto'},
    {key:'recvr-rc-disc',value:'recvr-rc-disc'},
    {key:'redstone-cpss',value:'redstone-cpss'},
    {key:'redstorm_diag',value:'redstorm_diag'},
    {key:'redstorm_find',value:'redstorm_find'},
    {key:'redstorm_info',value:'redstorm_info'},
    {key:'redstorm_join',value:'redstorm_join'},
    {key:'redstorm-diag',value:'redstorm-diag'},
    {key:'redstorm-find',value:'redstorm-find'},
    {key:'redstorm-info',value:'redstorm-info'},
    {key:'redstorm-join',value:'redstorm-join'},
    {key:'reload-config',value:'reload-config'},
    {key:'remote-collab',value:'remote-collab'},
    {key:'remoteware-cl',value:'remoteware-cl'},
    {key:'remoteware-un',value:'remoteware-un'},
    {key:'resacommunity',value:'resacommunity'},
    {key:'responselogic',value:'responselogic'},
    {key:'rmiactivation',value:'rmiactivation'},
    {key:'rockwell-csp2',value:'rockwell-csp2'},
    {key:'roomcast-capi',value:'roomcast-capi'},
    {key:'roomcast-mapi',value:'roomcast-mapi'},
    {key:'rp-reputation',value:'rp-reputation'},
    {key:'rt-labtracker',value:'rt-labtracker'},
    {key:'rusb-sys-port',value:'rusb-sys-port'},
    {key:'sallingbridge',value:'sallingbridge'},
    {key:'salutafugijms',value:'salutafugijms'},
    {key:'scientia-ssdb',value:'scientia-ssdb'},
    {key:'scipticslsrvr',value:'scipticslsrvr'},
    {key:'sde-discovery',value:'sde-discovery'},
    {key:'sec-t4net-clt',value:'sec-t4net-clt'},
    {key:'sec-t4net-srv',value:'sec-t4net-srv'},
    {key:'securitychase',value:'securitychase'},
    {key:'sercomm-wlink',value:'sercomm-wlink'},
    {key:'serialgateway',value:'serialgateway'},
    {key:'serverview-as',value:'serverview-as'},
    {key:'serverview-gf',value:'serverview-gf'},
    {key:'serverview-rm',value:'serverview-rm'},
    {key:'sfm-db-server',value:'sfm-db-server'},
    {key:'sgi-eventmond',value:'sgi-eventmond'},
    {key:'shaperai-disc',value:'shaperai-disc'},
    {key:'shiprush-d-ch',value:'shiprush-d-ch'},
    {key:'shrewd-stream',value:'shrewd-stream'},
    {key:'silverplatter',value:'silverplatter'},
    {key:'simbaservices',value:'simbaservices'},
    {key:'simple-push-s',value:'simple-push-s'},
    {key:'simplifymedia',value:'simplifymedia'},
    {key:'skillscapture',value:'skillscapture'},
    {key:'sky-transport',value:'sky-transport'},
    {key:'slc-systemlog',value:'slc-systemlog'},
    {key:'smar-se-port1',value:'smar-se-port1'},
    {key:'smar-se-port2',value:'smar-se-port2'},
    {key:'smartcard-tls',value:'smartcard-tls'},
    {key:'smart-install',value:'smart-install'},
    {key:'snifferclient',value:'snifferclient'},
    {key:'snifferserver',value:'snifferserver'},
    {key:'snmpdtls-trap',value:'snmpdtls-trap'},
    {key:'snmp-tcp-port',value:'snmp-tcp-port'},
    {key:'softdataphone',value:'softdataphone'},
    {key:'solaris-audit',value:'solaris-audit'},
    {key:'sonus-logging',value:'sonus-logging'},
    {key:'sossd-collect',value:'sossd-collect'},
    {key:'soundsvirtual',value:'soundsvirtual'},
    {key:'srvc_registry',value:'srvc_registry'},
    {key:'srvc-registry',value:'srvc-registry'},
    {key:'ssports-bcast',value:'ssports-bcast'},
    {key:'ssr-servermgr',value:'ssr-servermgr'},
    {key:'ssscreenshare',value:'ssscreenshare'},
    {key:'starquiz-port',value:'starquiz-port'},
    {key:'start-network',value:'start-network'},
    {key:'streamcomm-ds',value:'streamcomm-ds'},
    {key:'streetperfect',value:'streetperfect'},
    {key:'street-stream',value:'street-stream'},
    {key:'stun-behavior',value:'stun-behavior'},
    {key:'sugarlock-rcp',value:'sugarlock-rcp'},
    {key:'sun-as-jmxrmi',value:'sun-as-jmxrmi'},
    {key:'suncacao-snmp',value:'suncacao-snmp'},
    {key:'sunclustergeo',value:'sunclustergeo'},
    {key:'sunclustermgr',value:'sunclustermgr'},
    {key:'sunproxyadmin',value:'sunproxyadmin'},
    {key:'sunscalar-dns',value:'sunscalar-dns'},
    {key:'sunscalar-svc',value:'sunscalar-svc'},
    {key:'supportassist',value:'supportassist'},
    {key:'sybasedbsynch',value:'sybasedbsynch'},
    {key:'sybase-sqlany',value:'sybase-sqlany'},
    {key:'symantec-sfdb',value:'symantec-sfdb'},
    {key:'synapse-nhttp',value:'synapse-nhttp'},
    {key:'synapsis-edge',value:'synapsis-edge'},
    {key:'synchronet-db',value:'synchronet-db'},
    {key:'syncserverssl',value:'syncserverssl'},
    {key:'systemics-sox',value:'systemics-sox'},
    {key:'t1-e1-over-ip',value:'t1-e1-over-ip'},
    {key:'taskcoachsync',value:'taskcoachsync'},
    {key:'tcoflashagent',value:'tcoflashagent'},
    {key:'tcpdataserver',value:'tcpdataserver'},
    {key:'tcpnethaspsrv',value:'tcpnethaspsrv'},
    {key:'teamcoherence',value:'teamcoherence'},
    {key:'tesla-sys-msg',value:'tesla-sys-msg'},
    {key:'timbuktu-srv1',value:'timbuktu-srv1'},
    {key:'timbuktu-srv2',value:'timbuktu-srv2'},
    {key:'timbuktu-srv3',value:'timbuktu-srv3'},
    {key:'timbuktu-srv4',value:'timbuktu-srv4'},
    {key:'tmesis-upshot',value:'tmesis-upshot'},
    {key:'tmo-icon-sync',value:'tmo-icon-sync'},
    {key:'transmit-port',value:'transmit-port'},
    {key:'trap-port-mom',value:'trap-port-mom'},
    {key:'trendchip-dcp',value:'trendchip-dcp'},
    {key:'trinket-agent',value:'trinket-agent'},
    {key:'trnsprntproxy',value:'trnsprntproxy'},
    {key:'ttlpriceproxy',value:'ttlpriceproxy'},
    {key:'ttl-publisher',value:'ttl-publisher'},
    {key:'ttnrepository',value:'ttnrepository'},
    {key:'tungsten-http',value:'tungsten-http'},
    {key:'twamp-control',value:'twamp-control'},
    {key:'ufastro-instr',value:'ufastro-instr'},
    {key:'unify-adapter',value:'unify-adapter'},
    {key:'unihub-server',value:'unihub-server'},
    {key:'unimobilectrl',value:'unimobilectrl'},
    {key:'upsnotifyprot',value:'upsnotifyprot'},
    {key:'upstriggervsw',value:'upstriggervsw'},
    {key:'usp-agt-stomp',value:'usp-agt-stomp'},
    {key:'usp-ctr-stomp',value:'usp-ctr-stomp'},
    {key:'vision_server',value:'vision_server'},
    {key:'visionpyramid',value:'visionpyramid'},
    {key:'vision-server',value:'vision-server'},
    {key:'vistium-share',value:'vistium-share'},
    {key:'vitalanalysis',value:'vitalanalysis'},
    {key:'vocaltec-gold',value:'vocaltec-gold'},
    {key:'voispeed-port',value:'voispeed-port'},
    {key:'vrts-registry',value:'vrts-registry'},
    {key:'vue4rendercow',value:'vue4rendercow'},
    {key:'wap-push-http',value:'wap-push-http'},
    {key:'wap-wsp-wtp-s',value:'wap-wsp-wtp-s'},
    {key:'warehouse-sss',value:'warehouse-sss'},
    {key:'westell-stats',value:'westell-stats'},
    {key:'winpoplanmess',value:'winpoplanmess'},
    {key:'wms-messenger',value:'wms-messenger'},
    {key:'wpl-analytics',value:'wpl-analytics'},
    {key:'wta-wsp-wtp-s',value:'wta-wsp-wtp-s'},
    {key:'wtc-viscostar',value:'wtc-viscostar'},
    {key:'xprint-server',value:'xprint-server'},
    {key:'xrpc-registry',value:'xrpc-registry'},
    {key:'zigbee-bridge',value:'zigbee-bridge'},
    {key:'z-wave-tunnel',value:'z-wave-tunnel'},
    {key:'abi-instrument',value:'abi-instrument'},
    {key:'accelenet-data',value:'accelenet-data'},
    {key:'accessdata-f2d',value:'accessdata-f2d'},
    {key:'accessdata-f2w',value:'accessdata-f2w'},
    {key:'accuracer-dbms',value:'accuracer-dbms'},
    {key:'acmaint_transd',value:'acmaint_transd'},
    {key:'acmaint-transd',value:'acmaint-transd'},
    {key:'acronis-backup',value:'acronis-backup'},
    {key:'activeraid-ssl',value:'activeraid-ssl'},
    {key:'addressbooksrv',value:'addressbooksrv'},
    {key:'adi-gxp-srvprt',value:'adi-gxp-srvprt'},
    {key:'adtempusclient',value:'adtempusclient'},
    {key:'aeroflight-ads',value:'aeroflight-ads'},
    {key:'aeroflight-ret',value:'aeroflight-ret'},
    {key:'aerohive-proxy',value:'aerohive-proxy'},
    {key:'afore-vdp-disc',value:'afore-vdp-disc'},
    {key:'aimpp-port-req',value:'aimpp-port-req'},
    {key:'alazartech-atn',value:'alazartech-atn'},
    {key:'altova-lm-disc',value:'altova-lm-disc'},
    {key:'altserviceboot',value:'altserviceboot'},
    {key:'amahi-anywhere',value:'amahi-anywhere'},
    {key:'amicon-fpsu-ra',value:'amicon-fpsu-ra'},
    {key:'amt-soap-https',value:'amt-soap-https'},
    {key:'antidotemgrsvr',value:'antidotemgrsvr'},
    {key:'appletv-itunes',value:'appletv-itunes'},
    {key:'appleugcontrol',value:'appleugcontrol'},
    {key:'apwi-rxspooler',value:'apwi-rxspooler'},
    {key:'armcenterhttps',value:'armcenterhttps'},
    {key:'asgcypresstcps',value:'asgcypresstcps'},
    {key:'asnaacceler8db',value:'asnaacceler8db'},
    {key:'aspen-services',value:'aspen-services'},
    {key:'astergate-disc',value:'astergate-disc'},
    {key:'astronova-main',value:'astronova-main'},
    {key:'ati-ip-to-ncpe',value:'ati-ip-to-ncpe'},
    {key:'atm-zip-office',value:'atm-zip-office'},
    {key:'attachmate-g32',value:'attachmate-g32'},
    {key:'attachmate-s2s',value:'attachmate-s2s'},
    {key:'attachmate-uts',value:'attachmate-uts'},
    {key:'audit-transfer',value:'audit-transfer'},
    {key:'aurora-balaena',value:'aurora-balaena'},
    {key:'autopassdaemon',value:'autopassdaemon'},
    {key:'autosendimages',value:'autosendimages'},
    {key:'autotargets-ap',value:'autotargets-ap'},
    {key:'avauthsrvprtcl',value:'avauthsrvprtcl'},
    {key:'axis-wimp-port',value:'axis-wimp-port'},
    {key:'backup-express',value:'backup-express'},
    {key:'bandwiz-system',value:'bandwiz-system'},
    {key:'bcinameservice',value:'bcinameservice'},
    {key:'behnke-station',value:'behnke-station'},
    {key:'beserver-msg-q',value:'beserver-msg-q'},
    {key:'bigbangmancala',value:'bigbangmancala'},
    {key:'bilobit-update',value:'bilobit-update'},
    {key:'binderysupport',value:'binderysupport'},
    {key:'bmcpatrolagent',value:'bmcpatrolagent'},
    {key:'bmc-perf-agent',value:'bmc-perf-agent'},
    {key:'boe-processsvr',value:'boe-processsvr'},
    {key:'boxbackupstore',value:'boxbackupstore'},
    {key:'brcm-comm-port',value:'brcm-comm-port'},
    {key:'bridgeprotocol',value:'bridgeprotocol'},
    {key:'broker_service',value:'broker_service'},
    {key:'broker-service',value:'broker-service'},
    {key:'buddycloud-api',value:'buddycloud-api'},
    {key:'bvcdaemon-port',value:'bvcdaemon-port'},
    {key:'bv-queryengine',value:'bv-queryengine'},
    {key:'cadencecontrol',value:'cadencecontrol'},
    {key:'cajo-discovery',value:'cajo-discovery'},
    {key:'call-sig-trans',value:'call-sig-trans'},
    {key:'can-ferret-ssl',value:'can-ferret-ssl'},
    {key:'canon-cpp-disc',value:'canon-cpp-disc'},
    {key:'canto-roboflow',value:'canto-roboflow'},
    {key:'capwap-control',value:'capwap-control'},
    {key:'carrius-rshell',value:'carrius-rshell'},
    {key:'cequint-cityid',value:'cequint-cityid'},
    {key:'cernsysmgmtagt',value:'cernsysmgmtagt'},
    {key:'cert-initiator',value:'cert-initiator'},
    {key:'cert-responder',value:'cert-responder'},
    {key:'checkpoint-rtm',value:'checkpoint-rtm'},
    {key:'chevinservices',value:'chevinservices'},
    {key:'childkey-notif',value:'childkey-notif'},
    {key:'choiceview-agt',value:'choiceview-agt'},
    {key:'choiceview-clt',value:'choiceview-clt'},
    {key:'cisco-cloudsec',value:'cisco-cloudsec'},
    {key:'cisco-net-mgmt',value:'cisco-net-mgmt'},
    {key:'clariion-evr01',value:'clariion-evr01'},
    {key:'cloudsignaling',value:'cloudsignaling'},
    {key:'cognex-dataman',value:'cognex-dataman'},
    {key:'cognex-insight',value:'cognex-insight'},
    {key:'coherence-disc',value:'coherence-disc'},
    {key:'cohesity-agent',value:'cohesity-agent'},
    {key:'combox-web-acc',value:'combox-web-acc'},
    {key:'commtact-https',value:'commtact-https'},
    {key:'com-ocs-es-mcc',value:'com-ocs-es-mcc'},
    {key:'comotionmaster',value:'comotionmaster'},
    {key:'compx-lockview',value:'compx-lockview'},
    {key:'conferencetalk',value:'conferencetalk'},
    {key:'connect-client',value:'connect-client'},
    {key:'connect-server',value:'connect-server'},
    {key:'consul-insight',value:'consul-insight'},
    {key:'controlone-con',value:'controlone-con'},
    {key:'corba-iiop-ssl',value:'corba-iiop-ssl'},
    {key:'corel_vncadmin',value:'corel_vncadmin'},
    {key:'corel-vncadmin',value:'corel-vncadmin'},
    {key:'core-of-source',value:'core-of-source'},
    {key:'creativepartnr',value:'creativepartnr'},
    {key:'creativeserver',value:'creativeserver'},
    {key:'cresco-control',value:'cresco-control'},
    {key:'cruise-swroute',value:'cruise-swroute'},
    {key:'csbphonemaster',value:'csbphonemaster'},
    {key:'csoft-plusclnt',value:'csoft-plusclnt'},
    {key:'ctechlicensing',value:'ctechlicensing'},
    {key:'ctiprogramload',value:'ctiprogramload'},
    {key:'cyborg-systems',value:'cyborg-systems'},
    {key:'d2000webserver',value:'d2000webserver'},
    {key:'d2k-datamover1',value:'d2k-datamover1'},
    {key:'d2k-datamover2',value:'d2k-datamover2'},
    {key:'darkhorsetimer',value:'darkhorsetimer'},
    {key:'data-insurance',value:'data-insurance'},
    {key:'datascaler-ctl',value:'datascaler-ctl'},
    {key:'datasurfsrvsec',value:'datasurfsrvsec'},
    {key:'d-data-control',value:'d-data-control'},
    {key:'de-cache-query',value:'de-cache-query'},
    {key:'dell-soss-ds-w',value:'dell-soss-ds-w'},
    {key:'dellwebadmin-1',value:'dellwebadmin-1'},
    {key:'dellwebadmin-2',value:'dellwebadmin-2'},
    {key:'demoncamremote',value:'demoncamremote'},
    {key:'dhcp-failover2',value:'dhcp-failover2'},
    {key:'dialpad-voice1',value:'dialpad-voice1'},
    {key:'dialpad-voice2',value:'dialpad-voice2'},
    {key:'digilent-adept',value:'digilent-adept'},
    {key:'digital-notary',value:'digital-notary'},
    {key:'directplaysrvr',value:'directplaysrvr'},
    {key:'discovery-port',value:'discovery-port'},
    {key:'dmod-workspace',value:'dmod-workspace'},
    {key:'dns-update-tls',value:'dns-update-tls'},
    {key:'dof-dps-mc-sec',value:'dof-dps-mc-sec'},
    {key:'dof-tunnel-sec',value:'dof-tunnel-sec'},
    {key:'dots-call-home',value:'dots-call-home'},
    {key:'downtools-disc',value:'downtools-disc'},
    {key:'driveappserver',value:'driveappserver'},
    {key:'drm-production',value:'drm-production'},
    {key:'dslremote-mgmt',value:'dslremote-mgmt'},
    {key:'dsmcc-download',value:'dsmcc-download'},
    {key:'dsmcc-passthru',value:'dsmcc-passthru'},
    {key:'dsm-scm-target',value:'dsm-scm-target'},
    {key:'dvl-activemail',value:'dvl-activemail'},
    {key:'dxmessagebase1',value:'dxmessagebase1'},
    {key:'dxmessagebase2',value:'dxmessagebase2'},
    {key:'edm-adm-notify',value:'edm-adm-notify'},
    {key:'edm-std-notify',value:'edm-std-notify'},
    {key:'elektron-admin',value:'elektron-admin'},
    {key:'emc-pp-mgmtsvc',value:'emc-pp-mgmtsvc'},
    {key:'emc-xsw-dcache',value:'emc-xsw-dcache'},
    {key:'encrypted-llrp',value:'encrypted-llrp'},
    {key:'enguity-xccetp',value:'enguity-xccetp'},
    {key:'eq-office-4940',value:'eq-office-4940'},
    {key:'eq-office-4941',value:'eq-office-4941'},
    {key:'eq-office-4942',value:'eq-office-4942'},
    {key:'erunbook_agent',value:'erunbook_agent'},
    {key:'erunbook-agent',value:'erunbook-agent'},
    {key:'event_listener',value:'event_listener'},
    {key:'event-listener',value:'event-listener'},
    {key:'faxportwinport',value:'faxportwinport'},
    {key:'fc-faultnotify',value:'fc-faultnotify'},
    {key:'fcp-addr-srvr1',value:'fcp-addr-srvr1'},
    {key:'fcp-addr-srvr2',value:'fcp-addr-srvr2'},
    {key:'fcp-srvr-inst1',value:'fcp-srvr-inst1'},
    {key:'fcp-srvr-inst2',value:'fcp-srvr-inst2'},
    {key:'fibotrader-com',value:'fibotrader-com'},
    {key:'filenet-powsrm',value:'filenet-powsrm'},
    {key:'fiorano-msgsvc',value:'fiorano-msgsvc'},
    {key:'fiorano-rtrsvc',value:'fiorano-rtrsvc'},
    {key:'fjippol-polsvr',value:'fjippol-polsvr'},
    {key:'flamenco-proxy',value:'flamenco-proxy'},
    {key:'fmpro-internal',value:'fmpro-internal'},
    {key:'fmserver-admin',value:'fmserver-admin'},
    {key:'fnet-remote-ui',value:'fnet-remote-ui'},
    {key:'foresyte-clear',value:'foresyte-clear'},
    {key:'fortisphere-vm',value:'fortisphere-vm'},
    {key:'frameforge-lic',value:'frameforge-lic'},
    {key:'freezexservice',value:'freezexservice'},
    {key:'fyre-messanger',value:'fyre-messanger'},
    {key:'gadgetgate1way',value:'gadgetgate1way'},
    {key:'gadgetgate2way',value:'gadgetgate2way'},
    {key:'galaxy-network',value:'galaxy-network'},
    {key:'gamesmith-port',value:'gamesmith-port'},
    {key:'gilatskysurfer',value:'gilatskysurfer'},
    {key:'glasspadserver',value:'glasspadserver'},
    {key:'global-cd-port',value:'global-cd-port'},
    {key:'gruber-cashreg',value:'gruber-cashreg'},
    {key:'h323callsigalt',value:'h323callsigalt'},
    {key:'h323hostcallsc',value:'h323hostcallsc'},
    {key:'haipe-discover',value:'haipe-discover'},
    {key:'health-polling',value:'health-polling'},
    {key:'hncp-dtls-port',value:'hncp-dtls-port'},
    {key:'homeportal-web',value:'homeportal-web'},
    {key:'homesteadglory',value:'homesteadglory'},
    {key:'hp-3000-telnet',value:'hp-3000-telnet'},
    {key:'hp-device-disc',value:'hp-device-disc'},
    {key:'hpoms-dps-lstn',value:'hpoms-dps-lstn'},
    {key:'hp-pdl-datastr',value:'hp-pdl-datastr'},
    {key:'hrpd-ith-at-an',value:'hrpd-ith-at-an'},
    {key:'http-rpc-epmap',value:'http-rpc-epmap'},
    {key:'hyperscsi-port',value:'hyperscsi-port'},
    {key:'ianywhere-dbns',value:'ianywhere-dbns'},
    {key:'iascontrol-oms',value:'iascontrol-oms'},
    {key:'iatp-normalpri',value:'iatp-normalpri'},
    {key:'ibm_wrless_lan',value:'ibm_wrless_lan'},
    {key:'ibm-diradm-ssl',value:'ibm-diradm-ssl'},
    {key:'ibm-wrless-lan',value:'ibm-wrless-lan'},
    {key:'ica-networking',value:'ica-networking'},
    {key:'iclcnet_svinfo',value:'iclcnet_svinfo'},
    {key:'iclcnet-locate',value:'iclcnet-locate'},
    {key:'iclcnet-svinfo',value:'iclcnet-svinfo'},
    {key:'ideafarm-panic',value:'ideafarm-panic'},
    {key:'idonix-metanet',value:'idonix-metanet'},
    {key:'idp-infotrieve',value:'idp-infotrieve'},
    {key:'immedianet-bcn',value:'immedianet-bcn'},
    {key:'i-net-2000-npr',value:'i-net-2000-npr'},
    {key:'infiniswitchcl',value:'infiniswitchcl'},
    {key:'inova-ip-disco',value:'inova-ip-disco'},
    {key:'inst-discovery',value:'inst-discovery'},
    {key:'intellistor-lm',value:'intellistor-lm'},
    {key:'interactionweb',value:'interactionweb'},
    {key:'interpathpanel',value:'interpathpanel'},
    {key:'intersys-cache',value:'intersys-cache'},
    {key:'intu-ec-client',value:'intu-ec-client'},
    {key:'intuitive-edge',value:'intuitive-edge'},
    {key:'ipether232port',value:'ipether232port'},
    {key:'iph-policy-adm',value:'iph-policy-adm'},
    {key:'iph-policy-cli',value:'iph-policy-cli'},
    {key:'isbconference1',value:'isbconference1'},
    {key:'isbconference2',value:'isbconference2'},
    {key:'isg-uda-server',value:'isg-uda-server'},
    {key:'ismaeasdaqlive',value:'ismaeasdaqlive'},
    {key:'ismaeasdaqtest',value:'ismaeasdaqtest'},
    {key:'isoipsigport-1',value:'isoipsigport-1'},
    {key:'isoipsigport-2',value:'isoipsigport-2'},
    {key:'itelserverport',value:'itelserverport'},
    {key:'iwb-whiteboard',value:'iwb-whiteboard'},
    {key:'jaugsremotec-1',value:'jaugsremotec-1'},
    {key:'jaugsremotec-2',value:'jaugsremotec-2'},
    {key:'jboss-iiop-ssl',value:'jboss-iiop-ssl'},
    {key:'jetformpreview',value:'jetformpreview'},
    {key:'jini-discovery',value:'jini-discovery'},
    {key:'k3software-cli',value:'k3software-cli'},
    {key:'k3software-svr',value:'k3software-svr'},
    {key:'kastenchasepad',value:'kastenchasepad'},
    {key:'keynotepairing',value:'keynotepairing'},
    {key:'kfxaclicensing',value:'kfxaclicensing'},
    {key:'kingdomsonline',value:'kingdomsonline'},
    {key:'kopek-httphead',value:'kopek-httphead'},
    {key:'krb5gatekeeper',value:'krb5gatekeeper'},
    {key:'lansurveyorxml',value:'lansurveyorxml'},
    {key:'lanyon-lantern',value:'lanyon-lantern'},
    {key:'leecoposserver',value:'leecoposserver'},
    {key:'LiebDevMgmt_DM',value:'LiebDevMgmt_DM'},
    {key:'LiebDevMgmt-DM',value:'LiebDevMgmt-DM'},
    {key:'limnerpressure',value:'limnerpressure'},
    {key:'linogridengine',value:'linogridengine'},
    {key:'listcrt-port-2',value:'listcrt-port-2'},
    {key:'llsurfup-https',value:'llsurfup-https'},
    {key:'lmsocialserver',value:'lmsocialserver'},
    {key:'logsheetserver',value:'logsheetserver'},
    {key:'lorica-out-sec',value:'lorica-out-sec'},
    {key:'lpsrecommender',value:'lpsrecommender'},
    {key:'lsys-appserver',value:'lsys-appserver'},
    {key:'macromedia-fcs',value:'macromedia-fcs'},
    {key:'mac-srvr-admin',value:'mac-srvr-admin'},
    {key:'magaya-network',value:'magaya-network'},
    {key:'mapper-mapethd',value:'mapper-mapethd'},
    {key:'mapper-nodemgr',value:'mapper-nodemgr'},
    {key:'mapper-ws_ethd',value:'mapper-ws_ethd'},
    {key:'mapper-ws-ethd',value:'mapper-ws-ethd'},
    {key:'mccwebsvr-port',value:'mccwebsvr-port'},
    {key:'mcs-calypsoicf',value:'mcs-calypsoicf'},
    {key:'mdc-portmapper',value:'mdc-portmapper'},
    {key:'mediacntrlnfsd',value:'mediacntrlnfsd'},
    {key:'mediavault-gui',value:'mediavault-gui'},
    {key:'medimageportal',value:'medimageportal'},
    {key:'megardsvr-port',value:'megardsvr-port'},
    {key:'megaregsvrport',value:'megaregsvrport'},
    {key:'menandmice_noh',value:'menandmice_noh'},
    {key:'menandmice-dns',value:'menandmice-dns'},
    {key:'menandmice-lpm',value:'menandmice-lpm'},
    {key:'menandmice-mon',value:'menandmice-mon'},
    {key:'menandmice-noh',value:'menandmice-noh'},
    {key:'menandmice-upg',value:'menandmice-upg'},
    {key:'messageservice',value:'messageservice'},
    {key:'mgcp-callagent',value:'mgcp-callagent'},
    {key:'mgesupervision',value:'mgesupervision'},
    {key:'micromuse-ncps',value:'micromuse-ncps'},
    {key:'micromuse-ncpw',value:'micromuse-ncpw'},
    {key:'microtalon-com',value:'microtalon-com'},
    {key:'microtalon-dis',value:'microtalon-dis'},
    {key:'mil-2045-47001',value:'mil-2045-47001'},
    {key:'mnet-discovery',value:'mnet-discovery'},
    {key:'mobileanalyzer',value:'mobileanalyzer'},
    {key:'mobile-file-dl',value:'mobile-file-dl'},
    {key:'mobileip-agent',value:'mobileip-agent'},
    {key:'mrssrendezvous',value:'mrssrendezvous'},
    {key:'ms-cluster-net',value:'ms-cluster-net'},
    {key:'msexch-routing',value:'msexch-routing'},
    {key:'msfw-s-storage',value:'msfw-s-storage'},
    {key:'msi-selectplay',value:'msi-selectplay'},
    {key:'ms-rule-engine',value:'ms-rule-engine'},
    {key:'mtl8000-matrix',value:'mtl8000-matrix'},
    {key:'mt-scaleserver',value:'mt-scaleserver'},
    {key:'multicast-ping',value:'multicast-ping'},
    {key:'multiling-http',value:'multiling-http'},
    {key:'must-backplane',value:'must-backplane'},
    {key:'mxodbc-connect',value:'mxodbc-connect'},
    {key:'mynahautostart',value:'mynahautostart'},
    {key:'mysql-cm-agent',value:'mysql-cm-agent'},
    {key:'natdataservice',value:'natdataservice'},
    {key:'nati-vi-server',value:'nati-vi-server'},
    {key:'navegaweb-port',value:'navegaweb-port'},
    {key:'navisphere-sec',value:'navisphere-sec'},
    {key:'ncdloadbalance',value:'ncdloadbalance'},
    {key:'ndl-tcp-ois-gw',value:'ndl-tcp-ois-gw'},
    {key:'ndm-agent-port',value:'ndm-agent-port'},
    {key:'nei-management',value:'nei-management'},
    {key:'netbill-keyrep',value:'netbill-keyrep'},
    {key:'netcabinet-com',value:'netcabinet-com'},
    {key:'netconf-ch-ssh',value:'netconf-ch-ssh'},
    {key:'netconf-ch-tls',value:'netconf-ch-tls'},
    {key:'netiq-endpoint',value:'netiq-endpoint'},
    {key:'net-projection',value:'net-projection'},
    {key:'netview-aix-10',value:'netview-aix-10'},
    {key:'netview-aix-11',value:'netview-aix-11'},
    {key:'netview-aix-12',value:'netview-aix-12'},
    {key:'netwatcher-mon',value:'netwatcher-mon'},
    {key:'nfsd-keepalive',value:'nfsd-keepalive'},
    {key:'nfs-domainroot',value:'nfs-domainroot'},
    {key:'ni-visa-remote',value:'ni-visa-remote'},
    {key:'nm-asses-admin',value:'nm-asses-admin'},
    {key:'nm-game-server',value:'nm-game-server'},
    {key:'noit-transport',value:'noit-transport'},
    {key:'norton-lambert',value:'norton-lambert'},
    {key:'novastorbakcup',value:'novastorbakcup'},
    {key:'novell-ipx-cmd',value:'novell-ipx-cmd'},
    {key:'npep-messaging',value:'npep-messaging'},
    {key:'oemcacao-jmxmp',value:'oemcacao-jmxmp'},
    {key:'officelink2000',value:'officelink2000'},
    {key:'olpc-activity1',value:'olpc-activity1'},
    {key:'omginitialrefs',value:'omginitialrefs'},
    {key:'onehome-remote',value:'onehome-remote'},
    {key:'onenet-pairing',value:'onenet-pairing'},
    {key:'opalis-rbt-ipc',value:'opalis-rbt-ipc'},
    {key:'openvms-sysipc',value:'openvms-sysipc'},
    {key:'opequus-server',value:'opequus-server'},
    {key:'opsession-clnt',value:'opsession-clnt'},
    {key:'opsession-prxy',value:'opsession-prxy'},
    {key:'opsession-srvr',value:'opsession-srvr'},
    {key:'oracleas-https',value:'oracleas-https'},
    {key:'oraclenet8cman',value:'oraclenet8cman'},
    {key:'ordinox-server',value:'ordinox-server'},
    {key:'ovalarmsrv-cmd',value:'ovalarmsrv-cmd'},
    {key:'p2pstorage-sec',value:'p2pstorage-sec'},
    {key:'pago-services1',value:'pago-services1'},
    {key:'pago-services2',value:'pago-services2'},
    {key:'pangolin-laser',value:'pangolin-laser'},
    {key:'paradym-31port',value:'paradym-31port'},
    {key:'passwordwallet',value:'passwordwallet'},
    {key:'passwrd-policy',value:'passwrd-policy'},
    {key:'paycash-online',value:'paycash-online'},
    {key:'pcanywheredata',value:'pcanywheredata'},
    {key:'pcanywherestat',value:'pcanywherestat'},
    {key:'pcc-image-port',value:'pcc-image-port'},
    {key:'pclemultimedia',value:'pclemultimedia'},
    {key:'pc-mta-addrmap',value:'pc-mta-addrmap'},
    {key:'pc-telecommute',value:'pc-telecommute'},
    {key:'pdl-datastream',value:'pdl-datastream'},
    {key:'personal-agent',value:'personal-agent'},
    {key:'personalos-001',value:'personalos-001'},
    {key:'pfu-prcallback',value:'pfu-prcallback'},
    {key:'photosmithsync',value:'photosmithsync'},
    {key:'pk-electronics',value:'pk-electronics'},
    {key:'pkix-timestamp',value:'pkix-timestamp'},
    {key:'pktcablemmcops',value:'pktcablemmcops'},
    {key:'PowerAlert-nsa',value:'PowerAlert-nsa'},
    {key:'powerclientcsf',value:'powerclientcsf'},
    {key:'predatar-comms',value:'predatar-comms'},
    {key:'presonus-ucnet',value:'presonus-ucnet'},
    {key:'princity-agent',value:'princity-agent'},
    {key:'priority-e-com',value:'priority-e-com'},
    {key:'prismiq-plugin',value:'prismiq-plugin'},
    {key:'prosharenotify',value:'prosharenotify'},
    {key:'PS-Capture-Pro',value:'PS-Capture-Pro'},
    {key:'psl-management',value:'psl-management'},
    {key:'ptcnameservice',value:'ptcnameservice'},
    {key:'publiqare-sync',value:'publiqare-sync'},
    {key:'qtms-bootstrap',value:'qtms-bootstrap'},
    {key:'qt-serveradmin',value:'qt-serveradmin'},
    {key:'quest-data-hub',value:'quest-data-hub'},
    {key:'questdb2-lnchr',value:'questdb2-lnchr'},
    {key:'radius-dynauth',value:'radius-dynauth'},
    {key:'radwiz-nms-srv',value:'radwiz-nms-srv'},
    {key:'rapidmq-center',value:'rapidmq-center'},
    {key:'recipe-sharing',value:'recipe-sharing'},
    {key:'remoteware-srv',value:'remoteware-srv'},
    {key:'remote-winsock',value:'remote-winsock'},
    {key:'rendezvouspong',value:'rendezvouspong'},
    {key:'robotraconteur',value:'robotraconteur'},
    {key:'rtps-discovery',value:'rtps-discovery'},
    {key:'s8-client-port',value:'s8-client-port'},
    {key:'sage-best-com1',value:'sage-best-com1'},
    {key:'sage-best-com2',value:'sage-best-com2'},
    {key:'salient-dtasrv',value:'salient-dtasrv'},
    {key:'salient-usrmgr',value:'salient-usrmgr'},
    {key:'sallingclicker',value:'sallingclicker'},
    {key:'samsung-unidex',value:'samsung-unidex'},
    {key:'sas-remote-hlp',value:'sas-remote-hlp'},
    {key:'satvid-datalnk',value:'satvid-datalnk'},
    {key:'sco-websrvrmg3',value:'sco-websrvrmg3'},
    {key:'sco-websrvrmgr',value:'sco-websrvrmgr'},
    {key:'sctp-tunneling',value:'sctp-tunneling'},
    {key:'sdp-portmapper',value:'sdp-portmapper'},
    {key:'sec-pc2fax-srv',value:'sec-pc2fax-srv'},
    {key:'secure-cfg-svr',value:'secure-cfg-svr'},
    {key:'sentillion-vlc',value:'sentillion-vlc'},
    {key:'sentillion-vlt',value:'sentillion-vlt'},
    {key:'sentlm-srv2srv',value:'sentlm-srv2srv'},
    {key:'serverview-asn',value:'serverview-asn'},
    {key:'serverviewdbms',value:'serverviewdbms'},
    {key:'serverview-icc',value:'serverview-icc'},
    {key:'shiva_confsrvr',value:'shiva_confsrvr'},
    {key:'shiva-confsrvr',value:'shiva-confsrvr'},
    {key:'shivadiscovery',value:'shivadiscovery'},
    {key:'shrewd-control',value:'shrewd-control'},
    {key:'sia-ctrl-plane',value:'sia-ctrl-plane'},
    {key:'silverpeakcomm',value:'silverpeakcomm'},
    {key:'silverpeakpeer',value:'silverpeakpeer'},
    {key:'siminsufflator',value:'siminsufflator'},
    {key:'simplement-tie',value:'simplement-tie'},
    {key:'skip-cert-recv',value:'skip-cert-recv'},
    {key:'skip-cert-send',value:'skip-cert-send'},
    {key:'skip-mc-gikreq',value:'skip-mc-gikreq'},
    {key:'slc-ctrlrloops',value:'slc-ctrlrloops'},
    {key:'smartcard-port',value:'smartcard-port'},
    {key:'smart-diagnose',value:'smart-diagnose'},
    {key:'smntubootstrap',value:'smntubootstrap'},
    {key:'sns-dispatcher',value:'sns-dispatcher'},
    {key:'sntp-heartbeat',value:'sntp-heartbeat'},
    {key:'softrack-meter',value:'softrack-meter'},
    {key:'solid-e-engine',value:'solid-e-engine'},
    {key:'sossecollector',value:'sossecollector'},
    {key:'spiderelectron',value:'spiderelectron'},
    {key:'spremotetablet',value:'spremotetablet'},
    {key:'spt-automation',value:'spt-automation'},
    {key:'spw-dnspreload',value:'spw-dnspreload'},
    {key:'sse-app-config',value:'sse-app-config'},
    {key:'stargatealerts',value:'stargatealerts'},
    {key:'stone-design-1',value:'stone-design-1'},
    {key:'stun-behaviors',value:'stun-behaviors'},
    {key:'subntbcst_tftp',value:'subntbcst_tftp'},
    {key:'subntbcst-tftp',value:'subntbcst-tftp'},
    {key:'sun-as-nodeagt',value:'sun-as-nodeagt'},
    {key:'suncacao-jmxmp',value:'suncacao-jmxmp'},
    {key:'sun-user-https',value:'sun-user-https'},
    {key:'surfcontrolcpa',value:'surfcontrolcpa'},
    {key:'sweetware-apps',value:'sweetware-apps'},
    {key:'syam-webserver',value:'syam-webserver'},
    {key:'sybaseanywhere',value:'sybaseanywhere'},
    {key:'synapse-nhttps',value:'synapse-nhttps'},
    {key:'synchronet-rtc',value:'synchronet-rtc'},
    {key:'synchronet-upd',value:'synchronet-upd'},
    {key:'synoptics-trap',value:'synoptics-trap'},
    {key:'synotics-relay',value:'synotics-relay'},
    {key:'sype-transport',value:'sype-transport'},
    {key:'syserverremote',value:'syserverremote'},
    {key:'system-monitor',value:'system-monitor'},
    {key:'targus-getdata',value:'targus-getdata'},
    {key:'taskmaster2000',value:'taskmaster2000'},
    {key:'tclprodebugger',value:'tclprodebugger'},
    {key:'tcoaddressbook',value:'tcoaddressbook'},
    {key:'telelpathstart',value:'telelpathstart'},
    {key:'teleniumdaemon',value:'teleniumdaemon'},
    {key:'telesis-licman',value:'telesis-licman'},
    {key:'teradataordbms',value:'teradataordbms'},
    {key:'thumbwrestling',value:'thumbwrestling'},
    {key:'timestenbroker',value:'timestenbroker'},
    {key:'tip-app-server',value:'tip-app-server'},
    {key:'tomato-springs',value:'tomato-springs'},
    {key:'toothpicserver',value:'toothpicserver'},
    {key:'topovista-data',value:'topovista-data'},
    {key:'travsoft-ipx-t',value:'travsoft-ipx-t'},
    {key:'tri-vis-client',value:'tri-vis-client'},
    {key:'tri-vis-server',value:'tri-vis-server'},
    {key:'trustestablish',value:'trustestablish'},
    {key:'ttcmremotectrl',value:'ttcmremotectrl'},
    {key:'tungsten-https',value:'tungsten-https'},
    {key:'tvdumtray-port',value:'tvdumtray-port'},
    {key:'tvnetworkvideo',value:'tvnetworkvideo'},
    {key:'ua-secureagent',value:'ua-secureagent'},
    {key:'ub-dns-control',value:'ub-dns-control'},
    {key:'ucdynamics-tuc',value:'ucdynamics-tuc'},
    {key:'ultraseek-http',value:'ultraseek-http'},
    {key:'unbind-cluster',value:'unbind-cluster'},
    {key:'unisys-eportal',value:'unisys-eportal'},
    {key:'univ-appserver',value:'univ-appserver'},
    {key:'universe_suite',value:'universe_suite'},
    {key:'universe-suite',value:'universe-suite'},
    {key:'usicontentpush',value:'usicontentpush'},
    {key:'vantronix-mgmt',value:'vantronix-mgmt'},
    {key:'vcnet-link-v10',value:'vcnet-link-v10'},
    {key:'vipremoteagent',value:'vipremoteagent'},
    {key:'virtual-places',value:'virtual-places'},
    {key:'viziblebrowser',value:'viziblebrowser'},
    {key:'vocaltec-admin',value:'vocaltec-admin'},
    {key:'vocaltec-phone',value:'vocaltec-phone'},
    {key:'vocaltec-wconf',value:'vocaltec-wconf'},
    {key:'vrml-multi-use',value:'vrml-multi-use'},
    {key:'vrts-auth-port',value:'vrts-auth-port'},
    {key:'vrts-ipcserver',value:'vrts-ipcserver'},
    {key:'vrtstrapserver',value:'vrtstrapserver'},
    {key:'vsamredirector',value:'vsamredirector'},
    {key:'vytalvaultbrtp',value:'vytalvaultbrtp'},
    {key:'vytalvaultpipe',value:'vytalvaultpipe'},
    {key:'vytalvaultvsmp',value:'vytalvaultvsmp'},
    {key:'wago-io-system',value:'wago-io-system'},
    {key:'wap-push-https',value:'wap-push-https'},
    {key:'wap-pushsecure',value:'wap-pushsecure'},
    {key:'wbem-exp-https',value:'wbem-exp-https'},
    {key:'webmethods-b2b',value:'webmethods-b2b'},
    {key:'websphere-snmp',value:'websphere-snmp'},
    {key:'westec-connect',value:'westec-connect'},
    {key:'wg-endpt-comms',value:'wg-endpt-comms'},
    {key:'wilkenlistener',value:'wilkenlistener'},
    {key:'wininstall-ipc',value:'wininstall-ipc'},
    {key:'wsm-server-ssl',value:'wsm-server-ssl'},
    {key:'wv-csp-sms-cir',value:'wv-csp-sms-cir'},
    {key:'wv-csp-udp-cir',value:'wv-csp-udp-cir'},
    {key:'xinuexpansion1',value:'xinuexpansion1'},
    {key:'xinuexpansion2',value:'xinuexpansion2'},
    {key:'xinuexpansion3',value:'xinuexpansion3'},
    {key:'xinuexpansion4',value:'xinuexpansion4'},
    {key:'xinupageserver',value:'xinupageserver'},
    {key:'xmlink-connect',value:'xmlink-connect'},
    {key:'xmltec-xmlmail',value:'xmltec-xmlmail'},
    {key:'xnm-clear-text',value:'xnm-clear-text'},
    {key:'xs-openstorage',value:'xs-openstorage'},
    {key:'zabbix-trapper',value:'zabbix-trapper'},
    {key:'zigbee-gateway',value:'zigbee-gateway'},
    {key:'zixi-transport',value:'zixi-transport'},
    {key:'actifioudsagent',value:'actifioudsagent'},
    {key:'afs3-fileserver',value:'afs3-fileserver'},
    {key:'almobile-system',value:'almobile-system'},
    {key:'alpacadiscovery',value:'alpacadiscovery'},
    {key:'ampl-tableproxy',value:'ampl-tableproxy'},
    {key:'asf-secure-rmcp',value:'asf-secure-rmcp'},
    {key:'aspcoordination',value:'aspcoordination'},
    {key:'audio-activmail',value:'audio-activmail'},
    {key:'av-chat-ring-01',value:'av-chat-ring-01'},
    {key:'brski-registrar',value:'brski-registrar'},
    {key:'caldsoft-backup',value:'caldsoft-backup'},
    {key:'cardifftec-back',value:'cardifftec-back'},
    {key:'cartographerxmp',value:'cartographerxmp'},
    {key:'cisco-vpath-tun',value:'cisco-vpath-tun'},
    {key:'citriximaclient',value:'citriximaclient'},
    {key:'cloudcheck-ping',value:'cloudcheck-ping'},
    {key:'codemeter-cmwan',value:'codemeter-cmwan'},
    {key:'composit-server',value:'composit-server'},
    {key:'cplscrambler-al',value:'cplscrambler-al'},
    {key:'cplscrambler-in',value:'cplscrambler-in'},
    {key:'cplscrambler-lg',value:'cplscrambler-lg'},
    {key:'crescoctrl-disc',value:'crescoctrl-disc'},
    {key:'damewaremobgtwy',value:'damewaremobgtwy'},
    {key:'dbcontrol_agent',value:'dbcontrol_agent'},
    {key:'dbcontrol-agent',value:'dbcontrol-agent'},
    {key:'easyspndlg-sync',value:'easyspndlg-sync'},
    {key:'emc-xsw-dconfig',value:'emc-xsw-dconfig'},
    {key:'encrypted_admin',value:'encrypted_admin'},
    {key:'encrypted-admin',value:'encrypted-admin'},
    {key:'equationbuilder',value:'equationbuilder'},
    {key:'erunbook_server',value:'erunbook_server'},
    {key:'erunbook-server',value:'erunbook-server'},
    {key:'gog-multiplayer',value:'gog-multiplayer'},
    {key:'goldleaf-licman',value:'goldleaf-licman'},
    {key:'googexpeditions',value:'googexpeditions'},
    {key:'gsremotecontrol',value:'gsremotecontrol'},
    {key:'hp-managed-node',value:'hp-managed-node'},
    {key:'ieee11073-20701',value:'ieee11073-20701'},
    {key:'intelliadm-disc',value:'intelliadm-disc'},
    {key:'intu-ec-svcdisc',value:'intu-ec-svcdisc'},
    {key:'irradiatd-iclip',value:'irradiatd-iclip'},
    {key:'itactionserver1',value:'itactionserver1'},
    {key:'itactionserver2',value:'itactionserver2'},
    {key:'k-patentssensor',value:'k-patentssensor'},
    {key:'larvaio-control',value:'larvaio-control'},
    {key:'mazepseudo-game',value:'mazepseudo-game'},
    {key:'msi-cps-rm-disc',value:'msi-cps-rm-disc'},
    {key:'msr-plugin-port',value:'msr-plugin-port'},
    {key:'netconfsoapbeep',value:'netconfsoapbeep'},
    {key:'netconfsoaphttp',value:'netconfsoaphttp'},
    {key:'neto-wol-server',value:'neto-wol-server'},
    {key:'netwave-ap-mgmt',value:'netwave-ap-mgmt'},
    {key:'netwkpathengine',value:'netwkpathengine'},
    {key:'nsdeepfreezectl',value:'nsdeepfreezectl'},
    {key:'ntz-p2p-storage',value:'ntz-p2p-storage'},
    {key:'observium-agent',value:'observium-agent'},
    {key:'octopustentacle',value:'octopustentacle'},
    {key:'oemcacao-websvc',value:'oemcacao-websvc'},
    {key:'officetime-sync',value:'officetime-sync'},
    {key:'oktaauthenticat',value:'oktaauthenticat'},
    {key:'omadm-bootstrap',value:'omadm-bootstrap'},
    {key:'openremote-ctrl',value:'openremote-ctrl'},
    {key:'optech-port1-lm',value:'optech-port1-lm'},
    {key:'panagolin-ident',value:'panagolin-ident'},
    {key:'papachi-p2p-srv',value:'papachi-p2p-srv'},
    {key:'pjj-player-disc',value:'pjj-player-disc'},
    {key:'popup-reminders',value:'popup-reminders'},
    {key:'prodigy-intrnet',value:'prodigy-intrnet'},
    {key:'prosharerequest',value:'prosharerequest'},
    {key:'pstmailsync-ssl',value:'pstmailsync-ssl'},
    {key:'railgun-webaccl',value:'railgun-webaccl'},
    {key:'restconf-ch-tls',value:'restconf-ch-tls'},
    {key:'rmonitor_secure',value:'rmonitor_secure'},
    {key:'rmonitor-secure',value:'rmonitor-secure'},
    {key:'rt-classmanager',value:'rt-classmanager'},
    {key:'rt-devicemapper',value:'rt-devicemapper'},
    {key:'saltd-licensing',value:'saltd-licensing'},
    {key:'secrmmsafecopya',value:'secrmmsafecopya'},
    {key:'sercomm-scadmin',value:'sercomm-scadmin'},
    {key:'setlistmaker-rc',value:'setlistmaker-rc'},
    {key:'setlistmaker-ss',value:'setlistmaker-ss'},
    {key:'shiprush-db-svr',value:'shiprush-db-svr'},
    {key:'showcockpit-net',value:'showcockpit-net'},
    {key:'signacert-agent',value:'signacert-agent'},
    {key:'speedtrace-disc',value:'speedtrace-disc'},
    {key:'stingray-remote',value:'stingray-remote'},
    {key:'sun-as-iiops-ca',value:'sun-as-iiops-ca'},
    {key:'suncacao-websvc',value:'suncacao-websvc'},
    {key:'sun-sr-iiop-aut',value:'sun-sr-iiop-aut'},
    {key:'synotics-broker',value:'synotics-broker'},
    {key:'talarian-mcast1',value:'talarian-mcast1'},
    {key:'talarian-mcast2',value:'talarian-mcast2'},
    {key:'talarian-mcast3',value:'talarian-mcast3'},
    {key:'talarian-mcast4',value:'talarian-mcast4'},
    {key:'talarian-mcast5',value:'talarian-mcast5'},
    {key:'talon-webserver',value:'talon-webserver'},
    {key:'targus-getdata1',value:'targus-getdata1'},
    {key:'targus-getdata2',value:'targus-getdata2'},
    {key:'targus-getdata3',value:'targus-getdata3'},
    {key:'telelpathattack',value:'telelpathattack'},
    {key:'toad-bi-appsrvr',value:'toad-bi-appsrvr'},
    {key:'video-activmail',value:'video-activmail'},
    {key:'wmedistribution',value:'wmedistribution'},
    {key:'wso2esb-console',value:'wso2esb-console'},
    {key:'xmpcr-interface',value:'xmpcr-interface'},
    {key:'xplat-replicate',value:'xplat-replicate'},
    {key:'Escale (Newton Dock)',value:'Escale (Newton Dock)'},
    {key:'Apple Remote Desktop (Net Assistant)',value:'Apple Remote Desktop (Net Assistant)'},
    
    
    
    

    
    




];