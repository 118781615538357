import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SavedBundlesService {
  imxServerUrl = environment.imxServer.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`,
  });

  getPath = '/get-saved-bundles';
  postPath = '/post-saved-bundle';
  deletePath = '/delete-saved-bundle';
  updatePath = '/update-saved-bundle';
  searchPath = '/search-saved-bundles';

  constructor(private httpClient: HttpClient) { }

  async getSavedBundles(page, sortKey){
    let urlArr = window.location.href.split('/');
    let path = urlArr[urlArr.length-1];
    let size = 15;
    if(sortKey === '') sortKey = 'na';

    switch(path){
      case 'bundle':
      case 'guided':
        size = 10;
    }

    let from = (page-1)*size;

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.get(`${this.imxServerUrl}${this.getPath}/${sortKey}/${from}/${size}`, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        reject(e);
      }
    });
  } 

  async postSavedBundle(bundle){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.postPath}`, bundle, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        console.log(e);
        reject(e);
      }
    });
  } 

  async deleteSavedBundle(docID){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.delete(`${this.imxServerUrl}${this.deletePath}/${docID}`, { headers: this.headers })
          .subscribe((res) => {
            resolve(res);
          })
      } catch(e){
        reject(e);
      }
    });
  } 

  async updateSavedBundle(obj){
    console.log(obj);
    let docID = obj._id;
    let bundle = obj._source;

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.updatePath}/${docID}`, bundle, { headers: this.headers })
          .subscribe((res) => {
            resolve(res);
          })
      } catch(e){
        reject(e);
      }
    });
  }
  
  async searchSavedBundles(page: number, query, filters = undefined) {
    let queryString = btoa(JSON.stringify(query));
    return new Promise((resolve, reject) => {
      try {
        if (!filters)
          this.httpClient.get(`${this.imxServerUrl}${this.searchPath}/${queryString}`, { headers: this.headers })
            .subscribe(
              data => resolve(data),
              error => reject(error)
            )
          else {
            const { sortKey, size } = filters;
            this.httpClient.get(`${this.imxServerUrl}${this.searchPath}/${queryString}/${sortKey}/${(page-1)*size}/${size}`, { headers: this.headers })
              .subscribe(
                data => resolve(data),
                error => reject(error)
              )
          }
      } catch(e){
        reject(e);
      }
    });
  }
}
