import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IdentifyStixService {
  imxServerUrl = environment.imxServer.url;
  headers = new HttpHeaders({
    'Authorization': `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`,
  });

  postPath = "/identify-stix";
  postFilePath = '/identify-stix-file';


  constructor(private httpClient: HttpClient) { }

  async postIdentifyStix(text){
    this.headers['Content-Type'] = 'application/json';
    let body = {
      text: text
    }

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.postPath}`, body, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        reject(e);
      }
    });
  }

  async postIdentifyStixFile(formData, fileName){
    if(this.headers['Content-Type']) delete this.headers['Content-Type'];

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.put(`${this.imxServerUrl}${this.postFilePath}/${fileName}`, formData, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        reject(e);
      }
    });
  }
}
