import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeciderServiceService {

  mitreAttackName: String = 'mitre-attack';
  mitreAttackUrl: String = 'https://attack.mitre.org/techniques/';
  domain: String = '';

  constructor() {
    let deciderUrl = environment.deciderURL;
    let urlSplit = deciderUrl.split('.');
    urlSplit[urlSplit.length-1].replace('/', '');
    this.domain = `${urlSplit[urlSplit.length-2]}.${urlSplit[urlSplit.length-1]}`;
  }

  async openDeciderTool() {
    return new Promise<any[]>(async (resolve) => {
      document.cookie = `imx_attack_patterns=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${this.domain};`;

      window.open(environment.deciderURL, "_blank").focus();

      let deciderRes = await this.getImxDeciderCookie();

      let cartObj = JSON.parse(deciderRes);

      let tempResult = cartObj;
      let result = [];

      for(let obj of tempResult.cartItems){

        let splitId = obj.index.split('.');
        let idPath = '';
        if(splitId.length > 1){
          idPath = `${splitId[0]}/${splitId[1]}`;
        } else {
          idPath = obj.index;
        }

        let phase_name = obj.tacticName.toLowerCase();
        phase_name = phase_name.replaceAll(' ', '-');

        let temp = {
          external_reference: {
            external_id: obj.index,
            source_name: this.mitreAttackName,
            url: `${this.mitreAttackUrl}${idPath}`
          },
          kill_chain_phase: {
            kill_chain_name: this.mitreAttackName,
            phase_name: phase_name
          }
        }

        result.push(temp);
      }

      resolve(result);
    });
  }

  async getImxDeciderCookie(): Promise<string>{
    return new Promise((resolve, reject) => {
      let interval = setInterval(function(){
        let cookie = document.cookie;

        if(cookie && cookie.indexOf('imx_attack_patterns') !== -1){
          let splitCookie = cookie.split('=');
    
          for(let i=0; i<splitCookie.length; i++){
            if(splitCookie[i].startsWith('imx_attack_patterns')){
              resolve(splitCookie[i+1]);
              clearInterval(interval);
            }
          }
        }
      }, 200);
    })
  }
}
