import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  getPath = '/get-alerts';
  getUnreadPath = '/get-unread-alerts';
  getStatusPath = '/get-status';
  updatePath = '/update-alert'

  imxServerUrl = environment.imxServer.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`,
  });

  constructor(private httpClient: HttpClient) { }

  async getAlerts(page, sortKey){
    let size = 15;
    let from = (page-1)*size;

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.get(`${this.imxServerUrl}${this.getPath}/${sortKey}/${from}/${size}`, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        reject(e);
      }
    });
  }

  async getUnreadAlerts(){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.get(`${this.imxServerUrl}${this.getUnreadPath}`, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        reject(e);
      }
    });
  }

  async getStatus(alertObj){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.getStatusPath}`, alertObj, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        reject(e);
      }
    });
  }

  async postAlert(){
    
  }

  async updateAlert(alert){
    let doc = alert._source;
    let docID = alert._id;

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.updatePath}/${docID}`, doc, { headers: this.headers })
          .subscribe((res) => {
            resolve(res);
          })
      } catch(e){
        reject(e);
      }
    });
  }
}
