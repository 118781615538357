<div class="status-table w-100" style="margin-top: 10px; overflow: auto;">
    <div class="container-fluid ">
        <div>
            <table *ngIf="alertTable.length != 0 && !isLoading" class="table mb-0 status-bottom-border">
                <thead>
                    <tr>
                        <!-- <th scope="col">
                            <span  style="cursor:pointer;" (click)="sort('status')">
                                Read/Unread
                                <fa-icon *ngIf="sortKey === 'status-des'" [icon]="faAngleDown">
                                </fa-icon>
                                <fa-icon *ngIf="sortKey === 'status-asc'" [icon]="faAngleUp">
                                </fa-icon>
                            </span>
                        </th> -->
                        <th scope="col">
                            <span  style="cursor:pointer;" (click)="sort('status')">
                                Type
                                <fa-icon *ngIf="sortKey === 'status-des'" [icon]="faAngleDown">
                                </fa-icon>
                                <fa-icon *ngIf="sortKey === 'status-asc'" [icon]="faAngleUp">
                                </fa-icon>
                            </span>
                        </th>
                        <th scope="col">
                            <span  style="cursor:pointer;" (click)="sort('status')">
                                Target
                                <fa-icon *ngIf="sortKey === 'taxii-des'" [icon]="faAngleDown">
                                </fa-icon>
                                <fa-icon *ngIf="sortKey === 'taxii-asc'" [icon]="faAngleUp">
                                </fa-icon>
                            </span>
                        </th>
                        <th scope="col">
                            <span  style="cursor:pointer;" (click)="sort('status')">
                                New Objects
                                <fa-icon *ngIf="sortKey === 'taxii-des'" [icon]="faAngleDown">
                                </fa-icon>
                                <fa-icon *ngIf="sortKey === 'taxii-asc'" [icon]="faAngleUp">
                                </fa-icon>
                            </span>
                        </th>
                        <th scope="col">
                            <span  style="cursor:pointer;" (click)="sort('status')">
                                Added After
                                <fa-icon *ngIf="sortKey === 'taxii-des'" [icon]="faAngleDown">
                                </fa-icon>
                                <fa-icon *ngIf="sortKey === 'taxii-asc'" [icon]="faAngleUp">
                                </fa-icon>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let obj of alertTable; let i = index;">
                        <tr [ngClass]="{'read': obj._source.read == true, 'unread': obj._source.read == false}">
                            <!-- <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name" (click)="changeShow(i)">
                                {{ obj._source.read }}
                            </td> -->
                            <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name" (click)="changeShow(i)">
                                {{ obj._source.query.type }}
                            </td>
                            <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name" (click)="changeShow(i)">
                                {{ getTarget(i) }}
                            </td>
                            <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name" (click)="changeShow(i)">
                                {{ getHits(i) }}
                            </td>
                            <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name" (click)="changeShow(i)">
                                {{ obj._source.timestamp }}
                            </td>
                        </tr>
                        <tr class="expanded-panel" *ngIf="rowNumber == i && !isSearching">
                            <td colspan="5">
                                <pre class="preview-json-window">{{ alertResults | json }}</pre>
                            </td>
                        </tr>
                        <tr class="expanded-panel" *ngIf="rowNumber == i && isSearching">
                            <td colspan="5">
                                <div class="row-name pl-3 pt-3">
                                    Loading...
                                    <span class="spinner-grow spinner-grow-sm" role="status"
                                        aria-hidden="true"></span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <div class="footer" *ngIf="totalPages !== 0 && !isLoading" [ngClass]="{'footer-no-bottom': totalPages == 1}">
                <div *ngIf="totalPages !== 1 && !isLoading"  class="span-footer float-left">
                    <span >Page: {{currentPage}}/{{totalPages}}</span>
                </div>
                <div class="buttons-footer">
                    <button *ngIf="currentPage > 1 && !isLoading" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('left')">
                        <fa-icon [icon]="faArrowLeft"></fa-icon>
                    </button>
                    <button *ngIf="currentPage < totalPages && !isLoading" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('right')">
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="!isLoading && alertTable.length == 0">
            <span style="font-weight: bold; text-align: left;">No Alerts for current user</span>
        </div>
        <ng-container *ngIf="isLoading">
            <div class="row-name pl-3 pt-3">
                Loading...
                <span class="spinner-grow spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
            </div>
        </ng-container>
    </div>
</div>