import { Component, OnInit, Inject } from '@angular/core';
import { faPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StixService } from 'src/app/stix-service.service';
import { environment } from 'src/environments/environment';
import { AlertSubsService } from '../alert-subs-service/alert-subs.service';
import { AnnouncementService } from 'src/app/announcement-service/announcement-service.service';

export interface AlertSubData {
  apiRoots: []
}

@Component({
  selector: 'app-alert-sub-dialogue',
  templateUrl: './alert-sub-dialogue.component.html',
  styleUrls: ['./alert-sub-dialogue.component.css']
})
export class AlertSubDialogueComponent implements OnInit {
  faPlus = faPlus;
  faBan = faBan;

  apiRoots = [];
  collections = [];

  loadingApiRoots: boolean = false;

  selectedAPIRoot = undefined;
  selectedCollection = undefined;
  taxiiServer = {
    availableCollections: [],
    apiRoot: '',
    childRoot: '',
    collection: {}
  }

  constructor(public dialogRef: MatDialogRef<AlertSubDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertSubData,
    public stixService: StixService, private alertSubsService: AlertSubsService,
    private announcement: AnnouncementService) {
      this.apiRoots = data.apiRoots;
    }

  ngOnInit(): void {
  }

  async addSub(){
    let newSub = {
      taxiiServer: environment.taxiiServer.url,
      type: 'collection',
      sub: {
        apiRootPath: this.selectedAPIRoot.apiPath,
        collection: this.selectedCollection.id
      }
    }

    let result: any = await this.alertSubsService.addAlertSub(newSub);

    if(result.status === 201){
      this.dialogRef.close(result);
    } else {
      this.announcement.show('Subscription Error', 'There was an error communicating to the IMX Server to create your subscription. Please try again or create a support request', 'error', false);
    }

  }

  onCollectionChanged(collection) {
    this.selectedCollection = collection;
    this.taxiiServer.collection = collection;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onX(): void {
    this.dialogRef.close();
  }

  selectApiRoot(apiRoot) {
    this.selectedAPIRoot = apiRoot;
    this.taxiiServer.apiRoot = apiRoot.title;
    this.taxiiServer.childRoot = apiRoot.childRoot;
    this.selectedCollection = undefined;
    // this.getCollectionsIds();
    for(let root of this.apiRoots){
      if(this.selectedAPIRoot.apiPath === root.apiPath){
        this.collections = root.collections;
        break;
      }
    }
  }

}
