import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faEdit, faTrash, faBan } from '@fortawesome/free-solid-svg-icons';

export interface GuidedCartData {
  cart: { },
  editMode: false,
  currentMode: string
}

@Component({
  selector: 'app-guided-cart-modal',
  templateUrl: './guided-cart-modal.component.html',
  styleUrls: ['./guided-cart-modal.component.css']
})
export class GuidedCartModalComponent implements OnInit {

  faEdit = faEdit;
  faTrash = faTrash;
  faBan = faBan;

  cart: any = { };
  editMode: boolean = false;

  currentMode: string = '';

  constructor(public dialogRef: MatDialogRef<GuidedCartModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GuidedCartData) {
      this.cart = data.cart;
      this.editMode = data.editMode;
      this.currentMode = data.currentMode;
    }

  ngOnInit(): void {
  }

  editFromCart(key, index){
    let result = {
      mode: 'edit',
      key: key,
      index: index
    }

    this.dialogRef.close(result);
  }

  deleteFromCart(key, index, search){
    let result = {
      mode: 'delete',
      key: key,
      index: index,
      search: search
    }
    
    this.dialogRef.close(result);
  }

  getName(value){
    return value.display_name;
  }
  
  onX(): void {
    this.dialogRef.close();
  }

}
