<div class="dialog">
    <div class="modal-header">
        <h2 class="modal-title" id="modal-title">Configure Windows Registry Value Type</h2>
        <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body extensions-modal-body">
        <div class="col-md-12">
            <span class="tab"></span>

            <div class="row bump">
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Name</b>
                        </span>
                        <input class="form-control" (input)="isAddEnabled()" [(ngModel)]="name" type="text">
                    </div>
                </div>
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Data</b>
                        </span>
                        <input class="form-control" (input)="isAddEnabled()" [(ngModel)]="data" type="text">
                    </div>
                </div>
            </div>

            <div class="row bump">
                <div class="p-0 col-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text">
                            <b>Data Type</b>
                        </span>
                        <select type="text" class="form-control" (change)="isAddEnabled()" [(ngModel)]="data_type">
                            <option value=""></option>
                            <option *ngFor="let opt of dataOptions" [value]="opt">{{opt}}</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" aria-label="Close"
            (click)="cancelModal()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-success btn-windows-key" [disabled]="!addEnabled" type="button" (click)="onAdd()">
            <!-- (click)="addModalObject('windows_value'); this.isAddEnabled.emit();"> -->
            <fa-icon [icon]="faPlus" class="pr-1"></fa-icon>
            Add
        </button>
    </div>
</div>
