import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';


@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit {
  private pullHeaders = new HttpHeaders()
    .set("Content-Type", "application/taxii+json;version=2.1");
  private params = new HttpParams()
    .set("dashboardId", environment.quicksight.dashboardId)
    .append("userArn", environment.quicksight.userArn)
    .append("resetDisabled", environment.quicksight.resetDisabled)
    .append("undoRedoDisabled", environment.quicksight.undoRedoDisabled);

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.grabDashboardUrl();
  }

  grabDashboardUrl(): void {
    this.httpClient.get(environment.quicksight.apiGatewayUrl, {
      headers: this.pullHeaders,
      params: this.params
    }).subscribe((response: any) => {
      console.log(response);
      this.embedDashboard(response.EmbedUrl);
    });
  }

  private embedDashboard(embedUrl: string): void {
    console.log(embedUrl);
    const dasboardContainer = document.getElementById("dashboardContainer");
    const params = {
      url: embedUrl,
      container: dasboardContainer,
      height: "1500px",
      width: "85%"
    };

    const dashboard = QuickSightEmbedding.embedDashboard(params);

    dashboard.on('error', () => {
      console.error("In error");
      document.getElementById("loadingOverlay").classList.add("d-none");
      document.getElementById("errorOverlay").classList.remove("d-none");
    });
    dashboard.on('load', () => {
      console.log("In load");
      document.getElementById("loadingOverlay").classList.add("d-none");
      document.getElementById("dashboardContainer").classList.remove("d-none");
    });
  }
}