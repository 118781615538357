<div class="status-table w-100" style="margin-top: 10px; overflow: auto;">
    <div class="container-fluid ">
        <div *ngIf="!isLoading">
            <button class="btn btn-sm btn-outline-primary mr-2" (click)="addSub()">
                <fa-icon [icon]="faPlus"></fa-icon>
                Subscription
            </button>
        </div>
        <div class="mt-1">
            <table *ngIf="alertSubsTable.length != 0 && !isLoading" class="table mb-0 status-bottom-border">
                <thead>
                    <tr>
                        <th scope="col">
                            <span  style="cursor:pointer;" (click)="sort('status')">
                                Type
                                <fa-icon *ngIf="sortKey === 'status-des'" [icon]="faAngleDown">
                                </fa-icon>
                                <fa-icon *ngIf="sortKey === 'status-asc'" [icon]="faAngleUp">
                                </fa-icon>
                            </span>
                        </th>
                        <th scope="col">
                            <span  style="cursor:pointer;" (click)="sort('status')">
                                Taxii Server
                                <fa-icon *ngIf="sortKey === 'taxii-des'" [icon]="faAngleDown">
                                </fa-icon>
                                <fa-icon *ngIf="sortKey === 'taxii-asc'" [icon]="faAngleUp">
                                </fa-icon>
                            </span>
                        </th>
                        <th scope="col">
                            <span  style="cursor:pointer;" (click)="sort('status')">
                                Target
                                <fa-icon *ngIf="sortKey === 'taxii-des'" [icon]="faAngleDown">
                                </fa-icon>
                                <fa-icon *ngIf="sortKey === 'taxii-asc'" [icon]="faAngleUp">
                                </fa-icon>
                            </span>
                        </th>
                        <th scope="col">
                            <span>
                                Actions
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let obj of alertSubsTable; let i = index;">
                        <tr>
                            <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name" (click)="changeShow(i)">
                                {{obj._source.type}}
                            </td>
                            <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name" (click)="changeShow(i)">
                                {{obj._source.taxiiServer}}
                            </td>
                            <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name" (click)="changeShow(i)">
                                {{ getTarget(i) }}
                            </td>
                            <td style="cursor:pointer; color:#007bff; font-weight: normal; width: 12%;"
                                class="row-name">
                                <button type="button" class="btn btn-fa btn-danger ml-1"
                                    (click)="deleteSub(obj)"
                                    title="Click to delete the subscription">
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </td>
                        </tr>
                        <tr class="expanded-panel" *ngIf="rowNumber == i">
                            <td colspan="5">
                                <pre class="preview-json-window">{{ alertResults | json }}</pre>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <div class="footer" *ngIf="totalPages !== 0 && !isLoading" [ngClass]="{'footer-no-bottom': totalPages == 1}">
                <div *ngIf="totalPages !== 1 && !isLoading"  class="span-footer float-left">
                    <span >Page: {{currentPage}}/{{totalPages}}</span>
                </div>
                <div class="buttons-footer">
                    <button *ngIf="currentPage > 1 && !isLoading" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('left')">
                        <fa-icon [icon]="faArrowLeft"></fa-icon>
                    </button>
                    <button *ngIf="currentPage < totalPages && !isLoading" class="btn btn-fa-sm btn-outline-primary ml-1 btn-status" (click)="nextPage('right')">
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="!isLoading && alertSubsTable.length == 0">
            <span style="font-weight: bold; text-align: left;">No Alert Subscriptions for current user</span>
        </div>
        <ng-container *ngIf="isLoading">
            <div class="row-name pl-3 pt-3">
                Loading...
                <span class="spinner-grow spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
            </div>
        </ng-container>
    </div>
</div>