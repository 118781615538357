import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faPlus, faTrash, faBan } from '@fortawesome/free-solid-svg-icons';
import { StixService } from 'src/app/stix-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-string-array-dialog',
  templateUrl: './string-array-dialog.component.html',
  styleUrls: ['./string-array-dialog.component.css']
})
export class StringArrayDialogComponent implements OnInit {
  faPlus = faPlus;
  faTrash = faTrash;
  faBan= faBan;

  currentStringValidator?: any;
  /*currentStringInput: FormControl = new FormControl();
  currentStringInput2: FormControl = new FormControl();
  currentStringInput3: FormControl = new FormControl();*/
  currentString: string = '';
  //currentStrings: string[] = [];
  isAddingElement: boolean = false;
  newStr: string = "";
  labels: string;
  tlp_options = [];
  tooltip: string;
  env = environment;
  tlpDescription: string = "";
  disableMarkingDefInput = false;

  constructor(public stixService: StixService,
    public dialogRef:
    MatDialogRef<StringArrayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
    String) { 
      console.log(data);
      this.currentStringValidator = data;
    }

  ngOnInit(): void {
    if(environment.tlpConfig.enabledTlpVersions.length === 1){
      this.tlp_options = this.stixService.getTlpOptions(environment.tlpConfig.enabledTlpVersions[0].value);
    }
  }

  onNoClick(): void {
    this.dialogRef.close(this.currentString);
  }

  onX(): void {
    //this.dialogRef.close(this.currentString);
    this.dialogRef.close('');
  }

  onConfirm(): void {
    // let result = this.addButton();

    // if(result.valid === false){
    //   this.errorMessage = result.errorMessage;
    //   return;
    // }

    console.log(this.currentString);
    this.dialogRef.close(this.currentString);
  }

  getTlpOptions(event: any) {
    this.tlp_options = this.stixService.getTlpOptions(event.target.value);
    this.currentString = '';
  }

  onTlpChange(event) {
    this.disableMarkingDefInput = false;
    
    if (event && event.target && event.target.value) {
      const tlp = this.tlp_options.find(o => o.value === event.target.value);
      if (tlp) {
        this.disableMarkingDefInput = true;
        this.tlpDescription = tlp.description;
      }
    }
  }
  
}
