import { Injectable } from "@angular/core";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private poolData = {
        UserPoolId: environment.cognitoUserPoolId,
        ClientId: environment.cognitoAppClientId
    };

    private sessionUserAttributes = null;
    private currentUser: CognitoUser | null = null;
    
    constructor( ) { }

    get isAuthenticated(): boolean {
        let isAuth = false;
        let userPool = new CognitoUserPool(this.poolData);
        let cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    console.error(err.message || JSON.stringify(err));
                }
                this.currentUser = cognitoUser;
                isAuth = session.isValid();
            })
        }

        // return isAuth;
        return true;
    }

    login(email: string, password: string): Promise<any> {
        let authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });
        let userPool = new CognitoUserPool(this.poolData);
        let userData = { Username: email, Pool: userPool };
        var cognitoUser = new CognitoUser(userData);
        this.currentUser = cognitoUser;
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (_) => {
                    console.debug("Authenticated!");
                    resolve(authenticationDetails);
                    return;
                },
                onFailure: (err) => {
                    reject(err.message || JSON.stringify(err));
                    return;
                },
                newPasswordRequired: (userAttributes) => {
                    console.debug("User created by Admin, must set new password");
                    delete userAttributes.email_verified;
                    delete userAttributes.email;
                    this.sessionUserAttributes = userAttributes;
                    reject("You must set a new password");
                    return;
                },
            });
        });
    }

    logout() {
        this.currentUser?.signOut();
        this.currentUser = null;
    }
    
    isAdmin() {
        return true;
    }

    handleNewPassword(newPassword: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.currentUser.completeNewPasswordChallenge(newPassword, this.sessionUserAttributes, {
                onSuccess: (session) => {
                    console.debug("New Password Set!");
                    resolve(session);
                    return;
                },
                onFailure: (err) => {
                    reject(err.message || JSON.stringify(err));
                    return;
                }
            })
        })
    }
}