<div class="d-flex flex-column justify-content-start align-items-center mb-3">
    <div class="d-flex flex-row justify-content-around align-items-end gr-top-width">
        <div class="w-75 pl-3 d-flex align-items-end">
            <div class="mb-3">
                <img class="card-img" src="./assets/sword.svg" class="center" width="80" height="80" />
            </div>
            <span class="title-main-size3">HOW?</span>
        </div>

        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
    </div>

    <div class="horizonal-rule-top"></div>
    <div class="d-flex justify-content-center align-items-start w-80">
        <div class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">Adding Weaknesses</h6>
            <div class="gr-help-section-content">
                <p><b>Common Controls</b>: A shared safety measure or security practice that is used across different systems, departments, or organizations to protect against threats.</p>
                <p><b>Common Weaknesses</b>:  A “weakness” is a condition in software, firmware, or hardware that, under certain circumstances, could contribute to a vulnerability within a system.</p>
                <p><b>Common Vulnerabilities</b>: Weak spots or flaws found in software or systems that make them easy targets for hackers or malicious software.</p>
                <p>Was there a weak control measure that may have resulted in a vulnerability?</p>
                <p>Select a common weakness if one was identified.</p>
                <p>If a vulnerability was exploited, enter the CVE# or use the link to find one then enter it.</p>
            </div>
        </div>
        <div class="d-flex flex-column align-items-left gr-outter-wrapper mt-5" style="position: relative;">
            <div class="cwe-section pt-4">     
                <div class="form-check form-check-inline gr-input-wrapper ml-5 mr-5 pl-4 pr-4 mt-1 mb-3">
                    <div class="mr-5">Did Threat Actor exploit a weakness and/or vulnerability that is bypassed or non-existant?</div>
                    <div class="form-check form-check-inline mr-3">
                        <input class="form-check-input" type="radio" name="answer" id="answerY" value="Y" [(ngModel)]="answer" (change)="toggleAnswer($event)">
                        <label class="form-check-label" for="answerY">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="answer" id="answerN" value="N" [(ngModel)]="answer" (change)="toggleAnswer($event)">
                        <label class="form-check-label" for="answerN">No</label>
                    </div>
                </div>

                <!--div class="d-flex col-12">
                    <div class="col-11 pl-5 mt-4 mb-3 h6">Did Threat Actor exploit a weakness and/or vulnerability that is bypassed or non-existant?</div>
                    <div class="col-1 guided-toggle-wrapper mt-4" [class]="{'guided-toggle': answer}">
                        <ui-switch size="small" [(ngModel)]="answer" (change)="toggleAnswer($event)"
                            labelOn="Yes" labelOff="No"></ui-switch>
                            <div *ngIf="answer" class="ml-2" style="font-size: 0.8rem; margin-top: -5px;">True</div>
                            <div *ngIf="!answer" class="ml-2" style="font-size: 0.8rem; margin-top: -5px;">False</div>
                    </div>
                </div-->

                <div class="d-flex col-12">
                    <div class="col-11 pl-5 mt-3 mb-3 h6" style="color: #007bff;">Common Controls</div>
                    <div class="col-1">
                        <button class="btn btn-primary btn-circle float-right" (click)="angleDownCtl = !angleDownCtl" [ngClass]="{'btn-angle-up': angleDownCtl}" [disabled]="answer!=='Y'">
                            <fa-icon *ngIf="!angleDownCtl" [icon]="faAngleDown"></fa-icon>
                            <fa-icon *ngIf="angleDownCtl" class="angle-up" [icon]="faAngleUp"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <div class="col-9 ml-5 pl-3 mb-3" *ngIf="angleDownCtl">
                        <div>Was there a control not implemented that resulted in a weakness?</div>
                        <select class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="currentControl" multiple size="7" (change)="checkCart()">
                            <option *ngFor="let option of controlOptions" [value]="option">{{option}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="cwe-section pt-2">
                <div class="d-flex col-12">
                    <div class="col-11 pl-5 mt-3 mb-3 h6" style="color: #007bff;">TTP Designation</div>
                    <div class="col-1">
                        <button class="btn btn-primary btn-circle float-right" (click)="angleDownTtp = !angleDownTtp" [ngClass]="{'btn-angle-up': angleDownTtp}">
                            <fa-icon *ngIf="!angleDownTtp" [icon]="faAngleDown"></fa-icon>
                            <fa-icon *ngIf="angleDownTtp" class="angle-up" [icon]="faAngleUp"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="d-flex col-12 p-0">
                    <div class="col-9 ml-5 pl-3 mb-3" *ngIf="angleDownTtp">
                        <select class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="currentTtp" (change)="checkCart()">
                            <option value="">None</option>
                            <option *ngFor="let option of getTtps()" [value]="option.id">{{ option.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="cwe-section pt-2">
                <div class="d-flex col-12">
                    <div class="col-11 pl-5 mt-3 mb-3 h6" style="color: #007bff;">Common Weakness<span class="text-danger ml-1">*</span></div>
                    <div class="col-1">
                        <button class="btn btn-primary btn-circle float-right" (click)="angleDownWks = !angleDownWks" [ngClass]="{'btn-angle-up': angleDownWks}">
                            <fa-icon *ngIf="!angleDownWks" [icon]="faAngleDown"></fa-icon>
                            <fa-icon *ngIf="angleDownWks" class="angle-up" [icon]="faAngleUp"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="d-flex col-12 p-0">
                    <div class="col-9 ml-5 pl-3 mb-3" *ngIf="angleDownWks">
                        <div>Was this an exploitation against a <a href="https://cwe.mitre.org/data/definitions/1003.html" target="_blank">weakness</a>?</div>
                        <select class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="currentWeakness" (change)="checkCart()">
                            <!--option disabled selected style="color: #999" value="">Unsecured Network</option-->
                            <option *ngFor="let option of weaknessOptions" [value]="option.key">{{ option.value }}</option>
                        </select>
                    </div>
                </div>
            </div>
            
            <div class="cwe-section">
            <div class="d-flex col-12">
                <div class="col-11 pl-5 mt-3 mb-3 h6" style="color: #007bff;">Common Vulnerability</div>
                <div class="col-1">
                    <button class="btn btn-primary btn-circle float-right" (click)="angleDownVul = !angleDownVul" [ngClass]="{'btn-angle-up': angleDownVul}" [disabled]="!currentWeakness">
                        <fa-icon *ngIf="!angleDownVul" [icon]="faAngleDown"></fa-icon>
                        <fa-icon *ngIf="angleDownVul" class="angle-up" [icon]="faAngleUp"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="col-12 p-0">
                <div class="col-9 ml-5 pl-3 mb-3" *ngIf="angleDownVul">
                    <div>Was there a vulnerability that was exploited that can be referenced by 
                        <a href="https://www.cisa.gov/known-exploited-vulnerabilities-catalog" target="_blank">CVE#</a>?
                    </div>
                    <input class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="currentCveId" (ngModelChange)="isCveValid()" style="text-transform: uppercase;"
                        placeholder="CVE-[4 digit year]-[4 or 5 digit ID]" />
                        <div *ngIf="isValidating">
                           <span class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true" style="color: #007bff;"></span>
                           <span style="font-size: 14px;">Validating CVE...</span>
                        </div>
                        <div *ngIf="!isValidating && message" class="success-message">{{message}}&nbsp;&check;</div>
                        <div *ngIf="!isValidating && errorMessage" class="warning-message">{{errorMessage}}</div>
                </div>
            </div>   
               
        </div>
        <!--app-guided-cart class="w-100" #guidedCart [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [editModeEvent]="editModeEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart-->
        <app-guided-cart class="w-100" #guidedCart [currentComponent]="component" [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [editModeEvent]="editModeEmitter" [syncCartEvent]="syncCartEmitter" [getEditItemEvent]="getEditItemEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart>        
    </div>   
</div>
