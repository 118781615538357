<div class="d-flex align-items-center" [ngClass]="{'mt-1':stixService.guidedUI}">
    <div>
        <p *ngIf="!stixService.guidedUI" class="stix-title" [title]="tooltip">External References</p>
        <p *ngIf="stixService.guidedUI" class="stix-title" [title]="tooltip">Add External References,
             such as URLs or Hash types</p>
    </div>
    <div class="ml-3">
        <div class="float-right">
            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                <button class="btn btn-fa-sm btn-primary" type="button" *ngIf="!isAddingExternalReferences"
                    (click)="openDialog()">
                    <fa-icon [icon]="faPlus"></fa-icon>
                    <!-- Add -->
                </button>
            </ng-container>
            <button class="btn btn-fa-sm btn-outline-secondary fa-ban-sm" type="button"
                *ngIf="isAddingExternalReferences" (click)="addOrCancel()">
                <fa-icon [icon]="faBan"></fa-icon>
                <!-- Cancel -->
            </button>
        </div>
    </div>
</div>

<div class="row">
    <div class="mx-auto warning-message">{{this.errorMessage}}</div>    
</div>

<div class="form-group mt-2 pl-0" *ngIf="!isAddingExternalReferences && this.stixService.externalReferences.length > 0">
    <accordion>
        <accordion-group *ngFor="let externalReference of this.stixService.externalReferences"
            [heading]="externalReference.source_name" [panelClass]="'accordion-panel-class'">
            <ng-container *ngIf="!this.stixService.revoked && !this.stixService.revocation">
                <div>
                    <button type="button"
                        class="btn btn-fa btn-primary" 
                        (click)="editExternalRef(externalReference)">
                        <fa-icon [icon]="faEdit" ></fa-icon>
                    </button>
                    <button type="button"
                        class="btn btn-fa btn-danger" style="margin-left: 10px;"
                        (click)="deleteExternalRef(externalReference)">
                        <fa-icon [icon]="faTrash" ></fa-icon>
                    </button>
                    <!-- <button class="btn btn-sm btn-danger add-button" type="button"
                        (click)=editExternalRef(externalReference)
                        style="height:40px;width:auto; background-color: rgb(40, 130, 255)">
                        Edit
                    </button>
                    <button class="btn btn-sm btn-danger add-button" type="button"
                        (click)=deleteExternalRef(externalReference)
                        style="height:40px;width:auto;background-color:rgb(210, 0, 0)">
                        Delete
                    </button> -->
                </div>
            </ng-container>
            <pre>{{ externalReference | json }}</pre>
        </accordion-group>
    </accordion>
</div>

<div class="form-group mt-2" *ngIf="isAddingExternalReferences">
    <div class="row">
        <div class="col-12">
            <div class="input-group mb-2">
                <span class="input-group-text">
                    <b>Source Name*</b>
                </span>
                <input class="form-control" [(ngModel)]="this.currentExternalReference.source_name" type="text">
            </div>
        </div>

        <div class="col-12">
            <div class="input-group mb-2">
                <span class="input-group-text">
                    <b>Description</b>
                </span>
                <input class="form-control" [(ngModel)]="this.currentExternalReference.description" type="text">
            </div>
        </div>

        <div class="col-12">
            <div class="input-group mb-2">
                <span class="input-group-text">
                    <b>URL</b>
                </span>
                <input class="form-control" [(ngModel)]="this.currentExternalReference.url " type="text">
            </div>
        </div>
        <div class="input-group" style="margin-left:15px; margin-right:15px;">
            <span class="input-group-text">
                <div class="tooltipright"><b>Hash Type</b>
                    <!--<span class="toolttext">Use SHA-256 whenever possible<br>(<a
                            href="https://docs.oasis-open.org/cti/stix/v2.1/cs02/stix-v2.1-cs02.html#_odoabbtwuxyd">STIX
                            2.1 Section 2.7</a>).</span> -->
                            <fa-icon class="pl-1"
                                [icon]="faInfoCircle">
                            </fa-icon>
                            <div class="toolttext">
                                To enhance compatibility, the SHA-256 hash SHOULD be used whenever possible.
                            </div>
                </div>
            </span>
            <select type="text" class="form-control" style="max-width: 102px;" [(ngModel)]="this.hashType" name="hashes" id="hashes">
                <!--<option value="SHA-256" selected="selected">SHA-256</option>-->
                <option *ngFor="let item of hashOptions" [value]="item.value">{{item.value}}</option>
            </select>
            <span class="input-group-text">
                <b>Hash Value</b>
            </span>
            <input class="form-control" [(ngModel)]="this.hashValue" type="text">
            <button class="btn btn-fa-sm btn-primary" type="button"
                (click)="addHash(this.hashType, this.hashValue)"
                [disabled]="this.hashValue == undefined || this.hashValue == ''">
                <fa-icon [icon]="faPlus"></fa-icon>
            </button>
        </div>
        <!-- <div class="col-4">
            <div class="input-group mb-2">
                <span class="input-group-text">
                    <div class="tooltipright"><b>Hash Type</b>
                         <span class="toolttext">Use SHA-256 whenever possible<br>(<a
                                href="https://docs.oasis-open.org/cti/stix/v2.1/cs02/stix-v2.1-cs02.html#_odoabbtwuxyd">STIX
                                2.1 Section 2.7</a>).</span> 
                                <fa-icon class="pl-1"
                                    [icon]="faInfoCircle">
                                </fa-icon>
                                <div class="toolttext">
                                    To enhance compatibility, the SHA-256 hash SHOULD be used whenever possible.
                                </div>
                    </div>
                </span>
                <select type="text" class="form-control" [(ngModel)]="this.hashType" name="hashes" id="hashes"> -->
                    <!--<option value="SHA-256" selected="selected">SHA-256</option>-->
                    <!-- <option *ngFor="let item of hashOptions" [value]="item.value">{{item.value}}</option>
                </select>
                <span class="input-group-text">
                    <b>Hash Value</b>
                </span>
                <input class="form-control" [(ngModel)]="this.hashValue" type="text">
                <button class="btn btn-sm btn-primary mr-1" type="button"
                    (click)="addHash(this.hashType, this.hashValue)"
                    [disabled]="this.hashValue == undefined || this.hashValue == ''">
                    Add
                </button>
            </div>
        </div>
        <!--div class="col-6">
            <div class="input-group mb-2">
                <span class="input-group-text">
                    <b>Hash Value</b>
                </span>
                <input class="form-control" [(ngModel)]="this.hashValue" type="text">
            </div>
        </div>
        <div class="col-1">
            <button class="btn btn-sm btn-primary add-button" type="button"
                (click)="addHash(this.hashType, this.hashValue)"
                [disabled]="this.hashValue == undefined || this.hashValue == ''">
                Add
            </button>
        </div> -->

        <div class="row">
            <div class="ml-3 mb-2 pl-4 warning-message">
                {{this.hashError}}
            </div>
        </div>

        <div class="row" id="added-hash">
            <span class="tab">
                <div class="form-group mt-2 added-items-font">
                    <li *ngFor="let x of this.currentExternalReference.hashes | keyvalue" tabindex="0">
                        {{x.key}}: {{x.value}}
                        <button type="button" class="btn btn-fa-xsm btn-danger trash-can"
                            (click)="deleteHash(x.key)" (keydown.enter)="deleteHash(x.key)">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                        <!-- <span><img src="./assets/trash.svg" width="16" height="16" role="button" tabindex="0" class="ml-2 center trash-can"
                            title="Delete" alt="Delete" (click)="deleteHash(x.key)" (keydown.enter)="deleteHash(x.key)">
                        </span>  -->
                    </li>
                </div>
            </span>
        </div>

        <div class="col-12">
            <div class="input-group mb-2">
                <span class="input-group-text">
                    <b>External ID</b>
                </span>
                <input class="form-control" [(ngModel)]="this.currentExternalReference.external_id" type="text">
            </div>
        </div>
    </div>

    <div class="float-right mb-3">
        {{this.errorMessage}}
        <button class="btn btn-fa-sm btn-primary" type="button" (click)="addExternalReference()"
            [disabled]="this.currentExternalReference.source_name == ''">
            <fa-icon [icon]="faPlus"></fa-icon>
            <!-- Add -->
        </button>
    </div>
</div>