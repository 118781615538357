<div class="dialog">
    <div class="modal-header">
        <h4 class="modal-title" id="publish-modal-title">
            <span>Create Relationship</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="onX()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label class="dialogLabel">Source</label>
            <div class="warning" *ngIf="sourceWarning !== ''">
                <h6>{{sourceWarning}}</h6>
            </div>
            <select type="text" class="form-control" [(ngModel)]="relationshipSourceID" (change)="sourceChange()">
                <option [ngValue]="null" [disabled]="true">Select Source</option>
                <option *ngFor="let option of relationshipOptions" [ngValue]="option.id">{{stixService.getComponentDisplay(option)}}</option>
            </select>
        </div>
        <div class="form-group">
            <label class="dialogLabel">Target</label>
            <div class="warning" *ngIf="targetWarning !== ''">
                <h6>{{targetWarning}}</h6>
            </div>
            <select type="text" class="form-control" [(ngModel)]="relationshipTargetID" (change)="targetChange()">
                <option [ngValue]="null" [disabled]="true">Select Target</option>
                <option *ngFor="let option of relationshipOptions" [ngValue]="option.id">{{stixService.getComponentDisplay(option)}}</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <button type="button" class="btn btn-sm btn-success" (click)="onConfirm()">
            <fa-icon [icon]="faCheck" class="pr-1"></fa-icon>
            Confirm
        </button>
    </div>
</div>