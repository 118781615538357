import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faArrowDown, faArrowsAlt, faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { StixService } from '../stix-service.service';
import { GuidedService } from '../guided.service';
@Component({
  selector: 'app-guided-menu',
  templateUrl: './guided-menu.component.html',
  styleUrls: ['./guided-menu.component.css']
})
export class GuidedMenuComponent implements OnInit {
  @Input() updateMenuSelection: string;
  @Output() menuSelection: EventEmitter<any>;

  faArrowDown = faArrowDown;
  faArrowsAlt = faArrowsAlt;
  faCartArrowDown = faCartArrowDown;

  pageNameEnum = {
    'indicator-type-1':     1,
    'indicator-type-2':     2,
    'indicator-type-3':     3,
    'additional-info-1':    4,
    'additional-info-2':    5,
    'additional-info-3':    6,
    'who-individual':       7,
    'how-event-detection':  8,
    'how-observable':       9,
    'how-pattern-builder':  10,
    'how-ttp':              11,
    'how-cwe':              12,
    // 'how-event':            13,
    // 'when-event':           14,
    'what-impact':          15,
    'where-location':       16,
    // 'why-common-behaviors': 17,
    // 'why-attacker':         18,
    'report-assessment':    19,
    'relationship-1':       20,
    'relationship-2':       21,
    'object-addition':      22,
    'build-review':         23,
    'tlp-type':             24,
    'publish-type-1':       25, 
    'publish-type-2': 	    26,
    'publish-review':       27,
    'publish-success':      28,
  }
  currentPageIndex = 0;
  currentPage: string;
  rootCollectionSelected = false;
  hasUnassignedObjMarkingRefObjects = false;

  isGuidedReport = false;
  expendGrSubmenu = false;
  furtherestVisitedGrPage = null;
  isEditting = false;

  constructor(
    public stixService: StixService,
    public guidedService: GuidedService,
  ) {
    this.menuSelection = new EventEmitter();
    this.currentPage = 'indicator-type-1';
  }

  ngOnInit(): void {
    this.menuSelection.emit(this.currentPage);

    if (this.updateMenuSelection) {
      this.currentPage = this.updateMenuSelection;
    }

    this.isGuidedReport = this.guidedService.isGuidedReport;

    this.stixService.getData().subscribe(data => {
      if ((data.type === 'page-forward'
          || data.type === 'page-back')
            && data.menuSelection) {
                this.isEditting = false;
                this.currentPage = data.menuSelection;
                this.currentPageIndex = this.pageNameEnum[data.menuSelection];

                this.isGuidedReport = false;
                this.expendGrSubmenu = false;

                if (this.currentPageIndex >= this.pageNameEnum['who-individual']
                  && this.currentPageIndex <= this.pageNameEnum['report-assessment']
                ) {
                  this.isGuidedReport = true;
                  this.expendGrSubmenu = true;

                  if (!this.furtherestVisitedGrPage) {
                    this.furtherestVisitedGrPage = data.menuSelection;
                  } else {
                    if (this.furtherestVisitedGrPage === 'report-assessment') {
                      this.furtherestVisitedGrPage = 'who-individual';
                    } else if (this.pageNameEnum[this.furtherestVisitedGrPage] < this.pageNameEnum[data.menuSelection]) {
                      this.furtherestVisitedGrPage = data.menuSelection;
                    }
                  }
                }
      }

      if (data.type === 'edit-object' && data.menuSelection) {
        this.isEditting = true;
        this.currentPage = data.menuSelection;
        this.currentPageIndex = this.pageNameEnum[data.menuSelection];
        this.isGuidedReport = true;
        this.expendGrSubmenu = true;
      }

      if (data.type === 'update-object' || data.type === 'cancel-update') {
        this.currentPage = data.menuSelection;
        this.currentPageIndex = this.pageNameEnum[data.menuSelection];
        this.isGuidedReport = false;
        this.expendGrSubmenu = false;
        this.isEditting = false;
      }

      if (data.type === 'is-root-collection-selected') {
        this.rootCollectionSelected = data.value;
      }

      if (data.type === 'update-unassigned-obj-marking-ref-objects'
        && data.value) {
            this.hasUnassignedObjMarkingRefObjects = data.value.length > 0;
      }
    })
  }

  disableGrSubmenuItem(item) {
    if (this.isEditting) {
      return (this.pageNameEnum[item] > this.currentPageIndex) || (this.pageNameEnum[item] < this.currentPageIndex);
    } else {
      return this.pageNameEnum[item] > this.pageNameEnum[this.furtherestVisitedGrPage];
    }
  }

  onSelectMenu(event) {
    this.currentPage = event;
    this.menuSelection.emit(this.currentPage);
  }

  disableMenuItem(pageType) {
    switch (pageType) {
        case 'indicator-type':
        case 'additional-info':
        case 'relationship':
        case 'object-addition':
        case 'build-review':
            if (this.currentPageIndex >= this.pageNameEnum['tlp-type']) {
                return true;
            } else if (this.isEditting) {
                return true;
            }
    }

    switch (pageType) {
      case 'additional-info':
        return this.currentPageIndex <= this.pageNameEnum['indicator-type-3']
          || this.currentPageIndex >= this.pageNameEnum['additional-info-3'];
      case 'build-review':
      case 'publish-type':
        if (this.isEditting) return true;
        else return this.currentPageIndex <= this.pageNameEnum['additional-info-3'] && this.stixService.bundle?.objects?.length === 0;
      case 'publish-review':
        return !this.rootCollectionSelected
          || (this.currentPageIndex <= this.pageNameEnum['additional-info-3'] && this.stixService.bundle?.objects?.length === 0);
      case 'publish-success':
        return this.currentPageIndex <= this.pageNameEnum['additional-info-3'];
      case 'tlp-type':
        if (this.isEditting) return true;
        else return !this.hasUnassignedObjMarkingRefObjects;
      default:
        return false;
    }
  }

  grSubMenuHandler() {
    this.expendGrSubmenu = !this.expendGrSubmenu;
  }

}
