import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AddComponentComponent } from 'src/app/add-component/add-component.component';
import { GuidedService } from 'src/app/guided.service';
import { StixService } from 'src/app/stix-service.service';
import { faCartPlus, faShoppingCart, faQuestionCircle, faPlus, faEdit, faTrash, faTools } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidV4 } from "uuid";
import { GuidedCartComponent } from '../guided-cart/guided-cart.component';
import { FormModel } from '../../dynamic-form-component/form-model';

@Component({
  selector: 'app-what-impact',
  templateUrl: './what-impact.component.html',
  styleUrls: ['./what-impact.component.css']
})
export class WhatImpactComponent implements OnInit {
  @ViewChild("guidedCart", { static: false }) guidedCart: GuidedCartComponent;

  //With Cart
  @Output() disableCartEmitter = new EventEmitter<boolean>();
  @Output() newItemEmitter = new EventEmitter<any>();
  @Output() editModeEmitter = new EventEmitter<boolean>();
  @Output() syncCartEmitter = new EventEmitter<any>();
  @Output() getEditItemEmitter = new EventEmitter<any>();

  //With Parent
  @Output() showAssistance = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();

  component: string = 'what-impact'

  @ViewChild("appAddComponent", { static: false }) appAddComponent: AddComponentComponent;

  faCartPlus = faCartPlus;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faCart = faShoppingCart;
  faQuestionCircle = faQuestionCircle;
  faTools = faTools;

  lossType = '';
  infoCompromised = '';
  monetaryImpacted = '';
  physicalImpactType = '';
  assetAffected = '';
  types = [];
  data = [];
  actionMode = 'add';
  categoryToUpdate = '';
  errorMessage = '';

  objectSelection = null;

  lossTypeOptions: any[] = [
    { key: 'confirmed-loss', value: 'Confirmed Loss' },
    { key: 'contained', value: 'Contained' },
    { key: 'exploited-loss', value: 'Exploited Loss' },
    { key: 'none', value: 'None' },
    { key: 'suspected-loss', value: 'Suspected Loss' }
  ]

  informationTypeOptions: any[] = [
    { key: 'classified-material', value: 'Classified Material' },
    { key: 'communication', value: 'Communication' },
    { key: 'credentials-admin', value: 'Credentials Admin' },
    { key: 'credentials-user', value: 'Credentials User' },
    { key: 'financial', value: 'Financial' },
    { key: 'legal', value: 'Legal' },
    { key: 'payment', value: 'Payment' },
    { key: 'phi', value: 'PHI' },
    { key: 'pii', value: 'PII' },
    { key: 'proprietary', value: 'Proprietary' },
    { key: 'system', value: 'System' }
  ]

  monetaryImpactOptions: any[] = [
    { key: 'asset-and-fraud', value: 'Asset and Fraud' },
    { key: 'brand-damage', value: 'Brand Damage' },
    { key: 'business-disruption', value: 'Business Disruption' },
    { key: 'legal-and-regulatory', value: 'Legal and Regulatory' },
    { key: 'operating-costs', value: 'Operating Costs' },
    { key: 'ransom-demand', value: 'Ransom Demand' },
    { key: 'ransom-payment', value: 'Ransom Payment' },
    { key: 'response-and-recover', value: 'Response and Recover' },
    { key: 'uncategorized', value: 'Uncategorized' }
  ]

  physicalImpactOptions: any[] = [
    { key: 'damaged-functional', value: 'Damaged Functional' },
    { key: 'damaged-nonfunctional', value: 'Damaged Nonfunctional' },
    { key: 'destruction', value: 'Destruction' },
    { key: 'none', value: 'None' },
    { key: 'unknown', value: 'Unknown' }
  ]

  assetTypeOptions: any[] = [
    { key: 'building-doors', value: 'Building Doors' },
    { key: 'building-windows', value: 'Building Windows' },
    { key: 'buildings', value: 'Buildings' },
    { key: 'computers-mobile', value: 'Computers Mobile' },
    { key: 'computers-personal', value: 'Computers Personal' },
    { key: 'computers-server', value: 'Computers Server' },
    { key: 'environment', value: 'Environment' },
    { key: 'ics-actuator', value: 'ICS Actuator' },
    { key: 'ics-engineering-workstation', value: 'ICS Engineering Workstation' },
    { key: 'ics-historian', value: 'ICS Historian' },
    { key: 'ics-hmi', value: 'ICS HMI' },
    { key: 'ics-other', value: 'ICS Other' },
    { key: 'ics-plc', value: 'ICS PLC' },
    { key: 'ics-safety-system', value: 'ICS Safety System' },
    { key: 'ics-sensor', value: 'ICS Sensor' },
    { key: 'inventory', value: 'Inventory' },
    { key: 'network-device', value: 'Network Device' },
    { key: 'private-infrastructure', value: 'Private Infrastructure' },
    { key: 'public-infrastructure', value: 'Public Infrastructure' },
    { key: 'security-containers', value: 'Security Containers' },
    { key: 'vehicles', value: 'Vehicles' }
  ]

  editMode: boolean = false;
  cartIds: number[] = [];

  constructor(
    public guidedService: GuidedService,
    public stixService: StixService,
  ) { }

  ngOnInit(): void {
    const editCartTimeout = setTimeout( () => {
      if(this.guidedService.editCartObject.component && this.guidedService.editCartObject.component === this.component){
        this.getEditItemEmitter.emit(this.guidedService.editCartObject);
      }
    }, 100);
  }

  addCartItem(cancel){
    if(cancel){
      this.reset();
      this.checkCart();
      return;
    }

    let emitObj = {
      mode: this.component,
      push: []
    }

    if (this.lossType !== '') {
      let temp = {
        "type": "impact",
        "id": 'impact--' + `${uuidV4()}`,
        "created": (new Date()).toISOString(),
        "modified": (new Date()).toISOString(),
        "impact_category": "confidentiality-ext",
        cartId: this.cartIds[0] ? this.cartIds[0] : null,
        "extensions": {
            "confidentiality-ext": {
                "loss_type": this.lossType
            },
            "extension-definition--7cc33dd6-f6a1-489b-98ea-522d351d71b9": {
                "extension_type": "new-sdo"
            }
        }
      }

      if(this.infoCompromised !== '') temp.extensions['confidentiality-ext']['information_type'] = this.infoCompromised;
      
      emitObj.push.push(temp)
    }
    if (this.physicalImpactType !== '') {
      let temp = {
        "type": "impact",
        "id": 'impact--' + `${uuidV4()}`,
        "created": (new Date()).toISOString(),
        "modified": (new Date()).toISOString(),
        "impact_category": "physical",
        cartId: this.cartIds[1] ? this.cartIds[1] : null,
        "extensions": {
            "physical-ext": {
                "impact_type": this.physicalImpactType,
            },
            "extension-definition--7cc33dd6-f6a1-489b-98ea-522d351d71b9": {
                "extension_type": "new-sdo"
            }
        }
      };

      if(this.assetAffected !== '') temp.extensions['physical-ext']['asset_type'] = this.assetAffected;
      
      emitObj.push.push(temp);
    }
    if (this.monetaryImpacted !== '') {
      emitObj.push.push(
        {
          "type": "impact",
          "id": 'impact--' + `${uuidV4()}`,
          "created": (new Date()).toISOString(),
          "modified": (new Date()).toISOString(),
          "impact_category": "monetary",
          cartId: this.cartIds[2] ? this.cartIds[2] : null,
          "extensions": {
              "monetary-ext": {
                  "variety": this.monetaryImpacted 
              },
              "extension-definition--7cc33dd6-f6a1-489b-98ea-522d351d71b9": {
                  "extension_type": "new-sdo"
              }
          }
        }
      )
    }

    this.newItemEmitter.emit(emitObj);
    this.disableCartEmitter.emit(true);
    this.reset();
  }

  checkCart(){
    if(this.lossType === '' && this.physicalImpactType === '') {
      this.disableCartEmitter.emit(true);
      return;
    }

    this.disableCartEmitter.emit(false);
  }

  editCartItem(event){
    this.reset();
    this.editMode = true;

    if(event.impact_category === 'confidentiality-ext'){
      this.cartIds.push(event.cartId);

      this.lossType = event.extensions["confidentiality-ext"]["loss_type"];
      this.infoCompromised = event.extensions["confidentiality-ext"]["information_type"] ? event.extensions["confidentiality-ext"]["information_type"] : '';
    } else {
      this.cartIds.push(null);
    }

    if(event.impact_category === 'physical'){
      this.cartIds.push(event.cartId);

      this.physicalImpactType = event.extensions["physical-ext"]["impact_type"];
      this.assetAffected = event.extensions["physical-ext"]["asset_type"] ? event.extensions["physical-ext"]["asset_type"] : '';
    } else {
      this.cartIds.push(null);
    }

    if(event.impact_category === 'monetary'){
      this.cartIds.push(event.cartId);

      this.monetaryImpacted = event.extensions["monetary-ext"]["variety"];
    } else {
      this.cartIds.push(null);
    }

    this.checkCart();
  }

  reset() {
    this.lossType = '';
    this.infoCompromised = '';
    this.monetaryImpacted = '';
    this.physicalImpactType = '';
    this.assetAffected = '';
    
    this.categoryToUpdate = '';
    this.errorMessage = '';

    this.editMode = false;
    this.cartIds = [];
  }

  showAssistanceTrigger(){
    this.showAssistance.emit();
  }

  addComponent() {
    if(this.guidedCart.cartLength === 0){
      this.reset();
      return;
    }
    let types = [];
    let data = [];

    for(let type in this.guidedCart.cart){
      let objects = this.guidedCart.cart[type];

      for(let obj of objects){
        types.push(obj.type);

        if(obj.display_name) delete obj.display_name;
        if(obj.cartId) delete obj.cartId;
        if(obj.linkId) delete obj.linkId;

        data.push(obj);
      }
    }

    this.data.forEach(d => {
      let dataTmp = {};
      dataTmp["impact_category"] = d["impact_category"];
      dataTmp["extensions"] = d["extensions"];
      data.push(dataTmp)

      types.push(d.type);
    })

    this.guidedService.addComponents(types, data);

     // Update Incident object with ref to Event
     setTimeout(() => {
      this.guidedService.findPreviouslyAddedObjects('incident', 'incident-object');
      this.guidedService.addedObjects.subscribe(addedObjects => {
        if (addedObjects.eventType === 'incident-object'
          && addedObjects.objects
          && addedObjects.objects.length > 0) {

            let incidentObject = addedObjects.objects[0];
          
            this.guidedService.findPreviouslyAddedObjects('impact', 'impact-object');
            this.guidedService.addedObjects.subscribe(addedObjects => {
              if (addedObjects.eventType === 'impact-object'
                && addedObjects.objects
                && addedObjects.objects.length > 0) {

                  let impactIds = [];
                  addedObjects.objects.forEach(o => {
                    impactIds.push(o.id);
                  })
                
                  incidentObject["extensions"][this.guidedService.incidentCoreExtentionIncidentId]["impact_refs"] = impactIds;
                  this.stixService.addComponent(Object.assign({}, incidentObject as FormModel));
              }
            })

        }
      })
    }, 300)
    
    this.reset();
  }

  redirectCart(event){
    if(event.component !== this.component){
      this.changePage.emit(event);
    } else {
      this.getEditItemEmitter.emit(event);
    }
  }

  syncCart(event){
    if(event === this.component){
      this.syncCartEmitter.emit();
    }
  }
}
