<ngb-toast *ngIf="showDuplicatedObjectToast" 
    header="Duplicated Content" 
    [autohide]="true" 
    class="bg-warning duplicated-objects-toast" 
    [delay]="8000"
>
    <div class="d-flex">
        <div>{{duplicates.length}} duplicated content found.</div>&nbsp;
        <span>Click<img src="./assets/merge-cells.svg" alt="" width="25" height="25" class="ml-1 mr-1" title="Duplicated Objects">to&nbsp;view.</span>
    </div>
</ngb-toast>

<!-- <ngb-toast
    header="Content Subscription" 
    [autohide]="false"
    class="bg-success subscription-objects-toast"
    style=""
    [delay]="8000"
>
    There has been new version of object created in your subscribed<br> or published collection. <a>Click to View</a>
    <br><br>
    There has been new report that reference content in your subscribed<br> or published collection. <a>Click to View</a>
</ngb-toast> -->

<div class="container-fluid">    

    <div *ngIf="fullScreenMode">
        <app-stix-viewer  class="full-width" [bundle]="getStixPreview()" [class]="{'full-screen': fullScreenMode}">
        </app-stix-viewer>
    </div>

    <div [class]="{'move-out-of-view': fullScreenMode}" class="row mt-3 ml-2 mr-2">
        <ng-container *ngIf="this.stixService.hasObjectsToLoad">{{this.importObjects(saveModal)}}</ng-container>
        <ng-container *ngIf="this.deletedCISAIdentity">{{this.openModal(CISAIdentityModal)}}</ng-container>
        <div class="col-1"></div>
        <div [class]="{ 'col-10': !guidedUI, 'col-12': guidedUI }">
            <div *ngIf="!guidedUI" class="row pl-3 pb-2">
                <h3>
                    Build & Publish STIX Objects
                </h3>
            </div>
            <div *ngIf="showObjectSection">
                <div class="row col-11">
                    <button *ngIf="!this.creatingObject" type="button"
                        class="btn btn-collapse btn-outline-primary pr-3 pl-3 pt-1 showlabel" (click)="createObject()">
                        <fa-icon [icon]="faAngleDoubleDown" class="pr-1"></fa-icon>
                        Create Object
                    </button>
                    <button *ngIf="this.creatingObject" type="button"
                        class="btn btn-collapse btn-outline-secondary pr-3 pl-3 pt-1 showlabel" (click)="createObject()">
                        <fa-icon [icon]="faAngleDoubleUp" class="pr-1"></fa-icon>
                        Create Object
                    </button>
                </div>
                <div class="col-12 pb-2">
                    <ng-container *ngIf="creatingObject">
                        <div class="row pl-3 pr-3 pt-2" *ngFor="let stixObject of stixObjects; let i=index">
                            <ng-container *ngIf="i%2==0">
                                <div class="col-6">
                                    <div class="row">
                                        <h3 class="section-title">
                                            {{ stixObjects[i].displayName }}
                                        </h3>
                                    </div>
                                    <div class="row">
                                        <div class="col col-md-3 col-lg-3" *ngFor="let object of stixObjects[i].objects">
                                            <button type="button" class="sdo-object-button btn tooltipmenu"
                                                routerLink="/add-component/{{ object.routeName }}">
                                                {{ object.displayName }}
                                                <div *ngIf="object.description" class="toolttextToRight"
                                                    [innerHTML]="object.description">
                                                </div>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="stixObjects[i+1]">
                                    <div class="col-6">
                                        <div class="row">
                                            <h3 class="section-title">
                                                {{ stixObjects[i+1].displayName }}
                                            </h3>
                                        </div>
                                        <div class="row">
                                            <div class="col col-md-3 col-lg-3"
                                                *ngFor="let object of stixObjects[i+1].objects">
                                                <button type="button" class="sdo-object-button btn tooltipmenu"
                                                    routerLink="/add-component/{{ object.routeName }}">
                                                    {{ object.displayName }}
                                                    <div *ngIf="object.description" class="toolttext"
                                                        [innerHTML]="object.description">
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>

                        <div *ngIf="environment.customConfiguration.analyst1.enabled" class="col-6">
                            <div class="row">
                                <h3 class="section-title">
                                    Indicator Bulletin
                                </h3>
                            </div>
                            <div class="row">
                                <div class="col col-md-3 col-lg-3">
                                    <button type="button" class="sdo-object-button btn tooltipmenu"
                                        (click)="openAnalyst1Modal()">
                                        Search
                                        <div class="toolttextToRight" [innerHTML]="'Import Analyst1 Indicator'">
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-6">
                            <div class="row">
                                <h3 class="section-title">
                                    Common Repo
                                </h3>
                            </div>
                            <div class="row">
                                <div class="col col-md-3 col-lg-3">
                                    <button type="button" class="sdo-object-button btn tooltipmenu"
                                        (click)="openObjectViewerModal()">
                                        Objects
                                        <div class="toolttextToRight" [innerHTML]="'Import Analyst1 Indicator'">
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </ng-container>
                </div>
            </div>
            <div *ngIf="showVisualizerSection">
            <div class="row col-11 pb-2 align-items-center">
                <button *ngIf="!this.showingVisualizer" type="button"
                    class="btn btn-collapse btn-outline-primary pr-3 pl-3 pt-1 pb-1 showlabel" style="text-align: left;"
                    (click)="showVisualizer()">
                    <fa-icon [icon]="faAngleDoubleDown" style="padding-right: 10px !important;"></fa-icon>
                    Visualizer
                </button>
                <button *ngIf="this.showingVisualizer" type="button"
                    class="btn btn-collapse btn-outline-secondary pr-3 pl-3 pt-1 pb-1 showlabel" style="text-align: left;"
                    (click)="showVisualizer()">
                    <fa-icon [icon]="faAngleDoubleUp" style="padding-right: 10px !important;"></fa-icon>
                    Visualizer
                </button>
                <a *ngIf="showingVisualizer && !fullScreenMode" (click)="openFullscreen()">
                    <img src="./assets/expand.png" alt="" width="25" height="25"
                        class="d-inline-block align-top expand-icon ml-3" title="Expand full screen">
                </a>
            </div>
            <app-stix-viewer *ngIf="showingVisualizer" class="full-width nav-link" [bundle]="getStixPreview()">
            </app-stix-viewer>
            <!-- We are not going with this approach to show on bundle page for now -->
            <!-- <div class="row col-11 pb-2">
                <button *ngIf="!this.showingAnalyst1" type="button"
                    class="btn btn-outline-primary pr-3 pl-3 pt-1 pb-1 show" (click)="showAnalyst1()">
                    <fa-icon [icon]="faAngleDoubleDown" class="pr-1"></fa-icon>
                    Analyst 1
                </button>
                <button *ngIf="this.showingAnalyst1" type="button"
                    class="btn btn-outline-secondary pr-3 pl-3 pt-1 pb-1 show" (click)="showAnalyst1()">
                    <fa-icon [icon]="faAngleDoubleUp" class="pr-1"></fa-icon>
                    Analyst 1
                </button>
            </div> -->
            </div>
            <div *ngIf="showingAnalyst1">
                <div class="border-tops">
                    <div class="row text-center pt-3 pb-4">
                        <div class="col-8 input-group search-bar">
                            <input class="form-control search-input" [(ngModel)]="searchTextAnalyst1"
                                placeholder="Type to Search...">
                            <button class="clear-button" [disabled]="!searchTextAnalyst1"
                                (click)="clearSearchAnalyst1()">
                                <p class="clear-button-text" *ngIf="searchTextAnalyst1">X</p>
                            </button>
                        </div>
                        <div class="col-3" style="padding-left: 4.5%">
                            <button *ngIf="!loadingStix" [disabled]="!searchTextAnalyst1"
                                class="btn btn-primary pr-4 pl-4 pt-2 pb-2 query-button" (click)="getAnalyst1Objects()">
                                <fa-icon [icon]="faSearch" class="pr-1"></fa-icon>
                                Search
                            </button>
                            <button *ngIf="loadingStix" disabled
                                class="btn btn-primary pr-4 pl-4 pt-2 pb-2 query-button">
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="analyst1Objects?.length > 0" class="row text-center pb-2">
                        <div class="col-md-12 mt-2 d-flex justify-content-between">
                            <div class="col-md-9 d-flex">
                                <h1 class="h3">Objects</h1>
                            </div>
                            <div class="d-flex justify-content-end align-items-center col-md-3 pr-0">
                                <button *ngIf="!addingStixToBundle" type="button" class="btn btn-success mr-2"
                                    (click)="addAnalyst1ToBundle()" [disabled]="!enableAddToBundle()"
                                    title="Click to add this STIX object to bundle.">
                                    <fa-icon [icon]="faAddToBundle"></fa-icon>
                                </button>
                                <button *ngIf="addingStixToBundle" type="button" disabled class="btn btn-success mr-2">
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Importing...</span>
                                </button>
                                <button *ngIf="showNextAnalyst1Page() && !loadingStixNext" type="button"
                                    class="btn btn-sm btn-outline-primary next-button" (click)="getNextAnalyst1Page()"
                                    title="More objects are available for this query. Click Next to see them.">
                                    <fa-icon [icon]="faArrowRight" class="pr-1"></fa-icon>
                                    Next
                                </button>
                                <button *ngIf="loadingStixNext" type="button" disabled
                                    class="btn btn-sm btn-outline-primary next-button">
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Loading...</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [class]="{'move-out-of-view': !showingAnalyst1}"
                class="row justify-content-center border-tops border-bottoms mb-3 analyst1-objects-wrapper">
                <!-- Original A1 data import approach: Code for reusing query stix component -->
                <!-- <app-query-stix #queryStix [analyst1View]="true" style="display: none"></app-query-stix> -->

                <div *ngIf="analyst1Objects?.length === 0"
                    class="w-100 d-flex justify-content-center align-items-center">
                    <div class="container" style="text-align: center;">
                        <p style="font-weight: bold;">No results</p>
                        <p>Type search criteria above and click Search to view results</p>
                    </div>
                </div>

                <div *ngIf="analyst1Objects?.length > 0" class="w-100">
                    <div class="d-flex justify-content-start mt-3">
                        <div class="ml-5">
                            <input type="checkbox" (change)="checkAllAnalyst1Objects($event)" />
                        </div>
                        <div style="margin-left: 10%; width: 60%">
                            <p style="font-weight: bold;">Value</p>
                        </div>
                        <div style="margin-left: 5%">
                            <p style="font-weight: bold;">Type</p>
                        </div>
                    </div>
                    <div *ngFor="let object of analyst1Objects">
                        <div class="d-flex justify-content-start mt-3">
                            <div class="ml-5">
                                <input type="checkbox" [(ngModel)]="object.checked" />
                            </div>
                            <div style="margin-left: 10%; width: 60%; overflow: auto">
                                <div>{{ object?.value?.name }}</div>
                            </div>
                            <div style="margin-left: 5%">
                                {{ object?.type }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row col-12 pb-3"
                [class]="{ 'justify-content-between': !stixService.guidedUI,
                        'justify-content-center': stixService.guidedUI }">
                <button *ngIf="!showingBundle && !stixService.guidedUI" type="button"
                    class="btn btn-collapse btn-outline-primary pr-3 pl-3 pt-1 pb-1 showlabel" style="text-align: left;"
                    (click)="showBundle()">
                    <fa-icon [icon]="faAngleDoubleDown" class="pr-3"></fa-icon>
                    Bundle
                </button>
                <button *ngIf="showingBundle && !stixService.guidedUI" type="button"
                    class="btn btn-collapse btn-outline-secondary pr-3 pl-3 pt-1 pb-1 showlabel" style="text-align: left;"
                    (click)="showBundle()">
                    <fa-icon [icon]="faAngleDoubleUp" class="pr-3"></fa-icon>
                    Bundle
                </button>

                <div *ngIf="loadedBundleName && !guidedUI" class="bundle-name d-flex justify-content-end pb-1 pr-1">
                    <div style="margin-right: -30px;">
                        Bundle Name: &nbsp;
                        <span class="name-highlight"> {{loadedBundleName}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center" 
                    [class]="{ 'justify-content-between': guidedUI, 'justify-content-end': !guidedUI }"
                    style="width: 100%">
                    <div *ngIf="guidedUI && menuSelection === 'build-review'" class="d-flex justify-content-center align-items-center">
                        <span class="mr-2">Grouping:</span>
                        <select class="form-control" id="APIRoots" name="isGroupByTLP"
                            (change)="selectGroupByTLP($event)" [ngModel]="isGroupByTLP">
                            <option default value='false'>By Type</option>
                            <option value='true'>By TLP</option>
                        </select>
                    </div>
                    <div *ngIf="(guidedUI && menuSelection === 'build-review')" class="input-group search-bar"
                        [class]="{ 'col-8': !guidedUI, 'col-3': guidedUI }">
                        <input class="form-control search-input"
                            (keydown.shift.enter)="search('shift+enter')" (keydown.enter)="search('enter')"
                            (keydown.escape)="clearSearch()" placeholder="Type to Search..."
                            [(ngModel)]="searchText" (ngModelChange)="clearSearchResults()">

                        <div>
                            <p class="search-results" *ngIf="searchText !== ''">{{searchResults}}</p>
                        </div>
                        <button class="clear-button" [disabled]="searchText === ''" (click)="clearSearch()">
                            <p class="clear-button-text" *ngIf="searchText !== ''">X</p>
                        </button>
                    </div>
                    <div *ngIf="(guidedUI && !showBundleSection) || !guidedUI"
                        class="d-flex align-items-center" style="margin-right: -30px"
                        [class]="{ 'justify-content-between': guidedUI, 'justify-content-end': !guidedUI }">
                        <div class="d-flex justify-content-center align-items-center">
                            <select class="form-control acs-dropdown ml-5" id="APIRoots" name="APIRoots"
                                (change)="selectAcsMarking($event)" [ngModel]="stixService.acsSelection" height="38px">
                                <option value=''>Select an ACS Marking</option>
                                <option *ngFor="let acs of acsMarkingOptions; let i = index" [value]="acs.type"
                                    title="{{ acs.type }}">{{ acs.type }}</option>
                            </select>
                            <button type="button" class="btn btn-success ml-2 btn-100" style="height: 30px" (click)="applyACS()"
                                [disabled]="stixService.acsSelection?.length === 0">
                                <fa-icon [icon]="faPlusSquare" class="pr-1"></fa-icon>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="showingBundle">
                <div *ngIf="bundle.objects.length < 1" class="text-center">
                    Begin creating your STIX objects by selecting a component above or upload an existing bundle below!
                </div>
                <ng-container *ngIf="bundle.objects.length > 0">
                    <div *ngIf="!showBundleObjectsSectionOnly || showBundleSection" class="col border-tops">
                        <div class="row align-items-center text-center pt-3 pb-3">
                            <ng-template [ngIf]="this.stixService.savedBundles.length == 0"
                                [ngIfElse]="elseSavedBundles">
                                <div class="col-6 input-group search-bar">
                                    <input class="form-control search-input"
                                        (keydown.shift.enter)="search('shift+enter')" (keydown.enter)="search('enter')"
                                        (keydown.escape)="clearSearch()" placeholder="Type to Search..."
                                        [(ngModel)]="searchText" (ngModelChange)="clearSearchResults()">

                                    <div>
                                        <p class="search-results" *ngIf="searchText !== ''">{{searchResults}}</p>
                                    </div>
                                    <button class="clear-button" [disabled]="searchText === ''" (click)="clearSearch()">
                                        <p class="clear-button-text" *ngIf="searchText !== ''">X</p>
                                    </button>
                                </div>
                                <div class="col-3" style="padding-left: 2%">
                                    <button [disabled]="selectedObjects.length != 2"
                                        class="btn btn-success pr-4 pl-4 pt-2 pb-2 query-button"
                                        (click)="createRelationship()" style="width: 220px !important;">
                                        <fa-icon [icon]="faLink" class="pr-1"></fa-icon>
                                        Create Relationship
                                    </button>
                                </div>
                                <div *ngIf="!guidedUI" class="d-flex justify-content-center align-items-center col-3 pr-0">
                                    <span class="mr-2">Grouping:</span>
                                    <select class="form-control" id="APIRoots" name="isGroupByTLP"
                                        (change)="selectGroupByTLP($event)" [ngModel]="isGroupByTLP">
                                        <option default value='false'>By Type</option>
                                        <option value='true'>By TLP</option>
                                    </select>
                                </div>
                                <div *ngIf="guidedUI" class="col-3" style="padding-left: 2%">
                                    <button [disabled]="selectedObjects.length != 1"
                                        class="btn btn-success pr-4 pl-4 pt-2 pb-2 query-button"
                                        (click)="createSighting()">
                                        <fa-icon [icon]="faEye" class="pr-1"></fa-icon>
                                        Create Sighting
                                    </button>
                                </div>
                            </ng-template>
                            <ng-template #elseSavedBundles>
                                <div class="col-6 input-group search-bar">
                                    <input class="form-control search-input"
                                        (keydown.shift.enter)="search('shift+enter')" (keydown.enter)="search('enter')"
                                        (keydown.escape)="clearSearch()" placeholder="Type to Search..."
                                        [(ngModel)]="searchText" (ngModelChange)="clearSearchResults()">

                                    <div>
                                        <p class="search-results" *ngIf="searchText !== ''">{{searchResults}}</p>
                                    </div>
                                    <button class="clear-button" [disabled]="searchText === ''" (click)="clearSearch()">
                                        <p class="clear-button-text" *ngIf="searchText !== ''">X</p>
                                    </button>
                                </div>
                                <div class="col-3" style="padding-left: 2%">
                                    <button [disabled]="selectedObjects.length != 2"
                                        class="btn btn-success pr-4 pl-4 pt-2 pb-2 query-button"
                                        (click)="createRelationship()" style="width: 220px !important">
                                        <fa-icon [icon]="faLink" class="pr-1"></fa-icon>
                                        Create Relationship
                                    </button>
                                </div>
                                <div *ngIf="!guidedUI" class="d-flex justify-content-center align-items-center col-3 pr-0">
                                    <span class="mr-2">Grouping:</span>
                                    <select class="form-control" name="isGroupByTLP"
                                        (change)="selectGroupByTLP($event)" [ngModel]="isGroupByTLP">
                                        <option default value='false'>By Type</option>
                                        <option value='true'>By TLP</option>
                                    </select>
                                </div>
                                <div *ngIf="guidedUI" class="col-3">
                                    <button [disabled]="selectedObjects.length != 1"
                                        class="btn btn-100 btn-success pr-4 pl-4 pt-2 pb-2 query-button"
                                        (click)="createSighting()">
                                        <fa-icon [icon]="faEye" class="pr-1"></fa-icon>
                                        Create Sighting
                                    </button>
                                </div>
                                
                                <!-- <div class="d-flex justify-content-center align-items-center" style="margin-left: 25%">
                                    Group by TLP:
                                    <input
                                        type="checkbox"
                                        class="input-cb ml-2 mt-1"
                                        (change)="updateGroupByTLP()"
                                        [checked]="isGroupByTLP"
                                        [(ngModel)]="isGroupByTLP"/>
                                </div> -->
                                <div *ngIf="false" style="padding-left: 2%">
                                    <button class="btn btn-secondary pr-4 pl-4 pt-2 pb-2 query-button"
                                        (click)="createRelationship()">
                                        Load Objects
                                    </button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center text-dark stix-preview-message">
                            <div class="border-tops border-bottoms"
                                [class]="{ 'bundle-wrapper': !guidedUI, 'bundle-wrapper-guided': guidedUI }">

                                <ng-container *ngIf="isGroupByTLP">
                                    <div class="accordion" id="accordion">
                                        <div *ngFor="let tlp of tlpOptions" class="card">
                                          <div *ngIf="tlp.key === 'UNASSIGNED'" class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                              <button class="btn btn-link btn-block text-left" data-toggle="collapse" [attr.data-target]="'#collapse' + tlp.short" aria-expanded="true" [attr.aria-controls]="'collapse' + tlp.short">
                                                <span class="ml-2" style="display: inline-block; width: 156px; padding-right: 20px"><strong>Unassigned:</strong></span>
                                                <span class="badge rounded-pill bg-primary sdo-badge" style="min-width: 1rem">{{unassignedObjMarkingRefObjs?.length}}</span>
                                              </button>
                                            </h5>
                                          </div>

                                          <div *ngIf="tlp.key !== 'UNASSIGNED'" class="card-header" id="headingTwo">
                                            <h5 class="mb-0">
                                              <button class="btn btn-link btn-block text-left" data-toggle="collapse" [attr.data-target]="'#collapse' + tlp.short" aria-expanded="true" [attr.aria-controls]="'collapse' + tlp.short">
                                                <div class="badge" [class]="stixService.getTlpStyle(tlpShown(tlp)?.tlp?.key)">{{tlpShown(tlp).tlp.key}}</div>
                                                <span class="badge rounded-pill bg-primary sdo-badge"
                                                    [class]="{'tlp-margin-left': tlpMargin(tlp) === 'other',
                                                              'tlp-margin-left-amber': tlpMargin(tlp) === 'amber',
                                                              'tlp-margin-left-strict': tlpMargin(tlp) === 'strict'}"
                                                    style="min-width: 1rem">{{tlpCount(tlp)}}</span>
                                              </button>
                                            </h5>
                                          </div>
                                      
                                          <div id="{{tlpAccordianDataTargetId(tlp)}}" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                                <div *ngFor="let stixObject of stixObjects; let i = index;">
                                                    <div *ngFor="let object of stixObject.objects; let j = index;">
                                                        <div *ngIf="(bundleObjectType(tlp) | bundlefilter: object.type).length > 0">
                                                            <div class="row text-left">
                                                                <div class="col" id="{{getStixObjectID(object.type)}}">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <th colspan="2">
                                                                                {{object.displayName}}
                                                                                <span class="badge rounded-pill bg-primary sdo-badge">
                                                                                    {{ (bundleObjectType(tlp) | bundlefilter: object.type
                                                                                    ).length
                                                                                    }}
                                                                                </span>
                                                                            </th>
                                                                        </thead>
                                                                        <tbody>
                                                                            <ng-container
                                                                                *ngFor="let component of bundleObjectType(tlp) | bundlefilter: object.type; let k = index; let c = count;">
                                                                                <tr>
                                                                                    <td *ngIf="!showBundleObjectsSectionOnly"
                                                                                        class="active"
                                                                                        style="font-weight: normal; width: 2%;">
                                                                                        <input type="checkbox"
                                                                                            class="select-item checkbox"
                                                                                            (change)="updateSelectedObjects(component.id)">
                                                                                    </td>
                                                                                    <td *ngIf="guidedUI && showBundleObjectsSectionOnly"
                                                                                        style="font-weight: normal; width: 0.1%;"></td>
                                                                                    <td class="btn-link overflow"
                                                                                        style="padding-top:14px; width: 98%"
                                                                                        [ngClass]="{highlight: highlightSearch(component)}"
                                                                                        id="{{getComponentId(component, i, j, k)}}"
                                                                                        (click)="changeShow(j, k)">
                                                                                        <div class="pull-left float-left overflow">
                                                                                            <span [innerHTML]="getComponentDisplay(component)"></span>
                                                                                            <span *ngFor = "let objMark of getTlp(component)">
                                                                                                <span *ngIf = "objMark.type === 'tlpv1'" class = "mr-2"><span class = "badge {{getTlpStyle(objMark)}}">{{objMark.name}}</span> <span class = "tlpv1">v1.0</span></span> 
                                                                                                <span *ngIf = "objMark.type !== 'tlpv1'" class = "mr-2 badge {{getTlpStyle(objMark)}}">{{objMark.name}}</span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr class="expanded-panel"
                                                                                    *ngIf="objnumber == j && rownumber == k">
                                                                                    <td colspan="3">
                                                                                        <div *ngIf="!guidedUI || (guidedUI && showBundleObjectsSectionOnly)" class="row">
                                                                                            <div class="col-md-2">
                                                                                                <button type="button"
                                                                                                    [disabled]="disableEditObject(component)"
                                                                                                    class="btn btn-fa btn-primary mr-2"
                                                                                                    (click)="editObject(component, false);">
                                                                                                    <fa-icon [icon]="faEdit"></fa-icon>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    class="btn btn-fa btn-warning"
                                                                                                    (click)="removeObject(component.id);">
                                                                                                    <fa-icon [icon]="faTrash"></fa-icon>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row ml-1" style='overflow-wrap: break-word;'>
                                                                                            <pre>{{ getJsonDisplayForComponent(component) | json }}</pre>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!isGroupByTLP">
                                    <div *ngFor="let stixObject of stixObjects; let i = index;">
                                        <div *ngFor="let object of stixObject.objects; let j = index;">
                                            <div *ngIf="(bundle.objects | bundlefilter: object.type).length > 0">
                                                <div class="row text-left">
                                                    <div class="col" id="{{getStixObjectID(object.type)}}">
                                                        <table class="table">
                                                            <thead>
                                                                <th colspan="2">
                                                                    {{object.displayName}}
                                                                    <span class="badge rounded-pill bg-primary sdo-badge">
                                                                        {{ (bundle.objects | bundlefilter: object.type
                                                                        ).length
                                                                        }}
                                                                    </span>
                                                                </th>
                                                            </thead>
                                                            <tbody style="overflow-x: hidden">
                                                                <ng-container
                                                                    *ngFor="let component of bundle.objects | bundlefilter: object.type | paginate: { id: object.type + 'page', itemsPerPage: 10, currentPage: page.get(object.type)};  let k = index; let c = count; ">
                                                                    <tr>
                                                                        <td *ngIf="!showBundleObjectsSectionOnly"
                                                                            class="active"
                                                                            style="font-weight: normal; width: 2%;">
                                                                            <input type="checkbox"
                                                                                class="select-item checkbox"
                                                                                (change)="updateSelectedObjects(component.id)">
                                                                        </td>
                                                                        <td *ngIf="guidedUI && showBundleObjectsSectionOnly"
                                                                            style="font-weight: normal; width: 0.1%;"></td>
                                                                        <td class="btn-link overflow pointer"
                                                                            style="padding-top:14px; width: 98%"
                                                                            [ngClass]="{highlight: highlightSearch(component)}"
                                                                            id="{{getComponentId(component, i, j, k)}}"
                                                                            data-toggle="collapse" [attr.data-target]="'#'+component.id">
                                                                            <div class="pull-left float-left overflow">
                                                                                <span [innerHTML]="getComponentDisplay(component)"></span>
                                                                                <span *ngFor = "let objMark of getTlp(component)">
                                                                                    <span *ngIf = "objMark.type === 'tlpv1'" class = "mr-2"><span class = "badge {{getTlpStyle(objMark)}}">{{objMark.name}}</span> <span class = "tlpv1">v1.0</span></span> 
                                                                                    <span *ngIf = "objMark.type !== 'tlpv1'" class = "mr-2 badge {{getTlpStyle(objMark)}}">{{objMark.name}}</span>
                                                                                </span>
                                                                            </div>
                                                                            
                                                                            <img *ngIf="isDuplicated(component.id)" (click)="viewDuplicates($event, component)" src="./assets/merge-cells.svg" alt="" width="25" height="25" class="ml-2" title="Duplicated Objects">
                                                                            
                                                                            <img *ngIf="isReferenced(component.id)" (click)="viewReferences($event,component)" src="./assets/reference.svg" alt="" width="25" height="25" class="ml-2" title="Referenced Objects">
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="expanded-panel collapse" [id]="component.id"
                                                                        >
                                                                        <td colspan="3">
                                                                            <div *ngIf="!guidedUI || (guidedUI && showBundleObjectsSectionOnly)" class="row">
                                                                                <div class="col-md-2">
                                                                                    <button type="button"
                                                                                        [disabled]="disableEditObject(component)"
                                                                                        class="btn btn-fa btn-primary mr-2"
                                                                                        (click)="editObject(component, false);">
                                                                                        <fa-icon [icon]="faEdit"></fa-icon>
                                                                                    </button>
                                                                                    <button type="button"
                                                                                        class="btn btn-fa btn-warning"
                                                                                        (click)="removeObject(component.id);">
                                                                                        <fa-icon [icon]="faTrash"></fa-icon>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row ml-1" style='overflow-wrap: break-word;'>
                                                                                <pre>{{ getJsonDisplayForComponent(component) | json }}</pre>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                        <pagination-controls class="bundlePagination" (pageChange)="page.set(object.type, $event);" id="{{object.type}}page" [autoHide]="true"></pagination-controls>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div *ngIf="this.stixService.customObjects.length > 0">
                                    <div class="row text-left">
                                        <div class="col">
                                            <table class="table">
                                                <thead>
                                                    <th colspan="2">
                                                        Custom Objects
                                                        <span class="badge rounded-pill bg-primary sdo-badge">
                                                            {{ this.stixService.customObjects.length }}
                                                        </span>
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let component of this.stixService.customObjects | paginate: { id: 'customObjectsPage', itemsPerPage: 10, currentPage: customPage};let k = index; let c = count;">
                                                        <tr>
                                                            <td *ngIf="!showBundleObjectsSectionOnly" class="active" style="font-weight: normal; width: 2%;">
                                                                <input type="checkbox" class="select-item checkbox"
                                                                    (change)="updateSelectedObjects(component.id)">
                                                            </td>
                                                            <td class="btn-link overflow"
                                                                style="padding-top:14px; width: 98%"
                                                                [ngClass]="{highlight: highlightSearch(component)}"
                                                                id="{{getComponentId(component, -2, -2, k)}}"
                                                                (click)="changeShow(-2, k)">
                                                                <div class="pull-left float-left overflow pointer pl-4">
                                                                    {{getCustomObjectHeading(component)}}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr class="expanded-panel"
                                                            *ngIf="objnumber == -2 && rownumber == k">
                                                            <td colspan="2">
                                                                <div class="row">
                                                                    <div class="col-md-2">
                                                                        <button type="button"
                                                                            class="btn btn-fa btn-primary mr-2"
                                                                            (click)="editObject(component, true);">
                                                                            <fa-icon [icon]="faEdit"></fa-icon>
                                                                        </button>
                                                                        <button type="button"
                                                                            class="btn btn-fa btn-warning"
                                                                            (click)="removeObject(component.id);">
                                                                            <fa-icon [icon]="faTrash"></fa-icon>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="row ml-1" style='overflow-wrap: break-word;'>
                                                                    <pre>{{ getJsonDisplayForComponent(component) | json }}</pre>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                            <pagination-controls class="bundlePagination" (pageChange)="customPage = $event" id="customObjectsPage" [autoHide]="true"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div *ngIf="!guidedUI && bundle.objects.length > 0" class="row justify-content-between pt-3 pb-2 pl-0 pr-0 col-12 ml-0">
                <div class="d-flex justify-content-sm-center justify-content-md-center">
                    <button type="button" class="btn pr-5 pl-5 pt-2 pb-2 query-button save-button"
                        (click)="saveBundleModal(saveModal, true)" [disabled]="!(bundle.objects.length > 0) || this.stixService.isLoadingBundles">
                        <fa-icon *ngIf="!this.stixService.isLoadingBundles" [icon]="faSave" class="pr-1"></fa-icon>
                        <span *ngIf="!this.stixService.isLoadingBundles">Save</span>
                        <span *ngIf="this.stixService.isLoadingBundles" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    </button>
                </div>
                <div class="d-flex justify-content-sm-center justify-content-md-start">
                    <button type="button" class="btn btn-primary pr-4 pl-4 pt-2 pb-2 query-button"
                        (click)="downloadBundle();">
                        <fa-icon [icon]="faDownload" class="pr-1"></fa-icon>
                        Download
                    </button>
                </div>
                <div class="d-flex justify-content-sm-center justify-content-md-center">
                    <button type="button" class="btn btn-secondary pr-5 pl-5 pt-2 pb-2 query-button"
                        (click)="resetBundle(saveModal)" [disabled]="!(bundle.objects.length > 0)">
                        <fa-icon [icon]="faUndo" class="pr-1"></fa-icon>
                        Reset
                    </button>
                </div>
                <div class="d-flex justify-content-sm-center justify-content-md-end">
                    <button type="button" class="btn btn-success pr-5 pl-5 pt-2 pb-2 query-button"
                        (click)="openPublishModal(publishModal);">
                        <fa-icon [icon]="faUpload" class="pr-1"></fa-icon>
                        Publish
                    </button>
                </div>
            </div>
            <br>
            <ng-container *ngIf="!guidedUI">
            <h6>Select File(s) to Upload Below</h6>
            <div class="row">
                <div class="col-12">
                    <!-- style="overflow:hidden; max-width: 300px;" -->
                    <input type="file" [(ngModel)]="selectedFile" class="bottom-bump w-100" accept=".json" id="file1"
                         name="upload" (change)="onFileChanged($event)" multiple>
                </div>
                
                <!-- *ngIf="this.stixService.savedBundles.length != 0" -->
                <!-- <div class="col-2">
                    <div *ngIf="this.stixService.isLoadingBundles === true" class="col-2" style="padding-left: 4.5%">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    </div>
                    <div *ngIf="this.stixService.savedBundles.length != 0 && this.stixService.isLoadingBundles !== true" class="col-2" style="padding-left: 8%">OR</div>
                    <button *ngIf="this.stixService.savedBundles.length != 0 && this.stixService.isLoadingBundles !== true" class="btn btn-secondary query-button" (click)="loadBundle(loadModal)">
                        <fa-icon [icon]="faFileDownload" class="pr-1"></fa-icon>
                        Load Bundle
                    </button>
                </div> -->
            </div>
            <div class="row">
                <div class="col-4 d-flex" >
                    <button *ngIf="!isUploading" type="button" class="btn query-button btn-outline-primary"
                        (click)="uploadBundle();" [disabled]="this.selectedFile == undefined">
                        <fa-icon [icon]="faFileUpload" class="pr-1"></fa-icon>
                        Upload
                    </button>
                    <button *ngIf="isUploading" type="button" class="btn query-button btn-outline-primary"
                        [disabled]="true">
                        <span class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>Uploading...
                    </button>
                    <button class="btn btn-primary query-button ml-2" (click)="copyPaste(saveModal)">
                        <fa-icon [icon]="faPaste" class="pr-1"></fa-icon>
                        Raw Data Import
                    </button>
                    <!-- <div *ngIf="this.stixService.isLoadingBundles === true" class="ml-5 mt-1" >
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    </div> -->
                    <!-- <div *ngIf="this.stixService.savedBundles.length != 0 && this.stixService.isLoadingBundles !== true" class="col-2" style="padding-left: 8%">OR</div> -->
                    <!-- <button *ngIf="this.stixService.savedBundles.length != 0 && this.stixService.isLoadingBundles !== true" class="btn btn-secondary query-button ml-2" (click)="loadBundle(loadModal)">
                        <fa-icon [icon]="faFileDownload" class="pr-1"></fa-icon>
                        Load Bundle
                    </button> -->
                </div>
            </div>
            </ng-container>
            <div *ngIf="!guidedUI" class="big-bottom-bump"></div>
        </div>
    </div>

    <ng-template #publishModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="publish-modal-title">
                <span *ngIf="modalCurrentStep !== 4">Publish Bundle</span>
                <span *ngIf="modalCurrentStep === 4">Bundle Submitted!</span>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div id="modal-step-0" *ngIf="modalCurrentStep === 0">
                <h5>STIX Required Window</h5>
                <p>Prior to submission, your Taxii Server selection requires the following fields:</p>
                <div *ngFor="let field of required">
                    <div *ngIf="field.showInPopup">
                        <h6>{{field.label}}:</h6>
                        <div [ngSwitch]="field.label">
                            <div *ngSwitchCase="'ACS Markings'">
                                <div class="d-flex justify-content-left align-items-center">
                                    <select class="form-control acs-dropdown" id="APIRoots" name="APIRoots"
                                        (change)="selectAcsMarking($event)" [ngModel]="stixService.acsSelection">
                                        <option value=''>Select an ACS Marking</option>
                                        <option *ngFor="let acs of acsMarkingOptions; let i = index" [value]="acs.type"
                                            title="{{ acs.type }}">{{ acs.type }}</option>
                                    </select>
                                    <button type="button" class="btn btn-success ml-2 btn-100" style="height: 30px" (click)="applyACS()"
                                        [disabled]="stixService.acsSelection?.length === 0">
                                        <fa-icon [icon]="faPlusSquare" class="pr-1"></fa-icon>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-step-1" *ngIf="modalCurrentStep === 1">
                <h5>STIX Preview Window</h5>
                <p>Prior to submission, check the STIX preview window. Does this look good? If so, click "Next" to
                    configure
                    the
                    TAXII server connection information. If not, click "Continue Editing" to return to the Publish STIX
                    page.
                </p>
                <div>
                    <ul class="nav nav-tabs">
                        <li class="nav-item" (click)="activeTab = 'jsonViewer'">
                            <a class="nav-link active" [ngClass]="{'active': activeTab === 'jsonViewer'}">JSON</a>
                        </li>
                    </ul>

                    <pre *ngIf="activeTab === 'jsonViewer'" class="preview-bundle">{{ getStixPreview() | json }}</pre>
                    <app-stix-viewer *ngIf="activeTab === 'visualViewer'" class="nav-link" [bundle]="getStixPreview()">
                    </app-stix-viewer>
                </div>
            </div>
            <div id="modal-step-2" *ngIf="modalCurrentStep === 2">
                <h5>TAXII Repository Information</h5>
                <p>Select the API Root and Collection you would like to publish to.</p>
                <div *ngIf="taxiiServerType === 'default' || taxiiServerType === 'ais'">
                    <div class="input-group mb-3">
                        <span class="input-group-text input-sm" id="taxii-api-root">API Roots</span>
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn btn-outline-primary" style="height:31px;" id="sortMenu" ngbDropdownToggle
                                [disabled]="stixService.isLoadingRoots || stixService?.apiRoots2?.length === 0">
                                <span *ngIf="taxiiServer?.apiRoot">
                                    {{ taxiiServer?.apiRoot }}
                                </span>
                            </button>
                            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="sortMenu">
                                <button class="dropdown-item" *ngFor="let apiRoot of this.apiRootsPublishTo()"
                                    (click)="selectApiRoot(apiRoot)" title="{{ apiRoot?.description }}">
                                    {{ apiRoot.title }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text input-sm" id="taxii-collection-id">Collections</span>
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn btn-outline-primary" style="height:31px;" id="sortMenu" ngbDropdownToggle
                                [disabled]="!taxiiServer.apiRoot">
                                <span *ngIf="selectedCollection">
                                    {{ selectedCollection?.title }}
                                </span>
                            </button>
                            <div *ngIf="this.selectedAPIRoot && collectionsCanWriteTo()" ngbDropdownMenu class="dropdown-menu collections-dropdown"
                                aria-labelledby="sortMenu">
                                <ng-container *ngFor="let collection of taxiiServer.availableCollections">
                                    <button *ngIf="collection.can_write" class="dropdown-item"
                                        (click)="onCollectionChanged(collection)"
                                        title="{{ collection?.description }}">
                                        {{ collection.title }}
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="taxiiServerType === 'custom'">
                    <div class="input-group mb-3">
                        <span class="input-group-text input-sm" id="taxii-api-root">API Roots</span>
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn btn-outline-primary" style="height:31px;" id="sortMenu" ngbDropdownToggle
                                [disabled]="stixService.isLoadingRoots || stixService?.apiRoots2?.length === 0">
                                <span *ngIf="selectedAPIRoot">
                                    {{ selectedAPIRoot?.title }}
                                </span>
                            </button>
                            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="sortMenu">
                                <button class="dropdown-item" *ngFor="let apiRoot of apiRootsPublishTo()"
                                    (click)="onAPIRootChanged(apiRoot)" title="{{ apiRoot?.description }}">
                                    {{ apiRoot['title'] }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-1">
                        <span class="input-group-text input-sm" id="taxii-collection-id">Collections</span>
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn btn-outline-primary" style="height:31px;" id="sortMenu" ngbDropdownToggle
                                [disabled]="!selectedAPIRoot">
                                <span *ngIf="selectedCollection">
                                    {{ selectedCollection?.title }}
                                </span>
                            </button>
                            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="sortMenu">
                                <ng-container *ngFor="let collection of taxiiServer.availableCollections">
                                    <button *ngIf="collection.can_write" class="dropdown-item"
                                        (click)="onCollectionChanged(collection)" data-toggle="tooltip"
                                        data-placement="top" title="{{collection?.description}}">
                                        {{ collection?.title }}
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="modal-step-3" *ngIf="modalCurrentStep === 3">
                <h5>All Ready?</h5>
                <p>Everything's ready to go. Click "Publish" to post your STIX document to the aforementioned TAXII
                    server.
                    Having second thoughts, click "Continue Editing" to return to the Publish STIX page.</p>
                <div>
                    <div>
                        TAXII Server URL: {{ constructTaxiiServerURL() }}
                    </div>
                    <div *ngIf="taxiiServerType === 'custom'">
                        TAXII Server Username: {{ taxiiServer.username }}
                    </div>
                </div>
            </div>
            <div id="modal-step-4" *ngIf="modalCurrentStep === 4">
                <!-- <h5>Your bundle was received by the TAXII Server!</h5> -->
                <ol>
                    <li class="mt-3">
                        <b>Download</b> your STIX bundle to reference your object IDs for the future by clicking the
                        “download” button below.
                    </li>
                    <li class="mt-2">
                        Once the user closes this pop-up window, the <b>notification in the top right indicates the
                            status
                            of the submission</b>:
                        <ul>
                            <li>
                                <span class="text-success">Green</span> is a successful submission
                            </li>
                            <li>
                                <span class="text-warning">Yellow</span> and <span class="text-danger">red</span>
                                indicate errors in submission. Expand the message, download the output
                                and bundle, and email to <a
                                    href="mailto:dfta-group@gwe.cisa.dhs.gov">dfta-group@gwe.cisa.dhs.gov</a>.
                            </li>
                        </ul>
                    </li>
                    <li class="mt-2">
                        To edit or publish new bundles, ensure the bundle editor is clear by <b>clicking “reset”.</b>
                    </li>
                    <li class="mt-3">
                        <ng-container>
                            <div class="row pl-3 pb-1">
                                If you would like to save or download your bundle, please select one of the buttons in the lower right of the modal.
                            </div>
                        </ng-container>
                    </li>
                </ol>
            </div>
        </div>

        <div class="row" style="margin-top: 20px; overflow: auto;">
            <div class="container-fluid">
                <div *ngIf="this.stixService.apiRootProcessingError != ''" class="pt-1 pl-3">
                    <p style="font-weight: bold;">Failed to connect to {{this.stixService.taxiiServer.url ==
                        aisUrl ? this.stixService.prod ? "https://ais2.cisa.dhs.gov/taxii2/" :
                        "https://ais2-pfte.taxii.us-cert.gov/" : this.stixService.taxiiServer.url}}</p>
                    <p style="text-align: left;">{{this.stixService.apiRootProcessingError}}
                        <ng-container *ngIf="this.stixService.apiRootProcessingErrorURL != ''"><br>URL: 
                            {{this.stixService.apiRootProcessingErrorURL}}</ng-container></p>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="decreaseCurrentStep()"
                *ngIf="(modalCurrentStep == 1 && required.length > 0 ) || modalCurrentStep > 1 && modalCurrentStep !== 4" [disabled]="isPublishing">
                <fa-icon [icon]="faArrowLeft" class="pr-1"></fa-icon>
                Previous
            </button>
            <button type="button" class="btn btn-sm btn-secondary" *ngIf="modalCurrentStep === 1" data-toggle="tooltip"
                data-placement="top"
                title="Open this visualization in a new tab so that you can use your browser's full width to view your bundle"
                (click)="routeToNewTab('stix-viewer')" [disabled]="isPublishing">
                <fa-icon [icon]="faProjectDiagram" class="pr-1"></fa-icon>
                Visualize Bundle
            </button>
            <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(false)"
                [disabled]="isPublishing" *ngIf="modalCurrentStep !== 4">
                <fa-icon [icon]="faEdit" class="pr-1"></fa-icon>
                Continue Editing
            </button>
            <button type="button" class="btn btn-sm btn-success" (click)="this.publishA1 = true; increaseCurrentStep()"
                *ngIf="this.taxiiServerType === 'default' && modalCurrentStep == 2 && environment.customConfiguration.analyst1.enabled" [disabled]="isPublishing">
                <fa-icon [icon]="faUpload" class="pr-1"></fa-icon>
                Publish to A1
            </button>
            <button type="button" class="btn btn-sm btn-primary" (click)="increaseCurrentStep()"
                *ngIf="modalCurrentStep < modalStepCount" [disabled]="isNextDisabled()">
                <fa-icon [icon]="faArrowRight" class="pr-1"></fa-icon>
                Next
            </button>
            <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(false)"
                *ngIf="modalCurrentStep === 4">
                <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
                Cancel
            </button>
            <button type="button" class="btn btn-wsm btn-success save-button" (click)="saveBundleModal(saveModal, false);"
                *ngIf="modalCurrentStep === 4">
                <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
                Submitted Bundle
            </button>
            <button type="button" class="btn btn-wsm btn-success" (click)="downloadBundle();"
                *ngIf="modalCurrentStep === 4">
                <fa-icon [icon]="faDownload" class="pr-1"></fa-icon>
                Submitted Bundle
            </button>
            <button type="button" class="btn btn-sm btn-success" (click)="publishBundle(true)"
                *ngIf="modalCurrentStep === modalStepCount" [disabled]="isPublishing">
                <fa-icon [icon]="faUpload" class="pr-1"></fa-icon>
                <span *ngIf="!isPublishing">Publish</span>
                <span *ngIf="isPublishing">
                    <div class="spinner-grow text-light query-loading-icon" role="status"></div>
                </span>
            </button>
        </div>
    </ng-template>


    <!--------------------------------------------------------------------------------------------------------------------------------------------------------->

    <ng-template #CISAIdentityModal let-modal>
        <div class="modal-header">
            <h2 class="modal-title" id="modal-title">Warning</h2>
        </div>
        <div class="modal-body">
            <div>You have removed the default CISA identity from the current bundle. The identity will still be
                available within forms and can be added back to the bundle through the "Available Identities" section of
                the Identity form. The identity will also be automatically added on bundle reset or if the bundle is
                empty when loading the page.</div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-success" aria-label="Confirm"
                (click)="modal.dismiss('Cross click')">
                <fa-icon [icon]="faCheck" class="pr-1"></fa-icon>
                Confirm
            </button>
        </div>
    </ng-template>

    <ng-template #saveModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="save-modal-title">
                <span *ngIf="importingObjects && !savingBundle">Import STIX Objects</span>
                <span *ngIf="!importingObjects && !isReset && !loadingBundle">Save STIX Bundle</span>
                <span *ngIf="loadingBundle">Load STIX Bundle</span>
                <span *ngIf="!importingObjects && !savingBundle && isReset">Reset STIX Objects</span>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body pb-0">
            <div *ngIf="!savingBundle && !isReset && !importingObjects && !loadingBundle" class="pt-2">
                <app-saved-bundles-table class="w-100" [mode]="'saving'" (bundleStatus)="updateSaveModal($event)"></app-saved-bundles-table>
            </div>
            <div *ngIf="(savingBundle || isReset || importingObjects) && !loadingBundle" class="pt-2">
                <ng-container *ngIf="importingObjects && !savingBundle">
                    Preview objects ready to load displayed below. Choose how you would like to handle the existing
                    objects:<br>
                    (1) Discard the bundle entirely, (2) Merge the objects you are importing into the current
                    bundle, or (3) Save the current bundle for later use.
                    <br>
                </ng-container>
                <ng-container *ngIf="savingBundle">
                    <div class="row pl-3 pb-1">
                        If you would like to save your bundle, please enter a name below. If you would instead like to
                        discard/reset the bundle please confirm so.
                    </div>
                    <div *ngIf="!savingModalData.isNew && !savingModalData.isChangingName" class="row pl-3 pb-2 text-danger">
                       ** A bundle with a same name has been found. This will overwrite the saved bundle **
                    </div>
                    <div class="row ml-0 input-border">
                        <input class="form-control"
                            style="font-size: x-large; font-weight: 400;" type="text" [disabled]="true" [(ngModel)]="savingModalData.name">
                    </div>
                </ng-container>
                <ng-container *ngIf="!importingObjects && !savingBundle">
                    Your current bundle is displayed below. Confirm you would like to discard the bundle to reset.<br>
                </ng-container>
                <br>
                <ul class="nav nav-tabs" *ngIf="!savingBundle">
                    <li class="nav-item" (click)="activeTab = 'jsonViewer'">
                        <a class="nav-link active" [ngClass]="{'active': activeTab === 'jsonViewer'}">JSON</a>
                    </li>
                    <li *ngIf="!guidedUI" class="nav-item" (click)="activeTab = 'visualViewer'">
                        <a class="nav-link" [ngClass]="{'active': activeTab === 'visualViewer'}">Visual</a>
                    </li>
                </ul>
                <pre *ngIf="activeTab === 'jsonViewer' && !savingBundle" class="preview-bundle">{{ previewObjectsToLoad() | json }}</pre>
                <app-stix-viewer *ngIf="activeTab === 'visualViewer' && !savingBundle" class="nav-link" [preview]="true">
                </app-stix-viewer>
            </div>
            <div *ngIf="loadingBundle" class="pt-2">
                Selected Bundle:
                <select class="col-4 form-control" style="font-size: large; font-weight: 500;" type="text"
                    [(ngModel)]="selectedBundle" [disabled]="true">
                    <option *ngFor="let bundle of this.stixService.savedBundles" [value]="bundle.name">{{bundle.name}}
                    </option>
                </select>
                <br>
                <!-- <ul class="nav nav-tabs">
                    <li class="nav-item" (click)="activeTab = 'jsonViewer'">
                        <a class="nav-link active" [ngClass]="{'active': activeTab === 'jsonViewer'}">JSON</a>
                    </li>
                    <li class="nav-item" (click)="activeTab = 'visualViewer'">
                        <a class="nav-link" [ngClass]="{'active': activeTab === 'visualViewer'}">Visual</a>
                    </li>
                </ul>
                <pre *ngIf="activeTab === 'jsonViewer'" class="preview-bundle ">{{ getStixPreview() | json }}</pre>
                <app-stix-viewer *ngIf="activeTab === 'visualViewer'" class="nav-link" [bundle]="getStixPreview()">
                </app-stix-viewer> -->
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(false);">
                <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
                Cancel
            </button>
            <button *ngIf="savingBundle || loadingBundle" type="button" class="btn btn-sm btn-secondary" (click)="savingBundle = false; loadingBundle = false">
                <fa-icon [icon]="faArrowLeft" class="pr-1"></fa-icon>
                Previous
            </button>
            <button *ngIf="loadingBundle" type="button" class="btn btn-sm btn-success" (click)="sendBundle();"
                [disabled]="this.selectedBundle == ''">
                <fa-icon [icon]="faFileDownload" class="pr-1"></fa-icon>
                Load Bundle
            </button>
            <!-- <button *ngIf="!guidedUI" type="button" class="btn btn-sm btn-secondary" data-toggle="tooltip" data-placement="top"
                title="Open this visualization in a new tab so that you can use your browser's full width to view your bundle"
                (click)="routeToNewTab('stix-viewer')">
                Visualize Bundle
            </button> -->
            <ng-container *ngIf="importingObjects && !savingBundle && !loadingBundle">
                <button *ngIf="!isDiscarding" type="button" class="btn btn-sm btn-warning" (click)="discardBundle();">
                    <fa-icon [icon]="faBackspace" class="pr-1"></fa-icon>
                    Discard Bundle
                </button>
                <button *ngIf="isDiscarding" type="button" class="btn btn-sm btn-warning" [disabled]="true">
                    Discarding...
                </button>
                <button *ngIf="!isMerging" type="button" class="btn btn-sm btn-primary"
                    (click)="mergeBundle(this.stixService.objectsToLoad);" style="width: 175px !important">
                    <fa-icon [icon]="faMerge" class="pr-1"></fa-icon>
                    Merge into Bundle
                </button>
                <button *ngIf="isMerging" type="button" class="btn btn-sm btn-primary"
                    [disabled]="true">
                    Merging...
                </button>
            </ng-container>
            <ng-container *ngIf="savingBundle">
                <button *ngIf="savingModalData.destination == 'local' && savingModalData.isNew == false" type="button" class="btn btn-sm btn-success"
                (click)="overwriteBundle('local')">
                    <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
                    Locally
                </button>
                <button *ngIf="savingModalData.destination == 'local' && savingModalData.isNew == true" type="button" class="btn btn-sm btn-success"
                (click)="saveBundle('local')">
                    <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
                    Locally
                </button>
                <button *ngIf="savingModalData.destination == 'profile' && savingModalData.isNew == true" type="button" class="btn btn-sm btn-success"
                (click)="saveBundle('profile')">
                    <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
                    Profile
                </button>
                <button *ngIf="savingModalData.destination == 'profile' && savingModalData.isNew == false" type="button" class="btn btn-sm btn-success"
                (click)="overwriteBundle('profile')">
                    <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
                    Profile
                </button>
            </ng-container>
            <ng-container *ngIf="!importingObjects && !savingBundle && isReset">
                <button type="button" class="btn btn-sm btn-warning" (click)="clearBundle(); addObjectsAsync(this.stixService.objectsToLoad); dismissModal(); this.stixService.objectsToLoad = []; removeBundleName();">
                    <fa-icon [icon]="faBackspace" class="pr-1"></fa-icon>
                    Discard Bundle
                </button>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #loadModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="load-modal-title">
                Load STIX Bundles
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="!loadingBundle" class="pt-2">
                <app-saved-bundles-table class="w-100" [mode]="'loading'" (bundleStatus)="updateLoadModal($event)"></app-saved-bundles-table>
            </div>
            <div *ngIf="loadingBundle" class="pt-2">
                Selected Bundle:
                <select class="col-4 form-control" style="font-size: large; font-weight: 500;" type="text"
                    [(ngModel)]="selectedBundle" [disabled]="true">
                    <option *ngFor="let bundle of this.stixService.savedBundles" [value]="bundle.name">{{bundle.name}}
                    </option>
                </select>
                <br>
                <ul class="nav nav-tabs">
                    <li class="nav-item" (click)="activeTab = 'jsonViewer'">
                        <a class="nav-link active" [ngClass]="{'active': activeTab === 'jsonViewer'}">JSON</a>
                    </li>
                    <li class="nav-item" (click)="activeTab = 'visualViewer'">
                        <a class="nav-link" [ngClass]="{'active': activeTab === 'visualViewer'}">Visual</a>
                    </li>
                </ul>
                <pre *ngIf="activeTab === 'jsonViewer'" class="preview-bundle ">{{ getStixPreview() | json }}</pre>
                <app-stix-viewer *ngIf="activeTab === 'visualViewer'" class="nav-link" [bundle]="getStixPreview()">
                </app-stix-viewer>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close(false)">
                <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
                Cancel
            </button>
            <!-- <button *ngIf="false" type="button" class="btn btn-sm btn-secondary" data-toggle="tooltip"
                data-placement="top"
                title="Open this visualization in a new tab so that you can use your browser's full width to view your bundle"
                (click)="routeToNewTab('stix-viewer')">
                Visualize Bundle
            </button> -->
            <button *ngIf="loadingBundle" type="button" class="btn btn-sm btn-success" (click)="sendBundle();"
                [disabled]="this.selectedBundle == ''">
                <fa-icon [icon]="faFileDownload" class="pr-1"></fa-icon>
                Load Bundle
            </button>
        </div>
    </ng-template>
</div>