import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbModal, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { faList, faTrash, faTrashAlt, faPlusSquare, faAngleDown, faAngleUp, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { StixService } from 'src/app/stix-service.service';
import { AdminRoleComponent } from '../admin-role/admin-role.component';
import { AnnouncementService } from '../announcement-service/announcement-service.service';

@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['./admin-main.component.css']
})
export class AdminMainComponent implements OnInit {

  collections: any[] = [];
  collectionsShown: any[] = [];
  users: any[];
  apiRoots: any[];
  apiRootsCollections: any[] = [];
  activeTab: string;
  roles: any[] = [];
  roleDetails: any = [];

  faList = faList;
  faTrash = faTrash;
  faTrashAlt = faTrashAlt;
  faPlusSquare = faPlusSquare;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faPlus = faPlus;
  faPlusCircle = faPlusCircle;

  currentCollection = null;
  currentApiRoot = null;
  currentUser = null;
  currentRole = null;
  errorMessages = [];
  modalRef: any;
  unassignedCollection: string;

  sortKey: string = undefined;
  unsortedTable = [];

  autoConfirm = false;
  kibanaDashboardURL = null;

  private httpHeaders: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
    public modalService: NgbModal,
    public stixService: StixService,
    private _router: Router,
    public announcementService: AnnouncementService
  ) {
    this.activeTab = 'apiRoots';
    // this.activeTab = 'collections'; // test only
    // this.activeTab = 'roles'; // test only
    // this.activeTab = 'users';  // test only

    this.unassignedCollection = '<UNASSIGNED COLLECTION>';
  }

  ngOnInit() {
    this.httpHeaders = new HttpHeaders()
      .set('Accept', 'application/taxii+json;version=2.1')
      .set('Authorization', `Basic ${btoa(environment.adminServer.username + ":" + environment.adminServer.password)}`)
      .set('Content-Type', `application/taxii+json;version=2.1`);

    this.getCollections();

    this.getUsers();

    this.getApiRoots();

    this.getRoles();

    this.kibanaDashboardURL = environment.kibanaDashboardURL;

    // Testing API root page
    // this.currentApiRoot = {}

    // Testing collection page
    // this.setTab('collections');
    // this.currentCollection = {}
  }

  sort(col, table) {
    if (table === 'collections' && this.collectionsShown.length === 0) return;
    if (table === 'api-roots' && this.apiRoots.length === 0) return;
    if (table === 'users' && this.users.length === 0) return;
    if (table === 'groups'
      && this.roleDetails
      && this.roleDetails.privileges
      && this.roleDetails.privileges.length === 0) return;

    if (!this.sortKey) {
      this.sortKey = '';
      this.unsortedTable = [];
      if (table === 'collections') {
        for (let obj of this.collectionsShown)
          this.unsortedTable.push(obj);
      }
      else if (table === 'api-roots') {
        for (let obj of this.apiRoots)
          this.unsortedTable.push(obj);
      }
      else if (table === 'users') {
        for (let obj of this.users)
          this.unsortedTable.push(obj);
      }
      else if (table === 'groups') {
        for (let obj of this.roleDetails.privileges)
          this.unsortedTable.push(obj);
      }
    }

    if (this.sortKey === '') {
      if (table === 'collections') this.collectionsShown = this.sortResultsDes(col, this.collectionsShown, table);
      if (table === 'api-roots') this.apiRoots = this.sortResultsDes(col, this.apiRoots, table);
      if (table === 'users') this.users = this.sortResultsDes(col, this.users, table);
      if (table === 'groups') this.roleDetails.privileges = this.sortResultsDes(col, this.roleDetails.privileges, table);

      this.sortKey = `${col}-des`;
    }
    else {
      let keyArr = this.sortKey.split('-');
      if (col === keyArr[0] && keyArr[1] === 'des') {
        if (table === 'collections') this.collectionsShown = this.sortResultsAsc(col, this.collectionsShown, table);
        if (table === 'api-roots') this.apiRoots = this.sortResultsAsc(col, this.apiRoots, table);
        if (table === 'users') this.users = this.sortResultsAsc(col, this.users, table);
        if (table === 'groups') this.roleDetails.privileges = this.sortResultsAsc(col, this.roleDetails.privileges, table);

        this.sortKey = `${col}-asc`;
      }
      else if (col === keyArr[0] && keyArr[1] === 'asc') {
        if (table === 'collections') this.collectionsShown = this.unsortedTable;
        if (table === 'api-roots') this.apiRoots = this.unsortedTable;
        if (table === 'users') this.users = this.unsortedTable;
        if (table === 'groups') this.roleDetails.privileges = this.unsortedTable;

        this.sortKey = '';
      }
      else {
        if (table === 'collections') this.collectionsShown = this.sortResultsDes(col, this.collectionsShown, table);
        if (table === 'api-roots') this.apiRoots = this.sortResultsDes(col, this.apiRoots, table);
        if (table === 'users') this.users = this.sortResultsDes(col, this.users, table);
        if (table === 'groups') this.roleDetails.privileges = this.sortResultsDes(col, this.roleDetails.privileges, table);

        this.sortKey = `${col}-des`;
      }
    }
  }

  sortToLowercase(item) {
    if (typeof item === 'string') return item.toLowerCase();

    return item;
  }

  sortResultsDes(col: string, tableArray: any[], table) {
    if (col !== 'fragment' && table === 'api-roots')
      return this.objectSortDes(col, tableArray, 'apiRoot');

    switch (col) {
      case 'roles':
        return tableArray.sort((a, b) =>
          this.sortToLowercase(a[col][0]) > this.sortToLowercase(b[col][0]) ? 1 :
            this.sortToLowercase(a[col][0]) < this.sortToLowercase(b[col][0]) ? -1 : 0);
      default:
        return tableArray.sort((a, b) =>
          this.sortToLowercase(a[col]) > this.sortToLowercase(b[col]) ? 1 :
            this.sortToLowercase(a[col]) < this.sortToLowercase(b[col]) ? -1 : 0);
    }
  }

  sortResultsAsc(col: string, tableArray: any[], table) {
    if (col !== 'fragment' && table === 'api-roots')
      return this.objectSortAsc(col, tableArray, 'apiRoot');

    switch (col) {
      case 'roles':
        return tableArray.sort((a, b) =>
          this.sortToLowercase(a[col][0]) < this.sortToLowercase(b[col][0]) ? 1 :
            this.sortToLowercase(a[col][0]) > this.sortToLowercase(b[col][0]) ? -1 : 0);
      default:
        return tableArray.sort((a, b) =>
          this.sortToLowercase(a[col]) < this.sortToLowercase(b[col]) ? 1 :
            this.sortToLowercase(a[col]) > this.sortToLowercase(b[col]) ? -1 : 0);
    }
  }

  objectSortDes(col, tableArray, obj) {
    switch (col) {
      case 'versions':
        return tableArray.sort((a, b) =>
          this.sortToLowercase(a[obj][col][0]) > this.sortToLowercase(b[obj][col][0]) ? 1 :
            this.sortToLowercase(a[obj][col][0]) < this.sortToLowercase(b[obj][col][0]) ? -1 : 0);
      default:
        return tableArray.sort((a, b) =>
          this.sortToLowercase(a[obj][col]) > this.sortToLowercase(b[obj][col]) ? 1 :
            this.sortToLowercase(a[obj][col]) < this.sortToLowercase(b[obj][col]) ? -1 : 0);
    }
  }

  objectSortAsc(col, tableArray, obj) {
    switch (col) {
      case 'versions':
        return tableArray.sort((a, b) =>
          this.sortToLowercase(a[obj][col][0]) < this.sortToLowercase(b[obj][col][0]) ? 1 :
            this.sortToLowercase(a[obj][col][0]) > this.sortToLowercase(b[obj][col][0]) ? -1 : 0);
      default:
        return tableArray.sort((a, b) =>
          this.sortToLowercase(a[obj][col]) < this.sortToLowercase(b[obj][col]) ? 1 :
            this.sortToLowercase(a[obj][col]) > this.sortToLowercase(b[obj][col]) ? -1 : 0);
    }
  }

  getApiRootsCollectionsIds() {
    let ids = [];
    this.apiRootsCollections.forEach(ar => {
      ar.collections.forEach(c => {
        ids.push(c.id);
      })
    })

    return ids;
  }

  getApiRootDetail(fragment) {
    return this.apiRoots.find(r => r.fragment === fragment);
  }

  onAPIRootChanged(event: any = null) {
    // this.collections = [];
    this.currentApiRoot = null;
    if (event && event.target && event.target.value) {
      if (this.collections && event.target.value === '--unassigned--') {
        const apiCollectionIds = this.getApiRootsCollectionsIds();
        this.collectionsShown = this.collections.filter(c =>
          !apiCollectionIds.some(apiC =>
            apiC === c.id
          )
        )
      } else {
        this.currentApiRoot = event.target.value;
        const apiRootObj = this.apiRootsCollections.find(a => a.fragment === this.currentApiRoot);
        if (apiRootObj && apiRootObj.collections) {
          // this.collections = apiRootObj.collections;
          this.collectionsShown = apiRootObj.collections;
        } else {
          this.collectionsShown = [];
        }
      }
    } else {
      this.collectionsShown = [];
    }
  }

  onRoleChange(event: any = null) {
    if (event && event.target && event.target.value) {
      this.currentRole = event.target.value;
      this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
        this.httpClient.get<any>(this.stixService.getBaseURLs().baseAdminURL + "role/" + this.currentRole, { headers: header, observe: 'response' }).subscribe(
          resp => {
            this.roleDetails = resp.body;
          }
        )
      })
    } else {
      this.roleDetails = [];
    }
  }

  getRoles() {
    this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
      this.httpClient.get<any>(this.stixService.getBaseURLs().baseAdminURL + "roles", { headers: header, observe: 'response' }).subscribe(
        resp => {
          this.roles = resp.body;
        }
      )
    })
  }

  getCollections() {
    this.sortKey = undefined;

    this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
      this.httpClient.get<any>(this.stixService.getBaseURLs().baseAdminURL + "collections", { headers: header, observe: 'response' }).subscribe(
        resp => {
          this.collections = resp.body;
          // this.editCollection(this.collections[0]);  //test only
        }
      )
    })
  }

  getUsers() {
    this.sortKey = undefined;

    this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
      this.httpClient.get<any>(this.stixService.getBaseURLs().baseAdminURL + "users", { headers: header, observe: 'response' }).subscribe(
        resp => {
          this.users = resp.body;
          // console.log(this.users);
          // setTimeout(() => {
          //   this.editUser(this.users[this.users.length - 1]);  // test only
          // }, 500);
        }
      )
    })
  }

  getApiRoots() {
    this.sortKey = undefined
    this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
      this.httpClient.get<any>(this.stixService.getBaseURLs().baseAdminURL + "api-roots", { headers: header, observe: 'response' }).subscribe(
        resp => {
          this.apiRoots = resp.body
  
          this.apiRootsCollections = [];
          this.apiRoots.forEach(apiRoot => {
            this.httpClient.get<any>(this.stixService.getBaseURLs().baseAdminURL + 'api-root/' + apiRoot.fragment + "/collections/", { headers: header, observe: 'response' }).subscribe(
              resp => {
                if (resp.body.collections) {
                  let apiRootObj: any = {};
                  apiRootObj['fragment'] = apiRoot.fragment;
                  apiRootObj['collections'] = resp.body['collections'];
                  apiRootObj['collections'].forEach(c => {
                    delete c.readers;
                    delete c.writers;
                  })
                  apiRootObj.collections =
                    apiRootObj.collections.sort((a, b) => a.title - b.title);
                  this.apiRootsCollections.push(apiRootObj);
                }
  
                this.getCollections();
              }
            )
          })
  
          // setTimeout(() => {
          //   this.editApiRoot(this.apiRoots[this.apiRoots.length - 1]);  // test only
          // }, 1000);
        }
      )
    })
  }

  getCollectionInAPIRoot(apiRootName, cid = null) {
    if (this.apiRootsCollections
      && this.apiRootsCollections.length > 0 && apiRootName) {
      if (cid) {
        let apiRootObj = this.apiRootsCollections.find(a => a.fragment === apiRootName);
        if (apiRootObj && apiRootObj.collections) {
          return apiRootObj.collections.find(c => c.id === cid);
        }
      } else {
        return this.apiRootsCollections.find(a => a.fragment === apiRootName);
      }
    }
    return [];
  }

  addCollection() {
    this.currentCollection = {}
  }

  editCollection(collection) {
    this.currentCollection = collection;
  }

  getCollectionPerRolePermission(roleDetail, type) {
    const collection = this.collections.find(c => c.id === roleDetail.collection_id);
    if (type === 'read') {
      if (collection
        && collection.can_read) {
        return roleDetail.can_read;
      } else {
        return collection.can_read;
      }
    }

    if (type === 'write') {
      if (collection
        && collection.can_write) {
        return roleDetail.can_write;
      } else {
        return collection.can_write;
      }
    }
  }

  editRole(roleName = null, roleDetail = null) {
    this.modalRef = this.modalService.open(AdminRoleComponent);
    this.modalRef.componentInstance.roleDetail = JSON.parse(JSON.stringify(roleDetail));
    this.modalRef.componentInstance.roleDetails = this.roleDetails;
    this.modalRef.componentInstance.role = roleName;
    this.modalRef.componentInstance.collections = this.collections;
    this.modalRef.componentInstance.apiRoots = this.apiRoots;
    this.modalRef.componentInstance.apiRootsCollections = this.apiRootsCollections;

    this.modalRef.result.then((resp) => {
      switch (resp.type) {
        case 'save': {
          setTimeout(() => {
            if (this.roleDetails
              && this.roleDetails.privileges
              && this.roleDetails.privileges.length === 1
              && !resp.roleDetail.can_read
              && !resp.roleDetail.can_write) {
              this.getRoles();
              this.onRoleChange({ target: { value: null } });
              this.currentRole = null;
              this.roleDetails = [];
            } else {
              this.onRoleChange({ target: { value: roleName } });
            }
          }, 1000)
          break;
        }
        case 'cancel': {
          break;
        }
        default:
          break;
      }
    })
  }

  newRole() {
    this.modalRef = this.modalService.open(AdminRoleComponent);
    this.modalRef.componentInstance.roleDetails = this.roleDetails;
    this.modalRef.componentInstance.collections = this.collections;
    this.modalRef.componentInstance.apiRoots = this.apiRoots;
    this.modalRef.componentInstance.apiRootsCollections = this.apiRootsCollections;

    this.modalRef.result.then((resp) => {
      switch (resp.type) {
        case 'save': {
          setTimeout(() => {
            this.getRoles();
            this.onRoleChange({ target: { value: resp.role } });
          }, 1000)
          break;
        }
        case 'cancel': {
          break;
        }
        default:
          break;
      }
    })
  }

  addCollectionToRole() {
    this.editRole(this.currentRole);
  }

  addUser() {
    this.currentUser = {};
  }

  editUser(user) {
    this.currentUser = user;
  }

  addApiRoot() {
    this.currentApiRoot = {};
  }

  editApiRoot(apiRoot) {
    this.currentApiRoot = apiRoot;
  }

  backToMain(event) {
    switch (event.type) {
      case 'save-collection': {
        this.onAPIRootChanged();
        this.collectionsShown = [];
        // this.getApiRoots();

        setTimeout(() => {
          this.getApiRoots();
          // this.onAPIRootChanged();
        }, 1000);
        break;
      }
      case 'save-user': {
        const index = this.users.findIndex(c => c.user === event.data.user);
        if (index !== -1) {
          this.users[index] = event.data;
        } else {
          this.users.push(event.data);
        }
        this.activeTab = 'users';
        this.currentUser = null;
        break;
      }
      case 'save-api-root': {
        setTimeout(() => {
          this.getApiRoots();
        }, 1000);
        break;
      }
      default: {
        this.collectionsShown = [];
        this.resetCurrentSelections();
        this.getApiRoots();

        setTimeout(() => {
          // this.getApiRoots();
          this.onAPIRootChanged();
        }, 1000);
        // this.collections = [];
      }
    }

    this.currentCollection = null;
    this.currentUser = null;
    this.currentApiRoot = null;
  }

  resetCurrentSelections() {
    this.currentCollection = null;
    this.currentUser = null;
    this.currentApiRoot = null;
  }

  setTab(tab) {
    this.sortKey = undefined;

    switch (tab) {
      case 'kibana-dashboard': {
        this.activeTab = 'kibana-dashboard';
        break;
      }
      case 'collections': {
        this.activeTab = 'collections';
        this.onAPIRootChanged();
        break;
      }
      case 'users': {
        this.activeTab = 'users';
        this.getUsers();
        break;
      }
      case 'apiRoots': {
        this.activeTab = 'apiRoots';
        this.getApiRoots();
        break;
      }
      case 'roles': {
        this.activeTab = 'roles';
        this.currentRole = null;
        this.onRoleChange(null);
        this.getRoles();
        break;
      }
      default: {
        this.activeTab = 'collections';
        this.onAPIRootChanged();
      }
    }
    this.resetCurrentSelections();
  }

  removeRoleFromCollection(rootOfCollection, cId, role, roleType) {
    let root = null;
    let rw = null;

    root = this.apiRoots.find(r => r.fragment === rootOfCollection);
    rw = root.rws.find(r => r.cId === cId);

    if (roleType === 'readers') {
      if (rw.readers && rw.readers.length > 0) {
        const index = rw.readers.findIndex(r => r === role);
        if (index !== -1) {
          rw.readers.splice(index, 1);
        }
      }
    }

    if (roleType === 'writers') {
      if (rw.writers && rw.writers.length > 0) {
        const index = rw.writers.findIndex(r => r === role);
        if (index !== -1) {
          rw.writers.splice(index, 1);
        }
      }
    }
  }

  removeRole() {
    this.removeObject('role', this.currentRole);
  }

  removeObject(type, object) {
    this.errorMessages = [];
    let messageData = {};

    this.modalRef = this.modalService.open(MessageDialogComponent);

    switch (type) {
      case 'collection': {
        messageData = {
          'title': 'Remove Collection',
          'message': "Please confirm you would like to remove collection: <strong>" + object.title + "</strong>.",
        }
        break;
      }
      case 'user': {
        messageData = {
          'title': 'Remove User',
          'message': "Please confirm you would like to remove user: <strong>" + object.user + "</strong>.",
        }
        break;
      }
      case 'api-root': {
        messageData = {
          'title': 'Remove API Root',
          'message': "Please confirm you would like to remove api root: <strong>" + object.fragment + "</strong>.",
        }
        break;
      }
      case 'role-collection': {
        messageData = {
          'title': 'Remove Role',
          'message': "Please confirm you would like to remove group <strong>" + this.currentRole + "</strong> from collection <strong>" + this.getCollectionById(object.collection_id).title + "</strong> in root <strong>" + object.api_root + "</strong>.",
          'autoConfirm': this.autoConfirm,
        }
        break;
      }
      case 'role': {
        messageData = {
          'title': 'Remove Role',
          'message': "Please confirm you would like to remove group: <strong>" + object + "</strong>.",
        }
        break;
      }
    }

    this.modalRef.componentInstance.messageObj = messageData;

    this.modalRef.result.then((resp) => {
      switch (resp) {
        case 'confirm': {
          let url = '';
          switch (type) {
            case "collection": {
              url = this.stixService.getBaseURLs().baseAdminURL + 'collection/' + object.id;
              break;
            }
            case "user": {
              url = this.stixService.getBaseURLs().baseAdminURL + 'user/' + object.user;
              break;
            }
            case "api-root": {
              url = this.stixService.getBaseURLs().baseAdminURL + 'api-root/' + object.fragment;
              break;
            }
            case "role": {
              url = this.stixService.getBaseURLs().baseAdminURL + 'role/' + object;
              // this.autoConfirm = true; // needed if backend doesn't delete roles in all collections
              this.currentRole = null;
              break;
            }
            case "role-collection": {
              this.removeRoleFromCollection(object.api_root, object.collection_id, this.currentRole, 'readers');
              this.removeRoleFromCollection(object.api_root, object.collection_id, this.currentRole, 'writers');

              let fullRoot = this.apiRoots.find(r => r.fragment === object.api_root);

              let payload = {
                fragment: fullRoot.fragment,
                apiRoot: fullRoot.apiRoot,
                rws: fullRoot.rws,
              }

              // need to post to api root with RW removed for that collection.
              let url = this.stixService.getBaseURLs().baseAdminURL + 'api-root';
              this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
                this.httpClient.post<any>(url, payload, { headers: header, observe: 'response' }).subscribe(
                  results => {
                    console.info(results);
                    this.getRoles();
                    setTimeout(() => {
                      if (this.roleDetails.privileges && this.roleDetails.privileges.length === 1) {
                        this.getRoles();
                        this.onRoleChange({ target: { value: null } });
                        this.currentRole = null;
                        this.roleDetails = [];
                      } else {
                        this.onRoleChange({ target: { value: this.currentRole } });
                      }
                    }, 1000)
                  },
                  (err: any) => {
                    this.handleError(err, "Delete failed. Please check role parameters.")
                  }
                );
              })
              break;
            }
          }

          if (type !== "role-collection") {
            this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
              this.httpClient.delete<any>(url, { headers: header, observe: 'response' }).subscribe((resp: any) => {
                switch (type) {
                  case "collection": {
                    let index = this.collections.findIndex(x => x.id === resp.body.id)
                    this.collections.splice(index, 1);
  
                    index = this.collectionsShown.findIndex(x => x.id === resp.body.id)
                    this.collectionsShown.splice(index, 1);
                    break;
                  }
                  case "user": {
                    let index = this.users.findIndex(x => x.user === resp.body.user)
                    this.users.splice(index, 1);
                    // this.getUsers();
                    break;
                  }
                  case "api-root": {
                    let index = this.apiRoots.findIndex(x => x.fragment === resp.body.fragment)
                    this.apiRoots.splice(index, 1);
                    // this.getApiRoots();
                    break;
                  }
                  case "role": {
                    setTimeout(() => {
                      this.getRoles();
                      if (this.roles && this.roles.length > 0) {
                        this.onRoleChange({ target: { value: null } });
                        this.roleDetails = [];
                      }
                    }, 1000)
                    break;
                  }
                }
              },
                (err: any) => {
                  this.handleError(err, "Please check Collection assignment to API Root.");
                })
            })
          }

          break;
        }
        default:
          break;
      }
    })
  }

  removeCollection(collection) {
    this.errorMessages = [];

    this.modalRef = this.modalService.open(MessageDialogComponent);

    const messageData = {
      'title': 'Remove Collection',
      'message': "Please confirm you would like to remove collection: " + collection.title,
    }
    this.modalRef.componentInstance.messageObj = messageData;

    this.modalRef.result.then((resp) => {
      switch (resp) {
        case 'confirm': {
          const url = this.stixService.getBaseURLs().baseAdminURL + 'collection/' + collection.id;
          this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
            this.httpClient.delete<any>(url, { headers: header, observe: 'response' }).subscribe((resp: any) => {
              if (resp.status >= 400) {
                this.errorMessages.push(resp.statusText);
              } else {
                // const index = this.collections.findIndex(x => x.id === resp.body.id)
                // this.collections.splice(index, 1);
                this.getCollections();
              }
            },
              (err: any) => {
                this.handleError(err, "Please check Collection assignment to API Root.");
              });
          })
          break;
        }
        default:
          break;
      }
    })
  }

  handleError(err, defaultMsg = '') {
    if (err.status >= 400 && err.error && err.error.description) {
      // this.errorMessages.push("Error: " + err.error.description);
      // setTimeout(() => {
      //   this.errorMessages = [];
      // }, 8000)
      this.announcementService.show("Error", err.error.description, "error", false);
    } else {
      // this.errorMessages.push("Error: " + defaultMsg);
      // setTimeout(() => {
      //   this.errorMessages = [];
      // }, 8000)
      this.announcementService.show("Error", defaultMsg, "error", false);
    }
  }

  getCollectionById(cId) {
    return this.collections.find(c => c.id === cId)
  }
}
