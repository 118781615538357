<div class="d-flex flex-column justify-content-start align-items-center content-wrapper">
    <div class="d-flex flex-row justify-content-around align-items-end gr-top-width">
        <div class="w-75 pl-5 d-flex align-items-end">
            <div class="mb-3">
                <img class="card-img" src="./assets/sword.svg" class="center" width="80" height="80" />
            </div>
            <span class="title-main-size3">HOW?</span>
        </div>
        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
    </div>

    <div class="horizonal-rule-top"></div>
    <div class="d-flex justify-content-center align-items-start w-80">
        <div class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">Adding TTPs</h6>
            <div class="gr-help-section-content">
                If you have a tactic #, you may enter it in the "Tactic #" field.
                <br><br>
                You may also use CISA's Decider Tool to analyze adversary activity and help you arrive at the correct tactic, technique, or sub-technique.
                <br><br>
                Click the Decider Tool button to go to CISA's Decider Tool. There you will be guided through the TTP Selection Process.
                <br><br>
                Once you are done with the Decider Tool, the TTPs there will automatically populate the Tactic # field on this page and you may continue.
                <br><br>
                You may add multiple TTPs to your cart.
                <br><br>
                Add Observables: You may now add observables that you have previously created and pair them with an ATTACK TTP. 
                <br><br>
                Detection String: Choose a detection string to link to an ATTACK TTP.
            </div>
        </div>
        <div class="mt-5 test d-flex flex-column align-items-center gr-outter-wrapper">
            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3">
                <span class="w-100 font-weight-bold" title="">What technique, tactic, or procedure (TTP) was used to carry out the event?</span>
            </div>
            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3">
                <span class="w-25">TTP Designation
                    <span class="guided-tip">
                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('ttp_designation')"></span>
                    </span>
                </span>
                <input class="form-control w-75 noborder" placeholder="Enter a label or designation. If you don’t have one, it will be autogenerated for you." [(ngModel)]="name">
            </div>
            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3">
                <span class="w-25">Tactic<span class="text-danger ml-1">*</span>
                    <span class="guided-tip">
                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('ttp_tactic')"></span>
                    </span>
                </span>
                <input class="form-control w-75 noborder" placeholder="The MITRE ATT&CK Tactic" [(ngModel)]="tacticNumbers" (input)="disableContinue()">
            </div>
            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3">
                <span class="w-25">Technique<span class="text-danger ml-1">*</span>
                    <span class="guided-tip">
                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('ttp_technique')"></span>
                    </span>
                </span>
                <input class="form-control w-75 noborder" placeholder="The MITRE ATT&CK Technique #" [(ngModel)]="indexNumbers" (input)="disableContinue()">
            </div>
            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3">
                <span class="w-25">Add Observables
                    <span class="guided-tip">
                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('add_observables')"></span>
                    </span>
                </span>
                <select class="form-control w-75 noborder" type="text" [(ngModel)]="selectedSco" (ngModelChange)="checkSelectObs()" multiple size="3" [size]="scoSize+1">
                    <option value="" selected>None</option>
                    <option *ngFor="let option of getScos()" [value]="option.id">{{ option.type + ': ' + option.display_name }}</option>
                </select>   
            </div>
            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3">
                <span class="w-25">Detection String
                    <span class="guided-tip">
                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('detection_string')"></span>
                    </span>
                </span>
                <select class="form-control w-75 noborder" type="text" [(ngModel)]="selectedIndicator" (ngModelChange)="checkSelectDet()" multiple [size]="indicatorSize+1">
                    <option value="" selected>None</option>
                    <option *ngFor="let option of getIndicators()" [value]="option.id">{{ option.pattern }}</option>
                </select>
            </div>
            <app-guided-cart class="w-100 mt-3" #guidedCart [currentComponent]="component" [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [editModeEvent]="editModeEmitter" [syncCartEvent]="syncCartEmitter" [getEditItemEvent]="getEditItemEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart>
        </div>
    </div>
</div>

<!-- <app-add-component #appAddComponent style="display: none" [objectSelectionInput]="objectSelection"></app-add-component> -->
