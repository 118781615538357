<div class="body">
  <!-- <div class="mt-4 action-button">
    <button
          type="button"
          class="btn btn-sm btn-secondary"
          (click)="backToMain()"
    >
      Return
    </button>
  </div> -->
  <div
    *ngIf="!editingUsername"
    class="item-title">
    {{ user.user }}
    <button *ngIf="newObject"
      type="button"
      class="btn btn-fa btn-primary ml-3 mr-1"
      title="Click to edit"
      (click)="editUsername()">
      <fa-icon 
        [icon]="faEdit"
      >
      </fa-icon>
    </button>
  </div>

  <div
    *ngIf="editingUsername"
    class="d-flex item-title">
    <input
      class="form-control item-label-input"
      type="text"
      placeholder="User Name"
      [(ngModel)]="currentUsername"
    />
    <button type="button"
      class="btn btn-fa btn-success ml-3 mr-1"
      title="Click to save"
      (click)="saveUsername()">
      <fa-icon [icon]="faSave">
      </fa-icon>
    </button>
    <button type="button"
      class="btn btn-fa btn-danger ml-2 mr-1"
      title="Click to cancel"
      (click)="cancelUsername()">
        <fa-icon [icon]="faBan">
        </fa-icon>
    </button>
  </div>

  <div class="item-content">
    <label
      *ngIf="showPassword()"
      class="item-label">
      <div class="item-label-title">
        Password
      </div>
      <input
        type="password"
        class="form-control item-label-input input-sm"
        [(ngModel)]="user.password" />
    </label>

    <label class="item-label">
      <div class="item-label-title ">
        Groups
      </div>
      <div class="d-flex" style="width: 623px">
        <select class="form-control input-sm" id="APIRoots" name="APIRoots"
            (change)="selectRole($event)"
            [(ngModel)]="newRole">
            <option value='undefined' disabled selected>Select Group</option>
            <option *ngFor="let role of existingRoles; let i = index" [value]="role"
                title="role">{{ role }}</option>
        </select>
        <!-- <input type="text"
          style="width: 190px"
          class="form-control item-label-input input-sm"
          (change)="selectRole($event)"
          [(ngModel)]="newRole" /> -->
        <button
          type="button"
          class="btn btn-fa-sm btn-primary ml-2"
          title="Click to add"
          (click)="addRole()"
          [disabled]="!newRole">
          <fa-icon
            [icon]="faPlus"
          ></fa-icon>
        </button>
      </div>
    </label>

    <label class="item-label">
      <div class="item-label-title">
      </div>
      <div class="item-list-wrapper">
        <div *ngFor="let role of user.roles; let i = index;"
          class="media-list-item-wrapper">
          <div class="media-list-item-type">{{ role }}</div>
            <fa-icon [icon]="faTrash"
              title="Click to remove."
              class="btn btn-fa-sm btn-danger mr-1 action-button"
              (click)="removeRole(i)"></fa-icon>
        </div>
      </div>
    </label>
    
    <label class="item-label" *ngIf="!newObject">
    <div class="item-label-title">
      Certificate
    </div>
    <button 
      *ngIf="hasCert === false"
      type="button"
      class="btn btn-md btn-primary"
      (click)="assignCertificate()" 
      title="Assign certificate">
      <fa-icon [icon]="faKey" class="pr-1"></fa-icon>
      Assign Certificate
    </button>
    <button 
      *ngIf="hasCert === true"
      type="button"
      class="btn btn-md btn-danger"
      (click)="removeCertificate()" 
      title="Assign certificate">
      <fa-icon [icon]="faKey" class="pr-1"></fa-icon>
      Remove Certificate
    </button>
  </label>
  </div>

  <div class="item-action">
    <button 
      type="button"
      class="btn btn-sm btn-secondary mr-3"
      (click)="backToMain()">
      <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-sm btn-success"
      (click)="backToMain('save')"
      [disabled]="!user.user || editingUsername">
      <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
      Save
    </button>
  </div>

  <div
    *ngIf="errorMessages?.length > 0"
    class="row error-message">
    <div class="col">
        <div class="d-flex justify-content-center flex-column">
          <div *ngFor="let err of errorMessages;"
            class="alert alert-danger" role="alert">
            {{ err }}
          </div>
        </div>
    </div>
  </div>

</div>
