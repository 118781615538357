<div class="" >
    <div class="bottom"></div>
    <div class="bottom bottom-absolute">
        <div class="col-12 d-flex mb-2" *ngIf="currentComponent != 'how-event-detection'">
            <div class="pt-2" [ngClass]="{'col-4': !editMode, 'col-3': editMode, 'pl-0': currentComponent == 'how-event-detection'}">
                <div class="w-100 d-flex">
                    <h6 class="mt-2 mr-1">Added Items</h6>
                    <button class="btn btn-primary btn-cart" (click)="openCart()" [disabled]="cartLength == 0">
                        <fa-icon [icon]="faCart"></fa-icon>
                        <span *ngIf="cartLength != 0" class="ml-2 cart-number pl-2 pr-2">{{cartLength}}</span>
                    </button>
                </div> 
            </div>
            <div class="d-flex justify-content-center pt-2" [ngClass]="{'col-4': !editMode, 'col-3': editMode}">
                <h6 class="mt-2 mr-1" *ngIf="!editMode">Add to Cart</h6>
                <h6 class="mt-2 mr-1" *ngIf="editMode">Update Cart</h6>
                <button class="btn btn-primary btn-cart" (click)="addToCart()" [disabled]="isCartDisabled">
                    <fa-icon [icon]="faCartPlus"></fa-icon>
                </button>
            </div>
            <div class="col-3 d-flex justify-content-center pt-2" *ngIf="editMode">
                <h6 class="mt-2 mr-1" *ngIf="editMode">Cancel Update</h6>
                <button class="btn btn-secondary btn-cart" (click)="cancelUpdate()">
                    <fa-icon [icon]="faBan"></fa-icon>
                </button>
            </div>
            <div class="d-flex justify-content-end align-items-center" [ngClass]="{'col-4': !editMode, 'col-3': editMode}">
                <h6 class="mt-2 mr-1 assist-text" (click)="showAssistance.emit()">Need Help?</h6>
                <fa-icon class="faQuestionCircle" [icon]="faQuestionCircle" (click)="showAssistance.emit()">
                </fa-icon>
            </div>
        </div>
        <div class="col-12 d-flex mb-2 p-0" *ngIf="currentComponent == 'how-event-detection'">
            <div class="col-4 pl-0 pt-2">
                <div class="w-100 d-flex">
                    <h6 class="mt-2 mr-1">Added Items</h6>
                    <button class="btn btn-primary btn-cart" (click)="openCart()" [disabled]="cartLength == 0">
                        <fa-icon [icon]="faCart"></fa-icon>
                        <span *ngIf="cartLength != 0" class="ml-2 cart-number pl-2 pr-2">{{cartLength}}</span>
                    </button>
                </div> 
            </div>
            <div class="col-4 d-flex justify-content-center pt-2">
                <h6 class="mt-2 mr-1" *ngIf="!editMode">Add to Cart</h6>
                <h6 class="mt-2 mr-1" *ngIf="editMode">Update Cart</h6>
                <button class="btn btn-primary btn-cart" (click)="addToCart()" [disabled]="isCartDisabled">
                    <fa-icon [icon]="faCartPlus"></fa-icon>
                </button>
            </div>
            <div class="d-flex justify-content-end col-4 pt-2 pr-5" *ngIf="editMode">
                <h6 class="mt-2 mr-1" *ngIf="editMode">Cancel Update</h6>
                <button class="btn btn-secondary btn-cart" (click)="cancelUpdate()">
                    <fa-icon [icon]="faBan"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>