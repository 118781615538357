import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Reactive Forms
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// MatDialog
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// App Components
import { HomeComponent } from './home/home.component';
import { BundleComponent } from './bundle/bundle.component';
import { DynamicFormQuestionComponent } from './dynamic-form-component/app-question/app-question.component';
import { DynamicFormComponent } from './dynamic-form-component/dynamic-form/dynamic-form.component';
import { AddComponentComponent } from './add-component/add-component.component';
import { CustomObjectComponent } from './custom-objects/custom-object.component';
import { GranularMarkingsComponent } from './granular-markings/granular-markings.component';
import { ExtensionsComponent } from './extensions/extensions.component';

// NGX Bootstrap
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

//Multiselect Dropdown
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { NgSelectModule } from "@ng-select/ng-select";

// Owl Datetime Picker
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker"

// Bundle Type Filter
import { BundleTypeFilter } from "./bundle-type-filter.pipe";
import { ExternalReferencesComponent } from './external-references/external-references.component';
import { StringArrayComponent } from './string-array/string-array.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './auth/login/login.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ReportingComponent } from './reporting/reporting.component';
import { QueryStixComponent } from './query-stix/query-stix.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContentsComponent } from './contents/contents.component';
import { MessagesComponent } from './query-stix/messages/messages.component';
import { AdditionalFiltersComponent } from './query-stix/additional-filters/additional-filters.component';
import { StixViewerComponent } from './stix-viewer/stix-viewer.component';
import { ContentsDialogComponent } from './dialogs/contents-dialog/contents-dialog.component';
import { CustomizeColumnsComponent } from './dialogs/customize-columns/customize-columns.component';
import { RelationshipDialogComponent } from './query-stix/relationship-dialog/relationship-dialog/relationship-dialog.component';

// Admin
import { AdminMainComponent } from './admin-main/admin-main.component';
import { AdminCollectionComponent } from './admin-collection/admin-collection.component';
import { AdminUserComponent } from './admin-user/admin-user.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { AdminApiRootComponent } from './admin-api-root/admin-api-root.component';
import { AdminRwComponent } from './dialogs/admin-rw/admin-rw.component';
import { GuidedComponent } from './guided/guided.component';
import { GuidedMenuComponent } from './guided-menu/guided-menu.component';
import { HelpDialogComponent } from './dialogs/help-dialog/help-dialog.component';

// import { UiSwitchModule } from 'ngx-toggle-switch';
import { UiSwitchModule } from 'ngx-ui-switch';

//Keycloak
import { APP_INITIALIZER } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { Analyst1DialogComponent } from './dialogs/analyst1-dialog/analyst1-dialog.component';
import { ConfigComponent } from './config/config.component';
import { GranularMarkingsDialogComponent } from './granular-markings/granular-markings-dialog/granular-markings-dialog.component';
import { ExternalReferencesDialogComponent } from './external-references/external-references-dialog/external-references-dialog.component';
import { StringArrayDialogComponent } from './string-array/string-array-dialog/string-array-dialog.component';
import { ExtensionsDialogComponent } from './extensions/extensions-dialog/extensions-dialog.component';
import { AdminRoleComponent } from './admin-role/admin-role.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserDataComponent } from './user-data/user-data.component';
import { SavedBundlesTableComponent } from './saved-bundles/saved-bundles-table/saved-bundles-table.component';
import { SavedBundlesDialogComponent } from './saved-bundles/saved-bundles-dialog/saved-bundles-dialog.component';
import { ReportTypeComponent } from './guided-report/report-type/report-type.component';
import { ThreatActorComponent } from './guided-report/threat-actor/threat-actor.component';
import { ObjectAdditionComponent } from './guided-report/object-addition/object-addition.component';
import { AddIndicatorToBundleComponent } from './guided-components/add-indicator-to-bundle/add-indicator-to-bundle.component';
import { WhoIndividualComponent } from './guided-components/who-individual/who-individual.component';
import { HowTtpComponent } from './guided-components/how-ttp/how-ttp.component';
import { HowPatternBuilderComponent } from './guided-components/how-pattern-builder/how-pattern-builder.component';
import { HowObservableComponent } from './guided-components/how-observable/how-observable.component';
import { PatternBuilderComponent } from './dialogs/pattern-builder/pattern-builder.component';
import { MultipartDialogComponent } from './dialogs/multipart-dialog/multipart-dialog.component';
import { WindowsValueDialogComponent } from './dialogs/windows-value-dialog/windows-value-dialog.component';
import { HowEventDetectionComponent } from './guided-components/how-event-detection/how-event-detection.component';
import { WhenSightingComponent } from './guided-components/when-sighting/when-sighting.component';
import { HowEventComponent } from './guided-components/how-event/how-event.component';
import { HowCweComponent } from './guided-components/how-cwe/how-cwe.component';
import { WhyCommonBehaviorsComponent } from './guided-components/why-common-behaviors/why-common-behaviors.component';
import { WhyAttackerComponent } from './guided-components/why-attacker/why-attacker.component';
import { WhatImpactComponent } from './guided-components/what-impact/what-impact.component';
import { CopyPasteDialogComponent } from './dialogs/copy-paste-dialog/copy-paste-dialog.component';
import { ReportAssessmentComponent } from './guided-components/report-assessment/report-assessment.component';
import { GuidedCartComponent } from './guided-components/guided-cart/guided-cart.component';
import { WhenEventComponent } from './guided-components/when-event/when-event.component';
import { WhereLocationComponent } from './guided-components/where-location/where-location.component';
import { GuidedCartModalComponent } from './guided-components/guided-cart/guided-cart-modal/guided-cart-modal.component';
import { TotalGuidedCartComponent } from './guided-components/total-guided-cart/total-guided-cart.component';
import { ObjectsViewerComponent } from './objects-viewer/objects-viewer.component';
import { CustomTaxiiComponent } from './config/custom-taxii/custom-taxii.component';
import { AlertTabComponent } from './user-data/alert-tab/alert-tab.component';
import { AlertSubscriptionsComponent } from './user-data/alert-tab/alert-subscriptions/alert-subscriptions.component';
import { AlertHistoryComponent } from './user-data/alert-tab/alert-history/alert-history.component';
import { AlertSubDialogueComponent } from './user-data/alert-tab/alert-subscriptions/alert-sub-dialogue/alert-sub-dialogue.component';

function initializeKeycloak(keycloak: KeycloakService){
  return () => keycloak.init({
    config: {
      url: environment.keycloakConfig.url,
      realm: environment.keycloakConfig.realm,
      clientId: environment.keycloakConfig.clientId,
    },
    initOptions: {
      onLoad: 'login-required',
      flow: 'standard',
      checkLoginIframe: false
    },
    shouldAddToken: (request) => {
      const acceptableUrls = environment.bearerTokenURLs;

      const isAcceptable = acceptableUrls.some((path) => {
        return request.url.includes(path);
      }
      );
      return isAcceptable;
    },
    // bearerExcludedUrls: environment.notKeycloakTaxiiServers
  });
}
var this_providers = [];
if(environment.keycloak === true) this_providers.push({
  provide: APP_INITIALIZER,
  useFactory: initializeKeycloak,
  multi: true,
  deps: [KeycloakService]
})

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BundleComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    AddComponentComponent,
    CustomObjectComponent,
    GranularMarkingsComponent,
    ExtensionsComponent,
    BundleTypeFilter,
    ExternalReferencesComponent,
    StringArrayComponent,
    LoginComponent,
    SignUpComponent,
    QueryStixComponent,
    ReportingComponent,
    ContentsComponent,
    MessagesComponent,
    AdditionalFiltersComponent,
    StixViewerComponent,
    ContentsDialogComponent,
    CustomizeColumnsComponent,
    AdminMainComponent,
    AdminCollectionComponent,
    AdminUserComponent,
    MessageDialogComponent,
    AdminApiRootComponent,
    AdminRwComponent,
    RelationshipDialogComponent,
    GuidedComponent,
    GuidedMenuComponent,
    HelpDialogComponent,
    Analyst1DialogComponent,
    ConfigComponent,
    GranularMarkingsDialogComponent,
    ExternalReferencesDialogComponent,
    StringArrayDialogComponent,
    ExtensionsDialogComponent,
    AdminRoleComponent,
    UserDataComponent,
    SavedBundlesTableComponent,
    SavedBundlesDialogComponent,
    ReportTypeComponent,
    ThreatActorComponent,
    ObjectAdditionComponent,
    AddIndicatorToBundleComponent,
    WhoIndividualComponent,
    HowTtpComponent,
    HowPatternBuilderComponent,
    HowObservableComponent,
    PatternBuilderComponent,
    MultipartDialogComponent,
    WindowsValueDialogComponent,
    HowEventDetectionComponent,
    WhenSightingComponent,
    HowEventComponent,
    HowCweComponent,
    CopyPasteDialogComponent,
    ReportAssessmentComponent,
    WhyCommonBehaviorsComponent,
    WhyAttackerComponent,
    WhatImpactComponent,
    CopyPasteDialogComponent,
    GuidedCartComponent,
    WhenEventComponent,
    WhereLocationComponent,
    GuidedCartModalComponent,
    TotalGuidedCartComponent,
    ObjectsViewerComponent,
    CustomTaxiiComponent,
    AlertSubscriptionsComponent,
    AlertTabComponent,
    AlertHistoryComponent,
    AlertSubDialogueComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    AccordionModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    HttpClientModule,
    FontAwesomeModule,
    UiSwitchModule,
    KeycloakAngularModule,
    NgxPaginationModule
    //NgMultiSelectDropDownModule.forRoot(),
    //NgSelectModule
  ],
  providers: [
    this_providers,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
