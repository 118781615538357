<div class="viewer-wrapper">
  <!-- <div id="graph-element"
    data-stix-allow-dragdrop="false"
    data-show-idrefs="true"
    data-show-markings="true"
    data-show-sidebar="true"
    data-graph-layout="cise"
    data-caption="STIX 2.1 Viewer"
    data-disable-mouse-zoom="false"
    data-show-footer="true"
    data-graph-width="98%"
    data-graph-height="80vh">
  </div> -->
    <div id="canvas-container">
      <div id="canvas-wrapper">
        <div id="canvas"></div>
      </div>
      <div id="canvas-info">
        <div id="selected" class="sidebar">
          <h2>Selected Node</h2>
          <div id="selection"></div>
        </div>
        <div id="connections" class="sidebar">
          <h2>Linked Nodes</h2>
          <h3>Incoming Edges:</h3>
          <div id="connections-incoming"></div>
          <h3>Outgoing Edges:</h3>
          <div id="connections-outgoing"></div>
        </div>
        <div id="legend" class="sidebar">
          <h2>Legend</h2>
          <table id="legend-content">
          </table>
        </div>
      </div>
  </div>
</div>