import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faQuestionCircle, faCartPlus, faShoppingCart, faTrash, faEdit, faBan } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { GuidedCartModalComponent } from './guided-cart-modal/guided-cart-modal.component';
import { GuidedService } from 'src/app/guided.service';
import { StixService } from 'src/app/stix-service.service';

@Component({
  selector: 'app-guided-cart',
  templateUrl: './guided-cart.component.html',
  styleUrls: ['./guided-cart.component.css']
})
export class GuidedCartComponent implements OnInit {
  @Input() currentComponent: string;

  @Input() newItemEvent: EventEmitter<any>;
  @Input() disableCartEvent: EventEmitter<boolean>;
  @Input() editModeEvent: EventEmitter<boolean>;
  @Input() syncCartEvent: EventEmitter<any>;
  @Input() getEditItemEvent: EventEmitter<any>;
  @Input() clearCartEvent: EventEmitter<any>;

  @Output() showAssistance = new EventEmitter<any>();
  @Output() addItem = new EventEmitter<any>();
  @Output() updateItem = new EventEmitter<any>();

  faQuestionCircle = faQuestionCircle;
  faCartPlus = faCartPlus;
  faCart = faShoppingCart;
  faTrash = faTrash;
  faEdit = faEdit;
  faBan = faBan;

  currentMode: string = ''

  isCartDisabled: boolean = true;
  showCart: boolean = false;
  editMode: boolean = false;

  cart: any;
  cartLength: number;

  cartId: number = 0;

  editObjects = [];

  constructor(public cartModalDialog: MatDialog, public guidedService: GuidedService, public stixService: StixService) {
    this.cart = {};
    this.cartLength = 0;
  }

  ngOnInit(): void {
    this.subscribeToParent();

    this.syncCart();

    let largest = 0;
    for(let type in this.cart){
      for(let object of this.cart[type]){
        if(object.cartId > largest) largest = object.cartId;
      }
    }

    this.cartId = largest;
  }

  addCartItem(data){
    switch(this.currentMode){
      case '':
        break;
      default:
        if(this.cartLength === 0) this.cart = {};

        let editObjFound = [];
        for(let i in this.editObjects){
          editObjFound.push(false);
        }

        for(let i in data.push){
          let obj = data.push[i];

          let name = this.getObjectTypeName(obj.type);

          if(this.cart[name] && obj.cartId){
            let objFound = false;

            for(let j in this.editObjects){
              if(this.editObjects[j].cartId === obj.cartId){
                objFound = true;
                editObjFound[j] = true;

                for(let k in this.cart[name]){
                  if(this.cart[name][k].cartId === obj.cartId){
                    obj['display_name'] = this.getDisplayName(obj, this.cart[name].length, this.cart[name][k].display_name);
                    if(this.cart[name][k].id){
                      obj.id = this.cart[name][k].id;
                    } else {
                      obj.id = this.getStixId(obj, name);
                    }

                    this.cart[name][k] = obj;
                    break;
                  }
                }
              }
            }

            if(objFound === false){
              this.cartId++;
              obj.cartId = this.cartId;
              this.cartLength++;

              if(!obj.id){
                obj.id = this.getStixId(obj, name);
              }

              obj['display_name'] = this.getDisplayName(obj, this.cart[name].length, '');
              this.cart[name].push(obj)
            }

          } else if(this.cart[name]){
            this.cartId++;
            obj.cartId = this.cartId;
            
            if(!obj.id){
              obj.id = this.getStixId(obj, name);
            }
            
            obj['display_name'] = this.getDisplayName(obj, this.cart[name].length, '');
            this.cart[name].push(obj);

            this.cartLength++;
          } else {
            this.cartId++;
            obj.cartId = this.cartId;

            if(!obj.id){
              obj.id = this.getStixId(obj, name);
            }

            obj['display_name'] = this.getDisplayName(obj, 0, '');
            this.cart[name] = [ obj ];

            this.cartLength++;
          }
        }

        if(editObjFound.includes(false)){
          for(let i in editObjFound){
            if(editObjFound[i] === false){
              let name = this.getObjectTypeName(this.editObjects[i].type);

              for(let j in this.cart[name]){
                if(this.cart[name][j].cartId === this.editObjects[i].cartId){
                  this.cart[name].splice(j, 1);
                  this.cartLength--;
                  break;
                }
              }
            }
          }
        }
    }

    this.guidedService.syncCart(this.currentMode, this.cart);

    this.editMode = false;
    this.guidedService.cartEditMode = false;
    this.editObjects = [];
  }

  getStixId(object, typeName){
    if(this.guidedService.editCart && this.guidedService.editCart[this.currentMode] && this.guidedService.editCart[this.currentMode][typeName]){
      for(let oldObject of this.guidedService.editCart[this.currentMode][typeName]){
        if(oldObject.cartId === object.cartId){
          return oldObject.id;
        }
      }
    }

    return `${object.type}--${this.stixService.getUUIDFrIdContributingProperties(object)}`;
  }

  addToCart(){
    this.addItem.emit(false);
  }

  cancelUpdate(){
    this.editMode = false;
    this.guidedService.cartEditMode = false;
    this.isCartDisabled = true;
    this.editObjects = [];

    this.addItem.emit(true);
  }

  clearCart() {
    this.cart = {};
    this.cartLength = 0;
    this.guidedService.syncCart(this.currentMode, this.cart);
  }

  deleteFromCart(key, index, search){
    switch(this.currentMode){
      default:
        let tempObject = this.cart[key][index];
        this.cart[key].splice(index, 1);

        if(this.cart[key].length === 0) delete this.cart[key];

        if(search === true){
          switch(this.currentMode){
            case 'how-pattern-builder':
            case 'how-observable':
              if(this.cart['Sighting']){
                for(let i in this.cart['Sighting']){
                  let sighting = this.cart['Sighting'][i];
  
                  if(sighting.sighting_of_ref === tempObject.id){
                    this.deleteFromCart('Sighting', i, false);
                    break;
                  }
                }
              }
              if(this.cart['Observed Data']){
                for(let i in this.cart['Observed Data']){
                  let sighting = this.cart['Observed Data'][i];
  
                  if(sighting.object_refs.includes(tempObject.id)){
                    this.deleteFromCart('Observed Data', i, false);
                    break;
                  }
                }
              }
            }
        }
    }

    this.guidedService.syncCart(this.currentMode, this.cart);

    this.cartLength--;
    if(this.cartLength === 0) this.showCart = false;
  }

  editFromCart(key, index){
    if(!this.cart[key]){
      return;
    }

    this.editObjects = [];

    switch(this.currentMode){
      default:  
        let tempObject = this.cart[key][index];
        this.editObjects.push(tempObject);
        // this.deleteFromCart(key, index, false);

        let found = false;
        
        switch(tempObject.type){
          case 'sighting':
            let splitArr = tempObject.sighting_of_ref.split('--');
            let type = this.getObjectTypeName(splitArr[0]);

            if(this.cart[type]){
              for(let i in this.cart[type]){
                let object = this.cart[type][i];
                if(tempObject.sighting_of_ref === object.id){
                  found = true;

                  let emitObj = {
                    main: object,
                    observed: tempObject
                  }

                  this.editObjects.push(object);
                  // this.deleteFromCart(type, i, false);

                  this.updateItem.emit(emitObj);
                  break;
                }
              }
            }

            //THIS SHOULD NEVER HAPPEN
            if(found === false) this.updateItem.emit(tempObject);
            break;
          case 'observed-data':
            let split = tempObject.object_refs[0].split('--');
            let objType = this.getObjectTypeName(split[0]);

            if(this.cart[objType]){
              for(let i in this.cart[objType]){
                let object = this.cart[objType][i];
                if(tempObject.object_refs.includes(object.id)){
                  found = true;

                  let emitObj = {
                    main: object,
                    observed: tempObject
                  }

                  this.editObjects.push(object);
                  this.updateItem.emit(emitObj);
                  break;
                }
              }
            }

            //THIS SHOULD NEVER HAPPEN
            if(found === false) this.updateItem.emit(tempObject);
            break;
          case 'identity':
            if(tempObject.identity_class === 'organization'
              || tempObject.type === 'threat-actor'){
              this.editObjects = [];
              let objType = this.getObjectTypeName(tempObject.type);

              let emitObj = {
                arr: []
              };

              for(let i in this.cart[objType]){
                let object = this.cart[objType][i];

                if(object.linkId === tempObject.linkId){
                  this.editObjects.push(object);
                  emitObj.arr.push(object);
                }
              }

              this.updateItem.emit(emitObj);
            } else {
              this.updateItem.emit(tempObject);
            }
            break;
          default:
            if(this.cart['Observed Data']){
              for(let i in this.cart['Observed Data']){
                let observed = this.cart['Observed Data'][i];

                if(observed.object_refs.includes(tempObject.id)){
                  found = true;

                  let emitObj = {
                    main: tempObject,
                    observed: observed
                  }

                  this.editObjects.push(observed);
                  // this.deleteFromCart('Observed Data', i, false);

                  this.updateItem.emit(emitObj);
                  break;
                }
              }
            }

            if(found === false) this.updateItem.emit(tempObject);
            break;
        }

        break;
    }

    this.editMode = true;
    this.guidedService.cartEditMode = true;
    this.showCart = false;
    console.log("THERE")
    localStorage.removeItem("item-to-edit");
  }

  getDisplayName(opt, index, prev){
    let displayString = '';
    switch(opt.type) {
      case 'impact':
        if(prev !== ''){
          displayString = prev;
          break;
        }

        displayString = `${opt.impact_category} ${Number(index) + 1}`;
        break;
      case 'malware-analysis':
        break;

      case 'artifact': 
        displayString = (opt.url? opt.url : '') + (opt.payload_bin? opt.payload_bin : '');
        break;

      case 'autonomous-system':
        if(opt.name){
          displayString = opt.number + (opt.name? ' (' + opt.name + ')' : '');
        } else {
          displayString = opt.number;
        }
        break;

      case 'directory':
        displayString = opt.path;
        break;

      case 'email-message':
        displayString = opt.subject? opt.subject : '';
        break;

      case 'file': 
        if (opt.name) displayString = opt.name;
        else if (opt.hashes){
          for(let key in opt.hashes){
            displayString = `${key}:${opt.hashes[key]}`;
            break;
          }
        }
        else displayString = '';
        break;     

      case 'domain-name':
      case 'email-addr':
      case 'ipv4-addr':
      case 'ipv6-addr':
      case 'mac-addr':
      case 'url':
        displayString = opt.value;
        break;
      
      case 'process':
        if (opt.pid) displayString = opt.pid;
        else if (opt.cwd) displayString = opt.cwd;
        else displayString = '';
        break;       
      
      // case 'relationship': 
      //   if (opt.source_ref && opt.target_ref) {
      //       let sourceRefObject = this.stixService.bundle.objects.filter(obj => obj.id === opt.source_ref);
      //       let targetRefObject = this.stixService.bundle.objects.filter(obj => obj.id === opt.target_ref);
      //       if (sourceRefObject.length > 0 && targetRefObject.length > 0)
      //         displayString = this.getObjDisplayName(sourceRefObject[0]).split('|')[0].trim()+ ' -> ' 
      //         + this.getObjDisplayName(targetRefObject[0]).split('|')[0].trim();
      //       else
      //         displayString = '';
      //   } else displayString = '';
      //   break;     
      
      case 'user-account': 
        if (opt.user_id) displayString = opt.user_id;
        else if (opt.account_login) displayString = opt.account_login;  
        else if (opt.display_name) displayString = opt.display_name; 
        else displayString = '';
        break;

      case 'windows-registry-key': 
        if (opt.key) displayString = opt.key;
        else displayString = '';
        break;

      case 'x509-certificate':
        if (opt.subject) displayString = opt.subject;
        else if (opt.serial_number) displayString = opt.serial_number;
        else if (opt.hashes) displayString = Object.keys(opt.hashes)[0] + ': ' + opt.hashes[Object.keys(opt.hashes)[0]];
        else if (opt.issuer) displayString = opt.issuer;
        else displayString = '';
        break;

      case 'attack-pattern':
      case 'campaign': 
      case 'course-of-action': 
      case 'grouping':
      case 'identity':
      case 'incident':
      case 'indicator':
      case 'infrastructure':
      case 'intrusion-set': 
      case 'location': 
      case 'malware':
      case 'note':
      case 'observed-data':
      case 'opinion': 
      case 'report':
      case 'threat-actor': 
      case 'tool': 
      case 'vulnerability':      
      case 'sighting':      
      case 'mutex':
      case 'network-traffic':      
      case 'software': 
      case 'language-content': 
      case 'marking-definition':
      case 'extension-definition':
      case 'event':
      case 'cyber-threat-detection':
        displayString = opt.name;
        break;

      default:
        if(prev !== ''){
          displayString = prev;
          break;
        }

        displayString = `${opt.type} ${Number(index) + 1}`;
    }
    if (displayString && displayString.length > 100) displayString = `${displayString.substring(0, 97) + '...'}`;
    if (displayString){ 
      return displayString;
    }
    else {
      if(this.editMode === true){
        let ind = '';
        for(let i in this.editObjects){
          if(this.editObjects[i].type === opt.type){
            ind = this.editObjects[i].display_name.charAt(this.editObjects[i].display_name.length-1);
          }
        }
        if(prev !== '') return prev;

        return `${opt.type} ${Number(ind)}`;
      } else {
        if(prev !== '') return prev;

        return `${opt.type} ${Number(index) + 1}`;
      }
    }
  }

  getName(value){
    return value.display_name;
  }

  getObjectTypeName(type){
    let result = '';
    let split = type.split('-');
    for(let i in split){
      let word = split[i];

      if(word === 'addr'){
        split[i] = 'Address';
        continue;
      } else if(word === 'url') {
        split[i] = 'URL';
        continue;
      } else if (word.startsWith('ip')){
        let firstLetter = word.charAt(0).toUpperCase();
        let secondLetter = word.charAt(1).toUpperCase();

        split[i] = `${firstLetter}${secondLetter}${word.slice(2)}`;
        continue;
      }

      let firstLetter = word.charAt(0).toUpperCase();
      split[i] = `${firstLetter}${word.slice(1)}`;
    }

    result = split.join(' ');

    return(result);
  }

  openCart(){
    const dialogRef = this.cartModalDialog.open(GuidedCartModalComponent, {
      data: { cart: this.cart, editMode: this.editMode, currentMode: 'Added' },
      height: `${window.innerHeight / 1.75}px`,
      width: `${window.innerWidth / 2}px`
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        if(result.mode === 'edit'){
          this.editFromCart(result.key, result.index);
        } else if(result.mode === 'delete'){
          this.deleteFromCart(result.key, result.index, result.search);
        }
      }
    });
  }

  subscribeToParent(){
    this.disableCartEvent.subscribe((data: boolean) => {
      this.isCartDisabled = data;
    })

    this.editModeEvent.subscribe((data: boolean) => {
      if(this.editMode === true) this.editMode = false;
    })

    this.newItemEvent.subscribe((data: any) => {
      this.currentMode = data.mode;
      this.addCartItem(data);
    })
    
    this.syncCartEvent.subscribe((data: any) => {
      this.syncCart();
    })

    if (this.clearCartEvent) {
      this.clearCartEvent.subscribe((data: any) => {
        this.clearCart();
      })
    }

    this.getEditItemEvent.subscribe((data: any) => {
      this.editFromCart(data.key, data.index);

      this.guidedService.editCartObject = {};
    })
  }

  syncCart(){
    this.cartLength = 0;
    if(this.guidedService.cart[this.currentComponent]){
      this.cart = this.guidedService.cart[this.currentComponent];

      for(let type in this.cart){
        this.cartLength += this.cart[type].length;
      }
    }
  }

}
