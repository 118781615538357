<div class="d-flex flex-column justify-content-start align-items-center content-wrapper">
    <div class="d-flex flex-row justify-content-around align-items-end gr-top-width">
        <div class="w-75 pl-5 d-flex align-items-end">
            <fa-icon class="title-main-size4 pr-1 mr-1" [icon]="faMapPin"></fa-icon>
            <span class="title-main-size3">WHERE?</span>
        </div>

        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
    </div>

    <div class="horizonal-rule-top"></div>

    <div class="d-flex justify-content-center align-items-start w-80">
        <div class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">Location</h6>
            <div class="gr-help-section-content">
                For the target, is the incident contained to a physical location?
                <br><br>
                For the attacker, did the threat activity come from a physical location?
                <br><br>
                First, choose whose location you are recording—the target or attackers—by selecting the correct tab. 
                <br><br>
                If both locations are known, you may fill out both forms.
            </div>
        </div>
        <div class="mt-5 test gr-outter-wrapper align-items-center">
            <div class="w-100 d-flex">
                <div style="width:2.5%"></div>
                <div class="p-2 gr-outter-wrapper" style="border: none">
                    <div class="col-12 d-flex margin-border-top justify-content-between gr-input-wrapper pl-3 pr-3 position-relative align-items-center">
                        <span class="w-45">Have you been able to pinpoint a location?</span>
                        <div class="location-tabs">
                            <div class="location-question mr-2" [ngClass]="{ 'form-active': (isTargetTabEnabled || isAttackerTabEnabled)}" (click)="setFormEnabled('yes', null)">
                                Yes
                            </div>
                            <div class="location-question" [ngClass]="{ 'form-active': (!isTargetTabEnabled && !isAttackerTabEnabled) }" (click)="setFormEnabled('no', clearCartModal)">
                                No
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0 mt-3">
                        <ul class="nav nav-tabs" style="cursor: pointer">
                            <li class="nav-item" (click)="setTab('target')">
                                <a class="nav-link" [ngClass]="{'active': activeTab === 'target', 'disabled': !isTargetTabEnabled }">Target</a>
                            </li>
                            <li class="nav-item" (click)="setTab('attacker')">
                                <a class="nav-link" [ngClass]="{'active': activeTab === 'attacker', 'disabled': !isAttackerTabEnabled }">Attacker</a>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="activeTab === 'target'" class="d-flex mt-3 w-100">
                        <div class="col-6">
                            <div class="mb-1">
                                <span>Region
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('target_region')"></span>
                                    </span>
                                </span>
                            </div>
                            <select class="form-control w-100 mb-4 gr-input-wrapper-small" type="text" [(ngModel)]="target.region" (change)="isEnabled()" [disabled]="!isTargetTabEnabled">
                                <option disabled selected style="color: #999" value="">Select Region</option>
                                <option *ngFor="let option of regionOptions" [ngValue]="option.key">{{option.value}}</option>
                            </select>
                            <div class="mb-1">
                                <span>Country
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('target_country')"></span>
                                    </span>
                                </span>
                            </div>
                            <select class="form-control w-100 mb-4 gr-input-wrapper-small" type="text" [(ngModel)]="target.country" (change)="isEnabled()" [disabled]="!isTargetTabEnabled">
                                <option disabled selected style="color: #999" value="">Select Country</option>
                                <option *ngFor="let option of countryOptions" [ngValue]="option.key">{{option.value}}</option>
                            </select>
                            <div class="mb-1">
                                <span>Latitude
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('target_latitude')"></span>
                                    </span>
                                </span>
                            </div>
                            <div class="form-group" [ngClass]="{'mb-4': latError === ''}">
                                <!-- <label class="ml-1">Latitude:</label> -->
                                <input class="form-control w-100 gr-input-wrapper-small" type="number" [(ngModel)]="target.latitude" placeholder="Enter Latitude" (input)="isEnabled()" [disabled]="!isTargetTabEnabled">
                                <p *ngIf="latError !== ''" class="text-danger mb-4 ml-1">**{{latError}}**</p>
                            </div> 
                            <div class="mb-1">
                                <span>Longitude
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('target_longitude')"></span>
                                    </span>
                                </span>
                            </div>
                            <div class="form-group">
                                <!-- <label class="ml-1">Longitude:</label> -->
                                <input class="form-control w-100 gr-input-wrapper-small" type="number" [(ngModel)]="target.longitude" placeholder="Enter Longitude" (input)="isEnabled()" [disabled]="!isTargetTabEnabled">
                                <p *ngIf="longError !== ''" class="text-danger mb-4 ml-1">**{{longError}}**</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-1">
                                <span>Street Address
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('target_street_address')"></span>
                                    </span>
                                </span>
                            </div>
                            <input class="form-control w-100 mb-4 gr-input-wrapper-small" type="text" [(ngModel)]="target.street_address" [disabled]="!isOptionalEnabled || !isTargetTabEnabled" placeholder="Enter Street Address">
                            <div class="mb-1">
                                <span>Administrative Area
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('target_admin_area')"></span>
                                    </span>
                                </span>
                            </div>
                            <input class="form-control w-100 gr-input-wrapper-small" [ngClass]="{'mb-4': areaError === ''}" type="text" [(ngModel)]="target.administrative_area" [disabled]="!isOptionalEnabled || !isTargetTabEnabled" (input)="isEnabled()" placeholder="Enter Administrative Area">
                            <p *ngIf="areaError !== ''" class="text-danger mb-4 ml-1">**{{areaError}}**</p>
                            <div class="mb-1">
                                <span>City
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('target_city')"></span>
                                    </span>
                                </span>
                            </div>
                            <input class="form-control w-100 mb-4 gr-input-wrapper-small" type="text" [(ngModel)]="target.city" [disabled]="!isOptionalEnabled || !isTargetTabEnabled" placeholder="Enter City">
                            <div class="mb-1">
                                <span>Postal Code
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('target_postal_code')"></span>
                                    </span>
                                </span>
                            </div>
                            <input class="form-control w-100 gr-input-wrapper-small" type="text" [(ngModel)]="target.postal_code" [disabled]="!isOptionalEnabled || !isTargetTabEnabled" placeholder="Enter Postal Code">
                        </div>
                    </div>
                    <div *ngIf="activeTab === 'target'" class="d-flex w-100">
                        <div class="col-12">
                            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3 w-100">
                                <span class="w-25">Select a Target
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('select_target')"></span>
                                    </span>
                                </span>
                                <select class="form-control w-75 noborder" type="text" [(ngModel)]="currentTarget" [disabled]="!isOptionalEnabled || !isTargetTabEnabled">
                                    <option *ngFor="let option of getTargets()" [value]="option.id">{{ option.display_name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="activeTab === 'attacker'" class="d-flex mt-3 w-100">
                        <div class="col-6">
                            <div class="mb-1">
                                <span>Region
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_region')"></span>
                                    </span>
                                </span>
                            </div>
                            <select class="form-control w-100 mb-4 gr-input-wrapper-small" type="text" [(ngModel)]="attacker.region" (change)="isEnabled()" [disabled]="!isAttackerTabEnabled">
                                <option disabled selected style="color: #999" value="">Select Region</option>
                                <option *ngFor="let option of regionOptions">{{option.value}}</option>
                            </select>
                            <div class="mb-1">
                                <span>Country
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_country')"></span>
                                    </span>
                                </span>
                            </div>
                            <select class="form-control w-100 mb-4 gr-input-wrapper-small" type="text" [(ngModel)]="attacker.country" (change)="isEnabled()" [disabled]="!isAttackerTabEnabled">
                                <option disabled selected style="color: #999" value="">Select Country</option>
                                <option *ngFor="let option of countryOptions">{{option.value}}</option>
                            </select>
                            <div class="mb-1">
                                <span>Latitude
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_latitude')"></span>
                                    </span>
                                </span>
                            </div>
                            <div class="form-group" [ngClass]="{'mb-4': latError === ''}">
                                <!-- <label class="ml-1">Latitude:</label> -->
                                <input class="form-control w-100 gr-input-wrapper-small" type="number" [(ngModel)]="attacker.latitude" placeholder="Enter Latitude" (input)="isEnabled()" [disabled]="!isAttackerTabEnabled">
                                <p *ngIf="latError !== ''" class="text-danger mb-4 ml-1">**{{latError}}**</p>
                            </div>
                            <div class="mb-1">
                                <span>Longitude
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_longitude')"></span>
                                    </span>
                                </span>
                            </div>
                            <div class="form-group">
                                <!-- <label class="ml-1">Longitude:</label> -->
                                <input class="form-control w-100 gr-input-wrapper-small" type="number" [(ngModel)]="attacker.longitude" placeholder="Enter Longitude" (input)="isEnabled()" [disabled]="!isAttackerTabEnabled">
                                <p *ngIf="longError !== ''" class="text-danger mb-4 ml-1">**{{longError}}**</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-1">
                                <span>Street Address
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_street_address')"></span>
                                    </span>
                                </span>
                            </div>
                            <input class="form-control w-100 mb-4 gr-input-wrapper-small" type="text" [(ngModel)]="attacker.street_address" [disabled]="!isOptionalEnabled || !isAttackerTabEnabled" placeholder="Enter Street Address">
                            <div class="mb-1">
                                <span>Administrative Area
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_admin_area')"></span>
                                    </span>
                                </span>
                            </div>
                            <input class="form-control w-100 gr-input-wrapper-small" [ngClass]="{'mb-4': areaError === ''}" type="text" [(ngModel)]="attacker.administrative_area" [disabled]="!isOptionalEnabled || !isAttackerTabEnabled" (input)="isEnabled()" placeholder="Enter Administrative Area">
                            <p *ngIf="areaError !== ''" class="text-danger mb-4 ml-1">**{{areaError}}**</p>
                            <div class="mb-1">
                                <span>City
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_city')"></span>
                                    </span>
                                </span>
                            </div>
                            <input class="form-control w-100 mb-4 gr-input-wrapper-small" type="text" [(ngModel)]="attacker.city" [disabled]="!isOptionalEnabled || !isAttackerTabEnabled" placeholder="Enter City">
                            <div class="mb-1">
                                <span>Postal Code
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_postal_code')"></span>
                                    </span>
                                </span>
                            </div>
                            <input class="form-control w-100 gr-input-wrapper-small" type="text" [(ngModel)]="attacker.postal_code" [disabled]="!isOptionalEnabled || !isAttackerTabEnabled" placeholder="Enter Postal Code">
                        </div>
                    </div>
                    <div *ngIf="activeTab === 'attacker'" class="d-flex w-100">
                        <div class="col-12">
                            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3 w-100">
                                <span class="w-25">Select an Attacker
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('select_attacker')"></span>
                                    </span>  
                                </span>
                                <select class="form-control w-75 noborder" type="text" [(ngModel)]="currentAttacker" [disabled]="!isOptionalEnabled || !isAttackerTabEnabled">
                                    <option *ngFor="let option of getAttackers()" [value]="option.id">{{ option.display_name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width:2.5%"></div>
            </div>
            <app-guided-cart class="w-100" #guidedCart [currentComponent]="component" [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [clearCartEvent]="clearCartEmitter" [editModeEvent]="editModeEmitter" [syncCartEvent]="syncCartEmitter" [getEditItemEvent]="getEditItemEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart>
        </div>
    </div>
</div>

<ng-template #clearCartModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="publish-modal-title">
                <span>Guided Cart</span>
            </h4>
        </div>
        <div class="modal-body">
                This action will remove all items in the <b>Added Items</b> cart.
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
                <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
                Cancel
            </button>
            <button type="button" class="btn btn-sm btn-success" (click)="onConfirm()">
                <fa-icon [icon]="faCheck" class="pr-1"></fa-icon>
                Confirm
            </button>
        </div>
</ng-template>
