import { Component, OnInit } from '@angular/core';
import { faAngleUp, faAngleDown, faArrowRight, faArrowLeft, faClock, faStop, faCheck } from '@fortawesome/free-solid-svg-icons';
import { StixService } from '../stix-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FluentdService } from '../fluentd/fluentd.service';
import { environment } from 'src/environments/environment';
import { SubmissionsService } from '../submissions-service/submissions.service';
import { AnnouncementService } from '../announcement-service/announcement-service.service';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.css']
})
export class UserDataComponent implements OnInit {

  activeTab:        String = '';
  statusQueryError: String = '';
  sortKey:          String = '';
  
  isLoading:        boolean = false;

  totalStatusPages:   number = 1;
  currentStatusPage:  number = 1;
  statusRowNumber:    number = -1;

  statusQueryResults: any = {};

  statusTable:      any[] = [];
  unsortedResults:  any[] = [];
  statusIDs:        any[] = [];

  init: boolean = true;

  faArrowRight  = faArrowRight;
  faArrowLeft   = faArrowLeft;
  faAngleUp     = faAngleUp;
  faAngleDown   = faAngleDown;
  faClock       = faClock;
  faStop        = faStop;
  faCheck       = faCheck;

  constructor(public stixService: StixService, private httpClient: HttpClient, private fluentd: FluentdService, private submissions: SubmissionsService, private announcement: AnnouncementService) {
  }

  private async getSubmissions(){
    this.isLoading = true;
    this.statusQueryResults = {};
    this.unsortedResults = [];
    this.statusTable = [];
    this.statusIDs = [];
    this.sortKey = '';
    this.statusQueryError = '';

    await this.getPage();
    if(this.init && this.stixService.imxServerConnectionError !== '') this.activeTab = 'bundles';
    this.init = false;
    this.isLoading = false;
  }

  private async getSavedBundles(){
    this.isLoading = true;
    let res = await this.stixService.getSavedBundles(1, '');
    this.isLoading = false;
    if(res === false){
        // this.announcementService.show('Failed to retrieve Saved Bundles', 'There was an error in communicating to the IMX Server', 'error', false);
    }
  }

  ngOnInit(): void {
    if(history.state.activeTab){
      this.activeTab = history.state.activeTab;
    } else {
      this.setTab('submissions');
    }
  }

  public changeStatusShow(i){
    if(this.statusRowNumber === i){
      this.statusRowNumber = -1;
      this.statusQueryResults = {};
    } else {
      this.statusRowNumber = i;
      this.statusQueryResults = this.statusTable[i].res;
    }
  }

  public nextPage(direction){
    console.log(direction);
    switch(direction){
      case 'right':
        this.currentStatusPage++;
        break;
      case 'left':
        this.currentStatusPage--;
        break;
    }
    console.log(this.currentStatusPage);
    this.getSubmissions();
  }

  public setTab(tab){
    if(this.activeTab === tab) return;
    this.activeTab = tab;

    switch(tab){
      case 'submissions':
        this.getSubmissions();
        break;
      case 'bundles':
        this.getSavedBundles();
        break;
    }
  }

  public getTaxiiServer(server){
    switch(server){
      case `${environment.taxiiServer.url}`:
        return 'Default';
      case `${environment.aisTaxiiProxy.url}`:
        return 'AIS';
      default:
        return server;
    }
  }

  public sort(col: string, table: string) {
    if (this.statusTable.length === 0 && table === 'status') return;

    if (this.statusRowNumber !== null) this.statusRowNumber = null;

    if (!this.sortKey) {
        this.sortKey = '';
        this.unsortedResults = [];

        if (table === 'status'){
            for(let status of this.statusTable){
                this.unsortedResults.push(status);
            }
        }
    }

    if (this.sortKey === '') {
        if (table === 'status') this.statusTable = this.sortResultsDes(col, this.statusTable);

        this.sortKey = `${col}-des`;
    }
    else {
        let keyArr = this.sortKey.split('-');
        if (col === keyArr[0] && keyArr[1] === 'des') {
            if (table === 'status') this.statusTable = this.sortResultsAsc(col, this.statusTable);

            this.sortKey = `${col}-asc`;
        }
        else if (col === keyArr[0] && keyArr[1] === 'asc') {
            if (table === 'status') this.statusTable = this.unsortedResults;

            this.sortKey = '';
        }
        else {
            if (table === 'status') this.statusTable = this.sortResultsDes(col, this.statusTable);

            this.sortKey = `${col}-des`;
        }
    }
  }

  private sortResultsDes(col: string, tableArray: any[]) {
    switch (col) {
        case 'status':
            return tableArray.sort((a, b) =>
                this.sortToLowercase(a.res[col]) > this.sortToLowercase(b.res[col]) ? 1 :
                    this.sortToLowercase(a.res[col]) < this.sortToLowercase(b.res[col]) ? -1 : 0);
        case 'request_timestamp':
            return tableArray.sort(
                (a, b) => this.sortDate(a.res[col], b.res[col], 'des'));
        case 'created':
        case 'modified':
        case 'date_added':
        case 'version':
            return tableArray.sort(
                (a, b) => this.sortDate(a[col], b[col], 'des'));
        default:
            return tableArray.sort((a, b) =>
                this.sortToLowercase(a[col]) > this.sortToLowercase(b[col]) ? 1 :
                    this.sortToLowercase(a[col]) < this.sortToLowercase(b[col]) ? -1 : 0);
    }
  }

  private sortResultsAsc(col: string, tableArray: any[]) {
    switch (col) {
        case 'status':
            return tableArray.sort((a, b) =>
                this.sortToLowercase(a.res[col]) < this.sortToLowercase(b.res[col]) ? 1 :
                    this.sortToLowercase(a.res[col]) > this.sortToLowercase(b.res[col]) ? -1 : 0);
        case 'request_timestamp':
            return tableArray.sort(
                (a, b) => this.sortDate(a.res[col], b.res[col], 'asc'));
        case 'created':
        case 'modified':
        case 'date_added':
        case 'version':
            return tableArray.sort(
                (a, b) => this.sortDate(a[col], b[col], 'asc'));
        default:
            return tableArray.sort((a, b) =>
                this.sortToLowercase(a[col]) < this.sortToLowercase(b[col]) ? 1 :
                    this.sortToLowercase(a[col]) > this.sortToLowercase(b[col]) ? -1 : 0);
    }
  }

  private sortToLowercase(item) {
    if (typeof item === 'string') return item.toLowerCase();

    return item;
  }

  private sortDate(a: any, b: any, type: string) {

    a = new Date(a);
    b = new Date(b);

    if (type === 'asc') {
        if (!this.isValidDate(a) && !this.isValidDate(b)) return 0;
        else if (!this.isValidDate(a)) return 1;
        else if (!this.isValidDate(b)) return -1;
        else return (a < b ? 1 : a > b ? -1 : 0);
    }
    if (type === 'des') {
        if (!this.isValidDate(a) && !this.isValidDate(b)) return 0;
        else if (!this.isValidDate(a)) return 1;
        else if (!this.isValidDate(b)) return -1;
        else return (a > b ? 1 : a < b ? -1 : 0);
    }

    return 0;
  }

  private isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime());
  }

  private async getPage(){
    let page: any = await this.submissions.getPage(this.currentStatusPage);
    console.log(page);
    if(!page.hits){
      let errorCode = page.status? page.status : '0';
      let errorMessage = page.message? page.message: `Cannot connect to IMX Server at: ${environment.imxServer.url} Please create a support request using the link in the bottom right for further assistance.`
      this.stixService.imxServerConnectionError = `Error code [${errorCode}]: ${errorMessage}`;
      this.isLoading = false;
      return;
    }
    this.stixService.imxServerConnectionError = '';

    this.totalStatusPages = Math.ceil(page.total.value/15);
    for(let obj of page.hits){

      obj._source.root_collection = `${obj._source.root}/`;
      let isCollectionFound = false;
      for(let root of this.stixService.apiRoots2){
          if(obj._source.root === root.title){
              for(let col of root.collections){
                  if(obj._source.collection && col.id === obj._source.collection.id){
                      obj._source.root_collection += `${col.title}`;
                      isCollectionFound = true;
                      break;
                  }
              }
              break;
          }
      }

      if(this.stixService.apiRoots2.length === 0 || !isCollectionFound){
        if(obj._source.collection && obj._source.collection.title)  obj._source.root_collection += obj._source.collection.title;
      }

      let logInfo = {
        action: "query",
        source: 'status-bulk',
        description: 'User has attempted to query by Status ID on Taxii Server',
        status_id: obj.id,
        server_type: this.stixService.taxiiServerType,
        url: obj.url
    }

    let httpHeaders = new HttpHeaders()
      .set('Accept', 'application/taxii+json;version=2.1')
      .set('Content-Type', 'application/taxii+json;version=2.1')
      .set('Authorization', `Basic ${btoa(this.stixService.taxiiServer.username + ":" + this.stixService.taxiiServer.password)}`);

    try {
        if(obj._source.res.id !== "N/A" && obj._source.res.status === "pending" && (obj._source.taxiiServer === environment.taxiiServer.url || obj._source.taxiiServer === environment.aisTaxiiProxy.url || obj._source.taxiiServer === this.stixService.taxiiServer.url)){
            this.httpClient.get(obj._source.url, { headers: httpHeaders }).subscribe(
                (data: any) => {
                    let resp = {
                        status: 200
                    };
                    this.fluentd.logEvent(logInfo, resp);
                    obj._source.res = data;
                    this.statusTable.push(obj._source);

                    this.submissions.updateSubmission(obj);
                },
                err => {
                    let resp = {
                        status: err.status ? err.status : err
                    };
                    this.fluentd.logEvent(logInfo, resp);
                    
                    console.debug(`**Error code: ${err.status}, URL: ${err.url}**`);
                    this.statusTable.push(obj._source);
                    
                }
            );
        } else {
          if(obj._source.res.status === "pending"){
            this.announcement.show('Cannot Poll Custom Taxii Server', `Can only poll status of a Custom Taxii Server if currently connected. Try establishing a connection to the server with a red square and try again`, 'error', false);
          }
          this.statusTable.push(obj._source);
        }
        
    } catch (e) {
        console.debug(`**Error Name: ${e.name} Error Message ${e.message}**`);
        this.statusTable.push(obj._source);
    }
    }
  }

}
