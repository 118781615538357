<div class="dialog">
    <div class="modal-header">
        <h4 class="modal-title" id="publish-modal-title">
            <span>Extensions</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="onX()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="mx-auto warning-message">{{this.errorMessage}}</div>    
        </div>

        <div class="form-group mt-2">
            <div class="row">
                <div class="col">
                    <div class="input-group mb-2 float-right">
                        <span class="input-group-text">
                            <div class="tooltipright"><b>Extension ID</b>
                                <span class="toolttext">Select the ID of the extension or predefined extension (if
                                    available).<br>Each ID may only be used ONCE.<br>Definitions with no Property Extensions are
                                    excluded</span>
                            </div>
                        </span>
                        <select type="text" class="form-control" [(ngModel)]="this.currentExtension.extension_id"
                            name="extension_id" id="extension_id" (change)="getTypes()">
                            <option value=''></option>
                            <ng-container *ngFor="let obj of stixService.bundle.objects">
                                <option *ngIf="obj.id.includes('extension-definition--') && this.hasProperties(obj)"
                                    [value]="obj['id']">
                                    {{obj.name}} ({{obj.id}})
                                </option>
                            </ng-container>
                            <option *ngIf="stixService.currentType == 'file'" value="archive-ext">Archive File Extension
                                (archive-ext)</option>
                            <option *ngIf="stixService.currentType == 'file'" value="pdf-ext">PDF File Extension
                                (pdf-ext)</option>
                            <option *ngIf="stixService.currentType == 'file'" value="ntfs-ext">NTFS File Extension
                                (ntfs-ext)</option>
                            <option *ngIf="stixService.currentType == 'file'" value="raster-image-ext">Raster Image File
                                Extension (raster-image-ext)</option>
                            <option *ngIf="stixService.currentType == 'file'" value="windows-pebinary-ext">Windows PE Binary
                                File Extension (windows-pebinary-ext)</option>
                            <option *ngIf="stixService.currentType == 'network-traffic'" value="http-request-ext">HTTP Request
                                Extension (http-request-ext)</option>
                            <option *ngIf="stixService.currentType == 'network-traffic'" value="icmp-ext">ICMP Extension
                                (icmp-ext)</option>
                            <option *ngIf="stixService.currentType == 'network-traffic'" value="socket-ext">Network Socket
                                Extension (socket-ext)</option>
                            <option *ngIf="stixService.currentType == 'network-traffic'" value="tcp-ext">TCP Extension
                                (tcp-ext)</option>
                            <option *ngIf="stixService.currentType == 'process'" value="windows-process-ext">Windows Process
                                Extension (windows-process-ext)</option>
                            <option *ngIf="stixService.currentType == 'process'" value="windows-service-ext">Windows Service
                                Extension (windows-service-ext)</option>
                            <option *ngIf="stixService.currentType == 'user-account'" value="unix-account-ext">Unix Account
                                Extension (unix-account-ext)</option>
                            <option *ngIf="stixService.currentType == 'x509-certificate'" value="x509_v3_extensions">
                                X.509 V3 Extensions (x509_v3_extensions)</option>
                        </select>
                        <input type="text" class="form-control" [(ngModel)]="this.currentExtension.extension_id" (change)="getTypes()">
                        <!-- (click)="extensionModal(extensionBuilder)" -->
                        <!-- <button class="btn btn-fa-sm btn-primary config-button" type="button" 
                            [disabled]="!validID() || this.currentExtension.extension_id == '' || (!predefined && this.currentExtension.extension_type == '')">
                            <fa-icon [icon]="faPlus"></fa-icon>
                            Configure 
                        </button> -->
                    </div>
                    <ng-container *ngIf="!predefined && this.errorMessage == ''">
                        <div class="input-group mb-2">
                            <span class="input-group-text">
                                <div class="tooltipright"><b>Extension Type</b>
                                    <span class="toolttext">Extension types can currently only be configured one at a
                                        time.</span>
                                </div>
                            </span>
                            <select type="text" class="form-control" [(ngModel)]="this.currentExtension.extension_type"
                                name="extension_type" id="extension_type">
                                <ng-container *ngFor="let opt of type_options">
                                    <option [value]="opt">
                                        {{opt}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                    </ng-container>
                </div>
                <div>
                    <!-- <button class="btn btn-sm btn-primary config-button" type="button" (click)="extensionModal(extensionBuilder)"
                        [disabled]="!validID() || this.currentExtension.extension_id == '' || (!predefined && this.currentExtension.extension_type == '')">
                        Configure
                    </button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <button type="button" class="btn btn-sm btn-success" (click)="onConfirm()" [disabled]="currentExtension.extension_id === ''">
            <fa-icon [icon]="faWrench" class="pr-1"></fa-icon>
            Configure
        </button>
    </div>
</div>