<div class="dialog">
    <div class="modal-header">
        <h4 class="modal-title" id="publish-modal-title">
            <span>{{mode}} STIX Bundle</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="onX()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <!-- SAVE -->
        
        <div *ngIf="mode == 'Save'" class="pt-2">
            <ng-container>
                Your current bundle is displayed below. Choose how you would like to handle the existing
                objects:<br>
                (1) Discard the bundle entirely, (2) Merge the objects you are importing into the current
                bundle, or (3) Save the current bundle for later use.
                <br>
            </ng-container>
            <ng-container>
                <div class="row pl-3 pb-1">
                    If you would like to save your bundle, please enter a name below. If you would instead like to
                    discard/reset the bundle please confirm so.
                </div>
                <div class="row ml-0 input-border">
                    <input placeholder="Enter a name for the bundle" class="form-control"
                        style="font-size: x-large; font-weight: 400;" type="text" (input)="invalidBundleName()" [(ngModel)]="bundleName">
                </div>
                <div *ngIf="this.invalidName != ''" class="row pl-3">
                    <div style="color:red">**{{this.invalidName}}**</div>
                </div>
            </ng-container>
            <br>
            <ul class="nav nav-tabs">
                <li class="nav-item" (click)="activeTab = 'jsonViewer'">
                    <a class="nav-link active" [ngClass]="{'active': activeTab === 'jsonViewer'}">JSON</a>
                </li>
                <li *ngIf="!guidedUI" class="nav-item" (click)="activeTab = 'visualViewer'">
                    <a class="nav-link" [ngClass]="{'active': activeTab === 'visualViewer'}">Visual</a>
                </li>
            </ul>
            <pre *ngIf="activeTab === 'jsonViewer'" class="preview-bundle">{{ getStixPreview() | json }}</pre>
            <app-stix-viewer *ngIf="activeTab === 'visualViewer'" class="nav-link" [bundle]="getStixPreview()">
            </app-stix-viewer>
        </div>

        <!-- EDIT OR DELETE OR TRANSFER -->

        <div *ngIf="mode != 'Save'">
            <input [disabled]="true" class="form-control col-4 mb-1" [value]="bundleName">
            <ng-container *ngIf="mode == 'Edit'">
                Choose how you would like to handle the existing
                objects:<br>
                (1) Discard the bundle entirely or (2) Merge the objects you are importing into the current
                bundle.
                <br>
            </ng-container>
            <!-- <ul class="nav nav-tabs">
                <li class="nav-item" (click)="activeTab = 'jsonViewer'">
                    <a class="nav-link active" [ngClass]="{'active': activeTab === 'jsonViewer'}">JSON</a>
                </li>
                <li class="nav-item" (click)="activeTab = 'visualViewer'">
                    <a class="nav-link" [ngClass]="{'active': activeTab === 'visualViewer'}">Visual</a>
                </li>
            </ul>
            <pre *ngIf="activeTab === 'jsonViewer'" class="preview-bundle ">{{ getStixPreview() | json }}</pre>
            <app-stix-viewer *ngIf="activeTab === 'visualViewer'" class="nav-link" [bundle]="getStixPreview()">
            </app-stix-viewer> -->
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <ng-container *ngIf="mode == 'Save'">
            <div class="dropup">
                <button class="btn-sm btn btn-success dropdown-toggle" type="button"
                data-toggle="dropdown" aria-expanded="false" [disabled]="this.bundleName == '' || this.invalidName != ''">
                <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
                Save Bundle
                </button>
                <div class="dropdown-menu">
                <a class="dropdown-item" style="cursor: pointer" (click)="onSave('local')">Local</a>
                <a class="dropdown-item" style="cursor: pointer" (click)="onSave('server')">My Profile</a>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="mode == 'Edit'">
            <button *ngIf="!isDiscarding" type="button" class="btn btn-sm btn-danger" (click)="onDiscardBundle();">
                <fa-icon [icon]="faDiscard" class="pr-1"></fa-icon>
                Discard Bundle
            </button>
            <button *ngIf="isDiscarding" type="button" class="btn btn-sm btn-danger" [disabled]="true">
                Discarding...
            </button>
            <button *ngIf="!isMerging" type="button" class="btn btn-sm btn-primary"
                (click)="onMergeBundle();" style="width: 175px !important">
                <fa-icon [icon]="faMerge" class="pr-1"></fa-icon>
                Merge into Bundle
            </button>
            <button *ngIf="isMerging" type="button" class="btn btn-sm btn-primary"
                [disabled]="true">
                Merging...
            </button>
        </ng-container>
        <ng-container *ngIf="mode == 'Transfer'">
            <div class="dropup">
                <button class="btn-sm btn btn-success dropdown-toggle" type="button"
                data-toggle="dropdown" aria-expanded="false" [disabled]="this.bundleName == '' || this.invalidName != ''" style="width: 175px !important;">
                <fa-icon [icon]="faTransfer" class="pr-1"></fa-icon>
                Transfer Bundle
                </button>
                <div class="dropdown-menu">
                <a *ngIf="table != 'local'" class="dropdown-item" style="cursor: pointer" (click)="onTransfer('local')">Local</a>
                <a *ngIf="table != 'profile'" class="dropdown-item" style="cursor: pointer" (click)="onTransfer('server')">My Profile</a>
                </div>
            </div>
        </ng-container>
        <button *ngIf="mode == 'Delete'" type="button" class="btn btn-sm btn-danger" (click)="onDelete()">
            <fa-icon [icon]="faTrash" class="pr-1"></fa-icon>
            Delete Bundle
        </button>
    </div>
</div>