import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StixService } from 'src/app/stix-service.service';
import { faPlus, faTrash, faBan } from '@fortawesome/free-solid-svg-icons';
import { GranularMarking } from "../../models/granular-marking";
import { LANGUAGES } from "../../models/languages";
import { environment } from 'src/environments/environment';

export interface GranularMarkingData {
  selectors: [],
  lang: '',
  marking_ref: ''
}


@Component({
  selector: 'app-granular-markings-dialog',
  templateUrl: './granular-markings-dialog.component.html',
  styleUrls: ['./granular-markings-dialog.component.css']
})
export class GranularMarkingsDialogComponent implements OnInit {
  faPlus = faPlus;
  faTrash = faTrash;
  faBan = faBan

  errorMessage = '';
  currentGranularMarking = new GranularMarking();
  tlp_options;
  lang_options: any[] = LANGUAGES;
  newSelector = [];
  isAddingGranularMarkings = true;
  tlp_version = '';
  tlp_name = '';
  isTLPOne = true;

  // currentGranularMarking = new GranularMarking();
  // isAddingGranularMarkings: boolean = false;
  // newGranularMarkings: GranularMarking[] = [];
  // newSelector: any = undefined;
  // lang: any;
  // marking_ref: any;
  // errorMessage: string = '';
  // tooltip: string;

  constructor(
    public stixService: StixService,
    public dialogRef:
      MatDialogRef<GranularMarkingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
    GranularMarkingData) {
      this.errorMessage = '';
      console.log(data);
      this.currentGranularMarking = data;
      this.newSelector = data.selectors;
      if(data.marking_ref){
        let currentTlp = this.stixService.getCurrentTlp(data.marking_ref);
        this.tlp_version = currentTlp.version;
        this.getTlpOptions({target: {value: this.tlp_version}, reset: false});
        this.tlp_name = currentTlp.name

        console.log(this.tlp_options)
        console.log(this.tlp_name);
      }

    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onX(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    let result = this.addButton();

    if(result.valid === false){
      this.errorMessage = result.errorMessage;
      return;
    }

    console.log(this.currentGranularMarking);
    this.dialogRef.close(this.currentGranularMarking);
  }

  addButton(): any {
    const md_Regex = new RegExp('marking-definition--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}')
    
    if (this.currentGranularMarking.marking_ref != '') {
      if (!md_Regex.test(this.currentGranularMarking.marking_ref))
        return {
          valid: false,
          errorMessage: "Marking Reference must be formatted as marking-definition--(uuid)"
        };
    };

    this.currentGranularMarking.selectors = this.newSelector;

    return {
      valid: true
    }

  }

  getTlpOptions(event: any) {
    console.log(event);
    this.tlp_options = this.stixService.getTlpOptions(event.target.value);
    console.log(this.tlp_options);
    if(event.reset !== false) this.currentGranularMarking.marking_ref = '';
  }

  setMarkingRef() {
    if (!this.tlp_name) {
      this.currentGranularMarking.marking_ref = '';
    } else if (this.tlp_name.startsWith('marking-definition--')) {
      this.currentGranularMarking.marking_ref = this.tlp_name;
    } else {
      this.currentGranularMarking.marking_ref = this.tlp_options.filter(option => option.key === this.tlp_name)[0].value;      
    }
  }

  ngOnInit(): void {
    if (!environment.tlpConfig.enableTlpOne) {
      this.isTLPOne = false;
      this.tlp_version = 'v2';
      this.tlp_options = this.stixService.getTlpOptions(this.tlp_version); 
    }
  }

}
