<div>
    <div class="d-flex justify-content-left">
        <h4 class="modal-title pl-3 pt-3">
            {{ title }}
        </h4>
    </div>
    <hr>
    <div class="d-flex justify-content-left">
        <div class="modal-title pl-3">
            {{ description }}
        </div>
    </div>
    <div class="row">
        <div class="col text-center text-dark stix-preview-message">
            <div class="bundle-wrapper">
                <div *ngFor="let stixObject of stixObjects; let i = index;">
                    <div *ngFor="let object of stixObject.objects; let j = index;">
                        <div *ngIf="(objects | bundlefilter: object.type)?.length > 0">
                            <div class="row text-left">
                                <div class="col" id="{{getStixObjectID(object.type)}}">
                                    <table class="table">
                                        <thead>
                                           
                                            <th colspan="2" class="d-flex align-items-center">
                                                <input type="checkbox"
                                                    (change)="checkAllObjects($event)"
                                                    class="ml-3 mr-3 mt-1"
                                                />
                                                {{object.displayName}}
                                                <span class="badge rounded-pill bg-primary sdo-badge ml-1">
                                                    {{ (objects | bundlefilter: object.type
                                                    ).length
                                                    }}
                                                </span>
                                            </th>
                                        </thead>
                                        <tbody style="overflow: hidden">
                                            <div *ngIf="true">
                                            <ng-container
                                                *ngFor="let component of objects | bundlefilter: object.type; let k = index; let c = count;">
                                                <tr>
                                                    <td style="padding-left: 25px">
                                                        <input type="checkbox" [(ngModel)]="component.checked"/>
                                                    </td>
                                                    <td class="btn-link overflow"
                                                        style="padding-top:14px; width: 100vw; cursor: pointer"
                                                        id="{{getComponentId(component, i, j, k)}}"
                                                        (click)="changeShow(j, k)">
                                                        <div class="overflow">
                                                            {{getComponentDisplay(component)}} {{showRcPair(k)}}</div>
                                                    </td>
                                                </tr>
                                                <tr class="expanded-panel"
                                                    *ngIf="objnumber == j && rownumber == k">
                                                    <td colspan="2">
                                                        <div class="row pl-5" style="width: 80%">
                                                            <pre>{{ stixService.getJsonDisplayForComponent(component) | json }}</pre>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="objects?.length === 0" style="margin-top: 20%">
                    There is no duplicate object already published similar to this object.
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end mb-2 mr-3">
        <button class="btn btn-sm btn-secondary query-button mr-3" type="button" data-toggle="dropdown" aria-expanded="false"
            (click)="close()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <button class="btn btn-sm btn-success query-button" type="button" data-toggle="dropdown" aria-expanded="false"
            [disabled]="disableAddToBundle()" (click)="addToBundle()">
            <fa-icon [icon]="faAddToBundle" class="pr-1"></fa-icon>
            Update From Server
        </button>
    </div>
</div>
