import { v4 as uuid } from "uuid";
import { Observable, of } from "rxjs";
import { FormModel } from "../dynamic-form-component/form-model";
import { QuestionBase } from "../dynamic-form-component/question-base";
import { DatetimeQuestion } from "../dynamic-form-component/question-types/question-datepicker";
import { DropdownQuestion } from "../dynamic-form-component/question-types/question-dropdown";
import { TextboxQuestion } from "../dynamic-form-component/question-types/question-textbox";
import { RefArrayQuestion } from "../dynamic-form-component/question-types/question-ref-array";
import { IdentityQuestion } from "../dynamic-form-component/question-types/question-identity";
import { SCORefsQuestion } from "../dynamic-form-component/question-types/question-sco-refs";
import { TooltipTextboxQuestion } from "../dynamic-form-component/question-types/question-tooltip-textbox";
import { ReferenceArrayQuestion } from "../dynamic-form-component/question-types/question-reference-array";
import { ExternalReference } from "./external-reference";
import { GranularMarking } from "./granular-marking";
import { Extension } from "./extension";
import { StringArrayQuestion } from "../dynamic-form-component/question-types/question-string-array";
import { StixService } from "../stix-service.service";
import { LANGUAGES } from "./languages";
import { SCO_LIST } from "./sco_list";
import { Content } from "./content";
import { Window } from "./window";

export class MalwareAnalysis extends FormModel {

    type?: string;
    id?: string;
    spec_version?: string;
    created?: string;
    modified?: string;
    created_by_ref?: string;
    name?: string;
    description?: string;
    revoked?: boolean;
    confidence?: number;
    lang?: string;
    product?: string;
    version?: string;
    host_vm_ref?: string;
    operating_system_ref?: string;
    installed_software_refs?: string[];
    configuration_version?: string;
    modules?: string[];
    analysis_engine_version?: string;
    analysis_definition_version?: string;
    submitted?: string;
    analysis_started?: string;
    analysis_ended?: string;
    result_name?: string;
    result?: string;
    analysis_sco_refs?: string[];
    sample_refs?: string[];
    external_references?: ExternalReference[];
    object_marking_refs?: string[];
    granular_markings?: GranularMarking[];
    extensions?: Extension[];
    labels?: string[];
    loaded?: boolean = false;

    constructor(
        public stixService: StixService,
        type?: string | '',
        id?: string | '',
        spec_version?: string | '',
        created?: string | '',
        modified?: string | '',
        created_by_ref?: string | '',
        name?: string | '',
        description?: string | '',
        revoked?: boolean,
        confidence?: number,
        lang?: string | '',
        product?: string | '',
        version?: string | '',
        host_vm_ref?: string | '',
        operating_system_ref?: string | '',
        installed_software_refs?: string[] | [],
        configuration_version?: string | '',
        modules?: string[] | [],
        analysis_engine_version?: string | '',
        analysis_definition_version?: string | '',
        submitted?: string | '',
        analysis_started?: string | '',
        analysis_ended?: string | '',
        result_name?: string | '',
        result?: string | '',
        analysis_sco_refs?: string[] | [],
        sample_refs?: string[] | [],
        external_references?: ExternalReference[] | [],
        object_marking_refs?: string[] | [],
        granular_markings?: GranularMarking[] | [],
        extensions?: Extension[] | [],
        labels?: string[] | [],
    ) {
        super();
        this.type = type;
        this.id = id;
        this.spec_version = spec_version;
        this.created = created;
        this.modified = modified;
        this.created_by_ref = created_by_ref;
        this.name = name;
        this.description = description;
        this.revoked = revoked;
        this.labels = labels;
        this.confidence = confidence;
        this.lang = lang;
        this.external_references = external_references;
        this.object_marking_refs = object_marking_refs;
        this.granular_markings = granular_markings;
        this.extensions = extensions;
        this.product = product;
        this.version = version;
        this.host_vm_ref = host_vm_ref;
        this.operating_system_ref = operating_system_ref;
        this.installed_software_refs = installed_software_refs;
        this.configuration_version = configuration_version;
        this.modules = modules;
        this.analysis_engine_version = analysis_engine_version;
        this.analysis_definition_version = analysis_definition_version;
        this.submitted = submitted;
        this.analysis_started = analysis_started;
        this.analysis_ended = analysis_ended;
        this.result = result;
        this.analysis_sco_refs = analysis_sco_refs;
        this.result_name = result_name;
        this.sample_refs = sample_refs;
    }

    getExternalReferences(): ExternalReference[] {
        return this.external_references || [];
    }

    getGranularMarkings(): GranularMarking[] {
        return this.granular_markings || [];
    }
    getExtensions(): Extension[] {
        return this.extensions || [];
    }
    getWindows(): Window[] {
        return [];
    }

    getQuestions(): QuestionBase<any>[] {
        let questions: QuestionBase<any>[] = [
            new TextboxQuestion({
                key: 'type',
                label: 'Type',
                value: 'malware-analysis',
                required: true,
                order: 1,
                type: 'text',
                readonly: true,
                columnWidth: 'col-2 type'
            }),
            new TextboxQuestion({
                key: 'id',
                label: 'ID',
                value: this.id || `malware-analysis--${uuid()}`,
                validatorFn: (componentData: any) => {
                    const id = componentData.id;
                    const idRegex = new RegExp('^malware-analysis--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$')
                    if (!idRegex.test(id))
                        return {
                            valid: false,
                            errorMessage: "Must begin with 'malware-analysis--' and followed by a UUID (i.e. malware-analysis--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true,
                    };
                },
                required: true,
                order: 2,
                type: 'text',
                readonly: true,
                columnWidth: 'col-4 id'
            }),
            new TextboxQuestion({
                key: 'spec_version',
                label: 'Spec Ver.',
                value: '2.1',
                readonly: true,
                columnWidth: 'col-1 spec-version',
                required: true,
                order: 3
            }),
            new DatetimeQuestion({
                key: 'created',
                label: 'Created*',
                columnWidth: 'w-20 created',
                required: true,
                order: 4
            }),
            new DatetimeQuestion({
                key: 'modified',
                label: 'Modified*',
                validatorFn: (componentData: any) => {
                    // Check whether Modified Date is equal or after to Created Date                    
                    var createdDateTime = Date.parse(componentData['created']);
                    var modifiedDateTime = Date.parse(componentData['modified']);

                    if (modifiedDateTime && modifiedDateTime < createdDateTime
                        && !this.stixService.newObject) {
                        return {
                            valid: false,
                            errorMessage: "Modified date must be after created date."
                        };
                    }

                    return {
                        valid: true
                    };

                },
                columnWidth: 'w-20 modified',
                required: true,
                order: 5
            }),
            new TextboxQuestion({
                key: 'confidence',
                label: 'Confidence',
                validatorFn: (componentData: any) => {
                    const confidence = componentData.confidence;
                    const confidenceRegex = new RegExp('^(?:100|[1-9]?[0-9])$')
                    if (confidence) {
                        if (!confidenceRegex.test(confidence))
                            return {
                                valid: false,
                                errorMessage: "Confidence value must be an integer in the range of 0-100"
                            };
                    };
                    return {
                        valid: true,
                    };
                },
                columnWidth: 'col-2 confidence-2',
                order: 6,
                required: false,
                type: 'number',
            }),
            new IdentityQuestion({
                key: 'created_by_ref',
                label: 'Created By Ref',
                validatorFn: (componentData: any) => {
                    const created_by_ref = componentData.created_by_ref;
                    const created_by_refRegex = new RegExp('identity--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}')
                    if (created_by_ref != '') {
                        if (!created_by_refRegex.test(created_by_ref))
                            return {
                                valid: false,
                                errorMessage: "Must begin with 'identity--' and followed by a UUID (i.e. identity--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                            };
                    }
                    return {
                        valid: true,
                    };
                },
                relString: this.created_by_ref,
                order: 8,
                required: false,
                columnWidth: 'col-6 created-by-ref-2',
            }),
            new DropdownQuestion({
                key: 'lang',
                label: 'Language',
                options: LANGUAGES,
                order: 7,
                required: false,
                columnWidth: 'col-4 language-2',
            }),
            new DropdownQuestion({
                key: 'revoked',
                label: 'Revoked',
                options: [
                    { key: 'true', value: 'True' },
                    { key: 'false', value: 'False' },
                ],
                columnWidth: 'col-6',
                order: 9
            }),
            new TooltipTextboxQuestion({
                key: 'product',
                label: 'Product*',
                validatorFn: (componentData: any) => {
                    const product = componentData.product;
                    if (!product || product == '') {
                        return {
                            valid: false
                        };
                    };
                    questions.find((i) => i.key == "product").relString = componentData.product || '';
                    return {
                        valid: true
                    };
                },
                relString: '',
                tooltip: "Product SHOULD be all lowercase, separated by hypens; \n'anonymized' MUST be used if Product can not be specified",
                columnWidth: 'col-6',
                order: 10,
                required: true
            }),
            new TextboxQuestion({
                key: 'version',
                label: 'Version',
                order: 11,
                required: false,
                columnWidth: 'col-6'
            }),
            new TextboxQuestion({
                key: 'host_vm_ref',
                label: 'Host VM Ref',
                validatorFn: (componentData: any) => {
                    const host_vm_ref = componentData.host_vm_ref;
                    const host_vm_refRegex = new RegExp(/^software--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
                    if (host_vm_ref && host_vm_ref != '') {
                        if (!host_vm_refRegex.test(host_vm_ref))
                            return {
                                valid: false,
                                errorMessage: "Must be the identifier for a 'software' object (i.e. software--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                            };
                    };
                    return {
                        valid: true,
                    };
                },
                order: 12,
                required: false,
                columnWidth: 'col-6'
            }),
            new TextboxQuestion({
                key: 'operating_system_ref',
                label: 'Operating System Ref',
                validatorFn: (componentData: any) => {
                    const operating_system_ref = componentData.operating_system_ref;
                    const operating_system_refRegex = new RegExp(/^software--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);

                    if (operating_system_ref && operating_system_ref != '') {
                        if (!operating_system_refRegex.test(operating_system_ref))
                            return {
                                valid: false,
                                errorMessage: "Must be the identifier for a 'software' object (i.e. software--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                            };
                    };
                    return {
                        valid: true,
                    };
                },
                order: 13,
                required: false,
                columnWidth: 'col-6'
            }),
            new TextboxQuestion({
                key: 'configuration_version',
                label: 'Configuration Version',
                order: 14,
                required: false,
                columnWidth: 'col-6'
            }),
            new TextboxQuestion({
                key: 'analysis_engine_version',
                label: 'Analysis Engine Version',
                order: 15,
                required: false,
                columnWidth: 'col-6'
            }),
            new TextboxQuestion({
                key: 'analysis_definition_version',
                label: 'Analysis Definition Version',
                order: 16,
                required: false,
                columnWidth: 'col-6'
            }),
            new DatetimeQuestion({
                key: 'submitted',
                label: 'Submitted',
                columnWidth: 'col-6',
                required: false,
                order: 17
            }),
            new TextboxQuestion({
                key: 'result_name',
                label: 'Result Name',
                order: 18,
                required: false,
                columnWidth: 'col-6'
            }),
            new DatetimeQuestion({
                key: 'analysis_started',
                label: 'Analysis Started',
                validatorFn: (componentData: any) => {
                    const analysis_started = componentData.analysis_started;
                    if (analysis_started && analysis_started == '') {
                        return {
                            valid: false,
                        };
                    };
                    return {
                        valid: true,
                    };
                },
                columnWidth: 'col-6',
                order: 19
            }),
            new DatetimeQuestion({
                key: 'analysis_ended',
                label: 'Analysis Ended',
                validatorFn: (componentData: any) => {
                    // Check whether Modified Date is equal or after to Created Date                    
                    const startedDateTime = Date.parse(componentData['analysis_started']);
                    const endedDateTime = Date.parse(componentData['analysis_ended']);

                    if (startedDateTime && endedDateTime < startedDateTime) {
                        return {
                            valid: false,
                            errorMessage: "Analysis Ended must be after Analysis Started date."
                        };
                    }

                    return {
                        valid: true
                    };
                },
                columnWidth: 'col-6',
                order: 20
            }),            
            new RefArrayQuestion({
                key: 'sample_refs',
                label: 'Sample Refs',
                validatorFn: (componentData: any) => {
                    const sample_refs = componentData.sample_refs;
                    const sample_refsRegex = new RegExp(/^(file|network-traffic|artifact)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);
                    if (sample_refs != '' && !sample_refsRegex.test(sample_refs))
                        return {
                            valid: false,
                            errorMessage: "Must be the identifier for a 'file' or 'artifact' object (i.e. file--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true,
                    };
                },
                value: new Array(),
                columnWidth: 'col-6',
                order: 21,
                allowedRefsMap: ["file", "artifact", "network-traffic"],
                allowedRefs: ["file", "artifact", "network-traffic"]
            }),
            new DropdownQuestion({
                key: 'result',
                label: 'Result',
                validatorFn: (componentData: any) => {
                    const result = componentData.result;
                    if ((!result || result == '') && ((this.stixService.stringArrays.get("analysis_sco_refs") || []).length == 0))
                        return {
                            valid: false,
                            errorMessage: "Result or Analysis SCO Refs is required"
                        };
                    return {
                        valid: true,
                    };
                },
                options: [
                    { key: 'malicious', value: 'Malicious' },
                    { key: 'suspicious', value: 'Suspicious' },
                    { key: 'benign', value: 'Benign' },
                    { key: 'unknown', value: 'Unknown' }
                ],
                required: false,
                columnWidth: 'col-6',
                order: 22
            }),
            // new StringArrayQuestion({
            //     key: 'labels',
            //     label: 'Labels',
            //     value: new Array(),
            //     columnWidth: 'col-6',
            //     order: 24
            // }),
            new SCORefsQuestion({
                key: 'analysis_sco_refs',
                label: 'Analysis Sco Refs',
                validatorFn: (componentData: any) => {
                    const result = componentData.result[0];
                    const analysis_sco_refs = componentData.analysis_sco_refs;
                    const regex = new RegExp(/^(\w[-[a-z0-9]+]*)--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$/);

                    if (analysis_sco_refs && analysis_sco_refs != '') {
                        const matches = analysis_sco_refs.match(regex);
                        if (!matches || SCO_LIST.indexOf(matches[1]) == -1)
                            return {
                                valid: false,
                                errorMessage: "Must match id format [valid SCO object type]--[UUID]"
                            };
                    } else if (((this.stixService.stringArrays.get("analysis_sco_refs") || []).length == 0) && ((result || '').length == 0))
                        return {
                            valid: false,
                            errorMessage: "Result or Analysis SCO Refs is required"
                        };
                    return {
                        valid: true
                    };
                },
                required: false,
                value: new Array(),
                columnWidth: 'col-6',
                order: 23
            }),
            new StringArrayQuestion({
                key: 'modules',
                label: 'Modules',
                value: new Array(),
                columnWidth: 'col-6',
                order: 25,
                marginRight: true
            }),
            new ReferenceArrayQuestion({
                key: 'installed_software_refs',
                label: 'Installed Software Refs',
                validatorFn: (componentData: any) => {
                    const installed_software_refs = componentData.installed_software_refs;
                    const installed_software_refsRegex = new RegExp('^software--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$')
                    if (installed_software_refs && installed_software_refs != '') {
                        if (!installed_software_refsRegex.test(installed_software_refs))
                            return {
                                valid: false,
                                errorMessage: "Must be the identifier for a 'software' object (i.e. software--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                            };
                    };
                    return {
                        valid: true,
                    };
                },
                value: new Array(),
                columnWidth: 'col-6',
                order: 26
            }),
        ];

        return questions.sort((a, b) => a.order - b.order);
    }

    getContents(): Content[] {
        return [];
    }

    hasContents(): boolean {
        return false;
    }

    hasWindows(): boolean {
        return false;
    }

    hasExternalReferences(): boolean {
        return true;
    }

    hasGranularMarkings(): boolean {
        return true;
    }

    hasExtensions(): boolean {
        return true;
    }

    hasObjectMarkingReferences(): boolean {
        return true;
    }

    populateFromJSON(componentData: any, stixService: StixService): void {
        this.type = componentData.type;
        this.id = componentData.id;
        this.created_by_ref = componentData.created_by_ref;
        this.spec_version = componentData.spec_version;
        this.name = componentData.name;
        this.description = componentData.description;
        this.confidence = parseInt(componentData.confidence) || undefined;
        this.created = componentData.created;
        this.modified = componentData.modified;
        this.revoked = JSON.parse(componentData.revoked[0] || '""');
        this.lang = componentData.lang[0];
        this.external_references = componentData.external_references;
        this.labels = stixService.stringArrays.get("labels") || [];
        this.object_marking_refs = componentData.object_marking_refs;
        this.granular_markings = componentData.granular_markings;
        this.extensions = componentData.extensions;
        this.product = componentData.product;
        this.version = componentData.version;
        this.host_vm_ref = componentData.host_vm_ref;
        this.operating_system_ref = componentData.operating_system_ref;
        this.installed_software_refs = stixService.stringArrays.get("installed_software_refs") || [];
        this.configuration_version = componentData.configuration_version;
        this.modules = stixService.stringArrays.get("modules") || [];
        this.analysis_engine_version = componentData.analysis_engine_version;
        this.analysis_definition_version = componentData.analysis_definition_version;
        this.submitted = componentData.submitted;
        this.analysis_started = componentData.analysis_started;
        this.analysis_ended = componentData.analysis_ended;
        this.result_name = componentData.result_name;
        this.result = componentData.result[0];
        this.analysis_sco_refs = stixService.stringArrays.get("analysis_sco_refs") || [];        
        this.sample_refs = stixService.stringArrays.get("sample_refs") || [];
    }

    setContents(newContents: Content[]): void {
        // N/a
    }
    setWindows(newWindows: Window[]): void {
        // N/a
    }
    setExternalReferences(newExternalReferences: ExternalReference[]): void {
        this.external_references = newExternalReferences;
    }

    setGranularMarkings(newGranularMarkings: GranularMarking[]): void {
        this.granular_markings = newGranularMarkings;
    }

    setExtensions(newExtensions: Extension[]): void {
        this.extensions = newExtensions;
    }
    setObjectMarkingRefs(newObjectMarkingRefs: string[]): void {
        this.object_marking_refs = newObjectMarkingRefs;
    }
}