import { v5 as uuid } from "uuid";
import { FormModel } from "../dynamic-form-component/form-model";
import { QuestionBase } from "../dynamic-form-component/question-base";
import { TextboxQuestion } from "../dynamic-form-component/question-types/question-textbox";
import { DropdownQuestion } from "../dynamic-form-component/question-types/question-dropdown";
import { RefArrayQuestion } from "../dynamic-form-component/question-types/question-ref-array";
import { StixService } from "../stix-service.service";
import { ExternalReference } from "./external-reference";
import { GranularMarking } from "./granular-marking";
import { Extension } from "./extension";
// import { Value } from "./value";
// import { X509V3Extension } from "./x509-v3-extension";
import { Content } from "./content";
import { Window } from "./window";

export class Ipv4Addr extends FormModel {
    type?: string;
    id?: string;
    spec_version?: string;
    object_marking_refs?: string[];
    granular_markings?: GranularMarking[];
    extensions?: Extension[];
    value?: string;
    defanged?: boolean;
    resolves_to_refs?: string[];
    belongs_to_refs?: string[];

    constructor(
        public stixService: StixService,
        type?: string | '',
        id?: string | '',
        spec_version?: string | '',
        object_marking_refs?: string[] | [],
        granular_markings?: GranularMarking[] | [],
        extensions?: Extension[] | [],
        value?: string | '',
        defanged?: boolean,
        resolves_to_refs?: string[] | [],
        belongs_to_refs?: string[] | []
    ) {
        super();
        this.type = type;
        this.id = id;
        this.spec_version = spec_version;
        this.object_marking_refs = object_marking_refs;
        this.granular_markings = granular_markings;
        this.extensions = extensions;
        this.value = value;
        this.defanged = defanged;
        this.resolves_to_refs = resolves_to_refs;
        this.belongs_to_refs = belongs_to_refs;
    }

    getWindows(): Window[] {
        return [];
    }

    // getX509V3Extensions(): X509V3Extension[] {
    //     return [];
    // }

    getExternalReferences(): ExternalReference[] {
        return [];
    }

    getContents(): Content[] {
        return [];
    }

    getGranularMarkings(): GranularMarking[] {
        return this.granular_markings || [];
    }
    getExtensions(): Extension[] {
        return this.extensions || [];
    }

    getQuestions(): QuestionBase<any>[] {
        let questions: QuestionBase<any>[] = [
            new TextboxQuestion({
                key: 'type',
                label: 'Type',
                value: 'ipv4-addr',
                required: true,
                order: 1,
                type: 'text',
                readonly: true,
                columnWidth: 'col-3'
            }),
            new TextboxQuestion({
                key: 'id',
                label: 'ID',
                value: `ipv4-addr--`,
                required: true,
                order: 2,
                type: 'text',
                readonly: true,
                columnWidth: 'col-7'
            }),
            new TextboxQuestion({
                key: 'spec_version',
                label: 'Spec Ver.',
                value: '2.1',
                readonly: true,
                columnWidth: 'col-2',
                required: true,
                order: 3
            }),
            new TextboxQuestion({
                key: 'value',
                label: 'Value*',
                validatorFn: (componentData: any) => {
                    const value = componentData.value;
                    const ipv4Regex = '(((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])[.]){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]))';
                    const valueRegex = new RegExp('^((' + ipv4Regex + '([/](3[0-2]|2[0-9]|[1-9]))?)|((' + ipv4Regex + '[/](3[0-2]|2[0-9]|[1-9]))+))$');
                    if (componentData.defanged != 'true' && value && !valueRegex.test(value))
                        return {
                            valid: false,
                            errorMessage: "Value must be a valid IPv4 address"
                        };
                    return {
                        valid: true
                    };

                },
                columnWidth: 'col-6',
                required: true,
                order: 4
            }),
            new DropdownQuestion({
                key: 'defanged',
                label: 'Defanged',
                type: 'boolean',
                options: [
                    { key: 'true', value: 'True' },
                    { key: 'false', value: 'False' },
                ],
                columnWidth: 'col-6',
                order: 5
            }),
            new RefArrayQuestion({
                key: 'belongs_to_refs',
                label: 'Belongs to References',
                validatorFn: (componentData: any) => {
                    const btr = componentData.belongs_to_refs;
                    const btrRegex = new RegExp('^autonomous-system--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$')
                    if (btr != '' && !btrRegex.test(btr))
                        return {
                            valid: false,
                            errorMessage: "Must begin with 'autonomous-system--' and followed by a UUID (i.e. autonomous-system--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true
                    };
                },
                value: new Array(),
                columnWidth: 'col-6',
                order: 6,
                allowedRefsMap: ["autonomous-system"],
                allowedRefs: ["autonomous-system"],
                marginRight: true
            }),
            new RefArrayQuestion({
                key: 'resolves_to_refs',
                label: 'Resolves to References',
                validatorFn: (componentData: any) => {
                    const rtr = componentData.resolves_to_refs;
                    const rtrRegex = new RegExp('^mac-addr--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$')
                    if (rtr != '' && !rtrRegex.test(rtr))
                        return {
                            valid: false,
                            errorMessage: "Must begin with 'mac-addr--' and followed by a UUID (i.e. mac-addr--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true
                    };
                },
                value: new Array(),
                columnWidth: 'col-6',
                order: 7,
                allowedRefsMap: ["mac-addr"],
                allowedRefs: ["mac-addr"],
            })
        ];

        return questions.sort((a, b) => a.order - b.order);
    }

    hasX509V3Extensions(): boolean {
        return false;
    }

    hasWindows(): boolean {
        return false;
    }

    hasContents(): boolean {
        return false;
    }

    hasExternalReferences(): boolean {
        return false;
    }

    hasGranularMarkings(): boolean {
        return true;
    }

    hasExtensions(): boolean {
        return true;
    }

    hasObjectMarkingReferences(): boolean {
        return true;
    }

    populateFromJSON(componentData: any, stixService: StixService): void {
        this.type = componentData.type;
        this.id = this.genUUIDv5(componentData.type, componentData);
        this.spec_version = componentData.spec_version;
        this.object_marking_refs = componentData.object_marking_refs;
        this.granular_markings = componentData.granular_markings;
        this.extensions = componentData.extensions;
        this.value = componentData.value;
        this.defanged = JSON.parse(componentData.defanged[0] || '""');
        this.resolves_to_refs = stixService.stringArrays.get("resolves_to_refs") || [];
        this.belongs_to_refs = stixService.stringArrays.get("belongs_to_refs") || [];
    }

    genUUIDv5(id: string, componentData: any): any {
        id = id + '--' + this.stixService.getUUIDFrIdContributingProperties(componentData);
        return id;
    }

    setExternalReferences(newExternalReferences: ExternalReference[]): void {
        // N/a
    }

    setContents(newContents: Content[]): void {
        // N/a
    }

    setGranularMarkings(newGranularMarkings: GranularMarking[]): void {
        this.granular_markings = newGranularMarkings;
    }

    setExtensions(newExtensions: Extension[]): void {
        this.extensions = newExtensions;
    }

    setObjectMarkingRefs(newObjectMarkingRefs: string[]): void {
        this.object_marking_refs = newObjectMarkingRefs;
    }

    setWindows(newWindows: Window[]): void {
        // N/a
    }
}