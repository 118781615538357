import { v4 as uuid } from "uuid";
import { Observable, of } from "rxjs";
import { FormModel } from "../dynamic-form-component/form-model";
import { QuestionBase } from "../dynamic-form-component/question-base";
import { DatetimeQuestion } from "../dynamic-form-component/question-types/question-datepicker";
import { DropdownQuestion } from "../dynamic-form-component/question-types/question-dropdown";
import { StringArrayQuestion } from "../dynamic-form-component/question-types/question-string-array";
import { TextboxQuestion } from "../dynamic-form-component/question-types/question-textbox";
import { IdentityQuestion } from "../dynamic-form-component/question-types/question-identity"
import { KillChainQuestion } from "../dynamic-form-component/question-types/question-kill-chain";
import { PhaseNameQuestion } from "../dynamic-form-component/question-types/question-phase-name";
import { StixService } from "../stix-service.service";
import { ExternalReference } from "./external-reference";
import { GranularMarking } from "./granular-marking";
import { Extension } from "./extension";
import { LANGUAGES } from "./languages";
import { Content } from "./content";
import { Window } from "./window";

export class AttackPattern extends FormModel {

    type?: string;
    id?: string;
    spec_version?: string;
    created_by_ref?: string;
    name?: string;
    description?: string;
    aliases?: string[];
    labels?: string[];
    confidence?: number;
    lang?: string;
    kill_chain_phases?: any[];
    kill_chain_name?: string;
    phase_name?: string;
    external_references?: ExternalReference[];
    object_marking_refs?: string[];
    granular_markings?: GranularMarking[];
    extensions?: Extension[];
    created?: string;
    modified?: string;
    revoked?: boolean;
    loaded?: boolean = false;

    constructor(
        public stixService: StixService,
        type?: string | '',
        id?: string | '',
        spec_version?: string | '',
        created_by_ref?: string | '',
        name?: string | '',
        description?: string | '',
        aliases?: string[] | [],
        labels?: string[] | [],
        confidence?: number,
        lang?: string | '',
        kill_chain_phases?: any[] | [],
        kill_chain_name?: string | '',
        phase_name?: string | '',
        external_references?: ExternalReference[] | [],
        object_marking_refs?: string[] | [],
        granular_markings?: GranularMarking[] | [],
        extensions?: Extension[] | [],
        created?: string | '',
        modified?: string | '',
        revoked?: boolean,
    ) {
        super();
        this.type = type;
        this.id = id;
        this.spec_version = spec_version;
        this.name = name;
        this.description = description;
        this.aliases = aliases;
        this.created_by_ref = created_by_ref;
        this.labels = labels;
        this.confidence = confidence;
        this.lang = lang;
        this.kill_chain_name = kill_chain_name;
        this.phase_name = phase_name;
        this.external_references = external_references;
        this.object_marking_refs = object_marking_refs;
        this.granular_markings = granular_markings;
        this.extensions = extensions;
        this.created = created;
        this.modified = modified;
        this.revoked = revoked;
        this.kill_chain_phases = [];
    }

    getExternalReferences(): ExternalReference[] {
        return this.external_references || [];
    }
    getContents(): Content[] {
        return [];
    }
    getGranularMarkings(): GranularMarking[] {
        return this.granular_markings || [];
    }
    getExtensions(): Extension[] {
        return this.extensions || [];
    }
    getWindows(): Window[] {
        return [];
    }


    getQuestions(): QuestionBase<any>[] {
        let questions: QuestionBase<any>[] = [
            new TextboxQuestion({
                key: 'type',
                label: 'Type',
                value: 'attack-pattern',
                required: true,
                order: 1,
                type: 'text',
                readonly: true,
                columnWidth: 'col-2 type'
            }),
            new TextboxQuestion({
                key: 'id',
                label: 'ID',
                value: `attack-pattern--${uuid()}`,
                validatorFn: (componentData: any) => {
                    const id = componentData.id;
                    const idRegex = new RegExp('^attack-pattern--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}$')
                    if (!idRegex.test(id))
                        return {
                            valid: false,
                            errorMessage: "Must begin with 'attack-pattern--' and followed by a UUID (i.e. opinion--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                        };
                    return {
                        valid: true
                    };
                },
                required: true,
                order: 2,
                type: 'text',
                readonly: true,
                columnWidth: 'col-4 id'
            }),
            new TextboxQuestion({
                key: 'spec_version',
                label: 'Spec Ver.',
                value: '2.1',
                readonly: true,
                columnWidth: 'col-1 spec-version',
                required: true,
                order: 3
            }),
            new DatetimeQuestion({
                key: 'created',
                label: 'Created*',
                columnWidth: 'w-20 created',
                required: true,
                order: 4
            }),
            new DatetimeQuestion({
                key: 'modified',
                label: 'Modified*',
                validatorFn: (componentData: any) => {
                    // Check whether Modified Date is equal or after to Created Date                    
                    var createdDateTime = Date.parse(componentData['created']);
                    var modifiedDateTime = Date.parse(componentData['modified']);

                    if (modifiedDateTime && modifiedDateTime < createdDateTime
                        && !this.stixService.newObject) {
                        return {
                            valid: false,
                            errorMessage: "Modified date must be equal or after created date."
                        };
                    }

                    return {
                        valid: true
                    };
                },
                columnWidth: 'w-20 modified',
                required: true,
                order: 5
            }),
            new TextboxQuestion({
                key: 'name',
                label: 'Name*',
                order: 6,
                required: true,
                columnWidth: 'col-3 name'
            }),
            new TextboxQuestion({
                key: 'description',
                label: 'Description',
                order: 7,
                required: false,
                columnWidth: 'col-12'
            }),
            new TextboxQuestion({
                key: 'confidence',
                label: 'Confidence',
                validatorFn: (componentData: any) => {
                    const confidence = componentData.confidence;
                    const confidenceRegex = new RegExp('^(?:100|[1-9]?[0-9])$')
                    if (confidence) {              
                        if (!confidenceRegex.test(confidence))
                            return {
                                valid: false,
                                errorMessage: "Confidence value must be an integer in the range of 0-100"
                            };
                    };
                    return {
                        valid: true,
                    };
                },
                columnWidth: 'col-2 confidence',
                order: 8,
                required: false,
                type: 'number',
            }),
            new DropdownQuestion({
                key: 'revoked',
                label: 'Revoked',
                type: 'boolean',
                options: [
                    { key: 'true', value: 'True' },
                    { key: 'false', value: 'False' },
                ],
                columnWidth: 'col-6',
                order: 9
            }),
            new DropdownQuestion({
                key: 'lang',
                label: 'Language',
                options: LANGUAGES,
                order: 10,
                required: false,
                columnWidth: 'col-3 language'
            }),
            new IdentityQuestion({
                key: 'created_by_ref',
                label: 'Created By Ref',
                validatorFn: (componentData: any) => {
                    const created_by_ref = componentData.created_by_ref;
                    const created_by_refRegex = new RegExp('identity--[0-9a-f]{8}\-[0-9a-f]{4}\-[45][0-9a-f]{3}\-[89ab][0-9a-f]{3}\-[0-9a-f]{12}')
                    if (created_by_ref != '') {
                        if (!created_by_refRegex.test(created_by_ref))
                            return {
                                valid: false,
                                errorMessage: "Must begin with 'identity--' and followed by a UUID (i.e. identity--d9fc3f18-80c9-4a40-a4fc-8a6aca45c20e)"
                            };
                    }
                    return {
                        valid: true,
                    };
                },
                relString: this.created_by_ref,
                order: 11,
                required: false,
                columnWidth: 'col-4 created-by-ref'
            }),      
            new PhaseNameQuestion({
                key: 'phase_name',
                label: 'Phase Name',
                columnWidth: 'col-6',
                order: 25,
                relString: this.kill_chain_name,
            }),
            new KillChainQuestion({
                key: 'kill_chain_name',
                label: 'Kill Chain Name',
                validatorFn: (componentData: any) => {
                    let phase_name = componentData.phase_name;
                    let kill_chain_name = componentData.kill_chain_name;
                    
                    if (this.stixService.kill_chainTrack){
                        questions.find((i) => i.key == "kill_chain_name").value = '';
                        this.stixService.kill_chainTrack = false;
                    }
                    if (kill_chain_name === undefined || kill_chain_name === '') {
                        questions.find((i) => i.key == "phase_name").relString = '';
                        questions.find((i) => i.key == "phase_name").arrOptions = [];
                    } else {
                        questions.find((i) => i.key == "phase_name").relString = kill_chain_name;
                        questions.find((i) => i.key == "phase_name").arrOptions = [
                            'reconnaissance',
                            'weaponization',
                            'delivery',
                            'exploitation',
                            'installation',
                            'command-and-control',
                        ];
                    }
                    return {
                        valid: true
                    };
                },
                columnWidth: 'col-6',
                order: 24,
                relString: this.phase_name,
            }),

            // new StringArrayQuestion({
            //     key: 'labels',
            //     label: 'Labels',
            //     value: new Array(),
            //     columnWidth: 'col-6',
            //     order: 14,
            //     marginRight: true
            // }),
            new StringArrayQuestion({
                key: 'aliases',
                label: 'Aliases',
                value: new Array(),
                columnWidth: 'col-12',
                order: 15
            }),
        ];

        return questions.sort((a, b) => a.order - b.order);
    }

    hasX509V3Extensions(): boolean {
        return false;
    }

    hasWindows(): boolean {
        return false;
    }

    hasContents(): boolean {
        return false;
    }

    hasExternalReferences(): boolean {
        return true;
    }

    hasGranularMarkings(): boolean {
        return true;
    }

    hasExtensions(): boolean {
        return true;
    }

    hasObjectMarkingReferences(): boolean {
        return true;
    }

    populateFromJSON(componentData: any, stixService: StixService): void {
        this.type = componentData.type;
        this.id = componentData.id;
        this.spec_version = componentData.spec_version;
        this.name = componentData.name;
        this.description = componentData.description;
        this.aliases = stixService.stringArrays.get("aliases") || [];
        this.created_by_ref = componentData.created_by_ref;
        this.labels = stixService.stringArrays.get("labels") || [];
        this.confidence = parseInt(componentData.confidence) || undefined;
        this.lang = componentData.lang[0];
        this.kill_chain_phases = [];

        if ((stixService.stringArrays.get('killChain') || []).length > 0) {
            this.kill_chain_phases = [];
            for (let x in stixService.stringArrays.get('killChain')) {
                let obj = {}
                let tuple = stixService.stringArrays.get('killChain')[x].split(': ');
                obj["kill_chain_name"] = tuple[1];
                obj["phase_name"] = tuple[0];
                this.kill_chain_phases.push(obj);
            }
        }
        delete this.phase_name;
        delete this.kill_chain_name;

        this.external_references = componentData.external_references;
        this.object_marking_refs = componentData.object_marking_refs;
        this.granular_markings = componentData.granular_markings;
        this.extensions = componentData.extensions;
        this.created = componentData.created;
        this.modified = componentData.modified;
        this.revoked = JSON.parse(componentData.revoked[0] || '""');
    }

    setExternalReferences(newExternalReferences: ExternalReference[]): void {
        this.external_references = newExternalReferences;
    }

    setGranularMarkings(newGranularMarkings: GranularMarking[]): void {
        this.granular_markings = newGranularMarkings;
    }

    setExtensions(newExtensions: Extension[]): void {
        this.extensions = newExtensions;
    }

    setObjectMarkingRefs(newObjectMarkingReferences: string[]): void {
        this.object_marking_refs = newObjectMarkingReferences;
    }

    setContents(newContents: Content[]): void {
        // N/a
    }
    setWindows(newWindows: Window[]): void {
        // N/a
    }
}