<div *ngIf="step === 0" [ngClass]="{'loadingSpinner': addingStixToPending}">    
    <!-- <div class="modal-header">
        <h4 class="modal-title" id="publish-modal-title">
            <span>Import Analyst1 Indicators</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->

    <div class="modal-body pb-0" style="padding-left: 0px; padding-right: 0px; height: 83vh;">
        <div *ngIf="showingAnalyst1" class="w-100">
            <div class="d-flex justify-content-center flex-column">
                <div class="text-center d-flex flex-row justify-content-between pl-4 pr-4 pt-2 pb-4 align-items-center w-100">
                    <div class="d-flex flex-row" style="width: 85%;">
                        <div class="search-label">
                            Search Evidence Packages
                        </div>
                        <div class="col-8 search-bar" style="display: inline-block;">
                            <input class="form-control search-input" 
                                [(ngModel)]="searchTextAnalyst1"
                                placeholder="Input criteria to search indicators on Analyst1."
                                (keydown.enter)="getAnalyst1Objects()">
                            <button class="clear-button" [disabled]="!searchTextAnalyst1" (click)="clearSearchAnalyst1()">
                                <p class="clear-button-text" *ngIf="searchTextAnalyst1">X</p>
                            </button>
                        </div>
                        <div>
                            <button *ngIf="!loadingStix" [disabled]="!searchTextAnalyst1 || addingStixToPending"
                                class="btn btn-md btn-primary query-button search-button"
                                (click)="getAnalyst1Objects()">
                                <fa-icon [icon]="faSearch" class="pr-1"></fa-icon>
                                Search
                            </button>
                            <!-- <button *ngIf="loadingStix" disabled
                                class="btn btn-primary pr-4 pl-4 pt-2 pb-2 query-button">
                                <span class="spinner-grow spinner-grow-sm" role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                            </button> -->
                            <button *ngIf="loadingStix"
                                class="btn btn-md btn-secondary query-button search-button" (click)="cancelAnalyst1Search()">
                                <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div class="w-25">
                        <button *ngIf="showNextAnalyst1Page() && !loadingStixNext" type="button" class="btn btn-sm btn-outline-primary next-button"
                            (click)="getNextAnalyst1Page()" [disabled]="addingStixToPending" style="height: 38px; float: right;"
                            title="More objects are available for this query. Click Next to see them.">
                            <fa-icon [icon]="faArrowRight" class="pr-1"></fa-icon>
                            Next
                        </button>
                        <button *ngIf="showPrevAnalyst1Page() && !loadingStixNext" type="button" class="btn btn-sm btn-outline-primary next-button"
                            (click)="getPrevAnalyst1Page()" [disabled]="addingStixToPending" style="height: 38px; float: right;"
                            title="More objects are available for this query. Click Prev to see previous page.">
                            <fa-icon [icon]="faArrowLeft" class="pr-1"></fa-icon>
                            Prev
                        </button>
                        <button *ngIf="showNextAnalyst1Page() && loadingStixNext" type="button" class="btn btn-sm btn-outline-primary next-button"
                            style="height: 38px; float: right;" disabled
                            title="More objects are available for this query. Click Next to see them.">
                            <span class="spinner-grow spinner-grow-sm" role="status"
                            aria-hidden="true"></span>
                        </button>
                        <button *ngIf="showPrevAnalyst1Page() && loadingStixNext" type="button" class="btn btn-sm btn-outline-primary next-button"
                            style="height: 38px; float: right;" disabled
                            title="More objects are available for this query. Click Prev to see previous page.">
                            <span class="spinner-grow spinner-grow-sm" role="status"
                            aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
                <div class="pb-1" style="border-bottom: 1px solid #dee2e6;"></div>
                <div *ngIf="analyst1Objects?.length > 0" class="row text-center pl-2 pr-2 pb-2 result-label w-100">
                    <div class="mt-2 d-flex w-100">
                        <div class="d-flex mt-2 pl-4 pr-4 display-stat" [ngClass]="{'display-stat': expandDetails, 'hide-stat': !expandDetails}">
                            <h1 class="h3">Total:  <span style="font-weight: 200; font-style: normal;">{{totalResults}} {{totalResults > 1 ? 'results' : 'result'}}</span></h1>
                            <div>
                                Displaying page {{analyst1currentPage}} of {{analyst1TotalPages}}
                            </div>
                        </div>
                        <div class="d-flex mt-2 mb-4 flex-column align-items-start output-table" *ngIf="expandDetails">
                            <div class="w-100 mb-4 text-start">
                                Select output format:
                            </div>
                            <div class="d-flex w-100 flex-row justify-content-start">
                                <div class="d-flex align-items-center">
                                    <input class="mr-2" type="checkbox" [checked]="textOutput" [(ngModel)]="textOutput"/>
                                    TXT
                                </div>
                                <div class="ml-4 d-flex align-items-center">
                                    <input class="mr-2" type="checkbox" [checked]="csvOutput" [(ngModel)]="csvOutput"/>
                                    CSV
                                </div>
                                <div class="ml-4 d-flex align-items-center">
                                    <input class="mr-2" type="checkbox" [checked]="stixOutput" [(ngModel)]="stixOutput" (change)="getCountries('output');"/>
                                    STIX 2.1
                                </div>
                                <button class="btn btn-success ml-4" [disabled]="exportingObjects || addingStixToPending" (click)="exportObjects()" style="height: 45px; width: 74px;">
                                    <span *ngIf="!exportingObjects">
                                        Export
                                    </span>
                                    <span *ngIf="exportingObjects">
                                        <span class="spinner-grow spinner-grow-sm" role="status"
                                        aria-hidden="true"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="expandDetails" class="ml-auto mr-2">
                            <fa-icon  style="cursor: pointer;" [icon]="faArrowUp" (click)="expandResultDetail()"></fa-icon>
                        </div>
                        <div *ngIf="!expandDetails" class="ml-auto mb-auto mt-auto mr-2">
                            <fa-icon  style="cursor: pointer;" [icon]="faArrowDown" (click)="expandResultDetail()"></fa-icon>
                        </div>
                        <!-- <div class="d-flex justify-content-end align-items-center col-md-3 pr-0">
                            <button *ngIf="loadingStixNext" type="button" disabled
                                class="btn btn-lg btn-outline-primary next-button">
                                <span class="spinner-grow spinner-grow-sm" role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>  
        <div [class]="analyst1Objects?.length === 0 ? 'mb-6' : 'mb-3'" [ngClass]="{'expanded-body': !expandDetails, 'collapsed-body': expandDetails}" style="display: flex; justify-content: center; align-items: center; width: 100%;"
            class="mt-1 analyst1-objects-wrapper">
            <div *ngIf="analyst1Objects?.length === 0 && !addingStixToPending"
                class="d-flex justify-content-center align-items-center">
                <div class="container" style="text-align: center;">
                    <p style="font-weight: bold;">No results</p>
                    <!-- <p>Type search criteria above and click Search to view results</p> -->
                </div>
            </div>

            <div *ngIf="loadingStix">
                <span class="spinner-grow spinner-grow-sm" role="status"
                aria-hidden="true"></span>
            </div>
            <!-- <div *ngIf="addingStixToPending" class="d-flex justify-content-center align-items-center">
                Loading&nbsp;<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            </div> -->

            <div *ngIf="analyst1Objects?.length > 0" style="width: 100%; height: 100%;">
                <div class="d-flex justify-content-start mt-3 table-label">
                    <div class="d-flex align-items-center ml-5" style="width: 3%; max-width: 3%; min-width: 3%;">
                        <input type="checkbox"
                            [disabled]="addingStixToPending"
                            (change)="checkAllAnalyst1Objects($event)"
                        />
                    </div>
                    <div class="d-flex align-items-center ml-4" style="width: 5%; max-width: 5%; min-width: 5%;">
                        <div class="w-100" style="font-weight: bold;">ID</div>
                    </div>
                    <div class="d-flex align-items-center ml-4" style="width: 42%; max-width: 42%; min-width: 42%;">
                        <div class="w-100" style="font-weight: bold;">Package Name</div>
                    </div>
                    <!-- <div class="d-flex align-items-center ml-4" style="width: 5%; max-width: 5%; min-width: 5%;">
                        <div class="w-100" style="font-weight: bold;">Indicators</div>
                    </div> -->
                    <div class="d-flex align-items-center ml-4" style="width: 3%; max-width: 3%; min-width: 3%;">
                        <div class="w-100" style="font-weight: bold; text-align: center;">CVE</div>
                    </div>
                    <div class="d-flex align-items-center ml-4" style="width: 8%; max-width: 8%; min-width: 8%;">
                        <div class="w-100" style="font-weight: bold; text-align: center;">Threat Actor</div>
                    </div>
                    <div class="d-flex align-items-center ml-4" style="width: 4%; max-width: 4%; min-width: 4%;">
                        <div class="w-100" style="font-weight: bold; text-align: center;">TLP</div>
                    </div>
                    <div class="d-flex align-items-center ml-4" style="width: 6%; max-width: 6%; min-width: 6%; text-align: center;">
                        <div class="w-100" style="font-weight: bold;">Details</div>
                    </div>
                    <div class="d-flex align-items-center ml-4" style="width: 10%; max-width: 10%; min-width: 10%; text-align: center;">
                        <div class="w-100" style="font-weight: bold;">Preview</div>
                    </div>
                </div>
                <div *ngFor="let object of analyst1Objects; index as i" class="d-flex flex-column" style="background-color: #f5f5f5; border-bottom: 1px solid #969696;">
                    <div  class="d-flex justify-content-start mt-3 mb-3 result-table">
                        <div class="ml-5 d-flex align-items-center" style="width: 3%; max-width: 3%; min-width: 3%;">
                            <input type="checkbox"
                                [disabled]="addingStixToPending"
                                (change)="checkAllAnalyst1Indicators(object, $event)"
                                [(ngModel)]="object.checked"/>
                        </div>
                        <div class="d-flex align-items-center ml-4" style=" width: 5%; max-width: 5%; min-width: 5%;">
                            <div class="w-100">{{object.id}}</div>
                        </div>
                        <div class="d-flex align-items-center ml-4" style="width: 42%; max-width: 42%; min-width: 42%;">
                            <div class="w-100">{{object.title.name}}</div>
                        </div>
                        <div class="d-flex align-items-center ml-4" style="width: 3%; max-width: 3%; min-width: 3%; text-align: center;">
                            <div class="w-100">{{object.cves?.values?.length > 0 ? object.cves.values.length : 0}}</div>
                        </div>
                        <div class="d-flex align-items-center ml-4" style="width: 8%; max-width: 8%; min-width: 8%; text-align: center;">
                            <div class="w-100">{{object.actors?.idNamePairs.length > 0 ? object.actors.idNamePairs[0].name : 'N/A'}}</div>
                        </div>
                        <div class="d-flex align-items-center ml-4" style="width: 4%; max-width: 4%; min-width: 4%; word-break: break-all; text-align: center;">
                            <div class="w-100">{{object.tlp.toUpperCase()}}</div>
                        </div>
                        <div class="d-flex align-items-center ml-4" style="width: 6%; max-width: 6%; min-width: 6%;">
                            <button class="btn btn-primary btn-sm" [disabled]="addingStixToPending" (click)="getIndicators(object)" style="background-color: #1f80b6; width: 80px;">
                                <span *ngIf="object.expandedResult === 'loaded'">-</span>
                                <span *ngIf="object.expandedResult === 'hidden' || object.expandedResult === 'not-loaded'">+</span>
                                <span *ngIf="object.expandedResult !== 'loading'"> Details</span>
                                <span *ngIf="object.expandedResult === 'loading'" class="spinner-grow spinner-grow-sm" role="status"
                                aria-hidden="true"></span>
                            </button>
                        </div>
                        <div class="d-flex align-items-center justify-content-center ml-4" style="width: 10%; max-width: 10%; min-width: 10%;">
                            <!-- <button (click)="previewFile('text')" [disabled]="addingStixToPending" class="btn btn-primary preview-button" >
                                <span *ngIf="!object.textLoading">
                                    TXT
                                </span>
                                <span class="spinner-grow spinner-grow-sm" *ngIf="object.textLoading" role="status"
                                aria-hidden="true"></span>
                            </button>
                            <button (click)="previewFile('csv')" [disabled]="addingStixToPending" class="btn btn-primary preview-button">
                                <span *ngIf="!object.csvLoading">
                                    CSV
                                </span>
                                <span class="spinner-grow spinner-grow-sm" *ngIf="object.csvLoading" role="status"
                                    aria-hidden="true"></span>
                            </button> -->
                            <button (click)="getCountries('preview', object)" [disabled]="addingStixToPending" class="btn btn-primary preview-button">
                                <span *ngIf="!object.stixLoading">
                                    STIX 2.1
                                </span>
                                <span class="spinner-grow spinner-grow-sm" *ngIf="object.stixLoading" role="status"
                                    aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex flex-column pt-4 pb-4 pl-5 pr-5 detail-box" *ngIf="object.expandedResult === 'loaded'">
                        <div>
                            <span class="font-weight-bold">Source Title:</span> {{object.sourceTitle}}
                            <br />
                            <span class="font-weight-bold">Source ID:</span> {{object.sourceId}}
                            <br />
                            <span class="font-weight-bold">TLP:</span> {{object.tlp}}
                            <br />
                            <span class="font-weight-bold">Type:</span> {{object.type}}
                            <br />
                            <span class="font-weight-bold">Last Updated:</span> {{object.reportedDate?.date ? object.reportedDate.date : "N/A" }}
                            <br />
                            <span class="font-weight-bold">Description:</span> {{object.description ? object.description.name : 'None'}}
                        </div>
                        <div class="w-100 mt-4 ml-2 mr-4 detail-items">
                            <table class="w-100" *ngIf="object.indicatorNum > 0">
                                <thead class="detail-table-head p-4 d-flex flex-row justify-content-between align-items-center" (click)="expandIndicator(object)" style="font-weight: 500;">
                                    <div class="d-flex flex-row align-items-center">
                                        <input class="mr-2" type="checkbox" [disabled]="addingStixToPending" (change)="checkAllAnalyst1Types(object, $event)" [checked]="object.indicatorChecked" (click)="$event.stopPropagation()" [(ngModel)]="object.indicatorChecked"/>
                                        <div>Indicators ({{object.indicatorNum}})</div>
                                    </div>
                                    <div *ngIf="!object.indicatorExpanded">
                                        +
                                    </div>
                                    <div *ngIf="object.indicatorExpanded">
                                        -
                                    </div>
                                </thead>
                                <tbody *ngIf="object.indicatorExpanded" style="display: table-row-group; background-color: white;">
                                        <table class="type-table mt-2 mb-2 mr-2" *ngFor="let type of object.indicatorTypes">
                                            <thead class="detail-table-head p-4 d-flex flex-row justify-content-between align-items-center" (click)="expand(type)" style="font-weight: 500;">
                                                <div class="d-flex flex-row align-items-center">
                                                    <input class="mr-2" type="checkbox" [disabled]="addingStixToPending" (change)="checkAllIndicators(object.indicatorList[type.type], $event)" [checked]="type.checked" (click)="$event.stopPropagation()" [(ngModel)]="type.checked"/>
                                                    {{type.type}} ({{object.indicatorList[type.type].length}})
                                                </div>
                                                <div *ngIf="!type.expanded">
                                                    +
                                                </div>
                                                <div *ngIf="type.expanded">
                                                    -
                                                </div>
                                            </thead>
                                            <tbody *ngIf="type.expanded">
                                                <table class="type-table mt-2 mb-2 mr-2" *ngFor="let indicator of object.indicatorList[type.type]; index as num">
                                                    <thead class="detail-table-head p-4 d-flex flex-row justify-content-between align-items-center" (click)="expand(indicator)" style="font-weight: 500;">
                                                        <div class="d-flex flex-row align-items-center">
                                                            <input class="mr-2" type="checkbox" [disabled]="addingStixToPending" [checked]="indicator.checked" (click)="$event.stopPropagation()" [(ngModel)]="indicator.checked"/>
                                                            {{num + 1}}. {{indicator.nameValue}}
                                                        </div>
                                                        <div *ngIf="!indicator.expanded">
                                                            +
                                                        </div>
                                                        <div *ngIf="indicator.expanded">
                                                            -
                                                        </div>
                                                    </thead>
                                                    <tbody class="detail-expanded" *ngIf="indicator.expanded" style="display: table-row-group">
                                                        <tr>
                                                            <td>
                                                                <div class="d-flex flex-column p-4">
                                                                    <div>
                                                                        <span class="font-weight-bold">Name:</span> {{indicator.nameValue}}
                                                                    </div>
                                                                    <div class="mt-2">
                                                                        <span class="font-weight-bold">Type:</span> {{type.type}}
                                                                    </div>
                                                                    <div class="mt-2" *ngIf="indicator.description">
                                                                        <span class="font-weight-bold">Description:</span> {{indicator.description}}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </tbody>
                                        </table>
                                </tbody>
                            </table>
                            <table class="w-100 mt-2" *ngIf="object.cves?.values?.length > 0">
                                <thead class="detail-table-head p-4 d-flex flex-row justify-content-between" (click)="getCves(object)" style="font-weight: 500;">
                                    <div>
                                        CVEs ({{object.cves.values.length}})
                                    </div>
                                    <div *ngIf="!object.cveExpanded">
                                        +
                                    </div>
                                    <div *ngIf="object.cveExpanded">
                                        -
                                    </div>
                                </thead>
                                <tbody class="detail-expanded" *ngIf="object.cveExpanded" style="display: table-row-group">
                                    <tr>
                                        <td>
                                            <div class="d-flex flex-column pl-4 pt-4 pr-4">
                                                <div *ngFor="let cve of object.cves.values" class="mb-4">
                                                    {{cve}}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="w-100 mt-2" *ngIf="object.actors?.idNamePairs?.length > 0">
                                <thead class="detail-table-head p-4 d-flex flex-row justify-content-between" (click)="getThreatActors(object)" style="font-weight: 500;">
                                    <div>
                                        Threat Actor
                                    </div>
                                    <div *ngIf="object.threatActorExpanded === 'not-loaded' || object.threatActorExpanded === 'hidden'">
                                        +
                                    </div>
                                    <div *ngIf="object.threatActorExpanded === 'loaded'">
                                        -
                                    </div>
                                    <span class="spinner-grow spinner-grow-sm" *ngIf="object.threatActorExpanded === 'loading'" role="status"
                                        aria-hidden="true"></span>
                                </thead>
                                <tbody class="detail-expanded" *ngIf="object.threatActorExpanded === 'loaded'" style="display: table-row-group">
                                    <tr>
                                        <td>
                                            <div class="d-flex flex-column p-4">
                                                <div class="w-100 mt-2" *ngIf="object.actors?.idNamePairs?.length > 0">
                                                    <span class="font-weight-bold">Threat Actor: </span>
                                                    <span *ngFor="let actor of object.actors.idNamePairs; index as ind">
                                                        {{actor.name}}<span *ngIf="ind !== object.actors.idNamePairs.length - 1">,</span>
                                                    </span>
                                                </div>
                                                <div class="w-100 mt-4" *ngIf="object.threatActorCountry?.length > 0">
                                                    <span class="font-weight-bold">Country: </span>
                                                    <span *ngFor="let country of object.threatActorCountry; index as ind">
                                                        {{country}}<span *ngIf="ind !== object.threatActorCountry.length - 1">,</span>
                                                    </span>
                                                </div>
                                                <div class="w-100 d-flex flex-row mt-4">
                                                    <!-- <div class="w-50" *ngIf="object.attackPattern?.idNamePairs?.length > 0">
                                                        <span class="font-weight-bold">Attack Patterns:</span>
                                                        <ul class="pr-4 mt-2">
                                                            <li *ngFor="let attack of object.attackPattern.idNamePairs">
                                                                {{attack.name}}
                                                            </li>
                                                        </ul>   
                                                    </div> -->
                                                    <div class="w-50" *ngIf="object.threatActorAttackPatterns?.length > 0">
                                                        <span class="font-weight-bold">Attack Patterns:</span>
                                                        <ul class="pr-4 mt-2">
                                                            <li *ngFor="let attack of object.threatActorAttackPatterns">
                                                                {{attack.name}}
                                                            </li>
                                                        </ul>   
                                                    </div>
                                                    <!-- <div class="w-50" *ngIf="object.targets?.idNamePairs?.length > 0">
                                                        <span class="font-weight-bold">Targets:</span>
                                                        <ul class="pr-4 mt-2">
                                                            <li *ngFor="let target of object.targets.idNamePairs">
                                                                {{target.name}}
                                                            </li>
                                                        </ul>   
                                                    </div> -->
                                                    <div class="w-50" *ngIf="object.threatActorTargets?.length > 0">
                                                        <span class="font-weight-bold">Targets:</span>
                                                        <ul class="pr-4 mt-2">
                                                            <li *ngFor="let target of object.threatActorTargets">
                                                                {{target.name}}
                                                            </li>
                                                        </ul>   
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary query-button" (click)="close()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <!-- <button
            [disabled]="!enableAddToCart() || addingStixToPending"
            class="btn btn-sm btn-outline-danger query-button"
            (click)="removeFromCart()">
            <fa-icon [icon]="faMinus" class="pr-1"></fa-icon>
            Remove
        </button>
        <button
            [disabled]="!enableAddToCart() || addingStixToPending"
            class="btn btn-sm btn-outline-primary query-button"
            (click)="addToCart()">
            <fa-icon [icon]="faPlus" class="pr-1"></fa-icon>
            Add
        </button> -->
        <button *ngIf="!addingStixToPending" 
            [disabled]="!enableAddToCart()"
            class="btn btn-sm btn-primary query-button"
            (click)="getCountries('review')">
            <fa-icon [icon]="faUserEdit" class="pr-1"></fa-icon>
            Review
            <span *ngIf="analyst1ObjectsInCart?.length > 0" class="badge rounded-pill bg-primary sdo-badge">{{ analyst1ObjectsInCart?.length }}</span>
        </button>
        <button *ngIf="addingStixToPending" type="button" disabled
            class="btn btn-primary pr-4 pl-4 pt-2 pb-2 query-button">
            <span class="spinner-grow spinner-grow-sm" role="status"
                aria-hidden="true"></span>
            <span class="sr-only">Importing...</span>
        </button>
    </div>

    <ng-template #stixModal let-modal>
        <div class="modal-body">
            <div class="d-flex flex-column">
                <div class="d-flex flex-row font-weight-bold justify-content-space-between mb-4">
                    <div class="p-4 col-6 text-center" style="background-color: #e0ecfc; margin: 0 auto;">
                        Capture Required AIS Information
                    </div>
                    <div (click)="closeStixConfig();" style="color: #89888b; position: absolute; right: 20px; cursor: pointer;">
                        X
                    </div>
                </div>
                <div *ngIf="stixConfigType === 'preview'" class="d-flex flex-row font-weight-bold justify-content-center mb-4">
                    <div class="col-3 ">
                        AIS TLP
                    </div>
                    <div class="col-6">
                        <select disabled class="form-control">
                            <option>
                                {{tempStixConfig.aisTlp}}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- <div class="d-flex flex-row font-weight-bold justify-content-center mb-4 align-items-center">
                    <div class="col-3">
                        AIS Consent
                    </div>
                    <div class="col-6">
                        <select class="form-control" [(ngModel)]="tempStixConfig.aisConsent" id="AISConsent" name="AISConsent">
                            <option [value]="'Everyone'" selected>Everyone</option>
                            <option [value]="'USG'">USG</option>
                            <option [value]="'None'">None</option>  
                        </select>
                    </div>
                </div> -->
                <div class="d-flex flex-row font-weight-bold justify-content-center mb-4">
                    <div class="col-3">
                        ACS Marking
                    </div>
                    <div class="col-6">
                        <select class="form-control" id="acsMarkings" name="acsMarkings"
                            (change)="selectAcsMarking($event)" [ngModel]="tempStixConfig.acsMarkings">
                            <option value=''>Select an ACS Marking</option>
                            <option *ngFor="let acs of acsMarkingOptions; let i = index" [value]="acs.type"
                                title="{{ acs.type }}">{{ acs.type }}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex flex-row font-weight-bold justify-content-center mb-4">
                    <div class="col-3">
                        Org Country
                    </div>
                    <div class="col-6">
                        <select class="form-control" [ngModel]="tempStixConfig.orgCountry" (change)="changeCountry($event)" id="orgCountry" name="orgCountry">
                            <option *ngFor="let country of countries | keyvalue" [value]="country.value[0].country || country.value[0].name">{{country.value[0].country || country.value[0].name}}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex flex-row font-weight-bold justify-content-center mb-4">
                    <div class="col-3">
                        Org Admin
                    </div>
                    <div class="col-6">
                        <select [disabled]="!enableAdmin()" [ngModel]="tempStixConfig.orgAdmin" (change)="changeAdmin($event)" class="form-control" id="orgAdmin" name="orgAdmin">
                            <option *ngFor="let admin of sortAdmin(countries.get(tempStixConfig.orgCountry))" [value]="admin.administrative_area || ''">{{admin.administrative_area}}</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="d-flex flex-row font-weight-bold justify-content-center mb-4 align-items-center" style="height: 45px;">
                    <div class="col-3">
                        Recipient
                    </div>
                    <div class="col-6 d-flex flex-row" >
                        <div class=" d-flex align-items-center mr-4 font-weight-normal">
                            <input class="mr-2" type="radio" [ngModel]="tempStixConfig.recipient" (change)="changeRecipient($event, 'nonfed')" value="Nonfed" name="recipient" id="recipientNonFed"/>
                            <span>
                                NONFED
                            </span>
                        </div>
                        <div class=" d-flex align-items-center font-weight-normal">
                            <input class="mr-2" type="radio" [ngModel]="tempStixConfig.recipient" (change)="changeRecipient($event, 'fed')" value="Fed" name="recipient" id="recipientFed"/>
                            <span>
                                FED
                            </span>
                        </div>
                    </div>
                </div> -->
                <div class="d-flex justify-content-center">
                    <button [disabled]="!tempStixConfig.acsMarkings" *ngIf="stixConfigType === 'output'" (click)="saveStixConfig()" class="btn btn-md" style="background-color: #1f80b6; color: white;">
                        Save
                    </button>
                    <button [disabled]="!tempStixConfig.acsMarkings" *ngIf="stixConfigType === 'preview'" (click)="previewFile('stix')" class="btn btn-md" style="background-color: #1f80b6; color: white;">
                        Preview
                    </button>
                    <button [disabled]="!tempStixConfig.acsMarkings" *ngIf="stixConfigType === 'review'" (click)="viewDetail()" class="btn btn-md" style="background-color: #1f80b6; color: white;">
                        Review
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>


<div *ngIf="step === 1">
    <div class="modal-body mb-3" style="padding: 2rem; height: 81vh; overflow: hidden;">
        <div style="height: 65vh">
            <div>
                <ul class="nav nav-tabs mr-2">
                    <li class="nav-item" (click)="activeTab = 'indicator'">
                        <a class="nav-link active" [ngClass]="{'active': activeTab === 'indicator'}">Indicator</a>
                    </li>
                    <li class="nav-item" (click)="activeTab = 'stix'">
                        <a class="nav-link" [ngClass]="{'active': activeTab === 'stix'}">Observable</a>
                    </li>
                </ul>
                <div class="tooltipright inline-block">
                    <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                    <div class="toolttext" style="width: 350px">Parsed indicators are IOC's that IMX can and does automatically convert to cyber-observable STIX objects. Indicators are IOC's pulled from A1, but that the analyst would have to manually add as STIX objects. User has option to add one or both to the bundle, but Indicators will not be classified into STIX objects.</div>
                </div>

                <div *ngIf="activeTab === 'indicator'">
                    <div class="row">
                        <div class="col text-center text-dark stix-preview-message">
                            <div class="bundle-wrapper">
                                <div *ngFor="let stixObject of stixObjects; let i = index;">
                                    <div *ngFor="let object of stixObject.objects; let j = index;">
                                        <div *ngIf="(pendingObjects | bundlefilter: object.type).length > 0">
                                            <div class="row text-left">
                                                <div class="col" id="{{getStixObjectID(object.type)}}">
                                                    <table class="w-100">
                                                        <thead class="w-100">
                                                            <th colspan="2">
                                                                {{object.displayName}}
                                                                <span class="badge rounded-pill bg-primary sdo-badge">
                                                                    {{ (pendingObjects | bundlefilter: object.type
                                                                    ).length
                                                                    }}
                                                                </span>
                                                            </th>
                                                        </thead>
                                                        <tbody class="w-100 pl-4" style="overflow: hidden">
                                                            
                                                            <ng-container
                                                                *ngFor="let component of pendingObjects | bundlefilter: object.type; let k = index; let c = count;">
                                                                <tr class="d-flex w-100">
                                                                    <td class="btn-link overflow d-flex align-items-center justify-content-between"
                                                                        style="padding-top:14px; padding-bottom: 14px; width: 100%; border-top: 1px solid #dee2e6; max-width: 100%;"
                                                                        id="{{getComponentId(component, i, j, k)}}"
                                                                        (click)="changeShow(j, k)">
                                                                        <div class="pull-left float-left overflow">
                                                                            {{getComponentDisplay(component)}}
                                                                        </div>
                                                                        <div>
                                                                            <button type="button"
                                                                                class="btn btn-fa btn-outline-danger mr-4"
                                                                                (click)="removeObject(component.id);">
                                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="expanded-panel"
                                                                    *ngIf="objnumber == j && rownumber == k">
                                                                    <td colspan="2">
                                                                        <div class="row" style="width: 80%">
                                                                            <pre>{{ getJsonDisplayForComponent(component) | json }}</pre>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="activeTab === 'stix'">
                    <div class="row">
                        <div class="col text-center text-dark stix-preview-message">
                            <div class="bundle-wrapper">
                                <div *ngFor="let stixObject of stixObjects; let i = index;">
                                    <div *ngFor="let object of stixObject.objects; let j = index;">
                                        <div *ngIf="(pendingStixObjects | bundlefilter: object.type).length > 0">
                                            <div class="row text-left">
                                                <div class="col" id="{{getStixObjectID(object.type)}}">
                                                    <table class="w-100">
                                                        <thead class="w-100">
                                                            <th colspan="2">
                                                                {{object.displayName}}
                                                                <span class="badge rounded-pill bg-primary sdo-badge">
                                                                    {{ (pendingStixObjects | bundlefilter: object.type
                                                                    ).length
                                                                    }}
                                                                </span>
                                                            </th>
                                                        </thead>
                                                        <tbody class="w-100 pl-4" style="overflow: hidden">
                                                            <div *ngIf="true">
                                                            <ng-container
                                                                *ngFor="let component of pendingStixObjects | bundlefilter: object.type; let k = index; let c = count;">
                                                                <tr class="d-flex w-100">
                                                                    <td class="btn-link overflow d-flex align-items-center justify-content-between"
                                                                        style="padding-top:14px; padding-bottom: 14px; width: 100%; border-top: 1px solid #dee2e6; max-width: 100%;"
                                                                        id="{{getComponentId(component, i, j, k)}}"
                                                                        (click)="changeShow(j, k)">
                                                                        <div class="pull-left float-left overflow">
                                                                            {{getComponentDisplay(component)}}
                                                                        </div>
                                                                        <div>
                                                                            <button type="button"
                                                                                class="btn btn-fa btn-outline-danger mr-4"
                                                                                (click)="removeObject(component.id);">
                                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="expanded-panel"
                                                                    *ngIf="objnumber == j && rownumber == k">
                                                                    <td colspan="2">
                                                                        <div class="row" style="width: 80%">
                                                                            <pre>{{ getJsonDisplayForComponent(component) | json }}</pre>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                            </div>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="pendingStixObjects?.length === 0" style="margin-top: 20%">
                                    The JSON information pulled from A1 does not have a clear a:b pattern that allows IMX to convert it to a STIX object.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary query-button" (click)="close()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <button
            [disabled]="false"
            class="btn btn-sm btn-primary query-button"
            (click)="goBack()">
            <fa-icon [icon]="faArrowLeft" class="pr-1"></fa-icon>
            Back
        </button>
        <div class="dropdown d-flex align-items-center">
            <button class="btn btn-sm btn-success dropdown-toggle query-button add-button" type="button" data-toggle="dropdown" aria-expanded="false">
                <fa-icon [icon]="faAddToBundle" class="pr-1"></fa-icon>
              Add To Bundle
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="addToBundle('indicator')">Indicator Only</a>
              <a class="dropdown-item" (click)="addToBundle('parsed')">Observable Only</a>
              <a class="dropdown-item" (click)="addToBundle('both')">Indicator + Observable</a>
            </div>
          </div>
    </div>
</div>