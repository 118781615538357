<div class="dialog">
    <div class="modal-header">
        <h4 class="modal-title" id="publish-modal-title">
            <span>{{currentMode}} Items</span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="onX()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="w-100 cart">
            <div *ngFor="let type of cart | keyvalue" class="mb-1">
                <div class="w-100 cart-title pl-2 pr-2">
                    <h5 class="mb-0">{{type.key}}</h5>
                </div>
                <div *ngFor="let object of type.value | keyvalue" class="cart-item pl-2 pr-2 mt-1">
                    <span class="cart-display-name">
                        {{getName(object.value)}}
                    </span>
                    <button class="btn btn-danger btn-fa-sm cart-button" (click)="deleteFromCart(type.key, object.key, true)" [disabled]="editMode">
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                    <button class="btn btn-primary btn-fa-sm cart-button mr-1" (click)="editFromCart(type.key, object.key)" [disabled]="editMode">
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" (click)="onX()">
            <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
            Cancel
        </button>
        <!-- <button type="button" class="btn btn-sm btn-success" (click)="onConfirm()">
            <fa-icon [icon]="faCheck" class="pr-1"></fa-icon>
            Confirm
        </button> -->
    </div>
</div>

