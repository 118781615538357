export const SDO_SRO = [
    "type",
    "id",
    "spec_version",
    "created",
    "modified",
    "confidence",
    "lang",
    "created_by_ref",
    "revoked",
    "labels",
    "extensions"
]