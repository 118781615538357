import Dexie, { Table } from 'dexie';
import { Bundle } from './models/bundle';
import { v4 as uuid } from 'uuid'
import { environment } from 'src/environments/environment';

export interface SavedBundle {
    name: string,
    bundle: any[]
}

export class AppDB extends Dexie {
    imxCache!: Table<any, number>;
    savedBundles!: Table<SavedBundle, number>;
    bundles!: Table<any, number>;

    constructor() {
        super('imx');
        this.version(1).stores({
            savedBundles: '++, &name',
            imxCache: '&id, &index',
            bundles: '&publish_time'
        });

        this.on("populate", () => this.populate());
    }
    
    async populate() {
        await db.imxCache.add({id: environment.cisaIdentity.id, index: 1, object: environment.cisaIdentity});
    }
}

export const db = new AppDB();