export const LANGUAGES = [
    {key:'af', value: 'Afrikaans'},
    {key:'af-ZA', value: 'Afrikaans (South Africa)'},
    {key:'ar', value: 'Arabic'},
    {key:'ar-AE', value: 'Arabic (U.A.E.)'},
    {key:'ar-BH', value: 'Arabic (Bahrain)'},
    {key:'ar-DZ', value: 'Arabic (Algeria)'},
    {key:'ar-EG', value: 'Arabic (Egypt)'},
    {key:'ar-IQ', value: 'Arabic (Iraq)'},
    {key:'ar-JO', value: 'Arabic (Jordan)'},
    {key:'ar-KW', value: 'Arabic (Kuwait)'},
    {key:'ar-LB', value: 'Arabic (Lebanon)'},
    {key:'ar-LY', value: 'Arabic (Libya)'},
    {key:'ar-MA', value: 'Arabic (Morocco)'},
    {key:'ar-OM', value: 'Arabic (Oman)'},
    {key:'ar-QA', value: 'Arabic (Qatar)'},
    {key:'ar-SA', value: 'Arabic (Saudi Arabia)'},
    {key:'ar-SY', value: 'Arabic (Syria)'},
    {key:'ar-TN', value: 'Arabic (Tunisia)'},
    {key:'ar-YE', value: 'Arabic (Yemen)'},
    {key:'az', value: 'Azeri (Latin)'},
    {key:'az-AZ', value: 'Azeri (Latin) (Azerbaijan)'},
    {key:'az-Cyrl-AZ', value: 'Azeri (Cyrillic) (Azerbaijan)'},
    {key:'be', value: 'Belarusian'},
    {key:'be-BY', value: 'Belarusian (Belarus)'},
    {key:'bg', value: 'Bulgarian'},
    {key:'bg-BG', value: 'Bulgarian (Bulgaria)'},
    {key:'bs-BA', value: 'Bosnian (Bosnia and Herzegovina)'},
    {key:'ca', value: 'Catalan'},
    {key:'ca-ES', value: 'Catalan (Spain)'},
    {key:'cs', value: 'Czech'},
    {key:'cs-CZ', value: 'Czech (Czech Republic)'},
    {key:'cy', value: 'Welsh'},
    {key:'cy-GB', value: 'Welsh (United Kingdom)'},
    {key:'da', value: 'Danish'},
    {key:'da-DK', value: 'Danish (Denmark)'},
    {key:'de', value: 'German'},
    {key:'de-AT', value: 'German (Austria)'},
    {key:'de-CH', value: 'German (Switzerland)'},
    {key:'de-DE', value: 'German (Germany)'},
    {key:'de-LI', value: 'German (Liechtenstein)'},
    {key:'de-LU', value: 'German (Luxembourg)'},
    {key:'dv', value: 'Divehi'},
    {key:'dv-MV', value: 'Divehi (Maldives)'},
    {key:'el', value: 'Greek'},
    {key:'el-GR', value: 'Greek (Greece)'},
    {key:'en', value: 'English'},
    {key:'en-AU', value: 'English (Australia)'},
    {key:'en-BZ', value: 'English (Belize)'},
    {key:'en-CA', value: 'English (Canada)'},
    {key:'en-CB', value: 'English (Caribbean)'},
    {key:'en-GB', value: 'English (United Kingdom)'},
    {key:'en-IE', value: 'English (Ireland)'},
    {key:'en-JM', value: 'English (Jamaica)'},
    {key:'en-NZ', value: 'English (New Zealand)'},
    {key:'en-PH', value: 'English (Republic of the Philippines)'},
    {key:'en-TT', value: 'English (Trinidad and Tobago)'},
    {key:'en-US', value: 'English (United States)'},
    {key:'en-ZA', value: 'English (South Africa)'},
    {key:'en-ZW', value: 'English (Zimbabwe)'},
    {key:'eo', value: 'Esperanto'},
    {key:'es', value: 'Spanish'},
    {key:'es-AR', value: 'Spanish (Argentina)'},
    {key:'es-BO', value: 'Spanish (Bolivia)'},
    {key:'es-CL', value: 'Spanish (Chile)'},
    {key:'es-CO', value: 'Spanish (Colombia)'},
    {key:'es-CR', value: 'Spanish (Costa Rica)'},
    {key:'es-DO', value: 'Spanish (Dominican Republic)'},
    {key:'es-EC', value: 'Spanish (Ecuador)'},
    {key:'es-ES', value: 'Spanish (Spain)'},
    {key:'es-GT', value: 'Spanish (Guatemala)'},
    {key:'es-HN', value: 'Spanish (Honduras)'},
    {key:'es-MX', value: 'Spanish (Mexico)'},
    {key:'es-NI', value: 'Spanish (Nicaragua)'},
    {key:'es-PA', value: 'Spanish (Panama)'},
    {key:'es-PE', value: 'Spanish (Peru)'},
    {key:'es-PR', value: 'Spanish (Puerto Rico)'},
    {key:'es-PY', value: 'Spanish (Paraguay)'},
    {key:'es-SV', value: 'Spanish (El Salvador)'},
    {key:'es-UY', value: 'Spanish (Uruguay)'},
    {key:'es-VE', value: 'Spanish (Venezuela)'},
    {key:'et', value: 'Estonian'},
    {key:'et-EE', value: 'Estonian (Estonia)'},
    {key:'eu', value: 'Basque'},
    {key:'eu-ES', value: 'Basque (Spain)'},
    {key:'fa', value: 'Farsi'},
    {key:'fa-IR', value: 'Farsi (Iran)'},
    {key:'fi', value: 'Finnish'},
    {key:'fi-FI', value: 'Finnish (Finland)'},
    {key:'fo', value: 'Faroese'},
    {key:'fo-FO', value: 'Faroese (Faroe Islands)'},
    {key:'fr', value: 'French'},
    {key:'fr-BE', value: 'French (Belgium)'},
    {key:'fr-CA', value: 'French (Canada)'},
    {key:'fr-CH', value: 'French (Switzerland)'},
    {key:'fr-FR', value: 'French (France)'},
    {key:'fr-LU', value: 'French (Luxembourg)'},
    {key:'fr-MC', value: 'French (Principality of Monaco)'},
    {key:'gl', value: 'Galician'},
    {key:'gl-ES', value: 'Galician (Spain)'},
    {key:'gu', value: 'Gujarati'},
    {key:'gu-IN', value: 'Gujarati (India)'},
    {key:'he', value: 'Hebrew'},
    {key:'he-IL', value: 'Hebrew (Israel)'},
    {key:'hi', value: 'Hindi'},
    {key:'hi-IN', value: 'Hindi (India)'},
    {key:'hr', value: 'Croatian'},
    {key:'hr-BA', value: 'Croatian (Bosnia and Herzegovina)'},
    {key:'hr-HR', value: 'Croatian (Croatia)'},
    {key:'hu', value: 'Hungarian'},
    {key:'hu-HU', value: 'Hungarian (Hungary)'},
    {key:'hy', value: 'Armenian'},
    {key:'hy-AM', value: 'Armenian (Armenia)'},
    {key:'id', value: 'Indonesian'},
    {key:'id-ID', value:'Indonesian (Indonesia)'},
    {key:'is', value: 'Icelandic'},
    {key:'is-IS', value: 'Icelandic (Iceland)'},
    {key:'it', value: 'Italian'},
    {key:'it-CH', value: 'Italian (Switzerland)'},
    {key:'it-IT', value: 'Italian (Italy)'},
    {key:'ja', value: 'Japanese'},
    {key:'ja-JP', value:'Japanese (Japan)'},
    {key:'ka', value: 'Georgian'},
    {key:'ka-GE', value: 'Georgian (Georgia)'},
    {key:'kk', value: 'Kazakh'},
    {key:'kk-KZ', value: 'Kazakh (Kazakhstan)'},
    {key:'kn', value: 'Kannada'},
    {key:'kn-IN', value: 'Kannada (India)'},
    {key:'ko', value: 'Korean'},
    {key:'ko-KR', value: 'Korean (Korea)'},
    {key:'kok', value: 'Konkani'},
    {key:'kok-IN', value: 'Konkani (India)'},
    {key:'ky', value: 'Kyrgyz'},
    {key:'ky-KG', value: 'Kyrgyz (Kyrgyzstan)'},
    {key:'lt', value: 'Lithuanian'},
    {key:'lt-LT', value: 'Lithuanian (Lithuania)'},
    {key:'lv', value: 'Latvian'},
    {key:'lv-LV', value: 'Latvian (Latvia)'},
    {key:'mi', value: 'Maori'},
    {key:'mi-NZ', value: 'Maori (New Zealand)'},
    {key:'mk', value: 'FYRO Macedonian'},
    {key:'mk-MK', value: 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)'},
    {key:'mn', value: 'Mongolian'},
    {key:'mn-MN', value: 'Mongolian (Mongolia)'},
    {key:'mr', value:'Marathi'},
    {key:'mr-IN', value: 'Marathi (India)'},
    {key:'ms', value: 'Malay'},
    {key:'ms-BN', value: 'Malay (Brunei Darussalam)'},
    {key:'ms-MY', value: 'Malay (Malaysia)'},
    {key:'mt', value: 'Maltese'},
    {key:'mt-MT', value: 'Maltese (Malta)'},
    {key:'nb', value: 'Norwegian (Bokm?l)'},
    {key:'nb-NO', value: 'Norwegian (Bokm?l) (Norway)'},
    {key:'nl', value: 'Dutch'},
    {key:'nl-BE', value: 'Dutch (Belgium)'},
    {key:'nl-NL', value: 'Dutch (Netherlands)'},
    {key:'nn-NO', value: 'Norwegian (Nynorsk) (Norway)'},
    {key:'ns', value: 'Northern Sotho'},
    {key:'ns-ZA', value: 'Northern Sotho (South Africa)'},
    {key:'pa', value: 'Punjabi'},
    {key:'pa-IN', value: 'Punjabi (India)'},
    {key:'pl', value: 'Polish'},
    {key:'pl-PL', value: 'Polish (Poland)'},
    {key:'ps', value: 'Pashto'},
    {key:'ps-AR', value: 'Pashto (Afghanistan)'},
    {key:'pt', value: 'Portuguese'},
    {key:'pt-BR', value: 'Portuguese (Brazil)'},
    {key:'pt-PT', value: 'Portuguese (Portugal)'},
    {key:'qu', value:'Quechua'},
    {key:'qu-BO', value: 'Quechua (Bolivia)'},
    {key:'qu-EC', value: 'Quechua (Ecuador)'},
    {key:'qu-PE', value: 'Quechua (Peru)'},
    {key:'ro', value: 'Romanian'},
    {key:'ro-RO', value: 'Romanian (Romania)'},
    {key:'ru', value: 'Russian'},
    {key:'ru-RU', value: 'Russian (Russia)'},
    {key:'sa', value: 'Sanskrit'},
    {key:'sa-IN', value: 'Sanskrit (India)'},
    {key:'se', value: 'Sami'},
    {key:'se-FI', value: 'Sami (Finland)'},
    {key:'se-NO', value: 'Sami (Norway)'},
    {key:'se-SE', value: 'Sami (Sweden)'},
    {key:'sk', value: 'Slovak'},
    {key:'sk-SK', value: 'Slovak (Slovakia)'},
    {key:'sl', value: 'Slovenian'},
    {key:'sl-SI', value: 'Slovenian (Slovenia)'},
    {key:'sq', value: 'Albanian'},
    {key:'sq-AL', value: 'Albanian (Albania)'},
    {key:'sr-BA', value: 'Serbian (Latin) (Bosnia and Herzegovina)'},
    {key:'sr-Cyrl-BA', value: 'Serbian (Cyrillic) (Bosnia and Herzegovina)'},
    {key:'sr-SP', value: 'Serbian (Latin) (Serbia and Montenegro)'},
    {key:'sr-Cyrl-SP', value: 'Serbian (Cyrillic) (Serbia and Montenegro)'},
    {key:'sv', value: 'Swedish'},
    {key:'sv-FI', value: 'Swedish (Finland)'},
    {key:'sv-SE', value: 'Swedish (Sweden)'},
    {key:'sw', value: 'Swahili'},
    {key:'sw-KE', value: 'Swahili (Kenya)'},
    {key:'syr', value: 'Syriac'},
    {key:'syr-SY', value: 'Syriac (Syria)'},
    {key:'ta', value: 'Tamil'},
    {key:'ta-IN', value: 'Tamil (India)'},
    {key:'te', value: 'Telugu'},
    {key:'te-IN', value: 'Telugu (India)'},
    {key:'th', value: 'Thai'},
    {key:'th-TH', value: 'Thai (Thailand)'},
    {key:'tl', value: 'Tagalog'},
    {key:'tl-PH', value: 'Tagalog (Philippines)'},
    {key:'tn', value: 'Tswana'},
    {key:'tn-ZA', value: 'Tswana (South Africa)'},
    {key:'tr', value: 'Turkish'},
    {key:'tr-TR', value: 'Turkish (Turkey)'},
    {key:'tt', value: 'Tatar'},
    {key:'tt-RU', value: 'Tatar (Russia)'},
    {key:'ts', value: 'Tsonga'},
    {key:'uk', value: 'Ukrainian'},
    {key:'uk-UA', value: 'Ukrainian (Ukraine)'},
    {key:'ur', value: 'Urdu'},
    {key:'ur-PK', value: 'Urdu (Islamic Republic of Pakistan)'},
    {key:'uz', value: 'Uzbek (Latin)'},
    {key:'uz-UZ', value: 'Uzbek (Latin) (Uzbekistan)'},
    {key:'uz-Cyrl-UZ', value: 'Uzbek (Cyrillic) (Uzbekistan)'},
    {key:'vi', value: 'Vietnamese'},
    {key:'vi-VN', value: 'Vietnamese (Viet Nam)'},
    {key:'xh', value: 'Xhosa'},
    {key:'xh-ZA', value: 'Xhosa (South Africa)'},
    {key:'zh', value: 'Chinese'},
    {key:'zh-CN', value: 'Chinese (S)'},
    {key:'zh-HK', value: 'Chinese (Hong Kong)'},
    {key:'zh-MO', value: 'Chinese (Macau)'},
    {key:'zh-SG', value: 'Chinese (Singapore)'},
    {key:'zh-TW', value: 'Chinese (T)'},
    {key:'zu', value: 'Zulu'},
    {key:'zu-ZA', value: 'Zulu (South Africa)'},
  ];

  LANGUAGES.sort((a, b) => {
    let la = a.value.toLowerCase();
    let lb = b.value.toLowerCase();

    if (la < lb) {
        return -1;
    }
    if (la > lb) {
        return 1;
    }
    return 0;
  });