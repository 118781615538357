export const STIX_OBJECTS = [
    {
        type: "sdo",
        displayName: "Domain Objects",
        objects: [
            {
                displayName: "Attack Pattern",
                type: "attack-pattern",
                routeName: "attack-pattern",
                objectType: "sdo",
                description: "Describes methods that adversaries use to compromise targets.",
            },
            {
                displayName: "Campaign",
                type: "campaign",
                routeName: "campaign",
                objectType: "sdo",
                description: "Malicious activities or attacks that occur over a<br>period of time against a specific set of targets."
            },
            {
                displayName: "Course of Action",
                type: "course-of-action",
                routeName: "course-of-action",
                objectType: "sdo",
                description: "An action taken to prevent an attack or to<br>respond to an attack that is in progress."
            },
            {
                displayName: "Grouping",
                type: "grouping",
                routeName: "grouping",
                objectType: "sdo",
                description: "A Grouping of STIX Objects that have a shared context."
            },
            {
                displayName: "Identity",
                type: "identity",
                routeName: "identity",
                objectType: "sdo",
                description: "Identities represent actual individuals, organizations, or groups, as well<br>as classes of individuals, organizations, systems, or groups.<br>Identities may be targets of attacks, information sources, or object creators."
            },
            {
                displayName: "Incident",
                type: "incident",
                routeName: "incident",
                objectType: "sdo",
                description: "Incident objects represent cases composed of events and tasks as well as their actual or potential impacts."
            },
            {
                displayName: "Indicator",
                type: "indicator",
                routeName: "indicator",
                objectType: "sdo",
                description: "A pattern that can be used to detect<br>suspicious or malicious cyber activity."
            },
            {
                displayName: "Infrastructure",
                type: "infrastructure",
                routeName: "infrastructure",
                objectType: "sdo",
                description: "Describes any systems, software services, and any associated physical or virtual<br>resources intended to support some purpose. The Infrastructure SDO<br>represents a named group of related data that constitutes the infrastructure."
            },
            {
                displayName: "Intrusion Set",
                type: "intrusion-set",
                routeName: "intrusion-set",
                objectType: "sdo",
                description: "An Intrusion Set is a grouped set of adversarial behaviors and resources with<br>common properties believed to be orchestrated by a single organization."
            },
            {
                displayName: "Location",
                type: "location",
                routeName: "location",
                objectType: "sdo",
                description: "A geographic location. The location may be described as any, some or all of<br>the following: region (e.g. North America), civic address (e.g., New Yor, US),<br>latitude and longitude."
            },
            {
                displayName: "Malware",
                type: "malware",
                routeName: "malware",
                objectType: "sdo",
                description: "Malicious code that is inserted into a system—usually covertly–with the<br>intent to compromise the victim’s data, applications, or operating system (OS)."
            },
            {
                displayName: "Malware Analysis",
                type: "malware-analysis",
                routeName: "malware-analysis",
                objectType: "sdo",
                description: "Captures the metadata and results of a particular static or dynamic<br>analysis performed on a malware instance or family."
            },
            {
                displayName: "Note",
                type: "note",
                routeName: "note",
                objectType: "sdo",
                description: "Provides context or additional analysis to STIX Objects, Marking Definition<br>objects, or Language Content objects which the Note relates to."
            },
            {
                displayName: "Observed Data",
                type: "observed-data",
                routeName: "observed-data",
                objectType: "sdo",
                description: "Observed Data is raw information about cyber security related entities such<br>as files, systems, and networks using theSTIX Cyber-observable Objects (SCOs)."
            },
            {
                displayName: "Opinion",
                type: "opinion",
                routeName: "opinion",
                objectType: "sdo",
                description: "An assessment of the correctness of the information<br>in a STIX object produced by a different entity."
            },
            {
                displayName: "Report",
                type: "report",
                routeName: "report",
                objectType: "sdo",
                description: "Collections of one or more topics—such as the description of<br>a threat actor, malware, or attack technique—used to group<br>related threat intelligence together that can be published<br>as a comprehensive cyber threat story."
            },
            {
                displayName: "Threat Actor",
                type: "threat-actor",
                routeName: "threat-actor",
                objectType: "sdo",
                description: "Individuals, groups, or organizations believed<br>to be operating with malicious intent."
            },
            {
                displayName: "Tool",
                type: "tool",
                routeName: "tool",
                objectType: "sdo",
                description: "Legitimate software that threat actors can use to perform attacks.<br>Unlike malware, these tools or software packages are often found<br>on a system and have legitimate purposes."
            },
            {
                displayName: "Vulnerability",
                type: "vulnerability",
                routeName: "vulnerability",
                objectType: "sdo",
                description: "A weakness in the requirements, design, or implementation of the<br>computational logic/code found in software and firmware that can be exploited<br>to negatively impact the confidentiality, integrity, or availability of that system."
            },
        ]
    },
    {
        type: "sco",
        displayName: "Cyber Observable Objects",
        objects: [
            {
                displayName: "Artifact",
                type: "artifact",
                routeName: "artifact",
                objectType: "sco",
                description: "Artifact objects capture an array of bytes (8-bits), as a<br>base64-encoded string, or link to a file-like payload."
            },
            {
                displayName: "Autonomous System",
                type: "autonomous-system",
                routeName: "autonomous-system",
                objectType: "sco",
                description: "The properties of an Autonomous System (AS)."
            },
            {
                displayName: "Directory",
                type: "directory",
                routeName: "directory",
                objectType: "sco",
                description: "Properties common to a file system directory."
            },
            {
                displayName: "Domain Name",
                type: "domain-name",
                routeName: "domain-name",
                objectType: "sco",
                description: "Represents the properties of a network domain name."
            },
            {
                displayName: "Email Address",
                type: "email-addr",
                routeName: "email-addr",
                objectType: "sco",
                description: "Represents a single email address."
            },
            {
                displayName: "Email Message",
                type: "email-message",
                routeName: "email-message",
                objectType: "sco",
                description: "An instance of an email message."
            },
            {
                displayName: "File",
                type: "file",
                routeName: "file",
                objectType: "sco",
                description: "Properties of a file."
            },
            {
                displayName: "IPv4 Address",
                type: "ipv4-addr",
                routeName: "ipv4-addr",
                objectType: "sco",
                description: "Represents one or more IPv4 addresses<br>expressed using CIDR notation."
            },
            {
                displayName: "IPv6 Address",
                type: "ipv6-addr",
                routeName: "ipv6-addr",
                objectType: "sco",
                description: "Represents one or more IPv6 addresses<br>expressed using CIDR notation."
            },
            {
                displayName: "Mac Address",
                type: "mac-addr",
                routeName: "mac-addr",
                objectType: "sco",
                description: "Represents a single Media Access Control (MAC) address."
            },
            {
                displayName: "Mutex",
                type: "mutex",
                routeName: "mutex",
                objectType: "sco",
                description: "The properties of a mutual exclusion (mutex) object."
            },
            {
                displayName: "Network Traffic",
                type: "network-traffic",
                routeName: "network-traffic",
                objectType: "sco",
                description: "Arbitrary network traffic that originates from a<br>source and is addressed to a destination."
            },
            {
                displayName: "Process",
                type: "process",
                routeName: "process",
                objectType: "sco",
                description: "Represents common properties of an instance of a computer<br>program as executed on an operating system."
            },
            {
                displayName: "Software",
                type: "software",
                routeName: "software",
                objectType: "sco",
                description: "Represents high-level properties associated<br>with software, including software products."
            },
            {
                displayName: "URL",
                type: "url",
                routeName: "url",
                objectType: "sco",
                description: "Properties of a uniform resource locator (URL)."
            },
            {
                displayName: "User Account",
                type: "user-account",
                routeName: "user-account",
                objectType: "sco",
                description: "Any type of user account, including but not limited to operating system,<br>device, messaging service, and social media platform accounts."
            },
            {
                displayName: "Windows Registry Key",
                type: "windows-registry-key",
                routeName: "windows-registry-key",
                objectType: "sco",
                description: "Properties of a Windows registry key."
            },
            {
                displayName: "X.509 Certificate",
                type: "x509-certificate",
                routeName: "x509-certificate",
                objectType: "sco",
                description: "Represents the properties of an X.509 certificate,<br>as defined by ITU recommendation X.509 [X.509]."
            },
        ]
    },
    {
        type: "sro",
        displayName: "Relationship Objects",
        objects: [
            {
                displayName: "Relationship",
                type: "relationship",
                routeName: "relationship",
                objectType: "sro",
                description: "Used to link two SDOs or SCOs together in order<br>to describe how they are related to each other."
            },
            {
                displayName: "Sighting",
                type: "sighting",
                routeName: "sighting",
                objectType: "sro",
                description: "A belief that something in CTI (e.g., an indicator,<br>malware, tool, threat actor, etc.) was seen."
            },
        ]
    },
    {
        type: "smo",
        displayName: "Meta Objects",
        objects: [
            {
                displayName: "Language Content",
                type: "language-content",
                routeName: "language-content",
                objectType: "smo",
                description: "Text content for STIX objects in languages other than that of the original object: a translation<br>of the original object by a third-party, a first-source translation by the original publisher,<br>or additional official language content provided at the time of creation."
            },
            {
                displayName: "Marking Definition",
                type: "marking-definition",
                routeName: "marking-definition",
                objectType: "smo",
                description: "Specific data markings that represents handling or sharing requirements for data and are<br>applied in the object_marking_refs and granular__markings properties on STIX objects."
            },
            {
                displayName: "Extension Definition",
                type: "extension-definition",
                routeName: "extension-definition",
                objectType: "smo",
                description: "Extends existing STIX Objects or creates new STIX Objects."
            },
        ]
    },
]
export const STIX_OBJECTS_LIST = [
    "attack-pattern",
    "campaign",
    "course-of-action",
    "grouping",
    "identity",
    "incident",
    "indicator",
    "infrastructure",
    "intrusion-set",
    "location",
    "malware-analysis",
    "malware",
    "note",
    "observed-data",
    "opinion",
    "report",
    "threat-actor",
    "tool",
    "vulnerability",
    "relationship",
    "sighting",
    "artifact",
    "autonomous-system",
    "directory",
    "domain-name",
    "email-addr",
    "email-message",
    "file",
    "ipv4-addr",
    "ipv6-addr",
    "mac-addr",
    "mutex",
    "network-traffic",
    "process",
    "software",
    "url",
    "user-account",
    "windows-registry-key",
    "x509-certificate",
    "language-content",
    "marking-definition",
    "extension-definition"
]