<div class="body">
  <div class="modal-header">
    <h4 *ngIf="roleType === 0" class="modal-title" id="modal-title">New Group</h4>
    <h4 *ngIf="roleType === 1" class="modal-title" id="modal-title">New Collection</h4>
    <h4 *ngIf="roleType === 2" class="modal-title" id="modal-title">Edit Group Permission</h4>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="item-content">
    <label class="item-label">
      <div class="item-label-title">
        Group
      </div>
      <input type="text" class="form-control item-label-input input-sm" [disabled]="role?.length > 0 && roleType !== 0"
        (focusout)="onFocusOutGroup($event)" [(ngModel)]="role" />
    </label>

    <label class="item-label">
      <div class="item-label-title">
        API Root
      </div>
      <select class="form-control dropdown input-sm item-list-wrapper" (change)="selectApiRoot($event)"
        [(ngModel)]="currentRootTitle" [disabled]="roleType === 2">
        <option value="" disabled selected></option>
        <option *ngFor="let r of apiRoots" [value]="r?.apiRoot?.title">
          {{ r?.apiRoot?.title }}
        </option>
      </select>
    </label>

    <label class="item-label">
      <div class="item-label-title">
        Collection
      </div>
      <select class="form-control dropdown input-sm item-list-wrapper" (change)="selectCollection($event)"
        [(ngModel)]="currentCollectionTitle" [disabled]="roleType === 2">
        <option value="null" disabled selected></option>
        <option *ngFor="let c of getCollectionsListFromRoot()" [value]="c['title']">
          {{ c['title'] }}
        </option>
        <!-- <option *ngFor="let c of getCollectionsList()" [value]="c['title']">
                {{ c['title'] }}
            </option> -->
      </select>
    </label>

    <label class="item-label">
      <div class="item-label-title">
        Can Read
      </div>
      <input *ngIf="collectionPerRolePermission?.can_read" type="checkbox" class="input-cb"
        [checked]="collectionPerRolePermission?.can_read ? roleDetail.can_read : collectionPerRolePermission.can_read"
        [(ngModel)]="roleDetail.can_read" />
      <input *ngIf="!collectionPerRolePermission?.can_read" type="checkbox"
        [disabled]="!collectionPerRolePermission?.can_read" class="input-cb"
        [checked]="collectionPerRolePermission?.can_read" />
    </label>

    <label class="item-label">
      <div class="item-label-title">
        Can Write
      </div>
      <input *ngIf="collectionPerRolePermission?.can_write" type="checkbox" class="mt-1 input-cb"
        [checked]="collectionPerRolePermission?.can_write ? roleDetail.can_write : collectionPerRolePermission.can_write"
        [(ngModel)]="roleDetail.can_write" />
      <input *ngIf="!collectionPerRolePermission?.can_write" type="checkbox"
        [disabled]="!collectionPerRolePermission?.can_write" class="mt-1 input-cb"
        [checked]="collectionPerRolePermission?.can_write" />
    </label>
  </div>

  <div class="item-action modal-footer">
    <button type="button" class="btn btn-sm btn-secondary mr-3" (click)="close()">
      <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
      Cancel
    </button>
    <button type="button" class="btn btn-sm btn-success" (click)="save()" [disabled]="disableSave()">
      <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
      Save
    </button>
  </div>

  <div *ngIf="errorMessages?.length > 0" class="row error-message">
    <div class="col">
      <div class="d-flex justify-content-center flex-column">
        <div *ngFor="let err of errorMessages;" class="alert alert-danger" role="alert">
          {{ err }}
        </div>
      </div>
    </div>
  </div>
</div>