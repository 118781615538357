export const PATH_ENC = [
    { key: 'US-ASCII', value: 'US-ASCII' },
    { key: 'ISO_8859-1:1987', value: 'ISO_8859-1:1987' },
    { key: 'ISO_8859-2:1987', value: 'ISO_8859-2:1987' },
    { key: 'ISO_8859-3:1988', value: 'ISO_8859-3:1988' },
    { key: 'ISO_8859-4:1988', value: 'ISO_8859-4:1988' },
    { key: 'ISO_8859-5:1988', value: 'ISO_8859-5:1988' },
    { key: 'ISO_8859-6:1987', value: 'ISO_8859-6:1987' },
    { key: 'ISO_8859-7:1987', value: 'ISO_8859-7:1987' },
    { key: 'ISO_8859-8:1988', value: 'ISO_8859-8:1988' },
    { key: 'ISO_8859-9:1989', value: 'ISO_8859-9:1989' },
    { key: 'ISO-8859-10', value: 'ISO-8859-10' },
    { key: 'ISO_6937-2-add', value: 'ISO_6937-2-add' },
    { key: 'JIS_X0201', value: 'JIS_X0201' },
    { key: 'JIS_Encoding', value: 'JIS_Encoding' },
    { key: 'Shift_JIS', value: 'Shift_JIS' },
    { key: 'Extended_UNIX_Code_Packed_Format_for_Japanese', value: 'Extended_UNIX_Code_Packed_Format_for_Japanese' },
    { key: 'Extended_UNIX_Code_Fixed_Width_for_Japanese', value: 'Extended_UNIX_Code_Fixed_Width_for_Japanese' },
    { key: 'BS_4730', value: 'BS_4730' },
    { key: 'SEN_850200_C', value: 'SEN_850200_C' },
    { key: 'IT', value: 'IT' },
    { key: 'ES', value: 'ES' },
    { key: 'DIN_66003', value: 'DIN_66003' },
    { key: 'NS_4551-1', value: 'NS_4551-1' },
    { key: 'NF_Z_62-010', value: 'NF_Z_62-010' },
    { key: 'ISO-10646-UTF-1', value: 'ISO-10646-UTF-1' },
    { key: 'ISO_646.basic:1983', value: 'ISO_646.basic:1983' },
    { key: 'INVARIANT', value: 'INVARIANT' },
    { key: 'ISO_646.irv:1983', value: 'ISO_646.irv:1983' },
    { key: 'NATS-SEFI', value: 'NATS-SEFI' },
    { key: 'NATS-SEFI-ADD', value: 'NATS-SEFI-ADD' },
    { key: 'NATS-DANO', value: 'NATS-DANO' },
    { key: 'NATS-DANO-ADD', value: 'NATS-DANO-ADD' },
    { key: 'SEN_850200_B', value: 'SEN_850200_B' },
    { key: 'KS_C_5601-1987', value: 'KS_C_5601-1987' },
    { key: 'ISO-2022-KR', value: 'ISO-2022-KR' },
    { key: 'EUC-KR', value: 'EUC-KR' },
    { key: 'ISO-2022-JP', value: 'ISO-2022-JP' },
    { key: 'ISO-2022-JP-2', value: 'ISO-2022-JP-2' },
    { key: 'JIS_C6220-1969-jp', value: 'JIS_C6220-1969-jp' },
    { key: 'JIS_C6220-1969-ro', value: 'JIS_C6220-1969-ro' },
    { key: 'PT', value: 'PT' },
    { key: 'greek7-old', value: 'greek7-old' },
    { key: 'latin-greek', value: 'latin-greek' },
    { key: 'NF_Z_62-010_(1973)', value: 'NF_Z_62-010_(1973)' },
    { key: 'Latin-greek-1', value: 'Latin-greek-1' },
    { key: 'ISO_5427', value: 'ISO_5427' },
    { key: 'JIS_C6226-1978', value: 'JIS_C6226-1978' },
    { key: 'BS_viewdata', value: 'BS_viewdata' },
    { key: 'INIS', value: 'INIS' },
    { key: 'INIS-8', value: 'INIS-8' },
    { key: 'INIS-cyrillic', value: 'INIS-cyrillic' },
    { key: 'ISO_5427:1981', value: 'ISO_5427:1981' },
    { key: 'ISO_5428:1980', value: 'ISO_5428:1980' },
    { key: 'GB_1988-80', value: 'GB_1988-80' },
    { key: 'GB_2312-80', value: 'GB_2312-80' },
    { key: 'NS_4551-2', value: 'NS_4551-2' },
    { key: 'videotex-suppl', value: 'videotex-suppl' },
    { key: 'PT2', value: 'PT2' },
    { key: 'ES2', value: 'ES2' },
    { key: 'MSZ_7795.3', value: 'MSZ_7795.3' },
    { key: 'JIS_C6226-1983', value: 'JIS_C6226-1983' },
    { key: 'greek7', value: 'greek7' },
    { key: 'ASMO_449', value: 'ASMO_449' },
    { key: 'iso-ir-90', value: 'iso-ir-90' },
    { key: 'JIS_C6229-1984-a', value: 'JIS_C6229-1984-a' },
    { key: 'JIS_C6229-1984-b', value: 'JIS_C6229-1984-b' },
    { key: 'JIS_C6229-1984-b-add', value: 'JIS_C6229-1984-b-add' },
    { key: 'JIS_C6229-1984-hand', value: 'JIS_C6229-1984-hand' },
    { key: 'JIS_C6229-1984-hand-add', value: 'JIS_C6229-1984-hand-add' },
    { key: 'JIS_C6229-1984-kana', value: 'JIS_C6229-1984-kana' },
    { key: 'ISO_2033-1983', value: 'ISO_2033-1983' },
    { key: 'ANSI_X3.110-1983', value: 'ANSI_X3.110-1983' },
    { key: 'T.61-7bit', value: 'T.61-7bit' },
    { key: 'T.61-8bit', value: 'T.61-8bit' },
    { key: 'ECMA-cyrillic', value: 'ECMA-cyrillic' },
    { key: 'CSA_Z243.4-1985-1', value: 'CSA_Z243.4-1985-1' },
    { key: 'CSA_Z243.4-1985-2', value: 'CSA_Z243.4-1985-2' },
    { key: 'CSA_Z243.4-1985-gr', value: 'CSA_Z243.4-1985-gr' },
    { key: 'ISO_8859-6-E', value: 'ISO_8859-6-E' },
    { key: 'ISO_8859-6-I', value: 'ISO_8859-6-I' },
    { key: 'T.101-G2', value: 'T.101-G2' },
    { key: 'ISO_8859-8-E', value: 'ISO_8859-8-E' },
    { key: 'ISO_8859-8-I', value: 'ISO_8859-8-I' },
    { key: 'CSN_369103', value: 'CSN_369103' },
    { key: 'JUS_I.B1.002', value: 'JUS_I.B1.002' },
    { key: 'IEC_P27-1', value: 'IEC_P27-1' },
    { key: 'JUS_I.B1.003-serb', value: 'JUS_I.B1.003-serb' },
    { key: 'JUS_I.B1.003-mac', value: 'JUS_I.B1.003-mac' },
    { key: 'greek-ccitt', value: 'greek-ccitt' },
    { key: 'NC_NC00-10:81', value: 'NC_NC00-10:81' },
    { key: 'ISO_6937-2-25', value: 'ISO_6937-2-25' },
    { key: 'GOST_19768-74', value: 'GOST_19768-74' },
    { key: 'ISO_8859-supp', value: 'ISO_8859-supp' },
    { key: 'ISO_10367-box', value: 'ISO_10367-box' },
    { key: 'latin-lap', value: 'latin-lap' },
    { key: 'JIS_X0212-1990', value: 'JIS_X0212-1990' },
    { key: 'DS_2089', value: 'DS_2089' },
    { key: 'us-dk', value: 'us-dk' },
    { key: 'dk-us', value: 'dk-us' },
    { key: 'KSC5636', value: 'KSC5636' },
    { key: 'UNICODE-1-1-UTF-7', value: 'UNICODE-1-1-UTF-7' },
    { key: 'ISO-2022-CN', value: 'ISO-2022-CN' },
    { key: 'ISO-2022-CN-EXT', value: 'ISO-2022-CN-EXT' },
    { key: 'UTF-8', value: 'UTF-8)' },
    { key: 'ISO-8859-13', value: 'ISO-8859-13' },
    { key: 'ISO-8859-14', value: 'ISO-8859-14' },
    { key: 'ISO-8859-15', value: 'ISO-8859-15' },
    { key: 'ISO-8859-16', value: 'ISO-8859-16' },
    { key: 'GBK', value: 'GBK' },
    { key: 'GB18030', value: 'GB18030' },
    { key: 'OSD_EBCDIC_DF04_15', value: 'OSD_EBCDIC_DF04_15' },
    { key: 'OSD_EBCDIC_DF03_IRV', value: 'OSD_EBCDIC_DF03_IRV' },
    { key: 'OSD_EBCDIC_DF04_1', value: 'OSD_EBCDIC_DF04_1' },
    { key: 'ISO-11548-1', value: 'ISO-11548-1' },
    { key: 'KZ-1048', value: 'KZ-1048' },
    { key: 'ISO-10646-UCS-2', value: 'ISO-10646-UCS-2' },
    { key: 'ISO-10646-UCS-4', value: 'ISO-10646-UCS-4' },
    { key: 'ISO-10646-UCS-Basic', value: 'ISO-10646-UCS-Basic' },
    { key: 'ISO-10646-Unicode-Latin1', value: 'ISO-10646-Unicode-Latin1' },
    { key: 'ISO-10646-J-1', value: 'ISO-10646-J-1' },
    { key: 'ISO-Unicode-IBM-1261', value: 'ISO-Unicode-IBM-1261' },
    { key: 'ISO-Unicode-IBM-1268', value: 'ISO-Unicode-IBM-1268' },
    { key: 'ISO-Unicode-IBM-1276', value: 'ISO-Unicode-IBM-1276' },
    { key: 'ISO-Unicode-IBM-1264', value: 'ISO-Unicode-IBM-1264' },
    { key: 'ISO-Unicode-IBM-1265', value: 'ISO-Unicode-IBM-1265' },
    { key: 'UNICODE-1-1', value: 'UNICODE-1-1' },
    { key: 'SCSU', value: 'SCSU' },
    { key: 'UTF-7', value: 'UTF-7' },
    { key: 'UTF-16BE', value: 'UTF-16BE' },
    { key: 'UTF-16LE', value: 'UTF-16LE' },
    { key: 'UTF-16', value: 'UTF-16' },
    { key: 'CESU-8', value: 'CESU-8' },
    { key: 'UTF-32', value: 'UTF-32' },
    { key: 'UTF-32BE', value: 'UTF-32BE' },
    { key: 'UTF-32LE', value: 'UTF-32LE' },
    { key: 'BOCU-1', value: 'BOCU-1' },
    { key: 'UTF-7-IMAP', value: 'UTF-7-IMAP' },
    { key: 'ISO-8859-1-Windows-3.0-Latin-1', value: 'ISO-8859-1-Windows-3.0-Latin-1' },
    { key: 'ISO-8859-1-Windows-3.1-Latin-1', value: 'ISO-8859-1-Windows-3.1-Latin-1' },
    { key: 'ISO-8859-2-Windows-Latin-2', value: 'ISO-8859-2-Windows-Latin-2' },
    { key: 'ISO-8859-9-Windows-Latin-5', value: 'ISO-8859-9-Windows-Latin-5' },
    { key: 'hp-roman8', value: 'hp-roman8' },
    { key: 'Adobe-Standard-Encoding', value: 'Adobe-Standard-Encoding' },
    { key: 'Ventura-US', value: 'Ventura-US' },
    { key: 'Ventura-International', value: 'Ventura-International' },
    { key: 'DEC-MCS', value: 'DEC-MCS' },
    { key: 'IBM850', value: 'IBM850' },
    { key: 'PC8-Danish-Norwegian', value: 'PC8-Danish-Norwegian' },
    { key: 'IBM862', value: 'IBM862' },
    { key: 'PC8-Turkish', value: 'PC8-Turkish' },
    { key: 'IBM-Symbols', value: 'IBM-Symbols' },
    { key: 'IBM-Thai', value: 'IBM-Thai' },
    { key: 'HP-Legal', value: 'HP-Legal' },
    { key: 'HP-Pi-font', value: 'HP-Pi-font' },
    { key: 'HP-Math8', value: 'HP-Math8' },
    { key: 'Adobe-Symbol-Encoding', value: 'Adobe-Symbol-Encoding' },
    { key: 'HP-DeskTop', value: 'HP-DeskTop' },
    { key: 'Ventura-Math', value: 'Ventura-Math' },
    { key: 'Microsoft-Publishing', value: 'Microsoft-Publishing' },
    { key: 'Windows-31J', value: 'Windows-31J' },
    { key: 'GB2312', value: 'GB2312' },
    { key: 'Big5', value: 'Big5' },
    { key: 'macintosh', value: 'macintosh' },
    { key: 'IBM037', value: 'IBM037' },
    { key: 'IBM038', value: 'IBM038' },
    { key: 'IBM273', value: 'IBM273' },
    { key: 'IBM274', value: 'IBM274' },
    { key: 'IBM275', value: 'IBM275' },
    { key: 'IBM277', value: 'IBM277' },
    { key: 'IBM278', value: 'IBM278' },
    { key: 'IBM280', value: 'IBM280' },
    { key: 'IBM281', value: 'IBM281' },
    { key: 'IBM284', value: 'IBM284' },
    { key: 'IBM285', value: 'IBM285' },
    { key: 'IBM290', value: 'IBM290' },
    { key: 'IBM297', value: 'IBM297' },
    { key: 'IBM420', value: 'IBM420' },
    { key: 'IBM423', value: 'IBM423' },
    { key: 'IBM424', value: 'IBM424' },
    { key: 'IBM437', value: 'IBM437' },
    { key: 'IBM500', value: 'IBM500' },
    { key: 'IBM851', value: 'IBM851' },
    { key: 'IBM852', value: 'IBM852' },
    { key: 'IBM855', value: 'IBM855' },
    { key: 'IBM857', value: 'IBM857' },
    { key: 'IBM860', value: 'IBM860' },
    { key: 'IBM861', value: 'IBM861' },
    { key: 'IBM863', value: 'IBM863' },
    { key: 'IBM864', value: 'IBM864' },
    { key: 'IBM865', value: 'IBM865' },
    { key: 'IBM868', value: 'IBM868' },
    { key: 'IBM869', value: 'IBM869' },
    { key: 'IBM870', value: 'IBM870' },
    { key: 'IBM871', value: 'IBM871' },
    { key: 'IBM880', value: 'IBM880' },
    { key: 'IBM891', value: 'IBM891' },
    { key: 'IBM903', value: 'IBM903' },
    { key: 'IBM904', value: 'IBM904' },
    { key: 'IBM905', value: 'IBM905' },
    { key: 'IBM918', value: 'IBM918' },
    { key: 'IBM1026', value: 'IBM1026' },
    { key: 'EBCDIC-AT-DE', value: 'EBCDIC-AT-DE' },
    { key: 'EBCDIC-AT-DE-A', value: 'EBCDIC-AT-DE-A' },
    { key: 'EBCDIC-CA-FR', value: 'EBCDIC-CA-FR' },
    { key: 'EBCDIC-DK-NO', value: 'EBCDIC-DK-NO' },
    { key: 'EBCDIC-DK-NO-A', value: 'EBCDIC-DK-NO-A' },
    { key: 'EBCDIC-FI-SE', value: 'EBCDIC-FI-SE' },
    { key: 'EBCDIC-FI-SE-A', value: 'EBCDIC-FI-SE-A' },
    { key: 'EBCDIC-FR', value: 'EBCDIC-FR' },
    { key: 'EBCDIC-IT', value: 'EBCDIC-IT' },
    { key: 'EBCDIC-PT', value: 'EBCDIC-PT' },
    { key: 'EBCDIC-ES', value: 'EBCDIC-ES' },
    { key: 'EBCDIC-ES-A', value: 'EBCDIC-ES-A' },
    { key: 'EBCDIC-ES-S', value: 'EBCDIC-ES-S' },
    { key: 'EBCDIC-UK', value: 'EBCDIC-UK' },
    { key: 'EBCDIC-US', value: 'EBCDIC-US' },
    { key: 'UNKNOWN-8BIT', value: 'UNKNOWN-8BIT' },
    { key: 'MNEMONIC', value: 'MNEMONIC' },
    { key: 'MNEM', value: 'MNEM' },
    { key: 'VISCII', value: 'VISCII' },
    { key: 'VIQR', value: 'VIQR' },
    { key: 'KOI8-R', value: 'KOI8-R' },
    { key: 'HZ-GB-2312', value: 'HZ-GB-2312' },
    { key: 'IBM866', value: 'IBM866' },
    { key: 'IBM775', value: 'IBM775' },
    { key: 'KOI8-U', value: 'KOI8-U' },
    { key: 'IBM00858', value: 'IBM00858' },
    { key: 'IBM00924', value: 'IBM00924' },
    { key: 'IBM01140', value: 'IBM01140' },
    { key: 'IBM01141', value: 'IBM01141' },
    { key: 'IBM01142', value: 'IBM01142' },
    { key: 'IBM01143', value: 'IBM01143' },
    { key: 'IBM01144', value: 'IBM01144' },
    { key: 'IBM01145', value: 'IBM01145' },
    { key: 'IBM01146', value: 'IBM01146' },
    { key: 'IBM01147', value: 'IBM01147' },
    { key: 'IBM01148', value: 'IBM01148' },
    { key: 'IBM01149', value: 'IBM01149' },
    { key: 'Big5-HKSCS', value: 'Big5-HKSCS' },
    { key: 'IBM1047', value: 'IBM1047' },
    { key: 'PTCP154', value: 'PTCP154' },
    { key: 'Amiga-1251', value: 'Amiga-1251' },
    { key: 'KOI7-switched', value: 'KOI7-switched' },
    { key: 'BRF', value: 'BRF' },
    { key: 'TSCII', value: 'TSCII' },
    { key: 'CP51932', value: 'CP51932' },
    { key: 'windows-874', value: 'windows-874' },
    { key: 'windows-1250', value: 'windows-1250' },
    { key: 'windows-1251', value: 'windows-1251' },
    { key: 'windows-1252', value: 'windows-1252' },
    { key: 'windows-1253', value: 'windows-1253' },
    { key: 'windows-1254', value: 'windows-1254' },
    { key: 'windows-1255', value: 'windows-1255' },
    { key: 'windows-1256', value: 'windows-1256' },
    { key: 'windows-1257', value: 'windows-1257' },
    { key: 'windows-1258', value: 'windows-1258' },
    { key: 'TIS-620', value: 'TIS-620' },
    { key: 'CP50220', value: 'CP50220' },
  ];
  