import { Component, OnInit } from '@angular/core';
import { faArrowRight, faArrowLeft, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from './alert-service/alert.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { StixService } from 'src/app/stix-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-history',
  templateUrl: './alert-history.component.html',
  styleUrls: ['./alert-history.component.css']
})
export class AlertHistoryComponent implements OnInit {

  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;

  alertTable: any[] = [];

  isLoading: boolean = false;
  isSearching: boolean = false;

  currentPage: number = 1;
  totalPages: number = 0;
  rowNumber: number = -1;

  alertResults: any = {};

  sortKey: string = 'na';

  apiRootDetails = [];
  targets = [];

  httpHeaders = new HttpHeaders()
    .set('Accept', 'application/taxii+json;version=2.1')
    .set('Authorization', `Basic ${btoa(environment.taxiiServer.username + ":" + environment.taxiiServer.password)}`);

  constructor(private alertService: AlertService, private httpClient: HttpClient, private stixService: StixService, private router: Router) { }

  ngOnInit(): void {
    this.getAlerts();
  }

  async getAlerts(){
    this.isLoading = true;
    this.targets = [];

    this.apiRootDetails = await this.getApiRoots();
    let result: any = await this.alertService.getAlerts(this.currentPage, this.sortKey);
    this.totalPages = Math.ceil(result.total.value/15);

    for(let hit of result.hits){
      switch(hit._source.query.type){
        case 'collection':
          let colFound = false;

          for(let root of this.apiRootDetails){
            console.log(hit._source.query);
            if(hit._source.query.apiRoot === root.apiPath){

              for(let col of root.collections){
                if(col.id === hit._source.query.collectionID){
                  colFound = true;
                  this.targets.push(`${root.title}/${col.title}`);
                }
              }

              if(colFound === true) break;
            }
          }

          if(colFound === false) this.targets.push("Did not find Target");
          break;
      }
    }
    this.alertTable = result.hits;
    console.log(this.alertTable);

    this.isLoading = false;
  }

  async getApiRootInfo(rootUrl){
    return new Promise((resolve) => {
      this.httpClient.get(rootUrl, { headers: this.httpHeaders }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  async getCollectionsInfo(rootUrl){
    return new Promise((resolve) => {
      this.httpClient.get(`${rootUrl}collections/`, { headers: this.httpHeaders }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  async getApiRoots(): Promise<any[]> {
    let that = this;
    this.apiRootDetails = [];

    let getDiscoveryURL = `${environment.taxiiServer.url}taxii/${environment.taxiiServer.apiVersion + '/'}taxii2/`;
    return new Promise(async (resolve) => {
      this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
        let taxiiResult = this.httpClient.get(getDiscoveryURL, { headers: this.httpHeaders }).subscribe(
          async (data: any) => {
            try {
              if (data && data.api_roots) {
                let tempRoots = [];

                for (let i = 0; i < data.api_roots.length; i++) {
                  let currRoot = data.api_roots[i];
                  let splitRoot = currRoot.split('/');
      
                  let rootInfo = await this.getApiRootInfo(currRoot)
                  let collectionsInfo: any = await this.getCollectionsInfo(currRoot);
                  let collections = collectionsInfo.collections.filter(collection =>
                    collection.can_write === true
                  );

                  if(collections.length !== 0){
                    rootInfo['apiPath'] = splitRoot[splitRoot.length -2];
                    rootInfo['collections'] = collections;
                    tempRoots.push(rootInfo);
                  }
                }
                resolve(tempRoots);
              }
            } catch (e) {
              console.log(e);
              resolve([]);
              return;
            }
          });
      });
    });
  }

  getTarget(index){
    return(this.targets[index]);
  }

  getHits(index){
    if(this.alertTable[index]._source.hits > 50){
      return("50+");
    } else {
      return this.alertTable[index]._source.hits;
    }
  }

  async changeShow(index){
    let result = confirm('This will open a new tab and search for objects, click confirm to continue');

    if(result === true){
      //Update read on alert
      if(this.alertTable[index]._source.read === false){
        this.alertTable[index]._source.read = true;
        let updateRes = await this.alertService.updateAlert(this.alertTable[index]);
        this.stixService.getUnreadAlerts();
      }

      //Store reference in stixService
      localStorage['currentAlert'] = JSON.stringify(this.alertTable[index]);

      //Redirect to new tab to query page (use stix service to hopefully populate)
      window.open('/query-stix');
    }
    
  }

  nextPage(direction){
    switch(direction){
      case 'left':
        this.currentPage--;
        break;
      case 'right':
        this.currentPage++;
        break;
    }
    this.getAlerts();
  }

  sort(field){

  }
}
