<div class="d-flex flex-column justify-content-start align-items-center mb-3">
    <div class="d-flex flex-row justify-content-around align-items-end gr-top-width">
        <div class="w-75 pl-5 d-flex align-items-end">
            <div class="mb-3">
                <img class="card-img" src="./assets/sword.svg" class="center" width="80" height="80" />
            </div>
            <span class="title-main-size3">HOW?</span>
        </div>
        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
        <!-- <div class="title-sub-size1-2 mb-3">How did you detect that this cyber threat event occured?
        </div> -->
    </div>

    <div class="horizonal-rule-top"></div>
    <div class="d-flex justify-content-center align-items-start w-80">
        <div class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">Event Detection</h6>
            <div class="gr-help-section-content">
                <p>Enter a label or designation. If you don't have one, it will be autogenerated for you</p>
                <p>For the determination, select whether the cyber threat event was a failed attempt,
                    blocked, confirmed, a false positive, or just suspected.</p>
                <p>Is this attack being investigated? If so, what is the status of the investigation?</p>
                <p>Select how the attack was detected using the list menu.</p>
                <p>Event Details: please tell us what happened by providing contextual information.</p>
            </div>
        </div>
        <div class="d-flex flex-column align-items-left gr-outter-wrapper mt-5 gr-scroll-wrapper" style="position: relative;">
            <div class="px-4 pt-3 pb-3">

                <div class="d-flex align-items-center gr-input-wrapper px-4 mt-4">
                    <span class="w-100 font-weight-bold" title="">How did you detect that this cyber threat event occurred, and what is its status?</span>
                </div>

                <div class="d-flex align-items-center gr-input-wrapper px-4 mt-4">
                    <span class="w-25">Cyber Threat Event
                        <span class="guided-tip">
                            <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                            <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('incident_name')"></span>
                        </span>
                    </span>
                    <input type="text" [(ngModel)]="incidentName" class="form-control w-75 noborder"
                        placeholder="Enter a name or designation. One will be autogenerated if not provided."/>
                </div>

                <div class="d-flex align-items-center gr-input-wrapper px-4 mt-4">
                    <span class="w-25">Determination<span class="text-danger ml-1">*</span>
                        <span class="guided-tip">
                            <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                            <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('determination')"></span>
                        </span>
                    </span>
                    <select class="form-control w-75 noborder" type="text" [(ngModel)]="determination" (change)="isFormValid()">
                        <option disabled selected style="color: #91a4bb;" value="">Select Determination</option>
                        <option *ngFor="let option of determinationOptions" [value]="option.key">{{option.value}}</option>
                    </select>
                </div>

                <div class="d-flex align-items-center gr-input-wrapper px-4 mt-4">
                    <span class="w-25">Investigation Status<span class="text-danger ml-1">*</span>
                        <span class="guided-tip">
                            <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                            <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('investigation_status')"></span>
                        </span>
                    </span>
                    <select class="form-control w-75 noborder" type="text" [(ngModel)]="investigationStatus" (change)="isFormValid()">
                        <option disabled selected style="color: #91a4bb;" value="">Select Investigation Status</option>
                        <option *ngFor="let option of investigationStatusOptions" [value]="option.key">{{option.value}}</option>
                    </select>
                </div>

                <div class="d-flex align-items-center gr-input-wrapper px-4 mt-4">
                    <span class="w-25">Detection Methods
                        <span class="guided-tip">
                            <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                            <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('detection_methods')"></span>
                        </span>
                    </span>
                    <select class="form-control w-75 my-2 noborder" type="text" style="height: 11.7em !important" [(ngModel)]="detectionMethods" 
                            [disabled]="!(determination&&investigationStatus)" multiple size="4">
                            <option disabled selected style="color: #91a4bb;" value="">Select Detection Methods</option>
                            <option *ngFor="let option of detectionOptions" [value]="option.key">{{option.value}}</option>
                    </select>
                </div>

                <div class="align-items-center gr-input-wrapper px-4 mt-4 mb-2" style="position: relative;">
                    <div class="col-12 p-0 d-flex">
                    <span class="w-25 mt-5">Event Details
                        <span class="guided-tip">
                            <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                            <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('event_details')"></span>
                        </span>
                    </span>
                        <textarea class="form-control w-75 my-2 noborder" placeholder="Please add distinct details about the event" rows="3" wrap="soft" 
                            [(ngModel)]="description" [disabled]="!(determination&&investigationStatus)" (input)="checkCart()">
                        </textarea> 
                    </div>
                    <app-guided-cart class="w-100 p-0" #guidedCart [currentComponent]="component" [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [editModeEvent]="editModeEmitter" [syncCartEvent]="syncCartEmitter" [getEditItemEvent]="getEditItemEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart>
                </div>
            </div>

            <div class="w-100 d-flex justify-content-end mb-3">
                <div class="d-flex assist-text" style="right: 2vw" (click)="showAssistance.emit()">
                    <h6 class="mt-2 mr-1 assist-text">Need Help?</h6>
                    <fa-icon class="faQuestionCircle" [icon]="faQuestionCircle"></fa-icon>
                </div>
            </div>
        </div>
    </div>
</div>