import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AddComponentComponent } from 'src/app/add-component/add-component.component';
import { GuidedService } from 'src/app/guided.service';
import { StixService } from 'src/app/stix-service.service';
import { faCartPlus, faShoppingCart, faQuestionCircle, faPlus, faEdit, faTrash, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';
import { GuidedCartComponent } from '../guided-cart/guided-cart.component';

@Component({
  selector: 'app-why-attacker',
  templateUrl: './why-attacker.component.html',
  styleUrls: ['./why-attacker.component.css']
})
export class WhyAttackerComponent implements OnInit {
  @ViewChild("guidedCart", { static: false }) guidedCart: GuidedCartComponent;

  //With Cart
  @Output() disableCartEmitter = new EventEmitter<boolean>();
  @Output() newItemEmitter = new EventEmitter<any>();
  @Output() editModeEmitter = new EventEmitter<boolean>();
  @Output() syncCartEmitter = new EventEmitter<any>();
  @Output() getEditItemEmitter = new EventEmitter<any>();

  //With Parent
  @Output() showAssistance = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();

  component: string = 'why-attacker'

  @ViewChild("appAddComponent", { static: false }) appAddComponent: AddComponentComponent;

  faCartPlus = faCartPlus;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;
  faCart = faShoppingCart;
  faQuestionCircle = faQuestionCircle;
  faPuzzle = faPuzzlePiece

  currentAttackerName = '';
  currentAttackerRoles = [];
  currentAttackerMotivation = '';
  types = [];
  data = [];
  actionMode = 'add';
  nameToUpdate = '';
  errorMessage = '';

  editMode: boolean = false;
  cartIds: number[] = [];

  objectSelection = null;

  attackerOptions = [];

  roleOptions: any[] = [
    { key: 'agent', value: 'Agent' },
    { key: 'director', value: 'Director' },
    { key: 'independent', value: 'Independent' },
    { key: 'infrastructure-architect', value: 'Infrastructure Architect' },
    { key: 'infrastructure-operator', value: 'Infrastructure Operator' },
    { key: 'malware-author', value: 'Malware Author' },
    { key: 'sponsor', value: 'Sponsor' }
  ]

  motivationOptions: any[] = [
    { key: 'accidental', value: 'Accidental' },
    { key: 'coercion', value: 'Coercion' },
    { key: 'dominance', value: 'Dominance' },
    { key: 'ideology', value: 'Ideology' },
    { key: 'notoriety', value: 'Notoriety' },
    { key: 'organizational-gain', value: 'Organizational Gain' },
    { key: 'personal-gain', value: 'Personal Gain' },
    { key: 'personal-satisfaction', value: 'Personal Satisfaction' },
    { key: 'revenge', value: 'Revenge' },
    { key: 'unpredictable', value: 'Unpredictable' },
  ]

  addedObjects = false;

  constructor(
    public guidedService: GuidedService,
    public stixService: StixService,
  ) { }

  ngOnInit(): void {
    this.objectSelection = this.guidedService.allIOCObjects.find(o => o.routeName === 'threat-actor');
    this.nameToUpdate = '';
    this.attackerOptions = this.guidedService.attackers;
    // this.attackerOptions = [ 'attcker 1', 'attcker 2', 'attcker 3' ];

    const editCartTimeout = setTimeout( () => {
      if(this.guidedService.editCartObject.component && this.guidedService.editCartObject.component === this.component){
        this.getEditItemEmitter.emit(this.guidedService.editCartObject);
      }
    }, 100);
  }

  addCartItem(cancel){
    if(cancel){
      this.reset();
      this.checkCart();
      return;
    }

    let threatActorObject = {
      type: 'threat-actor',
      name: this.currentAttackerName
    }

    if(this.currentAttackerMotivation !== ''){
      threatActorObject['primary_motivation'] = this.currentAttackerMotivation;
    }

    if(this.currentAttackerRoles.length !== 0){
      threatActorObject['roles'] = this.currentAttackerRoles;
    }

    threatActorObject['cartId'] = this.cartIds[0] ? this.cartIds[0] : null;

    let emitObj = {
      mode: this.component,
      push: [
        threatActorObject
      ]
    }

    this.newItemEmitter.emit(emitObj);
    this.disableCartEmitter.emit(true);
    this.reset();
  }

  addComponents() {
    if(this.guidedCart.cartLength === 0){
      this.reset();
      return;
    }
    let types = [];
    let data = [];

    for(let type in this.guidedCart.cart){
      let objects = this.guidedCart.cart[type];

      for(let obj of objects){
        types.push(obj.type);

        if(obj.display_name) delete obj.display_name;
        if(obj.cartId) delete obj.cartId;
        if(obj.linkId) delete obj.linkId;

        data.push(obj);
      }
    }

    this.guidedService.addComponents(types, data);
    this.reset();

    if (this.guidedService.autoGenRelationships) {
      // Build relationship: Threat-actor --- Location (map all Threat Actor to each target and attacker Location, many to many)
      // Build relationship: Observable attacker alignment --- Threat Actor (map each observable attacker alignment to threat actor sequentially)
      setTimeout(() => {
        let targetLocations: any = this.guidedService.locations.filter(l => l.flag === 'target');
        let attackerLocations: any = this.guidedService.locations.filter(l => l.flag === 'attacker');
        let attackers = this.guidedService.observablesWithAlignment.filter(o => o.alignment === 'Attacker');

        this.guidedService.findPreviouslyAddedObjects('threat-actor', 'threat-actor-attacker');
        this.guidedService.addedObjects.subscribe(addedObjects => {
          if (addedObjects.eventType === 'threat-actor-attacker'
            && addedObjects.objects
            && addedObjects.objects.length > 0
            && !this.addedObjects) {

            this.addedObjects = true;
            let threatActors = addedObjects.objects;

            if (targetLocations.length > 0 || attackerLocations.length > 0) {
              attackerLocations.forEach(attackerLocation => {
                threatActors.forEach(o => {
                  this.guidedService.createRelationships(
                    'located-at',
                    o.id,
                    attackerLocation.id
                  )
                })
              })

              targetLocations.forEach(targetLocation => {
                threatActors.forEach(o => {
                  this.guidedService.createRelationships(
                    'targets',
                    o.id,
                    targetLocation.id
                  )
                })
              })
            }

            if (attackers.length > 0) {
              let threatActorCounter = 0;
              threatActors.forEach(i => {
                  if (attackers.length > 0
                    && attackers[threatActorCounter]) {
                      this.guidedService.createRelationships(
                        'related-to',
                        attackers[threatActorCounter].id,
                        i.id
                      )
                    }
    
                  ++threatActorCounter;
              })
            }
          }
        })
      }, 300)
    }
    
  }

  checkCart(){
    if(this.currentAttackerName !== ''){
      this.disableCartEmitter.emit(false);
      return;
    }

    this.disableCartEmitter.emit(true);
  }

  editCartItem(event){
    this.reset();
    this.editMode = true;

    this.cartIds = [ event.cartId ];

    this.currentAttackerName = event.name;

    if(event.primary_motivation){
      this.currentAttackerMotivation = event.primary_motivation;
    }

    if(event.roles){
      this.currentAttackerRoles = event.roles;
    }

    this.checkCart();
  }

  reset() {
    this.currentAttackerName = '';
    this.currentAttackerRoles = [];
    this.currentAttackerMotivation = '';
    
    this.nameToUpdate = '';
    this.errorMessage = '';

    this.editMode = false;
    this.cartIds = [];
  }

  redirectCart(event){
    if(event.component !== this.component){
      this.changePage.emit(event);
    } else {
      this.getEditItemEmitter.emit(event);
    }
  }

  syncCart(event){
    if(event === this.component){
      this.syncCartEmitter.emit();
    }
  }

  showAssistanceTrigger(){
    this.showAssistance.emit();
  }

}
