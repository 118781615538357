export const MIME = [

    {key:'application/A2L', value:'	application/A2L'},
    {key:'application/AML', value:'	application/AML'},
    {key:'application/ATF', value:'	application/ATF'},
    {key:'application/CEA', value:'	application/CEA'},
    {key:'application/cfw', value:'	application/cfw'},
    {key:'application/clr', value:'	application/clr'},
    {key:'application/cms', value:'	application/cms'},
    {key:'application/cwl', value:'	application/cwl'},
    {key:'application/cwt', value:'	application/cwt'},
    {key:'application/DCD', value:'	application/DCD'},
    {key:'application/DII', value:'	application/DII'},
    {key:'application/DIT', value:'	application/DIT'},
    {key:'application/dns', value:'	application/dns'},
    {key:'application/efi', value:'	application/efi'},
    {key:'application/exi', value:'	application/exi'},
    {key:'application/fdf', value:'	application/fdf'},
    {key:'application/ipp', value:'	application/ipp'},
    {key:'application/jwt', value:'	application/jwt'},
    {key:'application/LXF', value:'	application/LXF'},
    {key:'application/MF4', value:'	application/MF4'},
    {key:'application/mp4', value:'	application/mp4'},
    {key:'application/mxf', value:'	application/mxf'},
    {key:'application/nss', value:'	application/nss'},
    {key:'application/ODA', value:'	application/ODA'},
    {key:'application/ODX', value:'	application/ODX'},
    {key:'application/ogg', value:'	application/ogg'},
    {key:'application/p21', value:'	application/p21'},
    {key:'application/pdf', value:'	application/pdf'},
    {key:'application/PDX', value:'	application/PDX'},
    {key:'application/rtf', value:'	application/rtf'},
    {key:'application/rtx', value:'	application/rtx'},
    {key:'application/sbe', value:'	application/sbe'},
    {key:'application/sdp', value:'	application/sdp'},
    {key:'application/sql', value:'	application/sql'},
    {key:'application/xml', value:'	application/xml'},
    {key:'application/zip', value:'	application/zip'},
    {key:'application/ATFX', value:'application/ATFX'},
    {key:'application/cbor', value:'application/cbor'},
    {key:'application/cdni', value:'application/cdni'},
    {key:'application/cose', value:'application/cose'},
    {key:'application/dvcs', value:'application/dvcs'},
    {key:'application/fits', value:'application/fits'},
    {key:'application/gzip', value:'application/gzip'},
    {key:'application/H224', value:'application/H224'},
    {key:'application/http', value:'application/http'},
    {key:'application/iges', value:'application/iges'},
    {key:'application/IOTP', value:'application/IOTP'},
    {key:'application/ISUP', value:'application/ISUP'},
    {key:'application/jose', value:'application/jose'},
    {key:'application/json', value:'application/json'},
    {key:'application/marc', value:'application/marc'},
    {key:'application/mbox', value:'application/mbox'},
    {key:'application/mipc', value:'application/mipc'},
    {key:'application/mp21', value:'application/mp21'},
    {key:'application/node', value:'application/node'},
    {key:'application/oxps', value:'application/oxps'},
    {key:'application/QSIG', value:'application/QSIG'},
    {key:'application/SGML', value:'application/SGML'},
    {key:'application/sipc', value:'application/sipc'},
    {key:'application/smil', value:'application/smil'},
    {key:'application/srgs', value:'application/srgs'},
    {key:'application/trig', value:'application/trig'},
    {key:'application/tzif', value:'application/tzif'},
    {key:'application/wasm', value:'application/wasm'},
    {key:'application/wita', value:'application/wita'},
    {key:'application/xfdf', value:'application/xfdf'},
    {key:'application/yang', value:'application/yang'},
    {key:'application/zlib', value:'application/zlib'},
    {key:'application/zstd', value:'application/zstd'},
    {key:'application/ATXML',value:'application/ATXML'},
    {key:'application/cccex',value:'application/cccex'},
    {key:'application/dicom',value:'application/dicom'},
    {key:'application/eshop',value:'application/eshop'},
    {key:'application/index',value:'application/index'},
    {key:'application/ipfix',value:'application/ipfix'},
    {key:'application/mikey',value:'application/mikey'},
    {key:'application/pkcs8',value:'application/pkcs8'},
    {key:'application/sieve',value:'application/sieve'},
    {key:'application/slate',value:'application/slate'},
    {key:'application/vemmi',value:'application/vemmi'},
    {key:'application/at+jwt', value:'application/at+jwt'},
    {key:'application/dec-dx', value:'application/dec-dx'},
    {key:'application/msword', value:'application/msword'},
    {key:'application/oscore', value:'application/oscore'},
    {key:'application/pkcs10', value:'application/pkcs10'},
    {key:'application/pkcs12', value:'application/pkcs12'},
    {key:'application/riscos', value:'application/riscos'},
    {key:'application/ulpfec', value:'application/ulpfec'},
    {key:'application/widget', value:'application/widget'},
    {key:'application/xv+xml', value:'application/xv+xml'},
    {key:'application/cda+xml', value:'application/cda+xml'},
    {key:'application/cpl+xml', value:'application/cpl+xml'},
    {key:'application/dca-rft', value:'application/dca-rft'},
    {key:'application/EDIFACT', value:'application/EDIFACT'},
    {key:'application/EDI-X12', value:'application/EDI-X12'},
    {key:'application/elm+xml', value:'application/elm+xml'},
    {key:'application/epp+xml', value:'application/epp+xml'},
    {key:'application/example', value:'application/example'},
    {key:'application/express', value:'application/express'},
    {key:'application/fdt+xml', value:'application/fdt+xml'},
    {key:'application/flexfec', value:'application/flexfec'},
    {key:'application/gml+xml', value:'application/gml+xml'},
    {key:'application/its+xml', value:'application/its+xml'},
    {key:'application/ld+json', value:'application/ld+json'},
    {key:'application/lgr+xml', value:'application/lgr+xml'},
    {key:'application/linkset', value:'application/linkset'},
    {key:'application/lpf+zip', value:'application/lpf+zip'},
    {key:'application/n-quads', value:'application/n-quads'},
    {key:'application/nasdata', value:'application/nasdata'},
    {key:'application/odm+xml', value:'application/odm+xml'},
    {key:'application/p21+zip', value:'application/p21+zip'},
    {key:'application/pkixcmp', value:'application/pkixcmp'},
    {key:'application/pls+xml', value:'application/pls+xml'},
    {key:'application/prs.cww', value:'application/prs.cww'},
    {key:'application/prs.cyn', value:'application/prs.cyn'},
    {key:'application/rdf+xml', value:'application/rdf+xml'},
    {key:'application/rfc+xml', value:'application/rfc+xml'},
    {key:'application/sep-exi', value:'application/sep-exi'},
    {key:'application/sep+xml', value:'application/sep+xml'},
    {key:'application/shf+xml', value:'application/shf+xml'},
    {key:'application/sru+xml', value:'application/sru+xml'},
    {key:'application/td+json', value:'application/td+json'},
    {key:'application/tei+xml', value:'application/tei+xml'},
    {key:'application/vnd.age', value:'application/vnd.age'},
    {key:'application/vnd.bmi', value:'application/vnd.bmi'},
    {key:'application/vnd.bpf', value:'application/vnd.bpf'},
    {key:'application/vnd.dbf', value:'application/vnd.dbf'},
    {key:'application/vnd.dna', value:'application/vnd.dna'},
    {key:'application/vnd.dxr', value:'application/vnd.dxr'},
    {key:'application/vnd.dzr', value:'application/vnd.dzr'},
    {key:'application/vnd.gmx', value:'application/vnd.gmx'},
    {key:'application/vnd.hdt', value:'application/vnd.hdt'},
    {key:'application/vnd.jam', value:'application/vnd.jam'},
    {key:'application/vnd.las', value:'application/vnd.las'},
    {key:'application/vnd.mcd', value:'application/vnd.mcd'},
    {key:'application/vnd.mif', value:'application/vnd.mif'},
    {key:'application/vnd.obn', value:'application/vnd.obn'},
    {key:'application/vnd.rar', value:'application/vnd.rar'},
    {key:'application/vnd.sar', value:'application/vnd.sar'},
    {key:'application/vnd.shp', value:'application/vnd.shp'},
    {key:'application/vnd.shx', value:'application/vnd.shx'},
    {key:'application/vnd.svd', value:'application/vnd.svd'},
    {key:'application/vnd.tml', value:'application/vnd.tml'},
    {key:'application/vnd.vcx', value:'application/vnd.vcx'},
    {key:'application/vnd.vsf', value:'application/vnd.vsf'},
    {key:'application/vnd.wmc', value:'application/vnd.wmc'},
    {key:'application/vnd.wqd', value:'application/vnd.wqd'},
    {key:'application/vnd.zul', value:'application/vnd.zul'},
    {key:'application/x400-bp', value:'application/x400-bp'},
    {key:'application/xml-dtd', value:'application/xml-dtd'},
    {key:'application/xop+xml', value:'application/xop+xml'},
    {key:'application/yin+xml', value:'application/yin+xml'},
    {key:'application/ace+cbor', value:'application/ace+cbor'},
    {key:'application/ace+json', value:'application/ace+json'},
    {key:'application/aif+cbor', value:'application/aif+cbor'},
    {key:'application/aif+json', value:'application/aif+json'},
    {key:'application/atom+xml', value:'application/atom+xml'},
    {key:'application/beep+xml', value:'application/beep+xml'},
    {key:'application/cbor-seq', value:'application/cbor-seq'},
    {key:'application/ccmp+xml', value:'application/ccmp+xml'},
    {key:'application/CDFX+XML', value:'application/CDFX+XML'},
    {key:'application/clue+xml', value:'application/clue+xml'},
    {key:'application/cnrp+xml', value:'application/cnrp+xml'},
    {key:'application/cose-key', value:'application/cose-key'},
    {key:'application/csrattrs', value:'application/csrattrs'},
    {key:'application/csta+xml', value:'application/csta+xml'},
    {key:'application/cwl+json', value:'application/cwl+json'},
    {key:'application/dash+xml', value:'application/dash+xml'},
    {key:'application/dns+json', value:'application/dns+json'},
    {key:'application/dssc+der', value:'application/dssc+der'},
    {key:'application/dssc+xml', value:'application/dssc+xml'},
    {key:'application/elm+json', value:'application/elm+json'},
    {key:'application/emma+xml', value:'application/emma+xml'},
    {key:'application/encaprtp', value:'application/encaprtp'},
    {key:'application/epub+zip', value:'application/epub+zip'},
    {key:'application/fastsoap', value:'application/fastsoap'},
    {key:'application/fhir+xml', value:'application/fhir+xml'},
    {key:'application/geo+json', value:'application/geo+json'},
    {key:'application/held+xml', value:'application/held+xml'},
    {key:'application/jrd+json', value:'application/jrd+json'},
    {key:'application/json-seq', value:'application/json-seq'},
    {key:'application/jwk+json', value:'application/jwk+json'},
    {key:'application/lost+xml', value:'application/lost+xml'},
    {key:'application/mads+xml', value:'application/mads+xml'},
    {key:'application/mets+xml', value:'application/mets+xml'},
    {key:'application/mods+xml', value:'application/mods+xml'},
    {key:'application/mud+json', value:'application/mud+json'},
    {key:'application/passport', value:'application/passport'},
    {key:'application/pgp-keys', value:'application/pgp-keys'},
    {key:'application/pidf+xml', value:'application/pidf+xml'},
    {key:'application/pkix-crl', value:'application/pkix-crl'},
    {key:'application/pskc+xml', value:'application/pskc+xml'},
    {key:'application/pvd+json', value:'application/pvd+json'},
    {key:'application/rlmi+xml', value:'application/rlmi+xml'},
    {key:'application/rpki-roa', value:'application/rpki-roa'},
    {key:'application/sbml+xml', value:'application/sbml+xml'},
    {key:'application/smil+xml', value:'application/smil+xml'},
    {key:'application/soap+xml', value:'application/soap+xml'},
    {key:'application/srgs+xml', value:'application/srgs+xml'},
    {key:'application/ssml+xml', value:'application/ssml+xml'},
    {key:'application/swid+xml', value:'application/swid+xml'},
    {key:'application/ttml+xml', value:'application/ttml+xml'},
    {key:'application/vnd.anki', value:'application/vnd.anki'},
    {key:'application/vnd.bpf3', value:'application/vnd.bpf3'},
    {key:'application/vnd.curl', value:'application/vnd.curl'},
    {key:'application/vnd.dart', value:'application/vnd.dart'},
    {key:'application/vnd.hbci', value:'application/vnd.hbci'},
    {key:'application/vnd.jisp', value:'application/vnd.jisp'},
    {key:'application/vnd.koan', value:'application/vnd.koan'},
    {key:'application/vnd.loom', value:'application/vnd.loom'},
    {key:'application/vnd.MFER', value:'application/vnd.MFER'},
    {key:'application/vnd.mfmp', value:'application/vnd.mfmp'},
    {key:'application/vnd.mseq', value:'application/vnd.mseq'},
    {key:'application/vnd.nimn', value:'application/vnd.nimn'},
    {key:'application/vnd.nitf', value:'application/vnd.nitf'},
    {key:'application/vnd.palm', value:'application/vnd.palm'},
    {key:'application/vnd.pcos', value:'application/vnd.pcos'},
    {key:'application/vnd.psfs', value:'application/vnd.psfs'},
    {key:'application/vnd.sema', value:'application/vnd.sema'},
    {key:'application/vnd.semd', value:'application/vnd.semd'},
    {key:'application/vnd.semf', value:'application/vnd.semf'},
    {key:'application/vnd.smaf', value:'application/vnd.smaf'},
    {key:'application/vnd.ufdl', value:'application/vnd.ufdl'},
    {key:'application/vnd.xara', value:'application/vnd.xara'},
    {key:'application/vnd.xfdl', value:'application/vnd.xfdl'},
    {key:'application/wsdl+xml', value:'application/wsdl+xml'},
    {key:'application/xenc+xml', value:'application/xenc+xml'},
    {key:'application/xmpp+xml', value:'application/xmpp+xml'},
    {key:'application/xslt+xml', value:'application/xslt+xml'},
    {key:'application/applefile', value:'application/applefile'},
    {key:'application/CALS-1840', value:'application/CALS-1840'},
    {key:'application/ccxml+xml', value:'application/ccxml+xml'},
    {key:'application/city+json', value:'application/city+json'},
    {key:'application/csvm+json', value:'application/csvm+json'},
    {key:'application/cybercash', value:'application/cybercash'},
    {key:'application/dashdelta', value:'application/dashdelta'},
    {key:'application/dicom+xml', value:'application/dicom+xml'},
    {key:'application/dots+cbor', value:'application/dots+cbor'},
    {key:'application/dskpp+xml', value:'application/dskpp+xml'},
    {key:'application/fhir+json', value:'application/fhir+json'},
    {key:'application/font-sfnt', value:'application/font-sfnt'},
    {key:'application/font-woff', value:'application/font-woff'},
    {key:'application/hl7v2+xml', value:'application/hl7v2+xml'},
    {key:'application/index.cmd', value:'application/index.cmd'},
    {key:'application/index.obj', value:'application/index.obj'},
    {key:'application/index.vnd', value:'application/index.vnd'},
    {key:'application/inkml+xml', value:'application/inkml+xml'},
    {key:'application/jose+json', value:'application/jose+json'},
    {key:'application/moss-keys', value:'application/moss-keys'},
    {key:'application/mpeg4-iod', value:'application/mpeg4-iod'},
    {key:'application/n-triples', value:'application/n-triples'},
    {key:'application/nlsml+xml', value:'application/nlsml+xml'},
    {key:'application/parityfec', value:'application/parityfec'},
    {key:'application/pkix-cert', value:'application/pkix-cert'},
    {key:'application/raptorfec', value:'application/raptorfec'},
    {key:'application/rdap+json', value:'application/rdap+json'},
    {key:'application/scaip+xml', value:'application/scaip+xml'},
    {key:'application/scim+json', value:'application/scim+json'},
    {key:'application/senml-exi', value:'application/senml-exi'},
    {key:'application/senml+xml', value:'application/senml+xml'},
    {key:'application/smpte336m', value:'application/smpte336m'},
    {key:'application/spdx+json', value:'application/spdx+json'},
    {key:'application/stix+json', value:'application/stix+json'},
    {key:'application/TETRA_ISI', value:'application/TETRA_ISI'},
    {key:'application/tzif-leap', value:'application/tzif-leap'},
    {key:'application/vcard+xml', value:'application/vcard+xml'},
    {key:'application/vnd.ciedi', value:'application/vnd.ciedi'},
    {key:'application/vnd.ffsns', value:'application/vnd.ffsns'},
    {key:'application/vnd.fints', value:'application/vnd.fints'},
    {key:'application/vnd.Kinar', value:'application/vnd.Kinar'},
    {key:'application/vnd.msign', value:'application/vnd.msign'},
    {key:'application/vnd.mynfc', value:'application/vnd.mynfc'},
    {key:'application/vnd.rapid', value:'application/vnd.rapid'},
    {key:'application/vnd.s3sms', value:'application/vnd.s3sms'},
    {key:'application/vnd.unity', value:'application/vnd.unity'},
    {key:'application/vnd.visio', value:'application/vnd.visio'},
    {key:'application/vq-rtcpxr', value:'application/vq-rtcpxr'},
    {key:'application/xacml+xml', value:'application/xacml+xml'},
    {key:'application/xhtml+xml', value:'application/xhtml+xml'},
    {key:'application/xliff+xml', value:'application/xliff+xml'},
    {key:'application/atomicmail', value:'application/atomicmail'},
    {key:'application/batch-SMTP', value:'application/batch-SMTP'},
    {key:'application/cdmi-queue', value:'application/cdmi-queue'},
    {key:'application/cellml+xml', value:'application/cellml+xml'},
    {key:'application/dicom+json', value:'application/dicom+json'},
    {key:'application/ecmascript', value:'application/ecmascript'},
    {key:'application/font-tdpfr', value:'application/font-tdpfr'},
    {key:'application/javascript', value:'application/javascript'},
    {key:'application/macwriteii', value:'application/macwriteii'},
    {key:'application/mathml+xml', value:'application/mathml+xml'},
    {key:'application/pkcs7-mime', value:'application/pkcs7-mime'},
    {key:'application/postscript', value:'application/postscript'},
    {key:'application/prs.nprend', value:'application/prs.nprend'},
    {key:'application/sarif+json', value:'application/sarif+json'},
    {key:'application/senml+cbor', value:'application/senml+cbor'},
    {key:'application/senml+json', value:'application/senml+json'},
    {key:'application/sensml-exi', value:'application/sensml-exi'},
    {key:'application/sensml+xml', value:'application/sensml+xml'},
    {key:'application/tamp-error', value:'application/tamp-error'},
    {key:'application/taxii+json', value:'application/taxii+json'},
    {key:'application/thraud+xml', value:'application/thraud+xml'},
    {key:'application/tnauthlist', value:'application/tnauthlist'},
    {key:'application/vcard+json', value:'application/vcard+json'},
    {key:'application/vnd.cybank', value:'application/vnd.cybank'},
    {key:'application/vnd.gerber', value:'application/vnd.gerber'},
    {key:'application/vnd.grafeq', value:'application/vnd.grafeq'},
    {key:'application/vnd.gridmp', value:'application/vnd.gridmp'},
    {key:'application/vnd.hp-hps', value:'application/vnd.hp-hps'},
    {key:'application/vnd.hp-PCL', value:'application/vnd.hp-PCL'},
    {key:'application/vnd.is-xpr', value:'application/vnd.is-xpr'},
    {key:'application/vnd.laszip', value:'application/vnd.laszip'},
    {key:'application/vnd.ms-asf', value:'application/vnd.ms-asf'},
    {key:'application/vnd.ms-ims', value:'application/vnd.ms-ims'},
    {key:'application/vnd.ms-lrm', value:'application/vnd.ms-lrm'},
    {key:'application/vnd.ms-wpl', value:'application/vnd.ms-wpl'},
    {key:'application/vnd.netfpx', value:'application/vnd.netfpx'},
    {key:'application/vnd.picsel', value:'application/vnd.picsel'},
    {key:'application/vnd.umajin', value:'application/vnd.umajin'},
    {key:'application/vnd.wt.stf', value:'application/vnd.wt.stf'},
    {key:'application/atomcat+xml', value:'application/atomcat+xml'},
    {key:'application/atomsvc+xml', value:'application/atomsvc+xml'},
    {key:'application/cdmi-domain', value:'application/cdmi-domain'},
    {key:'application/cdmi-object', value:'application/cdmi-object'},
    {key:'application/dns-message', value:'application/dns-message'},
    {key:'application/EDI-consent', value:'application/EDI-consent'},
    {key:'application/fastinfoset', value:'application/fastinfoset'},
    {key:'application/gltf-buffer', value:'application/gltf-buffer'},
    {key:'application/hyperstudio', value:'application/hyperstudio'},
    {key:'application/ibe-pp-data', value:'application/ibe-pp-data'},
    {key:'application/link-format', value:'application/link-format'},
    {key:'application/marcxml+xml', value:'application/marcxml+xml'},
    {key:'application/mathematica', value:'application/mathematica'},
    {key:'application/mmt-aei+xml', value:'application/mmt-aei+xml'},
    {key:'application/mmt-usd+xml', value:'application/mmt-usd+xml'},
    {key:'application/msc-ivr+xml', value:'application/msc-ivr+xml'},
    {key:'application/problem+xml', value:'application/problem+xml'},
    {key:'application/prs.plucker', value:'application/prs.plucker'},
    {key:'application/prs.xsf+xml', value:'application/prs.xsf+xml'},
    {key:'application/reginfo+xml', value:'application/reginfo+xml'},
    {key:'application/rpki-updown', value:'application/rpki-updown'},
    {key:'application/rtploopback', value:'application/rtploopback'},
    {key:'application/sensml+cbor', value:'application/sensml+cbor'},
    {key:'application/sensml+json', value:'application/sensml+json'},
    {key:'application/set-payment', value:'application/set-payment'},
    {key:'application/tamp-update', value:'application/tamp-update'},
    {key:'application/tlsrpt+gzip', value:'application/tlsrpt+gzip'},
    {key:'application/tlsrpt+json', value:'application/tlsrpt+json'},
    {key:'application/tve-trigger', value:'application/tve-trigger'},
    {key:'application/vnd.acucorp', value:'application/vnd.acucorp'},
    {key:'application/vnd.dpgraph', value:'application/vnd.dpgraph'},
    {key:'application/vnd.dvb.ait', value:'application/vnd.dvb.ait'},
    {key:'application/vnd.dvb.pfr', value:'application/vnd.dvb.pfr'},
    {key:'application/vnd.dynageo', value:'application/vnd.dynageo'},
    {key:'application/vnd.efi.img', value:'application/vnd.efi.img'},
    {key:'application/vnd.efi.iso', value:'application/vnd.efi.iso'},
    {key:'application/vnd.enliven', value:'application/vnd.enliven'},
    {key:'application/vnd.genozip', value:'application/vnd.genozip'},
    {key:'application/vnd.geonext', value:'application/vnd.geonext'},
    {key:'application/vnd.geoplan', value:'application/vnd.geoplan'},
    {key:'application/vnd.hal+xml', value:'application/vnd.hal+xml'},
    {key:'application/vnd.hc+json', value:'application/vnd.hc+json'},
    {key:'application/vnd.hp-HPGL', value:'application/vnd.hp-HPGL'},
    {key:'application/vnd.hp-hpid', value:'application/vnd.hp-hpid'},
    {key:'application/vnd.hp-jlyt', value:'application/vnd.hp-jlyt'},
    {key:'application/vnd.kahootz', value:'application/vnd.kahootz'},
    {key:'application/vnd.ms-tnef', value:'application/vnd.ms-tnef'},
    {key:'application/vnd.nervana', value:'application/vnd.nervana'},
    {key:'application/vnd.oma.dcd', value:'application/vnd.oma.dcd'},
    {key:'application/vnd.osgi.dp', value:'application/vnd.osgi.dp'},
    {key:'application/vnd.panoply', value:'application/vnd.panoply'},
    {key:'application/vnd.rs-274x', value:'application/vnd.rs-274x'},
    {key:'application/vnd.sbm.cid', value:'application/vnd.sbm.cid'},
    {key:'application/vnd.scribus', value:'application/vnd.scribus'},
    {key:'application/vnd.seemail', value:'application/vnd.seemail'},
    {key:'application/vnd.sqlite3', value:'application/vnd.sqlite3'},
    {key:'application/vnd.sss-cod', value:'application/vnd.sss-cod'},
    {key:'application/vnd.sss-dtf', value:'application/vnd.sss-dtf'},
    {key:'application/vnd.sss-ntf', value:'application/vnd.sss-ntf'},
    {key:'application/vnd.trueapp', value:'application/vnd.trueapp'},
    {key:'application/vnd.truedoc', value:'application/vnd.truedoc'},
    {key:'application/vnd.uri-map', value:'application/vnd.uri-map'},
    {key:'application/vnd.wap.sic', value:'application/vnd.wap.sic'},
    {key:'application/vnd.wap.slc', value:'application/vnd.wap.slc'},
    {key:'application/vnd.wfa.dpp', value:'application/vnd.wfa.dpp'},
    {key:'application/vnd.wfa.p2p', value:'application/vnd.wfa.p2p'},
    {key:'application/vnd.wfa.wsc', value:'application/vnd.wfa.wsc'},
    {key:'application/vnd.xmi+xml', value:'application/vnd.xmi+xml'},
    {key:'application/vnd.yaoweme', value:'application/vnd.yaoweme'},
    {key:'application/xcap-el+xml', value:'application/xcap-el+xml'},
    {key:'application/xcap-ns+xml', value:'application/xcap-ns+xml'},
    {key:'application/3gppHal+json',value:'application/3gppHal+json'},
    {key:'application/3gpp-ims+xml',value:'application/3gpp-ims+xml'},
    {key:'application/andrew-inset',value:'application/andrew-inset'},
    {key:'application/atsc-dwd+xml',value:'application/atsc-dwd+xml'},
    {key:'application/calendar+xml',value:'application/calendar+xml'},
    {key:'application/captive+json',value:'application/captive+json'},
    {key:'application/cea-2018+xml',value:'application/cea-2018+xml'},
    {key:'application/coap-payload',value:'application/coap-payload'},
    {key:'application/commonground',value:'application/commonground'},
    {key:'application/cose-key-set',value:'application/cose-key-set'},
    {key:'application/CSTAdata+xml',value:'application/CSTAdata+xml'},
    {key:'application/davmount+xml',value:'application/davmount+xml'},
    {key:'application/geo+json-seq',value:'application/geo+json-seq'},
    {key:'application/geoxacml+xml',value:'application/geoxacml+xml'},
    {key:'application/jf2feed+json',value:'application/jf2feed+json'},
    {key:'application/jwk-set+json',value:'application/jwk-set+json'},
    {key:'application/linkset+json',value:'application/linkset+json'},
    {key:'application/lostsync+xml',value:'application/lostsync+xml'},
    {key:'application/mac-binhex40',value:'application/mac-binhex40'},
    {key:'application/mbms-msk+xml',value:'application/mbms-msk+xml'},
    {key:'application/mosskey-data',value:'application/mosskey-data'},
    {key:'application/ocsp-request',value:'application/ocsp-request'},
    {key:'application/octet-stream',value:'application/octet-stream'},
    {key:'application/pkix-pkipath',value:'application/pkix-pkipath'},
    {key:'application/problem+json',value:'application/problem+json'},
    {key:'application/prs.hpub+zip',value:'application/prs.hpub+zip'},
    {key:'application/reputon+json',value:'application/reputon+json'},
    {key:'application/secevent+jwt',value:'application/secevent+jwt'},
    {key:'application/session-info',value:'application/session-info'},
    {key:'application/sparql-query',value:'application/sparql-query'},
    {key:'application/vnd.3gpp.lpp',value:'application/vnd.3gpp.lpp'},
    {key:'application/vnd.3gpp.sms',value:'application/vnd.3gpp.sms'},
    {key:'application/vnd.acucobol',value:'application/vnd.acucobol'},
    {key:'application/vnd.api+json',value:'application/vnd.api+json'},
    {key:'application/vnd.claymore',value:'application/vnd.claymore'},
    {key:'application/vnd.cups-pdf',value:'application/vnd.cups-pdf'},
    {key:'application/vnd.cups-ppd',value:'application/vnd.cups-ppd'},
    {key:'application/vnd.cups-raw',value:'application/vnd.cups-raw'},
    {key:'application/vnd.dece.zip',value:'application/vnd.dece.zip'},
    {key:'application/vnd.dvb.dvbj',value:'application/vnd.dvb.dvbj'},
    {key:'application/vnd.ecip.rlp',value:'application/vnd.ecip.rlp'},
    {key:'application/vnd.geo+json',value:'application/vnd.geo+json'},
    {key:'application/vnd.geospace',value:'application/vnd.geospace'},
    {key:'application/vnd.hal+json',value:'application/vnd.hal+json'},
    {key:'application/vnd.hp-PCLXL',value:'application/vnd.hp-PCLXL'},
    {key:'application/vnd.httphone',value:'application/vnd.httphone'},
    {key:'application/vnd.igloader',value:'application/vnd.igloader'},
    {key:'application/vnd.intergeo',value:'application/vnd.intergeo'},
    {key:'application/vnd.intu.qbo',value:'application/vnd.intu.qbo'},
    {key:'application/vnd.intu.qfx',value:'application/vnd.intu.qfx'},
    {key:'application/vnd.ipld.car',value:'application/vnd.ipld.car'},
    {key:'application/vnd.ipld.raw',value:'application/vnd.ipld.raw'},
    {key:'application/vnd.isac.fcs',value:'application/vnd.isac.fcs'},
    {key:'application/vnd.ms-excel',value:'application/vnd.ms-excel'},
    {key:'application/vnd.ms-works',value:'application/vnd.ms-works'},
    {key:'application/vnd.musician',value:'application/vnd.musician'},
    {key:'application/vnd.ocf+cbor',value:'application/vnd.ocf+cbor'},
    {key:'application/vnd.oma.dcdc',value:'application/vnd.oma.dcdc'},
    {key:'application/vnd.oma.push',value:'application/vnd.oma.push'},
    {key:'application/vnd.onepager',value:'application/vnd.onepager'},
    {key:'application/vnd.paos.xml',value:'application/vnd.paos.xml'},
    {key:'application/vnd.preminet',value:'application/vnd.preminet'},
    {key:'application/vnd.sbm.mid2',value:'application/vnd.sbm.mid2'},
    {key:'application/vnd.trid.tpt',value:'application/vnd.trid.tpt'},
    {key:'application/vnd.uoml+xml',value:'application/vnd.uoml+xml'},
    {key:'application/vnd.vd-study',value:'application/vnd.vd-study'},
    {key:'application/vnd.vel+json',value:'application/vnd.vel+json'},
    {key:'application/vnd.wap.wmlc',value:'application/vnd.wap.wmlc'},
    {key:'application/vnd.webturbo',value:'application/vnd.webturbo'},
    {key:'application/voicexml+xml',value:'application/voicexml+xml'},
    {key:'application/wspolicy+xml',value:'application/wspolicy+xml'},
    {key:'application/xcap-att+xml',value:'application/xcap-att+xml'},
    {key:'application/activemessage', value:'application/activemessage'},
    {key:'application/activity+json', value:'application/activity+json'},
    {key:'application/atsc-held+xml', value:'application/atsc-held+xml'},
    {key:'application/atsc-rdt+json', value:'application/atsc-rdt+json'},
    {key:'application/atsc-rsat+xml', value:'application/atsc-rsat+xml'},
    {key:'application/calendar+json', value:'application/calendar+json'},
    {key:'application/clue_info+xml', value:'application/clue_info+xml'},
    {key:'application/emotionml+xml', value:'application/emotionml+xml'},
    {key:'application/manifest+json', value:'application/manifest+json'},
    {key:'application/metalink4+xml', value:'application/metalink4+xml'},
    {key:'application/mpeg4-generic', value:'application/mpeg4-generic'},
    {key:'application/mpeg4-iod-xmt', value:'application/mpeg4-iod-xmt'},
    {key:'application/msc-mixer+xml', value:'application/msc-mixer+xml'},
    {key:'application/ocsp-response', value:'application/ocsp-response'},
    {key:'application/pgp-encrypted', value:'application/pgp-encrypted'},
    {key:'application/pgp-signature', value:'application/pgp-signature'},
    {key:'application/pidf-diff+xml', value:'application/pidf-diff+xml'},
    {key:'application/route-apd+xml', value:'application/route-apd+xml'},
    {key:'application/route-usd+xml', value:'application/route-usd+xml'},
    {key:'application/rpki-manifest', value:'application/rpki-manifest'},
    {key:'application/vnd.3gpp.gtpc', value:'application/vnd.3gpp.gtpc'},
    {key:'application/vnd.3gpp.ngap', value:'application/vnd.3gpp.ngap'},
    {key:'application/vnd.3gpp.pfcp', value:'application/vnd.3gpp.pfcp'},
    {key:'application/vnd.3gpp.s1ap', value:'application/vnd.3gpp.s1ap'},
    {key:'application/vnd.3gpp2.sms', value:'application/vnd.3gpp2.sms'},
    {key:'application/vnd.adobe.fxp', value:'application/vnd.adobe.fxp'},
    {key:'application/vnd.amiga.ami', value:'application/vnd.amiga.ami'},
    {key:'application/vnd.artsquare', value:'application/vnd.artsquare'},
    {key:'application/vnd.chess-pgn', value:'application/vnd.chess-pgn'},
    {key:'application/vnd.ctc-posml', value:'application/vnd.ctc-posml'},
    {key:'application/vnd.dece.data', value:'application/vnd.dece.data'},
    {key:'application/vnd.dtg.local', value:'application/vnd.dtg.local'},
    {key:'application/vnd.epson.esf', value:'application/vnd.epson.esf'},
    {key:'application/vnd.epson.msf', value:'application/vnd.epson.msf'},
    {key:'application/vnd.epson.ssf', value:'application/vnd.epson.ssf'},
    {key:'application/vnd.fdsn.seed', value:'application/vnd.fdsn.seed'},
    {key:'application/vnd.ieee.1905', value:'application/vnd.ieee.1905'},
    {key:'application/vnd.kde.kivio', value:'application/vnd.kde.kivio'},
    {key:'application/vnd.kde.kword', value:'application/vnd.kde.kword'},
    {key:'application/vnd.leap+json', value:'application/vnd.leap+json'},
    {key:'application/vnd.nokia.ncd', value:'application/vnd.nokia.ncd'},
    {key:'application/vnd.pawaafile', value:'application/vnd.pawaafile'},
    {key:'application/vnd.pg.format', value:'application/vnd.pg.format'},
    {key:'application/vnd.pg.osasli', value:'application/vnd.pg.osasli'},
    {key:'application/vnd.pvi.ptid1', value:'application/vnd.pvi.ptid1'},
    {key:'application/vnd.seis+json', value:'application/vnd.seis+json'},
    {key:'application/vnd.sycle+xml', value:'application/vnd.sycle+xml'},
    {key:'application/vnd.syft+json', value:'application/vnd.syft+json'},
    {key:'application/vnd.uiq.theme', value:'application/vnd.uiq.theme'},
    {key:'application/vnd.visionary', value:'application/vnd.visionary'},
    {key:'application/vnd.wap.wbxml', value:'application/vnd.wap.wbxml'},
    {key:'application/whoispp-query', value:'application/whoispp-query'},
    {key:'application/x-pki-message', value:'application/x-pki-message'},
    {key:'application/xcap-caps+xml', value:'application/xcap-caps+xml'},
    {key:'application/xcap-diff+xml', value:'application/xcap-diff+xml'},
    {key:'application/xml-patch+xml', value:'application/xml-patch+xml'},
    {key:'application/yang-data+xml', value:'application/yang-data+xml'},
    {key:'application/alto-cdni+json',value:'application/alto-cdni+json'},
    {key:'application/bacnet-xdd+zip',value:'application/bacnet-xdd+zip'},
    {key:'application/cdmi-container',value:'application/cdmi-container'},
    {key:'application/dash-patch+xml',value:'application/dash-patch+xml'},
    {key:'application/index.response',value:'application/index.response'},
    {key:'application/moss-signature',value:'application/moss-signature'},
    {key:'application/multipart-core',value:'application/multipart-core'},
    {key:'application/news-groupinfo',value:'application/news-groupinfo'},
    {key:'application/pkix-attr-cert',value:'application/pkix-attr-cert'},
    {key:'application/provenance+xml',value:'application/provenance+xml'},
    {key:'application/vnd.3gpp.5gnas',value:'application/vnd.3gpp.5gnas'},
    {key:'application/vnd.3gpp2.tcap',value:'application/vnd.3gpp2.tcap'},
    {key:'application/vnd.aether.imp',value:'application/vnd.aether.imp'},
    {key:'application/vnd.afpc.modca',value:'application/vnd.afpc.modca'},
    {key:'application/vnd.ah-barcode',value:'application/vnd.ah-barcode'},
    {key:'application/vnd.audiograph',value:'application/vnd.audiograph'},
    {key:'application/vnd.canon-cpdl',value:'application/vnd.canon-cpdl'},
    {key:'application/vnd.canon-lips',value:'application/vnd.canon-lips'},
    {key:'application/vnd.cinderella',value:'application/vnd.cinderella'},
    {key:'application/vnd.drive+json',value:'application/vnd.drive+json'},
    {key:'application/vnd.epson.salt',value:'application/vnd.epson.salt'},
    {key:'application/vnd.etsi.mheg5',value:'application/vnd.etsi.mheg5'},
    {key:'application/vnd.fdsn.mseed',value:'application/vnd.fdsn.mseed'},
    {key:'application/vnd.filmit.zfc',value:'application/vnd.filmit.zfc'},
    {key:'application/vnd.FloGraphIt',value:'application/vnd.FloGraphIt'},
    {key:'application/vnd.framemaker',value:'application/vnd.framemaker'},
    {key:'application/vnd.fut-misnet',value:'application/vnd.fut-misnet'},
    {key:'application/vnd.fuzzysheet',value:'application/vnd.fuzzysheet'},
    {key:'application/vnd.hyper+json',value:'application/vnd.hyper+json'},
    {key:'application/vnd.ibm.modcap',value:'application/vnd.ibm.modcap'},
    {key:'application/vnd.iccprofile',value:'application/vnd.iccprofile'},
    {key:'application/vnd.insors.igm',value:'application/vnd.insors.igm'},
    {key:'application/vnd.kde.karbon',value:'application/vnd.kde.karbon'},
    {key:'application/vnd.kde.kchart',value:'application/vnd.kde.kchart'},
    {key:'application/vnd.kenameaapp',value:'application/vnd.kenameaapp'},
    {key:'application/vnd.mason+json',value:'application/vnd.mason+json'},
    {key:'application/vnd.micro+json',value:'application/vnd.micro+json'},
    {key:'application/vnd.miele+json',value:'application/vnd.miele+json'},
    {key:'application/vnd.Mobius.DAF',value:'application/vnd.Mobius.DAF'},
    {key:'application/vnd.Mobius.DIS',value:'application/vnd.Mobius.DIS'},
    {key:'application/vnd.Mobius.MBK',value:'application/vnd.Mobius.MBK'},
    {key:'application/vnd.Mobius.MQY',value:'application/vnd.Mobius.MQY'},
    {key:'application/vnd.Mobius.MSL',value:'application/vnd.Mobius.MSL'},
    {key:'application/vnd.Mobius.PLC',value:'application/vnd.Mobius.PLC'},
    {key:'application/vnd.Mobius.TXF',value:'application/vnd.Mobius.TXF'},
    {key:'application/vnd.ms-project',value:'application/vnd.ms-project'},
    {key:'application/vnd.music-niff',value:'application/vnd.music-niff'},
    {key:'application/vnd.olpc-sugar',value:'application/vnd.olpc-sugar'},
    {key:'application/vnd.patentdive',value:'application/vnd.patentdive'},
    {key:'application/vnd.pmi.widget',value:'application/vnd.pmi.widget'},
    {key:'application/vnd.sealed.3df',value:'application/vnd.sealed.3df'},
    {key:'application/vnd.sealed.csf',value:'application/vnd.sealed.csf'},
    {key:'application/vnd.sealed.doc',value:'application/vnd.sealed.doc'},
    {key:'application/vnd.sealed.eml',value:'application/vnd.sealed.eml'},
    {key:'application/vnd.sealed.mht',value:'application/vnd.sealed.mht'},
    {key:'application/vnd.sealed.net',value:'application/vnd.sealed.net'},
    {key:'application/vnd.sealed.ppt',value:'application/vnd.sealed.ppt'},
    {key:'application/vnd.sealed.xls',value:'application/vnd.sealed.xls'},
    {key:'application/vnd.siren+json',value:'application/vnd.siren+json'},
    {key:'application/vnd.syncml+xml',value:'application/vnd.syncml+xml'},
    {key:'application/vnd.wv.csp+xml',value:'application/vnd.wv.csp+xml'},
    {key:'application/vnd.wv.ssp+xml',value:'application/vnd.wv.ssp+xml'},
    {key:'application/vnd.xacml+json',value:'application/vnd.xacml+json'},
    {key:'application/vnd.xmpie.cpkg',value:'application/vnd.xmpie.cpkg'},
    {key:'application/vnd.xmpie.dpkg',value:'application/vnd.xmpie.dpkg'},
    {key:'application/vnd.xmpie.plan',value:'application/vnd.xmpie.plan'},
    {key:'application/vnd.xmpie.ppkg',value:'application/vnd.xmpie.ppkg'},
    {key:'application/vnd.xmpie.xlim',value:'application/vnd.xmpie.xlim'},
    {key:'application/vnd.youtube.yt',value:'application/vnd.youtube.yt'},
    {key:'application/wordperfect5.1',value:'application/wordperfect5.1'},
    {key:'application/x-x509-ca-cert',value:'application/x-x509-ca-cert'},
    {key:'application/xcap-error+xml',value:'application/xcap-error+xml'},
    {key:'application/yang-data+cbor',value:'application/yang-data+cbor'},
    {key:'application/yang-data+json',value:'application/yang-data+json'},
    {key:'application/yang-patch+xml',value:'application/yang-patch+xml'},
    {key:'application/alto-error+json',value:'application/alto-error+json'},
    {key:'application/atomdeleted+xml',value:'application/atomdeleted+xml'},
    {key:'application/auth-policy+xml',value:'application/auth-policy+xml'},
    {key:'application/call-completion',value:'application/call-completion'},
    {key:'application/cdmi-capability',value:'application/cdmi-capability'},
    {key:'application/coap-group+json',value:'application/coap-group+json'},
    {key:'application/dialog-info+xml',value:'application/dialog-info+xml'},
    {key:'application/jscalendar+json',value:'application/jscalendar+json'},
    {key:'application/json-patch+json',value:'application/json-patch+json'},
    {key:'application/mosskey-request',value:'application/mosskey-request'},
    {key:'application/mrb-publish+xml',value:'application/mrb-publish+xml'},
    {key:'application/opc-nodeset+xml',value:'application/opc-nodeset+xml'},
    {key:'application/p2p-overlay+xml',value:'application/p2p-overlay+xml'},
    {key:'application/pkcs7-signature',value:'application/pkcs7-signature'},
    {key:'application/pkcs8-encrypted',value:'application/pkcs8-encrypted'},
    {key:'application/remote-printing',value:'application/remote-printing'},
    {key:'application/scvp-cv-request',value:'application/scvp-cv-request'},
    {key:'application/scvp-vp-request',value:'application/scvp-vp-request'},
    {key:'application/senml-etch+cbor',value:'application/senml-etch+cbor'},
    {key:'application/senml-etch+json',value:'application/senml-etch+json'},
    {key:'application/timestamp-query',value:'application/timestamp-query'},
    {key:'application/timestamp-reply',value:'application/timestamp-reply'},
    {key:'application/vnd.ahead.space',value:'application/vnd.ahead.space'},
    {key:'application/vnd.android.ota',value:'application/vnd.android.ota'},
    {key:'application/vnd.apple.pages',value:'application/vnd.apple.pages'},
    {key:'application/vnd.arastra.swi',value:'application/vnd.arastra.swi'},
    {key:'application/vnd.autopackage',value:'application/vnd.autopackage'},
    {key:'application/vnd.avalon+json',value:'application/vnd.avalon+json'},
    {key:'application/vnd.avistar+xml',value:'application/vnd.avistar+xml'},
    {key:'application/vnd.bbf.usp.msg',value:'application/vnd.bbf.usp.msg'},
    {key:'application/vnd.cab-jscript',value:'application/vnd.cab-jscript'},
    {key:'application/vnd.cloanto.rp9',value:'application/vnd.cloanto.rp9'},
    {key:'application/vnd.commonspace',value:'application/vnd.commonspace'},
    {key:'application/vnd.cosmocaller',value:'application/vnd.cosmocaller'},
    {key:'application/vnd.ctct.ws+xml',value:'application/vnd.ctct.ws+xml'},
    {key:'application/vnd.cups-raster',value:'application/vnd.cups-raster'},
    {key:'application/vnd.d3m-dataset',value:'application/vnd.d3m-dataset'},
    {key:'application/vnd.d3m-problem',value:'application/vnd.d3m-problem'},
    {key:'application/vnd.dvb.service',value:'application/vnd.dvb.service'},
    {key:'application/vnd.eudora.data',value:'application/vnd.eudora.data'},
    {key:'application/vnd.ezpix-album',value:'application/vnd.ezpix-album'},
    {key:'application/vnd.frogans.fnc',value:'application/vnd.frogans.fnc'},
    {key:'application/vnd.frogans.ltf',value:'application/vnd.frogans.ltf'},
    {key:'application/vnd.futoin+cbor',value:'application/vnd.futoin+cbor'},
    {key:'application/vnd.futoin+json',value:'application/vnd.futoin+json'},
    {key:'application/vnd.geocube+xml',value:'application/vnd.geocube+xml'},
    {key:'application/vnd.groove-help',value:'application/vnd.groove-help'},
    {key:'application/vnd.heroku+json',value:'application/vnd.heroku+json'},
    {key:'application/vnd.ibm.MiniPay',value:'application/vnd.ibm.MiniPay'},
    {key:'application/vnd.kde.kontour',value:'application/vnd.kde.kontour'},
    {key:'application/vnd.kde.kspread',value:'application/vnd.kde.kspread'},
    {key:'application/vnd.las.las+xml',value:'application/vnd.las.las+xml'},
    {key:'application/vnd.lotus-1-2-3',value:'application/vnd.lotus-1-2-3'},
    {key:'application/vnd.lotus-notes',value:'application/vnd.lotus-notes'},
    {key:'application/vnd.medcalcdata',value:'application/vnd.medcalcdata'},
    {key:'application/vnd.ms-artgalry',value:'application/vnd.ms-artgalry'},
    {key:'application/vnd.ms-htmlhelp',value:'application/vnd.ms-htmlhelp'},
    {key:'application/vnd.muvee.style',value:'application/vnd.muvee.style'},
    {key:'application/vnd.ncd.control',value:'application/vnd.ncd.control'},
    {key:'application/vnd.oma.dd2+xml',value:'application/vnd.oma.dd2+xml'},
    {key:'application/vnd.oma.pal+xml',value:'application/vnd.oma.pal+xml'},
    {key:'application/vnd.onepagertat',value:'application/vnd.onepagertat'},
    {key:'application/vnd.openeye.oeb',value:'application/vnd.openeye.oeb'},
    {key:'application/vnd.osgi.bundle',value:'application/vnd.osgi.bundle'},
    {key:'application/vnd.pocketlearn',value:'application/vnd.pocketlearn'},
    {key:'application/vnd.realvnc.bed',value:'application/vnd.realvnc.bed'},
    {key:'application/vnd.sealed.tiff',value:'application/vnd.sealed.tiff'},
    {key:'application/vnd.vectorworks',value:'application/vnd.vectorworks'},
    {key:'application/vnd.wordperfect',value:'application/vnd.wordperfect'},
    {key:'application/watcherinfo+xml',value:'application/watcherinfo+xml'},
    {key:'application/yang-patch+json',value:'application/yang-patch+json'},
    {key:'application/kpml-request+xml',value:'application/kpml-request+xml'},
    {key:'application/load-control+xml',value:'application/load-control+xml'},
    {key:'application/merge-patch+json',value:'application/merge-patch+json'},
    {key:'application/mrb-consumer+xml',value:'application/mrb-consumer+xml'},
    {key:'application/news-checkgroups',value:'application/news-checkgroups'},
    {key:'application/poc-settings+xml',value:'application/poc-settings+xml'},
    {key:'application/route-s-tsid+xml',value:'application/route-s-tsid+xml'},
    {key:'application/rls-services+xml',value:'application/rls-services+xml'},
    {key:'application/rpki-publication',value:'application/rpki-publication'},
    {key:'application/samlmetadata+xml',value:'application/samlmetadata+xml'},
    {key:'application/scvp-cv-response',value:'application/scvp-cv-response'},
    {key:'application/scvp-vp-response',value:'application/scvp-vp-response'},
    {key:'application/set-registration',value:'application/set-registration'},
    {key:'application/soap+fastinfoset',value:'application/soap+fastinfoset'},
    {key:'application/tamp-apex-update',value:'application/tamp-apex-update'},
    {key:'application/timestamped-data',value:'application/timestamped-data'},
    {key:'application/urc-grpsheet+xml',value:'application/urc-grpsheet+xml'},
    {key:'application/urc-ressheet+xml',value:'application/urc-ressheet+xml'},
    {key:'application/vnd.3gpp.bsf+xml',value:'application/vnd.3gpp.bsf+xml'},
    {key:'application/vnd.3gpp.sms+xml',value:'application/vnd.3gpp.sms+xml'},
    {key:'application/vnd.amadeus+json',value:'application/vnd.amadeus+json'},
    {key:'application/vnd.artisan+json',value:'application/vnd.artisan+json'},
    {key:'application/vnd.chemdraw+xml',value:'application/vnd.chemdraw+xml'},
    {key:'application/vnd.coffeescript',value:'application/vnd.coffeescript'},
    {key:'application/vnd.contact.cmsg',value:'application/vnd.contact.cmsg'},
    {key:'application/vnd.dreamfactory',value:'application/vnd.dreamfactory'},
    {key:'application/vnd.ecdis-update',value:'application/vnd.ecdis-update'},
    {key:'application/vnd.ecowin.chart',value:'application/vnd.ecowin.chart'},
    {key:'application/vnd.eszigno3+xml',value:'application/vnd.eszigno3+xml'},
    {key:'application/vnd.etsi.aoc+xml',value:'application/vnd.etsi.aoc+xml'},
    {key:'application/vnd.etsi.cug+xml',value:'application/vnd.etsi.cug+xml'},
    {key:'application/vnd.etsi.sci+xml',value:'application/vnd.etsi.sci+xml'},
    {key:'application/vnd.etsi.tsl+xml',value:'application/vnd.etsi.tsl+xml'},
    {key:'application/vnd.etsi.tsl.der',value:'application/vnd.etsi.tsl.der'},
    {key:'application/vnd.groove-vcard',value:'application/vnd.groove-vcard'},
    {key:'application/vnd.jsk.isdn-ngn',value:'application/vnd.jsk.isdn-ngn'},
    {key:'application/vnd.kde.kformula',value:'application/vnd.kde.kformula'},
    {key:'application/vnd.kidspiration',value:'application/vnd.kidspiration'},
    {key:'application/vnd.las.las+json',value:'application/vnd.las.las+json'},
    {key:'application/vnd.noblenet-web',value:'application/vnd.noblenet-web'},
    {key:'application/vnd.novadigm.EDM',value:'application/vnd.novadigm.EDM'},
    {key:'application/vnd.novadigm.EDX',value:'application/vnd.novadigm.EDX'},
    {key:'application/vnd.novadigm.EXT',value:'application/vnd.novadigm.EXT'},
    {key:'application/vnd.oipf.pae.gem',value:'application/vnd.oipf.pae.gem'},
    {key:'application/vnd.onepagertamp',value:'application/vnd.onepagertamp'},
    {key:'application/vnd.onepagertamx',value:'application/vnd.onepagertamx'},
    {key:'application/vnd.onepagertatp',value:'application/vnd.onepagertatp'},
    {key:'application/vnd.onepagertatx',value:'application/vnd.onepagertatx'},
    {key:'application/vnd.restful+json',value:'application/vnd.restful+json'},
    {key:'application/vnd.spotfire.dxp',value:'application/vnd.spotfire.dxp'},
    {key:'application/vnd.spotfire.sfs',value:'application/vnd.spotfire.sfs'},
    {key:'application/vnd.sun.wadl+xml',value:'application/vnd.sun.wadl+xml'},
    {key:'application/vnd.sus-calendar',value:'application/vnd.sus-calendar'},
    {key:'application/vnd.tcpdump.pcap',value:'application/vnd.tcpdump.pcap'},
    {key:'application/vnd.triscape.mxs',value:'application/vnd.triscape.mxs'},
    {key:'application/vnd.uplanet.list',value:'application/vnd.uplanet.list'},
    {key:'application/vnd.veryant.thin',value:'application/vnd.veryant.thin'},
    {key:'application/vnd.wv.csp+wbxml',value:'application/vnd.wv.csp+wbxml'},
    {key:'application/vnd.xfdl.webform',value:'application/vnd.xfdl.webform'},
    {key:'application/voucher-cms+json',value:'application/voucher-cms+json'},
    {key:'application/whoispp-response',value:'application/whoispp-response'},
    {key:'application/3gppHalForms+json',value:'application/3gppHalForms+json'},
    {key:'application/alto-costmap+json',value:'application/alto-costmap+json'},
    {key:'application/alto-propmap+json',value:'application/alto-propmap+json'},
    {key:'application/ibe-pkg-reply+xml',value:'application/ibe-pkg-reply+xml'},
    {key:'application/kpml-response+xml',value:'application/kpml-response+xml'},
    {key:'application/mbms-envelope+xml',value:'application/mbms-envelope+xml'},
    {key:'application/mbms-register+xml',value:'application/mbms-register+xml'},
    {key:'application/mbms-schedule+xml',value:'application/mbms-schedule+xml'},
    {key:'application/media_control+xml',value:'application/media_control+xml'},
    {key:'application/news-transmission',value:'application/news-transmission'},
    {key:'application/oebps-package+xml',value:'application/oebps-package+xml'},
    {key:'application/ppsp-tracker+json',value:'application/ppsp-tracker+json'},
    {key:'application/prs.rdf-xml-crypt',value:'application/prs.rdf-xml-crypt'},
    {key:'application/rpki-ghostbusters',value:'application/rpki-ghostbusters'},
    {key:'application/samlassertion+xml',value:'application/samlassertion+xml'},
    {key:'application/sgml-open-catalog',value:'application/sgml-open-catalog'},
    {key:'application/simple-filter+xml',value:'application/simple-filter+xml'},
    {key:'application/spirits-event+xml',value:'application/spirits-event+xml'},
    {key:'application/tamp-status-query',value:'application/tamp-status-query'},
    {key:'application/vnd.3gpp.GMOP+xml',value:'application/vnd.3gpp.GMOP+xml'},
    {key:'application/vnd.3gpp.ussd+xml',value:'application/vnd.3gpp.ussd+xml'},
    {key:'application/vnd.adobe.xdp+xml',value:'application/vnd.adobe.xdp+xml'},
    {key:'application/vnd.apple.keynote',value:'application/vnd.apple.keynote'},
    {key:'application/vnd.apple.mpegurl',value:'application/vnd.apple.mpegurl'},
    {key:'application/vnd.apple.numbers',value:'application/vnd.apple.numbers'},
    {key:'application/vnd.bbf.usp.error',value:'application/vnd.bbf.usp.error'},
    {key:'application/vnd.balsamiq.bmpr',value:'application/vnd.balsamiq.bmpr'},
    {key:'application/vnd.byu.uapi+json',value:'application/vnd.byu.uapi+json'},
    {key:'application/vnd.clonk.c4group',value:'application/vnd.clonk.c4group'},
    {key:'application/vnd.comicbook-rar',value:'application/vnd.comicbook-rar'},
    {key:'application/vnd.comicbook+zip',value:'application/vnd.comicbook+zip'},
    {key:'application/vnd.crick.clicker',value:'application/vnd.crick.clicker'},
    {key:'application/vnd.cyclonedx+xml',value:'application/vnd.cyclonedx+xml'},
    {key:'application/vnd.dece.ttml+xml',value:'application/vnd.dece.ttml+xml'},
    {key:'application/vnd.desmume.movie',value:'application/vnd.desmume.movie'},
    {key:'application/vnd.document+json',value:'application/vnd.document+json'},
    {key:'application/vnd.ecowin.series',value:'application/vnd.ecowin.series'},
    {key:'application/vnd.enphase.envoy',value:'application/vnd.enphase.envoy'},
    {key:'application/vnd.etsi.mcid+xml',value:'application/vnd.etsi.mcid+xml'},
    {key:'application/vnd.etsi.pstn+xml',value:'application/vnd.etsi.pstn+xml'},
    {key:'application/vnd.ezpix-package',value:'application/vnd.ezpix-package'},
    {key:'application/vnd.fluxtime.clip',value:'application/vnd.fluxtime.clip'},
    {key:'application/vnd.fsc.weblaunch',value:'application/vnd.fsc.weblaunch'},
    {key:'application/vnd.fujitsu.oasys',value:'application/vnd.fujitsu.oasys'},
    {key:'application/vnd.fujixerox.ddd',value:'application/vnd.fujixerox.ddd'},
    {key:'application/vnd.geogebra.file',value:'application/vnd.geogebra.file'},
    {key:'application/vnd.geogebra.tool',value:'application/vnd.geogebra.tool'},
    {key:'application/vnd.hcl-bireports',value:'application/vnd.hcl-bireports'},
    {key:'application/vnd.ims.imsccv1p1',value:'application/vnd.ims.imsccv1p1'},
    {key:'application/vnd.ims.imsccv1p2',value:'application/vnd.ims.imsccv1p2'},
    {key:'application/vnd.ims.imsccv1p3',value:'application/vnd.ims.imsccv1p3'},
    {key:'application/vnd.lotus-wordpro',value:'application/vnd.lotus-wordpro'},
    {key:'application/vnd.motorola.iprm',value:'application/vnd.motorola.iprm'},
    {key:'application/vnd.ms-fontobject',value:'application/vnd.ms-fontobject'},
    {key:'application/vnd.ms-powerpoint',value:'application/vnd.ms-powerpoint'},
    {key:'application/vnd.ncd.reference',value:'application/vnd.ncd.reference'},
    {key:'application/vnd.nebumind.line',value:'application/vnd.nebumind.line'},
    {key:'application/vnd.nokia.pcd+xml',value:'application/vnd.nokia.pcd+xml'},
    {key:'application/vnd.oma.lwm2m+tlv',value:'application/vnd.oma.lwm2m+tlv'},
    {key:'application/vnd.orange.indata',value:'application/vnd.orange.indata'},
    {key:'application/vnd.osa.netdeploy',value:'application/vnd.osa.netdeploy'},
    {key:'application/vnd.powerbuilder6',value:'application/vnd.powerbuilder6'},
    {key:'application/vnd.powerbuilder7',value:'application/vnd.powerbuilder7'},
    {key:'application/vnd.rainstor.data',value:'application/vnd.rainstor.data'},
    {key:'application/vnd.shopkick+json',value:'application/vnd.shopkick+json'},
    {key:'application/vnd.smart.teacher',value:'application/vnd.smart.teacher'},
    {key:'application/vnd.street-stream',value:'application/vnd.street-stream'},
    {key:'application/vnd.swiftview-ics',value:'application/vnd.swiftview-ics'},
    {key:'application/vnd.syncml.dm+xml',value:'application/vnd.syncml.dm+xml'},
    {key:'application/vnd.tri.onesource',value:'application/vnd.tri.onesource'},
    {key:'application/vnd.uplanet.alert',value:'application/vnd.uplanet.alert'},
    {key:'application/vnd.ves.encrypted',value:'application/vnd.ves.encrypted'},
    {key:'application/vnd.wmf.bootstrap',value:'application/vnd.wmf.bootstrap'},
    {key:'application/vnd.yamaha.hv-dic',value:'application/vnd.yamaha.hv-dic'},
    {key:'application/x-x509-ca-ra-cert',value:'application/x-x509-ca-ra-cert'},
    {key:'application/geopackage+sqlite3',value:'application/geopackage+sqlite3'},
    {key:'application/im-iscomposing+xml',value:'application/im-iscomposing+xml'},
    {key:'application/mathml-content+xml',value:'application/mathml-content+xml'},
    {key:'application/resource-lists+xml',value:'application/resource-lists+xml'},
    {key:'application/sparql-results+xml',value:'application/sparql-results+xml'},
    {key:'application/urc-targetdesc+xml',value:'application/urc-targetdesc+xml'},
    {key:'application/vnd.3gpp-prose+xml',value:'application/vnd.3gpp-prose+xml'},
    {key:'application/vnd.biopax.rdf+xml',value:'application/vnd.biopax.rdf+xml'},
    {key:'application/vnd.cyclonedx+json',value:'application/vnd.cyclonedx+json'},
    {key:'application/vnd.dolby.mobile.1',value:'application/vnd.dolby.mobile.1'},
    {key:'application/vnd.dolby.mobile.2',value:'application/vnd.dolby.mobile.2'},
    {key:'application/vnd.dtg.local.html',value:'application/vnd.dtg.local.html'},
    {key:'application/vnd.dvb.dvbisl+xml',value:'application/vnd.dvb.dvbisl+xml'},
    {key:'application/vnd.dvb.ipdcesgpdd',value:'application/vnd.dvb.ipdcesgpdd'},
    {key:'application/vnd.ficlab.flb+zip',value:'application/vnd.ficlab.flb+zip'},
    {key:'application/vnd.fujitsu.oasys2',value:'application/vnd.fujitsu.oasys2'},
    {key:'application/vnd.fujitsu.oasys3',value:'application/vnd.fujitsu.oasys3'},
    {key:'application/vnd.fujixerox.ART4',value:'application/vnd.fujixerox.ART4'},
    {key:'application/vnd.fujixerox.HBPL',value:'application/vnd.fujixerox.HBPL'},
    {key:'application/vnd.groove-account',value:'application/vnd.groove-account'},
    {key:'application/vnd.kde.kpresenter',value:'application/vnd.kde.kpresenter'},
    {key:'application/vnd.lotus-approach',value:'application/vnd.lotus-approach'},
    {key:'application/vnd.micrografx.flo',value:'application/vnd.micrografx.flo'},
    {key:'application/vnd.micrografx.igx',value:'application/vnd.micrografx.igx'},
    {key:'application/vnd.ms-3mfdocument',value:'application/vnd.ms-3mfdocument'},
    {key:'application/vnd.ms-officetheme',value:'application/vnd.ms-officetheme'},
    {key:'application/vnd.ms-xpsdocument',value:'application/vnd.ms-xpsdocument'},
    {key:'application/vnd.msa-disk-image',value:'application/vnd.msa-disk-image'},
    {key:'application/vnd.nokia.catalogs',value:'application/vnd.nokia.catalogs'},
    {key:'application/vnd.oftn.l10n+json',value:'application/vnd.oftn.l10n+json'},
    {key:'application/vnd.oma.bcast.ltkm',value:'application/vnd.oma.bcast.ltkm'},
    {key:'application/vnd.oma.bcast.sgdu',value:'application/vnd.oma.bcast.sgdu'},
    {key:'application/vnd.oma.bcast.stkm',value:'application/vnd.oma.bcast.stkm'},
    {key:'application/vnd.oma.lwm2m+cbor',value:'application/vnd.oma.lwm2m+cbor'},
    {key:'application/vnd.oma.lwm2m+json',value:'application/vnd.oma.lwm2m+json'},
    {key:'application/vnd.omads-file+xml',value:'application/vnd.omads-file+xml'},
    {key:'application/vnd.onvif.metadata',value:'application/vnd.onvif.metadata'},
    {key:'application/vnd.osgi.subsystem',value:'application/vnd.osgi.subsystem'},
    {key:'application/vnd.pagerduty+json',value:'application/vnd.pagerduty+json'},
    {key:'application/vnd.powerbuilder75',value:'application/vnd.powerbuilder75'},
    {key:'application/vnd.quarantainenet',value:'application/vnd.quarantainenet'},
    {key:'application/vnd.rig.cryptonote',value:'application/vnd.rig.cryptonote'},
    {key:'application/vnd.sigrok.session',value:'application/vnd.sigrok.session'},
    {key:'application/vnd.smart.notebook',value:'application/vnd.smart.notebook'},
    {key:'application/vnd.tmobile-livetv',value:'application/vnd.tmobile-livetv'},
    {key:'application/vnd.uplanet.signal',value:'application/vnd.uplanet.signal'},
    {key:'application/vnd.wap.wmlscriptc',value:'application/vnd.wap.wmlscriptc'},
    {key:'application/vnd.wolfram.player',value:'application/vnd.wolfram.player'},
    {key:'application/vnd.zzazz.deck+xml',value:'application/vnd.zzazz.deck+xml'},
    {key:'application/alto-directory+json',value:'application/alto-directory+json'},
    {key:'application/conference-info+xml',value:'application/conference-info+xml'},
    {key:'application/ibe-key-request+xml',value:'application/ibe-key-request+xml'},
    {key:'application/mbms-deregister+xml',value:'application/mbms-deregister+xml'},
    {key:'application/oauth-authz-req+jwt',value:'application/oauth-authz-req+jwt'},
    {key:'application/patch-ops-error+xml',value:'application/patch-ops-error+xml'},
    {key:'application/tamp-update-confirm',value:'application/tamp-update-confirm'},
    {key:'application/trickle-ice-sdpfrag',value:'application/trickle-ice-sdpfrag'},
    {key:'application/vnd.3gpp.pic-bw-var',value:'application/vnd.3gpp.pic-bw-var'},
    {key:'application/vnd.businessobjects',value:'application/vnd.businessobjects'},
    {key:'application/vnd.collection+json',value:'application/vnd.collection+json'},
    {key:'application/vnd.cups-postscript',value:'application/vnd.cups-postscript'},
    {key:'application/vnd.data-vision.rdz',value:'application/vnd.data-vision.rdz'},
    {key:'application/vnd.dtg.local.flash',value:'application/vnd.dtg.local.flash'},
    {key:'application/vnd.dvb.ipdcroaming',value:'application/vnd.dvb.ipdcroaming'},
    {key:'application/vnd.etsi.asic-s+zip',value:'application/vnd.etsi.asic-s+zip'},
    {key:'application/vnd.etsi.asic-e+zip',value:'application/vnd.etsi.asic-e+zip'},
    {key:'application/vnd.f-secure.mobile',value:'application/vnd.f-secure.mobile'},
    {key:'application/vnd.fujitsu.oasysgp',value:'application/vnd.fujitsu.oasysgp'},
    {key:'application/vnd.geogebra.slides',value:'application/vnd.geogebra.slides'},
    {key:'application/vnd.groove-injector',value:'application/vnd.groove-injector'},
    {key:'application/vnd.hyper-item+json',value:'application/vnd.hyper-item+json'},
    {key:'application/vnd.hyperdrive+json',value:'application/vnd.hyperdrive+json'},
    {key:'application/vnd.ibm.afplinedata',value:'application/vnd.ibm.afplinedata'},
    {key:'application/vnd.immervision-ivp',value:'application/vnd.immervision-ivp'},
    {key:'application/vnd.immervision-ivu',value:'application/vnd.immervision-ivu'},
    {key:'application/vnd.intertrust.nncp',value:'application/vnd.intertrust.nncp'},
    {key:'application/vnd.iso11783-10+zip',value:'application/vnd.iso11783-10+zip'},
    {key:'application/vnd.lotus-freelance',value:'application/vnd.lotus-freelance'},
    {key:'application/vnd.lotus-organizer',value:'application/vnd.lotus-organizer'},
    {key:'application/vnd.lotus-screencam',value:'application/vnd.lotus-screencam'},
    {key:'application/vnd.marlin.drm.mdcf',value:'application/vnd.marlin.drm.mdcf'},
    {key:'application/vnd.mozilla.xul+xml',value:'application/vnd.mozilla.xul+xml'},
    {key:'application/vnd.multiad.creator',value:'application/vnd.multiad.creator'},
    {key:'application/vnd.nearst.inv+json',value:'application/vnd.nearst.inv+json'},
    {key:'application/vnd.noblenet-sealer',value:'application/vnd.noblenet-sealer'},
    {key:'application/vnd.nokia.conml+xml',value:'application/vnd.nokia.conml+xml'},
    {key:'application/vnd.nokia.pcd+wbxml',value:'application/vnd.nokia.pcd+wbxml'},
    {key:'application/vnd.oma.cab-pcc+xml',value:'application/vnd.oma.cab-pcc+xml'},
    {key:'application/vnd.omads-email+xml',value:'application/vnd.omads-email+xml'},
    {key:'application/vnd.oma-scws-config',value:'application/vnd.oma-scws-config'},
    {key:'application/vnd.otps.ct-kip+xml',value:'application/vnd.otps.ct-kip+xml'},
    {key:'application/vnd.oxli.countgraph',value:'application/vnd.oxli.countgraph'},
    {key:'application/vnd.powerbuilder6-s',value:'application/vnd.powerbuilder6-s'},
    {key:'application/vnd.powerbuilder7-s',value:'application/vnd.powerbuilder7-s'},
    {key:'application/vnd.pwg-multiplexed',value:'application/vnd.pwg-multiplexed'},
    {key:'application/vnd.resilient.logic',value:'application/vnd.resilient.logic'},
    {key:'application/vnd.ruckus.download',value:'application/vnd.ruckus.download'},
    {key:'application/vnd.shade-save-file',value:'application/vnd.shade-save-file'},
    {key:'application/vnd.shootproof+json',value:'application/vnd.shootproof+json'},
    {key:'application/vnd.solent.sdkm+xml',value:'application/vnd.solent.sdkm+xml'},
    {key:'application/vnd.syncml.dm+wbxml',value:'application/vnd.syncml.dm+wbxml'},
    {key:'application/vnd.uplanet.cacheop',value:'application/vnd.uplanet.cacheop'},
    {key:'application/vnd.uplanet.channel',value:'application/vnd.uplanet.channel'},
    {key:'application/vnd.uplanet.listcmd',value:'application/vnd.uplanet.listcmd'},
    {key:'application/vnd.verimatrix.vcas',value:'application/vnd.verimatrix.vcas'},
    {key:'application/vnd.yamaha.hv-voice',value:'application/vnd.yamaha.hv-voice'},
    {key:'application/x-x509-next-ca-cert',value:'application/x-x509-next-ca-cert'},
    {key:'application/alto-cdnifilter+json',value:'application/alto-cdnifilter+json'},
    {key:'application/alto-networkmap+json',value:'application/alto-networkmap+json'},
    {key:'application/tamp-sequence-adjust',value:'application/tamp-sequence-adjust'},
    {key:'application/tamp-status-response',value:'application/tamp-status-response'},
    {key:'application/urc-uisocketdesc+xml',value:'application/urc-uisocketdesc+xml'},
    {key:'application/vnd.3M.Post-it-Notes',value:'application/vnd.3M.Post-it-Notes'},
    {key:'application/vnd.afpc.afplinedata',value:'application/vnd.afpc.afplinedata'},
    {key:'application/vnd.bbf.usp.msg+json',value:'application/vnd.bbf.usp.msg+json'},
    {key:'application/vnd.bint.med-content',value:'application/vnd.bint.med-content'},
    {key:'application/vnd.bluetooth.ep.oob',value:'application/vnd.bluetooth.ep.oob'},
    {key:'application/vnd.bluetooth.le.oob',value:'application/vnd.bluetooth.le.oob'},
    {key:'application/vnd.cirpack.isdn-ext',value:'application/vnd.cirpack.isdn-ext'},
    {key:'application/vnd.datapackage+json',value:'application/vnd.datapackage+json'},
    {key:'application/vnd.dece.unspecified',value:'application/vnd.dece.unspecified'},
    {key:'application/vnd.dvb.esgcontainer',value:'application/vnd.dvb.esgcontainer'},
    {key:'application/vnd.eprints.data+xml',value:'application/vnd.eprints.data+xml'},
    {key:'application/vnd.epson.quickanime',value:'application/vnd.epson.quickanime'},
    {key:'application/vnd.evolv.ecig.theme',value:'application/vnd.evolv.ecig.theme'},
    {key:'application/vnd.exstream-package',value:'application/vnd.exstream-package'},
    {key:'application/vnd.fujitsu.oasysprs',value:'application/vnd.fujitsu.oasysprs'},
    {key:'application/vnd.fujixerox.ART-EX',value:'application/vnd.fujixerox.ART-EX'},
    {key:'application/vnd.genomatix.tuxedo',value:'application/vnd.genomatix.tuxedo'},
    {key:'application/vnd.gentics.grd+json',value:'application/vnd.gentics.grd+json'},
    {key:'application/vnd.google-earth.kmz',value:'application/vnd.google-earth.kmz'},
    {key:'application/vnd.hzn-3d-crossword',value:'application/vnd.hzn-3d-crossword'},
    {key:'application/vnd.infotech.project',value:'application/vnd.infotech.project'},
    {key:'application/vnd.intercon.formnet',value:'application/vnd.intercon.formnet'},
    {key:'application/vnd.kodak-descriptor',value:'application/vnd.kodak-descriptor'},
    {key:'application/vnd.macports.portpkg',value:'application/vnd.macports.portpkg'},
    {key:'application/vnd.oipf.dae.svg+xml',value:'application/vnd.oipf.dae.svg+xml'},
    {key:'application/vnd.oipf.spdlist+xml',value:'application/vnd.oipf.spdlist+xml'},
    {key:'application/vnd.oma.bcast.sgboot',value:'application/vnd.oma.bcast.sgboot'},
    {key:'application/vnd.oma.drm.risd+xml',value:'application/vnd.oma.drm.risd+xml'},
    {key:'application/vnd.omads-folder+xml',value:'application/vnd.omads-folder+xml'},
    {key:'application/vnd.omaloc-supl-init',value:'application/vnd.omaloc-supl-init'},
    {key:'application/vnd.patientecommsdoc',value:'application/vnd.patientecommsdoc'},
    {key:'application/vnd.powerbuilder75-s',value:'application/vnd.powerbuilder75-s'},
    {key:'application/vnd.proteus.magazine',value:'application/vnd.proteus.magazine'},
    {key:'application/vnd.radisys.moml+xml',value:'application/vnd.radisys.moml+xml'},
    {key:'application/vnd.radisys.msml+xml',value:'application/vnd.radisys.msml+xml'},
    {key:'application/vnd.RenLearn.rlprint',value:'application/vnd.RenLearn.rlprint'},
    {key:'application/vnd.syncml.dmddf+xml',value:'application/vnd.syncml.dmddf+xml'},
    {key:'application/vnd.tableschema+json',value:'application/vnd.tableschema+json'},
    {key:'application/vnd.yamaha.hv-script',value:'application/vnd.yamaha.hv-script'},
    {key:'application/webpush-options+json',value:'application/webpush-options+json'},
    {key:'application/expect-ct-report+json',value:'application/expect-ct-report+json'},
    {key:'application/mbms-msk-response+xml',value:'application/mbms-msk-response+xml'},
    {key:'application/oblivious-dns-message',value:'application/oblivious-dns-message'},
    {key:'application/pem-certificate-chain',value:'application/pem-certificate-chain'},
    {key:'application/simpleSymbolContainer',value:'application/simpleSymbolContainer'},
    {key:'application/tamp-community-update',value:'application/tamp-community-update'},
    {key:'application/vnd.3gpp.mid-call+xml',value:'application/vnd.3gpp.mid-call+xml'},
    {key:'application/vnd.3gpp.pic-bw-large',value:'application/vnd.3gpp.pic-bw-large'},
    {key:'application/vnd.3gpp.pic-bw-small',value:'application/vnd.3gpp.pic-bw-small'},
    {key:'application/vnd.accpac.simply.aso',value:'application/vnd.accpac.simply.aso'},
    {key:'application/vnd.accpac.simply.imp',value:'application/vnd.accpac.simply.imp'},
    {key:'application/vnd.adobe.flash.movie',value:'application/vnd.adobe.flash.movie'},
    {key:'application/vnd.afpc.foca-charset',value:'application/vnd.afpc.foca-charset'},
    {key:'application/vnd.amundsen.maze+xml',value:'application/vnd.amundsen.maze+xml'},
    {key:'application/vnd.apache.arrow.file',value:'application/vnd.apache.arrow.file'},
    {key:'application/vnd.balsamiq.bmml+xml',value:'application/vnd.balsamiq.bmml+xml'},
    {key:'application/vnd.banana-accounting',value:'application/vnd.banana-accounting'},
    {key:'application/vnd.blueice.multipass',value:'application/vnd.blueice.multipass'},
    {key:'application/vnd.commerce-battelle',value:'application/vnd.commerce-battelle'},
    {key:'application/vnd.cryptii.pipe+json',value:'application/vnd.cryptii.pipe+json'},
    {key:'application/vnd.crypto-shade-file',value:'application/vnd.crypto-shade-file'},
    {key:'application/vnd.cryptomator.vault',value:'application/vnd.cryptomator.vault'},
    {key:'application/vnd.dataresource+json',value:'application/vnd.dataresource+json'},
    {key:'application/vnd.dm.delegation+xml',value:'application/vnd.dm.delegation+xml'},
    {key:'application/vnd.dvb.ipdcesgaccess',value:'application/vnd.dvb.ipdcesgaccess'},
    {key:'application/vnd.ecowin.fileupdate',value:'application/vnd.ecowin.fileupdate'},
    {key:'application/vnd.espass-espass+zip',value:'application/vnd.espass-espass+zip'},
    {key:'application/vnd.etsi.iptvsync+xml',value:'application/vnd.etsi.iptvsync+xml'},
    {key:'application/vnd.etsi.simservs+xml',value:'application/vnd.etsi.simservs+xml'},
    {key:'application/vnd.geometry-explorer',value:'application/vnd.geometry-explorer'},
    {key:'application/vnd.gov.sk.e-form+xml',value:'application/vnd.gov.sk.e-form+xml'},
    {key:'application/vnd.gov.sk.e-form+zip',value:'application/vnd.gov.sk.e-form+zip'},
    {key:'application/vnd.hhe.lesson-player',value:'application/vnd.hhe.lesson-player'},
    {key:'application/vnd.ms-cab-compressed',value:'application/vnd.ms-cab-compressed'},
    {key:'application/vnd.ms-wmdrm.lic-resp',value:'application/vnd.ms-wmdrm.lic-resp'},
    {key:'application/vnd.neurolanguage.nlu',value:'application/vnd.neurolanguage.nlu'},
    {key:'application/vnd.nintendo.snes.rom',value:'application/vnd.nintendo.snes.rom'},
    {key:'application/vnd.nokia.conml+wbxml',value:'application/vnd.nokia.conml+wbxml'},
    {key:'application/vnd.nokia.n-gage.data',value:'application/vnd.nokia.n-gage.data'},
    {key:'application/vnd.oma.bcast.imd+xml',value:'application/vnd.oma.bcast.imd+xml'},
    {key:'application/vnd.openblox.game+xml',value:'application/vnd.openblox.game+xml'},
    {key:'application/vnd.Quark.QuarkXPress',value:'application/vnd.Quark.QuarkXPress'},
    {key:'application/vnd.stepmania.package',value:'application/vnd.stepmania.package'},
    {key:'application/vnd.syncml.dmtnds+xml',value:'application/vnd.syncml.dmtnds+xml'},
    {key:'application/vnd.ubisoft.webplayer',value:'application/vnd.ubisoft.webplayer'},
    {key:'application/vnd.yamaha.smaf-audio',value:'application/vnd.yamaha.smaf-audio'},
    {key:'application/x-www-form-urlencoded',value:'application/x-www-form-urlencoded'},
    {key:'application/3gpdash-qoe-report+xml',value:'application/3gpdash-qoe-report+xml'},
    {key:'application/alto-endpointprop+json',value:'application/alto-endpointprop+json'},
    {key:'application/alto-endpointcost+json',value:'application/alto-endpointcost+json'},
    {key:'application/mediaservercontrol+xml',value:'application/mediaservercontrol+xml'},
    {key:'application/set-payment-initiation',value:'application/set-payment-initiation'},
    {key:'application/simple-message-summary',value:'application/simple-message-summary'},
    {key:'application/vnd.3gpp.srvcc-ext+xml',value:'application/vnd.3gpp.srvcc-ext+xml'},
    {key:'application/vnd.afpc.foca-codepage',value:'application/vnd.afpc.foca-codepage'},
    {key:'application/vnd.afpc.modca-cmtable',value:'application/vnd.afpc.modca-cmtable'},
    {key:'application/vnd.afpc.modca-formdef',value:'application/vnd.afpc.modca-formdef'},
    {key:'application/vnd.afpc.modca-overlay',value:'application/vnd.afpc.modca-overlay'},
    {key:'application/vnd.amazon.mobi8-ebook',value:'application/vnd.amazon.mobi8-ebook'},
    {key:'application/vnd.apache.thrift.json',value:'application/vnd.apache.thrift.json'},
    {key:'application/vnd.aristanetworks.swi',value:'application/vnd.aristanetworks.swi'},
    {key:'application/vnd.cyan.dean.root+xml',value:'application/vnd.cyan.dean.root+xml'},
    {key:'application/vnd.dvb.ipdcesgaccess2',value:'application/vnd.dvb.ipdcesgaccess2'},
    {key:'application/vnd.dvb.notif-init+xml',value:'application/vnd.dvb.notif-init+xml'},
    {key:'application/vnd.eclipse.ditto+json',value:'application/vnd.eclipse.ditto+json'},
    {key:'application/vnd.ecowin.filerequest',value:'application/vnd.ecowin.filerequest'},
    {key:'application/vnd.ericsson.quickcall',value:'application/vnd.ericsson.quickcall'},
    {key:'application/vnd.evolv.ecig.profile',value:'application/vnd.evolv.ecig.profile'},
    {key:'application/vnd.font-fontforge-sfd',value:'application/vnd.font-fontforge-sfd'},
    {key:'application/vnd.informix-visionary',value:'application/vnd.informix-visionary'},
    {key:'application/vnd.intertrust.digibox',value:'application/vnd.intertrust.digibox'},
    {key:'application/vnd.joost.joda-archive',value:'application/vnd.joost.joda-archive'},
    {key:'application/vnd.mapbox-vector-tile',value:'application/vnd.mapbox-vector-tile'},
    {key:'application/vnd.maxmind.maxmind-db',value:'application/vnd.maxmind.maxmind-db'},
    {key:'application/vnd.mediastation.cdkey',value:'application/vnd.mediastation.cdkey'},
    {key:'application/vnd.meridian-slingshot',value:'application/vnd.meridian-slingshot'},
    {key:'application/vnd.mophun.application',value:'application/vnd.mophun.application'},
    {key:'application/vnd.mophun.certificate',value:'application/vnd.mophun.certificate'},
    {key:'application/vnd.motorola.flexsuite',value:'application/vnd.motorola.flexsuite'},
    {key:'application/vnd.ms-windows.wsd.oob',value:'application/vnd.ms-windows.wsd.oob'},
    {key:'application/vnd.nacamar.ybrid+json',value:'application/vnd.nacamar.ybrid+json'},
    {key:'application/vnd.nintendo.nitro.rom',value:'application/vnd.nintendo.nitro.rom'},
    {key:'application/vnd.noblenet-directory',value:'application/vnd.noblenet-directory'},
    {key:'application/vnd.nokia.landmark+xml',value:'application/vnd.nokia.landmark+xml'},
    {key:'application/vnd.nokia.radio-preset',value:'application/vnd.nokia.radio-preset'},
    {key:'application/vnd.oipf.dae.xhtml+xml',value:'application/vnd.oipf.dae.xhtml+xml'},
    {key:'application/vnd.oipf.ueprofile+xml',value:'application/vnd.oipf.ueprofile+xml'},
    {key:'application/vnd.oma.bcast.sgdd+xml',value:'application/vnd.oma.bcast.sgdd+xml'},
    {key:'application/vnd.oma.poc.groups+xml',value:'application/vnd.oma.poc.groups+xml'},
    {key:'application/vnd.opentimestamps.ots',value:'application/vnd.opentimestamps.ots'},
    {key:'application/vnd.previewsystems.box',value:'application/vnd.previewsystems.box'},
    {key:'application/vnd.recordare.musicxml',value:'application/vnd.recordare.musicxml'},
    {key:'application/vnd.route66.link66+xml',value:'application/vnd.route66.link66+xml'},
    {key:'application/vnd.SimTech-MindMapper',value:'application/vnd.SimTech-MindMapper'},
    {key:'application/vnd.snesdev-page-table',value:'application/vnd.snesdev-page-table'},
    {key:'application/vnd.syncml.dmddf+wbxml',value:'application/vnd.syncml.dmddf+wbxml'},
    {key:'application/vnd.uplanet.list-wbxml',value:'application/vnd.uplanet.list-wbxml'},
    {key:'application/vnd.veritone.aion+json',value:'application/vnd.veritone.aion+json'},
    {key:'application/vnd.yamaha.smaf-phrase',value:'application/vnd.yamaha.smaf-phrase'},
    {key:'application/vnd.yamaha.through-ngn',value:'application/vnd.yamaha.through-ngn'},
    {key:'application/alto-costmapfilter+json', value:'application/alto-costmapfilter+json'},
    {key:'application/alto-propmapparams+json', value:'application/alto-propmapparams+json'},
    {key:'application/mathml-presentation+xml', value:'application/mathml-presentation+xml'},
    {key:'application/missing-blocks+cbor-seq', value:'application/missing-blocks+cbor-seq'},
    {key:'application/relax-ng-compact-syntax', value:'application/relax-ng-compact-syntax'},
    {key:'application/resource-lists-diff+xml', value:'application/resource-lists-diff+xml'},
    {key:'application/token-introspection+jwt', value:'application/token-introspection+jwt'},
    {key:'application/vnd.3gpp.mcdata-payload', value:'application/vnd.3gpp.mcdata-payload'},
    {key:'application/vnd.3gpp.mcptt-info+xml', value:'application/vnd.3gpp.mcptt-info+xml'},
    {key:'application/vnd.3gpp.SRVCC-info+xml', value:'application/vnd.3gpp.SRVCC-info+xml'},
    {key:'application/vnd.3gpp2.bcmcsinfo+xml', value:'application/vnd.3gpp2.bcmcsinfo+xml'},
    {key:'application/vnd.afpc.foca-codedfont', value:'application/vnd.afpc.foca-codedfont'},
    {key:'application/vnd.apache.arrow.stream', value:'application/vnd.apache.arrow.stream'},
    {key:'application/vnd.aplextor.warrp+json', value:'application/vnd.aplextor.warrp+json'},
    {key:'application/vnd.apple.installer+xml', value:'application/vnd.apple.installer+xml'},
    {key:'application/vnd.bekitzur-stech+json', value:'application/vnd.bekitzur-stech+json'},
    {key:'application/vnd.capasystems-pg+json', value:'application/vnd.capasystems-pg+json'},
    {key:'application/vnd.collection.doc+json', value:'application/vnd.collection.doc+json'},
    {key:'application/vnd.dvb.iptv.alfec-base', value:'application/vnd.dvb.iptv.alfec-base'},
    {key:'application/vnd.ecowin.seriesupdate', value:'application/vnd.ecowin.seriesupdate'},
    {key:'application/vnd.etsi.iptvsad-bc+xml', value:'application/vnd.etsi.iptvsad-bc+xml'},
    {key:'application/vnd.evolv.ecig.settings', value:'application/vnd.evolv.ecig.settings'},
    {key:'application/vnd.fastcopy-disk-image', value:'application/vnd.fastcopy-disk-image'},
    {key:'application/vnd.fujifilm.fb.jfi+xml', value:'application/vnd.fujifilm.fb.jfi+xml'},
    {key:'application/vnd.fujixerox.docuworks', value:'application/vnd.fujixerox.docuworks'},
    {key:'application/vnd.groove-tool-message', value:'application/vnd.groove-tool-message'},
    {key:'application/vnd.liberty-request+xml', value:'application/vnd.liberty-request+xml'},
    {key:'application/vnd.ms-wmdrm.meter-resp', value:'application/vnd.ms-wmdrm.meter-resp'},
    {key:'application/vnd.multiad.creator.cif', value:'application/vnd.multiad.creator.cif'},
    {key:'application/vnd.nokia.n-gage.ac+xml', value:'application/vnd.nokia.n-gage.ac+xml'},
    {key:'application/vnd.nokia.radio-presets', value:'application/vnd.nokia.radio-presets'},
    {key:'application/vnd.oipf.cspg-hexbinary', value:'application/vnd.oipf.cspg-hexbinary'},
    {key:'application/vnd.oma.bcast.sprov+xml', value:'application/vnd.oma.bcast.sprov+xml'},
    {key:'application/vnd.pwg-xhtml-print+xml', value:'application/vnd.pwg-xhtml-print+xml'},
    {key:'application/vnd.stepmania.stepchart', value:'application/vnd.stepmania.stepchart'},
    {key:'application/vnd.syncml.dmtnds+wbxml', value:'application/vnd.syncml.dmtnds+wbxml'},
    {key:'application/vnd.uplanet.alert-wbxml', value:'application/vnd.uplanet.alert-wbxml'},
    {key:'application/vnd.wolfram.mathematica', value:'application/vnd.wolfram.mathematica'},
    {key:'application/vnd.wrq-hp3000-labelled', value:'application/vnd.wrq-hp3000-labelled'},
    {key:'application/vnd.yamaha.remote-setup', value:'application/vnd.yamaha.remote-setup'},
    {key:'application/1d-interleaved-parityfec', value:'application/1d-interleaved-parityfec'},
    {key:'application/framework-attributes+xml', value:'application/framework-attributes+xml'},
    {key:'application/media-policy-dataset+xml', value:'application/media-policy-dataset+xml'},
    {key:'application/tamp-apex-update-confirm', value:'application/tamp-apex-update-confirm'},
    {key:'application/vnd.3gpp.mcdata-info+xml', value:'application/vnd.3gpp.mcdata-info+xml'},
    {key:'application/vnd.3gpp-prose-pc3ch+xml', value:'application/vnd.3gpp-prose-pc3ch+xml'},
    {key:'application/vnd.adobe.partial-upload', value:'application/vnd.adobe.partial-upload'},
    {key:'application/vnd.afpc.modca-mediummap', value:'application/vnd.afpc.modca-mediummap'},
    {key:'application/vnd.americandynamics.acc', value:'application/vnd.americandynamics.acc'},
    {key:'application/vnd.antix.game-component', value:'application/vnd.antix.game-component'},
    {key:'application/vnd.apache.thrift.binary', value:'application/vnd.apache.thrift.binary'},
    {key:'application/vnd.belightsoft.lhzd+zip', value:'application/vnd.belightsoft.lhzd+zip'},
    {key:'application/vnd.belightsoft.lhzl+zip', value:'application/vnd.belightsoft.lhzl+zip'},
    {key:'application/vnd.chipnuts.karaoke-mmd', value:'application/vnd.chipnuts.karaoke-mmd'},
    {key:'application/vnd.collection.next+json', value:'application/vnd.collection.next+json'},
    {key:'application/vnd.coreos.ignition+json', value:'application/vnd.coreos.ignition+json'},
    {key:'application/vnd.ecowin.seriesrequest', value:'application/vnd.ecowin.seriesrequest'},
    {key:'application/vnd.etsi.iptvcommand+xml', value:'application/vnd.etsi.iptvcommand+xml'},
    {key:'application/vnd.etsi.iptvprofile+xml', value:'application/vnd.etsi.iptvprofile+xml'},
    {key:'application/vnd.etsi.iptvsad-cod+xml', value:'application/vnd.etsi.iptvsad-cod+xml'},
    {key:'application/vnd.etsi.iptvservice+xml', value:'application/vnd.etsi.iptvservice+xml'},
    {key:'application/vnd.etsi.timestamp-token', value:'application/vnd.etsi.timestamp-token'},
    {key:'application/vnd.exstream-empower+zip', value:'application/vnd.exstream-empower+zip'},
    {key:'application/vnd.google-earth.kml+xml', value:'application/vnd.google-earth.kml+xml'},
    {key:'application/vnd.groove-tool-template', value:'application/vnd.groove-tool-template'},
    {key:'application/vnd.hydrostatix.sof-data', value:'application/vnd.hydrostatix.sof-data'},
    {key:'application/vnd.ibm.electronic-media', value:'application/vnd.ibm.electronic-media'},
    {key:'application/vnd.ibm.secure-container', value:'application/vnd.ibm.secure-container'},
    {key:'application/vnd.imagemeter.image+zip', value:'application/vnd.imagemeter.image+zip'},
    {key:'application/vnd.infotech.project+xml', value:'application/vnd.infotech.project+xml'},
    {key:'application/vnd.iptc.g2.newsitem+xml', value:'application/vnd.iptc.g2.newsitem+xml'},
    {key:'application/vnd.logipipe.circuit+zip', value:'application/vnd.logipipe.circuit+zip'},
    {key:'application/vnd.minisoft-hp3000-save', value:'application/vnd.minisoft-hp3000-save'},
    {key:'application/vnd.nokia.landmark+wbxml', value:'application/vnd.nokia.landmark+wbxml'},
    {key:'application/vnd.oipf.spdiscovery+xml', value:'application/vnd.oipf.spdiscovery+xml'},
    {key:'application/vnd.oipf.userprofile+xml', value:'application/vnd.oipf.userprofile+xml'},
    {key:'application/vnd.openblox.game-binary', value:'application/vnd.openblox.game-binary'},
    {key:'application/vnd.oracle.resource+json', value:'application/vnd.oracle.resource+json'},
    {key:'application/vnd.sailingtracker.track', value:'application/vnd.sailingtracker.track'},
    {key:'application/vnd.vividence.scriptfile', value:'application/vnd.vividence.scriptfile'},
    {key:'application/xcon-conference-info+xml', value:'application/xcon-conference-info+xml'},
    {key:'application/EmergencyCallData.cap+xml',value:'application/EmergencyCallData.cap+xml'},
    {key:'application/mbms-reception-report+xml',value:'application/mbms-reception-report+xml'},
    {key:'application/vnd.3gpp.mcptt-signed+xml',value:'application/vnd.3gpp.mcptt-signed+xml'},
    {key:'application/vnd.3gpp.mcvideo-info+xml',value:'application/vnd.3gpp.mcvideo-info+xml'},
    {key:'application/vnd.afpc.cmoca-cmresource',value:'application/vnd.afpc.cmoca-cmresource'},
    {key:'application/vnd.airzip.filesecure.azf',value:'application/vnd.airzip.filesecure.azf'},
    {key:'application/vnd.airzip.filesecure.azs',value:'application/vnd.airzip.filesecure.azs'},
    {key:'application/vnd.apache.thrift.compact',value:'application/vnd.apache.thrift.compact'},
    {key:'application/vnd.astraea-software.iota',value:'application/vnd.astraea-software.iota'},
    {key:'application/vnd.crick.clicker.palette',value:'application/vnd.crick.clicker.palette'},
    {key:'application/vnd.criticaltools.wbs+xml',value:'application/vnd.criticaltools.wbs+xml'},
    {key:'application/vnd.cryptomator.encrypted',value:'application/vnd.cryptomator.encrypted'},
    {key:'application/vnd.debian.binary-package',value:'application/vnd.debian.binary-package'},
    {key:'application/vnd.dvb.notif-generic+xml',value:'application/vnd.dvb.notif-generic+xml'},
    {key:'application/vnd.etsi.iptvsad-npvr+xml',value:'application/vnd.etsi.iptvsad-npvr+xml'},
    {key:'application/vnd.eu.kasparian.car+json',value:'application/vnd.eu.kasparian.car+json'},
    {key:'application/vnd.firemonkeys.cloudcell',value:'application/vnd.firemonkeys.cloudcell'},
    {key:'application/vnd.fujifilm.fb.docuworks',value:'application/vnd.fujifilm.fb.docuworks'},
    {key:'application/vnd.ibm.rights-management',value:'application/vnd.ibm.rights-management'},
    {key:'application/vnd.imagemeter.folder+zip',value:'application/vnd.imagemeter.folder+zip'},
    {key:'application/vnd.ipunplugged.rcprofile',value:'application/vnd.ipunplugged.rcprofile'},
    {key:'application/vnd.japannet-registration',value:'application/vnd.japannet-registration'},
    {key:'application/vnd.japannet-verification',value:'application/vnd.japannet-verification'},
    {key:'application/vnd.jcp.javame.midlet-rms',value:'application/vnd.jcp.javame.midlet-rms'},
    {key:'application/vnd.maxar.archive.3tz+zip',value:'application/vnd.maxar.archive.3tz+zip'},
    {key:'application/vnd.ms-office.activeX+xml',value:'application/vnd.ms-office.activeX+xml'},
    {key:'application/vnd.ms-wmdrm.lic-chlg-req',value:'application/vnd.ms-wmdrm.lic-chlg-req'},
    {key:'application/vnd.nokia.iptv.config+xml',value:'application/vnd.nokia.iptv.config+xml'},
    {key:'application/vnd.oma-scws-http-request',value:'application/vnd.oma-scws-http-request'},
    {key:'application/vnd.publishare-delta-tree',value:'application/vnd.publishare-delta-tree'},
    {key:'application/vnd.qualcomm.brew-app-res',value:'application/vnd.qualcomm.brew-app-res'},
    {key:'application/vnd.quobject-quoxdocument',value:'application/vnd.quobject-quoxdocument'},
    {key:'application/vnd.radisys.msml-conf+xml',value:'application/vnd.radisys.msml-conf+xml'},
    {key:'application/vnd.think-cell.ppttc+json',value:'application/vnd.think-cell.ppttc+json'},
    {key:'application/vnd.tmd.mediaflex.api+xml',value:'application/vnd.tmd.mediaflex.api+xml'},
    {key:'application/vnd.uplanet.bearer-choice',value:'application/vnd.uplanet.bearer-choice'},
    {key:'application/vnd.uplanet.cacheop-wbxml',value:'application/vnd.uplanet.cacheop-wbxml'},
    {key:'application/vnd.uplanet.channel-wbxml',value:'application/vnd.uplanet.channel-wbxml'},
    {key:'application/vnd.uplanet.listcmd-wbxml',value:'application/vnd.uplanet.listcmd-wbxml'},
    {key:'application/vnd.valve.source.material',value:'application/vnd.valve.source.material'},
    {key:'application/vnd.vidsoft.vidconference',value:'application/vnd.vidsoft.vidconference'},
    {key:'application/vnd.windows.devicepairing',value:'application/vnd.windows.devicepairing'},
    {key:'application/alto-networkmapfilter+json', value:'application/alto-networkmapfilter+json'},
    {key:'application/atsc-dynamic-event-message', value:'application/atsc-dynamic-event-message'},
    {key:'application/EmergencyCallData.VEDS+xml', value:'application/EmergencyCallData.VEDS+xml'},
    {key:'application/mbms-register-response+xml', value:'application/mbms-register-response+xml'},
    {key:'application/vnd.3gpp.interworking-data', value:'application/vnd.3gpp.interworking-data'},
    {key:'application/vnd.3gpp.mc-signalling-ear', value:'application/vnd.3gpp.mc-signalling-ear'},
    {key:'application/vnd.3gpp.mcdata-signalling', value:'application/vnd.3gpp.mcdata-signalling'},
    {key:'application/vnd.afpc.modca-pagesegment', value:'application/vnd.afpc.modca-pagesegment'},
    {key:'application/vnd.crick.clicker.keyboard', value:'application/vnd.crick.clicker.keyboard'},
    {key:'application/vnd.crick.clicker.template', value:'application/vnd.crick.clicker.template'},
    {key:'application/vnd.crick.clicker.wordbank', value:'application/vnd.crick.clicker.wordbank'},
    {key:'application/vnd.denovo.fcselayout-link', value:'application/vnd.denovo.fcselayout-link'},
    {key:'application/vnd.dvb.ipdcdftnotifaccess', value:'application/vnd.dvb.ipdcdftnotifaccess'},
    {key:'application/vnd.etsi.iptvdiscovery+xml', value:'application/vnd.etsi.iptvdiscovery+xml'},
    {key:'application/vnd.etsi.iptvueprofile+xml', value:'application/vnd.etsi.iptvueprofile+xml'},
    {key:'application/vnd.ims.lis.v2.result+json', value:'application/vnd.ims.lis.v2.result+json'},
    {key:'application/vnd.marlin.drm.license+xml', value:'application/vnd.marlin.drm.license+xml'},
    {key:'application/vnd.motorola.flexsuite.fis', value:'application/vnd.motorola.flexsuite.fis'},
    {key:'application/vnd.motorola.flexsuite.kmr', value:'application/vnd.motorola.flexsuite.kmr'},
    {key:'application/vnd.motorola.flexsuite.ttc', value:'application/vnd.motorola.flexsuite.ttc'},
    {key:'application/vnd.motorola.flexsuite.wem', value:'application/vnd.motorola.flexsuite.wem'},
    {key:'application/vnd.oma.cab-user-prefs+xml', value:'application/vnd.oma.cab-user-prefs+xml'},
    {key:'application/vnd.oma.scidm.messages+xml', value:'application/vnd.oma.scidm.messages+xml'},
    {key:'application/vnd.oma.xcap-directory+xml', value:'application/vnd.oma.xcap-directory+xml'},
    {key:'application/vnd.oma-scws-http-response', value:'application/vnd.oma-scws-http-response'},
    {key:'application/vnd.openstreetmap.data+xml', value:'application/vnd.openstreetmap.data+xml'},
    {key:'application/vnd.osgeo.mapguide.package', value:'application/vnd.osgeo.mapguide.package'},
    {key:'application/vnd.radisys.msml-audit+xml', value:'application/vnd.radisys.msml-audit+xml'},
    {key:'application/vnd.recordare.musicxml+xml', value:'application/vnd.recordare.musicxml+xml'},
    {key:'application/vnd.shana.informed.package', value:'application/vnd.shana.informed.package'},
    {key:'application/vnd.syncml.dm.notification', value:'application/vnd.syncml.dm.notification'},
    {key:'application/vnd.syncml.ds.notification', value:'application/vnd.syncml.ds.notification'},
    {key:'application/vnd.yamaha.openscoreformat', value:'application/vnd.yamaha.openscoreformat'},
    {key:'application/vnd.yamaha.tunnel-udpencap', value:'application/vnd.yamaha.tunnel-udpencap'},
    {key:'application/xml-external-parsed-entity', value:'application/xml-external-parsed-entity'},
    {key:'application/EmergencyCallData.eCall.MSD', value:'application/EmergencyCallData.eCall.MSD'},
    {key:'application/prs.alvestrand.titrax-sheet', value:'application/prs.alvestrand.titrax-sheet'},
    {key:'application/set-registration-initiation', value:'application/set-registration-initiation'},
    {key:'application/vnd.3gpp.mcdata-regroup+xml', value:'application/vnd.3gpp.mcdata-regroup+xml'},
    {key:'application/vnd.adobe.formscentral.fcdt', value:'application/vnd.adobe.formscentral.fcdt'},
    {key:'application/vnd.blink-idb-value-wrapper', value:'application/vnd.blink-idb-value-wrapper'},
    {key:'application/vnd.dvb.notif-container+xml', value:'application/vnd.dvb.notif-container+xml'},
    {key:'application/vnd.easykaraoke.cdgdownload', value:'application/vnd.easykaraoke.cdgdownload'},
    {key:'application/vnd.familysearch.gedcom+zip', value:'application/vnd.familysearch.gedcom+zip'},
    {key:'application/vnd.gnu.taler.exchange+json', value:'application/vnd.gnu.taler.exchange+json'},
    {key:'application/vnd.gnu.taler.merchant+json', value:'application/vnd.gnu.taler.merchant+json'},
    {key:'application/vnd.groove-identity-message', value:'application/vnd.groove-identity-message'},
    {key:'application/vnd.informedcontrol.rms+xml', value:'application/vnd.informedcontrol.rms+xml'},
    {key:'application/vnd.iptc.g2.catalogitem+xml', value:'application/vnd.iptc.g2.catalogitem+xml'},
    {key:'application/vnd.iptc.g2.conceptitem+xml', value:'application/vnd.iptc.g2.conceptitem+xml'},
    {key:'application/vnd.iptc.g2.newsmessage+xml', value:'application/vnd.iptc.g2.newsmessage+xml'},
    {key:'application/vnd.iptc.g2.packageitem+xml', value:'application/vnd.iptc.g2.packageitem+xml'},
    {key:'application/vnd.irepository.package+xml', value:'application/vnd.irepository.package+xml'},
    {key:'application/vnd.japannet-payment-wakeup', value:'application/vnd.japannet-payment-wakeup'},
    {key:'application/vnd.motorola.flexsuite.adsi', value:'application/vnd.motorola.flexsuite.adsi'},
    {key:'application/vnd.ms-wmdrm.meter-chlg-req', value:'application/vnd.ms-wmdrm.meter-chlg-req'},
    {key:'application/vnd.ntt-local.content-share', value:'application/vnd.ntt-local.content-share'},
    {key:'application/vnd.ntt-local.file-transfer', value:'application/vnd.ntt-local.file-transfer'},
    {key:'application/vnd.ntt-local.sip-ta_remote', value:'application/vnd.ntt-local.sip-ta_remote'},
    {key:'application/vnd.oasis.opendocument.base', value:'application/vnd.oasis.opendocument.base'},
    {key:'application/vnd.oasis.opendocument.text', value:'application/vnd.oasis.opendocument.text'},
    {key:'application/vnd.oma.cab-subs-invite+xml', value:'application/vnd.oma.cab-subs-invite+xml'},
    {key:'application/vnd.radisys.msml-dialog+xml', value:'application/vnd.radisys.msml-dialog+xml'},
    {key:'application/vnd.shana.informed.formdata', value:'application/vnd.shana.informed.formdata'},
    {key:'application/vnd.yellowriver-custom-menu', value:'application/vnd.yellowriver-custom-menu'},
    {key:'application/alto-endpointpropparams+json',value:'application/alto-endpointpropparams+json'},
    {key:'application/alto-endpointcostparams+json',value:'application/alto-endpointcostparams+json'},
    {key:'application/alto-updatestreamparams+json',value:'application/alto-updatestreamparams+json'},
    {key:'application/tamp-sequence-adjust-confirm',value:'application/tamp-sequence-adjust-confirm'},
    {key:'application/vnd.3gpp.mcptt-ue-config+xml',value:'application/vnd.3gpp.mcptt-ue-config+xml'},
    {key:'application/vnd.afpc.afplinedata-pagedef',value:'application/vnd.afpc.afplinedata-pagedef'},
    {key:'application/vnd.citationstyles.style+xml',value:'application/vnd.citationstyles.style+xml'},
    {key:'application/vnd.d2l.coursepackage1p0+zip',value:'application/vnd.d2l.coursepackage1p0+zip'},
    {key:'application/vnd.dir-bi.plate-dl-nosuffix',value:'application/vnd.dir-bi.plate-dl-nosuffix'},
    {key:'application/vnd.dvb.notif-ia-msglist+xml',value:'application/vnd.dvb.notif-ia-msglist+xml'},
    {key:'application/vnd.iptc.g2.planningitem+xml',value:'application/vnd.iptc.g2.planningitem+xml'},
    {key:'application/vnd.japannet-jpnstore-wakeup',value:'application/vnd.japannet-jpnstore-wakeup'},
    {key:'application/vnd.japannet-setstore-wakeup',value:'application/vnd.japannet-setstore-wakeup'},
    {key:'application/vnd.marlin.drm.conftoken+xml',value:'application/vnd.marlin.drm.conftoken+xml'},
    {key:'application/vnd.motorola.flexsuite.gotap',value:'application/vnd.motorola.flexsuite.gotap'},
    {key:'application/vnd.ms-PrintSchemaTicket+xml',value:'application/vnd.ms-PrintSchemaTicket+xml'},
    {key:'application/vnd.ms-windows.devicepairing',value:'application/vnd.ms-windows.devicepairing'},
    {key:'application/vnd.nokia.iSDS-radio-presets',value:'application/vnd.nokia.iSDS-radio-presets'},
    {key:'application/vnd.oasis.opendocument.chart',value:'application/vnd.oasis.opendocument.chart'},
    {key:'application/vnd.oasis.opendocument.image',value:'application/vnd.oasis.opendocument.image'},
    {key:'application/vnd.oma.cab-address-book+xml',value:'application/vnd.oma.cab-address-book+xml'},
    {key:'application/vnd.oma.group-usage-list+xml',value:'application/vnd.oma.group-usage-list+xml'},
    {key:'application/vnd.oma.poc.final-report+xml',value:'application/vnd.oma.poc.final-report+xml'},
    {key:'application/vnd.sealedmedia.softseal.pdf',value:'application/vnd.sealedmedia.softseal.pdf'},
    {key:'application/alto-updatestreamcontrol+json', value:'application/alto-updatestreamcontrol+json'},
    {key:'application/EmergencyCallData.Comment+xml', value:'application/EmergencyCallData.Comment+xml'},
    {key:'application/EmergencyCallData.Control+xml', value:'application/EmergencyCallData.Control+xml'},
    {key:'application/tamp-community-update-confirm', value:'application/tamp-community-update-confirm'},
    {key:'application/vnd.3gpp.mcdata-ue-config+xml', value:'application/vnd.3gpp.mcdata-ue-config+xml'},
    {key:'application/vnd.3lightssoftware.imagescal', value:'application/vnd.3lightssoftware.imagescal'},
    {key:'application/vnd.ims.lti.v2.toolproxy+json', value:'application/vnd.ims.lti.v2.toolproxy+json'},
    {key:'application/vnd.iptc.g2.knowledgeitem+xml', value:'application/vnd.iptc.g2.knowledgeitem+xml'},
    {key:'application/vnd.ms-windows.nwprinting.oob', value:'application/vnd.ms-windows.nwprinting.oob'},
    {key:'application/vnd.ms-windows.printerpairing', value:'application/vnd.ms-windows.printerpairing'},
    {key:'application/vnd.oma.bcast.drm-trigger+xml', value:'application/vnd.oma.bcast.drm-trigger+xml'},
    {key:'application/vnd.sealedmedia.softseal.html', value:'application/vnd.sealedmedia.softseal.html'},
    {key:'application/vnd.tao.intent-module-archive', value:'application/vnd.tao.intent-module-archive'},
    {key:'application/xcon-conference-info-diff+xml', value:'application/xcon-conference-info-diff+xml'},
    {key:'application/sarif-external-properties+json',value:'application/sarif-external-properties+json'},
    {key:'application/vnd.3gpp.mcvideo-ue-config+xml',value:'application/vnd.3gpp.mcvideo-ue-config+xml'},
    {key:'application/vnd.afpc.modca-objectcontainer',value:'application/vnd.afpc.modca-objectcontainer'},
    {key:'application/vnd.cendio.thinlinc.clientconf',value:'application/vnd.cendio.thinlinc.clientconf'},
    {key:'application/vnd.century-systems.tcp_stream',value:'application/vnd.century-systems.tcp_stream'},
    {key:'application/vnd.dvb.iptv.alfec-enhancement',value:'application/vnd.dvb.iptv.alfec-enhancement'},
    {key:'application/vnd.emclient.accessrequest+xml',value:'application/vnd.emclient.accessrequest+xml'},
    {key:'application/vnd.fujixerox.docuworks.binder',value:'application/vnd.fujixerox.docuworks.binder'},
    {key:'application/vnd.HandHeld-Entertainment+xml',value:'application/vnd.HandHeld-Entertainment+xml'},
    {key:'application/vnd.innopath.wamp.notification',value:'application/vnd.innopath.wamp.notification'},
    {key:'application/vnd.japannet-directory-service',value:'application/vnd.japannet-directory-service'},
    {key:'application/vnd.marlin.drm.actiontoken+xml',value:'application/vnd.marlin.drm.actiontoken+xml'},
    {key:'application/vnd.ms-playready.initiator+xml',value:'application/vnd.ms-playready.initiator+xml'},
    {key:'application/vnd.oasis.opendocument.formula',value:'application/vnd.oasis.opendocument.formula'},
    {key:'application/vnd.oci.image.manifest.v1+json',value:'application/vnd.oci.image.manifest.v1+json'},
    {key:'application/vnd.oma.bcast.notification+xml',value:'application/vnd.oma.bcast.notification+xml'},
    {key:'application/vnd.shana.informed.interchange',value:'application/vnd.shana.informed.interchange'},
    {key:'application/mbms-protection-description+xml',value:'application/mbms-protection-description+xml'},
    {key:'application/vnd.3gpp.mcptt-user-profile+xml',value:'application/vnd.3gpp.mcptt-user-profile+xml'},
    {key:'application/vnd.gov.sk.xmldatacontainer+xml',value:'application/vnd.gov.sk.xmldatacontainer+xml'},
    {key:'application/vnd.ntt-local.ogw_remote-access',value:'application/vnd.ntt-local.ogw_remote-access'},
    {key:'application/vnd.ntt-local.sip-ta_tcp_stream',value:'application/vnd.ntt-local.sip-ta_tcp_stream'},
    {key:'application/vnd.oasis.opendocument.database',value:'application/vnd.oasis.opendocument.database'},
    {key:'application/vnd.oasis.opendocument.graphics',value:'application/vnd.oasis.opendocument.graphics'},
    {key:'application/vnd.oasis.opendocument.text-web',value:'application/vnd.oasis.opendocument.text-web'},
    {key:'application/vnd.oma.cab-feature-handler+xml',value:'application/vnd.oma.cab-feature-handler+xml'},
    {key:'application/vnd.poc.group-advertisement+xml',value:'application/vnd.poc.group-advertisement+xml'},
    {key:'application/vnd.radisys.msml-audit-conf+xml',value:'application/vnd.radisys.msml-audit-conf+xml'},
    {key:'application/vnd.radisys.msml-audit-conn+xml',value:'application/vnd.radisys.msml-audit-conn+xml'},
    {key:'application/vnd.shana.informed.formtemplate',value:'application/vnd.shana.informed.formtemplate'},
    {key:'application/vnd.software602.filler.form+xml',value:'application/vnd.software602.filler.form+xml'},
    {key:'application/vnd.uplanet.bearer-choice-wbxml',value:'application/vnd.uplanet.bearer-choice-wbxml'},
    {key:'application/vnd.wolfram.mathematica.package',value:'application/vnd.wolfram.mathematica.package'},
    {key:'application/EmergencyCallData.DeviceInfo+xml', value:'application/EmergencyCallData.DeviceInfo+xml'},
    {key:'application/vnd.1000minds.decision-model+xml', value:'application/vnd.1000minds.decision-model+xml'},
    {key:'application/vnd.3gpp.mcdata-user-profile+xml', value:'application/vnd.3gpp.mcdata-user-profile+xml'},
    {key:'application/vnd.3gpp.mcptt-floor-request+xml', value:'application/vnd.3gpp.mcptt-floor-request+xml'},
    {key:'application/vnd.3gpp.mcptt-location-info+xml', value:'application/vnd.3gpp.mcptt-location-info+xml'},
    {key:'application/vnd.apothekende.reservation+json', value:'application/vnd.apothekende.reservation+json'},
    {key:'application/vnd.cluetrust.cartomobile-config', value:'application/vnd.cluetrust.cartomobile-config'},
    {key:'application/vnd.dvb.notif-aggregate-root+xml', value:'application/vnd.dvb.notif-aggregate-root+xml'},
    {key:'application/vnd.fujifilm.fb.docuworks.binder', value:'application/vnd.fujifilm.fb.docuworks.binder'},
    {key:'application/vnd.ims.lti.v2.toolproxy.id+json', value:'application/vnd.ims.lti.v2.toolproxy.id+json'},
    {key:'application/vnd.ims.lti.v2.toolsettings+json', value:'application/vnd.ims.lti.v2.toolsettings+json'},
    {key:'application/vnd.japannet-registration-wakeup', value:'application/vnd.japannet-registration-wakeup'},
    {key:'application/vnd.japannet-verification-wakeup', value:'application/vnd.japannet-verification-wakeup'},
    {key:'application/vnd.nokia.landmarkcollection+xml', value:'application/vnd.nokia.landmarkcollection+xml'},
    {key:'application/vnd.nokia.n-gage.symbian.install', value:'application/vnd.nokia.n-gage.symbian.install'},
    {key:'application/vnd.oipf.mippvcontrolmessage+xml', value:'application/vnd.oipf.mippvcontrolmessage+xml'},
    {key:'application/vnd.piaccess.application-licence', value:'application/vnd.piaccess.application-licence'},
    {key:'application/vnd.radisys.msml-dialog-base+xml', value:'application/vnd.radisys.msml-dialog-base+xml'},
    {key:'application/EmergencyCallData.ServiceInfo+xml', value:'application/EmergencyCallData.ServiceInfo+xml'},
    {key:'application/mbms-user-service-description+xml', value:'application/mbms-user-service-description+xml'},
    {key:'application/vnd.3gpp.mcptt-service-config+xml', value:'application/vnd.3gpp.mcptt-service-config+xml'},
    {key:'application/vnd.3gpp.mcptt-ue-init-config+xml', value:'application/vnd.3gpp.mcptt-ue-init-config+xml'},
    {key:'application/vnd.3gpp.mcvideo-user-profile+xml', value:'application/vnd.3gpp.mcvideo-user-profile+xml'},
    {key:'application/vnd.3gpp.state-and-event-info+xml', value:'application/vnd.3gpp.state-and-event-info+xml'},
    {key:'application/vnd.collabio.xodocuments.document', value:'application/vnd.collabio.xodocuments.document'},
    {key:'application/vnd.fujixerox.docuworks.container', value:'application/vnd.fujixerox.docuworks.container'},
    {key:'application/vnd.microsoft.portable-executable', value:'application/vnd.microsoft.portable-executable'},
    {key:'application/vnd.oma.bcast.provisioningtrigger', value:'application/vnd.oma.bcast.provisioningtrigger'},
    {key:'application/vnd.radisys.msml-audit-dialog+xml', value:'application/vnd.radisys.msml-audit-dialog+xml'},
    {key:'application/vnd.radisys.msml-audit-stream+xml', value:'application/vnd.radisys.msml-audit-stream+xml'},
    {key:'application/vnd.radisys.msml-dialog-group+xml', value:'application/vnd.radisys.msml-dialog-group+xml'},
    {key:'application/EmergencyCallData.ProviderInfo+xml',value:'application/EmergencyCallData.ProviderInfo+xml'},
    {key:'application/vnd.3gpp.mcdata-service-config+xml',value:'application/vnd.3gpp.mcdata-service-config+xml'},
    {key:'application/vnd.3gpp.mcptt-mbms-usage-info+xml',value:'application/vnd.3gpp.mcptt-mbms-usage-info+xml'},
    {key:'application/vnd.3gpp.mcvideo-location-info+xml',value:'application/vnd.3gpp.mcvideo-location-info+xml'},
    {key:'application/vnd.ms-excel.addin.macroEnabled.12',value:'application/vnd.ms-excel.addin.macroEnabled.12'},
    {key:'application/vnd.ms-excel.sheet.macroEnabled.12',value:'application/vnd.ms-excel.sheet.macroEnabled.12'},
    {key:'application/vnd.ms-PrintDeviceCapabilities+xml',value:'application/vnd.ms-PrintDeviceCapabilities+xml'},
    {key:'application/vnd.oasis.opendocument.spreadsheet',value:'application/vnd.oasis.opendocument.spreadsheet'},
    {key:'application/vnd.oasis.opendocument.text-master',value:'application/vnd.oasis.opendocument.text-master'},
    {key:'application/vnd.oipf.contentaccessdownload+xml',value:'application/vnd.oipf.contentaccessdownload+xml'},
    {key:'application/vnd.radisys.msml-dialog-speech+xml',value:'application/vnd.radisys.msml-dialog-speech+xml'},
    {key:'application/vnd.3gpp.access-transfer-events+xml', value:'application/vnd.3gpp.access-transfer-events+xml'},
    {key:'application/vnd.3gpp.mcvideo-service-config+xml', value:'application/vnd.3gpp.mcvideo-service-config+xml'},
    {key:'application/vnd.fujifilm.fb.docuworks.container', value:'application/vnd.fujifilm.fb.docuworks.container'},
    {key:'application/vnd.globalplatform.card-content-mgt', value:'application/vnd.globalplatform.card-content-mgt'},
    {key:'application/vnd.mitsubishi.misty-guard.trustweb', value:'application/vnd.mitsubishi.misty-guard.trustweb'},
    {key:'application/vnd.oasis.opendocument.presentation', value:'application/vnd.oasis.opendocument.presentation'},
    {key:'application/vnd.oipf.contentaccessstreaming+xml', value:'application/vnd.oipf.contentaccessstreaming+xml'},
    {key:'application/vnd.oma.bcast.smartcard-trigger+xml', value:'application/vnd.oma.bcast.smartcard-trigger+xml'},
    {key:'application/vnd.software602.filler.form-xml-zip', value:'application/vnd.software602.filler.form-xml-zip'},
    {key:'application/EmergencyCallData.SubscriberInfo+xml',value:'application/EmergencyCallData.SubscriberInfo+xml'},
    {key:'application/vnd.3gpp.mcvideo-mbms-usage-info+xml',value:'application/vnd.3gpp.mcvideo-mbms-usage-info+xml'},
    {key:'application/vnd.cluetrust.cartomobile-config-pkg',value:'application/vnd.cluetrust.cartomobile-config-pkg'},
    {key:'application/vnd.collabio.xodocuments.spreadsheet',value:'application/vnd.collabio.xodocuments.spreadsheet'},
    {key:'application/vnd.ms-word.document.macroEnabled.12',value:'application/vnd.ms-word.document.macroEnabled.12'},
    {key:'application/vnd.ms-word.template.macroEnabled.12',value:'application/vnd.ms-word.template.macroEnabled.12'},
    {key:'application/vnd.oasis.opendocument.text-template',value:'application/vnd.oasis.opendocument.text-template'},
    {key:'application/vnd.3gpp.mcvideo-affiliation-info+xml', value:'application/vnd.3gpp.mcvideo-affiliation-info+xml'},
    {key:'application/vnd.collabio.xodocuments.presentation', value:'application/vnd.collabio.xodocuments.presentation'},
    {key:'application/vnd.microsoft.windows.thumbnail-cache', value:'application/vnd.microsoft.windows.thumbnail-cache'},
    {key:'application/vnd.ms-excel.template.macroEnabled.12', value:'application/vnd.ms-excel.template.macroEnabled.12'},
    {key:'application/vnd.oasis.opendocument.chart-template', value:'application/vnd.oasis.opendocument.chart-template'},
    {key:'application/vnd.oasis.opendocument.image-template', value:'application/vnd.oasis.opendocument.image-template'},
    {key:'application/vnd.oma.bcast.simple-symbol-container', value:'application/vnd.oma.bcast.simple-symbol-container'},
    {key:'application/vnd.oma.poc.invocation-descriptor+xml', value:'application/vnd.oma.poc.invocation-descriptor+xml'},
    {key:'application/vnd.radisys.msml-dialog-transform+xml', value:'application/vnd.radisys.msml-dialog-transform+xml'},
    {key:'application/vnd.yamaha.openscoreformat.osfpvg+xml', value:'application/vnd.yamaha.openscoreformat.osfpvg+xml'},
    {key:'application/vnd.3gpp.mcptt-affiliation-command+xml', value:'application/vnd.3gpp.mcptt-affiliation-command+xml'},
    {key:'application/vnd.3gpp-v2x-local-service-information', value:'application/vnd.3gpp-v2x-local-service-information'},
    {key:'application/vnd.doremir.scorecloud-binary-document', value:'application/vnd.doremir.scorecloud-binary-document'},
    {key:'application/vnd.llamagraphics.life-balance.desktop', value:'application/vnd.llamagraphics.life-balance.desktop'},
    {key:'application/vnd.radisys.msml-dialog-fax-detect+xml', value:'application/vnd.radisys.msml-dialog-fax-detect+xml'},
    {key:'application/vnd.3gpp.mcdata-affiliation-command+xml', value:'application/vnd.3gpp.mcdata-affiliation-command+xml'},
    {key:'application/vnd.ims.lti.v2.toolconsumerprofile+json', value:'application/vnd.ims.lti.v2.toolconsumerprofile+json'},
    {key:'application/vnd.ims.lti.v2.toolsettings.simple+json', value:'application/vnd.ims.lti.v2.toolsettings.simple+json'},
    {key:'application/vnd.ms-powerpoint.addin.macroEnabled.12', value:'application/vnd.ms-powerpoint.addin.macroEnabled.12'},
    {key:'application/vnd.ms-powerpoint.slide.macroEnabled.12', value:'application/vnd.ms-powerpoint.slide.macroEnabled.12'},
    {key:'application/vnd.oasis.opendocument.formula-template', value:'application/vnd.oasis.opendocument.formula-template'},
    {key:'application/vnd.3gpp.mcvideo-affiliation-command+xml', value:'application/vnd.3gpp.mcvideo-affiliation-command+xml'},
    {key:'application/vnd.oasis.opendocument.graphics-template', value:'application/vnd.oasis.opendocument.graphics-template'},
    {key:'application/vnd.oma.poc.detailed-progress-report+xml', value:'application/vnd.oma.poc.detailed-progress-report+xml'},
    {key:'application/vnd.radisys.msml-dialog-fax-sendrecv+xml', value:'application/vnd.radisys.msml-dialog-fax-sendrecv+xml'},
    {key:'application/mbms-associated-procedure-description+xml', value:'application/mbms-associated-procedure-description+xml'},
    {key:'application/vnd.3gpp.mcdata-msgstore-ctrl-request+xml', value:'application/vnd.3gpp.mcdata-msgstore-ctrl-request+xml'},
    {key:'application/vnd.3gpp.mcvideo-transmission-request+xml', value:'application/vnd.3gpp.mcvideo-transmission-request+xml'},
    {key:'application/vnd.dvb.notif-ia-registration-request+xml', value:'application/vnd.dvb.notif-ia-registration-request+xml'},
    {key:'application/vnd.ms-excel.sheet.binary.macroEnabled.12', value:'application/vnd.ms-excel.sheet.binary.macroEnabled.12'},
    {key:'application/vnd.oma.poc.optimized-progress-report+xml', value:'application/vnd.oma.poc.optimized-progress-report+xml'},
    {key:'application/vnd.anser-web-certificate-issue-initiation', value:'application/vnd.anser-web-certificate-issue-initiation'},
    {key:'application/vnd.collabio.xodocuments.document-template', value:'application/vnd.collabio.xodocuments.document-template'},
    {key:'application/vnd.dvb.notif-ia-registration-response+xml', value:'application/vnd.dvb.notif-ia-registration-response+xml'},
    {key:'application/vnd.ms-powerpoint.template.macroEnabled.12', value:'application/vnd.ms-powerpoint.template.macroEnabled.12'},
    {key:'application/vnd.llamagraphics.life-balance.exchange+xml', value:'application/vnd.llamagraphics.life-balance.exchange+xml'},
    {key:'application/vnd.ms-powerpoint.slideshow.macroEnabled.12', value:'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'},
    {key:'application/vnd.oasis.opendocument.spreadsheet-template', value:'application/vnd.oasis.opendocument.spreadsheet-template'},
    {key:'application/vnd.openxmlformats-officedocument.theme+xml', value:'application/vnd.openxmlformats-officedocument.theme+xml'},
    {key:'application/vnd.etsi.overload-control-policy-dataset+xml', value:'application/vnd.etsi.overload-control-policy-dataset+xml'},
    {key:'application/vnd.globalplatform.card-content-mgt-response', value:'application/vnd.globalplatform.card-content-mgt-response'},
    {key:'application/vnd.oasis.opendocument.presentation-template', value:'application/vnd.oasis.opendocument.presentation-template'},
    {key:'application/vnd.openxmlformats-officedocument.vmlDrawing', value:'application/vnd.openxmlformats-officedocument.vmlDrawing'},
    {key:'application/vnd.openxmlformats-package.relationships+xml', value:'application/vnd.openxmlformats-package.relationships+xml'},
    {key:'application/vnd.collabio.xodocuments.spreadsheet-template', value:'application/vnd.collabio.xodocuments.spreadsheet-template'},
    {key:'application/vnd.openxmlformats-officedocument.drawing+xml', value:'application/vnd.openxmlformats-officedocument.drawing+xml'},
    {key:'application/vnd.collabio.xodocuments.presentation-template', value:'application/vnd.collabio.xodocuments.presentation-template'},
    {key:'application/vnd.ms-powerpoint.presentation.macroEnabled.12', value:'application/vnd.ms-powerpoint.presentation.macroEnabled.12'},
    {key:'application/vnd.openxmlformats-package.core-properties+xml', value:'application/vnd.openxmlformats-package.core-properties+xml'},
    {key:'application/vnd.oma.bcast.associated-procedure-parameter+xml', value:'application/vnd.oma.bcast.associated-procedure-parameter+xml'},
    {key:'application/vnd.openxmlformats-officedocument.themeOverride+xml', value:'application/vnd.openxmlformats-officedocument.themeOverride+xml'},
    {key:'application/vnd.openxmlformats-officedocument.drawingml.chart+xml', value:'application/vnd.openxmlformats-officedocument.drawingml.chart+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.slide', value:'application/vnd.openxmlformats-officedocument.presentationml.slide'},
    {key:'application/vnd.openxmlformats-officedocument.custom-properties+xml', value:'application/vnd.openxmlformats-officedocument.custom-properties+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.template', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.template'},
    {key:'application/vnd.openxmlformats-officedocument.customXmlProperties+xml', value:'application/vnd.openxmlformats-officedocument.customXmlProperties+xml'},
    {key:'application/vnd.openxmlformats-officedocument.extended-properties+xml', value:'application/vnd.openxmlformats-officedocument.extended-properties+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.tags+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.tags+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.template', value:'application/vnd.openxmlformats-officedocument.presentationml.template'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.table+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.table+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.slide+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.slide+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.slideshow', value:'application/vnd.openxmlformats-officedocument.presentationml.slideshow'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml'},
    {key:'application/vnd.openxmlformats-officedocument.drawingml.chartshapes+xml', value:'application/vnd.openxmlformats-officedocument.drawingml.chartshapes+xml'},
    {key:'application/vnd.openxmlformats-officedocument.drawingml.diagramData+xml', value:'application/vnd.openxmlformats-officedocument.drawingml.diagramData+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.document', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.template', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.template'},
    {key:'application/vnd.openxmlformats-officedocument.drawingml.diagramStyle+xml', value:'application/vnd.openxmlformats-officedocument.drawingml.diagramStyle+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.comments+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.comments+xml'},
    {key:'application/vnd.openxmlformats-officedocument.drawingml.diagramColors+xml', value:'application/vnd.openxmlformats-officedocument.drawingml.diagramColors+xml'},
    {key:'application/vnd.openxmlformats-officedocument.drawingml.diagramLayout+xml', value:'application/vnd.openxmlformats-officedocument.drawingml.diagramLayout+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.comments+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.comments+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.presentation', value:'application/vnd.openxmlformats-officedocument.presentationml.presentation'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.calcChain+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.calcChain+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.userNames+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.userNames+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.footer+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.footer+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.styles+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.styles+xml'},
    {key:'application/vnd.openxmlformats-package.digital-signature-xmlsignature+xml', value:'application/vnd.openxmlformats-package.digital-signature-xmlsignature+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.presProps+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.presProps+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.viewProps+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.viewProps+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.chartsheet+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.chartsheet+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotTable+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotTable+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.queryTable+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.queryTable+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.notesSlide+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.notesSlide+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.connections+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.connections+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.dialogsheet+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.dialogsheet+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.revisionLog+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.revisionLog+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.comments+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.comments+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.endnotes+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.endnotes+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.settings+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.settings+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.notesMaster+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.notesMaster+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.slideLayout+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.slideLayout+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.slideMaster+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.slideMaster+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.tableStyles+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.tableStyles+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.externalLink+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.externalLink+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.fontTable+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.fontTable+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.footnotes+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.footnotes+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.numbering+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.numbering+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.sharedStrings+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.sharedStrings+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetMetadata+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetMetadata+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.template.main+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.template.main+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.handoutMaster+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.handoutMaster+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.template.main+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.template.main+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.webSettings+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.webSettings+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.commentAuthors+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.commentAuthors+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.slideshow.main+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.slideshow.main+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.revisionHeaders+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.revisionHeaders+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.slideUpdateInfo+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.slideUpdateInfo+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.tableSingleCells+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.tableSingleCells+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.template.main+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.template.main+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotCacheRecords+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotCacheRecords+xml'},
    {key:'application/vnd.openxmlformats-officedocument.presentationml.presentation.main+xml', value:'application/vnd.openxmlformats-officedocument.presentationml.presentation.main+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotCacheDefinition+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.pivotCacheDefinition+xml'},
    {key:'application/vnd.openxmlformats-officedocument.spreadsheetml.volatileDependencies+xml', value:'application/vnd.openxmlformats-officedocument.spreadsheetml.volatileDependencies+xml'},
    {key:'application/vnd.openxmlformats-officedocument.wordprocessingml.document.glossary+xml', value:'application/vnd.openxmlformats-officedocument.wordprocessingml.document.glossary+xml'},
    {key:'audio/CN', value:'audio/CN'},
    {key:'audio/DV', value:'audio/DV'},
    {key:'audio/L8', value:'audio/L8'},
    {key:'audio/aac', value:'audio/aac'},
    {key:'audio/ac3', value:'audio/ac3'},
    {key:'audio/AMR', value:'audio/AMR'},
    {key:'audio/asc', value:'audio/asc'},
    {key:'audio/dls', value:'audio/dls'},
    {key:'audio/EVS', value:'audio/EVS'},
    {key:'audio/GSM', value:'audio/GSM'},
    {key:'audio/L16', value:'audio/L16'},
    {key:'audio/L20', value:'audio/L20'},
    {key:'audio/L24', value:'audio/L24'},
    {key:'audio/LPC', value:'audio/LPC'},
    {key:'audio/MPA', value:'audio/MPA'},
    {key:'audio/mp4', value:'audio/mp4'},
    {key:'audio/ogg', value:'audio/ogg'},
    {key:'audio/RED', value:'audio/RED'},
    {key:'audio/rtx', value:'audio/rtx'},
    {key:'audio/SMV', value:'audio/SMV'},
    {key:'audio/t38', value:'audio/t38'},
    {key:'audio/3gpp', value:'audio/3gpp'},
    {key:'audio/aptx', value:'audio/aptx'},
    {key:'audio/BV16', value:'audio/BV16'},
    {key:'audio/BV32', value:'audio/BV32'},
    {key:'audio/DVI4', value:'audio/DVI4'},
    {key:'audio/eac3', value:'audio/eac3'},
    {key:'audio/EVRC', value:'audio/EVRC'},
    {key:'audio/G719', value:'audio/G719'},
    {key:'audio/G722', value:'audio/G722'},
    {key:'audio/G723', value:'audio/G723'},
    {key:'audio/G728', value:'audio/G728'},
    {key:'audio/G729', value:'audio/G729'},
    {key:'audio/iLBC', value:'audio/iLBC'},
    {key:'audio/MELP', value:'audio/MELP'},
    {key:'audio/mhas', value:'audio/mhas'},
    {key:'audio/mpeg', value:'audio/mpeg'},
    {key:'audio/opus', value:'audio/opus'},
    {key:'audio/PCMA', value:'audio/PCMA'},
    {key:'audio/PCMU', value:'audio/PCMU'},
    {key:'audio/scip', value:'audio/scip'},
    {key:'audio/SMV0', value:'audio/SMV0'},
    {key:'audio/sofa', value:'audio/sofa'},
    {key:'audio/tone', value:'audio/tone'},
    {key:'audio/usac', value:'audio/usac'},
    {key:'audio/VDVI', value:'audio/VDVI'},
    {key:'audio/3gpp2', value:'audio/3gpp2'},
    {key:'audio/basic', value:'audio/basic'},
    {key:'audio/DAT12', value:'audio/DAT12'},
    {key:'audio/EVRC0', value:'audio/EVRC0'},
    {key:'audio/EVRC1', value:'audio/EVRC1'},
    {key:'audio/EVRCB', value:'audio/EVRCB'},
    {key:'audio/G7221', value:'audio/G7221'},
    {key:'audio/G7291', value:'audio/G7291'},
    {key:'audio/G729D', value:'audio/G729D'},
    {key:'audio/G729E', value:'audio/G729E'},
    {key:'audio/QCELP', value:'audio/QCELP'},
    {key:'audio/speex', value:'audio/speex'},
    {key:'audio/t140c', value:'audio/t140c'},
    {key:'audio/AMR-WB', value:'audio/AMR-WB'},
    {key:'audio/ATRAC3', value:'audio/ATRAC3'},
    {key:'audio/EVRCB0', value:'audio/EVRCB0'},
    {key:'audio/EVRCB1', value:'audio/EVRCB1'},
    {key:'audio/EVRCNW', value:'audio/EVRCNW'},
    {key:'audio/EVRCWB', value:'audio/EVRCWB'},
    {key:'audio/fwdred', value:'audio/fwdred'},
    {key:'audio/G711-0', value:'audio/G711-0'},
    {key:'audio/TSVCIS', value:'audio/TSVCIS'},
    {key:'audio/ulpfec', value:'audio/ulpfec'},
    {key:'audio/VMR-WB', value:'audio/VMR-WB'},
    {key:'audio/vorbis', value:'audio/vorbis'},
    {key:'audio/amr-wb+', value:'audio/amr-wb+'},
    {key:'audio/ATRAC-X', value:'audio/ATRAC-X'},
    {key:'audio/EVRCNW0', value:'audio/EVRCNW0'},
    {key:'audio/EVRCNW1', value:'audio/EVRCNW1'},
    {key:'audio/EVRCWB0', value:'audio/EVRCWB0'},
    {key:'audio/EVRCWB1', value:'audio/EVRCWB1'},
    {key:'audio/example', value:'audio/example'},
    {key:'audio/flexfec', value:'audio/flexfec'},
    {key:'audio/G726-16', value:'audio/G726-16'},
    {key:'audio/G726-24', value:'audio/G726-24'},
    {key:'audio/G726-32', value:'audio/G726-32'},
    {key:'audio/G726-40', value:'audio/G726-40'},
    {key:'audio/GSM-EFR', value:'audio/GSM-EFR'},
    {key:'audio/MELP600', value:'audio/MELP600'},
    {key:'audio/PCMA-WB', value:'audio/PCMA-WB'},
    {key:'audio/PCMU-WB', value:'audio/PCMU-WB'},
    {key:'audio/prs.sid', value:'audio/prs.sid'},
    {key:'audio/SMV-QCP', value:'audio/SMV-QCP'},
    {key:'audio/sp-midi', value:'audio/sp-midi'},
    {key:'audio/UEMCLIP', value:'audio/UEMCLIP'},
    {key:'audio/vnd.4SB', value:'audio/vnd.4SB'},
    {key:'audio/vnd.dra', value:'audio/vnd.dra'},
    {key:'audio/vnd.dts', value:'audio/vnd.dts'},
    {key:'audio/vnd.rip', value:'audio/vnd.rip'},
    {key:'audio/32kadpcm', value:'audio/32kadpcm'},
    {key:'audio/encaprtp', value:'audio/encaprtp'},
    {key:'audio/EVRC-QCP', value:'audio/EVRC-QCP'},
    {key:'audio/MELP1200', value:'audio/MELP1200'},
    {key:'audio/MELP2400', value:'audio/MELP2400'},
    {key:'audio/rtp-midi', value:'audio/rtp-midi'},
    {key:'audio/vnd.CELP', value:'audio/vnd.CELP'},
    {key:'audio/clearmode', value:'audio/clearmode'},
    {key:'audio/GSM-HR-08', value:'audio/GSM-HR-08'},
    {key:'audio/MP4A-LATM', value:'audio/MP4A-LATM'},
    {key:'audio/parityfec', value:'audio/parityfec'},
    {key:'audio/raptorfec', value:'audio/raptorfec'},
    {key:'audio/vnd.qcelp', value:'audio/vnd.qcelp'},
    {key:'audio/ip-mr_v2.5', value:'audio/ip-mr_v2.5'},
    {key:'audio/mobile-xmf', value:'audio/mobile-xmf'},
    {key:'audio/mpa-robust', value:'audio/mpa-robust'},
    {key:'audio/vnd.dts.hd', value:'audio/vnd.dts.hd'},
    {key:'audio/rtploopback', value:'audio/rtploopback'},
    {key:'audio/TETRA_ACELP', value:'audio/TETRA_ACELP'},
    {key:'audio/vnd.dts.uhd', value:'audio/vnd.dts.uhd'},
    {key:'audio/dsr-es201108', value:'audio/dsr-es201108'},
    {key:'audio/dsr-es202050', value:'audio/dsr-es202050'},
    {key:'audio/dsr-es202211', value:'audio/dsr-es202211'},
    {key:'audio/dsr-es202212', value:'audio/dsr-es202212'},
    {key:'audio/vnd.audiokoz', value:'audio/vnd.audiokoz'},
    {key:'audio/vnd.cns.anp1', value:'audio/vnd.cns.anp1'},
    {key:'audio/vnd.cns.inf1', value:'audio/vnd.cns.inf1'},
    {key:'audio/vnd.dvb.file', value:'audio/vnd.dvb.file'},
    {key:'audio/vnd.vmx.cvsd', value:'audio/vnd.vmx.cvsd'},
    {key:'audio/mpeg4-generic', value:'audio/mpeg4-generic'},
    {key:'audio/vnd.3gpp.iufp', value:'audio/vnd.3gpp.iufp'},
    {key:'audio/vnd.cisco.nse', value:'audio/vnd.cisco.nse'},
    {key:'audio/vnd.dlna.adts', value:'audio/vnd.dlna.adts'},
    {key:'audio/vnd.dolby.mlp', value:'audio/vnd.dolby.mlp'},
    {key:'audio/vnd.dolby.mps', value:'audio/vnd.dolby.mps'},
    {key:'audio/vnd.dolby.pl2', value:'audio/vnd.dolby.pl2'},
    {key:'audio/vnd.hns.audio', value:'audio/vnd.hns.audio'},
    {key:'audio/vnd.octel.sbc', value:'audio/vnd.octel.sbc'},
    {key:'audio/vorbis-config', value:'audio/vorbis-config'},
    {key:'audio/TETRA_ACELP_BB', value:'audio/TETRA_ACELP_BB'},
    {key:'audio/vnd.dece.audio', value:'audio/vnd.dece.audio'},
    {key:'audio/vnd.dolby.pl2x', value:'audio/vnd.dolby.pl2x'},
    {key:'audio/vnd.dolby.pl2z', value:'audio/vnd.dolby.pl2z'},
    {key:'audio/vnd.everad.plj', value:'audio/vnd.everad.plj'},
    {key:'audio/vnd.nortel.vbk', value:'audio/vnd.nortel.vbk'},
    {key:'audio/telephone-event', value:'audio/telephone-event'},
    {key:'audio/rtp-enc-aescm128', value:'audio/rtp-enc-aescm128'},
    {key:'audio/vnd.lucent.voice', value:'audio/vnd.lucent.voice'},
    {key:'audio/vnd.digital-winds', value:'audio/vnd.digital-winds'},
    {key:'audio/vnd.dolby.heaac.1', value:'audio/vnd.dolby.heaac.1'},
    {key:'audio/vnd.dolby.heaac.2', value:'audio/vnd.dolby.heaac.2'},
    {key:'audio/vnd.dolby.pulse.1', value:'audio/vnd.dolby.pulse.1'},
    {key:'audio/vnd.nuera.ecelp4800', value:'audio/vnd.nuera.ecelp4800'},
    {key:'audio/vnd.nuera.ecelp7470', value:'audio/vnd.nuera.ecelp7470'},
    {key:'audio/vnd.nuera.ecelp9600', value:'audio/vnd.nuera.ecelp9600'},
    {key:'audio/vnd.nokia.mobile-xmf', value:'audio/vnd.nokia.mobile-xmf'},
    {key:'audio/vnd.rhetorex.32kadpcm', value:'audio/vnd.rhetorex.32kadpcm'},
    {key:'audio/vnd.cmles.radio-events', value:'audio/vnd.cmles.radio-events'},
    {key:'audio/ATRAC-ADVANCED-LOSSLESS', value:'audio/ATRAC-ADVANCED-LOSSLESS'},
    {key:'audio/vnd.presonus.multitrack', value:'audio/vnd.presonus.multitrack'},
    {key:'audio/1d-interleaved-parityfec', value:'audio/1d-interleaved-parityfec'},
    {key:'audio/vnd.ms-playready.media.pya', value:'audio/vnd.ms-playready.media.pya'},
    {key:'audio/vnd.sealedmedia.softseal.mpeg', value:'audio/vnd.sealedmedia.softseal.mpeg'},
    {key:'font/otf', value:'font/otf'},
    {key:'font/ttf', value:'font/ttf'},
    {key:'font/sfnt', value: 'font/otf'},
    {key:'font/woff', value: 'font/ttf'},
    {key:'font/woff2', value:'font/woff2'},
    {key:'font/collection', value:'font/collection'},
    {key:'image/bmp', value:'image/bmp'},
    {key:'image/cgm', value:'image/cgm'},
    {key:'image/emf', value:'image/emf'},
    {key:'image/jls', value:'image/jls'},
    {key:'image/jp2', value:'image/jp2'},
    {key:'image/jph', value:'image/jph'},
    {key:'image/jpm', value:'image/jpm'},
    {key:'image/jpx', value:'image/jpx'},
    {key:'image/jxr', value:'image/jxr'},
    {key:'image/jxs', value:'image/jxs'},
    {key:'image/ktx', value:'image/ktx'},
    {key:'image/png', value:'image/png'},
    {key:'image/t38', value:'image/t38'},
    {key:'image/wmf', value:'image/wmf'},
    {key:'image/emf', value:'image/emf'},
    {key:'image/wmf', value:'image/wmf'},
    {key:'image/aces', value:'image/aces'},
    {key:'image/avci', value:'image/avci'},
    {key:'image/avcs', value:'image/avcs'},
    {key:'image/avif', value:'image/avif'},
    {key:'image/fits', value:'image/fits'},
    {key:'image/heic', value:'image/heic'},
    {key:'image/heif', value:'image/heif'},
    {key:'image/hsj2', value:'image/hsj2'},
    {key:'image/jphc', value:'image/jphc'},
    {key:'image/jxrA', value:'image/jxrA'},
    {key:'image/jxrS', value:'image/jxrS'},
    {key:'image/jxsc', value:'image/jxsc'},
    {key:'image/jxsi', value:'image/jxsi'},
    {key:'image/jxss', value:'image/jxss'},
    {key:'image/ktx2', value:'image/ktx2'},
    {key:'image/tiff', value:'image/tiff'},
    {key:'image/g3fax',value:'image/g3fax'},
    {key:'image/hej2k',value:'image/hej2k'},
    {key:'image/naplps', value:'image/naplps'},
    {key:'image/example', value:'image/example'},
    {key:'image/prs.pti', value:'image/prs.pti'},
    {key:'image/svg+xml', value:'image/svg+xml'},
    {key:'image/tiff-fx', value:'image/tiff-fx'},
    {key:'image/vnd.dwg', value:'image/vnd.dwg'},
    {key:'image/vnd.dxf', value:'image/vnd.dxf'},
    {key:'image/vnd.fpx', value:'image/vnd.fpx'},
    {key:'image/vnd.fst', value:'image/vnd.fst'},
    {key:'image/vnd.mix', value:'image/vnd.mix'},
    {key:'image/vnd.svf', value:'image/vnd.svf'},
    {key:'image/prs.btif', value:'image/prs.btif'},
    {key:'image/vnd.djvu', value:'image/vnd.djvu'},
    {key:'image/vnd.xiff', value:'image/vnd.xiff'},
    {key:'image/dicom-rle', value:'image/dicom-rle'},
    {key:'image/pwg-raster', value:'image/pwg-raster'},
    {key:'image/vnd.ms-modi', value:'image/vnd.ms-modi'},
    {key:'image/vnd.net-fpx', value:'image/vnd.net-fpx'},
    {key:'image/vnd.pco.b16', value:'image/vnd.pco.b16'},
    {key:'image/vnd.cns.inf2', value:'image/vnd.cns.inf2'},
    {key:'image/vnd.radiance', value:'image/vnd.radiance'},
    {key:'image/vnd.wap.wbmp', value:'image/vnd.wap.wbmp'},
    {key:'image/heic-sequence', value:'image/heic-sequence'},
    {key:'image/heif-sequence', value:'image/heif-sequence'},
    {key:'image/vnd.sealed.png', value:'image/vnd.sealed.png'},
    {key:'image/vnd.zbrush.pcx', value:'image/vnd.zbrush.pcx'},
    {key:'image/vnd.tencent.tap', value:'image/vnd.tencent.tap'},
    {key:'image/vnd.dece.graphic', value:'image/vnd.dece.graphic'},
    {key:'image/vnd.dvb.subtitle', value:'image/vnd.dvb.subtitle'},
    {key:'image/vnd.fastbidsheet', value:'image/vnd.fastbidsheet'},
    {key:'image/vnd.mozilla.apng', value:'image/vnd.mozilla.apng'},
    {key:'image/vnd.microsoft.icon', value:'image/vnd.microsoft.icon'},
    {key:'image/vnd.adobe.photoshop', value:'image/vnd.adobe.photoshop'},
    {key:'image/vnd.globalgraphics.pgb', value:'image/vnd.globalgraphics.pgb'},
    {key:'image/vnd.fujixerox.edmics-mmr', value:'image/vnd.fujixerox.edmics-mmr'},
    {key:'image/vnd.fujixerox.edmics-rlc', value:'image/vnd.fujixerox.edmics-rlc'},
    {key:'image/vnd.valve.source.texture', value:'image/vnd.valve.source.texture'},
    {key:'image/vnd.airzip.accelerator.azv', value:'image/vnd.airzip.accelerator.azv'},
    {key:'image/vnd.sealedmedia.softseal.gif', value:'image/vnd.sealedmedia.softseal.gif'},
    {key:'image/vnd.sealedmedia.softseal.jpg', value:'image/vnd.sealedmedia.softseal.jpg'},
    {key:'message/sip', value:'message/sip'},
    {key:'message/CPIM', value:'message/CPIM'},
    {key:'message/http', value:'message/http'},
    {key:'message/news', value:'message/news'},
    {key:'message/global', value:'message/global'},
    {key:'message/s-http', value:'message/s-http'},
    {key:'message/example', value:'message/example'},
    {key:'message/sipfrag', value:'message/sipfrag'},
    {key:'message/imdn+xml', value:'message/imdn+xml'},
    {key:'message/vnd.si.simp', value:'message/vnd.si.simp'},
    {key:'message/vnd.wfa.wsc', value:'message/vnd.wfa.wsc'},
    {key:'message/global-headers', value:'message/global-headers'},
    {key:'message/delivery-status', value:'message/delivery-status'},
    {key:'message/feedback-report', value:'message/feedback-report'},
    {key:'message/tracking-status', value:'message/tracking-status'},
    {key:'message/global-delivery-status', value:'message/global-delivery-status'},
    {key:'message/disposition-notification', value:'message/disposition-notification'},
    {key:'message/global-disposition-notification', value:'message/global-disposition-notification'},
    {key:'model/3mf', value:'model/3mf'},
    {key:'model/e57', value:'model/e57'},
    {key:'model/mtl', value:'model/mtl'},
    {key:'model/obj', value:'model/obj'},
    {key:'model/prc', value:'model/prc'},
    {key:'model/stl', value:'model/stl'},
    {key:'model/u3d', value:'model/u3d'},
    {key:'model/iges', value:'model/iges'},
    {key:'model/step', value:'model/step'},
    {key:'model/example', value:'model/example'},
    {key:'model/vnd.dwf', value:'model/vnd.dwf'},
    {key:'model/vnd.gdl', value:'model/vnd.gdl'},
    {key:'model/vnd.gtw', value:'model/vnd.gtw'},
    {key:'model/vnd.mts', value:'model/vnd.mts'},
    {key:'model/vnd.vtu', value:'model/vnd.vtu'},
    {key:'model/x3d+xml', value:'model/x3d+xml'},
    {key:'model/step+xml', value:'model/step+xml'},
    {key:'model/step+zip', value:'model/step+zip'},
    {key:'model/x3d-vrml', value:'model/x3d-vrml'},
    {key:'model/gltf+json', value:'model/gltf+json'},
    {key:'model/vnd.gs-gdl', value:'model/vnd.gs-gdl'},
    {key:'model/gltf-binary', value:'model/gltf-binary'},
    {key:'model/vnd.opengex', value:'model/vnd.opengex'},
    {key:'model/vnd.sap.vds', value:'model/vnd.sap.vds'},
    {key:'model/step-xml+zip', value:'model/step-xml+zip'},
    {key:'model/vnd.moml+xml', value:'model/vnd.moml+xml'},
    {key:'model/vnd.usdz+zip', value:'model/vnd.usdz+zip'},
    {key:'model/vnd.pytha.pyox', value:'model/vnd.pytha.pyox'},
    {key:'model/vnd.collada+xml', value:'model/vnd.collada+xml'},
    {key:'model/x3d+fastinfoset', value:'model/x3d+fastinfoset'},
    {key:'model/vnd.flatland.3dml', value:'model/vnd.flatland.3dml'},
    {key:'model/vnd.parasolid.transmit.text', value:'model/vnd.parasolid.transmit.text'},
    {key:'model/vnd.parasolid.transmit.binary', value:'model/vnd.parasolid.transmit.binary'},
    {key:'model/vnd.valve.source.compiled-map', value:'model/vnd.valve.source.compiled-map'},
    {key:'model/vnd.rosette.annotated-data-model', value:'model/vnd.rosette.annotated-data-model'},
    {key:'multipart/report', value:'multipart/report'},
    {key:'multipart/signed', value:'multipart/signed'},
    {key:'multipart/example', value:'multipart/example'},
    {key:'multipart/related', value:'multipart/related'},
    {key:'multipart/encrypted', value:'multipart/encrypted'},
    {key:'multipart/form-data', value:'multipart/form-data'},
    {key:'multipart/byteranges', value:'multipart/byteranges'},
    {key:'multipart/header-set', value:'multipart/header-set'},
    {key:'multipart/appledouble',value:'multipart/appledouble'},
    {key:'multipart/multilingual', value:'multipart/multilingual'},
    {key:'multipart/voice-message', value:'multipart/voice-message'},
    {key:'multipart/x-mixed-replace', value:'multipart/x-mixed-replace'},
    {key:'multipart/vnd.bint.med-plus', value:'multipart/vnd.bint.med-plus'},
    {key:'text/n3', value:'text/n3'},
    {key:'text/cql', value:'text/cql'},
    {key:'text/css', value:'text/css'},
    {key:'text/csv', value:'text/csv'},
    {key:'text/dns', value:'text/dns'},
    {key:'text/RED', value:'text/RED'},
    {key:'text/rtf', value:'text/rtf'},
    {key:'text/rtx', value:'text/rtx'},
    {key:'text/vtt', value:'text/vtt'},
    {key:'text/xml', value:'text/xml'},
    {key:'text/gff3', value:'text/gff3'},
    {key:'text/html', value:'text/html'},
    {key:'text/SGML', value:'text/SGML'},
    {key:'text/shex', value:'text/shex'},
    {key:'text/spdx', value:'text/spdx'},
    {key:'text/t140', value:'text/t140'},
    {key:'text/mizar', value:'text/mizar'},
    {key:'text/troff', value:'text/troff'},
    {key:'text/vcard', value:'text/vcard'},
    {key:'text/vnd.a', value:'text/vnd.a'},
    {key:'text/fwdred', value:'text/fwdred'},
    {key:'text/shaclc', value:'text/shaclc'},
    {key:'text/turtle', value:'text/turtle'},
    {key:'text/ulpfec', value:'text/ulpfec'},
    {key:'text/example', value:'text/example'},
    {key:'text/flexfec', value:'text/flexfec'},
    {key:'text/jcr-cnd', value:'text/jcr-cnd'},
    {key:'text/strings', value:'text/strings'},
    {key:'text/vnd.abc', value:'text/vnd.abc'},
    {key:'text/vnd.fly', value:'text/vnd.fly'},
    {key:'text/vnd.gml', value:'text/vnd.gml'},
    {key:'text/vnd.hgl', value:'text/vnd.hgl'},
    {key:'text/calendar', value:'text/calendar'},
    {key:'text/encaprtp', value:'text/encaprtp'},
    {key:'text/fhirpath', value:'text/fhirpath'},
    {key:'text/markdown', value:'text/markdown'},
    {key:'text/uri-list', value:'text/uri-list'},
    {key:'text/vnd.curl', value:'text/vnd.curl'},
    {key:'text/vnd.hans', value:'text/vnd.hans'},
    {key:'text/vnd.sosi', value:'text/vnd.sosi'},
    {key:'text/directory', value:'text/directory'},
    {key:'text/parityfec', value:'text/parityfec'},
    {key:'text/raptorfec', value:'text/raptorfec'},
    {key:'text/csv-schema', value:'text/csv-schema'},
    {key:'text/ecmascript', value:'text/ecmascript'},
    {key:'text/javascript', value:'text/javascript'},
    {key:'text/parameters', value:'text/parameters'},
    {key:'text/vnd.wap.si', value:'text/vnd.wap.si'},
    {key:'text/vnd.wap.sl', value:'text/vnd.wap.sl'},
    {key:'text/rtploopback', value:'text/rtploopback'},
    {key:'text/vnd.latex-z', value:'text/vnd.latex-z'},
    {key:'text/vnd.wap.wml', value:'text/vnd.wap.wml'},
    {key:'text/vnd.graphviz', value:'text/vnd.graphviz'},
    {key:'text/prs.lines.tag', value:'text/prs.lines.tag'},
    {key:'text/vnd.ascii-art', value:'text/vnd.ascii-art'},
    {key:'text/vnd.in3d.3dml', value:'text/vnd.in3d.3dml'},
    {key:'text/vnd.in3d.spot', value:'text/vnd.in3d.spot'},
    {key:'text/vnd.IPTC.NITF', value:'text/vnd.IPTC.NITF'},
    {key:'text/cache-manifest', value:'text/cache-manifest'},
    {key:'text/cql-expression', value:'text/cql-expression'},
    {key:'text/cql-identifier', value:'text/cql-identifier'},
    {key:'text/prs.prop.logic', value:'text/prs.prop.logic'},
    {key:'text/rfc822-headers', value:'text/rfc822-headers'},
    {key:'text/vnd.ficlab.flt', value:'text/vnd.ficlab.flt'},
    {key:'text/vnd.IPTC.NewsML', value:'text/vnd.IPTC.NewsML'},
    {key:'text/grammar-ref-list', value:'text/grammar-ref-list'},
    {key:'text/rtp-enc-aescm128', value:'text/rtp-enc-aescm128'},
    {key:'text/vnd.dvb.subtitle', value:'text/vnd.dvb.subtitle'},
    {key:'text/vnd.fmi.flexstor', value:'text/vnd.fmi.flexstor'},
    {key:'text/vnd.wap.wmlscript', value:'text/vnd.wap.wmlscript'},
    {key:'text/vnd.DMClientScript', value:'text/vnd.DMClientScript'},
    {key:'text/provenance-notation', value:'text/provenance-notation'},
    {key:'text/prs.fallenstein.rst', value:'text/prs.fallenstein.rst'},
    {key:'text/vnd.motorola.reflex', value:'text/vnd.motorola.reflex'},
    {key:'text/vnd.ms-mediapackage', value:'text/vnd.ms-mediapackage'},
    {key:'text/vnd.senx.warpscript', value:'text/vnd.senx.warpscript'},
    {key:'text/vnd.si.uricatalogue', value:'text/vnd.si.uricatalogue'},
    {key:'text/tab-separated-values', value:'text/tab-separated-values'},
    {key:'text/vnd.debian.copyright', value:'text/vnd.debian.copyright'},
    {key:'text/vnd.trolltech.linguist', value:'text/vnd.trolltech.linguist'},
    {key:'text/vnd.familysearch.gedcom', value:'text/vnd.familysearch.gedcom'},
    {key:'text/1d-interleaved-parityfec', value:'text/1d-interleaved-parityfec'},
    {key:'text/xml-external-parsed-entity', value:'text/xml-external-parsed-entity'},
    {key:'text/vnd.sun.j2me.app-descriptor', value:'text/vnd.sun.j2me.app-descriptor'},
    {key:'text/vnd.esmertec.theme-descriptor', value:'text/vnd.esmertec.theme-descriptor'},
    {key:'text/vnd.radisys.msml-basic-layout', value:'text/vnd.radisys.msml-basic-layout'},
    {key:'text/vnd.net2phone.commcenter.command', value:'text/vnd.net2phone.commcenter.command'},
    {key:'video/DV', value:'video/DV'},
    {key:'video/nv', value:'video/nv'},
    {key:'video/mj2', value:'video/mj2'},
    {key:'video/mp4', value:'video/mp4'},
    {key:'video/MPV', value:'video/MPV'},
    {key:'video/ogg', value:'video/ogg'},
    {key:'video/raw', value:'video/raw'},
    {key:'video/rtx', value:'video/rtx'},
    {key:'video/vc1', value:'video/vc1'},
    {key:'video/vc2', value:'video/vc2'},
    {key:'video/VP8', value:'video/VP8'},
    {key:'video/VP9', value:'video/VP9'},
    {key:'video/3gpp', value:'video/3gpp'},
    {key:'video/CelB', value:'video/CelB'},
    {key:'video/FFV1', value:'video/FFV1'},
    {key:'video/H261', value:'video/H261'},
    {key:'video/H263', value:'video/H263'},
    {key:'video/H264', value:'video/H264'},
    {key:'video/H265', value:'video/H265'},
    {key:'video/JPEG', value:'video/JPEG'},
    {key:'video/jxsv', value:'video/jxsv'},
    {key:'video/MP1S', value:'video/MP1S'},
    {key:'video/MP2P', value:'video/MP2P'},
    {key:'video/MP2T', value:'video/MP2T'},
    {key:'video/scip', value:'video/scip'},    
    {key:'video/3gpp2', value:'video/3gpp2'},
    {key:'video/BMPEG', value:'video/BMPEG'},
    {key:'video/BT656', value:'video/BT656'},
    {key:'video/ulpfec', value:'video/ulpfec'},
    {key:'video/3gpp-tt', value:'video/3gpp-tt'},
    {key:'video/example', value:'video/example'},
    {key:'video/flexfec', value:'video/flexfec'},
    {key:'video/MP4V-ES', value:'video/MP4V-ES'},
    {key:'video/pointer', value:'video/pointer'},
    {key:'video/vnd.fvt', value:'video/vnd.fvt'},
    {key:'video/encaprtp', value:'video/encaprtp'},
    {key:'video/H264-SVC', value:'video/H264-SVC'},
    {key:'video/jpeg2000', value:'video/jpeg2000'},
    {key:'video/smpte291', value:'video/smpte291'},
    {key:'video/vnd.CCTV', value:'video/vnd.CCTV'},
    {key:'video/vnd.vivo', value:'video/vnd.vivo'},    
    {key:'video/H263-1998', value:'video/H263-1998'},
    {key:'video/H263-2000', value:'video/H263-2000'},
    {key:'video/H264-RCDO', value:'video/H264-RCDO'},
    {key:'video/parityfec', value:'video/parityfec'},
    {key:'video/quicktime', value:'video/quicktime'},
    {key:'video/raptorfec', value:'video/raptorfec'},
    {key:'video/SMPTE292M', value:'video/SMPTE292M'},
    {key:'video/iso.segment', value:'video/iso.segment'},
    {key:'video/rtploopback', value:'video/rtploopback'},
    {key:'video/vnd.dece.hd', value:'video/vnd.dece.hd'},
    {key:'video/vnd.dece.pd', value:'video/vnd.dece.pd'},
    {key:'video/vnd.dece.sd', value:'video/vnd.dece.sd'},
    {key:'video/vnd.mpegurl', value:'video/vnd.mpegurl'},
    {key:'video/vnd.dece.mp4', value:'video/vnd.dece.mp4'},
    {key:'video/vnd.dvb.file', value:'video/vnd.dvb.file'},
    {key:'video/vnd.uvvu.mp4', value:'video/vnd.uvvu.mp4'},
    {key:'video/mpeg4-generic', value:'video/mpeg4-generic'},
    {key:'video/vnd.hns.video', value:'video/vnd.hns.video'},
    {key:'video/vnd.dece.video', value:'video/vnd.dece.video'},
    {key:'video/vnd.sealed.swf', value:'video/vnd.sealed.swf'},
    {key:'video/vnd.youtube.yt', value:'video/vnd.youtube.yt'},
    {key:'video/vnd.dece.mobile', value:'video/vnd.dece.mobile'},
    {key:'video/vnd.nokia.mp4vr', value:'video/vnd.nokia.mp4vr'},
    {key:'video/vnd.objectvideo', value:'video/vnd.objectvideo'},
    {key:'video/rtp-enc-aescm128', value:'video/rtp-enc-aescm128'},
    {key:'video/vnd.directv.mpeg', value:'video/vnd.directv.mpeg'},
    {key:'video/vnd.sealed.mpeg1', value:'video/vnd.sealed.mpeg1'},
    {key:'video/vnd.sealed.mpeg4', value:'video/vnd.sealed.mpeg4'},
    {key:'video/vnd.dlna.mpeg-tts', value:'video/vnd.dlna.mpeg-tts'},
    {key:'video/vnd.motorola.video', value:'video/vnd.motorola.video'},
    {key:'video/vnd.motorola.videop', value:'video/vnd.motorola.videop'},
    {key:'video/vnd.nokia.videovoip', value:'video/vnd.nokia.videovoip'},
    {key:'video/vnd.directv.mpeg-tts', value:'video/vnd.directv.mpeg-tts'},
    {key:'video/vnd.iptvforum.ttsavc', value:'video/vnd.iptvforum.ttsavc'},
    {key:'video/vnd.iptvforum.ttsmpeg2', value:'video/vnd.iptvforum.ttsmpeg2'},
    {key:'video/vnd.radgamettools.bink', value:'video/vnd.radgamettools.bink'},
    {key:'video/1d-interleaved-parityfec', value:'video/1d-interleaved-parityfec'},
    {key:'video/vnd.radgamettools.smacker', value:'video/vnd.radgamettools.smacker'},
    {key:'video/vnd.ms-playready.media.pyv', value:'video/vnd.ms-playready.media.pyv'},
    {key:'video/vnd.sealedmedia.softseal.mov', value:'video/vnd.sealedmedia.softseal.mov'},
    {key:'video/vnd.iptvforum.1dparityfec-1010', value:'video/vnd.iptvforum.1dparityfec-1010'},
    {key:'video/vnd.iptvforum.1dparityfec-2005', value:'video/vnd.iptvforum.1dparityfec-2005'},
    {key:'video/vnd.iptvforum.2dparityfec-1010', value:'video/vnd.iptvforum.2dparityfec-1010'},
    {key:'video/vnd.iptvforum.2dparityfec-2005', value:'video/vnd.iptvforum.2dparityfec-2005'},
    {key:'video/vnd.nokia.interleaved-multimedia', value:'video/vnd.nokia.interleaved-multimedia'}




















































































    























    







    

    


        













    


   


























































];