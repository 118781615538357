<div class="d-flex flex-column justify-content-start align-items-center mb-3">
    <div class="d-flex flex-row justify-content-around align-items-end w-100">
        <div class="w-75 mr-5 d-flex align-items-end">
            <fa-icon class="title-main-size4 pr-3" [icon]="faTools"></fa-icon>
            <span class="title-main-size3">WHAT?</span>
        </div>

        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
    </div>

    <div class="horizonal-rule-top"></div>
    <div class="d-flex justify-content-center align-items-start w-80">
        <div class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">Impact on the Target</h6>
            <div class="gr-help-section-content">
                    <p>First, select the type of loss and what information was compromised</p>
                    <p>Next, select the type and physical damage caused by the incident.</p>
                    <!-- <p>Last, select the monetary impact on the target if known.</p> -->
            </div>
        </div>
        <div class="d-flex flex-column align-items-left gr-outter-wrapper mt-5" style="position: relative;">
            
            <div class="cwe-section px-2 pt-2">
                <div class="row my-4">
                    <div class="col-6 pl-5 pr-4 h5">What loss was occurred?
                        <select class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="lossType" (change)="checkCart()">
                            <option disabled selected style="color: #91a4bb;" value="">Select Loss Type</option>
                            <option *ngFor="let option of lossTypeOptions" [value]="option.key">{{option.value}}</option>
                        </select>    
                    </div>
                    <div class="col-6 pl-4 pr-5 h5">What information were compromised?
                        <select class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="infoCompromised" [disabled]="!lossType" (change)="checkCart()">
                            <option disabled selected style="color: #91a4bb;" value="">Select Information Type</option>
                            <option *ngFor="let option of informationTypeOptions" [value]="option.key">{{option.value}}</option>
                        </select>
                    </div>
                </div>
                </div>
            <div class="cwe-section px-2 pt-2">
                <div class="row my-4">
                    <div class="col-6 pl-5 pr-4 h5">What was the physical impact?
                        <select class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="physicalImpactType" (change)="checkCart()">
                            <option disabled selected style="color: #91a4bb;" value="">Select Physical Impact</option>
                            <option *ngFor="let option of physicalImpactOptions" [value]="option.key">{{option.value}}</option>
                        </select>    
                    </div>
                    <div class="col-6 pl-4 pr-5 h5">What property or system was affected?
                        <select class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="assetAffected" [disabled]="!physicalImpactType" (change)="checkCart()">
                            <option disabled selected style="color: #91a4bb;" value="">Select Asset Type</option>
                            <option *ngFor="let option of assetTypeOptions" [value]="option.key">{{option.value}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <div class="cwe-section px-2 pt-2">
                <div class="row my-4">
                    <div class="col-12 px-5 h5">What was the monetary impact?<br>
                        <select class="form-control my-2 gr-input-wrapper-small" type="text" [(ngModel)]="monetaryImpacted" (change)="checkCart()">
                            <option disabled selected style="color: #91a4bb;" value="">Select Impact Variety</option>
                            <option *ngFor="let option of monetaryImpactOptions" [value]="option.key">{{option.value}}</option>
                        </select>    
                    </div>
                </div>   
            </div> -->
            <app-guided-cart class="w-100" #guidedCart [currentComponent]="component" [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [editModeEvent]="editModeEmitter" [syncCartEvent]="syncCartEmitter" [getEditItemEvent]="getEditItemEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart>
         </div>
    </div>
</div>
