export const SCO_LIST = [
	"artifact",
	"autonomous-system",
	"directory",
	"domain-name",
	"email-addr",
	"email-message",
	"email-mime-part-type",
	"file",
	"archive-ext",
	"archive-ext",
	"alternate-data-stream-type",
	"pdf-ext",
	"raster-image-ext",
	"windows-pebinary-ext",
	"windows-pe-optional-header-type",
	"windows-pe-section-type",
	"ipv4-addr",
	"ipv6-addr",
	"mac-addr",
	"mutex",
	"network-traffic",
	"http-request-ext",
	"icmp-ext",
	"socket-ext",
	"tcp-ext",
	"process",
	"windows-process-ext",
	"windows-service-ext",
	"software",
	"url",
	"user-account",
	"unix-account-ext",
	"windows-registry-key",
	"windows-registry-value-type",
	"x509-certificate",
	"x509_v3_extensions"
  ]