import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddComponentComponent } from './add-component/add-component.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthKeycloak } from './auth/auth.keycloak';
import { LoginComponent } from './auth/login/login.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { BundleComponent } from './bundle/bundle.component';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { QueryStixComponent } from './query-stix/query-stix.component';
import { ReportingComponent } from './reporting/reporting.component';
import { StixViewerComponent } from './stix-viewer/stix-viewer.component';
import { CustomObjectComponent } from './custom-objects/custom-object.component';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { GuidedComponent } from './guided/guided.component';
import { environment } from 'src/environments/environment';
import { ConfigComponent } from './config/config.component';
import { UserDataComponent } from './user-data/user-data.component';

var auth: any = AuthGuard;
if (environment.keycloak === true) {
  auth = AuthKeycloak;
}

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  /*   { path: 'home', component: HomeComponent }, */
  { path: 'home', component: HomeComponent, canActivate: [auth] },
  { path: 'bundle', component: BundleComponent, canActivate: [auth] },
  { path: 'add-component/:component-id', component: AddComponentComponent, canActivate: [auth] },
  { path: 'custom-object', component: CustomObjectComponent, canActivate: [auth] },
  { path: 'query-stix', component: QueryStixComponent, canActivate: [auth] },
  { path: 'reporting', component: ReportingComponent, canActivate: [auth] },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'stix-viewer', component: StixViewerComponent },
  { path: '404', redirectTo: '/home', pathMatch: 'full' },
  { path: 'admin-main', component: AdminMainComponent, pathMatch: 'full', canActivate: [auth], data: { roles: [environment.taxiiServer.adminRoleName] } },
  { path: 'config', component: ConfigComponent, pathMatch: 'full', canActivate: [auth] },
  { path: 'my-profile', component: UserDataComponent, pathMatch: 'full', canActivate: [auth] },
  { path: 'guided', component: GuidedComponent, pathMatch: 'full', canActivate: [auth] },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }