<div class="container-fluid">
    <div *ngIf="screenWidth >= 1350" class="row">
        <div class="col-6 mt-3">
            <div class="card-columns">
                <div class="card border-info bg-light mb-3">
                    <img class="card-img" src="./assets/pencil-square.svg" class="center" width="150" height="100" />
                    <div class="card-body">
                        <div id="button" class="left">
                            <button type="button" class="btn btn-publish btn-lg card-button" routerLink='{{ editorLink }}'>
                                <strong>Editor</strong>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card border-info bg-light mb-3" style="max-width: 20rem;">
                    <img class="card-img" src="./assets/search.svg" class="center" width="150" height="100" />
                    <div class="card-body">
                        <div id="button" class="left">
                            <button type="button" class="btn btn-query btn-lg card-button" routerLink="/query-stix">
                                <strong>Search</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row" style="justify-content: center;">
                <div class="card border-info bg-light mb-3" style="max-width: 20rem;">
                    <img class="card-img" src="./assets/file-bar-graph.svg" class="center" width="150" height="100" style="align-self: center;"/>
                    <div class="card-body">
                        <div id="button" class="left">
                            <button type="button" class="btn btn-reporting btn-lg card-button" routerLink="/reporting">
                                <strong>Analytics</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div *ngIf="!isAdmin()" class="row" style="justify-content: center;">
                <div class="card border-info bg-light mb-3" style="max-width: 20rem;">
                    <img class="card-img" src="./assets/file-bar-graph.svg" class="center" width="150" height="100" style="align-self: center;"/>
                    <div class="card-body">
                        <div id="button" class="left">
                            <button type="button" class="btn btn-reporting btn-lg card-button" routerLink="/reporting">
                                <strong>Analytics</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->
            <div *ngIf="isAdmin" class="row" style="justify-content: center;">
                <div class="card border-info bg-light mb-3" style="max-width: 20rem;">
                    <img class="card-img" src="./assets/file-bar-graph.svg" class="center" width="150" height="100"
                        style="align-self: center;" />
                    <div class="card-body">
                        <div id="button" class="left">
                            <button type="button" class="btn btn-reporting btn-lg card-button" routerLink="/admin-main">
                                <strong>Admin</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div id="logo" class="text-center">
                <img src="./assets/IMX_Icon_White_WithBackground.png" class="imx-logo" />
            </div>
        </div>
    </div>

    <div *ngIf="screenWidth < 1350" class="bg-img">
        <img src="./assets/IMX_Icon_White_WithBackground.png" class="imx-logo" />
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="card border-info bg-light mb-3">
                <img class="card-img" src="./assets/pencil-square.svg" class="center align-self-center" width="150"
                    height="100" />
                <div class="card-body">
                    <div id="button" class="left">
                        <button type="button" class="btn btn-publish btn-lg card-button" routerLink='{{ editorLink }}'>
                            <strong>Editor</strong>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card border-info bg-light mb-3" style="margin-left: 1rem">
                <img class="card-img" src="./assets/search.svg" class="center align-self-center" width="150"
                    height="100" />
                <div class="card-body">
                    <div id="button" class="left">
                        <button type="button" class="btn btn-query btn-lg card-button" routerLink="/query-stix">
                            <strong>Search</strong>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="isAdmin" class="card border-info bg-light mb-3" style="margin-left: 1rem">
                <img class="card-img" src="./assets/file-bar-graph.svg" class="center" width="150" height="100"
                    style="align-self: center;" />
                <div class="card-body">
                    <div id="button" class="left">
                        <button type="button" class="btn btn-query btn-lg card-button" routerLink="/admin-main">
                            <strong>Admin</strong>
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div class="card border-info bg-light mb-3">
                <img class="card-img" src="./assets/file-bar-graph.svg" class="center align-self-center" width="150" height="100"/>
                <div class="card-body">
                    <div id="button" class="left">
                        <button type="button" class="btn btn-reporting btn-lg card-button" routerLink="/reporting">
                            <strong>Analytics</strong>
                        </button>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>