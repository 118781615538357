<div class="body">
  <!-- <div class="mt-4 action-button">
    <button
          type="button"
          class="btn btn-sm btn-secondary"
          (click)="backToMain()"
    >
      Return
    </button>
  </div> -->
  <div
    *ngIf="!editingTitle"
    class="item-title">
    {{ collection.title }}
    <button type="button" class="btn btn-fa btn-primary ml-3 mr-1 action-button"
      title="Click to edit"
      (click)="editTitle()">
      <fa-icon
        [icon]="faEdit"
      >
      </fa-icon>
    </button>
  </div>

  <div
    *ngIf="editingTitle"
    class="d-flex item-title">
    <input
      class="form-control item-label-input"
      type="text"
      placeholder="Collection Title"
      [(ngModel)]="currentTitle"
    />
    <button type="button" class="btn btn-fa btn-success ml-3 mr-1"
      title="Click to save"
      (click)="saveTitle()">
      <fa-icon [icon]="faSave">
      </fa-icon>
    </button>
    <button type="button" class="btn btn-fa btn-danger ml-2 mr-1"
      title="Click to cancel"
      (click)="cancelTitle()">
      <fa-icon [icon]="faBan">
      </fa-icon>
    </button>
  </div>

  <div class="item-content">
    <label class="item-label">
      <div class="item-label-title">
        Alias
      </div>
      <input
        type="text"
        class="form-control item-label-input input-sm"
        [(ngModel)]="collection.alias" />
    </label>

    <label class="item-label">
      <div class="item-label-title">
        ID
      </div>
      <input
        type="text"
        class="item-label-input input-sm"
        [(ngModel)]="collection.id"
        [disabled]="true" />
    </label>

    <label class="item-label">
      <div class="item-label-title">
        Can Read
      </div>
      <input
        type="checkbox"
        class="input-cb"
        [checked]="collection.can_read"
        [(ngModel)]="collection.can_read"/>
    </label>

    <label class="item-label">
      <div class="item-label-title">
        Can Write
      </div>
      <input
        type="checkbox"
        class="mt-1 input-cb"
        [checked]="collection.can_write"
        [(ngModel)]="collection.can_write"/>
    </label>

    <label class="item-label">
      <div class="item-label-title">
        Description
      </div>
      <input type="text"
        class="form-control input-sm item-label-input"
        [(ngModel)]="collection.description" />
    </label>

    <label class="item-label">
      <div class="item-label-title">
        Media Types
      </div>
      <div class="d-flex">
        <input
          type="text"
          class="form-control item-label-input input-sm"
          [(ngModel)]="newMediaType" />
        <button
          type="button"
          class="btn btn-fa-sm btn-primary ml-2"
          title="Click to add"
          (click)="addMediaType()"
          [disabled]="!newMediaType">
          <fa-icon
            [icon]="faPlus"
          ></fa-icon>
        </button>
      </div>
    </label>

    <label class="item-label">
      <div class="item-label-title">
      </div>
      <div class="item-list-wrapper">
        <div *ngFor="let mediaType of collection?.media_types; let i = index;"
          class="media-list-item-wrapper">
          <div class="media-list-item-type">{{ mediaType }}</div>
            <fa-icon [icon]="faTrash"
              title="Click to remove."
              class="btn btn-fa-sm btn-danger mr-1 action-button"
              (click)="removeMediaType(i)"></fa-icon>
        </div>
      </div>
    </label>

    <label class="d-flex">
      <div class="item-label-title mr-3">
        Server Validation
      </div>

      <div class="col-6">
        <div class="d-flex flex-column ml-3 mt-3">
          <div *ngFor="let obj of serverValidation.slice(0, 5); let i = index" class="d-flex flex-column">
            <label class="mt-3">
              <input
                type="checkbox"
                name="lenient-{{i}}"
                class="input-cb"
                [checked]="obj.doCheck"
                (change)="updateCheck($event, obj)"
                />
                <span class="ml-2">{{obj.title}}</span>
                <span class="guided-tip">
                  <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                  <span class="tip-text" [innerHTML]="stixService.getServerValidationTooltip(obj)"></span>
                </span>
            </label>
            <div class="d-flex align-items-center ml-4">
              <input type="radio" [disabled]="obj.disableBoth" name="{{obj.title}}" id="pass-{{i}}" value="false" [(ngModel)]="obj.doFail" class="mr-2 mb-1">
              <label for="pass-{{i}}">Pass</label>
            </div>
            <div class="d-flex align-items-center ml-4">
              <input type="radio" [disabled]="obj.disableBoth" name="{{obj.title}}" id="fail-{{i}}" value="true" [(ngModel)]="obj.doFail" class="mr-2 mb-1">
              <label for="fail-{{i}}">Fail<span>&nbsp;(Default)</span></label>
            </div>
          </div>
          <label class="mt-3">
            <input
              type="checkbox"
              name="lenient-sco"
              class="input-cb"
              [checked]="serverValidation[serverValidation.length - 1].doCheck"
              (change)="updateCheck($event, serverValidation[serverValidation.length - 1])"
              />
              <span class="ml-2">{{serverValidation[serverValidation.length - 1].title}}</span>
              <span class="guided-tip mb-1">
                <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                <span class="tip-text" [innerHTML]="stixService.getServerValidationTooltip(serverValidation[serverValidation.length - 1])"></span>
              </span>
          </label>
        </div>
      </div>

      <div class="col-6">
        <div class="d-flex flex-column ml-3 mt-3">
          <div *ngFor="let obj of serverValidation.slice(5, serverValidation.length - 1); let j = index" class="d-flex flex-column">
            <label class="mt-3">
              <input
                type="checkbox"
                name="lenient-{{j}}"
                class="input-cb"
                [checked]="obj.doCheck"
                (change)="updateCheck($event, obj)"
                />
                <span class="ml-2">{{obj.title}}</span>
                <span class="guided-tip">
                  <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                  <span class="tip-text" [innerHTML]="stixService.getServerValidationTooltip(obj)"></span>
                </span>
            </label>
            <div class="d-flex align-items-center ml-4">
              <input type="radio" [disabled]="obj.disableBoth" name="{{obj.title}}" id="pass-{{j + 5}}" value="false" [(ngModel)]="obj.doFail" class="mr-2 mb-1">
              <label for="pass-{{j + 5}}">Pass<span *ngIf="j + 5 > 7">&nbsp;(Default)</span></label>
            </div>
            <div class="d-flex align-items-center ml-4">
              <input type="radio" [disabled]="obj.disableBoth" name="{{obj.title}}" id="fail-{{j + 5}}" value="true" [(ngModel)]="obj.doFail" class="mr-2 mb-1">
              <label for="fail-{{j + 5}}">Fail<span *ngIf="j + 5 <= 7">&nbsp;(Default)</span></label>
            </div>
          </div>
        </div>
      </div>

    </label>
  </div>

  <div class="item-action">
    <button 
      type="button"
      class="btn btn-sm btn-secondary mr-3"
      (click)="backToMain()">
      <fa-icon [icon]="faBan" class="pr-1"></fa-icon>
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-sm btn-success"
      (click)="backToMain('save')"
      [disabled]="!collection?.title || !collection?.id || editingTitle">
      <fa-icon [icon]="faSave" class="pr-1"></fa-icon>
      Save
    </button>
  </div>

  <div
    *ngIf="errorMessages?.length > 0"
    class="row error-message">
    <div class="col">
        <div class="d-flex justify-content-center flex-column">
          <div *ngFor="let err of errorMessages;"
            class="alert alert-danger" role="alert">
            {{ err }}
          </div>
        </div>
    </div>
  </div>
</div>