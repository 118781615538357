import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { StixService } from 'src/app/stix-service.service';
import { faChevronLeft, faEdit, faSave, faStop, faTrash, faTrashAlt, faPlus, faBan, faKey } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { AnnouncementService } from '../announcement-service/announcement-service.service';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.css'],
})
export class AdminUserComponent implements OnInit {
  @Input() userInput: any;
  @Output() back: EventEmitter<any>;

  newObject = true;
  newRole = null;
  addNewRole = false;

  faChevronLeft = faChevronLeft;
  faEdit = faEdit;
  faSave = faSave;
  faStop = faStop;
  faTrash = faTrash;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;
  faBan = faBan;
  faKey = faKey;

  editingUsername = false;
  currentUsername = '';
  user;
  errorMessages = [];
  existingRoles = [];

  isChecked = false;
  hasCert: boolean;

  private httpHeaders: HttpHeaders;
  private imxHeaders: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
    public stixService: StixService,
    private announcementService: AnnouncementService
  ) {
    this.newRole = undefined;

    this.back = new EventEmitter();

    this.httpHeaders = new HttpHeaders()
      .set('Accept', 'application/taxii+json;version=2.1')
      .set('Authorization', `Basic ${btoa(environment.adminServer.username + ":" + environment.adminServer.password)}`)
      .set('Content-Type', `application/taxii+json;version=2.1`);

    this.imxHeaders = new HttpHeaders()
      .set('Authorization', `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`);
  } 

  ngOnInit(): void {
    this.newObject = Object.keys(this.userInput).length === 0;

    this.user = Object.assign({}, this.userInput);
    this.user.roles = this.user
                      && this.user.roles
                      && this.user.roles.length > 0
                        ? this.user.roles.slice()
                        : [];

    if (this.newObject) {
      this.user.user = null;
      this.user.roles = [];
      this.user.password = null;
    }

    this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
      this.httpClient.get<any>(this.stixService.getBaseURLs().baseAdminURL + "roles", { headers: header, observe: 'response' }).subscribe(
        resp => {
          this.existingRoles = resp.body;
  
          this.existingRoles = this.existingRoles.filter(r => !this.user.roles.some(ur => ur === r));
        }
      )
    })

    this.hasCertificate();
  }

  showPassword() {
    return !environment.keycloak;
  }

  editUsername() {
    this.editingUsername = true;

    if (this.user.user) {
      this.currentUsername = this.user.user;
    } else {
      this.currentUsername = '';
    }
  }

  saveUsername() {
    this.user.user = this.currentUsername;
    this.editingUsername = false;
  }

  cancelUsername() {
    this.editingUsername = false;
    this.currentUsername = null;
  }

  addRole() {
    if (this.user.roles) {
      this.user.roles.push(this.newRole);
    } else {
      this.user['roles'] = [];
      this.user.roles.push(this.newRole);
    }

    const index = this.existingRoles.indexOf(this.newRole);
    if (index >= 0) {
      this.existingRoles.splice(index, 1);
    }

    this.newRole = undefined;
  }

  removeRole(roleIndex) {
    const role = this.user.roles.splice(roleIndex, 1);

    const index = this.existingRoles.indexOf(role[0]);
    if (index < 0) {
      this.existingRoles.push(role[0]);
    }

    this.newRole = undefined;
  }

  async backToMain(event = null) {
    this.errorMessages = [];

    switch (event) {
      case 'save': {
        if (this.hasCert) {
          await this.removeCertificate();
          await this.assignCertificate();
        }
        let url = '';

        // if (!this.user.password.includes('MD5')) {
        //   const md5 = new Md5();
        //   this.user.password = md5.appendStr(this.user.password).end();
        // }
        const emitObj = { type: 'save-user', data: this.user };

        if (this.newObject) {  // New object
          url = this.stixService.getBaseURLs().baseAdminURL + 'user?create=true';
        } else {  // Update existing object
          url = this.stixService.getBaseURLs().baseAdminURL + 'user';
        }

        // IMX Client uses Keycloak for authentication and uses Bearer token for authorization.
        // Backend will support password field for clients using Basic auth.
        // So set password to null as IMX Client uses Bearer token and its user name, not pw. 
        this.user.password = this.user.password ? this.user.password : null;

        // User needs to be a part of at a minmum a predefined group if it is defined in env file
        if (this.user.roles
          && environment.adminServer.baselineGroup
          && environment.adminServer.baselineGroup.length > 0
          && !this.user.roles.find(r => r === environment.adminServer.baselineGroup)) {
          this.user.roles.push(environment.adminServer.baselineGroup);
        }
        this.stixService.getCert(this.httpHeaders, (header: HttpHeaders) => {
          this.httpClient.post<any>(url, this.user, { headers: header }).subscribe((resp: any) => {
            if (this.errorMessages.length === 0
              && resp.password) {
              emitObj.data.password = resp.password;
              this.back.emit(emitObj);
            }
          },
          (err: any) => {
            this.handleError(err, "Please check user parameters.");
          })
        })
        break;
      }
      default: {
        const emitObj = { type: 'cancel' };
        this.back.emit(emitObj);
      }
    }
  }

  handleError(err, defaultMsg = '') {
    if (err.status >= 400 && err.error && err.error.description) {
      this.errorMessages.push("Error: " + err.error.description);
      setTimeout(() => {
        this.errorMessages = [];
      }, 8000)
    } else {
      this.errorMessages.push("Error: " + defaultMsg);
      setTimeout(() => {
        this.errorMessages = [];
      }, 8000)
    }
  }

  selectRole(event: any) {
    if (event && event.target && event.target.value) {
      this.newRole = event.target.value;
    }
  }

  hasCertificate() {
    this.httpClient.get<any>(environment.imxServer.url + `/cert/${this.user.user}`, { headers: this.imxHeaders, observe: 'response' }).subscribe(
      resp => {
        if (resp.body && resp.body.username)
          this.hasCert = true;
        else  
          this.hasCert = false;
      },
      err => {
        this.hasCert = false;
      }
    )
  }
  
  async assignCertificate() {
    try {
      const url = this.stixService.taxiiServer.url + `admin/v1.0/pkcs10/DFTA/${this.user.user}`;
      const imxUrl = environment.imxServer.url + '/cert/' + this.user.user; 
  
      const httpHeaders = new HttpHeaders()
        .set('Accept', '*/*')
        .set('Authorization', `Basic ${btoa(environment.adminServer.username + ":" + environment.adminServer.password)}`)
        .set('Content-Type', `application/json`);
  
      const response = await this.httpClient.post<any>(url, this.user.roles, { headers: httpHeaders, observe: 'response' }).toPromise()
        if (response.body) {
          try {
            const imxResponse = await this.httpClient.post<any>(imxUrl, response.body, { headers: this.imxHeaders, observe: 'response' }).toPromise();
            this.hasCert = true;

            let certHeaders = new HttpHeaders()
              .set('Authorization', `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`);
      
            this.announcementService.show("Certificate generated", "Certificate has been generated and assigned to the user", "success", false);
            try {
              const certRes = await this.httpClient.get<any>(environment.imxServer.url + '/cert', { headers: certHeaders }).toPromise();
              if(certRes.data){
                this.stixService.certFound = true;
                this.stixService.cert = certRes.data.pkcs10;
              }
            } catch (err) {
              console.log("Cert is not for user");
            }
            // this.stixService.certFound = true;
            // this.stixService.cert = response.body.pkcs10;
          } catch (imxErr) {
            this.httpClient.delete<any>(this.stixService.taxiiServer.url + `admin/v1.0/user/${response.body.user.user}`, { headers: httpHeaders, observe: 'response'}).subscribe(
              deleteResp => {
                this.handleError(imxErr, "Failed to post Cert to IMX Server");
              }, 
              deleteErr => {
                this.handleError(deleteErr, "Error while creating user certificate: certificate persists on TAXII Server");
              }
            )
          }
        } else
          this.handleError({}, "TAXII Server did not properly return certification");
    } catch (err) {
      this.handleError(err, "Failed to request TAXII Client for self-signed cert");
    }
  }

  async removeCertificate() {
    try {
      const url = this.stixService.taxiiServer.url + `admin/v1.0/user/`;
      const imxUrl = environment.imxServer.url + '/cert/' + this.user.user; 
      const httpHeaders = new HttpHeaders()
        .set('Accept', '*/*')
        .set('Authorization', `Basic ${btoa(environment.adminServer.username + ":" + environment.adminServer.password)}`)
        .set('Content-Type', `application/json`);
  
      const resp: any = await this.httpClient.get<any>(imxUrl, { headers: this.imxHeaders, observe: "response" }).toPromise();
          if (resp.body && resp.body.username) {
            const username = resp.body.username;
            try {
              const taxiiResp: any = await this.httpClient.delete<any>(url + username, { headers: httpHeaders, observe: "response" }).toPromise();
              try {
                const imxResp: any = await this.httpClient.delete<any>(imxUrl, { headers: this.imxHeaders, observe: "response" }).toPromise();
                  if (imxResp.body && imxResp.body.data) {
                    this.hasCert = false;
                    this.stixService.certFound = false;
                    this.stixService.cert = '';
                    this.announcementService.show("Successfully removed certificate", "The certificate associated with the user has been removed", "success", false);
                    let certHeaders = new HttpHeaders()
                      .set('Authorization', `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`);
                    this.httpClient.get<any>(environment.imxServer.url + '/cert', { headers: certHeaders }).toPromise().catch(err => {
                      console.log("REmoved")
                      this.stixService.certFound = false;
                      this.stixService.cert = '';
                    });
                  } else 
                    this.handleError({}, "Failed to remove certificate");
              } catch (imxErr) {
                this.handleError(imxErr, "IMX Server failed to removed the certificate for user");
              }
            } catch (taxiiErr) {
              try {
                const imxResp = await this.httpClient.delete<any>(imxUrl, { headers: this.imxHeaders, observe: "response" }).toPromise();
                if (imxResp.body && imxResp.body.data) {
                  this.hasCert = false;
                  this.announcementService.show("Successfully removed certificate", "The certificate associated with the user has been removed", "success", false);
                } else 
                  this.handleError({}, "Failed to remove certificate");
              } catch (imxErr) {
                this.handleError(imxErr, "IMX Server failed to removed the certificate for user");
              }
            }
          } else 
            this.handleError({}, "IMX Server failed to find the certificate associated with the user");
    } catch (err) {
      this.handleError(err, "IMX Server failed to find the certificate associated with the user");
    }
  }
}
