import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubmissionsService {
  imxServerUrl = environment.imxServer.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`,
  });

  getPath = '/get-my-submissions';
  getDocPath ='/get-submission';
  postPath = '/post-my-submission';
  updatePath = '/update-my-submission';
  updateByStatusIDPath = '/update-by-status-id';
  searchPath = '/search-my-submissions';

  constructor(private httpClient: HttpClient) { }

  //Gets docs in increments of ten
  public async getPage(page){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.get(`${this.imxServerUrl}${this.getPath}/${page}`, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => {
              resolve(error)
            }
          )
      } catch(e){
        reject(e);
      }
    });
  }

  //Gets docs in increments of ten
  public async getSubmissionByID(docID){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.get(`${this.imxServerUrl}${this.getDocPath}/${docID}`, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => reject(error)
          )
      } catch(e){
        reject(e);
      }
    });
  }

  //Posts new submission
  public async postSubmission(submission){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.postPath}`, submission, { headers: this.headers })
          .subscribe((res) => {
            resolve(res);
          })
      } catch(e){
        reject(e);
      }
    });
  }

  //Update submission
  public async updateSubmission(submission){
    let doc = submission._source;
    let docID = submission._id;

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.updatePath}/${docID}`, doc, { headers: this.headers })
          .subscribe((res) => {
            resolve(res);
          })
      } catch(e){
        reject(e);
      }
    });
  }

  //Update submission
  public async updateByStatusID(submission){
    let statusID = submission.id;

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.updateByStatusIDPath}/${statusID}`, submission, { headers: this.headers })
          .subscribe((res) => {
            resolve(res);
          })
      } catch(e){
        reject(e);
      }
    });
  }
  
  //Search Bundles
  public async searchSavedBundles(query){
    let queryString = btoa(JSON.stringify(query));
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.get(`${this.imxServerUrl}${this.getPath}/${queryString}`, { headers: this.headers })
          .subscribe((res) => {
            console.log(res);
            resolve(res);
          })
      } catch(e){
        reject(e);
      }
    });
  }
}
