<div>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title">Customize Columns</h2>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal"
      (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body extensions-modal-body" >
    <div *ngFor="let column of columns" style="height:31px">
      <input
        type="checkbox"
        class="mr-3"
        name="column.name"
        [checked]="column.checked"
        [(ngModel)]="column.checked"
      /> 
      <label>{{column.name}}</label>
    </div>

    <div class="col text-right mt-2 pr-0">
      <button
        class="btn btn-sm btn-success content-button" 
        type="button"
        (click)="closeModal(); activeModal.dismiss('Cross click')"
      >
      <fa-icon [icon]="faCheck" class="pr-1"></fa-icon>
        OK
      </button>
    </div>
  </div>
</div>