import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AlertSubsService {
  imxServerUrl = environment.imxServer.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Basic ${btoa(environment.imxServer.user + ":" + environment.imxServer.pass)}`,
  });

  getPath = '/get-alert-subs';
  addPath = '/add-alert-sub';
  removePath = '/remove-alert-sub';

  constructor(private httpClient: HttpClient) { }

  async getAlertSubs(page, sortKey){
    let size = 15;
    let from = (page-1)*size;

    return new Promise((resolve, reject) => {
      try {
        this.httpClient.get(`${this.imxServerUrl}${this.getPath}/${sortKey}/${from}/${size}`, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        reject(e);
      }
    });
  }

  async addAlertSub(sub){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.addPath}`, sub, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        console.log(e);
        reject(e);
      }
    });
  }

  async removeAlertSub(docID){
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.post(`${this.imxServerUrl}${this.removePath}/${docID}`, {}, { headers: this.headers })
          .subscribe(
            data => resolve(data),
            error => resolve(error)
          )
      } catch(e){
        console.log(e);
        reject(e);
      }
    });
  }
}
