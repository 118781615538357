<div class="d-flex flex-column justify-content-start align-items-center content-wrapper">
    <div class="d-flex flex-row justify-content-around align-items-end gr-top-width">
        <div class="w-75 pl-5 d-flex align-items-end">
            <fa-icon class="title-main-size4 pr-1 mr-1" [icon]="faUser"></fa-icon>
            <span class="title-main-size3">WHO?</span>
        </div>

        <div class="total-cart-wrapper total-cart-subtitle-position mb-4">
            <app-total-guided-cart (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
        </div>
    </div>

    <div class="horizonal-rule-top"></div>

    <div  class="d-flex justify-content-center align-items-start w-80">
        <div *ngIf="whoType === 'individual' && isTarget" class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">For targets:</h6>
            <div class="gr-help-section-content">
                <span class="gr-help-section-sub-title">Target name:</span> the name of the targeted individual. If the target is an enterprise or organization, toggle to "Organization".
                <br><br>
                <span class="gr-help-section-sub-title">Target contact information:</span> enter the best contact information for the target such as an email address and/or phone number.
                <br><br>
                <span *ngIf="isOrganization && isTarget"><span class="gr-help-section-sub-title">Sector:</span> select the sector that best describes what part of the industry the target works within.</span>
            </div>
        </div>
        <div *ngIf="whoType === 'organization' && isTarget" class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">For targets</h6>
            <div class="gr-help-section-content">
                <span class="gr-help-section-sub-title">Target name and contact information:</span> enter the name and best contact information for the target such as an email address and/or phone number. Multiple organizations or individuals may be added.
                <br><br>
                <span class="gr-help-section-sub-title">Sector:</span> select the sector that best describes what part of the industry the target works within.
            </div>
        </div>
        <div *ngIf="!isTarget" class="mt-5 d-flex flex-column justify-content-start gr-help-section mr-3">
            <h6 class="gr-help-section-title">For attackers:</h6>
            <div class="gr-help-section-content">
                <span class="gr-help-section-sub-title">Attacker name:</span>  the name of the individual or organization that initiated the attack.
                <br><br>
                <span class="gr-help-section-sub-title">Motivation: </span> Can you identify the attacker’s motivation?
            </div>
        </div>
        <div class="mt-5 test gr-outter-wrapper align-items-center">
            <div clas="w-100 d-flex">
                <div style="width:2.5%"></div>
                <div class="p-2 gr-outter-wrapper gr-outter-wrapper-for-tabs">
                    <div class="d-flex align-items-center">
                        <div class="w-100 d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3 w-100">
                            <span class="w-100 font-weight-bold" title="who is target, who is attacker">Who was targeted, and who executed the attack?</span>
                        </div>
                        <!-- <div class="w-25 total-cart-wrapper total-cart-subtitle-position mt-3">
                            <div class="col-8 float-left"></div>
                            <app-total-guided-cart class="float-right" (redirectCart)="redirectCart($event)" (syncCart)="syncCart($event)"></app-total-guided-cart>
                        </div> -->
                    </div>
                    <ul class="nav nav-tabs mb-3 mt-3">
                        <li class="nav-item">
                            <a class="nav-link tabs" (click)="changeTargetAttackerType('target')" [ngClass]="{'active': targetAttackerType === 'target', 'disabled': disableTargetType}">Target</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link tabs disabled" (click)="changeTargetAttackerType('attacker')" [ngClass]="{'active': targetAttackerType === 'attacker', 'disabled': disableAttackerType}">Attacker</a>
                        </li>
                    </ul>
                    <!-- <ul *ngIf="targetAttackerType === 'target'" class="nav nav-tabs">
                        <li class="nav-item tabs" (click)="changeWhoType('individual')">
                            <a class="nav-link" [ngClass]="{'active': whoType === 'individual'}">Individual</a>
                        </li>
                        <li class="nav-item tabs" (click)="changeWhoType('organization')">
                            <a class="nav-link" [ngClass]="{'active': whoType === 'organization'}">Organization</a>
                        </li>
                    </ul> -->
                    <select *ngIf="targetAttackerType === 'target'" class="disabled ml-3 form-control w-25 gr-input-wrapper-small" name="individual or organization" (change)="changeWhoType($event)" [(ngModel)]="whoType"
                    [ngClass]="{'disabled': disableWhoType}">
                        <!-- <option value=''></option> -->
                        <option default [value]="'individual'" title="Individual">Individual</option>
                        <option [value]="'organization'" title="Organization">Organization</option>
                    </select>
                    <div *ngIf="whoType === 'individual' && targetAttackerType === 'target'" class="d-flex mt-2 w-100">
                        <div class="col-12">
                            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3 w-100">
                                <span class="w-25">Target Name
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('individual_target')"></span>
                                    </span>  
                                </span>
                                <input type="text" class="form-control w-75 noborder" placeholder="Enter a name or designation. One will be autogenerated if not provided." (keyup)="enableAddToCart($event)" [(ngModel)]="name"/>
                            </div>
            
                            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-4 w-100">
                                <span class="w-25">Target Phone
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('individual_phone')"></span>
                                    </span>
                                </span>
                                <input type="text" class="form-control w-75 noborder" (blur)="checkError('phone')" (keyup)="enableAddToCart($event)" [(ngModel)]="phone"/>
                            </div>
                            <div *ngIf="!checkEmpty('phone') && phoneError && checkError('phone')" class="col-12 ml-2">
                                <p class="hint" style="color: red">**Must be a valid phone number**</p>
                            </div>

                            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-4 w-100">
                                <span class="w-25">Target Email
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('individual_email')"></span>
                                    </span>
                                </span>
                                <input type="text" class="form-control w-75 noborder" (blur)="checkError('email')" (keyup)="enableAddToCart($event)" [(ngModel)]="email"/>
                            </div>
                            <div *ngIf="!checkEmpty('email') && emailError && checkError('email')" class="col-12 ml-2">
                                <p class="hint" style="color: red">**Must be a valid email address**</p>
                            </div>
            
                            <!-- <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-4 w-100">
                                <span class="w-25" title="Sector">Sector</span>
                                <select class="form-control w-75 noborder" id="sector" name="sector" (change)="sectorHandler($event)" [(ngModel)]="sector">
                                    <option value=''></option>
                                    <option *ngFor="let sector of guidedService.identitySectors; let i = index" [value]="sector.key"
                                        [title]="sector?.value">{{ sector?.value }}</option>
                                </select>
                            </div> -->
                        </div>
                    </div>
                    <div *ngIf="whoType === 'organization' && targetAttackerType === 'target'" class="d-flex flex-column align-items-center mt-2 w-100">
                        <div class="col-12">
                            <div class="d-flex flex-column align-items-left gr-input-wrapper pl-3 pr-3 mt-3">
                                <div class="w-50 mt-3">Targets name and contact information
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('organiztion_target')"></span>
                                    </span>
                                </div>
                                <div>
                                    <textarea class="form-control w-100 mt-2 mb-2 noborder" name="name" id="nameAndContacts" rows="5"
                                        placeholder="Add multiple target names and associated contact information in the following format:&#10;name, phone, email;&#10;name, phone, email;&#10;"
                                        [(ngModel)]="nameAndContacts" (keyup)="enableAddToCart($event)"></textarea>
                                </div>
                            </div>
            
                            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-4 mb-1">
                                <span class="w-25">Sector
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('sectors')"></span>
                                    </span>
                                </span>
                                <select class="form-control w-75 noborder" [disabled]="!nameAndContacts" id="sector" name="sector" [(ngModel)]="sector" (change)="sectorHandler($event)">
                                    <option value=''></option>
                                    <option *ngFor="let sector of guidedService.identitySectors; let i = index" [value]="sector.key"
                                        [title]="sector?.value">{{ sector?.value }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="targetAttackerType === 'attacker'" class="d-flex mt-2 w-100">
                        <div class="col-12">
                            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3 w-100">
                                <span class="w-25">Attacker Name
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_name')"></span>
                                    </span>
                                </span>
                                <input type="text" class="form-control w-75 noborder" [disabled]="guidedService.disableName(name)" placeholder="Enter a name or designation. One will be autogenerated if not provided." (keyup)="enableAddToCart($event)" [(ngModel)]="name"/>
                            </div>

                            <div class="d-flex align-items-center gr-input-wrapper pl-3 pr-3 mt-3 w-100">
                                <span class="w-25">Attacker Motivation
                                    <span class="guided-tip">
                                        <fa-icon class="pl-1" [icon]="faInfoCircle"></fa-icon>
                                        <span class="tip-text" [innerHTML]="guidedService.getTipByLabel('attacker_motivation')"></span>
                                    </span>
                                </span>
                                <select class="form-control w-75 noborder" type="text" [(ngModel)]="currentAttackerMotivation" (change)="enableAddToCart($event)">
                                    <option disabled selected style="color: #91a4bb;" value="">Select Attacker Motivation</option>
                                    <option *ngFor="let option of motivationOptions" [value]="option.key">{{option.value}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
            
                </div>
                <div style="width:2.5%"></div>
            </div>
            <app-guided-cart class="w-100" #guidedCart [currentComponent]="component" [disableCartEvent]="disableCartEmitter" [newItemEvent]="newItemEmitter" [editModeEvent]="editModeEmitter" [syncCartEvent]="syncCartEmitter" [getEditItemEvent]="getEditItemEmitter" (showAssistance)="showAssistanceTrigger()" (addItem)="addCartItem($event)" (updateItem)="editCartItem($event)"></app-guided-cart>
        </div>
    </div>
</div>
